<div
  *ngIf="+statusId === PROJECT_STATUSES.CLOSED"
  class="bg-red d-flex justify-content-center align-items-center"
  style="height: 52px"
>
  <p class="m-0 white font-weight-semibold d-inline-block align-middle px-2">
    <span>CLOSED on {{ closedDate }} at {{ closedTime }}</span>
  </p>
  <button
    mat-flat-button
    (click)="reActivate()"
    type="button"
    class="mx-2 lh-28 d-inline-block align-middle px-3 text-danger"
  >
    Reactivate
  </button>
</div>
<header
  #mainScreen
  data-test-id="project-header"
  id="project-header"
  class="project-header d-flex flex-column full-width"
  [ngClass]="{
    'header-to-banner': isClosedProject
  }"
>
  <div class="d-flex header-top align-items-center mb-1">
    <h3 class="m-0 d-block text-truncate">
      <span *ngIf="code" class="font-weight-semibold dkgray">
        {{ code }}
      </span>
      <span *ngIf="code && (buildAndFloorCodes || title)" class="font-weight-semibold gray ml-1 mr-1"> | </span>
      <span *ngIf="buildAndFloorCodes" class="font-weight-semibold dkgray">
        {{ buildAndFloorCodes }}
      </span>
      <span *ngIf="buildAndFloorCodes && title" class="font-weight-semibold gray ml-1 mr-1"> | </span>
      <span *ngIf="title" class="font-weight-bold dkblue">
        {{ title }}
      </span>
    </h3>

    <!--TENANT-->
    <div *ngIf="isTenant && !isWorkspaceStaff" class="ml-auto d-flex align-items-center text-right">
      <button
        mat-flat-button
        color="warn"
        type="button"
        class="ml-2 lh-34"
        *ngIf="+statusId === PROJECT_STATUSES.CLOSED"
        [disabled]="!canReactivate"
        (click)="reActivate()"
      >
        Reactivate
      </button>
    </div>
    <!--STAFF-->
    <div class="ml-auto d-flex align-items-center text-right">
      <a
        href="#"
        [routerLink]="'/projects/' + parentProjectId"
        class="text-decoration-none ml-2"
        *ngIf="parentProjectId && isWorkspaceStaff"
      >
        <button mat-stroked-button color="accent" type="button" class="pr-3 pl-2">
          <mat-icon class="mr-1">assignment_turned_in</mat-icon>
          <span>PJ-{{ parentProjectCode }}</span>
        </button>
      </a>
      <app-split-button
        *ngIf="statusId === 1 && isWorkspaceStaff"
        class="ml-2"
        lineHeight="24"
        [matMenu]="menu"
        (clickEvent)="close()"
      >
        Close Project
      </app-split-button>
      <app-split-button
        *ngIf="statusId !== 1 && isWorkspaceStaff"
        class="ml-2"
        lineHeight="24"
        [matMenu]="menu"
        (clickEvent)="toActive()"
      >
        Set to Active
      </app-split-button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="toActive()" *ngIf="isWorkspaceStaff && statusId !== 1">
          <mat-icon>label_important</mat-icon>
          <span> Set to Active </span>
        </button>
        <button mat-menu-item *ngIf="isWorkspaceStaff && statusId !== 2" (click)="markAsPlanned()">
          <mat-icon>next_plan</mat-icon>
          <span> Mark As Planned </span>
        </button>
        <button mat-menu-item *ngIf="isWorkspaceStaff && statusId !== 3" (click)="placeOnHold()">
          <mat-icon>flag</mat-icon>
          <span> Place On Hold </span>
        </button>
        <button *ngIf="isWorkspaceStaff && statusId !== 4" (click)="close()" mat-menu-item>
          <mat-icon>done_all</mat-icon>
          <span> Close Project </span>
        </button>
      </mat-menu>
      <button
        mat-button
        [matMenuTriggerFor]="project_master_menu"
        class="ml-2 lh-34 pl-2 pr-2 bg-ltltltblue"
        color="accent"
        [disabled]="!isWorkspaceStaff"
      >
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #project_master_menu="matMenu" class="mt-2" xPosition="before">
        <button
          data-test-id="project-header-edit-project-button"
          mat-menu-item
          color="primary"
          (click)="openProjectDetailsDialog()"
        >
          <mat-icon>assignment</mat-icon>
          <span *ngIf="isWorkspaceStaff">Edit Project Details</span>
          <span *ngIf="!isWorkspaceStaff">View Project Details</span>
        </button>
        <button
          data-test-id="project-header-more-action-create-task-button"
          mat-menu-item
          color="primary"
          (click)="createTask()"
        >
          <mat-icon>assignment_turned_in</mat-icon>
          <span> Create Task </span>
        </button>
        <button
          (click)="createMeeting()"
          data-test-id="project-header-more-action-create-meeting-button"
          mat-menu-item
          color="primary"
        >
          <mat-icon>event</mat-icon>
          <span> Create Meeting </span>
        </button>
        <button
          (click)="createMeetingAgendaFromProject()"
          data-test-id="project-header-more-action-add-meeting-agenda-button"
          mat-menu-item
          color="primary"
        >
          <mat-icon>event_notes</mat-icon>
          <span> Add to Meeting Agenda </span>
        </button>
        <button mat-menu-item (click)="projectFollowers.editFollowers()">
          <mat-icon>group_add</mat-icon>
          <span>Manage Followers</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div id="project-details" class="d-flex align-items-center">
    <div class="d-flex align-items-center pr-3">
      <h6
        *ngIf="workspaceName"
        class="m-0 badge white bg-dkblue text-uppercase px-2 mr-3 lh-20"
        matTooltip="Workspace: {{ workspaceName }}"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
      >
        {{ workspaceName }}
      </h6>
      <!--            TODO Adam which color do we want for Planned?-->
      <h6
        *ngIf="statusName"
        class="m-0 badge white text-uppercase px-2 mr-3 lh-20"
        [ngClass]="{
          'bg-green': statusId === 1,
          'bg-ltblue': statusId === 2,
          'bg-red': statusId === 3,
          'bg-gray': statusId === 4
        }"
        matTooltip="Status: {{ statusName }}"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
      >
        {{ statusName }}
      </h6>
      <h6
        *ngIf="priority"
        [matMenuTriggerFor]="priority_menu"
        class="m-0 white badge text-uppercase pointer px-2 mr-3 lh-20"
        [ngClass]="{
          'bg-dkred': priorityId === 4,
          'bg-red': priorityId === 3,
          'bg-ltblue': priorityId === 2,
          'bg-gray': priorityId === 1
        }"
        matTooltip="Priority: {{ priority }}"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
      >
        {{ priority }}
      </h6>
      <mat-menu #priority_menu="matMenu">
        <button
          mat-menu-item
          type="button"
          class="font-weight-semibold"
          *ngFor="let priorityOption of projectPriorities"
          [ngClass]="{
            gray: !priorityOption?.id,
            dkgray: priorityOption?.id === 1,
            ltblue: priorityOption?.id === 2,
            red: priorityOption?.id === 3,
            dkred: priorityOption?.id === 4
          }"
          (click)="updatePriority(priorityOption)"
        >
          <span>{{ priorityOption.name }}</span>
        </button>
      </mat-menu>
      <h6
        class="m-0 badge text-uppercase pointer px-2 lh-20"
        [ngClass]="{ 'bg-shade-gray gray': !substatus?.name, 'bg-gray white': substatus?.name }"
        matTooltip="Substatus: {{ substatus?.name || 'Not Set' }}"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        [matMenuTriggerFor]="substatus_menu"
      >
        {{ substatus?.name || 'Set Substatus' }}
      </h6>
      <mat-menu #substatus_menu="matMenu">
        <div class="py-2" *ngFor="let category of projectSubstatusCategories">
          <h6 class="gray ml-3">
            {{ category.name }}
          </h6>
          <button
            mat-menu-item
            type="button"
            color="primary"
            class="font-weight-semibold"
            *ngFor="let substatus of category.substatuses"
            (click)="updateSubstatus(substatus)"
          >
            <span class="px-2">{{ substatus.name }}</span>
          </button>
        </div>
      </mat-menu>
    </div>
    <div *ngIf="divWidth > 1100" class="d-flex align-items-center px-3 border-left border-gray">
      <h6
        *ngIf="formattedEstimatedCompletionDate"
        (click)="estimatedCompletionDatePicker.open()"
        class="m-0 mr-3 ltblue bg-shade-ltblue br-4 px-2 lh-20 pointer"
        matTooltip="Estimated Completion Date: {{ formattedEstimatedCompletionDate }} ({{
          projectEndDateEstimation ? projectEndDateEstimation : '0'
        }}%)
      }}"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
      >
        <span class="dkblue"> ECD: </span>
        &nbsp;{{ formattedEstimatedCompletionDate }} ({{ projectEndDateEstimation ? projectEndDateEstimation : '0' }}%)
        <span>
          <input
            matInput
            [matDatepicker]="estimatedCompletionDatePicker"
            autocomplete="off"
            style="visibility: hidden; width: 0"
            [formControl]="input_estimated_completion_date"
          />
          <mat-datepicker #estimatedCompletionDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
        </span>
      </h6>
      <h6
        class="m-0 ltblue bg-shade-ltblue br-4 px-2 lh-20 pointer"
        matTooltip="Project Manager: {{ projectManagerName }}"
        matTooltipPosition="above"
        [matTooltipShowDelay]="1000"
        (click)="openUserProfilePreview(project?.project_manager_id)"
      >
        <span class="dkblue"> PM: </span>
        &nbsp;
        {{ projectManagerName }}
      </h6>
    </div>
    <!-- TAGS -->
    <div class="d-flex align-items-center px-3 border-left border-gray" *ngIf="isStaff">
      <h6
        *ngFor="let tag of project?.tags || []"
        class="m-0 mr-3 ltblue border border-gray br-10 px-2 lh-20"
        matTooltip="{{ tag.name }}"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
      >
        {{ tag.abbreviation }}
      </h6>
    </div>
    <div class="d-flex align-items-center ml-auto" *ngIf="!isVendor">
      <app-project-followers #projectFollowers [project]="project"></app-project-followers>
    </div>
  </div>
</header>

import { Pipe, PipeTransform } from '@angular/core';

import { Milestone } from 'src/app/types';

/**
 * This pipe is for filtering the tasks of the tasks page.
 * TODO: Performance can be increased by allowing it to be "pure". Currently, the pipe is refreshed on every detection change. Happens too much.
 */
@Pipe({
  name: 'milestonesFilter',
})
export class MilestonesFilterPipe implements PipeTransform {
  transform(milestones: Milestone[]): any {
    milestones.sort((a, b) => {
      return a.display_order_hash >= b.display_order_hash ? 1 : -1;
    });

    return milestones;
  }
}

import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SortDirection } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { sortBy, sumBy } from 'lodash';
import * as moment from 'moment/moment';
import { merge, Subscription } from 'rxjs';
import { ArfProductComponent, EditorComponent, FileChipListComponent, InvoiceMenuComponent } from 'src/app/components';
import {
  ArfAdminEditingStatus,
  ArfPurchaseTypeId,
  ArfStatus,
  CompanyVerificationStatus,
  InvoiceStatus,
  ResourceType,
  TaskAccessoryType,
  TaskReviewStatus,
  TaskStatus,
  Workspace,
  WorkspaceType,
} from 'src/app/enums';
import { TaskAccessoryDataFactory } from 'src/app/models';
import {
  ArfsService,
  AuthService,
  CompanyService,
  DateService,
  DisplayReviewersService,
  FileService,
  GeneralLedgerService,
  ModalService,
  ModuleService,
  ProductService,
  ProgressIndicatorService,
  ProjectEventService,
  ReviewRevisionService,
  SidenavService,
  TaskActionsService,
} from 'src/app/services';
import {
  APIFilter,
  Arf,
  ArfCustodyChain,
  ArfProduct,
  ArfPurchaseType,
  Company,
  CostCode,
  Module,
  Preferences,
  Product,
  Task,
  TaskAccessoryData,
  UhatFileReference,
} from 'src/app/types';
import { convertDateToFiscalYear, PreferenceStorage } from 'src/app/utils';

interface ArfInvoicePreferences extends Preferences {
  fieldToSortBy: string;
  sortDirection: SortDirection;
}

@Component({
  selector: 'app-arf',
  templateUrl: './arf.component.html',
  styleUrls: ['./arf.component.scss'],
})
export class ArfComponent implements OnInit, OnDestroy {
  @ViewChild('arfFile', { static: true }) arfFile;
  @ViewChild('company_auto_input', { read: MatAutocompleteTrigger }) companyAutoInput: MatAutocompleteTrigger;
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  @ViewChildren('productComponent') productComponents: QueryList<ArfProductComponent>;
  @ViewChildren('invoiceMenuComponent') invoiceMenuComponents: QueryList<InvoiceMenuComponent>;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('fileChipListComponent') fileChipListComponent: FileChipListComponent;

  public arfFormGroup: FormGroup = this.fb.group({
    title: ['', [Validators.required]],
    description: ['', [Validators.required]],
    company_id: ['', [Validators.required]],
    purchase_type_id: ['', [Validators.required]],
    asset_tag: [''],
    fiscal_year: ['', [Validators.required]],
    module_id: ['', [Validators.required]],
  });
  constructor(
    private arfService: ArfsService,
    public authService: AuthService,
    private companyService: CompanyService,
    private dateService: DateService,
    private displayReviewerService: DisplayReviewersService,
    private eventService: ProjectEventService,
    private fb: FormBuilder,
    private fileService: FileService,
    private generalLedgerService: GeneralLedgerService,
    public moduleService: ModuleService,
    private modalService: ModalService,
    private productService: ProductService,
    private progressIndicatorService: ProgressIndicatorService,
    private reviewRevisionService: ReviewRevisionService,
    private route: ActivatedRoute,
    private sidenavService: SidenavService,
    private taskActionsService: TaskActionsService,
    private snackbarService: MatSnackBar
  ) {}

  accessoryData: TaskAccessoryData;
  arf: Arf;
  ArfStatus = ArfStatus;
  arfId: number;
  arfDataToUpdate: Arf = {};
  allProducts: Product[] = [];
  arfProducts: ArfProduct[] = [];
  initialArfProducts: ArfProduct[] = [];
  allCompanies: Company[] = [];
  costCodes: CostCode[] = [];
  filteredCompanies: Company[] = [];
  purchaseTypes: ArfPurchaseType[];
  ArfPurchaseTypeId = ArfPurchaseTypeId;
  isUpdating: boolean;
  InvoiceStatus = InvoiceStatus;
  updateArfSubscription: Subscription;
  fiscalYears: { value: string; isCurrentYear: boolean }[] = [];
  taskUpdatedSubscription: Subscription;
  invoiceUpdatedSubscription: Subscription;
  isEditingAsAdmin: boolean;
  adminEditingStatus = ArfAdminEditingStatus.NoneAdminAction;
  arfProductIsLoading: boolean;
  companyInputName = '';
  CompanyVerificationStatus = CompanyVerificationStatus;
  isDialogOpen = false;

  siblingFields = {
    company_id: this.arfService.arfCompanyField,
    module_id: this.arfService.arfModuleField,
    status_id: this.arfService.arfStatusField,
    custody_chain_id: this.arfService.arfCustodyChainField,
    review_task_id: this.arfService.arfReviewTaskField,
  };

  fieldToSortBy = 'created_datetime';
  sortDirection: SortDirection = 'desc';
  private preferences = new PreferenceStorage<ArfInvoicePreferences>('preferences_arf_invoices', {
    sortDirection: 'desc',
    fieldToSortBy: 'created_datetime',
    version: 1,
  });
  sortOptions = [
    { name: 'Number', value: 'number' },
    { name: 'Date', value: 'created_datetime' },
    { name: 'Amount', value: 'total' },
  ];
  isFileReady: boolean = false;
  noteFiles: UhatFileReference[];

  async ngOnInit(): Promise<void> {
    this.arfFormGroup.addControl('description', this._editor_component?.content, { emitEvent: false });

    this.arfFormGroup.valueChanges.subscribe(async (value) => {
      const moduleIdHasChanged = value.module_id && value.module_id !== this.arf.module_id;
      this.updateArf(value, []);

      if (moduleIdHasChanged) {
        this.setIsUpdatingToTrue();
        this.costCodes = [];
        this.costCodes = await this.generalLedgerService.getCostCodesByModuleId(value.module_id);
        await this.getAllProducts();
        this.setIsUpdatingToFalse();
      }
    });

    this._editor_component.content.valueChanges.subscribe((value) => {
      if (value) {
        this.arfFormGroup.get('description').setErrors(null);
      } else {
        this.arfFormGroup.get('description').setErrors({ isRequired: true });
      }

      if ((value || this.arf?.description) && value !== this.arf?.description) {
        this.updateArf({ description: value });
      }
    });

    merge(this.arfService.refreshNeeded$, this.route.params).subscribe(async (pair: any) => {
      if (pair?.id) {
        this.arfId = pair.id;
      }
      await this.refresh();
    });

    this.taskUpdatedSubscription = this.taskActionsService.taskService.taskReviewChanged.subscribe(async (task) => {
      if (this.arf?.review_task_id && task?.id && this.arf.review_task_id === task.id) {
        this.arf.review_task = { ...this.arf.review_task, ...task };
        this.convertAccessoryData();
        let arfFields = [];
        if (
          this.arf?.status_id === ArfStatus.InReview &&
          !this.accessoryData.reviewChain?.find((r) =>
            [TaskReviewStatus.Rejected, TaskReviewStatus.Pending].includes(r.status)
          )
        ) {
          this.arf.status_id = ArfStatus.ReviewComplete;
        }

        if (task.is_locked) {
          if (task.status_id === TaskStatus.Complete) {
            this.arf.status_id = ArfStatus.Finalized;
            if (
              (!this.arf.purchase_task_id && this.arf.purchase_type_id !== ArfPurchaseTypeId.Vendor) ||
              (!this.arf.tag_asset_task_id && this.arf.asset_tag)
            ) {
              arfFields = [...arfFields, ...['purchase_task_id', 'tag_asset_task_id']];
            }
          } else if (this.arf.status_id !== ArfStatus.Draft) {
            this.arf.status_id = ArfStatus.Draft;
            this.arfFormGroup.enable({ emitEvent: false });
          }
        }

        if (this.arf.status_id !== this.arf.arf_status?.id) {
          arfFields.push(this.arfService.arfStatusField);
          await this.getArfData(arfFields);
        }
        this.getMostRecentTaskActivityFile(this.arf?.review_task_id);
      } else if (this.arf?.invoices?.find((i) => i.approval_task_id === task.id)) {
        const invoice = this.arf?.invoices?.find((i) => i.approval_task_id === task.id);
        const accessoryData = task?.accessory_data && JSON.parse(task.accessory_data);
        const oldAccessoryData =
          invoice?.approval_task_accessory_data && JSON.parse(invoice.approval_task_accessory_data);
        const reviewStatus = accessoryData?.reviewChain.find((r) =>
          [TaskReviewStatus.Pending, TaskReviewStatus.Rejected].includes(r.status)
        );

        if (accessoryData?.reviewChain?.length && !reviewStatus && reviewStatus?.status !== 0) {
          invoice.status_id = InvoiceStatus.Approved;
        } else if (
          reviewStatus?.status === TaskReviewStatus.Pending &&
          oldAccessoryData?.reviewChain.find((r) =>
            [TaskReviewStatus.Pending, TaskReviewStatus.Rejected].includes(r.status)
          )?.status !== TaskReviewStatus.Pending &&
          ![InvoiceStatus.Received, InvoiceStatus.New].includes(invoice?.status_id)
        ) {
          invoice.status_id = InvoiceStatus.Received;
        }
        this.getMostRecentTaskActivityFile(task.id);
      }
    });

    this.invoiceUpdatedSubscription = this.taskActionsService.taskService.invoiceUpdatedInTask.subscribe((invoice) => {
      const updatedInvoiceIndex = this.arf?.invoices.findIndex((i) => i.id === invoice.id);
      if (updatedInvoiceIndex !== -1) {
        this.arf.invoices[updatedInvoiceIndex] = invoice;
      }
    });

    this.fieldToSortBy = this.preferences.currentValue.fieldToSortBy;
    this.sortDirection = this.preferences.currentValue.sortDirection;
  }

  ngOnDestroy(): void {
    if (this.updateArfSubscription) {
      this.updateArfSubscription.unsubscribe();
    }

    if (this.taskUpdatedSubscription) {
      this.taskUpdatedSubscription.unsubscribe();
    }

    if (this.invoiceUpdatedSubscription) {
      this.invoiceUpdatedSubscription.unsubscribe();
    }
  }

  DownloadPDF() {
    if (this.fileChipListComponent?.files?.length > 0) {
      this.fileChipListComponent.downloadAll();
    } else {
      this.snackbarService.open('No file to download or you do not have access to download this file', 'close');
    }
  }

  getMostRecentTaskActivityFile(taskId: number) {
    this.taskActionsService.taskService
      .getMostRecentTaskActivityFile(taskId)
      .then((noteFiles: UhatFileReference[]) => {
        this.noteFiles = noteFiles;
      })
      .finally(() => {
        this.isFileReady = this.noteFiles.length > 0;
      });
  }

  get isDownloadablePDF() {
    return (
      this.arf?.review_task_id &&
      this.arf?.arf_status?.id >= ArfStatus.InReview &&
      this.arf?.arf_status?.id <= ArfStatus.Finalized
    );
  }

  get isSidenavClosed(): boolean {
    return this.sidenavService?.isSidenavClosed;
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.arf?.module_id);
  }

  get userStaffWorkspaces(): Module[] {
    return this.moduleService.userWorkspaces?.filter(
      (w) => this.authService.isUserWorkspaceStaff(w.id) && +w?.id !== +Workspace.Dispatch
    );
  }

  get arfTotal(): string {
    return (sumBy(this.arfProducts || [], 'total_price') || 0).toFixed(2);
  }

  get canEdit(): boolean {
    return [ArfStatus.Draft].includes(this.arf?.status_id) || this.isEditingAsAdmin;
  }
  get isReadyToReview(): boolean {
    return (
      (this.arf?.status_id === ArfStatus.Draft &&
        this.arf?.custody_chain &&
        this.arfFormGroup.valid &&
        !!this.arf?.quote_file_id &&
        this.productComponents.length &&
        !this.productComponents?.filter((p) => !p.productFormGroup.valid)?.length) ||
      false
    );
  }

  get arfIsDraft(): boolean {
    return this.arf?.status_id === ArfStatus.Draft;
  }
  get arfReviewIsComplete(): boolean {
    return this.arf?.status_id === ArfStatus.ReviewComplete;
  }

  get arfIsFinalized(): boolean {
    return this.arf?.status_id === ArfStatus.Finalized;
  }

  get arfIsClosed(): boolean {
    return this.arf?.status_id === ArfStatus.Closed;
  }

  get noPendingInvoices(): boolean {
    return (
      this.arf?.invoices?.filter((i) => [InvoiceStatus.ReadyForPayment, InvoiceStatus.Rejected].includes(i.status_id))
        .length === this.arf?.invoices?.length
    );
  }

  get invoicesTotal(): string {
    return (
      sumBy(this.arf?.invoices?.filter((i) => i.status_id !== InvoiceStatus.Rejected) || [], 'total') || 0
    ).toFixed(2);
  }

  get arfTotalEqualsInvoicesTotal(): boolean {
    return Number(this.arfTotal) === Number(this.invoicesTotal);
  }

  async refresh(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading ARF...');

    this.arf = await this.arfService.getArfById(this.arfId).toPromise();
    this.convertAccessoryData();

    this.initialArfProducts = [...[], ...this.arf.products];
    this.arfProducts = this.arf.products;
    this.arf.fiscal_year = this.arf.fiscal_year?.toString() || convertDateToFiscalYear(new Date()).toString();

    this.fiscalYears = this.dateService.getFiscalYearOptions(this.arf.fiscal_year);

    if (this.arf?.id) {
      this.setArfForm();
      this.purchaseTypes = await this.productService
        .getArfPurchaseTypes(this.productService.purchaseTypeFields)
        .toPromise();

      await this.getAllProducts();
    }

    const companiesFilter: APIFilter[] = [
      { type: 'field', field: 'type_id', value: '1^3' }, // Staff and Supplier companies
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_enabled', value: 1 },
    ];
    this.allCompanies = await this.companyService
      .getCompanies(['id', 'name', 'verification_status'], companiesFilter, 10000)
      .toPromise();

    this.filteredCompanies = this.allCompanies;

    this.costCodes = await this.generalLedgerService.getCostCodesByModuleId(this.arf?.module_id);
    this.progressIndicatorService.close();
  }

  setArfForm(): void {
    for (const key in this.arfFormGroup.value) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.arfFormGroup.value.hasOwnProperty(key)) {
        if (this.arfFormGroup.get(key).value !== this.arf[key]) {
          if (key === 'description') {
            this._editor_component.content.setValue(this.arf[key], { emitEvent: false });
          }

          this.arfFormGroup.get(key).setValue(this.arf[key], { emitEvent: false });
        }
      }
    }

    if (this.canEdit) {
      this.arfFormGroup.enable({ emitEvent: false });
    } else {
      this.arfFormGroup.disable({ emitEvent: false });
    }
  }

  async getAllProducts(): Promise<void> {
    const productFilters: APIFilter[] = [
      { type: 'field', field: 'module_id', value: this.arf?.module_id?.toString() },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'enabled', value: '1' },
    ];

    // get products and sort by name
    this.allProducts = sortBy(
      (await this.productService.getProducts(this.productService.productFields, productFilters).toPromise()) ?? [],
      [(product) => product?.name?.toLowerCase()]
    );
  }

  public async openUploadModal(): Promise<void> {
    const data = {
      parentResourceType: ResourceType.AcquisitionRequestForm,
      parentResourceId: this.arf?.id,
      preSelectedTags: [],
      verifyFileExtension: true,
      allowComment: false,
      allowSearchFromProject: false,
      limit: 1,
    };
    const files = await this.modalService.openFileAttachmentDialog(data).toPromise();
    if (files.length) {
      this.arf.quote_file = files[0];
      this.updateArf({ quote_file_id: this.arf.quote_file?.id });
    }
  }

  async removeFile(file: UhatFileReference): Promise<void> {
    this.arf.quote_file = null;
    this.updateArf({ quote_file_id: null });
    await this.fileService.deleteFileWithoutConfirmation(file).toPromise();
  }

  updateArf(newArfData: Arf = null, additionalFields: string[] = []): void {
    if (this.arf?.id) {
      for (const key in newArfData) {
        // eslint-disable-next-line no-prototype-builtins
        if (newArfData.hasOwnProperty(key)) {
          if (newArfData[key] !== this.arf[key]) {
            this.arfDataToUpdate[key] = newArfData[key];
          }
        }
      }

      if (this.updateArfSubscription) {
        this.updateArfSubscription.unsubscribe();
      }

      let arfFields: string[] = [];
      for (const key in this.arfDataToUpdate) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.arfDataToUpdate.hasOwnProperty(key)) {
          arfFields.push(key);
          if (this.siblingFields[key] && !additionalFields.includes(this.siblingFields[key])) {
            additionalFields.push(this.siblingFields[key]);
          }
        }
      }

      if (arfFields.length && !this.isEditingAsAdmin) {
        this.setIsUpdatingToTrue();
        arfFields = [...arfFields, ...additionalFields];
        this.arf = { ...this.arf, ...this.arfDataToUpdate };

        this.updateArfSubscription = this.arfService
          .updateArf(this.arf.id, this.arfDataToUpdate, arfFields)
          .subscribe((newArf) => {
            this.arf = { ...this.arf, ...newArf };
            this.arfDataToUpdate = {};

            if (newArf.review_task?.accessory_data) {
              this.convertAccessoryData();
            }

            this.setIsUpdatingToFalse();
          });
      }
    }
  }

  async getArfData(arfFields: string[]): Promise<void> {
    const arfData: Arf = await this.arfService.getArfById(this.arf.id, arfFields).toPromise();

    this.arf = { ...this.arf, ...arfData };
  }

  setIsUpdatingToTrue(): void {
    this.isUpdating = true;
  }

  setIsUpdatingToFalse(): void {
    this.isUpdating = false;
  }

  // Products
  async addProduct(): Promise<void> {
    this.arfProductIsLoading = true;
    const productData: ArfProduct = {
      name: '',
      quantity: 1,
      unit_price: 0,
      total_price: 0,
      arf_id: this.arf?.id,
    };

    const newProduct = await this.productService.createArfProduct(productData).toPromise();
    this.arfProducts.push(newProduct);
    this.initialArfProducts = [...[], ...this.arfProducts];
    this.arfProductIsLoading = false;
  }

  updateProduct(product: ArfProduct): void {
    const productIndex = this.arfProducts?.findIndex((p) => p?.id === product?.id);

    if (productIndex > -1) {
      this.arfProducts[productIndex] = { ...this.arfProducts[productIndex], ...product };
    }
  }

  deleteProduct(productNumber: number): void {
    this.setIsUpdatingToTrue();
    const updatedProducts = this.arfProducts.filter((a) => a.id !== productNumber);
    this.initialArfProducts = this.arfProducts = updatedProducts;
  }

  // Reviews
  async startARFTaskReview(): Promise<void> {
    const reviewersToInclude = this.displayReviewerService.getArfReviewers(this.arfTotal, this.arf.module);
    const reviewChain = this.displayReviewerService.getArfReviewChain(reviewersToInclude, this.arf.custody_chain);
    let assignedUserId = reviewChain[0].id || this.authService.currentUser?.id;
    const approvedOnCreation = reviewChain[0].id === this.authService.currentUser.id;

    const headerText = approvedOnCreation ? 'Submit ARF for Review' : 'Submit ARF for Review';
    const descriptionText = approvedOnCreation
      ? `Clicking the "Submit" button will automatically generate the review task and <b>your approval will be instantly submitted</b>. </br></br>If there are other reviewers, the ARF will be locked while in review. If changes need to be made, click "Make A Change".`
      : `Clicking the "Submit" button will automatically generate the review task. </br></br>While in review, no changes can be made to this ARF. To unlock this ARF and make changes, simply click Make A Change. `;
    const confirmationButtonText = approvedOnCreation ? 'Submit and Approve' : 'Submit For Review';
    const res = await this.modalService
      .openConfirmationDialog({
        titleBarText: 'ARF Review',
        headerText,
        descriptionText,
        confirmationButtonText,
      })
      .toPromise();

    if (res) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Creating File...');
      const accessoryData: TaskAccessoryData = TaskAccessoryDataFactory.createArfReviewApprovalData(
        true,
        reviewChain,
        this.arf.id,
        this.authService?.currentUser?.id
      );

      if (approvedOnCreation) {
        reviewChain[0].status = TaskReviewStatus.Approved;
        reviewChain[0].status = TaskReviewStatus.Approved;
        reviewChain[0].date = moment().toDate();
        assignedUserId = reviewChain[1]?.id || accessoryData?.reviewCreator || this.authService.currentUser?.id;
      }

      const arfFiles = await this.arfFile.saveArfFile(accessoryData.reviewChain);
      accessoryData.reviewFiles = arfFiles.map((file) => ({ id: file.file_id || file.id, name: file.name })) || [];

      const taskData = {
        title: `Review ARF for ${this.arf?.title} - ${this.arf?.module?.name}`,
        description: this.arf.description,
        assigned_user_id: assignedUserId,
        accessory_data: JSON.stringify(accessoryData),
        parent_id: this.arf?.id,
        parent_resource_type_id: ResourceType.AcquisitionRequestForm,
        can_delete: 0,
      };

      this.progressIndicatorService.updateStatus('Creating Task...');
      const taskNote = this.taskActionsService.getTaskNoteText(TaskAccessoryType.Arf, arfFiles?.length);
      const newTask = await this.taskActionsService.createTaskAndAttachFiles(taskData, taskNote, [], arfFiles, []);

      if (newTask?.id) {
        if (approvedOnCreation) {
          await this.eventService
            .createTaskApprovalEvent(newTask?.id, TaskReviewStatus.Approved, 'Approved upon creation')
            .toPromise();
        }

        const arfData = {
          review_task_id: newTask?.id,
          status_id:
            approvedOnCreation && accessoryData.reviewChain.length === 1
              ? ArfStatus.ReviewComplete
              : ArfStatus.InReview,
        };

        this.progressIndicatorService.close();
        this.updateArf(arfData);
      }
    }
  }

  async resetReview(reset = false): Promise<void> {
    const res = await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Reset Approval Process',
        descriptionText:
          'You are about to reset the approval process, are you sure? This will invalidate any existing approvals and require a new approval.',
        confirmationButtonText: 'Submit',
        userInput: {
          required: true,
          placeholder: 'Reason for Reset',
        },
      })
      .toPromise();

    if (res) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Resetting ARF...');
      await this.reviewRevisionService.arfRevision(this.arf, reset, res);
      this.arf.revision = this.arf.revision + 1;
      this.arf.status_id = ArfStatus.Draft;
      await this.getArfData([this.arfService.arfStatusField]);
      this.arfFormGroup.enable({ emitEvent: false });

      if (this.arf?.tag_asset_task_id || this.arf.purchase_task_id) {
        const taskIds = [this.arf.tag_asset_task_id, this.arf.purchase_task_id];
        for (const taskId of taskIds) {
          if (taskId) {
            const taskData: Task = {
              id: taskId,
              is_locked: 1,
              status_id: TaskStatus.Open,
            };
            await this.taskActionsService.projectService.updateTask(taskData).toPromise();
          }
        }
      }
      this.progressIndicatorService.close();
    }
  }

  async resubmitReview(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Creating ARF file...');
    this.arfFormGroup.disable();
    const reviewersToInclude = this.displayReviewerService.getArfReviewers(this.arfTotal, this.arf.module);
    const arfFile = [await this.arfFile.createArfFile(null, null, reviewersToInclude, null, null, this.arf)];

    this.progressIndicatorService.updateStatus('Restarting ARF review...');
    await this.reviewRevisionService.arfSubmitRevision(this.arf, arfFile, 55, reviewersToInclude);
    this.arf.status_id = ArfStatus.InReview;
    this.progressIndicatorService.close();
  }

  async finalizeArf(): Promise<void> {
    const updatedArf = await this.arfService.finalizeArf(this.arf, this.accessoryData);
    this.arf = { ...this.arf, ...(updatedArf || {}) };
  }

  async closeArf(): Promise<void> {
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Close ARF',
        headerText: 'Close ARF',
        confirmationButtonText: 'Close ARF',
        descriptionText:
          'Please double-check all details and billing before closing.<br/><br/>If the invoice total is less that the original ARF amount, that difference will be logged as savings. If the invoice total is more than the original ARF amount, please Make a Change and update it. <br/><br/>Closing the ARF will lock it from changes.',
      })
      .toPromise()
      .then((res) => {
        if (res) {
          this.updateArf({ status_id: ArfStatus.Closed });
        }
      });
  }

  async reopenArf(): Promise<void> {
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Reopen ARF',
        headerText: 'Reopen ARF',
        confirmationButtonText: 'Reopen ARF',
        descriptionText: 'Are you sure you want to reopen this ARF? This will change the status of the ARF.',
      })
      .toPromise()
      .then((res) => {
        if (res) {
          let arfStatus = ArfStatus.Draft;

          if (this.arf?.review_task_id) {
            if (this.arf.review_task?.status_id === TaskStatus.Complete) {
              arfStatus = ArfStatus.Finalized;
            } else if (!this.accessoryData.reviewChain?.find((r) => r.status === TaskReviewStatus.Pending)) {
              arfStatus = ArfStatus.ReviewComplete;
            } else {
              arfStatus = ArfStatus.InReview;
            }
          }

          this.updateArf({ status_id: arfStatus });
        }
      });
  }

  setAdminEditing(statusId = ArfAdminEditingStatus.EditingAsAdmin): void {
    this.isEditingAsAdmin = !this.isEditingAsAdmin;
    this.adminEditingStatus = statusId;

    if (this.canEdit) {
      this.arfFormGroup.enable({ emitEvent: false });
    } else {
      this.arfFormGroup.disable({ emitEvent: false });
    }
  }

  async saveAsAdmin(): Promise<void> {
    this.updateArf();
    this.setAdminEditing(ArfAdminEditingStatus.SavingAsAdmin);

    const arfTasks = [this.arf?.review_task_id, this.arf?.purchase_task_id, this.arf?.tag_asset_task_id];

    if (arfTasks?.length) {
      const arfFiles = await this.arfFile.saveArfFile(this.accessoryData.reviewChain);
      this.accessoryData.reviewFiles = arfFiles.map((file) => ({ id: file.file_id || file.id, name: file.name })) || [];

      for (const taskId of arfTasks) {
        if (taskId) {
          if (taskId === this.arf?.review_task_id) {
            const taskData = {
              id: taskId,
              accessory_data: JSON.stringify(this.accessoryData),
            };
            await this.taskActionsService.projectService.updateTask(taskData, 'accessory_data').toPromise();
          }

          const note = await this.taskActionsService.projectService
            .createNote(ResourceType.Task, taskId, '<p>A change has been made to this ARF.</p>')
            .toPromise();
          await this.fileService.addFilesToNote(note, taskId, [], arfFiles);
        }
      }
    }
    this.updateArf({ revision: this.arf.revision + 1 }, [this.arfService.arfReviewTaskField]);

    if (this.arf.invoices?.length) {
      const previousSubCostCodeBudgets = this.productComponents.map((pc) => pc.previousSubCostCode);
      const currentSubCostCodeBudgets = this.arf.products.map((p) => p.sub_cost_code_budget);

      for (const invoice of this.arf?.invoices) {
        let index = 0;
        let invoiceUpdated = false;
        for (const cs of currentSubCostCodeBudgets) {
          if (previousSubCostCodeBudgets[index]?.id && cs.id !== previousSubCostCodeBudgets[index]?.id) {
            let arfInvoiceAmountIndex = 0;
            for (let a of invoice.arf_invoice_amounts) {
              if (
                a.sub_cost_code_budget_id === previousSubCostCodeBudgets[index]?.id &&
                a.sub_cost_code_budget_id !== cs.id
              ) {
                const arfInvoiceAmountData = {
                  sub_cost_code_budget_id: cs.id,
                };

                a = await this.arfService
                  .updateArfInvoiceAmount(a.id, arfInvoiceAmountData, this.arfService.arfInvoiceAmountFields)
                  .toPromise();
                invoiceUpdated = true;
                invoice.arf_invoice_amounts[arfInvoiceAmountIndex] = a;
              }

              arfInvoiceAmountIndex++;
            }
          }

          index++;
        }

        if (invoiceUpdated) {
          const invoiceMenuComponent = this.invoiceMenuComponents.find((i) => i.invoice?.id === invoice.id);
          await invoiceMenuComponent.createInvoiceNote(invoice, true);
        }
      }
    }
  }

  cancelSaveAsAdmin(): void {
    this.setArfForm();
    this._editor_component.content.setValue(this.arf.description, { emitEvent: false });
    this.arfDataToUpdate = {};
    this.setAdminEditing(ArfAdminEditingStatus.Cancelling);
  }

  // Company Functions
  filterCompanies(input): void {
    const companyName = input.target.value;
    if (companyName) {
      this.companyInputName = companyName;
      this.filteredCompanies = this.allCompanies.filter(
        (c) => c.name && c.name.toLowerCase().includes(companyName.toLowerCase())
      );
    } else {
      this.companyInputName = '';
      this.filteredCompanies = this.allCompanies;
      this.updateArf({ company_id: null }, ['company']);
    }
  }

  updateCompany(company: Company): void {
    if (company?.id && this.isCompanyAllowed(company)) {
      this.filteredCompanies = [company];
      this.arfFormGroup.get('company_id').setValue(company.id);
    }
  }

  updateTagAsset(): void {
    this.arfFormGroup.get('asset_tag').setValue(this.arf.asset_tag ? 0 : 1);
  }

  public async viewTask(taskId: number): Promise<void> {
    if (taskId) {
      await this.modalService.openViewTaskModal(taskId).toPromise();
    }
  }

  public async openEditStaffDialog(viewOnly = false): Promise<void> {
    this.isDialogOpen = true;
    const res = await this.modalService
      .openEditStaffDialog(this.arf.module, this.arf.custody_chain, viewOnly)
      .toPromise();

    if (res) {
      this.setIsUpdatingToTrue();
      const staffIds: ArfCustodyChain = {
        arf_id: this.arf?.id,
      };
      for (const key in res) {
        // eslint-disable-next-line no-prototype-builtins
        if (res.hasOwnProperty(key)) {
          if (key !== 'dfs_required') {
            staffIds[`${key}_id`] = res[key]?.id || null;
          } else {
            staffIds[`${key}`] = res[key] === true ? 1 : res[key] === false ? 0 : null;
          }
        }
      }

      let arfCustodyChain: ArfCustodyChain;
      if (this.arf.custody_chain_id) {
        arfCustodyChain = await this.arfService
          .updateArfCustodyChain(this.arf?.custody_chain_id, staffIds, this.arfService.arfCustodyChainFields)
          .toPromise();

        this.arf.custody_chain = arfCustodyChain;
      } else {
        arfCustodyChain = await this.arfService
          .createArfCustodyChain(staffIds, this.arfService.arfCustodyChainFields)
          .toPromise();
      }

      if (arfCustodyChain?.id) {
        const arfData: Arf = {
          custody_chain_id: arfCustodyChain.id,
        };

        this.updateArf(arfData, [this.arfService.arfCustodyChainField]);
      } else {
        this.setIsUpdatingToFalse();
      }
    }
    this.isDialogOpen = false;
  }

  public convertAccessoryData(): void {
    this.accessoryData = this.arf.review_task?.accessory_data && JSON.parse(this.arf.review_task.accessory_data);
  }

  public openUserProfilePreview(userId: number): void {
    if (userId) {
      this.modalService.openUserProfileDialog(userId);
    }
  }

  public async openNewInvoiceDialog(): Promise<void> {
    this.isDialogOpen = true;
    const newInvoice = await this.modalService
      .openNewInvoiceModal(this.arf.module_id, null, true, this.arf)
      .toPromise();
    if (newInvoice) {
      this.arf.invoices = [...(this.arf.invoices || []), ...[newInvoice]];
      const additionalInfo = await this.taskActionsService.projectService
        .getInvoiceById(newInvoice?.id, ['approval_task_id'])
        .toPromise();
      newInvoice.approval_task_id = additionalInfo.approval_task_id;
    }
    this.isDialogOpen = false;
  }

  public async openViewInvoiceDialog(invoiceId: number): Promise<void> {
    this.isDialogOpen = true;
    await this.modalService.openViewInvoiceModal(invoiceId, this.arf?.module_id, false, false, false).toPromise();
    this.isDialogOpen = false;
  }

  public async openCostCodeHelpDialog(): Promise<void> {
    this.isDialogOpen = true;
    await this.modalService
      .openCostCodeHelpDialog({ moduleId: this.arf.module_id || this.arfFormGroup.get('module_id').value || null })
      .toPromise();
    this.isDialogOpen = false;
  }

  public createCompany(): void {
    const companyData = {
      company: { type_id: 3, name: this.companyInputName },
      showTrades: false,
      companies: this.allCompanies,
    };

    void this.modalService
      .openCompanyDialog(companyData)
      .toPromise()
      .then((createdCompany: Company) => {
        if (createdCompany?.id) {
          // push new company into the companies and sort by name
          this.allCompanies = sortBy([...this.allCompanies, createdCompany], (company: Company) =>
            company?.name?.toLowerCase()
          );
          void this.updateCompany(createdCompany);
          void this.companyAutoInput.closePanel();
        }
      });
  }

  public isCompanyAllowed(company: Company): boolean {
    return (
      company.verification_status === CompanyVerificationStatus.Pending ||
      (company.verification_status === CompanyVerificationStatus['UHAT Only'] &&
        (this.arf?.module?.workspace_type_id === WorkspaceType.Uhat ||
          this.arf?.module?.workspace_type_id === WorkspaceType.UhatAdmin)) ||
      company.verification_status === CompanyVerificationStatus['1CALL & UHAT']
    );
  }

  public changeDialogOpenStatus(value = false): void {
    this.isDialogOpen = value;
  }

  updatePreferences() {
    this.preferences.setPartialValue({
      fieldToSortBy: this.fieldToSortBy,
      sortDirection: this.sortDirection,
    });
  }

  changeSort(event, fieldToSortBy) {
    event.stopPropagation();
    if (this.fieldToSortBy === fieldToSortBy) {
      this.sortDirection = this.sortDirection === 'asc' ? (this.sortDirection = 'desc') : 'asc';
    } else {
      this.fieldToSortBy = fieldToSortBy;
    }
    this.updatePreferences();
    this.paginator.firstPage();
  }

  public updateProducts(newProduct: ArfProduct): void {
    // only update it if the product is not already updated
    if (newProduct?.id && !this.allProducts?.find((product: ArfProduct) => +product.id === +newProduct.id)) {
      // insert new product and sort by name
      this.allProducts = sortBy([...this.allProducts, newProduct], [(product) => product?.name?.toLowerCase()]);
    }
  }

  openProductListDialog(): void {
    this.modalService.openProductDialog({ showList: true, workspaceId: this.arf.module.id }).subscribe((product) => {
      if (product) {
        void this.getAllProducts();
      }
    });
  }
}

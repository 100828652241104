import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { FileService } from 'src/app/services';
import { UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-file-thumbnail',
  templateUrl: './file-thumbnail.component.html',
  styleUrls: ['./file-thumbnail.component.scss'],
})
export class FileThumbnailComponent {
  @Input() public borderActive: boolean;
  @Input() public file: UhatFileReference;
  @Input() public height = 30;
  @Input() public noBorder: boolean;
  @Input() public overlayIcon;
  @Input() public overlayIconBackground = 'green';
  @Input() public overlayIconColor = 'green';
  @Input() public overlayIconOpacity = 1;
  @Input() public overlayIconTooltip = '';
  @Input() public width = 30;

  @Output() public overlayIconClickEvent = new EventEmitter();

  public isLoaded: boolean;
  public supportedExtensions: string[] = [
    'doc',
    'docx',
    'jpeg',
    'jpg',
    'pdf',
    'ppt',
    'pptx',
    'png',
    'xls',
    'xlsx',

    'heic',
    'HEIC',
    'dwg',
    'eps',
    'psd',
    'xd',
    'svg',
    '.ai',
    'dng',
    'gif',
  ];
  public thumbnailImage$: Observable<SafeResourceUrl>;
  public unSupportedImageExtensions: string[] = ['tiff', 'bmp'];

  constructor(private _fileService: FileService, private elRef: ElementRef) {}

  ngAfterViewInit() {
    const observer = new IntersectionObserver(([entry]) => {
      if (this.isSupportedExtension && !this.isLoaded && entry.isIntersecting) {
        this._loadThumbnail();
        observer.unobserve(this.elRef.nativeElement);
      }
    });
    observer.observe(this.elRef.nativeElement);
  }

  get extension(): string {
    if (this.file?.extension) {
      return this.file?.extension.toLowerCase();
    }

    // get it from the name
    const nameParts = this.file?.name?.split('.');
    // To capture names without extensions
    return nameParts?.length > 1 ? nameParts.pop() : '';
  }

  get extensions(): string[] {
    return [...this.supportedExtensions, ...this.unSupportedImageExtensions, 'cvs', 'txt', 'zip'];
  }

  get isCsv(): boolean {
    return this.extension === 'csv';
  }

  get isTxt(): boolean {
    return this.extension === 'txt';
  }

  get isSupportedExtension(): boolean {
    return this.extension && this.supportedExtensions.indexOf(this.extension) !== -1;
  }

  get isUnKnownFileType(): boolean {
    return this.extensions.indexOf(this.extension) === -1;
  }

  get isUnSupportedImage(): boolean {
    return this.unSupportedImageExtensions.indexOf(this.extension) !== -1;
  }

  get isZip(): boolean {
    return this.extension === 'zip';
  }

  private _loadThumbnail() {
    this.thumbnailImage$ = this._fileService.getCachedFileThumbnailPreview(this.file);
    this.thumbnailImage$.subscribe((base64Value) => {
      this.isLoaded = base64Value && base64Value.toString().length > 0;
    });
  }

  public onIconClick() {
    this.overlayIconClickEvent.emit();
  }
}

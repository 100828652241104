import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BugReportDialogComponent, ConfirmationDialogComponent } from 'src/app/components';
import { RequestAccountDialogComponent } from 'src/app/components/';
import { ModalService } from 'src/app/services';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  errorMessage;
  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private userService: UserService,
    private modalService: ModalService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  imageNumber: number;

  loginFormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
    staySignedIn: [true, [Validators.required]],
  });

  get email() {
    return this.loginFormGroup.get('email');
  }
  get password() {
    return this.loginFormGroup.get('password');
  }
  get staySignedIn() {
    return this.loginFormGroup.get('staySignedIn');
  }

  ngOnInit(): void {
    this.imageNumber = Math.floor(Math.random() * 7 + 1);
    this.authService.removeTokens();
    // commenting this out until we can fix it not working on iPad
    // const browserName = this.getBrowserName();
    // if (browserName !== 'chrome') {
    //   this.router.navigateByUrl(`/browser-not-supported`);
    // }
  }

  login(): void {
    const formCredentials = this.loginFormGroup.getRawValue();
    const credentialsToSubmit = {
      email: formCredentials.email,
      password: formCredentials.password,
    };
    this.authService.login(credentialsToSubmit, this.staySignedIn.value).subscribe(
      () => {},
      (error) => {
        // clear password and trigger error
        this.errorMessage = error.error.message || `Invalid Credentials`;
        if (error.status === 0 && error.error instanceof ProgressEvent) {
          // A client-side or network error occurred.
          this.errorMessage = navigator.onLine === true ? 'No Internet Connection' : 'Connection Error';
        }
        this.snackbar.open(this.errorMessage);

        if (error.error.message === 'Account not yet created') {
          this.requestAccount(this.email.value);
        } else {
          this.password.setValue(null);
          this.password.setErrors({ invalid: true });
        }
      },
      () => {
        // get return url from query parameters or default to home page
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigateByUrl(returnUrl);
      }
    );
  }

  openPasswordResetDialog() {
    this.modalService.openPasswordResetDialog(this.email.value);
  }

  requestAccount(email?: string) {
    const requestAccountDialog = this.dialog.open(RequestAccountDialogComponent, {
      width: '540px',
      data: {
        email,
      },
    });

    requestAccountDialog.afterClosed().subscribe(async (result) => {});
  }

  openBugReportDialog() {
    const dialogRef = this.dialog.open(BugReportDialogComponent, {
      width: '540px',
    });
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(window as any).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  public loginHelp() {
    this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      data: {
        titleBarText: 'Help Me Sign In',
        headerText: 'Need help signing in?',
        hideConfirmationButton: true,
        descriptionText: `If you have not received an invite to set up your account, you will need to click <b>Request Account</b> at the bottom of this page and fill out your information.<br><br>A 1CALL admin will review your information and determine your level of access. If approved, you will receive a follow-up email to login and finish setting up your account.<br><br>If you're still having trouble, use the <strong>Contact Support</strong> link below.`,
        cancelButtonText: 'Dismiss',
      },
    });
  }
}

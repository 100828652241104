import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep, orderBy, sortBy } from 'lodash';
import { BuildingManagerComponent, DepartmentManagerComponent, SuitesManagerComponent } from 'src/app/components';
import { Workspace } from 'src/app/enums';
import {
  AuthService,
  ModalService,
  ModuleService,
  NotificationService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTemplateService,
  SearchService,
  SidenavService,
  TopicService,
  WorkOrderService,
} from 'src/app/services';
import {
  APIFilter,
  Milestone,
  Phase,
  ProjectTemplate,
  ProjectTemplateMilestone,
  ProjectTemplatePhase,
  ProjectTemplateTask,
  Topic,
  TopicAccess,
  TopicCategory,
  TopicGroup,
  TopicType,
  WorkOrderPriority,
} from 'src/app/types';
import { getDefaultRanks, getRankBetween } from 'src/app/utils';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-workspace-settings',
  templateUrl: './workspace-settings.component.html',
  styleUrls: ['./workspace-settings.component.scss'],
})
export class WorkspaceSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('buildingManager') buildingManager: BuildingManagerComponent;
  @ViewChild('suiteManager') suiteManager: SuitesManagerComponent;
  @ViewChild('departmentManager') departmentManager: DepartmentManagerComponent;
  constructor(
    public notificationService: NotificationService,
    private progressIndicatorService: ProgressIndicatorService,
    public searchService: SearchService,
    private _sidenavService: SidenavService,
    private projectTemplateService: ProjectTemplateService,
    private moduleService: ModuleService,
    private modalService: ModalService,
    private projectService: ProjectService,
    private snackbar: MatSnackBar,
    private topicService: TopicService,
    private workOrderService: WorkOrderService,
    private ref: ChangeDetectorRef,
    private authService: AuthService
  ) {}

  @ViewChildren('taskTitle') taskTitle: QueryList<ElementRef>;
  @ViewChildren('milestoneTitle') milestoneTitle: QueryList<ElementRef>;
  private projectTemplateFields: string[] = [
    'id',
    'name',
    'is_default',
    'phases{type_id,type_sequence,name,milestones{name,sequence,tasks{title,sequence,description,rank}}}',
  ];
  public topicTypes: TopicType[];
  public topicGroups: TopicGroup[];
  public workOrderPriorities: WorkOrderPriority[];
  public topicManagerPage: 'groups' | 'categories' | 'topics' = 'groups';
  public selectedTopicGroup: TopicGroup;
  public selectedTopicCategory: TopicCategory;
  public selectedTopic: Topic;
  public topicAccess: TopicAccess[];
  public topicCategoryDraft: TopicCategory;
  public topicDraft: Topic;
  public isWorkspaceTopicEditor = false;

  public projectTemplates: ProjectTemplate[];
  private currentSubscription;
  private shownWorkspaceId: number;
  public selectedSettingIndex = 0;
  public settingTabs = [
    { index: 0, label: 'Request Topics' },
    { index: 1, label: 'Task Templates' },
  ];
  public editingTemplate = false;
  public selectedTemplate = null;
  private phaseTypes;
  private focusOnLastTaskFromMilestoneId = null;
  private focusOnLastMilestoneFromPhaseId = null;
  private hoverOptions = {};
  public selectedTemplateTitleLength = 50;

  get isSidenavClosed(): boolean {
    return this._sidenavService.isSidenavClosed;
  }

  get workspace() {
    return this.moduleService.workspace;
  }

  // TODO should be removed since its only used once
  // RANK LOGIC
  /*
  public async addTaskRank() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Adding ranks to tasks...');
    const projectTemplates = await this.projectTemplateService
      .getProjectTemplates(this.projectTemplateFields, [])
      .toPromise();
    for (const projectTemplate of projectTemplates) {
      const phases = projectTemplate.phases.map((phase) => {
        const milestones = phase.milestones.map((milestone) => {
          if (milestone.tasks.length && milestone.tasks.every((task) => !task.rank)) {
            const ranks = getDefaultRanks(milestone.tasks.length);
            const tasks = milestone.tasks.map((task, index) => ({ ...task, rank: ranks[index] }));
            // mutate the takses
            milestone.tasks = tasks;
          }
          return milestone;
        });
        // mutate the milestones
        phase.milestones = milestones;
        return phase;
      });
      const templateToUpdate = {
        name: projectTemplate.name,
        phases,
      };
      // update templates with tasks
      if (phases[0]?.milestones[0]?.tasks.length) {
        await this.projectTemplateService.updateProjectTemplate(projectTemplate.id, templateToUpdate).toPromise();
      }
    }
    this.progressIndicatorService.close();
  }
*/
  get isBuildingsDepartmentsSuitesManager() {
    return this.authService.isBuildingsDepartmentsSuitesManager;
  }

  async ngOnInit() {
    await this.refresh();
    // keep track of subscription so we can destroy it when the component is destroyed
    this.currentSubscription = this.moduleService.selectWorkspaceEvent.subscribe(async (workspace) => {
      if (this.shownWorkspaceId !== workspace.id) {
        await this.refresh();
      }
    });
    this.taskTitle.changes.subscribe(() => {
      if (this.focusOnLastTaskFromMilestoneId) {
        const tasksForFocus = this.taskTitle.filter(
          (t) => t.nativeElement.task.project_template_milestone_id === this.focusOnLastTaskFromMilestoneId
        );
        tasksForFocus[tasksForFocus.length - 1].nativeElement.focus();
        this.focusOnLastTaskFromMilestoneId = null;
        this.ref.detectChanges();
      }
    });
    this.milestoneTitle.changes.subscribe(() => {
      if (this.focusOnLastMilestoneFromPhaseId) {
        const milestonesForFocus = this.milestoneTitle.filter(
          (m) => m.nativeElement.milestone.project_template_phase_id === this.focusOnLastMilestoneFromPhaseId
        );
        milestonesForFocus[milestonesForFocus.length - 1].nativeElement.focus();
        this.focusOnLastMilestoneFromPhaseId = null;
        this.ref.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }
  }

  async refresh() {
    if (this.workspace?.id) {
      this.isWorkspaceTopicEditor =
        this.authService.isUserWorkspaceAdmin(this.workspace.id) ||
        this.authService.isWorkspaceTopicEditor(this.workspace.id);
    }
    switch (this.selectedSettingIndex) {
      case 0:
        await this.refreshTopicManager();
        break;
      case 1:
        await this.refreshTemplateBuilder();
        break;
      case 2:
        this.buildingManager.getBuildings();
        break;
      case 3:
        this.suiteManager.getSuites();
        break;
      case 4:
        this.departmentManager.getDepartments();
        break;
    }
  }

  async settingTabChange(event) {
    const settingIndex: number = event.index;
    if (settingIndex >= 0) {
      await this.refresh();
    }
  }

  async refreshTopicManager() {
    if (this.workspace?.id) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Loading Data..');
      this.topicTypes = await this.topicService.getTopicTypes(['id', 'name']).toPromise();
      this.topicAccess = await this.topicService.getTopicAccess(['id', 'name']).toPromise();
      this.workOrderPriorities = await this.workOrderService
        .getWorkOrderPriorities(['id', 'name', 'abbreviation'])
        .toPromise();
      const topicGroupFilter = [{ type: 'field', field: 'managing_workspace_id', value: this.workspace.id.toString() }];
      let topicGroups = sortBy(
        await this.topicService
          .getTopicGroups(this.topicService.workspaceSettingsTopicGroupFields, topicGroupFilter)
          .toPromise(),
        (g) => g.name
      );
      topicGroups = topicGroups.filter((g) => g.is_enabled);
      for (const g of topicGroups) {
        g.topic_categories = sortBy(
          g.topic_categories.filter((c) => c.is_enabled),
          (c) => c.name
        );
        for (const c of g.topic_categories) {
          c.topics = sortBy(
            c.topics.filter((t) => t.is_enabled),
            (t) => t.name
          );
          for (const t of c.topics) {
            t.visible_to = JSON.parse(t.visible_to);
            this.getTopicVisibleToLabels(t);
          }
        }
      }
      if (this.selectedTopicGroup) {
        const foundGroup = topicGroups.find((g) => g.id === this.selectedTopicGroup?.id);
        if (foundGroup) {
          this.selectedTopicGroup = foundGroup;
          if (this.selectedTopicCategory) {
            const foundCategory = this.selectedTopicGroup.topic_categories.find(
              (c) => c.id === this.selectedTopicCategory?.id
            );
            if (foundCategory) {
              this.selectedTopicCategory = foundCategory;
            } else {
              this.selectedTopicCategory = null;
              this.topicManagerPage = 'categories';
            }
          }
        } else {
          this.selectedTopicGroup = null;
          this.topicManagerPage = 'groups';
        }
      }
      this.topicGroups = topicGroups;
      this.progressIndicatorService.close();
    }
  }

  getTopicVisibleToLabels(topic: Topic) {
    if (topic.visible_to && topic.visible_to.length === 0) {
      topic.visibleToLabels = 'No one';
    } else {
      topic.visibleToLabels = topic.visible_to_access
        ? topic.visible_to_access.map((v) => v.name).join(', ')
        : 'Everyone';
    }
  }

  selectTopicGroup(group: TopicGroup) {
    this.selectedTopicGroup = group;
    this.selectedTopicCategory = null;
    this.selectedTopic = null;
    if (group) {
      this.topicManagerPage = 'categories';
    } else {
      this.topicManagerPage = 'groups';
    }
  }

  selectTopicCategory(category: TopicCategory) {
    this.selectedTopicCategory = category;
    this.selectedTopic = null;
    if (category) {
      this.topicManagerPage = 'topics';
    } else {
      this.topicManagerPage = 'categories';
    }
  }

  editTopicGroup(group: TopicGroup) {
    group.draft = { name: group.name };
  }

  async saveTopicGroupChanges(group: TopicGroup) {
    if (group.draft.name) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Updating Topic Group..');
      const updatedTopic = await this.topicService.updateTopicGroup(group?.id, { name: group.draft.name }).toPromise();
      group.name = updatedTopic.name;
      group.draft = null;
      this.progressIndicatorService.close();
    } else {
      this.snackbar.open('Please fill out all required fields');
    }
  }

  discardTopicGroupChanges(group: TopicGroup) {
    group.draft = null;
  }

  disableTopicGroup(group: TopicGroup) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: `Delete ${group.name}`,
        descriptionText:
          'Are you sure you want to delete this topic group? Existing requests and work orders will not be affected, but this group will no longer be selectable for new items.',
        confirmationButtonText: `Delete Group`,
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Deleting Topic Group..');
          await this.topicService.updateTopicGroup(group?.id, { is_enabled: 0 }).toPromise();
          this.snackbar.open(`Topic group deleted!`);
          this.refreshTopicManager();
          this.progressIndicatorService.close();
        }
      });
  }

  addTopicCategory() {
    this.topicCategoryDraft = {};
  }

  async saveTopicCategoryDraft() {
    if (this.selectedTopicGroup?.id && this.topicCategoryDraft.name) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving Topic Category..');
      const categoryToCreate: TopicCategory = {
        topic_group_id: this.selectedTopicGroup.id,
        name: this.topicCategoryDraft.name,
      };
      const createdTopicCategory = await this.topicService
        .createTopicCategory(categoryToCreate, this.topicService.workspaceSettingsTopicCategoryFields)
        .toPromise();
      const categories = [...this.selectedTopicGroup.topic_categories, createdTopicCategory];
      this.selectedTopicGroup.topic_categories = sortBy(categories, (c) => c.name);
      this.topicCategoryDraft = null;
      this.progressIndicatorService.close();
    } else {
      this.snackbar.open('Please fill out all required fields');
    }
  }

  discardTopicCategoryDraft() {
    this.topicCategoryDraft = null;
  }

  editTopicCategory(category: TopicCategory) {
    category.draft = { name: category.name };
  }

  async saveTopicCategoryChanges(category: TopicCategory) {
    if (category.draft.name) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Updating Topic Category..');
      const updatedTopicCategory = await this.topicService
        .updateTopicCategory(category?.id, { name: category.draft.name })
        .toPromise();
      category.name = updatedTopicCategory.name;
      category.draft = null;
      this.progressIndicatorService.close();
    } else {
      this.snackbar.open('Please fill out all required fields');
    }
  }

  discardTopicCategoryChanges(category: TopicCategory) {
    category.draft = null;
  }

  disableTopicCategory(category: TopicCategory) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: `Delete ${category.name}`,
        descriptionText:
          'Are you sure you want to delete this topic category and the associated topics? Existing requests and work orders will not be affected, but this category will no longer be selectable for new items.',
        confirmationButtonText: `Delete Category`,
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Deleting Topic Category..');
          await this.topicService.updateTopicCategory(category?.id, { is_enabled: 0 }).toPromise();
          this.snackbar.open(`Topic category deleted!`);
          this.refreshTopicManager();
          this.progressIndicatorService.close();
        }
      });
  }

  async openTopicDialog(topic = null) {
    if (!topic?.id) {
      topic = {
        topic_category_id: this.selectedTopicCategory?.id,
        workspace_id: this.workspace?.id,
      };
    }
    const savedTopic = await this.modalService.openTopicDialog(topic).toPromise();
    if (savedTopic) {
      savedTopic.visible_to = JSON.parse(savedTopic.visible_to);
      this.getTopicVisibleToLabels(savedTopic);
      if (topic?.id) {
        const topicIndex = this.selectedTopicCategory.topics.findIndex((t) => t.id === topic.id);
        this.selectedTopicCategory.topics[topicIndex] = savedTopic;
      } else {
        const topics = [...this.selectedTopicCategory.topics, savedTopic];
        this.selectedTopicCategory.topics = sortBy(topics, (t) => t.name);
      }
    }
  }

  addTopic() {
    this.topicDraft = {};
  }

  async saveTopicDraft() {
    if (this.selectedTopicCategory?.id && this.workspace?.id && this.topicDraft.name && this.topicDraft.topic_type_id) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving Topic..');
      const topicToCreate: Topic = {
        topic_category_id: this.selectedTopicCategory.id,
        name: this.topicDraft.name,
        topic_type_id: this.topicDraft.topic_type_id,
        workspace_id: this.workspace?.id,
        visible_to: this.topicDraft.visible_to ? JSON.stringify(this.topicDraft.visible_to) : null,
        selectable_by: this.topicDraft.visible_to ? JSON.stringify(this.topicDraft.visible_to) : null,
        priority_id: this.topicDraft.priority_id,
      };
      const createdTopic = await this.topicService
        .createTopic(topicToCreate, this.topicService.workspaceSettingsTopicFields)
        .toPromise();

      createdTopic.visible_to = JSON.parse(createdTopic.visible_to);
      this.getTopicVisibleToLabels(createdTopic);
      const topics = [...this.selectedTopicCategory.topics, createdTopic];
      this.selectedTopicCategory.topics = sortBy(topics, (t) => t.name);
      this.topicDraft = null;
      this.progressIndicatorService.close();
    } else {
      this.snackbar.open('Please fill out all required fields');
    }
  }

  discardTopicDraft() {
    this.topicDraft = null;
  }

  editTopic(topic: Topic) {
    topic.draft = {
      name: topic.name,
      visible_to: topic.visible_to,
      topic_type_id: topic.topic_type_id,
      priority_id: topic.priority_id,
    };
  }

  setTopicAccessToEveryone(draft: any) {
    draft.visible_to = null;
  }

  setTopicPriorityToNull(draft: any) {
    draft.priority_id = null;
  }

  async saveTopicChanges(topic: Topic) {
    if (topic.draft.name && (topic.draft.topic_type_id ?? topic.topic_type_id)) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Updating Topic..');
      const updatedTopic = await this.topicService
        .updateTopic(
          topic?.id,
          {
            name: topic.draft.name,
            topic_type_id: topic.draft.topic_type_id ?? topic.topic_type_id,
            visible_to: topic.draft.visible_to ? JSON.stringify(topic.draft.visible_to) : null,
            priority_id: topic.draft.priority_id,
            selectable_by: topic.draft.visible_to ? JSON.stringify(topic.draft.visible_to) : null,
          },
          this.topicService.workspaceSettingsTopicFields
        )
        .toPromise();
      topic.name = updatedTopic.name;
      topic.topic_type_id = updatedTopic.topic_type_id;
      topic.topic_type = updatedTopic.topic_type;
      topic.priority_id = updatedTopic.priority_id;
      topic.work_order_priority = updatedTopic.work_order_priority;
      topic.visible_to = JSON.parse(updatedTopic.visible_to);
      topic.visible_to_access = updatedTopic.visible_to_access;
      this.getTopicVisibleToLabels(topic);
      topic.draft = null;
      this.progressIndicatorService.close();
    } else {
      this.snackbar.open('Please fill out all required fields');
    }
  }

  discardTopicChanges(topic: Topic) {
    topic.draft = null;
  }

  disableTopic(topic: Topic) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: `Delete ${topic.name}`,
        descriptionText:
          'Are you sure you want to delete this topic? Existing requests and work orders will not be affected, but this topic will no longer be selectable for new items.',
        confirmationButtonText: `Delete Topic`,
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Deleting Topic..');
          await this.topicService.updateTopic(topic?.id, { is_enabled: 0 }).toPromise();
          this.snackbar.open(`Topic deleted!`);
          this.refreshTopicManager();
          this.progressIndicatorService.close();
        }
      });
  }

  menuEnter(topicId) {
    this.hoverOptions[topicId].isMatMenuOpen = true;
  }

  menuLeave(trigger, topicId) {
    setTimeout(() => {
      if (!this.hoverOptions[topicId]?.enteredButton) {
        this.hoverOptions[topicId].isMatMenuOpen = false;
        trigger.closeMenu();
      } else {
        this.hoverOptions[topicId].isMatMenuOpen = false;
      }
    }, 80);
  }

  buttonEnter(trigger, topicId) {
    setTimeout(() => {
      if (!this.hoverOptions[topicId]?.isMatMenuOpen) {
        this.hoverOptions[topicId] = { ...this.hoverOptions[topicId], ...{ enteredButton: true } };
        trigger.openMenu();
      } else {
        this.hoverOptions[topicId] = { ...this.hoverOptions[topicId], ...{ enteredButton: true } };
      }
    });
  }

  buttonLeave(trigger, topicId) {
    setTimeout(() => {
      if (this.hoverOptions[topicId]?.enteredButton && !this.hoverOptions[topicId]?.isMatMenuOpen) {
        trigger.closeMenu();
      }
      if (!this.hoverOptions[topicId]?.isMatMenuOpen) {
        trigger.closeMenu();
      } else {
        this.hoverOptions[topicId].enteredButton = false;
      }
    }, 200);
  }

  async refreshTemplateBuilder() {
    if (this.workspace?.id) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Loading Data..');
      this.shownWorkspaceId = this.workspace?.id;
      const templateFilters: APIFilter[] = [
        { type: 'field', field: 'workspace_id', value: this.workspace?.id.toString() },
      ];
      const phaseTypes = await this.projectService
        .getPhaseTypes(['id', 'name', 'workspace_id', 'sequence', 'is_default'])
        .toPromise();
      this.phaseTypes = phaseTypes.filter(
        (pt) => pt.workspace_id === this.workspace?.id || (this.workspace?.id !== 1 && !pt.workspace_id)
      );
      const projectTemplates = await this.projectTemplateService
        .getProjectTemplates(this.projectTemplateFields, templateFilters)
        .toPromise();

      for (const t of projectTemplates) {
        this.getTemplateCounts(t);
      }
      this.projectTemplates = projectTemplates;
      if (this.selectedTemplate?.id) {
        const foundTemplate = this.projectTemplates.find((t) => t.id === this.selectedTemplate?.id);
        this.selectTemplate(foundTemplate ?? null);
      }
      this.progressIndicatorService.close();
    }
  }

  getTemplateCounts(template: ProjectTemplate) {
    if (template) {
      let milestoneCount = 0;
      let taskCount = 0;
      for (const p of template.phases || []) {
        let phaseMilestoneCount = 0;
        for (const m of p.milestones || []) {
          milestoneCount++;
          phaseMilestoneCount++;
          taskCount += (m.tasks || []).length;
        }
        p.milestoneCount = phaseMilestoneCount;
      }
      template.milestoneCount = milestoneCount;
      template.taskCount = taskCount;
    }
  }

  newTemplate() {
    const newTemplate = {
      milestoneCount: 0,
      taskCount: 0,
    };
    this.selectTemplate(newTemplate);
    this.editTemplate();
  }

  selectTemplate(templateToSelect: ProjectTemplate) {
    const template = cloneDeep(templateToSelect);
    if (template) {
      for (const p of template.phases ?? []) {
        for (const m of p?.milestones ?? []) {
          // sort by rank
          m.tasks = orderBy(m.tasks, (task) => task.rank, ['asc']);
          for (const t of m?.tasks ?? []) {
            t.showDescription = !!t.description;
          }
        }
      }
      for (const pt of this.phaseTypes) {
        if (!template.phases) {
          template.phases = [];
        }
        const foundPhase = template.phases.find((p) => p.type_id === pt.id);
        if (!foundPhase) {
          template.phases.push({
            type_id: pt.id,
            type_sequence: pt.sequence,
            name: pt.name,
            milestones: [],
          });
        }
      }
      template.phases = sortBy(template.phases, (p) => p.type_sequence);
      // These don't appear to be hooked up properly.
      let sequence = 1;
      for (const p of template.phases) {
        p.sequence = sequence;
        sequence++;
      }
    }
    this.editingTemplate = false;
    this.selectedTemplate = template ?? null;
  }
  public async editTemplate(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading...');
    // track if you need to update
    let needsToUpdate = false;
    const phases = this.selectedTemplate?.phases?.map((phase: ProjectTemplatePhase) => {
      const milestones = phase.milestones?.map((milestone: ProjectTemplateMilestone) => {
        // check if one milestone gruop needs ranking
        if (milestone.tasks.length && !milestone?.tasks?.every((task: ProjectTemplateTask) => task.rank)) {
          needsToUpdate = true;
          const ranks = getDefaultRanks(milestone.tasks.length);
          const tasks = milestone.tasks.map((task: ProjectTemplateTask, index) => ({ ...task, rank: ranks[index] }));
          // mutate the takses
          milestone.tasks = tasks;
        }
        return milestone;
      }); // end of milestone
      phase.milestones = milestones;
      return phase;
    }); // end of phases

    if (needsToUpdate) {
      const templateToUpdate = {
        ...this.selectedTemplate,
        phases,
      };
      // update the template
      this.selectedTemplate = await this.projectTemplateService
        .updateProjectTemplate(this.selectedTemplate.id, templateToUpdate)
        .toPromise();
    }

    this.editingTemplate = true;
    this.progressIndicatorService.close();
  }

  createMilestone(phase: ProjectTemplatePhase) {
    if (phase) {
      if (!phase?.id && !phase?.tempId) {
        phase.tempId = uuidv4();
      }
      if (!phase?.milestones) {
        phase.milestones = [];
      }
      phase.milestones.push({
        tempId: uuidv4(),
        name: null,
        project_template_phase_id: phase.id ?? phase.tempId,
      });
    }
    this.getTemplateCounts(this.selectedTemplate);
    this.focusOnLastMilestoneFromPhaseId = phase.id ?? phase.tempId;
  }

  deleteMilestone(milestone: ProjectTemplateMilestone, phase: ProjectTemplatePhase, milestoneIndex: number) {
    if (milestone.id) {
      milestone.should_delete = true;
    } else {
      phase.milestones.splice(milestoneIndex, 1);
    }
    this.getTemplateCounts(this.selectedTemplate);
  }

  createTask(milestone: ProjectTemplateMilestone) {
    if (milestone) {
      if (!milestone?.tasks) {
        milestone.tasks = [];
      }

      milestone.tasks.push({
        title: null,
        description: null,
        project_template_milestone_id: milestone.id ?? milestone.tempId,
        rank: getRankBetween(milestone.tasks[milestone.tasks.length - 1]?.rank), // this will add a rank to the added task
      });
    }
    this.getTemplateCounts(this.selectedTemplate);
    this.focusOnLastTaskFromMilestoneId = milestone.id ?? milestone.tempId;
  }

  deleteTask(task: ProjectTemplateTask, milestone: ProjectTemplateMilestone, taskIndex: number) {
    if (task.id) {
      task.should_delete = true;
    } else {
      milestone.tasks.splice(taskIndex, 1);
    }
    this.getTemplateCounts(this.selectedTemplate);
  }

  toggleDescription(task) {
    task.showDescription = !task.showDescription;
    if (!task.showDescription) {
      task.description = null;
    }
  }

  discardTemplateChanges() {
    if (this.selectedTemplate?.id) {
      const foundTemplate = this.projectTemplates.find((t) => t.id === this.selectedTemplate?.id);
      this.selectTemplate(foundTemplate ?? null);
    } else {
      this.selectTemplate(null);
    }
    this.editingTemplate = false;
  }

  isTemplateValid() {
    // calculate the count since it can vary depending on the users actions
    const selectedMilestoneCount = this.selectedTemplate?.phases?.reduce(
      (total: number, currentPhase: ProjectTemplatePhase) =>
        total +
          currentPhase?.milestones?.filter((milestone: ProjectTemplateMilestone) => !milestone.should_delete)?.length ||
        0,
      0
    );
    let invalidTemplateMsg = '';
    let invalidTitle74MilestoneMsg = '';
    let invalidMilestoneCount = 0;
    let invalidTaskCount = 0;
    if (!this.selectedTemplate?.name?.trim()?.length) {
      invalidTemplateMsg = `Template name must not be empty`;
    } else if (this.selectedTemplate?.name?.trim()?.length > this.selectedTemplateTitleLength) {
      invalidTemplateMsg = `Template name length must not exceed ${this.selectedTemplateTitleLength}`;
    } else if (+this.workspace?.id !== Workspace.Construction && !selectedMilestoneCount) {
      // none construction check for at least a milestone
      invalidTitle74MilestoneMsg = `A template must have at least one milestone!`;
    } else {
      for (const p of this.selectedTemplate?.phases ?? []) {
        for (const m of p?.milestones ?? []) {
          if (!m?.name?.trim()) {
            invalidMilestoneCount++;
          }
          for (const t of m?.tasks ?? []) {
            if (!t?.title?.trim()) {
              invalidTaskCount++;
            }
          }
        }
      }
    }

    if (invalidTemplateMsg?.trim()?.length) {
      this.snackbar.open(invalidTemplateMsg);
    } else if (invalidTitle74MilestoneMsg?.trim()?.length) {
      this.snackbar.open(invalidTitle74MilestoneMsg);
    } else if (invalidMilestoneCount > 0) {
      this.snackbar.open(`All milestone names must be not be empty`);
    } else if (invalidTaskCount > 0) {
      this.snackbar.open(`All task names must not be empty`);
    }
    const valid =
      invalidTemplateMsg?.length + invalidTitle74MilestoneMsg?.length + invalidMilestoneCount + invalidTaskCount === 0;
    return valid;
  }

  async saveTemplate() {
    let templateId;
    if (this.selectedTemplate && this.isTemplateValid()) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving Template..');
      if (this.selectedTemplate?.id) {
        templateId = this.selectedTemplate.id;
        const templateToUpdate = {
          name: this.selectedTemplate.name?.trim(),
          phases: this.selectedTemplate?.phases,
        };
        await this.projectTemplateService
          .updateProjectTemplate(this.selectedTemplate?.id, templateToUpdate)
          .toPromise();
        this.snackbar.open(`Project template updated!`);
      } else {
        if (this.workspace?.id) {
          const templateToCreate = {
            name: this.selectedTemplate?.name?.trim(),
            workspace_id: this.workspace?.id,
            phases: this.selectedTemplate?.phases,
          };
          const createdTemplate = await this.projectTemplateService.createProjectTemplate(templateToCreate).toPromise();
          templateId = createdTemplate.id;
          this.snackbar.open(`Project template created!`);
        }
      }
      this.editingTemplate = false;
      await this.refreshTemplateBuilder();
      this.progressIndicatorService.close();
      const foundTemplate = this.projectTemplates.find((t) => t.id === templateId);
      this.selectTemplate(foundTemplate ?? null);
    }
  }

  deleteTemplate() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Task Template',
        headerText: 'Delete Task Template',
        descriptionText: 'Are you sure you want to delete this task template? This action cannot be undone.',
        confirmationButtonText: 'Delete Task Template',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed && this.selectedTemplate?.id) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Deleting Template..');
          await this.projectTemplateService.deleteProjectTemplate(this.selectedTemplate?.id).toPromise();
          this.snackbar.open(`Project template deleted!`);
          await this.refreshTemplateBuilder();
          this.progressIndicatorService.close();
          this.selectTemplate(null);
        }
      });
  }

  public async drop(event: CdkDragDrop<string[]>, phase: Phase, milestone: Milestone) {
    // get the phase index
    const phaseIndex = this.selectedTemplate.phases
      .map((selectedPhase: Phase) => selectedPhase.id)
      .indexOf(Number(phase.id));

    // get the milestone index
    const mileStoneIndex = this.selectedTemplate.phases[phaseIndex].milestones
      .map((selectedMilestone) => selectedMilestone.id)
      .indexOf(Number(milestone.id));

    // use the phase and milestone index to locate the template tasks
    moveItemInArray(
      this.selectedTemplate.phases[phaseIndex].milestones[mileStoneIndex].tasks,
      event.previousIndex,
      event.currentIndex
    );

    //  get the ranks of the tasks
    const beforeTaskRank =
      this.selectedTemplate.phases[phaseIndex].milestones[mileStoneIndex].tasks[event.currentIndex - 1]?.rank;
    const afterTaskRank =
      this.selectedTemplate.phases[phaseIndex].milestones[mileStoneIndex].tasks[event.currentIndex + 1]?.rank;

    // when you save, the order will also be saved
    this.selectedTemplate.phases[phaseIndex].milestones[mileStoneIndex].tasks[event.currentIndex].rank = getRankBetween(
      beforeTaskRank,
      afterTaskRank
    );
  }
}

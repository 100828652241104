<div #mainScreen data-test-id="workspace-settings" id="workspace-settings" class="workspace-settings">
  <header
    [ngClass]="{
      'nav-closed': isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
      'nav-open': !isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
      'notifications-sidenav-open':
        (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && !isSidenavClosed,
      'notifications-sidenav-closed':
        (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && isSidenavClosed
    }"
    class="d-flex px-4 ease align-items-center"
  >
    <h1 *ngIf="workspace" class="dkblue font-weight-semibold mb-0 mr-auto text-nowrap mr-4">
      Settings
      <small class="ml-2 dkgray">
        {{ workspace.name }}
      </small>
    </h1>
    <button mat-stroked-button color="primary" type="button" routerLink="/dashboard">Back to Dashboard</button>
  </header>
  <main
    [ngClass]="{
      'nav-closed': isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
      'nav-open': !isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
      'notifications-sidenav-open':
        (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && !isSidenavClosed,
      'notifications-sidenav-closed':
        (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && isSidenavClosed
    }"
    class="d-flex ease"
  >
    <div class="container py-4">
      <mat-tab-group [(selectedIndex)]="selectedSettingIndex" (selectedTabChange)="settingTabChange($event)">
        <mat-tab>
          <ng-template mat-tab-label>{{ settingTabs[0].label }}</ng-template>
          <div class="d-flex flex-column align-items-top">
            <div class="d-flex align-items-center">
              <h4 class="dkblue my-5 d-flex align-items-center mr-auto">
                <span
                  [ngClass]="{ 'hover-u pointer ltblue': topicManagerPage !== 'groups' }"
                  (click)="topicManagerPage !== 'groups' ? selectTopicGroup(null) : null"
                >
                  {{ topicManagerPage === 'groups' ? 'Groups' : selectedTopicGroup.name }}
                </span>
                <mat-icon class="gray mx-3" *ngIf="topicManagerPage !== 'groups'"> chevron_right </mat-icon>
                <span
                  *ngIf="topicManagerPage !== 'groups'"
                  [ngClass]="{ 'hover-u pointer ltblue': topicManagerPage === 'topics' }"
                  (click)="topicManagerPage === 'topics' ? selectTopicGroup(selectedTopicGroup) : null"
                >
                  {{ topicManagerPage === 'topics' ? selectedTopicCategory.name : 'Categories' }}
                </span>
                <mat-icon class="gray mx-3" *ngIf="topicManagerPage === 'topics'"> chevron_right </mat-icon>
                <span *ngIf="topicManagerPage === 'topics'"> Topics </span>
              </h4>
              <button
                *ngIf="isWorkspaceTopicEditor && (topicManagerPage === 'categories' || topicManagerPage === 'topics')"
                mat-flat-button
                color="success"
                type="button"
                class="ml-5"
                (click)="topicManagerPage === 'categories' ? addTopicCategory() : openTopicDialog()"
              >
                {{ topicManagerPage === 'categories' ? 'Add Category' : 'Add Topic' }}
              </button>
            </div>

            <section class="d-flex flex-column request-topics mb-5 pb-5" *ngIf="topicManagerPage === 'groups'">
              <ng-container *ngFor="let g of topicGroups">
                <div class="m-portlet mb-3" *ngIf="g.draft">
                  <div class="m-portlet__body d-flex align-items-center p-3">
                    <form class="row m-0 d-flex full-width">
                      <div class="col pl-0">
                        <mat-form-field class="full-width" appearance="outline">
                          <input
                            matInput
                            placeholder="Enter Group Name *"
                            class="font-weight-semibold dkblue"
                            type="text"
                            [(ngModel)]="g.draft.name"
                            name="existingGroupName"
                            #existingGroupName="ngModel"
                            required
                          />
                        </mat-form-field>
                      </div>
                      <div class="col-3 text-right pr-0">
                        <button mat-button class="gray ml-2 lh-28 px-3" (click)="discardTopicGroupChanges(g)">
                          Cancel
                        </button>
                        <button
                          mat-flat-button
                          color="success"
                          class="ml-2 lh-28 px-3"
                          (click)="saveTopicGroupChanges(g)"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="m-portlet mb-3" *ngIf="!g.draft">
                  <div class="m-portlet__body d-flex align-items-center p-3">
                    <h5 (click)="selectTopicGroup(g)" class="dkblue font-weight-semibold m-0 my-1 pointer hover-u">
                      <span class="mr-2">
                        {{ g.name }}
                      </span>
                    </h5>
                    <h6 class="ml-2 white bg-gray br-5 m-0 lh-18 px-2 mr-auto">
                      {{ g.topic_categories?.length || 0 }}
                    </h6>
                    <button
                      mat-button
                      (click)="selectTopicGroup(g)"
                      color="accent"
                      class="ml-3 lh-28 px-2"
                      type="button"
                    >
                      <span class="mr-2"> View Categories </span>
                      <mat-icon> east </mat-icon>
                    </button>
                    <mat-icon
                      *ngIf="isWorkspaceTopicEditor"
                      matRipple
                      class="dkblue br-50 pointer ml-3"
                      [matMenuTriggerFor]="group_menu"
                      (click)="$event.stopPropagation()"
                    >
                      more_vert
                    </mat-icon>
                    <mat-menu #group_menu="matMenu" xPosition="before">
                      <button mat-menu-item (click)="editTopicGroup(g)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit {{ g.name }}</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </ng-container>
            </section>
            <section class="d-flex flex-column request-topics mb-5 pb-5" *ngIf="topicManagerPage === 'categories'">
              <div class="m-portlet mb-3" *ngIf="topicCategoryDraft">
                <div class="m-portlet__body pointer d-flex align-items-center p-3">
                  <form class="row m-0 d-flex full-width">
                    <div class="col pl-0">
                      <mat-form-field class="full-width" appearance="outline">
                        <input
                          matInput
                          placeholder="Enter Category Name *"
                          class="font-weight-semibold dkblue"
                          type="text"
                          [(ngModel)]="topicCategoryDraft.name"
                          name="newCategoryName"
                          #newCategoryName="ngModel"
                          required
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-3 text-right pr-0">
                      <button mat-button class="gray ml-2 lh-28 px-3" (click)="discardTopicCategoryDraft()">
                        Cancel
                      </button>
                      <button
                        mat-flat-button
                        color="success"
                        class="ml-2 lh-28 px-3"
                        (click)="saveTopicCategoryDraft()"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <ng-container *ngFor="let c of selectedTopicGroup.topic_categories">
                <div class="m-portlet mb-3" *ngIf="!c.draft">
                  <div class="m-portlet__body d-flex align-items-center p-3">
                    <h5 (click)="selectTopicCategory(c)" class="dkblue font-weight-semibold m-0 my-1 hover-u pointer">
                      <span class="mr-2">
                        {{ c.name }}
                      </span>
                    </h5>
                    <h6 class="ml-2 white bg-gray br-5 m-0 lh-18 px-2 mr-auto">
                      {{ c.topics?.length || 0 }}
                    </h6>
                    <button
                      mat-button
                      (click)="selectTopicCategory(c)"
                      color="accent"
                      class="ml-3 lh-28 px-2"
                      type="button"
                    >
                      <span class="mr-2"> View Topics </span>
                      <mat-icon> east </mat-icon>
                    </button>
                    <mat-icon
                      *ngIf="isWorkspaceTopicEditor"
                      matRipple
                      class="dkblue br-50 pointer ml-3"
                      [matMenuTriggerFor]="category_menu"
                      (click)="$event.stopPropagation()"
                    >
                      more_vert
                    </mat-icon>
                    <mat-menu #category_menu="matMenu" xPosition="before">
                      <button mat-menu-item (click)="editTopicCategory(c)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit {{ c.name }}</span>
                      </button>
                      <button mat-menu-item (click)="disableTopicCategory(c)">
                        <mat-icon>delete</mat-icon>
                        <span>Delete {{ c.name }}</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
                <div class="m-portlet mb-3" *ngIf="c.draft">
                  <div class="m-portlet__body pointer d-flex align-items-center p-3">
                    <form class="row m-0 d-flex full-width">
                      <div class="col pl-0">
                        <mat-form-field class="full-width" appearance="outline">
                          <input
                            matInput
                            placeholder="Enter Category Name *"
                            class="font-weight-semibold dkblue"
                            type="text"
                            [(ngModel)]="c.draft.name"
                            name="existingCategoryName"
                            #existingCategoryName="ngModel"
                            required
                          />
                        </mat-form-field>
                      </div>
                      <div class="col-3 text-right pr-0">
                        <button mat-button class="gray ml-2 lh-28 px-3" (click)="discardTopicCategoryChanges(c)">
                          Cancel
                        </button>
                        <button
                          mat-flat-button
                          color="success"
                          class="ml-2 lh-28 px-3"
                          (click)="saveTopicCategoryChanges(c)"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </ng-container>
            </section>
            <section class="d-flex flex-column request-topics mb-5 pb-5" *ngIf="topicManagerPage === 'topics'">
              <div class="m-portlet mb-3" *ngIf="topicDraft">
                <div class="m-portlet__body d-flex align-items-center p-3">
                  <form class="row m-0 d-flex full-width">
                    <div class="col pl-0">
                      <mat-form-field class="full-width" appearance="outline">
                        <input
                          matInput
                          placeholder="Enter Topic Name *"
                          class="font-weight-semibold dkblue"
                          type="text"
                          [(ngModel)]="topicDraft.name"
                          name="newTopicName"
                          #newTopicName="ngModel"
                          required
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-2 d-flex">
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-select
                          multiple
                          [placeholder]="topicDraft.visible_to ? 'Visible To' : 'Everyone'"
                          [(ngModel)]="topicDraft.visible_to"
                          name="newTopicVisibleTo"
                          #topicAccessSelector
                        >
                          <h6 class="dkblue font-weight-semibold m-3">Visible To</h6>
                          <div
                            (click)="topicAccessSelector.close(); setTopicAccessToEveryone(topicDraft)"
                            class="fake-option"
                          >
                            Everyone
                          </div>
                          <mat-option *ngFor="let a of topicAccess" [value]="a.id">{{ a.name }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-2 d-flex">
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-select
                          placeholder="Topic Type *"
                          [(ngModel)]="topicDraft.topic_type_id"
                          name="newTopicType"
                          #newTopicType="ngModel"
                          required
                        >
                          <h6 class="dkblue font-weight-semibold m-3">Topic Type</h6>
                          <mat-option *ngFor="let t of topicTypes" [value]="t.id">{{ t.name }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-2 d-flex">
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-select
                          placeholder="Topic Priority"
                          [(ngModel)]="topicDraft.priority_id"
                          name="newTopicPriority"
                          #newTopicPriority
                        >
                          <h6 class="dkblue font-weight-semibold m-3">Topic Priority</h6>
                          <div
                            (click)="newTopicPriority.close(); setTopicPriorityToNull(topicDraft)"
                            class="fake-option"
                          >
                            None
                          </div>
                          <mat-option *ngFor="let p of workOrderPriorities" [value]="p.id">{{ p.name }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-3 text-right pr-0">
                      <button mat-button class="gray ml-2 lh-28 px-3" (click)="discardTopicDraft()">Cancel</button>
                      <button
                        mat-flat-button
                        color="success"
                        class="ml-2 lh-28 px-3"
                        type="submit"
                        (click)="saveTopicDraft()"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <ng-container *ngFor="let t of selectedTopicCategory.topics">
                <div class="m-portlet mb-3" *ngIf="!t.draft">
                  <div class="m-portlet__body d-flex align-items-center p-3">
                    <h5 class="dkblue font-weight-semibold m-0 mr-auto my-1">
                      <span class="mr-2">
                        {{ t.name }}
                      </span>
                      <small class="dkgray font-weight-normal">
                        {{ t.visibleToLabels }}
                      </small>
                    </h5>
                    <h6 class="ml-2 mb-0 lh-22 px-2 bg-shade-ltblue dkblue br-4" *ngIf="t.workspace?.name">
                      {{ t.workspace?.name }}
                    </h6>
                    <h6
                      class="ml-2 mb-0 lh-22 px-2 br-4"
                      *ngIf="t.topic_type?.name"
                      [ngClass]="{
                        'bg-shade-orange dkorange': t.topic_type?.name === 'Work Order',
                        'bg-shade-ltblue ltblue': t.topic_type?.name === 'Project'
                      }"
                    >
                      {{ t.topic_type?.name }}
                    </h6>
                    <h6
                      class="ml-2 mb-0 lh-22 px-2 br-4 d-flex align-items-center"
                      *ngIf="t.work_order_priority?.name"
                      [ngClass]="{
                        'bg-shade-gray gray': t.work_order_priority?.id === 1,
                        'bg-shade-ltblue ltblue': t.work_order_priority?.id === 2,
                        'bg-shade-orange red': t.work_order_priority?.id === 3,
                        'bg-shade-red dkred': t.work_order_priority?.id === 4
                      }"
                    >
                      <i
                        class="mr-2 fa fa-{{
                          t.work_order_priority?.id === 1
                            ? 'angle-double-down'
                            : t.work_order_priority?.id === 2
                            ? 'equals'
                            : t.work_order_priority?.id === 3
                            ? 'angle-up'
                            : t.work_order_priority?.id === 4
                            ? 'angle-double-up'
                            : ''
                        }}"
                      >
                      </i>
                      {{ t.work_order_priority?.abbreviation }}
                    </h6>
                    <h6
                      class="ml-2 mb-0 lh-22 px-2 bg-shade-gray dkgray br-4"
                      *ngIf="t.topic_type?.id === 2 && t.checklist_template.length > 0"
                    >
                      Checklist
                    </h6>
                    <h6
                      [hidden]="!t.tooltip"
                      [matMenuTriggerFor]="tooltip"
                      #tooltipTrigger="matMenuTrigger"
                      (mouseenter)="buttonEnter(tooltipTrigger, t.id)"
                      (mouseleave)="buttonLeave(tooltipTrigger, t.id)"
                      class="mb-0 lh-22 px-2 bg-shade-gray dkgray br-4 ml-2 pointer"
                    >
                      Tip
                    </h6>
                    <mat-menu
                      #tooltip="matMenu"
                      direction="down"
                      yPosition="below"
                      overlapTrigger="false"
                      [hasBackdrop]="false"
                    >
                      <div
                        class="p-3"
                        style="min-width: 250px"
                        (mouseenter)="menuEnter(t.id)"
                        (mouseleave)="menuLeave(tooltipTrigger, t.id)"
                      >
                        <p class="m-0 dkblue font-weight-semibold mb-2">{{ t.name }}</p>
                        <p class="m-0 dkgray font-weight-normal" [innerHTML]="t.tooltip"></p>
                      </div>
                    </mat-menu>
                    <mat-icon
                      *ngIf="isWorkspaceTopicEditor"
                      matRipple
                      class="dkblue br-50 pointer ml-3"
                      [matMenuTriggerFor]="topic_menu"
                      (click)="$event.stopPropagation()"
                    >
                      more_vert
                    </mat-icon>
                    <mat-menu #topic_menu="matMenu" xPosition="before">
                      <!-- <div class="d-flex align-items-center" mat-menu-item>
                        <p class="dkblue font-weight-semibold m-0 mr-auto">
                          AR Only
                        </p>
                        <mat-slide-toggle color="accent" (click)="$event.stopPropagation()">
                        </mat-slide-toggle>
                      </div> -->
                      <button mat-menu-item (click)="openTopicDialog(t)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit {{ t.name }}</span>
                      </button>
                      <button mat-menu-item (click)="disableTopic(t)">
                        <mat-icon>delete</mat-icon>
                        <span>Delete {{ t.name }}</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
                <div class="m-portlet mb-3" *ngIf="t.draft">
                  <div class="m-portlet__body d-flex align-items-center p-3">
                    <form class="row m-0 d-flex full-width">
                      <div class="col pl-0">
                        <mat-form-field class="full-width" appearance="outline">
                          <input
                            matInput
                            placeholder="Enter Topic Name *"
                            class="font-weight-semibold dkblue"
                            type="text"
                            [(ngModel)]="t.draft.name"
                            name="existingTopicName"
                            #existingTopicName="ngModel"
                            required
                          />
                        </mat-form-field>
                      </div>
                      <div class="col-2 d-flex">
                        <mat-form-field appearance="outline" class="full-width">
                          <mat-select
                            multiple
                            [placeholder]="t.draft.visible_to ? 'Visible To' : 'Everyone'"
                            [(ngModel)]="t.draft.visible_to"
                            name="existingTopicVisibleTo"
                            #topicAccessSelector
                          >
                            <h6 class="dkblue font-weight-semibold m-3">Visible To</h6>
                            <div
                              (click)="topicAccessSelector.close(); setTopicAccessToEveryone(t.draft)"
                              class="fake-option"
                            >
                              Everyone
                            </div>
                            <mat-option *ngFor="let a of topicAccess" [value]="a.id">{{ a.name }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-2 d-flex">
                        <mat-form-field appearance="outline" class="full-width">
                          <mat-select
                            placeholder="Topic Type *"
                            [(ngModel)]="t.draft.topic_type_id"
                            name="existingTopicType"
                            #existingTopicType="ngModel"
                            required
                          >
                            <h6 class="dkblue font-weight-semibold m-3">Topic Type</h6>
                            <mat-option *ngFor="let t of topicTypes" [value]="t.id">{{ t.name }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-2 d-flex">
                        <mat-form-field appearance="outline" class="full-width">
                          <mat-select
                            placeholder="Topic Priority"
                            [(ngModel)]="t.draft.priority_id"
                            name="existingTopicPriority"
                            #existingTopicPriority
                          >
                            <h6 class="dkblue font-weight-semibold m-3">Topic Priority</h6>
                            <div
                              (click)="existingTopicPriority.close(); setTopicPriorityToNull(t.draft)"
                              class="fake-option"
                            >
                              None
                            </div>
                            <mat-option *ngFor="let p of workOrderPriorities" [value]="p.id">{{ p.name }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-3 text-right pr-0">
                        <button mat-button class="gray ml-2 lh-28 px-3" (click)="discardTopicChanges(t)">Cancel</button>
                        <button
                          mat-flat-button
                          color="success"
                          class="ml-2 lh-28 px-3"
                          (click)="saveTopicChanges(t)"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </ng-container>
            </section>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>{{ settingTabs[1].label }}</ng-template>
          <div class="row align-items-top">
            <div class="d-flex flex-column col-3">
              <button mat-flat-button color="success" type="button" class="full-width mb-4" (click)="newTemplate()">
                New Task Template
              </button>
              <h6 class="dkblue font-weight-semibold mb-2">Existing Templates</h6>
              <div class="m-portlet">
                <div
                  class="m-portlet__body item p-3 pointer d-flex align-items-top"
                  *ngFor="let t of projectTemplates"
                  (click)="selectTemplate(t)"
                  [ngClass]="{ 'bg-ltltltblue': t?.id === selectedTemplate?.id }"
                >
                  <div class="d-flex flex-column">
                    <p class="dkblue font-weight-semibold m-0">
                      {{ t.name }}
                    </p>
                    <p class="dkgray font-weight-normal m-0">
                      {{ t.milestoneCount }} Milestones, {{ t.taskCount }} Tasks
                    </p>
                  </div>
                  <mat-icon class="ml-auto gray" *ngIf="t.is_default"> star </mat-icon>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column col-9" *ngIf="selectedTemplate">
              <div class="d-flex align-items-top mb-4">
                <div class="d-flex flex-column mr-auto">
                  <h4 class="dkblue font-weight-semibold m-0 d-flex align-items-center" *ngIf="!editingTemplate">
                    <span>
                      {{ selectedTemplate.name }}
                    </span>
                    <mat-icon class="ml-2 gray" *ngIf="selectedTemplate.is_default"> star </mat-icon>
                  </h4>
                  <p *ngIf="!editingTemplate" class="dkgray font-weight-normal m-0">
                    {{ selectedTemplate.milestoneCount }} Milestones, {{ selectedTemplate.taskCount }} Tasks
                  </p>
                </div>
                <mat-form-field class="flex-grow-1" appearance="outline" *ngIf="editingTemplate">
                  <input
                    matInput
                    placeholder="Enter Template Title"
                    class="font-weight-semibold dkblue"
                    type="text"
                    [(ngModel)]="selectedTemplate.name"
                  />
                  <mat-hint
                    align="end"
                    [ngClass]="{
                      red: selectedTemplate?.name?.length > selectedTemplateTitleLength,
                      gray: selectedTemplate?.name?.length <= selectedTemplateTitleLength
                    }"
                    >{{ selectedTemplate?.name?.length || 0 }}/{{ selectedTemplateTitleLength }}</mat-hint
                  >
                </mat-form-field>
                <div class="d-flex align-items-center">
                  <button
                    mat-button
                    type="button"
                    class="gray ml-3"
                    *ngIf="editingTemplate"
                    (click)="discardTemplateChanges()"
                  >
                    Cancel
                  </button>
                  <button
                    mat-button
                    color="warn"
                    type="button"
                    class="dkgray ml-3"
                    (click)="deleteTemplate()"
                    *ngIf="!editingTemplate && selectedTemplate?.id && !selectedTemplate?.is_default"
                  >
                    Delete Template
                  </button>
                  <button
                    mat-stroked-button
                    color="accent"
                    type="button"
                    class="bg-white ml-3"
                    (click)="editTemplate()"
                    *ngIf="!editingTemplate"
                  >
                    Edit Template
                  </button>
                  <button
                    mat-flat-button
                    color="success"
                    type="button"
                    class="ml-3"
                    (click)="saveTemplate()"
                    *ngIf="editingTemplate"
                  >
                    Save
                  </button>
                </div>
              </div>
              <div class="d-flex flex-column bg-shade-ltblue br-10 p-3" *ngIf="workspace.id === 1">
                <p class="dkblue lh-18 m-0">
                  For all Construction Projects, the
                  <span class="font-weight-semibold">Base Project Template</span> is the dafault template. All other
                  templates will be added in addition to
                  <span class="font-weight-semibold">Base Project Template</span>.
                </p>
              </div>
              <div class="d-flex flex-column mt-4">
                <mat-tab-group mat-stretch-tabs>
                  <mat-tab *ngFor="let p of selectedTemplate.phases">
                    <ng-template mat-tab-label
                      >{{ p.name }}
                      <span class="button-badge bg-dkgray white ml-2" *ngIf="p.milestoneCount > 0">{{
                        p.milestoneCount
                      }}</span></ng-template
                    >
                    <section class="d-flex flex-column milestone-container my-5" *ngIf="editingTemplate">
                      <ng-container *ngFor="let m of p.milestones; let milestoneIndex = index">
                        <div class="mb-5" *ngIf="!m.should_delete">
                          <div class="d-flex align-items-center mb-3">
                            <mat-form-field class="flex-grow-1 mr-auto" appearance="outline">
                              <input
                                matInput
                                placeholder="Enter Milestone Title"
                                [(ngModel)]="m.name"
                                (change)="m.name = m.name?.trim()"
                                class="font-weight-semibold"
                                type="text"
                                #milestoneTitle
                                [milestone]="m"
                                (keyUp.enter)="createTask(m)"
                              />
                            </mat-form-field>
                            <button
                              mat-button
                              color="primary"
                              class="dkgray px-3 ml-5"
                              type="button"
                              (click)="deleteMilestone(m, p, milestoneIndex)"
                            >
                              Delete Milestone
                            </button>
                          </div>
                          <div
                            class="m-portlet mb-0 ease"
                            cdkDropList
                            [cdkDropListData]="m.tasks"
                            cdkDropListOrientation="vertical"
                            (cdkDropListDropped)="drop($event, p, m)"
                          >
                            <ng-container *ngFor="let task of m.tasks; let taskIndex = index; let last = last">
                              <div
                                cdkDrag
                                class="
                                  m-portlet__body
                                  item
                                  d-flex
                                  flex-column
                                  align-items-center
                                  px-3
                                  py-2
                                  ease
                                  bg-white
                                "
                                *ngIf="!task.should_delete"
                                [ngClass]="{ 'item-last': last }"
                              >
                                <div class="d-flex align-items-center full-width">
                                  <mat-icon class="mr-3 gray"> radio_button_unchecked </mat-icon>
                                  <button disabled mat-flat-button color="accent" type="button" class="mr-3 px-3 lh-30">
                                    {{ workspace?.id === 1 && [1, 3].indexOf(p.type_id) > -1 ? 'Architect' : 'PM' }}
                                  </button>
                                  <div class="flex-grow-1">
                                    <input
                                      style="font-size: 1.2rem"
                                      matInput
                                      placeholder="Enter Task Title"
                                      [(ngModel)]="task.title"
                                      (change)="task.title = task.title?.trim()"
                                      class="input-xl font-weight-semibold dkblue border rounded pl-3 py-2"
                                      #taskTitle
                                      [task]="task"
                                      (keyUp.enter)="createTask(m)"
                                    />
                                  </div>
                                  <h6 class="ml-5 m-0 ltblue pointer hover-u" (click)="toggleDescription(task)">
                                    {{ task.showDescription ? 'Remove' : 'Add' }}
                                    Description
                                  </h6>
                                  <button
                                    cdkDragHandle
                                    mat-icon-button
                                    color="primary"
                                    type="button"
                                    class="ml-3 gray draggable"
                                  >
                                    <mat-icon>open_with</mat-icon>
                                  </button>
                                  <button
                                    mat-icon-button
                                    color="primary"
                                    type="button"
                                    class="gray"
                                    (click)="deleteTask(task, m, taskIndex)"
                                  >
                                    <mat-icon>delete_forever</mat-icon>
                                  </button>
                                </div>
                                <div
                                  class="d-flex flex-column align-items-top px-5 my-4 full-width textarea"
                                  *ngIf="task.showDescription"
                                >
                                  <app-editor
                                    [item]="task"
                                    [field]="'description'"
                                    placeholder="Description"
                                  ></app-editor>
                                </div>
                                <div
                                  class="
                                    m-portlet__body
                                    item
                                    d-flex
                                    flex-column
                                    align-items-center
                                    px-3
                                    py-2
                                    ease
                                    task-placeholder
                                  "
                                  *cdkDragPlaceholder
                                ></div>
                              </div>
                            </ng-container>
                          </div>
                          <div
                            class="
                              pointer
                              mt-2
                              mb-3
                              d-flex
                              flex-column
                              align-items-center
                              py-1
                              dkgreen
                              px-3
                              py-1
                              bg-shade-green
                              br-6
                            "
                            (click)="createTask(m)"
                          >
                            <p class="dkgreen font-weight-semibold m-0">+ Task</p>
                          </div>
                        </div>
                      </ng-container>
                      <div
                        class="pointer d-flex flex-column align-items-center p-4 br-6 bg-shade-green"
                        (click)="createMilestone(p)"
                      >
                        <h5 class="dkgreen m-0">+ Milestone</h5>
                      </div>
                    </section>
                    <section class="d-flex flex-column milestone-container my-5" *ngIf="!editingTemplate">
                      <div *ngFor="let m of p.milestones" class="mb-5">
                        <div class="d-flex align-items-center mb-3">
                          <h4 class="dkblue font-weight-semibold m-0 mr-auto">
                            {{ m.name }}
                          </h4>
                        </div>
                        <div class="m-portlet">
                          <div
                            class="m-portlet__body item flex-column align-items-center px-3 py-2"
                            *ngFor="let task of m.tasks; let last = last"
                            [ngClass]="{ 'item-last': last }"
                          >
                            <div class="d-flex align-items-center full-width">
                              <mat-icon class="mr-3 gray"> radio_button_unchecked </mat-icon>
                              <button disabled mat-flat-button color="accent" type="button" class="mr-3 px-3 lh-30">
                                {{ workspace?.id === 1 && [1, 3].indexOf(p.type_id) > -1 ? 'Architect' : 'PM' }}
                              </button>
                              <h5 class="dkblue font-weight-semibold m-0 mr-auto">
                                {{ task.title }}
                              </h5>
                            </div>
                            <div class="d-flex flex-column align-items-top px-5 mt-1" *ngIf="task.description">
                              <!-- <h6 class="dkblue font-weight-semibold mb-2 ml-5">
                                Description
                              </h6> -->
                              <p
                                class="dkgray m-0 lh-20 ml-5 pl-4 font-weight-normal"
                                [innerHTML]="task.description"
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab *ngIf="isBuildingsDepartmentsSuitesManager">
          <ng-template mat-tab-label>Buildings</ng-template>
          <app-building-manager #buildingManager></app-building-manager>
        </mat-tab>
        <mat-tab *ngIf="isBuildingsDepartmentsSuitesManager">
          <ng-template mat-tab-label>Suites</ng-template>
          <app-suites-manager #suiteManager></app-suites-manager>
        </mat-tab>
        <mat-tab *ngIf="isBuildingsDepartmentsSuitesManager">
          <ng-template mat-tab-label>Departments</ng-template>
          <app-department-manager #departmentManager></app-department-manager>
        </mat-tab>
      </mat-tab-group>
    </div>
  </main>
</div>

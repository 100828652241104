import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DatepickerHeaderComponent, FileAttachmentDialogComponent } from 'src/app/components';
import { ProjectStatus, ResourceType } from 'src/app/enums';
import { AuthService, FileService, ProgressIndicatorService, ProjectService } from 'src/app/services';
import { UhatFileReference } from 'src/app/types';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-project-timeline',
  templateUrl: './project-timeline.component.html',
  styleUrls: ['./project-timeline.component.scss'],
})
export class ProjectTimelineComponent implements OnInit, OnDestroy {
  @Input() project: ProjectConstruction;

  @Output() public phaseUpdated = new EventEmitter();

  // these are ordered by newest to oldest
  // the first item has the newest created_datetime, and is the most current revision of the timeline for the project
  allTimelines: UhatFileReference[] = [];
  customHeader = DatepickerHeaderComponent;
  phases = [];
  public ProjectStatus = ProjectStatus;

  private projectSelectedSubscription: Subscription;

  get onSchedule() {
    return this.project.on_schedule;
  }

  get getLatest() {
    return this.getTimelines().slice(0, 1)[0];
  }

  get constructionStartDate() {
    if (moment(this.project.construction_start_date).isValid()) {
      return moment(this.project.construction_start_date).format('MMM Do, YYYY');
    } else {
      return 'Not Set';
    }
  }

  get projectEndDate() {
    return moment(this.project.end_date).format('MMM Do, YYYY');
  }

  get isAdmin() {
    return this.authService.isProjectAdmin(this.project.id, this.project.module_id);
  }

  get isArchitect() {
    return this.authService.isProjectArchitect(this.project.id);
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.project?.module_id);
  }

  get currentPhaseName(): string {
    return this.project.current_phase_name;
  }
  get getFirstIndex(): number {
    return Math.min(...this.phases.map((phase) => phase.sequence));
  }
  get getLastIndex(): number {
    return Math.max(...this.phases.map((phase) => phase.sequence));
  }

  constructor(
    private fileService: FileService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    public authService: AuthService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.refresh();
    });
    this.projectSelectedSubscription = this.projectService.projectSelectedEvent.subscribe((value) => {
      this.project = value;
    });
  }

  ngOnDestroy(): void {
    if (this.projectSelectedSubscription) {
      this.projectSelectedSubscription.unsubscribe();
    }
  }

  async refresh() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Timeline..');
    this.fileService.getFilesByParentId(ResourceType.Timeline, this.project.id).subscribe((schedules) => {
      this.allTimelines = schedules.sort((a, b) => (a.created_datetime > b.created_datetime ? -1 : 1));
    });

    await this.projectService
      .getProjectById(this.project.id)
      .toPromise()
      .then((data) => {
        this.project = data;
      });
    await this.projectService
      .getPhasesByProjectId(this.project.id)
      .toPromise()
      .then((data) => {
        this.phases = data;
      });
    this.progressIndicatorService.close();
  }

  getTimelines(): UhatFileReference[] {
    return this.allTimelines;
  }

  downloadFile(file) {
    file.downloading = true;
    this.fileService.downloadFile(file).subscribe((downloadedFileResult) => {
      saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
      file.downloading = false;
    });
  }

  openFileSelectDialog() {
    // since we dont 'allowComment', this just links the files to the parent (timeline) and the additionalParents (project)
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.project.id,
          maxFiles: 1,
          allowSearchFromProject: false,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (resultData) => {
        if (resultData) {
          for (const file of resultData) {
            await this.fileService.linkFile(file.file_id, this.project.id, ResourceType.Timeline).toPromise();
          }
          this.snackbar.open('Timeline uploaded!');
          this.refresh();
        }
      });
  }

  async deleteFile(file) {
    await this.fileService.unlinkFile(file.id).toPromise();
    this.snackbar.open('File removed from timeline history!');
    this.refresh();
  }

  setOnSchedule(isOnSchedule) {
    if (isOnSchedule === this.onSchedule) {
      return;
    }

    this.projectService.setOnSchedule(this.project.id, isOnSchedule).subscribe((data) => this.refresh());
    this.project.on_schedule = isOnSchedule;
  }

  setStartDate(value) {
    const toSetDate = moment(value).format('YYYY-MM-DD HH:mm:ss');
    this.project.construction_start_date = toSetDate;
    this.projectService.updateProject(this.project.id, { construction_start_date: toSetDate }).subscribe();
  }

  setEndDate(value) {
    const toSetDate = moment(value).format('YYYY-MM-DD HH:mm:ss');
    this.project.end_date = toSetDate;
    this.projectService.updateProject(this.project.id, { end_date: toSetDate }).subscribe();
  }

  // emitted from the phase selector
  async setPhase(phase) {
    await this.projectService.setPhaseIdForProject(this.project.id, phase.id).toPromise();
    this.snackbar.open('Project Phase set to ' + phase.name);
    await this.projectService.selectProjectById(this.project.id);
    this.phaseUpdated.emit(phase);
  }

  public changeProjectStatus(projectStatusId: number) {
    // succeed if pm or (setting to active and arch)
    // so fail if !(pm or (setting to active and arch))
    // so fail if !pm and !(setting to active and arch)
    if (
      this.project.project_manager_id !== this.authService.getLoggedInUser().id &&
      !(projectStatusId === ProjectStatus.ACTIVE && this.isArchitect)
    ) {
      this.snackbar.open('Only the Project Manager can change the project status.');
      return;
    }
    this.projectService
      .updateProject(this.project.id, { status_id: projectStatusId })
      .toPromise()
      .then((result) => {
        this.project.status_id = projectStatusId;
        this.projectService.currentSelectedProject.status_id = projectStatusId;
        this.snackbar.open('Project status updated!');
        // added refresh here so the page displays the new status
        // TODO the header needs to update as well
        this.refresh();
      });
  }
}

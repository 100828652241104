import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import PerfectScrollbar from 'perfect-scrollbar';
import { Workspace } from 'src/app/enums';
import { RequestListFilter } from 'src/app/models';
import { RequestListFilterPipe } from 'src/app/pipes';
import {
  AuthService,
  ModuleService,
  ProgressIndicatorService,
  RequestService,
  SidenavLinksService,
} from 'src/app/services';
import { APIFilter, Module, Request, RequestStatus } from 'src/app/types';

@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.scss'],
})
export class RequestListComponent implements OnInit, OnDestroy {
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  @ViewChild('paginator', { static: false }) paginator: any;

  constructor(
    private authService: AuthService,
    private requestService: RequestService,
    private progressIndicatorService: ProgressIndicatorService,
    private moduleService: ModuleService,
    private sidenavLinksService: SidenavLinksService
  ) {}

  public divWidth: number;
  public showDesktop: boolean;
  public showIpad: boolean;
  public startIndex = 0;
  public pageSize = 50;
  public endIndex = this.pageSize;
  public filteredCount = { count: 0 };

  requestStatuses: RequestStatus[] = [];
  requestStatusesCount = {};
  allRequests: Request[] = [];
  requestsCount: any;
  searchTerm: string;
  Workspace = Workspace;
  selectedRequestStatus: RequestStatus;
  requestFields: string[] = [
    'id',
    'status_id',
    'code',
    'request_type{id,name}',
    'requester{id,first_name,last_name}',
    'building{id,name}',
    'floor{id,name}',
    'budget{id,name}',
    'created_datetime',
    'module_id',
    'short_description',
    'topic{id,name,workspace{id,name},topic_category{id,name,topic_group_id},topic_group{id,name}}',
    'topic_category{id,name,topic_group_id}',
    'topic_group{id,name}',
    'topic_id',
  ];
  selectedRequestType: any;
  filter: RequestListFilter = new RequestListFilter();
  private requestFilter = new RequestListFilterPipe();

  public fieldToSortBy: string;
  public sortDirection: SortDirection = 'desc';

  private currentSubscription;

  get isDispatch() {
    return this.moduleService.workspace_id === 2;
  }

  async ngOnInit() {
    // adds the scrollbar
    const ps = new PerfectScrollbar(document.querySelector('#requests'));
    setTimeout(async () => {
      const selectedSideLink =
        (this.authService.isStaffOnAnyModule && this.sidenavLinksService.requests) ||
        this.sidenavLinksService.tenantRequests;
      this.sidenavLinksService.selectLink(selectedSideLink);

      if (!this.currentSubscription) {
        // keep track of subscription so we can destroy it when the component is destroyed
        this.currentSubscription = this.moduleService.selectWorkspaceEvent.subscribe(() => {
          this.refresh();
        });
      }
      this.requestStatuses = await this.requestService
        .getRequestStatuses(
          ['id', 'name'],
          [{ type: 'field', field: 'id', value: '5', match: '!=' }],
          null,
          null,
          null,
          true
        )
        .toPromise();

      // Set Sorting and filtering preferences
      const preferences = JSON.parse(localStorage.getItem('preferences'));
      this.setSelectedRequest(preferences?.filter_requests_by_status);
      this.sortDirection = preferences?.sort_requests_order;
      this.fieldToSortBy = preferences?.sort_requests_by_field || this.updateSortByField('code');

      await this.refresh();
    });

    if (!this.currentSubscription) {
      // keep track of subscription so we can destroy it when the component is destroyed
      this.currentSubscription = this.moduleService.selectWorkspaceEvent.subscribe(() => {
        this.refresh();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }
  }

  async refresh() {
    if (typeof this.workspace !== 'undefined') {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Retrieving Requests..');

      const isViewAll = this.filter.statusId == -1;
      // instatiate filter with status
      let filter: APIFilter[] = isViewAll ? [] : [{ type: 'field', field: 'status_id', value: this.filter.statusId }];
      // dispatch can have move than one workspace selected
      if (this.isDispatch) {
        const workspaceIds =
          this.filter?.workspaceIds?.length > 0
            ? this.filter?.workspaceIds?.join('^')
            : this.moduleService?.allWorkspaces?.map((workspace: Module) => workspace?.id)?.join('^');

        filter.push({ type: 'operator', value: 'AND' }, { type: 'field', field: 'module_id', value: workspaceIds });
      } else if (this.workspace?.id) {
        // specific workspace
        filter.push(
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'module_id', value: (this.workspace?.id || '')?.toString() }
        );
      }

      filter = this.cleanFilterOption(filter);
      this.allRequests = (await this.requestService.getRequests(this.requestFields, filter).toPromise()).map(
        (request: Request) => ({
          ...request,
          sortCode: Number(request?.id),
        })
      );

      // Get the counts  seperately
      for (const status of this.requestStatuses) {
        let countFilter: APIFilter[] = [...filter];
        if (countFilter[0].type == 'field' && countFilter[0].field == 'status_id') {
          if (status.id > -1) {
            countFilter[0] = { type: 'field', field: 'status_id', value: status?.id };
          } else {
            countFilter.shift(); //remove from array if status.id === -1
          }
        } else {
          if (status.id > -1) {
            countFilter.push(
              { type: 'operator', value: 'AND' },
              { type: 'field', field: 'status_id', value: status?.id }
            );
          }
        }

        countFilter = this.cleanFilterOption(countFilter);
        this.requestStatusesCount[status.id] = await this.requestService.getRequestCount(countFilter).toPromise();
      }
      this.progressIndicatorService.close();
    }
  }

  cleanFilterOption(filter: APIFilter[]) {
    //If first filter command is the AND operator without any preceding condition.
    let size = filter.length || 0;
    if (size > 0 && filter[0].type == 'operator' && filter[0].value == 'AND') {
      filter.shift();
    }
    return filter;
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
    if (this.divWidth > 800) {
      this.showDesktop = true;
      this.showIpad = false;
    } else {
      this.showDesktop = false;
      this.showIpad = true;
    }
  }

  public setSelectedRequest(requestId = null) {
    const id = requestId || requestId === 0 ? requestId : 1;
    this.filter.statusId = requestId ?? 1;
    this.selectedRequestStatus = this.requestStatuses.find((rs) => rs.id === id);
    localStorage.setItem('preferences', JSON.stringify(this.addToPreferences('filter_requests_by_status', id)));
    this.updateFilter();
    this.refresh();
  }

  get workspace() {
    return this.moduleService.workspace;
  }

  public updateWorkspaces(workspaceIds: number[]) {
    this.filter.workspaceIds = workspaceIds;
    this.updateFilter();
    this.refresh();
  }

  public updateSearchTerm(searchTerm: string) {
    this.filter.searchTerm = searchTerm;
    this.updateFilter();
  }

  /**
   * Force Pipe Refresh
   */
  public updateFilter() {
    this.filter = { ...this.filter, searchTerm: this.filter?.searchTerm?.trim() || '' };
    this.goToFirstPage();
  }

  public updateSortByField(field: string) {
    if (field === this.fieldToSortBy) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      localStorage.setItem(
        'preferences',
        JSON.stringify(this.addToPreferences('sort_requests_order', this.sortDirection))
      );
    } else {
      this.sortDirection = 'asc';
    }
    this.fieldToSortBy = field;
    localStorage.setItem(
      'preferences',
      JSON.stringify(this.addToPreferences('sort_requests_by_field', this.fieldToSortBy))
    );
  }

  private addToPreferences(key: string, value: any) {
    const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
    preferences[key] = value;

    return preferences;
  }

  goToFirstPage() {
    this.paginator.firstPage();
    this.startIndex = 0;
  }

  public pageChange(event) {
    this.startIndex = event.pageIndex * event.pageSize;
    this.endIndex = this.startIndex + event.pageSize;
    const element = document.querySelector('#requests');
    element.scrollTo(0, 0);
  }
}

import { ApplicationRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF, pdf } from '@progress/kendo-drawing';
import { saveAs } from 'file-saver';
import { floor, round, sumBy } from 'lodash';
import * as moment from 'moment';
import { FileAttachmentDialogComponent, ViewTaskDialogComponent } from 'src/app/components';
import { FileAction, ResourceType, ReviewType, TaskReviewStatus, TaskStatus } from 'src/app/enums';
import { USDollarPipe } from 'src/app/pipes';
import {
  AuthService,
  DateService,
  DisplayReviewersService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  ProjectEventService,
  ProjectService,
  ProjectTaskService,
  ReviewRevisionService,
  TaskActionsService,
  UserService,
} from 'src/app/services';
import { UhatFileReference } from 'src/app/types';
import {
  PEBFundingSource,
  PEBItemNew,
  PEBLine,
  PEBOption,
  PEBSection,
  PEBTenantLine,
  ProjectConstruction,
  Trade,
} from 'src/app/workspaces/construction/types';
import { PEBSectionDetailsDrawerComponent, PEBSectionDialogComponent } from '../../components';
import { PEBStatus, ProjectTenantPEBStatus } from '../../enums';
import { PEBApprovalProcess } from '../../models';
import { PEBService, ProjectTenantService } from '../../services';

@Component({
  selector: 'app-peb-new',
  templateUrl: './peb-new.component.html',
  styleUrls: ['./peb-new.component.scss'],
})
export class PEBNewComponent implements OnInit, OnDestroy {
  @Input() project: ProjectConstruction;
  @ViewChild('drawer') sectionDrawer: MatDrawer;
  @ViewChild('sectionDetailsDrawerComponent') sectionDetailsComponent: PEBSectionDetailsDrawerComponent;
  @ViewChild('pdf', { static: true }) public pdf: PDFExportComponent;
  @ViewChild('coverLetter', { static: true }) coverLetter;
  @ViewChild('summary', { static: true }) summary;
  private USDollarPipe = new USDollarPipe();

  loaders = {
    selectedOption: false,
  };

  PEBOptions: PEBOption[];
  selectedOption: PEBOption;
  selectedSection: PEBSection;
  selectedSectionIndex = 0;
  feeTypeIds = [2, 3, 5, 6];

  currentReviewers = [];

  coverLetterSection = {
    first_name: null,
    name: null,
    title: null,
    department: null,
    peb_cover_letter_text: null,
  };

  statusText: string;
  internalReviewCanStart: boolean;
  canSelect: boolean;
  reviewsHaveStarted: boolean;
  PEBRevision: number;
  exportShowAllSections = true;
  exportSectionsLength: number;
  selectedPEBId: number;
  osfWCirc: number;

  allTrades: Trade[];
  filteredTrades: Trade[];
  reviewTaskUpdated: any;
  percentageSubtotalsAreValid: boolean;

  constructor(
    private appRef: ApplicationRef,
    private dialog: MatDialog,
    private progressIndicatorService: ProgressIndicatorService,
    private pebService: PEBService,
    private snackbar: MatSnackBar,
    private projectTaskService: ProjectTaskService,
    private projectService: ProjectService,
    private modalService: ModalService,
    private fileService: FileService,
    private displayReviewersService: DisplayReviewersService,
    private userService: UserService,
    private authService: AuthService,
    private eventService: ProjectEventService,
    private projectTenantService: ProjectTenantService,
    private reviewRevisionService: ReviewRevisionService,
    private taskService: ProjectTaskService,
    private dateService: DateService,
    private taskActionsService: TaskActionsService
  ) {}

  async ngOnInit() {
    this.osfWCirc = (this.project.occupied_sqft ?? 0) * (1 + 0.01 * (this.project.building_circulation_factor ?? 0));
    pdf.defineFont({
      'Dawning of a New Day': '../../../assets/fonts/DawningofaNewDay-Regular.ttf',
    });

    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading PEB...');
    this.allTrades = await this.projectService.getTrades().toPromise();
    await this.refreshPEBOptions();
    this.progressIndicatorService.close();

    this.reviewTaskUpdated = this.taskService.milestoneTaskEvent.subscribe(async (data) => {
      if (data) {
        let allTasksComplete = true;
        let updatedTask;
        const internalTaskId = this.selectedOption.sections[0].peb_approval_task_id;
        let internalTaskHasBeenCompleted;
        let tenantTasksAreLocked = true;

        for (const section of this.selectedOption?.sections) {
          const tasks = [
            section.approval_process?._staffReview?._approvalTask,
            section.approval_process?._tenantReview?._approvalTask,
          ];
          updatedTask = tasks.find((t) => t?.id === data.id) || updatedTask;
          allTasksComplete =
            allTasksComplete && !!tasks.find((t) => t?.status_id === TaskStatus.Complete && t.status_id !== data.id);

          tenantTasksAreLocked = tenantTasksAreLocked
            ? !section.needs_tenant_approval || !!section.approval_process?._tenantReview?.getTask?.is_locked
            : false;
          internalTaskHasBeenCompleted =
            internalTaskHasBeenCompleted ||
            (internalTaskId === updatedTask?.id &&
              updatedTask.status_id !== TaskStatus.Complete &&
              data.status_id === TaskStatus.Complete);

          if (updatedTask && updatedTask.status_id !== data.status_id) {
            section.approval_process = new PEBApprovalProcess(
              section,
              this.projectTaskService,
              this.modalService,
              this.project?.cfmo_id,
              this.fileService,
              this.dateService,
              this.taskActionsService
            );
          }
        }

        if (
          (allTasksComplete && updatedTask && updatedTask.status_id !== data.status_id) ||
          (internalTaskHasBeenCompleted && tenantTasksAreLocked)
        ) {
          updatedTask.status_id = data.status_id;

          if (internalTaskHasBeenCompleted && tenantTasksAreLocked) {
            const tenantSections = this.selectedOption.sections.filter((s) => s.needs_tenant_approval);
            if (tenantSections?.length) {
              await this.submitForTenantReview(tenantSections);
            }
          } else {
            await this.updateApprovalProcesses();
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.reviewTaskUpdated) {
      this.reviewTaskUpdated.unsubscribe();
    }
  }

  get dataIsAddedBySectionStatus() {
    const sectionsStatus = [];
    for (const section of this.selectedOption.sections) {
      sectionsStatus.push(!section.approval_process?.hasFilesNew || !section.approval_process?.hasCoverLetter);
    }
    return sectionsStatus;
  }

  get requiredDataIsAdded(): boolean {
    return !this.dataIsAddedBySectionStatus.find((s) => s);
  }

  get tenantReviewCanStart(): boolean {
    return (
      this.selectedOption?.sections[0]?.approval_process?._staffReview?._approvalTask?.status_id ===
        TaskStatus.Complete &&
      !!this.selectedOption?.sections?.find(
        (s) => s.needs_tenant_approval && !s.tenant_approval_task_id && !s.saved_tenant_approval_task_id
      )
    );
  }

  get tenantReviewHasStarted(): boolean {
    return !!this.selectedOption?.sections?.find((s) => s.tenant_approval_task_id || s.saved_tenant_approval_task_id);
  }

  get tenantTaskIds(): number[] {
    return this.selectedOption?.sections
      ?.filter((s) => s.tenant_approval_task_id || s.saved_tenant_approval_task_id)
      ?.map((s) => s.tenant_approval_task_id || s.saved_tenant_approval_task_id);
  }

  get sectionsThatNeedTenantReviews(): PEBSection[] {
    const sectionsWithoutTasks: PEBSection[] = [];
    for (const section of this.selectedOption.sections) {
      if (!section.saved_tenant_approval_task_id && !section.tenant_approval_task_id && section.needs_tenant_approval) {
        sectionsWithoutTasks.push(section);
      }
    }

    return sectionsWithoutTasks;
  }
  get PEBApprovalTaskId(): number {
    return this.selectedOption?.sections?.[0]?.peb_approval_task_id;
  }

  get savedPEBApprovalTaskId(): number {
    return this.selectedOption.sections?.length && this.selectedOption.sections[0].saved_peb_approval_task_id;
  }

  get canEdit(): boolean {
    return !this.reviewsHaveStarted || !!this.savedPEBApprovalTaskId || this.selectedOption.isEditing;
  }

  get canFinalize(): boolean {
    return (
      this.selectedOption?.sections?.length &&
      !this.selectedOption?.sections?.find((s) => !s.approval_process?.canFinalizeNew)
    );
  }
  get isFinalized(): boolean {
    return this.selectedOption?.sections?.length && this.selectedOption.sections[0].approval_process?.isFinalized;
  }

  get isProjectAdmin() {
    return this.authService.isProjectAdmin(
      this.projectService.currentSelectedProjectId,
      this.projectService.currentSelectedProject?.module_id
    );
  }

  get tenantReviews() {
    return this.selectedOption?.sections?.filter((s) => s.needs_tenant_approval);
  }

  get pmInfo(): { name: string; title: string; email: string; phone: string } {
    const first = this.projectService.currentSelectedProject.project_manager_first_name
      ? this.projectService.currentSelectedProject.project_manager_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.project_manager_last_name
      ? this.projectService.currentSelectedProject.project_manager_last_name
      : '';
    const email = this.projectService.currentSelectedProject.project_manager_email
      ? this.projectService.currentSelectedProject.project_manager_email
      : '';
    const phone = this.projectService.currentSelectedProject.project_manager_office_phone
      ? this.projectService.currentSelectedProject.project_manager_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Project Manager',
      email,
      phone,
    };
  }
  get wmInfo(): { name: string; title: string; email: string; phone: string } {
    const first = this.projectService.currentSelectedProject.workspace_manager_first_name
      ? this.projectService.currentSelectedProject.workspace_manager_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.workspace_manager_last_name
      ? this.projectService.currentSelectedProject.workspace_manager_last_name
      : '';
    const email = this.projectService.currentSelectedProject.workspace_manager_email
      ? this.projectService.currentSelectedProject.workspace_manager_email
      : '';
    const phone = this.projectService.currentSelectedProject.workspace_manager_office_phone
      ? this.projectService.currentSelectedProject.workspace_manager_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Construction Manager',
      email,
      phone,
    };
  }
  get architectInfo(): { name: string; title: string; email: string; phone: string } {
    if (!this.projectService.currentSelectedProject.architect_first_name) {
      return null;
    }
    const first = this.projectService.currentSelectedProject.architect_first_name
      ? this.projectService.currentSelectedProject.architect_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.architect_last_name
      ? this.projectService.currentSelectedProject.architect_last_name
      : '';
    const email = this.projectService.currentSelectedProject.architect_email
      ? this.projectService.currentSelectedProject.architect_email
      : '';
    const phone = this.projectService.currentSelectedProject.architect_office_phone
      ? this.projectService.currentSelectedProject.architect_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Architect',
      email,
      phone,
    };
  }
  get noTenantReviews(): boolean {
    return !this.selectedOption?.sections?.find((s) => s.needs_tenant_approval);
  }

  private async refreshPEBOptions(pebIdToSelect?: number, reload = false) {
    if (this.project?.id) {
      await this.getPEBOptions();
      if (this.PEBOptions?.[0]) {
        this.selectedPEBId = this.PEBOptions?.[0].sections?.[0]?.selected_peb_id ?? null;
        if (pebIdToSelect) {
          const pebOptionToSelect = this.PEBOptions.find((o) => o.id === pebIdToSelect);
          await this.selectOption(pebOptionToSelect ?? this.PEBOptions[0], reload);
        } else {
          const selectedPEBOption = this.PEBOptions.find((o) => o.id === this.selectedPEBId);
          await this.selectOption(selectedPEBOption ?? this.PEBOptions[0], reload);
        }
      }
    }
  }

  formatDollar(oldValue: string | number, includeCommas = false): string {
    if (oldValue !== null) {
      oldValue = +oldValue;
      let oldDecimalCount;
      if (Math.floor(oldValue) === oldValue) {
        oldDecimalCount = 0;
      } else {
        if (!oldValue.toString().includes('.')) {
          oldDecimalCount = 0;
        } else {
          oldDecimalCount = oldValue.toString().split('.')[1].length || 0;
        }
      }
      let newDecimalCount;
      if (!oldDecimalCount || oldDecimalCount <= 2) {
        newDecimalCount = 2;
      } else if (oldDecimalCount <= 4) {
        newDecimalCount = oldDecimalCount;
      } else {
        newDecimalCount = 4;
      }
      const newValue = this.USDollarPipe.transform(oldValue, newDecimalCount, false, includeCommas, false);
      return newValue;
    } else {
      return null;
    }
  }

  blurDollarItem(object: any, property: string) {
    object[property] = this.formatDollar(object[property]);
  }

  blurPercentage(object: any, property: string) {
    object[property] = floor(+object[property], 4);
  }

  private async getPEBOptions() {
    const pebOptions = await this.pebService
      .getPEBsNew(
        ['id', 'title', 'sequence'],
        [{ type: 'field', field: 'project_id', value: this.project?.id?.toString() }]
      )
      .toPromise();
    for (const [i, o] of pebOptions.entries()) {
      if (!o.title) {
        o.title = `Option ${o.sequence ?? i}`;
      }
    }
    this.PEBOptions = pebOptions;
  }

  async updateApprovalProcesses() {
    let canSelect = true;
    let internalTask;
    for (const s of this.selectedOption.sections) {
      s.isExpanded = true;
      if (s.peb_approval_task_id || s.saved_peb_approval_task_id) {
        internalTask = await this.taskService
          .loadTask(s.peb_approval_task_id || s.saved_peb_approval_task_id)
          .toPromise();
      }
      let tenantTask;
      if (s.tenant_approval_task_id || s.saved_tenant_approval_task_id) {
        tenantTask = await this.taskService
          .loadTask(s.tenant_approval_task_id || s.saved_tenant_approval_task_id)
          .toPromise();
      }

      s.approval_process = new PEBApprovalProcess(
        s,
        this.projectTaskService,
        this.modalService,
        this.project?.cfmo_id,
        this.fileService,
        this.dateService,
        this.taskActionsService,
        internalTask,
        tenantTask
      );

      s.tenantReviewCanStart = s.approval_process.tenantCanStart;
      s.tenantReviewHasStarted = !!s.tenant_approval_task_id || !!s.saved_tenant_approval_task_id;
      if (!s.approval_process.canSelectPEB) {
        canSelect = false;
      }
    }
    this.canSelect = canSelect;

    // In order to avoid a larger refactor, we assume all internal reviews are the same
    if (this.selectedOption.sections.length > 0) {
      const firstSection = this.selectedOption.sections[0];
      this.reviewsHaveStarted = !!this.PEBApprovalTaskId || !!this.savedPEBApprovalTaskId;
      this.internalReviewCanStart = firstSection.approval_process?.staffCanStartNew && this.requiredDataIsAdded;
      this.statusText = firstSection.approval_process?.statusText;
      this.PEBRevision = firstSection.peb_revision;
      this.selectedPEBId = firstSection.selected_peb_id;
    }
  }

  async cancelEditing(): Promise<void> {
    this.selectedOption.isEditing = false;
    await this.refreshPEBOptions(this.selectedOption.id, true);
  }

  public async selectOption(optionToSelect: PEBOption, reload = false) {
    // TODO: PEB - if you're editing the current option, confirmation dialog
    if (this.selectedOption?.id !== optionToSelect?.id || !optionToSelect?.isLoaded || reload) {
      this.loaders.selectedOption = true;
      this.selectedOption = optionToSelect;
      if (!optionToSelect.isLoaded) {
        optionToSelect = await this.pebService.getPEBData(optionToSelect.id).toPromise();
        optionToSelect.isLoaded = true;
      }

      this.percentageSubtotalsAreValid = this.pebService.calculateOptionTotals(optionToSelect);
      this.formatOptionValues(optionToSelect);
      optionToSelect.isEditing = false;

      // finding and setting this value in PEBOptions prevents unnecessary reloading
      const foundOptionIndex = this.PEBOptions.findIndex((o) => o.id === optionToSelect.id);
      optionToSelect.title = this.PEBOptions[foundOptionIndex].title;
      this.PEBOptions[foundOptionIndex] = optionToSelect;
      this.selectedOption = this.PEBOptions[foundOptionIndex];
      this.exportShowAllSections = true;
      this.exportSectionsLength = this.selectedOption.sections?.length;
      await this.updateApprovalProcesses();
      this.updateFilteredTrades();
      this.loaders.selectedOption = false;
    }
  }

  private updateFilteredTrades() {
    const optionTradeIds = this.selectedOption.lines.map((l) => l.trade_id);
    this.filteredTrades = this.allTrades.filter((t) => optionTradeIds.indexOf(t.id) === -1);
  }

  private formatOptionValues(option: PEBOption) {
    for (const s of option.sections) {
      s.rental_rate = this.formatDollar(s.rental_rate, true);
      for (const fs of s.funding_sources) {
        for (const i of fs.items) {
          i.value = this.formatDollar(i.value);
        }
      }
    }
  }

  public refreshSectionSF(section: PEBSection) {
    for (const l of section.lines) {
      l.sqft = section.construction_sqft;
      this.tenantLineChanged(l);
    }
  }

  public pebItemChanged(item: PEBItemNew, fundingSource?: PEBFundingSource) {
    item.is_modified = true;
    let value;
    if (this.feeTypeIds.indexOf(item.line_type_id) > -1) {
      const tradeTotal = this.getFundingSourceTradeTotal(fundingSource);
      value = round((item.percentage / 100) * tradeTotal, 4);
      item.value = value;
    } else {
      value = item?.value;
    }
    if (String(value).match(new RegExp(/^\d*\.?\d{0,4}$/))) {
      this.percentageSubtotalsAreValid = this.pebService.calculateOptionTotals(this.selectedOption);
    }
  }

  changeCalculationType(section: PEBSection, item: PEBItemNew, calculationTypeId: number) {
    let lineItems = [];
    const foundLine = section.lines.find((l) => l.id === item.line_id);
    foundLine.calculation_type_id = calculationTypeId;
    for (const fs of section.funding_sources) {
      lineItems = [...lineItems, ...fs.items.filter((i) => i.line_id === item.line_id)];
    }
    for (const i of lineItems) {
      i.calculation_type_id = calculationTypeId;
      i.is_modified = true;
    }
    this.percentageSubtotalsAreValid = this.pebService.calculateOptionTotals(this.selectedOption);
  }

  lineSubtotalChanged(section: PEBSection, line: PEBLine) {
    if (line.calculation_type_id === 2) {
      let lineItems = [];
      for (const fs of section.funding_sources) {
        lineItems = [...lineItems, ...fs.items.filter((i) => i.line_id === line.id)];
      }
      for (const i of lineItems) {
        i.value = this.formatDollar(round((i.percentage / 100) * +line.subtotal, 4));
        i.is_modified = true;
      }
      this.percentageSubtotalsAreValid = this.pebService.calculateOptionTotals(this.selectedOption);
    }
  }

  private getFundingSourceTradeTotal(fundingSource: PEBFundingSource) {
    const tradeItems = fundingSource.items.filter((i) => i.line_type_id === 1);
    const tradeTotal = sumBy(tradeItems, (i) => +i.value);
    return tradeTotal;
  }

  private updatePercentageLineItems(option) {
    for (const s of option?.sections ?? []) {
      for (const fs of s.funding_sources) {
        const tradeTotal = this.getFundingSourceTradeTotal(fs);
        const nonManagementFeeTypes = this.feeTypeIds.filter((ftid) => ftid !== 6);
        const nonManagementFeeItems = fs.items.filter((fsi) => nonManagementFeeTypes.indexOf(fsi.line_type_id) > -1);
        let tradeAndFeeTotal = tradeTotal;
        for (const i of nonManagementFeeItems) {
          i.value = this.formatDollar(round((i.percentage / 100) * tradeTotal, 4));
          tradeAndFeeTotal += +i.value;
          i.is_modified = true;
        }
        const managementFeeItems = fs.items.filter((fsi) => fsi.line_type_id === 6);
        for (const i of managementFeeItems) {
          i.value = this.formatDollar(round((i.percentage / 100) * tradeAndFeeTotal, 4));
          i.is_modified = true;
        }
      }
    }
  }

  public tenantLineChanged(line: PEBTenantLine) {
    line.is_modified = true;
  }

  public async addPEBTradeLine(trade) {
    const pebLineToCreate = {
      peb_id: this.selectedOption.id,
      type_id: 1,
      trade_id: trade.id,
    };
    const newPEBLine = await this.pebService.createPEBLine(pebLineToCreate).toPromise();
    newPEBLine.name = trade.name;
    this.selectedOption.lines.push(newPEBLine);
    for (const s of this.selectedOption.sections) {
      const pebTenantLineToCreate = {
        tenant_id: s.tenant_id,
        line_id: newPEBLine.id,
        sqft: 0,
      };
      const newPEBTenantLine = await this.pebService.createPEBTenantLine(pebTenantLineToCreate).toPromise();
      const pebTenantLine = {
        ...newPEBLine,
        sqft: newPEBTenantLine.sqft,
        tenant_line_id: newPEBTenantLine.id,
      };
      s.lines.push(pebTenantLine);
      for (const fs of s.funding_sources) {
        const newItem: PEBItemNew = {
          is_new: true,
          funding_source_id: fs.id,
          line_id: newPEBLine.id,
          value: '0.00',
          line_type_id: 1,
        };
        fs.items.push(newItem);
      }
    }
    await this.savePEBOption(true);
    this.updateFilteredTrades();
    this.percentageSubtotalsAreValid = this.pebService.calculateOptionTotals(this.selectedOption);
    this.snackbar.open(`PEB Line '${trade.name}' added!`);
  }

  public openPebSectionDialog(sectionToSelect?) {
    const dialogRef = this.dialog
      .open(PEBSectionDialogComponent, {
        data: {
          projectId: this.project.id,
          sections: this.selectedOption?.sections ?? [],
          buildingCirculationFactor: (this.project.building_circulation_factor ?? 0) / 100,
          sectionIdToSelect: sectionToSelect?.tenant_id,
          canEdit: this.canEdit,
        },
        autoFocus: false,
        width: '800px',
      })
      .afterClosed()
      .subscribe(async (shouldRefresh) => {
        if (shouldRefresh) {
          await this.refreshPEBOptions(this.selectedOption?.id);
        } else {
          this.selectedOption.sections = this.selectedOption.sections.filter((s) => !s.isNew);
        }
      });
  }

  public toggleSectionExpansion(section: PEBSection) {
    section.isExpanded = !section.isExpanded;
  }

  public async updateSectionData(section: PEBSection, singleSection = true) {
    this.selectedSection = section;
    for (const l of this.selectedSection.lines) {
      l.funding_sources = [];
      for (const fs of this.selectedSection.funding_sources) {
        const foundItem = fs.items.find((i) => i.line_id === l.id);
        l.funding_sources.push({ item_value: foundItem.value ?? 0 });
      }
    }
    this.coverLetterSection = {
      first_name: section.representative_first_name,
      name: `${section.representative_first_name} ${section.representative_last_name}`,
      title: section.representative_title ?? '',
      department: section.tenant_name,
      peb_cover_letter_text: section.peb_cover_letter_text,
    };

    if (singleSection) {
      this.exportShowAllSections = false;
      this.exportSectionsLength = this.selectedOption.sections?.filter(
        (s) => s.tenant_id === section.tenant_id
      )?.length;
    } else {
      this.exportShowAllSections = true;
      this.exportSectionsLength = this.selectedOption.sections?.length;
    }

    const tenantsForReviewers = this.selectedOption.sections.map((s) => ({
      id: s.tenant_id,
      peb_approval_task_id: s.peb_approval_task_id,
    }));
    const reviewers = await this.displayReviewersService.displayReviewers(tenantsForReviewers, ReviewType.PEB);
    if (reviewers && section?.tenant_id && reviewers?.[section?.tenant_id]) {
      this.currentReviewers = reviewers?.[section?.tenant_id];
    } else {
      this.currentReviewers = [];
    }
  }

  public async openSectionDrawer(section: PEBSection) {
    await this.updateSectionData(section);
    await this.sectionDrawer.open();
    await this.sectionDetailsComponent.load(section);
  }

  public async deleteSection(section: PEBSection) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Deleting Section...');
    await this.projectTenantService.unlinkTenantFromProject(section.tenant_id).toPromise();
    this.selectedOption.sections = this.selectedOption.sections.filter((s) => s.id !== section.id);
    this.progressIndicatorService.close();
  }

  public async createPEBOption() {
    this.loaders.selectedOption = true;
    const pebToCreate = { project_id: this.project?.id };
    const createdPEB = await this.pebService.createPEBNew(pebToCreate).toPromise();
    await this.refreshPEBOptions(createdPEB?.id);
  }

  deletePEBOption(option: PEBOption) {
    console.log('TODO: delete option');
  }

  async deleteTrade(line: PEBLine) {
    const res = await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Delete Trade',
        descriptionText: 'Are you sure you want to delete this trade?',
        confirmationButtonText: 'Delete',
      })
      .toPromise();

    if (res) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Deleting Trade...');
      await this.pebService.deletePEBLine(line.id).toPromise();
      this.selectedOption.lines = this.selectedOption.lines.filter((l) => l.id !== line.id);
      // remove the info rather than refresh. Since refreshing would break the editing experience
      for (const section of this.selectedOption.sections) {
        section.lines = section.lines.filter((l) => l.trade_id !== line.trade_id);
        for (const fs of section.funding_sources) {
          fs.items = fs.items.filter((i) => i.line_id !== line.id);
        }

        this.percentageSubtotalsAreValid = this.pebService.calculateOptionTotals(this.selectedOption);
      }
      this.progressIndicatorService.close();
    }
  }

  public async savePEBOption(continueEditing?) {
    if (!(await this.PEBchanged()) && !continueEditing) {
      this.selectedOption.isEditing = false;
      return;
    }

    if ((this.PEBApprovalTaskId && (await this.resetReview()) === true) || !this.PEBApprovalTaskId) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving Option...');
      // We might want to do a better job detecting changes here before deciding to make an API call
      const updatedPEB = await this.pebService.updatePEBData(this.selectedOption).toPromise();
      if (!updatedPEB) {
        this.snackbar.open('PEB could not be saved - PEB ID is missing');
      } else {
        await this.refreshPEBOptions(this.selectedOption?.id);
        this.selectedOption.isEditing = continueEditing ? true : false;
        this.progressIndicatorService.close();
        this.snackbar.open('PEB Saved!');
      }
    }
  }

  public async exportPEB() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Exporting...');
    await this.combineFiles(this.selectedOption?.sections, true, FileAction.Download);
    this.progressIndicatorService.close();
  }

  public finalizePEB() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Finalize PEB',
        headerText: 'Finalize PEB',
        confirmationButtonText: 'Finalize PEB',
        descriptionText:
          'Warning: Finalizing is permanent. Please verify that the selected PEB is 100% correct, as no more changes can be made after finalizing. Are you sure you want to finalize the PEB?',
      })
      .subscribe(async (closeEvent) => {
        if (closeEvent) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Finalizing PEB...');
          for (const s of this.selectedOption.sections) {
            this.selectedOption.isSelected = true;
            this.selectedPEBId = this.selectedOption.id;
            this.project.peb_status = PEBStatus.Finalized;
            await this.projectTenantService
              .updateProjectTenant(s.tenant_id, {
                selected_peb_id: this.selectedOption.id,
                peb_status: ProjectTenantPEBStatus.Finalized,
              })
              .toPromise();
            s.selected_peb_id = this.selectedOption.id;
            s.peb_status = ProjectTenantPEBStatus.Finalized;
            const tasksToLock = [s.peb_approval_task_id, s.tenant_approval_task_id];
            for (const taskId of tasksToLock) {
              if (taskId) {
                await this.changeTaskLockStatus(taskId);
              }
            }
          }
          this.progressIndicatorService.updateStatus('Refreshing Reviews...');
          await this.updateApprovalProcesses();
          this.progressIndicatorService.close();
          this.snackbar.open('PEB Finalized!');
        }
      });
  }

  async unFinalizePEB() {
    const closeEvent = await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Un-finalize PEB',
        headerText: 'Un-finalize PEB',
        confirmationButtonText: 'Un-Finalize PEB',
        descriptionText:
          'Warning: Please verify that you would like to un-finalize this PEB, this will reset all reviews?',
      })
      .toPromise();

    if (closeEvent) {
      for (const s of this.selectedOption.sections) {
        this.selectedOption.isSelected = true;
        this.selectedPEBId = this.selectedOption.id;
        this.project.peb_status = PEBStatus.SubmittedForInternalApproval;
        await this.projectTenantService
          .updateProjectTenant(s.tenant_id, {
            selected_peb_id: null,
            peb_status: null,
          })
          .toPromise();
        s.selected_peb_id = this.selectedOption.id;
        s.peb_status = null;
      }
    }

    return closeEvent;
  }
  // -----------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------
  // --------------------------------------------Reviews--------------------------------------------
  // -----------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------

  public async submitForInternalReview() {
    if (!this.PEBOptions?.length) {
      this.snackbar.open('No PEBs exist!');
      return;
    }
    if (!this.selectedOption?.sections.length) {
      this.snackbar.open('No sections exist for this PEB!');
      return;
    }
    let hasRequiredFiles = true;
    let refresh = true;
    let errorSection;
    for (const s of this.selectedOption.sections) {
      if (!s.bubble_drawing_ids || !s.bubble_drawing_ids.length) {
        errorSection = { name: s.tenant_name, file: 'Bubble Drawing' };
        hasRequiredFiles = false;
        break;
      }
      if (!s.approval_process?.hasFilesNew) {
        errorSection = {
          name: s.tenant_name,
          file: 'Sublease Contract, Reimbursement Agreement, Exhibit B, or Amortization Schedule',
        };
        hasRequiredFiles = false;
        break;
      }
    }
    if (!hasRequiredFiles) {
      this.snackbar.open(`Required files missing: ${errorSection.name} - ${errorSection.file}`);
      return;
    }
    const section = this.selectedOption.sections[0];

    if (section.saved_peb_approval_task_id) {
      await this.restartReviewProcess(section);
    } else {
      refresh = await this.startInternalReviewProcess(section);
    }

    if (refresh) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Refreshing Reviews...');
      await this.updateApprovalProcesses();
      this.progressIndicatorService.close();
    }
  }

  public async submitForTenantReview(sections, refreshData = true) {
    if (!this.PEBOptions?.length) {
      this.snackbar.open('No PEBs exist for this tenant!');
      return;
    }
    let updateTenantReview = true;
    if (this.tenantReviewCanStart) {
      updateTenantReview = await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Tenant Review',
          headerText: 'Start Tenant Review',
          descriptionText: `This action will create ${sections?.length} tenant review task${
            this.selectedOption?.sections?.length > 1 ? 's' : ''
          }. Please verify that the internal review has been properly approved and you're ready to send this budget for tenant review.`,
          confirmationButtonText: `Start Tenant Review${this.selectedOption?.sections?.length > 1 ? 's' : ''}`,
        })
        .toPromise();
    }

    if (updateTenantReview) {
      let index = 1;
      for (const section of sections) {
        if (section.needs_tenant_approval) {
          if (section.saved_tenant_approval_task_id) {
            await this.restartReviewProcess(section, false, index);
          } else if (this.tenantReviewCanStart) {
            await this.startTenantReview(section, index);
          }
          index++;
        }
      }

      if (refreshData) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Refreshing Reviews...');
        await this.updateApprovalProcesses();
        this.progressIndicatorService.close();
      }
    }
  }

  async PEBchanged() {
    let current = await this.pebService.getPEBData(this.selectedOption.id).toPromise();
    current = this.pebService.mapBugetForComparison(current);
    const selectedOption = this.pebService.mapBugetForComparison(this.selectedOption);
    return JSON.stringify(selectedOption) !== JSON.stringify(current);
  }

  // stops the review process and adds to revision if not reset
  public async resetReview(reset = false) {
    let resetReview = true;
    if (this.isFinalized) {
      resetReview = await this.unFinalizePEB();
    }

    if (resetReview) {
      const section = this.selectedOption.sections[0];
      await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Reset Approval Process',
          descriptionText:
            'You are about to reset the approval process, are you sure? This will invalidate any existing approvals and require a new approval.',
          confirmationButtonText: 'Submit',
          userInput: {
            required: true,
            placeholder: 'Reason for Reset',
          },
        })
        .toPromise()
        .then(async (res) => {
          if (res) {
            this.progressIndicatorService.openAwaitIndicatorModal();
            this.progressIndicatorService.updateStatus('Resetting Approval');

            if (section.peb_approval_task_id) {
              await this.reviewRevisionService.pebInternalRevision({ id: section.tenant_id, ...section }, reset, res);
              section.saved_peb_approval_task_id = section.peb_approval_task_id;
              section.peb_approval_task_id = null;
              section.peb_revision = section.peb_revision + 1;
            }
            if (section.tenant_approval_task_id || section.saved_tenant_approval_task_id) {
              for (const s of this.selectedOption.sections) {
                if (s.tenant_approval_task_id) {
                  await this.reviewRevisionService.pebTenantRevision({ id: s.tenant_id, ...s }, reset, res);
                  s.saved_tenant_approval_task_id = s.tenant_approval_task_id;
                  s.tenant_approval_task_id = null;
                  s.peb_revision = (section.saved_peb_approval_task_id && s.peb_revision) || s.peb_revision + 1;
                }
              }
            }

            if (reset) {
              const taskId = section.peb_approval_task_id;
              const updatedAccessoryData = await this.displayReviewersService.getCurrentReviewers(taskId);

              if (!updatedAccessoryData.isTheSame) {
                const taskData = {
                  id: taskId,
                  accessory_data: JSON.stringify(updatedAccessoryData.accessoryData),
                  assigned_user_id: updatedAccessoryData.accessoryData.reviewChain[0].id,
                  status_id: TaskStatus.Open,
                };
                await this.projectService.updateTask(taskData).toPromise();
              }
            }

            this.progressIndicatorService.updateStatus('Refreshing Reviews...');
            await this.updateApprovalProcesses();
            this.progressIndicatorService.close();
            this.snackbar.open('The review process has been reset');
            return true;
          } else {
            resetReview = false;
          }
        });
    }
    return resetReview;
  }

  private async startInternalReviewProcess(section: PEBSection) {
    const res = await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Start Review',
        headerText: 'Start Internal Review',
        descriptionText:
          'Starting the internal review process will automatically create a review task and lock the PEB page from editing.',
        confirmationButtonText: 'Start',
      })
      .toPromise();

    if (res) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Generating files for task creation...');

      // Generate PDF Files from these tenant PDFs
      const pebFiles = [await this.combineFiles(this.selectedOption.sections)];
      const linkedFiles = [];
      for (const s of this.selectedOption.sections) {
        for (const fileId of s.bubble_drawing_ids) {
          linkedFiles.push(await this.fileService.getIdAndName(fileId).toPromise());
        }
      }

      const reviewers = await this.displayReviewersService.getInternalReviewers();
      const reviewIds = reviewers.reviewIds;
      const selectedReviewers = reviewers.selectedReviewers;

      this.progressIndicatorService.updateStatus('Creating review task...');
      // Opens create task dialog while passing some predetermined info
      const newTaskId = await section.approval_process.beginStaffReview({
        pebName:
          (this.project.title ? this.project.title : 'Project ' + this.project.code) +
          ' (' +
          (section.type_id === 3 ? 'UHAT' : section.tenant_name) +
          ')',
        files: pebFiles,
        linkedFiles,
        reviewIds,
        areFilesLinked: false,
        selectedReviewers,
        parentId: section?.tenant_id,
      });

      if (newTaskId) {
        this.progressIndicatorService.updateStatus('Updating Review Data...');
        // If the user creating the review task is the same as the first reviewers this creates a task activity on the new task
        if (this.authService.getLoggedInUser().id === selectedReviewers[0].id) {
          await this.eventService
            .createTaskApprovalEvent(newTaskId, TaskReviewStatus.Approved, 'Approved upon creation')
            .toPromise();
        }

        await this.projectTenantService
          .updateProjectTenant(section.tenant_id, { peb_approval_task_id: newTaskId })
          .toPromise();
        section.peb_approval_task_id = newTaskId;
        this.snackbar.open('Approval Process Started and Task Created');
      }
    }

    return res;
  }

  private async startTenantReview(section: PEBSection, index = 0) {
    const countProgressMessage = (index && `[${index}/${this.tenantReviews?.length}] `) || '';
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus(`${countProgressMessage}Generating files for task...`);

    // Generate PDF Files from this tenants PDFs
    const pebFiles = [await this.combineFiles([section], false)];
    const reviewIds = [{ id: section.representative_id, status: TaskReviewStatus.Pending }];
    const assigned_user = {
      id: section.representative_id,
    };
    this.progressIndicatorService.updateStatus(`${countProgressMessage}Creating Task...`);
    const newTaskId = await section.approval_process.beginTenantReview({
      pebName:
        (this.project.title ? this.project.title : 'Project ' + this.project.code) +
        ' (' +
        (section.type_id === 3 ? 'UHAT' : section.tenant_name) +
        ')',
      files: pebFiles,
      reviewIds,
      areFilesLinked: false,
      assigned_user,
    });

    if (newTaskId) {
      await this.projectTenantService
        .updateProjectTenant(section.tenant_id, {
          tenant_approval_task_id: newTaskId,
          peb_status: ProjectTenantPEBStatus.AwaitingTenantApproval,
        })
        .toPromise();
      section.tenant_approval_task_id = newTaskId;
      this.progressIndicatorService.updateStatus(`${countProgressMessage}Task Created...`);
      this.snackbar.open('Approval Process Started and Task Created');
      if (this.sectionDrawer.opened) {
        await this.sectionDrawer.close();
      }
    } else {
      this.snackbar.open('Error: there was an issue creating the task');
    }
    this.progressIndicatorService.close();
  }
  // TODO: PEB - Revise Review
  private async restartReviewProcess(section, isInternal = true, index = 0) {
    const countProgressMessage = (index && `[${index}/${this.tenantReviews?.length}] `) || '';
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus(`${countProgressMessage}Generating files for task...`);
    this.snackbar.open('Restarting Review');

    if (isInternal) {
      const linkedFiles = [];
      for (const s of this.selectedOption.sections) {
        for (const fileId of s.bubble_drawing_ids) {
          linkedFiles.push(await this.fileService.getIdAndName(fileId).toPromise());
        }
      }

      const internalFile = [await this.combineFiles(this.selectedOption.sections)];
      await this.reviewRevisionService.internalPebSubmitRevision(
        { id: section.tenant_id, ...section },
        internalFile,
        48,
        linkedFiles
      );

      section.peb_approval_task_id = section.saved_peb_approval_task_id;
      section.saved_peb_approval_task_id = null;
    } else {
      const tenantFile = [await this.combineFiles([section], false)];
      await this.reviewRevisionService.tenantPebSubmitRevision({ id: section.tenant_id, ...section }, tenantFile, 48);
      section.tenant_approval_task_id = section.saved_tenant_approval_task_id;
      section.saved_tenant_approval_task_id = null;
    }
  }

  private async combineFiles(sections?: PEBSection[], isInternal = true, fileAction = FileAction.Return) {
    const files = [];
    for (const singleSection of sections) {
      this.selectedSectionIndex += 1;
      await this.updateSectionData(singleSection, !isInternal);

      this.appRef.tick();
      // Generate Cover Letter Export
      const coverLetterGroup = await this.coverLetter.export();
      const coverLetterBase64 = (await exportPDF(coverLetterGroup)).replace('data:application/pdf;base64,', '');
      const coverLetterByteCharacters = atob(coverLetterBase64);
      const coverLetterData = new Array(coverLetterByteCharacters.length);
      for (let i = 0; i < coverLetterByteCharacters.length; i++) {
        coverLetterData[i] = coverLetterByteCharacters.charCodeAt(i);
      }
      let coverLetterTitle = 'Cover_Letter_' + (singleSection.type_id === 3 ? 'UHAT' : singleSection.tenant_name);
      // replaced with this regex to replace ALL occurances, rather than just the first
      // '/ /g' means every space, not just the first
      coverLetterTitle = coverLetterTitle.replace(/ /g, '_');
      // Combine Files
      files.push({
        file: new Blob([new Uint8Array(coverLetterData)]),
        name: `${coverLetterTitle}_v${singleSection.peb_revision}.pdf`,
      });

      await this.delay(100); // Delay so that the HTML has time to update from the (exportingPEB binding reassignment)
      // We must export the Kendo PDF which returns a Kendo Group object
      const pebGroup = await this.pdf.export();

      const pebBase64 = (await exportPDF(pebGroup)).replace('data:application/pdf;base64,', '');
      const pebByteCharacters = atob(pebBase64);
      const pebData = new Array(pebByteCharacters.length);
      for (let i = 0; i < pebByteCharacters.length; i++) {
        pebData[i] = pebByteCharacters.charCodeAt(i);
      }

      let title =
        this.selectedOption.title != null && this.selectedOption.title !== ''
          ? this.selectedOption.title
          : `PEB_${this.selectedOption.sequence}`;
      title = title.replace(/ /g, '_');

      files.push({
        file: new Blob([new Uint8Array(pebData)]),
        name: `${title}_v${this.PEBRevision}.pdf`,
      });
      const linkedFiles = await this.getLinkedFiles(singleSection);

      for (const f of linkedFiles) {
        const linkedFile = await this.fileService.downloadFile({ id: f.id }).toPromise();
        files.push({
          file: new Blob([new Uint8Array(linkedFile.file.data)]),
          name: linkedFile.name,
        });
      }
    }

    if (isInternal) {
      await this.delay(100);
      const summaryGroup = await this.summary.export();
      const summaryBase64 = (await exportPDF(summaryGroup)).replace('data:application/pdf;base64,', '');
      const summaryByteCharacters = atob(summaryBase64);
      const summaryData = new Array(summaryByteCharacters.length);
      for (let i = 0; i < summaryByteCharacters.length; i++) {
        summaryData[i] = summaryByteCharacters.charCodeAt(i);
      }
      let summaryTitle = 'Summary_' + (sections[0]?.type_id === 3 ? 'UHAT' : sections[0]?.tenant_name);
      // replaced with this regex to replace ALL occurances, rather than just the first
      // '/ /g' means every space, not just the first
      summaryTitle = summaryTitle.replace(/ /g, '_');
      // Combine Files
      files.push({
        file: new Blob([new Uint8Array(summaryData)]),
        name: `${summaryTitle}_v${sections[0]?.peb_revision}.pdf`,
      });
    }

    let combinedFile;
    try {
      combinedFile = await this.fileService.combinePDFs(files).toPromise();
    } catch (e) {
      const errorSnack = this.snackbar.open(e.error.message, 'Close', { duration: undefined });
      errorSnack.onAction().subscribe(async () => {
        this.snackbar.dismiss();
      });
      return;
    }

    const pebTitle = `PEB_v${this.PEBRevision}_PRJ${this.project.code}.pdf`;
    if (fileAction === FileAction.Return) {
      const blob = new Blob([new Uint8Array(combinedFile.data)], { type: 'application/pdf' });
      return new File([blob], pebTitle);
    } else {
      saveAs(new Blob([new Uint8Array(combinedFile.data)]), pebTitle);
    }
  }

  private getLinkedFiles(singleSection: PEBSection) {
    let sections;
    if (singleSection) {
      sections = [singleSection];
    } else {
      sections = this.selectedOption.sections;
    }
    const linkedFiles = [];
    for (const section of sections) {
      // for (let i = 0; i < section.bubble_drawing_ids.length; i++) {
      //   linkedFiles.push({ id: section.bubble_drawing_ids[i], name: 'Bubble_Drawing_' + i });
      // }
      if (section.reimbursement_file_id != null) {
        linkedFiles.push({ id: section.reimbursement_file_id, name: 'Reimbursement Agreement' });
      }
      if (section.sublease_contract_file_id != null) {
        linkedFiles.push({ id: section.sublease_contract_file_id, name: 'Sublease Contract' });
      }
      if (section.exhibit_b_file_id != null) {
        linkedFiles.push({ id: section.exhibit_b_file_id, name: 'Exhibit B' });
      }
      if (section.amortization_file_id != null) {
        linkedFiles.push({ id: section.amortization_file_id, name: 'Amortization Schedule' });
      }
    }
    return linkedFiles;
  }

  /**
   * Awaitable Delay function. Could probably be moved out to a Utility Service
   * @param ms Milliseconds to delay for.
   */
  private delay(ms: number): Promise<{}> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Attach commas to a number.
   * @param x Number to apply commas to
   */
  public numberWithCommas(x: number): string {
    if (!x) {
      return '';
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // -----------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------
  // ------------------------------------------End Reviews------------------------------------------
  // -----------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment().format('LTS');
  }

  /**
   * Get the current date in the format August 15, 2019
   */
  public getCurrentDate(): string {
    return moment().format('LL');
  }

  public async viewTenantTasks() {
    const tenantTasks = [];
    let taskId;
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Getting Task Data...');
    for (const section of this.selectedOption?.sections) {
      taskId =
        section.needs_tenant_approval && (section.tenant_approval_task_id || section.saved_tenant_approval_task_id);
      if (taskId) {
        tenantTasks.push(
          section.approval_process?._tenantReview?.getTask ||
            (await this.projectService.getTaskById(taskId).toPromise())
        );
      }
    }

    this.progressIndicatorService.close();
    if (tenantTasks?.length > 1) {
      const data = {
        tasks: tenantTasks,
        dontFilterTasks: true,
      };
      await this.modalService.openTaskOverviewDialog(data).toPromise();
    } else {
      await this.viewTask(tenantTasks[0]?.id);
    }
  }

  public viewTask(taskId: number) {
    if (taskId) {
      const dialogRef = this.dialog.open(ViewTaskDialogComponent, {
        data: {
          taskId,
        },
        autoFocus: false,
      });
      dialogRef.componentInstance.reviewChanged.subscribe(async () => {
        await this.updateApprovalProcesses();
      });
    }
  }

  public async internalReviewAction(taskId: number) {
    if (taskId) {
      this.viewTask(taskId);
    } else if (this.internalReviewCanStart || this.savedPEBApprovalTaskId) {
      await this.submitForInternalReview();
    }
  }

  public async tenantReviewAction(): Promise<void> {
    if (!this.sectionsThatNeedTenantReviews?.length) {
      await this.viewTenantTasks();
    } else if (this.tenantReviewCanStart) {
      await this.submitForTenantReview(this.sectionsThatNeedTenantReviews);
    }
  }

  public async changeTaskLockStatus(taskId, isLocked = 1) {
    const task = {
      id: taskId,
      is_locked: isLocked,
    };

    await this.projectService.updateTask(task).toPromise();
  }

  public async saveCoverLetter(data) {
    if ((this.PEBApprovalTaskId && (await this.resetReview()) === true) || !this.PEBApprovalTaskId) {
      if (data.tenant?.id) {
        await this.projectTenantService
          .updateProjectTenant(data.tenant.id, { peb_cover_letter_text: data.tenant.cover_letter_text })
          .toPromise();
        data.section.peb_cover_letter_text = data.tenant.cover_letter_text;
        this.snackbar.open('Your changes have been saved.');
      }
    }
  }

  // Helper function for attaching supporting documents
  private _attachFileHelper(preSelectedTags?: { id: number }[]) {
    let data;
    if (preSelectedTags) {
      data = {
        parentResourceType: ResourceType.Project,
        parentResourceId: this.projectService.currentSelectedProjectId,
        maxFiles: 1,
        allowComment: false,
        verifyFileExtension: true,
        preSelectedTags,
      };
    } else {
      data = {
        parentResourceType: ResourceType.Project,
        parentResourceId: this.projectService.currentSelectedProjectId,
        maxFiles: 1,
        allowComment: false,
        verifyFileExtension: true,
      };
    }
    return this.dialog
      .open(FileAttachmentDialogComponent, {
        data,
        disableClose: true,
      })
      .afterClosed();
  }

  async uploadBubbleDrawing(section) {
    if ((this.PEBApprovalTaskId && (await this.resetReview()) === true) || !this.PEBApprovalTaskId) {
      this._attachFileHelper([{ id: 13 }]).subscribe(async (filesToAttach: UhatFileReference[]) => {
        if (filesToAttach) {
          const file = filesToAttach[0];
          section.bubble_drawing_ids.push(file.file_id);
          await this.projectTenantService
            .updateProjectTenant(section.tenant_id, {
              bubble_drawing_ids: `[${section.bubble_drawing_ids.join(',')}]`,
            })
            .toPromise();
          await this.fileService.addTags(file.file_id, [13]).toPromise();
          section.approval_process.loadData(
            section,
            this.modalService,
            this.fileService,
            this.dateService,
            this.taskActionsService
          );
          this.updateApprovalProcesses();
          this.snackbar.open('Bubble Drawing Has Been Attached');
        }
      });
    }
  }

  async uploadReimbursementAgreement(section) {
    if ((this.PEBApprovalTaskId && (await this.resetReview()) === true) || !this.PEBApprovalTaskId) {
      this._attachFileHelper([{ id: 43 }]).subscribe(async (filesToAttach: UhatFileReference[]) => {
        if (filesToAttach) {
          const file = filesToAttach[0];
          await this.projectTenantService
            .updateProjectTenant(section.tenant_id, { reimbursement_file_id: file.file_id })
            .toPromise();
          await this.fileService.addTags(file.file_id, [43]).toPromise();
          section.reimbursement_file_id = file.file_id;
          section.reimbursement_file_name = file.name;
          this.updateApprovalProcesses();
          this.snackbar.open('Reimbursement Agreement Has Been Attached');
        }
      });
    }
  }

  async uploadSubleaseAgreement(section) {
    if ((this.PEBApprovalTaskId && (await this.resetReview()) === true) || !this.PEBApprovalTaskId) {
      this._attachFileHelper([{ id: 46 }]).subscribe(async (filesToAttach: UhatFileReference[]) => {
        if (filesToAttach) {
          const file = filesToAttach[0];
          await this.projectTenantService
            .updateProjectTenant(section.tenant_id, { sublease_contract_file_id: file.file_id })
            .toPromise();
          await this.fileService.addTags(file.file_id, [46]).toPromise();
          section.sublease_contract_file_id = file.file_id;
          section.sublease_contract_file_name = file.name;
          this.updateApprovalProcesses();
          this.snackbar.open('Sublease Contract Has Been Attached');
        }
      });
    }
  }

  async uploadExhibitB(section) {
    if ((this.PEBApprovalTaskId && (await this.resetReview()) === true) || !this.PEBApprovalTaskId) {
      this._attachFileHelper([{ id: 44 }]).subscribe(async (filesToAttach: UhatFileReference[]) => {
        if (filesToAttach) {
          const file = filesToAttach[0];
          await this.projectTenantService
            .updateProjectTenant(section.tenant_id, { exhibit_b_file_id: file.file_id })
            .toPromise();
          await this.fileService.addTags(file.file_id, [44]).toPromise();
          section.exhibit_b_file_id = file.file_id;
          section.exhibit_b_file_name = file.name;
          this.updateApprovalProcesses();
          this.snackbar.open('Exhibit B Has Been Attached');
        }
      });
    }
  }

  async uploadAmortizationSchedule(section) {
    if ((this.PEBApprovalTaskId && (await this.resetReview()) === true) || !this.PEBApprovalTaskId) {
      this._attachFileHelper([{ id: 98 }]).subscribe(async (filesToAttach: UhatFileReference[]) => {
        if (filesToAttach) {
          const file = filesToAttach[0];
          await this.projectTenantService
            .updateProjectTenant(section.tenant_id, { amortization_file_id: file.file_id })
            .toPromise();
          await this.fileService.addTags(file.file_id, [98]).toPromise();
          section.amortization_file_id = file.file_id;
          section.amortization_file_name = file.name;
          this.updateApprovalProcesses();
          this.snackbar.open('Amortization Schedule Has Been Attached');
        }
      });
    }
  }

  public async removeBubbleDrawing(data) {
    if ((this.PEBApprovalTaskId && (await this.resetReview()) === true) || !this.PEBApprovalTaskId) {
      this.modalService
        .openConfirmationDialog({
          titleBarText: 'Remove Bubble Drawing',
          headerText: 'Remove Bubble Drawing',
          confirmationButtonText: 'Remove',
          descriptionText:
            'Are you sure you want to detach the bubble drawing? It will still be available in Project Files.',
        })
        .subscribe(async (confirmed) => {
          if (confirmed) {
            // remove the bubble drawing tag from this file (id = 13)
            await this.fileService.removeTags(data.fileId, [13]).toPromise();
            data.section.bubble_drawing_ids.splice(data.section.bubble_drawing_ids.indexOf(data.fileId), 1);
            await this.projectTenantService
              .updateProjectTenant(data.section.tenant_id, {
                bubble_drawing_ids: `[${data.section.bubble_drawing_ids.join(',')}]`,
              })
              .toPromise();
            this.updateApprovalProcesses();
            this.snackbar.open('Bubble Drawing Has Been Removed');
            data.section.approval_process
              .loadData(data.section, this.modalService, this.fileService, this.dateService, this.taskActionsService)
              .then();
          }
        });
    }
  }

  public async removeReimbursement(section) {
    if ((this.PEBApprovalTaskId && (await this.resetReview()) === true) || !this.PEBApprovalTaskId) {
      this.modalService
        .openConfirmationDialog({
          headerText: 'Remove Reimbursement Agreement',
          descriptionText:
            'Are you sure you want to detach the Reimbursement Agreement? It will still be available in Project Files.',
        })
        .subscribe(async (confirmed) => {
          if (confirmed) {
            // remove the reimbursement tag from this file (id = 43)
            await this.fileService.removeTags(section.reimbursement_file_id, [43]).toPromise();
            await this.projectTenantService
              .updateProjectTenant(section.tenant_id, { reimbursement_file_id: null })
              .toPromise();
            section.reimbursement_file_id = null;
            section.reimbursement_file_name = null;
            this.updateApprovalProcesses();
            this.snackbar.open('Reimbursement Agreement has been removed');
          }
        });
    }
  }

  public async removeSubleaseContract(section) {
    if ((this.PEBApprovalTaskId && (await this.resetReview()) === true) || !this.PEBApprovalTaskId) {
      this.modalService
        .openConfirmationDialog({
          titleBarText: 'Remove Sublease Contract',
          descriptionText:
            'Are you sure you want to detach the Sublease Contract? It will still be available in Project Files.',
        })
        .subscribe(async (confirmed) => {
          if (confirmed) {
            // remove the sublease contract tag from this file (id = 46)
            await this.fileService.removeTags(section.sublease_contract_file_id, [46]).toPromise();
            await this.projectTenantService
              .updateProjectTenant(section.tenant_id, { sublease_contract_file_id: null })
              .toPromise();
            section.sublease_contract_file_id = null;
            section.sublease_contract_file_name = null;
            this.updateApprovalProcesses();
            this.snackbar.open('Sublease contract has been removed');
          }
        });
    }
  }

  public async removeExhibitB(section) {
    if ((this.PEBApprovalTaskId && (await this.resetReview()) === true) || !this.PEBApprovalTaskId) {
      this.modalService
        .openConfirmationDialog({
          headerText: 'Remove Exhibit B',
          descriptionText:
            'Are you sure you want to detach the Exhibit B? It will still be available in Project Files.',
        })
        .subscribe(async (confirmed) => {
          if (confirmed) {
            // remove the exhibit b tag from this file (id = 44)
            await this.fileService.removeTags(section.exhibit_b_file_id, [44]).toPromise();
            await this.projectTenantService
              .updateProjectTenant(section.tenant_id, { exhibit_b_file_id: null })
              .toPromise();
            section.exhibit_b_file_id = null;
            section.exhibit_b_file_name = null;
            this.updateApprovalProcesses();
            this.snackbar.open('Exhibit B has been removed');
          }
        });
    }
  }

  public async removeAmortizationSchedule(section) {
    if ((this.PEBApprovalTaskId && (await this.resetReview()) === true) || !this.PEBApprovalTaskId) {
      this.modalService
        .openConfirmationDialog({
          headerText: 'Remove Amortization Schedule',
          descriptionText:
            'Are you sure you want to detach the Amortization Schedule? It will still be available in Project Files.',
        })
        .subscribe(async (confirmed) => {
          if (confirmed) {
            // remove the amortization tag from this file (id = 98)
            await this.fileService.removeTags(section.amortization_file_id, [98]).toPromise();
            await this.projectTenantService
              .updateProjectTenant(section.tenant_id, { amortization_file_id: null })
              .toPromise();
            section.amortization_file_id = null;
            section.amortization_file_name = null;
            this.updateApprovalProcesses();
            this.snackbar.open('Amortization Schedule has been removed');
          }
        });
    }
  }

  parseNumber(n) {
    return parseFloat(n?.replace(/,/g, ''));
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubCostCodeBudgetChange } from '../../../types';

interface DialogData {
  costCodeType: 'allCodes' | 'costCodes' | 'subCostCodes';
  budgetType: 'uhat' | 'oneCall';
  relevantChanges: SubCostCodeBudgetChange[];
}

@Component({
  selector: 'app-budget-changes-dialog',
  templateUrl: './budget-changes-dialog.component.html',
  styleUrls: ['./budget-changes-dialog.component.scss'],
})
export class BudgetChangesDialogComponent implements OnInit {
  title = '';

  constructor(
    public dialogRef: MatDialogRef<BudgetChangesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.title = this.getTitleFromData(this.data);
  }

  handleClose(): void {
    this.dialogRef.close();
  }

  public close(): void {
    this.dialogRef.close();
  }

  private getTitleFromData(data: DialogData): string {
    const budgetType = data.budgetType === 'oneCall' ? '1Call' : data.budgetType.toUpperCase();

    let titleBody = '';

    switch (data.costCodeType) {
      case 'costCodes':
        titleBody = data.relevantChanges[0].costCode.code + ' - ' + data.relevantChanges[0].costCode.label;
        break;
      case 'subCostCodes':
        titleBody = data.relevantChanges[0]?.subCostCode.code + ' - ' + data.relevantChanges[0]?.subCostCode.label;
        break;
      default:
        titleBody = 'All Cost Code Activity';
    }

    return `${titleBody} - ${budgetType}`;
  }
}

<div class="project-modal new-customer-dialog p-0">
  <div class="row align-items-center mb-4">
    <div class="col">
      <h5 class="ltblue m-0 font-weight-bold">Add Tenant</h5>
    </div>
    <div class="col-auto text-right">
      <a (click)="cancel()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <form [formGroup]="customerFormGroup">
    <div class="row">
      <div class="col-12">
        <h6 class="mb-2 dkblue font-weight-semibold">Tenant Type</h6>
        <mat-form-field class="full-width" appearance="outline">
          <mat-select matNativeControl formControlName="tenantTypeId" (ngModelChange)="tenantTypeChanged()">
            <mat-option [value]="null">-</mat-option>
            <mat-option *ngFor="let t of tenantTypes" [value]="t.id" [disabled]="!!t.disabled"
              >{{ t.name }}
            </mat-option>
            <!-- <mat-option [value]="1">Billing Tenant</mat-option>
            <mat-option disabled [value]="2">Occupying Tenant</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- Search Department -->
    <div class="row">
      <div class="col-12">
        <h6 class="mb-2 dkblue font-weight-semibold">Department</h6>
        <mat-form-field class="full-width" appearance="outline">
          <input
            matInput
            type="text"
            required="true"
            formControlName="department"
            (ngModelChange)="departmentChanged()"
            [matAutocomplete]="departmentAuto"
          />
          <mat-autocomplete #departmentAuto="matAutocomplete" [displayWith]="departmentValueMapper">
            <mat-option *ngFor="let d of filteredDepartments" [value]="d">{{ d.name }}</mat-option>
            <!-- <mat-option [value]="null" class="gray" *ngIf="filteredDepartments && (!department.value || (department.value && !department.value.id))">
              <i class="la la-plus"></i>
              &nbsp;
              New Department
            </mat-option> -->
          </mat-autocomplete>
          <mat-error *ngIf="department.errors?.required">Department is required</mat-error>
          <mat-error *ngIf="department.errors?.alreadyExists">Department is already a tenant</mat-error>
          <mat-error *ngIf="department.errors?.invalidDepartment">Must be an existing department</mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- Search Users -->
    <div class="row">
      <div class="col-12">
        <h6 class="mb-2 dkblue font-weight-semibold">Representative</h6>
        <mat-form-field class="full-width" appearance="outline">
          <input
            matInput
            type="text"
            formControlName="representative"
            (ngModelChange)="representativeChanged()"
            [matAutocomplete]="representativeAuto"
          />
          <mat-autocomplete #representativeAuto="matAutocomplete" [displayWith]="representativeValueMapper">
            <mat-option *ngFor="let u of filteredRepresentatives" [value]="u">
              {{ u.first_name }} {{ u.last_name }}
            </mat-option>
            <mat-option
              (click)="createUser()"
              class="ltblue"
              *ngIf="
                filteredRepresentatives && (!representative.value || (representative.value && !representative.value.id))
              "
            >
              <i class="la la-plus"></i> &nbsp; Create Representative
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="representative.errors?.required">Representative is required</mat-error>
          <mat-error *ngIf="representative.errors?.invalidRepresentative">Must be an existing user</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6 class="mb-2 dkblue font-weight-semibold">Fiscal Year</h6>
        <mat-form-field class="full-width" appearance="outline">
          <mat-select matNativeControl formControlName="fiscalYear">
            <mat-option *ngFor="let fy of fiscalYears" [value]="fy.year">{{ fy.year }} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col">
        <button mat-button class="gray full-width" type="button" (click)="cancel()">Cancel</button>
      </div>
      <div class="col text-right">
        <button
          mat-flat-button
          *ngIf="!loaders.customer"
          class="full-width"
          color="success"
          type="submit"
          (click)="submit()"
        >
          Submit
        </button>
        <button mat-flat-button *ngIf="loaders.customer" class="full-width" color="success" type="button">
          <span><i class="fas fa-circle-notch fa-spin ml-3"></i></span>
        </button>
      </div>
    </div>
  </form>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { FileAttachmentDialogComponent } from 'src/app/components';
import { InvoiceStatus, ResourceType, TaskAccessoryType, TaskReviewStatus, TaskStatus } from 'src/app/enums';
import { TaskAccessoryDataFactory } from 'src/app/models';
import {
  AuthService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  ProjectEventService,
  ProjectService,
  ProjectTaskService,
  UpdateReviewerService,
  UserService,
} from 'src/app/services';
import { TaskModalInjectionData, UhatFileReference, User } from 'src/app/types';
import {
  ChangeOrderApprovalDialogComponent,
  ProposalRequestDialogComponent,
  ViewChangeOrderDialogComponent,
} from 'src/app/workspaces/construction/components';
import { ChangeOrder, ProjectConstruction, ProposalRequest } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-proposal-requests',
  templateUrl: './proposal-requests.component.html',
  styleUrls: ['./proposal-requests.component.scss'],
})
export class ProposalRequestsComponent implements OnInit {
  @Input() project: ProjectConstruction;

  constructor(
    private projectService: ProjectService,
    private fileService: FileService,
    private snackbar: MatSnackBar,
    private proposalRequestDialog: MatDialog,
    private changeOrderApprovalDialog: MatDialog,
    private modalService: ModalService,
    public authService: AuthService,
    private userService: UserService,
    private progressIndicatorService: ProgressIndicatorService,
    private eventService: ProjectEventService,
    private dialog: MatDialog,
    private updateReviewerService: UpdateReviewerService,
    private taskService: ProjectTaskService
  ) {}

  proposalRequests: ProposalRequest[];
  changeOrders: ChangeOrder[];
  draftChangeOrder: ChangeOrder = {};
  Math = Math;
  loaders: any = {};
  currentUser: User;

  private proposalRequestFields = [
    'code',
    'title',
    'description',
    'due_date',
    'files',
    'reason_id',
    'reason_name',
    'local_index',
    'project_id',
  ];

  private changeOrderFields = [
    'code',
    'bid_package_id',
    'proposal_request_id',
    'short_description',
    'summary',
    'trade_name',
    'company_name',
    'status_id',
    'cost_change',
    'time_change_amount',
    'time_change_unit',
    'files',
    'local_index',
    'invoices{status_id,approval_task_id}',
    'wm_arch_task_id',
    'wm_arch_status_id',
    'tenant_task_id',
    'tenant_status_id',
    'cfmo_task_id',
    'cfmo_status_id',
    'need_wm_arch_approval',
    'need_tenant_approval',
    'need_cfmo_approval',
    'funding_source_tenant_id',
    'funding_source_fee_id',
    'funding_source_tenant_rep_id',
    'funding_source_name',
    'funding_source_tenant_name',
    'funding_source_tenant_department_id',
    'funding_source_fee_funding_source_id',
    'funding_source_fee_funding_source_abbreviation',
    'project_id',
    'approval_datetime',
  ];

  public get hasAnExpandedProposalRequest(): boolean {
    return this.proposalRequests?.some((proposalRequest: ProposalRequest) => proposalRequest.is_expanded);
  }

  public get TaskStatus() {
    return TaskStatus;
  }

  async ngOnInit() {
    setTimeout(() => {
      this.refresh();
    });
    if (this.project && this.project.id) {
      this.currentUser = this.authService.getLoggedInUser();
      const detailedUser = await this.userService.getUserById(this.currentUser?.id).toPromise();
      this.currentUser.company_id = detailedUser.company_id;
      this.currentUser.company_name = detailedUser.company_name;
      if (detailedUser && detailedUser.company_id) {
      }
    }
  }

  async refresh() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Requests..');
    const proposalRequests = await this.projectService
      .getProposalRequests(
        [{ type: 'field', field: 'project_id', value: this.project?.id.toString() }],
        this.proposalRequestFields
      )
      .toPromise();
    const changeOrders = await this.projectService
      .getChangeOrders(
        [
          { type: 'field', field: 'project_id', value: this.project.id.toString() },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'bid_package_child_request_id', value: null },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'bid_package_child_project_id', value: null },
        ],
        this.changeOrderFields
      )
      .toPromise();
    for (const p of proposalRequests) {
      p.daysUntilDueDate = p.due_date ? moment(p.due_date).startOf('day').diff(moment().startOf('day'), 'days') : null;
      p.changeOrders = [];
      p.changeOrderStatusCounts = { new: 0, inReview: 0, approved: 0, finalized: 0 };
      changeOrders.forEach((changeOrder) => {
        if (changeOrder.proposal_request_id === p.id) {
          p.changeOrders.push(changeOrder);

          const changeOrderIsApproved = changeOrder.status_id === 2;
          const changeOrderIsFinalized = changeOrderIsApproved && !!changeOrder.approval_datetime;
          const changeOrderHasReviewTasks =
            changeOrder.wm_arch_task_id || changeOrder.tenant_task_id || changeOrder.cfmo_task_id;

          if (changeOrderIsApproved) {
            changeOrder.has_approved_invoices = !!changeOrder.invoices?.find(
              (invoice) => invoice.status_id === InvoiceStatus.ReadyForPayment
            );

            if (changeOrderIsFinalized) {
              p.changeOrderStatusCounts.finalized++;
            } else {
              p.changeOrderStatusCounts.approved++;
            }
          } else {
            if (changeOrderHasReviewTasks) {
              p.changeOrderStatusCounts.inReview++;
            } else {
              p.changeOrderStatusCounts.new++;
            }
          }
        }
      });
    }
    this.syncProposalRequestExpansion(proposalRequests);

    proposalRequests.sort((a, b) => +a.local_index - +b.local_index);

    this.proposalRequests = proposalRequests;
    this.changeOrders = changeOrders;

    this.progressIndicatorService.close();
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.project?.module_id);
  }

  addProposalRequest() {
    const dialogRef = this.proposalRequestDialog.open(ProposalRequestDialogComponent, {
      width: '500px',
      data: { preSelectedTags: [{ id: 3 }] },
    });

    dialogRef.afterClosed().subscribe(async (proposalRequestToCreate: ProposalRequest) => {
      if (proposalRequestToCreate && this.project) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Saving Proposal Request..');

        proposalRequestToCreate.project_id = this.project.id;
        let filesToCreate = [];
        if (proposalRequestToCreate.files) {
          filesToCreate = proposalRequestToCreate.files;
          delete proposalRequestToCreate.files;
        }
        const createdProposalRequest = await this.projectService
          .createProposalRequest(proposalRequestToCreate, this.proposalRequestFields)
          .toPromise();
        for (const f of filesToCreate) {
          await this.fileService
            .linkFile(f.file_id, createdProposalRequest.id, ResourceType.ProposalRequest)
            .toPromise();
          await this.fileService.addTags(f.file_id, [3]).toPromise();
        }

        this.snackbar.open(`Proposal Request added!`);
        this.refresh();
      }
    });
  }

  editProposalRequest(pr: ProposalRequest) {
    const dialogRef = this.proposalRequestDialog.open(ProposalRequestDialogComponent, {
      width: '500px',
      data: {
        proposalRequest: pr,
        preSelectedTags: [{ id: 3 }],
      },
    });

    dialogRef.afterClosed().subscribe(async (proposalRequestToUpdate: ProposalRequest) => {
      if (proposalRequestToUpdate && this.project) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Saving Proposal Request..');

        let filesToCreate;
        if (proposalRequestToUpdate.files) {
          filesToCreate = proposalRequestToUpdate.files;
          delete proposalRequestToUpdate.files;
        }
        const updatedProposalRequest = await this.projectService
          .updateProposalRequest(pr.id, proposalRequestToUpdate, this.proposalRequestFields)
          .toPromise();

        for (const f of filesToCreate) {
          if (!pr.files || !pr.files.find((prFile) => parseFloat(prFile.id) === f.file_id)) {
            await this.fileService
              .linkFile(f.file_id, updatedProposalRequest.id, ResourceType.ProposalRequest)
              .toPromise();
            await this.fileService.addTags(f.file_id, [3]).toPromise();
          } else {
            await this.fileService.unlinkFile(+f.id).toPromise();
            this.fileService.removeTags(+f.file_id || +f.id, [3]).subscribe();
          }
        }

        this.snackbar.open(`Proposal Request updated!`);
        await this.refresh();
      }
    });
  }

  removeProposalRequest(proposalRequest: ProposalRequest) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Proposal Request',
        descriptionText: 'Are you sure you want to remove this proposal request?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          if (proposalRequest && proposalRequest.id) {
            this.progressIndicatorService.openAwaitIndicatorModal();
            this.progressIndicatorService.updateStatus('Removing Request..');

            await this.projectService.deleteProposalRequest(proposalRequest.id).toPromise();
            this.snackbar.open(`Proposal Request '${proposalRequest.local_index}' removed!`);
            this.refresh();
          }
        }
      });
  }

  getInvalidDraftChangeOrderFields(proposalRequest: ProposalRequest): string[] {
    const invalidFields = [];
    if (!proposalRequest || !proposalRequest.id) {
      invalidFields.push('Proposal Request ID');
    }
    if (this.draftChangeOrder) {
      if (!this.currentUser.company_id) {
        invalidFields.push('Company');
      }
      if (!this.draftChangeOrder.trade_id) {
        invalidFields.push('Trade');
      }
      if (!this.draftChangeOrder.summary) {
        invalidFields.push('Short Description');
      }

      if (!this.draftChangeOrder.summary) {
        invalidFields.push('Summary');
      }
      if (!this.draftChangeOrder.cost_change_type) {
        invalidFields.push('Cost Change Type');
      }
      if (!this.draftChangeOrder.cost_change) {
        invalidFields.push('Cost Change');
      }
      if (!this.draftChangeOrder.time_change_amount) {
        invalidFields.push('Time Change Amount');
      }
      if (!this.draftChangeOrder.time_change_unit) {
        invalidFields.push('Time Change Unit');
      }
    } else {
      invalidFields.push('Draft Change Order');
    }
    return invalidFields;
  }

  addDraftChangeOrderFiles(files) {
    for (const f of files) {
      if (!this.draftChangeOrder.files || this.draftChangeOrder.files.length === 0) {
        this.draftChangeOrder.files = [f];
      } else if (this.draftChangeOrder.files.find((fi) => fi.name === f.name)) {
        this.snackbar.open(`File with name '${f.name}' already exists!`);
      } else {
        this.draftChangeOrder.files.push(f);
      }
    }
  }

  async approveChangeOrder(changeOrder: ChangeOrder) {
    await this.modalService
      .openConfirmationDialog({
        headerText: `Approve Change Order ${changeOrder.local_index}`,
        descriptionText:
          'Approving this Change Order will affect the overall budget for this project. Are you sure you want to continue?\n\nIf so, please also remember to notify the supplier of their newly Approved Change Order as the system will not. ',
        confirmationButtonText: 'Continue',
      })
      .toPromise()
      .then(async (isConfirmed) => {
        if (isConfirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Approving CO..');
          await this.projectService
            .updateChangeOrder(
              changeOrder.id,
              { status_id: 2, approval_datetime: moment().format('YYYY-MM-DD HH:mm:ss') },
              this.changeOrderFields
            )
            .toPromise();

          for (const invoice of changeOrder.invoices || []) {
            if (invoice.approval_task_id) {
              await this.projectService.updateTask({ id: invoice.approval_task_id, is_locked: 0 }).toPromise();
            }
          }
          this.snackbar.open(`Change Order '${changeOrder.local_index}' has been approved!`);
          await this.refresh();
          this.progressIndicatorService.close();
        }
      });
  }

  removeChangeOrder(changeOrder: ChangeOrder) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Change Order',
        descriptionText: 'Are you sure you want to remove this change order?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          if (changeOrder && changeOrder.id) {
            if (changeOrder.files) {
              for (const file of changeOrder.files) {
                this.fileService.removeTags(file.file_id || file.id, [4]).subscribe();
              }
            }
            await this.projectService.deleteChangeOrder(changeOrder.id).toPromise();
            this.snackbar.open(`Change Order '${changeOrder.local_index}' removed!`);
            this.refresh();
          }
        }
      });
  }

  syncProposalRequestExpansion(proposalRequests) {
    if (this.proposalRequests) {
      const openProposalRequestIds = this.proposalRequests.filter((p) => p.is_expanded).map((p) => p.id);
      if (openProposalRequestIds) {
        for (const p of proposalRequests) {
          p.is_expanded = true;
          p.is_expanded = openProposalRequestIds.indexOf(p.id) > -1;
        }
      }
    }
  }

  // opens the upload modal, and passes the file on once it's uploaded, so it can be linked to the correct object
  openUploadModal() {
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.project.id,
          allowComment: false,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          this.addDraftChangeOrderFiles(resultData);
        }
      });
  }

  async openChangeOrderModal(proposalRequest: ProposalRequest, changeOrder: ChangeOrder = null) {
    let editChangeOrder = true;
    if (changeOrder?.status_id === 2) {
      editChangeOrder = await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Approved Change Order',
          headerText: 'Edit Approved Changed Order',
          descriptionText:
            'Are you sure you want to edit this change order? Doing so will reset the change order review process and lock any related invoices in review.',
          confirmationButtonText: 'Edit',
        })
        .toPromise();
    }

    if (editChangeOrder) {
      this.modalService
        .openChangeOrderModal(proposalRequest, this.currentUser, this.changeOrderFields, changeOrder)
        .toPromise()
        .then(async (res) => {
          if (res) {
            this.snackbar.open(`Change Order ${changeOrder ? 'updated' : 'added'}!`);
            this.draftChangeOrder = null;
            if (changeOrder?.status_id === 2) {
              await this.resetCO(changeOrder);
              for (const invoice of changeOrder.invoices || []) {
                if (invoice.approval_task_id) {
                  await this.projectService.updateTask({ id: invoice.approval_task_id, is_locked: 1 }).toPromise();
                }
              }
            }
            await this.refresh();
          }
        });
    }
  }

  public displayDueDate(daysUntilDueDate) {
    let afterDueText = 'Today';
    if (daysUntilDueDate !== 0) {
      const day = `Day${daysUntilDueDate === 1 || daysUntilDueDate === -1 ? '' : 's'}`;
      afterDueText = `${Math.abs(daysUntilDueDate)} ${day} ${daysUntilDueDate < 0 ? ' Ago' : ''}`;
    }
    return `Due: ${afterDueText}`;
  }

  createTask(type: string | 'action' | 'review', changeOrder: ChangeOrder) {
    const taskModalData: TaskModalInjectionData = {};
    if (changeOrder.files && changeOrder.files.length > 0) {
      taskModalData.attachedFiles = changeOrder.files;
      taskModalData.taskNote = 'Change Order File(s)';
    }
    if (type === 'action') {
      taskModalData.taskTitle = `Action required for Change Order #${changeOrder.local_index} for Project ${this.projectService.currentSelectedProject.code}`;
      taskModalData.accessoryData = TaskAccessoryDataFactory.createChangeOrderData(changeOrder.id, false);
    } else if (type === 'review') {
      taskModalData.taskTitle = `Review Change Order #${changeOrder.local_index} for Project ${this.projectService.currentSelectedProject.code}`;
      taskModalData.accessoryData = TaskAccessoryDataFactory.createChangeOrderData(changeOrder.id, true);
      taskModalData.taskDescription =
        'The change order below needs to be reviewed. Please re-assign the task when you complete your review.';
    }

    this.modalService.openCreateTaskModal(taskModalData).subscribe((createdTask) => {
      if (!createdTask) {
        return;
      }
    });
  }

  async createApprovalTask(
    forUser: 'wm_arch_task_id' | 'tenant_task_id' | 'cfmo_task_id',
    changeOrder: ChangeOrder
  ): Promise<void> {
    let createdTask;
    const taskModalData: TaskModalInjectionData = {};
    if (changeOrder.files && changeOrder.files.length > 0) {
      taskModalData.taskNote = 'Change Order File(s)';
      if (!changeOrder.wm_arch_task_id && !changeOrder.tenant_task_id) {
        taskModalData.attachedFiles = changeOrder.files;
      } else {
        const taskId = changeOrder.tenant_task_id || changeOrder.wm_arch_task_id;
        taskModalData.attachedFiles = (await this.taskService.getLatestTaskActivityNote(taskId)) || changeOrder.files;
      }
    }

    taskModalData.taskTitle = `Review Change Order #${changeOrder.local_index} - ${changeOrder.company_name} - ${changeOrder.trade_name}`;
    taskModalData.accessoryData = TaskAccessoryDataFactory.createChangeOrderData(changeOrder.id, true);
    taskModalData.taskDescription = 'The change order below needs to be reviewed.';

    // since the change order will have this task id as one of its fields, don't allow the task to be deleted
    taskModalData.can_delete = 0;

    // set the select reviewers
    taskModalData.selectedReviewers = [];

    const project = this.projectService.currentSelectedProject;
    if (forUser === 'wm_arch_task_id') {
      taskModalData.selectedReviewers.push({
        firstName: project.workspace_manager_first_name,
        lastName: project.workspace_manager_last_name,
        id: project.workspace_manager_id,
        title: 'Construction Manager',
        reviewer: true,
      });
      if (project.architect_id) {
        taskModalData.selectedReviewers.push({
          firstName: project.architect_first_name,
          lastName: project.architect_last_name,
          id: project.architect_id,
          title: 'Project Architect',
          reviewer: true,
        });
      } else {
        taskModalData.selectedReviewers.push({
          firstName: project.project_manager_first_name,
          lastName: project.project_manager_last_name,
          id: project.project_manager_id,
          title: 'Project Manager',
          reviewer: true,
        });
      }
    } else if (forUser === 'cfmo_task_id') {
      if (changeOrder.wm_arch_task_id) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Updating Task...');
        const reviewer = { id: project.cfmo_id, status: TaskReviewStatus.Pending };
        createdTask = await this.projectService.getTaskById(changeOrder.wm_arch_task_id).toPromise();
        const accessoryData = createdTask.accessory_data && JSON.parse(createdTask.accessory_data);
        if (accessoryData?.reviewChain?.length) {
          if (!accessoryData?.reviewChain?.find((r) => r.id === reviewer.id)) {
            accessoryData.reviewChain.push(reviewer);
          }
        } else {
          accessoryData.reviewChain = [reviewer];
        }
        const taskData = {
          id: createdTask.id,
          accessory_data: JSON.stringify(accessoryData),
          status_id: TaskStatus.Open,
          assigned_user_id: reviewer.id,
        };

        const updatedTask = await this.projectService.updateTask(taskData).toPromise();
        changeOrder.wm_arch_status_id = TaskStatus.Open;
        this.taskService.taskSelectedEvent.emit({ task: updatedTask, navigate: false });
        this.progressIndicatorService.close();
      } else {
        taskModalData.selectedReviewers.push({
          firstName: project.cfmo_first_name,
          lastName: project.cfmo_last_name,
          id: project.cfmo_id,
          title: 'Chief Facilities Management Officer',
          reviewer: true,
        });
      }
    } else if (forUser === 'tenant_task_id') {
      // set the tenant rep as a reviewer
      if (changeOrder.funding_source_tenant_rep_id) {
        const tenant_rep = await this.userService
          .getUserById(changeOrder.funding_source_tenant_rep_id, ['first_name', 'last_name'])
          .toPromise();
        taskModalData.selectedReviewers.push({
          firstName: tenant_rep.first_name,
          lastName: tenant_rep.last_name,
          id: changeOrder.funding_source_tenant_rep_id,
          title: 'Tenant Representative',
          reviewer: true,
        });
      } else {
        // if there is no tenant rep, then this task shouldn't be created. abort
        this.snackbar.open("Tenant review isn't needed for this change order, since UHAT is the tenant.");

        // set tenant review N/A
        const changeOrderToUpdate: ChangeOrder = {};
        changeOrder.need_tenant_approval = 0;
        changeOrderToUpdate.need_tenant_approval = 0;
        this.projectService.updateChangeOrder(changeOrder.id, changeOrderToUpdate, this.changeOrderFields).subscribe();

        return;
      }
    }

    createdTask = createdTask || (await this.modalService.openCreateTaskModal(taskModalData).toPromise());

    if (!createdTask) {
      return;
    }
    // set the local copy
    changeOrder[forUser] = createdTask.id;
    // set the CO in the DB
    const updateChangeOrder: ChangeOrder = {};

    if (forUser === 'wm_arch_task_id') {
      changeOrder.need_wm_arch_approval = 1;
      updateChangeOrder.need_wm_arch_approval = 1;
    } else if (forUser === 'tenant_task_id') {
      changeOrder.need_tenant_approval = 1;
      updateChangeOrder.need_tenant_approval = 1;
    } else if (forUser === 'cfmo_task_id') {
      changeOrder.need_cfmo_approval = 1;
      updateChangeOrder.need_cfmo_approval = 1;
    }

    updateChangeOrder[forUser] = createdTask.id;
    await this.projectService.updateChangeOrder(changeOrder.id, updateChangeOrder, this.changeOrderFields).toPromise();
    await this.refresh();
  }

  public openViewChangeOrderModal(
    changeOrderId: number,
    isReviewItem: boolean
  ): Observable<{
    approvalStatus: TaskReviewStatus;
    approvalComment: string;
    attachedFiles?: UhatFileReference[];
  }> {
    const config = {
      width: '480px',
      disableClose: true,
      data: {
        changeOrderId,
        isReviewItem,
      },
    };
    const dialogRef = this.dialog.open(ViewChangeOrderDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openFundingSourceModal(co: ChangeOrder) {
    const changeOrderToApprove: ChangeOrder = {
      time_change_amount: co.time_change_amount,
      time_change_unit: co.time_change_unit,
      funding_source_name: co.funding_source_name,
      funding_source_tenant_id: co.funding_source_tenant_id,
      funding_source_fee_id: co.funding_source_fee_id,
      project_id: co.project_id,
    };
    const dialogRef = this.changeOrderApprovalDialog.open(ChangeOrderApprovalDialogComponent, {
      data: {
        changeOrder: changeOrderToApprove,
        viewOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe(async (changeOrderToUpdate: ChangeOrder) => {
      if (changeOrderToUpdate) {
        const toUpdate = {
          tenant_id: changeOrderToUpdate?.tenant_id,
          funding_source_fee_old_id: changeOrderToUpdate?.funding_source_fee_old_id,
          funding_source_fee_new_id: changeOrderToUpdate?.funding_source_fee_new_id,
          funding_source_name: changeOrderToUpdate?.funding_source_name,
          time_change_amount: changeOrderToUpdate?.time_change_amount,
          time_change_unit: changeOrderToUpdate?.time_change_unit,
        };
        await this.projectService
          .updateChangeOrder(co.id, toUpdate, this.changeOrderFields)
          .toPromise()
          .then((returnedChangeOrder) => {
            co.funding_source_fee_id = returnedChangeOrder.funding_source_fee_id;
            co.funding_source_name = returnedChangeOrder.funding_source_name;
            co.time_change_amount = returnedChangeOrder.time_change_amount;
            co.time_change_unit = returnedChangeOrder.time_change_unit;
            co.funding_source_tenant_rep_id = returnedChangeOrder.funding_source_tenant_rep_id;
            co.funding_source_tenant_id = returnedChangeOrder.funding_source_tenant_id;
            co.funding_source_tenant_name = returnedChangeOrder.funding_source_tenant_name;
          });

        const taskIds = [co.wm_arch_task_id || co.cfmo_task_id, co.tenant_task_id];
        for (const taskId of taskIds) {
          if (taskId) {
            const task = await this.projectService.getTaskById(taskId, ['accessory_data']).toPromise();
            const accessoryData = task.accessory_data && JSON.parse(task.accessory_data);
            if (accessoryData.reviewFiles[0].id !== co.files[0].id) {
              accessoryData.reviewFiles =
                co.files?.map((file) => ({ id: file.file_id || file.id, name: file.name })) || [];
              const createdNote = await this.projectService
                .createNote(ResourceType.Task, taskId, 'Change Order File(s)')
                .toPromise();
              if (createdNote) {
                await this.fileService.addFilesToNote(createdNote, taskId, [], co.files);
              }
            }

            const taskData = {
              id: taskId,
              is_locked: 0,
              accessory_data: JSON.stringify(accessoryData),
            };
            await this.projectService.updateTask(taskData).toPromise();
          }
        }
      }
    });
  }

  public async editCO(co: ChangeOrder) {}

  public async openResetCOConfirmation(co: ChangeOrder) {
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Reset CO',
        descriptionText:
          'Are you sure you wish to reset the CO? This will permanently reset the funding source information, as well as delete all review tasks.',
        confirmationButtonText: 'Continue',
      })
      .toPromise()
      .then(async (isConfirmed) => {
        if (isConfirmed) {
          await this.resetCO(co);
        }
      });
  }

  async resetCO(co) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Resetting CO..');
    const taskIds = [co.wm_arch_task_id || co.cfmo_task_id, co.tenant_task_id];

    for (const taskId of taskIds || []) {
      if (taskId) {
        await this.updateReviewerService.resetReview(
          taskId,
          { type: TaskAccessoryType.ChangeOrder },
          'Change Order Reset'
        );
        await this.projectService.updateTask({ id: taskId, is_locked: 1 }).toPromise();
        this.updateTaskStatus(co, TaskStatus.Open);
      }
    }

    co.funding_source_fee_id = null;
    co.funding_source_name = null;

    const changeOrderToUpdate: ChangeOrder = {
      funding_source_fee_old_id: null,
      funding_source_name: null,
    };

    if (co.status_id === 2) {
      co.status_id = 1;
      changeOrderToUpdate.status_id = 1;
    }

    await this.projectService.updateChangeOrder(co.id, changeOrderToUpdate, this.changeOrderFields).toPromise();
    this.progressIndicatorService.close();
  }

  public setNoTask(
    forUser: 'need_wm_arch_approval' | 'need_tenant_approval' | 'need_cfmo_approval',
    changeOrder: ChangeOrder
  ) {
    changeOrder[forUser] = 0;

    // set the CO in the DB
    const changeOrderToUpdate = {};
    changeOrderToUpdate[forUser] = 0;
    this.projectService.updateChangeOrder(changeOrder.id, changeOrderToUpdate, this.changeOrderFields).subscribe();
  }

  public async createPCOTask(p: ProposalRequest) {
    const taskModalData: TaskModalInjectionData = {};
    taskModalData.taskTitle = `Add Proposed Change Order for PR #${p.local_index}`;
    taskModalData.accessoryData = TaskAccessoryDataFactory.createAddPCOData(p.id);
    taskModalData.taskDescription = `Please click the 'Add PCO' button to upload your proposed change order.`;
    taskModalData.followers = [this.authService.getLoggedInUser()];
    taskModalData.isTypeLocked = true;

    this.modalService.openCreateTaskModal(taskModalData).subscribe((createdTask) => {
      if (!createdTask) {
        return;
      }
      this.snackbar.open('Task Created!');
    });
  }

  public updateTaskStatus(changeOrder, status, isTenant = false) {
    if (!isTenant) {
      changeOrder.wm_arch_status_id = status;
      if (changeOrder.cfmo_task_id) {
        changeOrder.cfmo_status_id = status;
      }
    } else {
      changeOrder.tenant_status_id = status;
    }
  }

  public toggleProposalRequests() {
    const newState = !this.hasAnExpandedProposalRequest;

    this.proposalRequests = this.proposalRequests?.map((proposalRequest: ProposalRequest) => ({
      ...proposalRequest,
      is_expanded: newState,
    }));
  }
}

<div id="date-picker-header" data-test-id="date-picker-header" class="date-picker-header example-header">
  <button color="primary" mat-icon-button class="example-double-arrow" (click)="previousClicked('year')">
    <mat-icon>keyboard_arrow_left</mat-icon>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button color="primary" mat-icon-button (click)="previousClicked('month')">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <span class="example-header-label dkblue">{{ periodLabel }}</span>
  <button color="primary" mat-icon-button (click)="nextClicked('month')">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <button color="primary" mat-icon-button class="example-double-arrow" (click)="nextClicked('year')">
    <mat-icon>keyboard_arrow_right</mat-icon>
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
<div class="header-buttons d-flex flex-column text-center">
  <div class="d-flex align-items-center">
    <button
      mat-button
      color="accent"
      id="datepicker-header-today"
      class="lh-22 pl-2 pr-2 m-1"
      (click)="incrementDate('today')"
    >
      Today
    </button>
    <button
      mat-button
      color="accent"
      id="datepicker-header-add-one-day"
      class="lh-22 pl-2 pr-2 m-1"
      (click)="incrementDate('+1 day')"
    >
      Next Day
    </button>
    <button
      mat-button
      color="accent"
      id="datepicker-header-add-one-week"
      class="lh-22 pl-2 pr-2 m-1"
      (click)="incrementDate('+1 week')"
    >
      +1 Week
    </button>
    <button
      mat-button
      color="accent"
      id="datepicker-header-add-two-weeks"
      class="lh-22 pl-2 pr-2 m-1"
      (click)="incrementDate('+2 weeks')"
    >
      +2 Weeks
    </button>
  </div>
  <div class="d-flex align-items-center">
    <button mat-button color="accent" id="datepicker-header-clear" class="lh-22 pl-2 pr-2 m-1" (click)="clearDate()">
      Clear Date
    </button>
  </div>
</div>

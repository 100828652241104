import { Location } from '@angular/common';
import { ApplicationRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF } from '@progress/kendo-drawing';
import { saveAs } from 'file-saver';
import { cloneDeep, filter, maxBy, remove, sortBy, sumBy } from 'lodash';
import * as moment from 'moment';
import {
  CoverLetterDialogComponent,
  CustomerDialogComponent,
  FileAttachmentDialogComponent,
  ViewTaskDialogComponent,
} from 'src/app/components';
import { ApplicationRole, ResourceType, ReviewType, TaskReviewStatus } from 'src/app/enums';
import { USDollarPipe } from 'src/app/pipes';
import {
  AppRoutingService,
  AuthService,
  DateService,
  DisplayReviewersService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  ProjectEventService,
  ProjectService,
  ProjectTaskService,
  ReviewRevisionService,
  TaskActionsService,
  UserService,
} from 'src/app/services';
import { APIFilter, UhatFileReference } from 'src/app/types';
import { FinalizePEBDialogComponent } from 'src/app/workspaces/construction/components';
import {
  PEBItemType as PEBItemTypeEnum,
  PEBStatus,
  ProjectTenantPEBStatus,
} from 'src/app/workspaces/construction/enums';
import { PEBApprovalProcess } from 'src/app/workspaces/construction/models';
import { PEBService, ProjectTenantService } from 'src/app/workspaces/construction/services';
import {
  PEB,
  PEBItem,
  PEBItemType,
  ProjectConstruction,
  ProjectTenantConstruction,
  Trade,
} from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-peb-old',
  templateUrl: './peb-old.component.html',
  styleUrls: ['./peb-old.component.scss'],
})
export class PEBOldComponent implements OnInit {
  constructor(
    private appRef: ApplicationRef,
    private dateService: DateService,
    private pebService: PEBService,
    private projectService: ProjectService,
    private projectTaskService: ProjectTaskService,
    private snackbar: MatSnackBar,
    public SubmitForApprovalDialog: MatDialog,
    private progressIndicatorService: ProgressIndicatorService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private routingService: AppRoutingService,
    private projectTenantService: ProjectTenantService,
    public reviewRevisionService: ReviewRevisionService,
    private authService: AuthService,
    private modalService: ModalService,
    public dialog: MatDialog,
    private displayReviewersService: DisplayReviewersService,
    private userService: UserService,
    private fileService: FileService,
    private eventService: ProjectEventService,
    private taskActionsService: TaskActionsService
  ) {}

  @Input() public project: ProjectConstruction;

  @ViewChild('pdf', { static: true }) public pdf: PDFExportComponent;
  @ViewChild('tenantTabGroup', { static: false }) tenantTabGroup: MatTabGroup;
  @ViewChild('coverLetter', { static: true }) coverLetter;
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;

  public unsavedChangesExist = false;
  public hideDetails = false;
  public isEditing = false;
  public projectTrades = [];
  public downloading = false;
  public loadingPEB = false;
  // public showCoverLetter = true;
  public changeTenantTabUIOnly = false;
  private reviewers = {};

  private divWidth: number;
  public showDesktop: boolean;
  private showIpad: boolean;
  private USDollarPipe = new USDollarPipe();

  private projectFields: string[] = [
    'code',
    'building_id',
    'title',
    'trades',
    'building_circulation_factor',
    'square_footage',
    'peb_status',
    'peb_revision',
    'selected_peb_id',
    'cfmo_id',
  ];
  private pebFields: string[] = [
    'title',
    'sequence',
    'project_id',
    'design_timeline',
    'construction_timeline',
    'total_timeline',
    'construction_sqft',
    'occupied_sqft',
    'rental_rate',
    'tenant_allowance_fixed_property',
    'tenant_allowance',
    'tenant_allowance_per_sqft',
    'tenant_id',
    'tenant_name',
    'tenant_type_id',
  ];
  private itemFields: string[] = [
    'name',
    'peb_id',
    'owner_type_id',
    'trade_id',
    'trade_name',
    'type_id',
    'fixed_properties',
    'percent_cost',
    'sqft',
    'cost_per_sqft',
    'subtotal',
  ];
  public projectTenants: ProjectTenantConstruction[] = [];

  public pebs: PEB[] = [];
  public selectedPEB: PEB;
  public PEBsToExport: PEB[];
  public exportingPEB: PEB;
  public exportingTenant: ProjectTenantConstruction;
  private allTrades: Trade[];
  private itemTypes: PEBItemType[];
  public selectedTenantId: number;
  private armedToSelectTenantId: number;
  public get pebStatus() {
    return ProjectTenantPEBStatus;
  }
  public filteredTenantPEBs: PEB[];
  private tenantAllowanceItemTemplate: PEBItem = {
    type_id: PEBItemTypeEnum.TenantAllowance,
    name: 'Tenant Allowance',
    owner_type_id: 1,
  };
  private trustAllowanceItemTemplate: PEBItem = {
    type_id: PEBItemTypeEnum.TenantAllowance,
    name: 'Tenant Allowance',
    owner_type_id: 2,
  };
  private managementFeeItemTemplate: PEBItem = {
    type_id: PEBItemTypeEnum.ManagementFee,
    name: 'Management Fee',
    owner_type_id: 1,
  };
  private tenantRep;
  combinedFile: UhatFileReference[];
  private allCoverLetterDesc = {};
  allCombinedFiles = {};

  async ngOnInit() {
    // this apparently prevents a view change error (by changing angular load timing?)
    setTimeout(async () => {
      await this.refresh();
      this.getDivWidth();
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.armedToSelectTenantId = params ? params.selectTenantId : null;
    });
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
    if (this.divWidth > 800) {
      this.showDesktop = true;
      this.showIpad = false;
    } else {
      this.showDesktop = false;
      this.showIpad = true;
    }
  }

  // Only allows certain staff users to edit the review process
  get isProjectAdmin() {
    return this.authService.isProjectAdmin(
      this.projectService.currentSelectedProjectId,
      this.projectService.currentSelectedProject?.module_id
    );
  }

  public async refresh() {
    if (await this.breakForUnsavedChanges()) {
      return;
    }
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Working on it...');
    if (this.loadingPEB) {
      return;
    }
    this.loadingPEB = true;
    await this.getProject();
    const pebFilters: APIFilter[] = [{ type: 'field', field: 'project_id', value: this.project.id.toString() }];
    await this.pebService
      .getPEBs(this.pebFields, pebFilters)
      .toPromise()
      .then(async (pebs: PEB[]) => {
        this.pebs = pebs;
        // Get Item types. Upon both api calls completing we run calculations on each PEB in order to fill out its data.
        const itemTypesPromise: Promise<PEBItemType[]> = this.pebService.getPEBItemTypes(['name']).toPromise();
        const projectTenantsPromise: Promise<ProjectTenantConstruction[]> = this.projectTenantService
          .getTenantsForProject(this.project.id)
          .toPromise();
        // Once all promises have complete we can finish the refresh process
        await Promise.all([itemTypesPromise, projectTenantsPromise]).then(async ([itemTypes, tenants]) => {
          this.itemTypes = itemTypes;
          this.projectTenants = tenants;
          for (const tenant of this.projectTenants) {
            tenant.approval_process = new PEBApprovalProcess(
              tenant,
              this.projectTaskService,
              this.modalService,
              this.project && this.project.cfmo_id,
              this.fileService,
              this.dateService,
              this.taskActionsService
            );
            this.getAllCoverLetterDesc(tenant);
          }
          // this.projectTenants.forEach( async tenant => {
          //   this.allCombinedFiles[tenant.id] = await this.combineFiles(tenant);
          // })

          if (this.selectedPEB) {
            this.selectPEB(this.selectedPEB);
            // } else if (this.selectedTenantId) {
            //   this.selectTenant(this.selectedTenantId, null, null, !this.showCoverLetter);
          } else if (this.projectTenants.length > 0) {
            await this.selectTenant(this.projectTenants[0].id);
          }
          if (this.selectedTenantId) {
            this.filteredTenantPEBs = this.pebs.filter((peb) => +peb.tenant_id === +this.selectedTenantId);
            // this.selectedPEB.sections = [{ name: 'UHAT Investment', owner_type_id: 2 }];
            // const foundTenant = this.projectTenants.find((t) => t.id === this.selectedTenantId);
            // if (foundTenant && foundTenant.type_id !== 3) {
            //   this.selectedPEB.sections.unshift({ name: foundTenant.tenant_name, owner_type_id: 1 });
            // }
            if (this.filteredTenantPEBs) {
              this.filteredTenantPEBs.forEach((filteredPEB) => {
                filteredPEB.sections = [{ name: 'UHAT Investment', owner_type_id: 2 }];
                const foundTenant = this.projectTenants.find((t) => t.id === filteredPEB.tenant_id);
                if (foundTenant && foundTenant.type_id !== 3) {
                  filteredPEB.sections.unshift({
                    name: foundTenant.tenant_name,
                    owner_type_id: 1,
                  });
                }
                this.sortPEB(filteredPEB);
                this.calculateHeader(filteredPEB);
                this.calculateSubtotals(filteredPEB);
              });
            }
          }

          this.selectTenantTabIfQueryParamGiven();
          this.reviewers = await this.displayReviewersService.displayReviewers(this.projectTenants, ReviewType.PEB);
          this.loadingPEB = false;
          this.progressIndicatorService.close();
        });
      });
    this.projectTenants.forEach((tenant) => {
      tenant.assigned_user = tenant.approval_process.tenantReviewAssignedUser;
    });
  }

  private async getAllCoverLetterDesc(tenant) {
    let coverLetterDesc = tenant.peb_cover_letter_text;

    if (!coverLetterDesc) {
      coverLetterDesc = `Enclosed is the Preliminary Estimated Budget for project ${this.projectService.currentSelectedProject.code} - ${this.projectService.currentSelectedProject.title}. Before any work can begin, please review, approve and sign the attached Preliminary Estimated Budget and Reimbursement agreement. Also, please indicate the funding source for this project. Once these items have been signed and approved, please upload them to the 1CALL System. We will review the documents to ensure all information required has been received and then coordinate with you regarding our next steps.`;
      await this.saveCoverLetterDesc(tenant.id, coverLetterDesc);
    }
    this.allCoverLetterDesc[tenant.id] = coverLetterDesc;
  }
  /**
   *  If armed to select tenant id (set from url params in NG On Init), then select the provided tenant, and nullify the armedToSelectTenantId
   *  so that it does not get recalled on every refresh. We need to execute this functionality AFTER all data is loaded.
   */
  private selectTenantTabIfQueryParamGiven() {
    if (this.armedToSelectTenantId) {
      const tenant = this.projectTenants.find((t) => t.department_id === this.armedToSelectTenantId);
      if (tenant) {
        this.selectTenant(null, null, this.armedToSelectTenantId);
      }
      // Clear the armed to select tenant id. This is set from the Observables subscribe in NG On init.
      this.armedToSelectTenantId = null;
      // Update URL, remove the param string from it
      this.location.replaceState(`projects/${this.projectService.currentSelectedProjectId}/peb`);
    }
  }

  private async getProject() {
    this.project = await this.projectService.getProjectById(this.project.id, this.projectFields).toPromise();
    // get default trades from database and put them in tenant costs
    const projectTradeIds = JSON.parse(this.project.trades);
    let projectTrades = [];
    let allTrades: Trade[] = [];
    if (projectTradeIds) {
      // get default construction square footage
      allTrades = await this.projectService.getTrades().toPromise();
      projectTrades = filter(allTrades, (t) => projectTradeIds.indexOf(t.id) > -1);
    }
    this.allTrades = allTrades;
    this.projectTrades = projectTrades;
  }

  private formatFields() {
    this.selectedPEB.rental_rate = this.formatDollar(this.selectedPEB.rental_rate);
    this.selectedPEB.tenant_allowance = this.formatDollar(this.selectedPEB.tenant_allowance);
    this.selectedPEB.tenant_allowance_per_sqft = this.formatDollar(this.selectedPEB.tenant_allowance_per_sqft);
    for (const i of this.selectedPEB.items) {
      i.cost_per_sqft = this.formatDollar(i.cost_per_sqft);
      i.subtotal = this.formatDollar(i.subtotal);
    }
  }

  private calculateFields(itemChanged?: PEBItem, lineItemPropertyChanged?: string) {
    // calculate cost line items
    // calculate cost item totals
    // calculate fee line items and cost item percentages
    // calculate subtotals
    for (const i of this.selectedPEB.costItems) {
      this.calculateCostLineItem(i, itemChanged && itemChanged.id === i.id ? lineItemPropertyChanged : null);
    }
    this.calculateCostItemTotals();
    for (const i of this.selectedPEB.feeItems) {
      this.calculateFeeLineItem(i, itemChanged && itemChanged.id === i.id ? lineItemPropertyChanged : null);
    }
    for (const i of this.selectedPEB.costItems) {
      const costItemTotal =
        i.owner_type_id === 1 ? +this.selectedPEB.tenantCostItemTotal : +this.selectedPEB.trustCostItemTotal;
      i.percent_cost = costItemTotal ? (100 * +i.subtotal) / costItemTotal : 0;
    }
    if (itemChanged?.type_id === PEBItemTypeEnum.ManagementFee) {
      this.selectedPEB.managementFeePercentage = itemChanged.percent_cost;
    }
    this.calculateHeader(
      this.selectedPEB,
      ['tenant_allowance', 'tenant_allowance_per_sqft'].indexOf(lineItemPropertyChanged) > -1
        ? lineItemPropertyChanged
        : null
    );
    this.addManagementFee(this.selectedPEB);
    this.calculateSubtotals(this.selectedPEB);
    this.sortPEB(this.selectedPEB);
  }

  private calculateHeader(peb: PEB, modifiedField?: string) {
    peb.circulation_sqft = 0.01 * +this.project.building_circulation_factor * +peb.occupied_sqft;
    peb.rental_sqft_w_circ = +peb.occupied_sqft + +peb.circulation_sqft;
    if (
      (!peb.tenant_allowance_fixed_property && !modifiedField && +peb.tenant_allowance) ||
      modifiedField === 'tenant_allowance' ||
      (!modifiedField && peb.tenant_allowance_fixed_property === 'tenant_allowance')
    ) {
      peb.tenant_allowance_fixed_property = 'tenant_allowance';
      peb.tenant_allowance_per_sqft = this.formatDollar(+peb.tenant_allowance / +peb.rental_sqft_w_circ);
    } else if (
      (!modifiedField && +peb.tenant_allowance_per_sqft) ||
      modifiedField === 'tenant_allowance_per_sqft' ||
      (!modifiedField && peb.tenant_allowance_fixed_property === 'tenant_allowance_per_sqft')
    ) {
      peb.tenant_allowance_fixed_property = 'tenant_allowance_per_sqft';
      peb.tenant_allowance = this.formatDollar(+peb.rental_sqft_w_circ * +peb.tenant_allowance_per_sqft); // rental sf w/ circ * tenant allowance
    }
    peb.total_rent_per_year = +peb.rental_sqft_w_circ * +peb.rental_rate; // rental sf w/ circ * rental rate
    if (peb.tenant_type_id !== 3) {
      const tenantAllowanceItem = cloneDeep(this.tenantAllowanceItemTemplate);
      const trustAllowanceItem = cloneDeep(this.trustAllowanceItemTemplate);
      tenantAllowanceItem.subtotal = this.formatDollar(-1 * +peb.tenant_allowance);
      trustAllowanceItem.subtotal = this.formatDollar(+peb.tenant_allowance);
      peb.tenantAllowanceItem = tenantAllowanceItem;
      peb.trustAllowanceItem = trustAllowanceItem;
      remove(peb.items, (i) => [PEBItemTypeEnum.TenantAllowance].indexOf(i.type_id) > -1);
      remove(peb.items, (i) => [PEBItemTypeEnum.TenantAllowance].indexOf(i.type_id) > -1);
      peb.items.push(peb.tenantAllowanceItem);
      peb.items.push(peb.trustAllowanceItem);
    }
    this.sortPEB(peb);
  }

  private addManagementFee(peb: PEB) {
    let managementFeeBasisTotal = 0;
    managementFeeBasisTotal += +this.selectedPEB.tenantCostItemTotal;
    for (const i of peb.feeItems) {
      if (i.owner_type_id === 1) {
        managementFeeBasisTotal += +i.subtotal;
      }
    }
    const foundTenantAllowanceItem = peb.items.find(
      (i) => i.type_id === PEBItemTypeEnum.TenantAllowance && i.owner_type_id === 1
    );
    if (foundTenantAllowanceItem) {
      managementFeeBasisTotal += +foundTenantAllowanceItem.subtotal;
    }
    const foundManagementFeeItem = this.selectedPEB.items.find((i) => i.type_id === PEBItemTypeEnum.ManagementFee);
    const percentCost = this.selectedPEB.managementFeePercentage;
    const subtotal = this.formatDollar(
      ((+managementFeeBasisTotal > 0 ? +managementFeeBasisTotal : 0) * +percentCost) / 100
    );
    if (!foundManagementFeeItem) {
      const managementFeeItem = cloneDeep(this.managementFeeItemTemplate);
      managementFeeItem.percent_cost = percentCost;
      managementFeeItem.subtotal = subtotal;
      this.selectedPEB.managementFeeItem = managementFeeItem;
      this.selectedPEB.items.push(this.selectedPEB.managementFeeItem);
      this.sortPEB(peb);
    } else {
      foundManagementFeeItem.percent_cost = percentCost;
      foundManagementFeeItem.subtotal = subtotal;
    }
  }

  private calculateCostItemTotals() {
    this.selectedPEB.trustCostItemTotal = sumBy(
      filter(this.selectedPEB.trustItems, (i) => i.type_id === 1),
      (i) => +i.subtotal
    );
    this.selectedPEB.tenantCostItemTotal = sumBy(
      filter(this.selectedPEB.tenantItems, (i) => i.type_id === 1),
      (i) => +i.subtotal
    );
  }

  private calculateSubtotals(peb: PEB) {
    peb.trustInvestment = sumBy(peb.trustItems, (i) => +i.subtotal);
    peb.hideTrustSection = !peb.trustItems.find((i) => +i.subtotal !== 0);
    peb.tenantInvestment = sumBy(peb.tenantItems, (i) => +i.subtotal);
    peb.hideTenantSection = !peb.tenantItems.find((i) => +i.subtotal !== 0);
    peb.total_cost = sumBy(peb.items, (i) => +i.subtotal);
    peb.total_cost_per_sqft = peb.construction_sqft ? peb.total_cost / peb.construction_sqft : 0;
  }

  private calculateCostLineItem(item: PEBItem, propertyChanged?: string) {
    if (!item.fixed_properties) {
      item.fixed_properties = [];
    }
    if (propertyChanged && item.fixed_properties) {
      if (item[propertyChanged] !== null && ['sqft', 'cost_per_sqft', 'subtotal'].indexOf(propertyChanged) > -1) {
        if (item.fixed_properties.indexOf(propertyChanged) === -1) {
          if (item.fixed_properties.length === 2) {
            // prioritize calculating subtotal then cost_per_sqft
            remove(item.fixed_properties, (p) => p === (propertyChanged !== 'subtotal' ? 'subtotal' : 'cost_per_sqft'));
          }
          item.fixed_properties.push(propertyChanged);
        }
      } else {
        remove(item.fixed_properties, (p) => p === propertyChanged);
      }
    }
    // determine based on fixed_properties
    if (item.fixed_properties.length < 2) {
      // ensure sqft is there and wasn't just cleared
      if (item.fixed_properties.indexOf('sqft') === -1 && (propertyChanged !== 'sqft' || item.sqft !== null)) {
        item.fixed_properties.push('sqft');
      }
      // then if length is still < 2, ensure cost_per_sqft is there and wasn't just cleared
      if (
        item.fixed_properties.length < 2 &&
        item.fixed_properties.indexOf('cost_per_sqft') === -1 &&
        (propertyChanged !== 'cost_per_sqft' || item.cost_per_sqft !== null)
      ) {
        item.fixed_properties.push('cost_per_sqft');
      }
      // then if length is still < 2, add subtotal
      if (item.fixed_properties.length < 2) {
        item.fixed_properties.push('subtotal');
      }
    }
    let variableProperty;
    if (item.sqft !== null && item.cost_per_sqft !== null && item.subtotal !== null) {
      variableProperty = ['sqft', 'cost_per_sqft', 'subtotal'].find((p) => item.fixed_properties.indexOf(p) === -1);
    } else if (item.cost_per_sqft !== null && item.subtotal !== null) {
      variableProperty = 'sqft';
    } else if (item.sqft !== null && item.subtotal !== null) {
      variableProperty = 'cost_per_sqft';
    } else if (item.sqft !== null && item.cost_per_sqft !== null) {
      variableProperty = 'subtotal';
    }
    if (variableProperty === 'sqft') {
      item.sqft = +item.cost_per_sqft ? Math.round(+item.subtotal / +item.cost_per_sqft) : 0;
    } else if (variableProperty === 'cost_per_sqft') {
      item.cost_per_sqft = this.formatDollar(+item.sqft ? +item.subtotal / +item.sqft : 0);
    } else if (variableProperty === 'subtotal') {
      item.subtotal = this.formatDollar(+item.cost_per_sqft * +item.sqft);
    }
  }

  private calculateFeeLineItem(item: PEBItem, propertyChanged?: string) {
    if (!item.fixed_properties) {
      item.fixed_properties = [];
    }
    if (propertyChanged && ['percent_cost', 'subtotal'].indexOf(propertyChanged) > -1) {
      item.fixed_properties = [propertyChanged];
    } else {
      remove(item.fixed_properties, (p) => p === propertyChanged);
    }
    if (item.fixed_properties.length < 1) {
      item.fixed_properties = ['percent_cost'];
    }
    const variableProperty = item.fixed_properties[0];
    if (variableProperty === 'percent_cost') {
      item.subtotal = this.formatDollar(
        (+item.percent_cost *
          (item.owner_type_id === 2 ? +this.selectedPEB.trustCostItemTotal : +this.selectedPEB.tenantCostItemTotal)) /
          100
      );
    } else if (variableProperty === 'subtotal') {
      const costItemTotal =
        +item.owner_type_id === 2 ? +this.selectedPEB.trustCostItemTotal : +this.selectedPEB.tenantCostItemTotal;
      item.percent_cost = +costItemTotal ? (100 * +item.subtotal) / +costItemTotal : 0;
    }
  }

  private sortPEB(peb: PEB) {
    if (peb && peb.items) {
      peb.items = sortBy(peb.items, (i) => {
        const rank = {
          [PEBItemTypeEnum.Cost]: 1,
          [PEBItemTypeEnum.Fee]: 2,
          [PEBItemTypeEnum.Contingency]: 3,
          [PEBItemTypeEnum.GeneralConditions]: 4,
          [PEBItemTypeEnum.TenantAllowance]: 5,
          [PEBItemTypeEnum.ManagementFee]: 6,
        };
        return rank[i.type_id];
      });
    }
    peb.costItems = filter(peb.items, (i) => i.type_id === 1);
    peb.feeItems = filter(peb.items, (i) => [2, 3, 5].indexOf(i.type_id) > -1);
    peb.tenantItems = filter(peb.items, (i) => i.owner_type_id === 1);
    peb.trustItems = filter(peb.items, (i) => i.owner_type_id === 2);
  }

  public async breakForUnsavedChanges(): Promise<boolean> {
    if (this.unsavedChangesExist) {
      let breakout = false;
      await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Unsaved Changes',
          descriptionText: 'You have unsaved changes that will be discarded if you continue. Do you wish to continue?',
          confirmationButtonText: 'Continue',
        })
        .toPromise()
        .then(async (isConfirmed) => {
          if (!isConfirmed) {
            breakout = true;
          } else {
            this.unsavedChangesExist = false;
            this.isEditing = false;
            this.refresh();
          }
        });
      return breakout;
    } else {
      return false;
    }
  }

  public modifyField(item: PEB | PEBItem, lineItemPropertyChanged?: string) {
    this.unsavedChangesExist = true;
    item.is_modified = true;
    this.calculateFields(item, lineItemPropertyChanged);
  }

  public addLineItem(typeId: number, ownerTypeId: number) {
    const latestLineItem = this.selectedPEB.items ? maxBy(this.selectedPEB.items, 'id') : null;
    const latestLineItemId = latestLineItem ? +latestLineItem.id : null;
    const foundType = this.itemTypes.find((t) => typeId === typeId);
    const newLineItem = {
      id: (latestLineItemId || 0) + 1,
      peb_id: this.selectedPEB.id,
      owner_type_id: ownerTypeId,
      type_id: typeId,
      trade: `New ${foundType.name} item`,
      percent_cost: 0,
      sqft: this.selectedPEB.construction_sqft || this.project.square_footage || 0,
      cost_per_sqft: 0,
      subtotal: 0,
      editable: true,
      is_new: true,
      fixed_properties: [],
    };
    if (this.selectedPEB.items && this.selectedPEB.items.length > 0) {
      this.selectedPEB.items.push(newLineItem);
    } else {
      this.selectedPEB.items = [newLineItem];
    }
    this.unsavedChangesExist = true;
    this.sortPEB(this.selectedPEB);
    this.sortPEB(this.filteredTenantPEBs.find((peb) => this.selectedPEB.id === peb.id));
  }

  public deleteLineItem(item: PEBItem) {
    if (item.type_id === 3) {
      this.snackbar.open(`Contingency Fee cannot be deleted! Instead, set '% Cost' to 0%.`);
    } else if (item.type_id === 5) {
      this.snackbar.open(`General Conditions Fee cannot be deleted! Instead, set '% Cost' to 0%.`);
    }
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Delete Item',
        headerText: 'Delete PEB Line Item',
        descriptionText: 'Are you sure you want to delete this PEB Line Item?',
        confirmationButtonText: 'Delete Item',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed && this.selectedPEB) {
          remove(this.selectedPEB.items, (i) => i.id === item.id);
          remove(this.selectedPEB.tenantItems, (i) => i.id === item.id);
          remove(this.selectedPEB.trustItems, (i) => i.id === item.id);
          if (!item.is_new) {
            if (this.selectedPEB.deletedItems) {
              this.selectedPEB.deletedItems.push(item);
            } else {
              this.selectedPEB.deletedItems = [item];
            }
          }
          this.unsavedChangesExist = true;
          this.calculateFields(this.selectedPEB);
        }
      });
  }

  public async selectTenant(tenantId?: number, selectEvent?, departmentId?: number, selectPEB = true) {
    // if breakForUnsavedChanges, silently return to the previous tenant tab
    if (await this.breakForUnsavedChanges()) {
      this.changeTenantTabUIOnly = true;
      this.tenantTabGroup.selectedIndex = this.projectTenants.map((t) => t.id).indexOf(this.selectedTenantId);
      return;
    }
    if (tenantId) {
      this.selectedTenantId = tenantId;
    } else if (selectEvent) {
      this.selectedTenantId = this.projectTenants[selectEvent.index].id;
    } else if (departmentId) {
      const foundTenant = this.projectTenants.find((t) => t.department_id === departmentId);
      this.selectedTenantId = foundTenant ? foundTenant.id : null;
    } else {
      console.error(`Invalid tenant selection: null`);
    }
    this.filteredTenantPEBs = this.pebs.filter((peb) => +peb.tenant_id === +this.selectedTenantId);

    if (this.filteredTenantPEBs) {
      this.filteredTenantPEBs.forEach((filteredPEB) => {
        filteredPEB.sections = [{ name: 'UHAT Investment', owner_type_id: 2 }];
        const foundTenant = this.projectTenants.find((t) => t.id === filteredPEB.tenant_id);
        if (foundTenant && foundTenant.type_id !== 3) {
          filteredPEB.sections.unshift({ name: foundTenant.tenant_name, owner_type_id: 1 });
        }
        this.sortPEB(filteredPEB);
        this.calculateHeader(filteredPEB);
        this.calculateSubtotals(filteredPEB);
      });
    }
    if (selectPEB) {
      const foundPEB = this.filteredTenantPEBs.length > 0 ? this.filteredTenantPEBs[0] : null;
      await this.selectPEB(foundPEB);
    }
    if (this.currentTenant && this.currentTenant.representative_id) {
      this.tenantRep = await this.userService.getUserById(this.currentTenant.representative_id).toPromise();
    } else {
      this.tenantRep = null;
    }
  }

  public async selectPEB(peb: PEB) {
    if (await this.breakForUnsavedChanges()) {
      return;
    }
    if (peb) {
      // this.showCoverLetter = false;
      const foundPEB = this.pebs.find((p) => p.id === peb.id);
      this.selectedPEB = foundPEB;
      if (this.selectedPEB && this.selectedPEB.tenant_id !== this.selectedTenantId) {
        await this.selectTenant(this.selectedPEB.tenant_id, null, null, false);
      }
      this.selectedPEB.sections = [{ name: 'UHAT Investment', owner_type_id: 2 }];
      const foundTenant = this.projectTenants.find((t) => t.id === this.selectedTenantId);
      if (foundTenant && foundTenant.type_id !== 3) {
        this.selectedPEB.sections.unshift({ name: foundTenant.tenant_name, owner_type_id: 1 });
        this.selectedPEB.originalManagementFeePercentage = foundTenant.peb_management_fee_percentage;
        this.selectedPEB.managementFeePercentage = foundTenant.peb_management_fee_percentage;
      }
      this.sortPEB(this.selectedPEB);
      this.sortPEB(this.filteredTenantPEBs.find((selectPeb) => this.selectedPEB.id === selectPeb.id));
      this.formatFields();
      this.calculateFields();
    } else {
      this.selectedPEB = null;
      // this.showCoverLetter = true;
    }
  }

  public async selectCoverLetter() {
    if (await this.breakForUnsavedChanges()) {
      return;
    }
    this.selectedPEB = null;
    // this.showCoverLetter = true;
  }

  public async addPEB(tenant: ProjectTenantConstruction) {
    if (await this.breakForUnsavedChanges()) {
      return;
    }
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Creating..');
    await this.getProject();
    const pebToCreate: PEB = {
      project_id: this.project.id,
      construction_sqft: this.project.square_footage,
      occupied_sqft: 0,
      rental_rate: 0,
      tenant_allowance: 0,
      tenant_allowance_per_sqft: 0,
      tenant_id: tenant.id,
    };
    const newPEB: PEB = await this.pebService.createPEB(pebToCreate, this.pebFields).toPromise();

    const itemPromises = [];
    for (const t of this.projectTrades) {
      const itemToCreate: PEBItem = {
        name: `${t.name} Budget`,
        peb_id: newPEB.id,
        sqft: this.project.square_footage,
        type_id: 1,
        trade_id: t.id,
        owner_type_id: tenant.type_id === 3 ? 2 : 1,
      };
      itemPromises.push(this.pebService.createPEBItem(itemToCreate, this.itemFields).toPromise());
    }
    const contingencyItemToCreate: PEBItem = {
      name: `Contingency Fee`,
      peb_id: newPEB.id,
      type_id: 3,
      owner_type_id: tenant.type_id === 3 ? 2 : 1,
    };
    itemPromises.push(this.pebService.createPEBItem(contingencyItemToCreate, this.itemFields).toPromise());
    const genConItemToCreate: PEBItem = {
      name: `General Conditions Fee`,
      peb_id: newPEB.id,
      type_id: 5,
      owner_type_id: tenant.type_id === 3 ? 2 : 1,
    };
    itemPromises.push(this.pebService.createPEBItem(genConItemToCreate, this.itemFields).toPromise());

    await Promise.all(itemPromises);
    this.snackbar.open('PEB created!');
    this.selectedPEB = newPEB;
    await this.refresh();
    this.progressIndicatorService.close();
  }

  public async duplicatePEB() {
    if (await this.breakForUnsavedChanges()) {
      return;
    }
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Creating PEB...');
    const pebToCreate: PEB = {
      project_id: this.selectedPEB.project_id,
      design_timeline: this.selectedPEB.design_timeline,
      construction_timeline: this.selectedPEB.construction_timeline,
      total_timeline: this.selectedPEB.total_timeline,
      construction_sqft: this.selectedPEB.construction_sqft,
      occupied_sqft: this.selectedPEB.occupied_sqft,
      rental_rate: this.selectedPEB.rental_rate,
      tenant_allowance_fixed_property: this.selectedPEB.tenant_allowance_fixed_property,
      tenant_allowance: this.selectedPEB.tenant_allowance,
      tenant_allowance_per_sqft: this.selectedPEB.tenant_allowance_per_sqft,
      tenant_id: this.selectedPEB.tenant_id,
    };
    const newPEB = await this.pebService.createPEB(pebToCreate, this.pebFields).toPromise();
    for (const i of this.selectedPEB.items as any[]) {
      const itemToCreate = {
        name: i.trade_name ? `${i.trade_name} Budget` : i.draft_name || i.name,
        trade_id: i.trade_id,
        peb_id: newPEB.id,
        owner_type_id: i.owner_type_id,
        type_id: i.type_id,
        fixed_properties: JSON.stringify(i.fixed_properties),
        percent_cost: i.percent_cost,
        sqft: i.sqft,
        cost_per_sqft: i.cost_per_sqft,
        subtotal: i.subtotal,
      };
      const newItem = await this.pebService.createPEBItem(itemToCreate, this.itemFields).toPromise();
    }
    this.snackbar.open(`PEB duplicated!`);
    this.refresh();
    this.progressIndicatorService.close();
  }

  public deletePEB() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Delete PEB',
        headerText: 'Delete PEB',
        confirmationButtonText: 'Delete PEB',
        descriptionText: 'Are you sure you want to delete this PEB?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          if (this.selectedPEB && this.selectedPEB.id) {
            this.filteredTenantPEBs = this.filteredTenantPEBs.filter((peb) => peb.id !== this.selectedPEB.id);
            const otherPebs = this.filteredTenantPEBs.length;
            const selectedPeb = otherPebs ? this.filteredTenantPEBs[otherPebs - 1] : null;

            this.pebService.deletePEB(this.selectedPEB.id).subscribe(async () => {
              this.snackbar.open('PEB deleted!');
              this.selectedPEB = selectedPeb;
              this.unsavedChangesExist = false;
              await this.refresh();
            });
          }
        }
      });
  }

  public async saveCoverLetter(tenant) {
    this.snackbar.open('Your changes have been saved.');
    this.unsavedChangesExist = false;
    this.isEditing = false;
    await this.saveCoverLetterDesc(tenant.id, tenant.cover_letter_text);
  }

  private async saveCoverLetterDesc(id: number, text: string) {
    await this.projectTenantService.updateProjectTenant(id, { peb_cover_letter_text: text }).toPromise();
  }

  public async discardCoverLetterChanges() {
    this.unsavedChangesExist = false;
    this.isEditing = false;
    this.refresh();
  }

  public async savePEB(refresh = true) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Saving PEB...');
    const promises = [];
    if (this.selectedPEB.is_modified) {
      const pebToUpdate: PEB = {
        title: this.selectedPEB.draft_title,
        design_timeline: this.selectedPEB.design_timeline,
        construction_timeline: this.selectedPEB.construction_timeline,
        total_timeline: this.selectedPEB.total_timeline,
        construction_sqft: +this.selectedPEB.construction_sqft,
        occupied_sqft: +this.selectedPEB.occupied_sqft,
        rental_rate: +this.selectedPEB.rental_rate,
        tenant_allowance_fixed_property: this.selectedPEB.tenant_allowance_fixed_property,
        tenant_allowance: +this.selectedPEB.tenant_allowance,
        tenant_allowance_per_sqft: +this.selectedPEB.tenant_allowance_per_sqft,
      };
      promises.push(this.pebService.updatePEB(this.selectedPEB.id, pebToUpdate, this.pebFields).toPromise());
    }
    if (this.selectedPEB.items) {
      for (const di of this.selectedPEB.deletedItems || []) {
        promises.push(this.pebService.deletePEBItem(di.id).toPromise());
        remove(this.selectedPEB.items, (i) => di.id === i.id);
      }
      for (const i of this.selectedPEB.items as any[]) {
        if ([PEBItemTypeEnum.TenantAllowance, PEBItemTypeEnum.ManagementFee].indexOf(i.type_id) === -1) {
          if (i.is_new) {
            const itemToCreate: PEBItem = {
              name: i.trade_name ? `${i.trade_name} Budget` : i.draft_name || i.name,
              trade_id: i.trade_id,
              peb_id: i.peb_id,
              owner_type_id: i.owner_type_id,
              type_id: i.type_id,
              fixed_properties: JSON.stringify(i.fixed_properties),
              percent_cost: +i.percent_cost,
              sqft: i.sqft,
              cost_per_sqft: +i.cost_per_sqft,
              subtotal: +i.subtotal,
            };
            promises.push(this.pebService.createPEBItem(itemToCreate, this.itemFields).toPromise());
          } else {
            const itemToUpdate: PEBItem = {
              name: i.trade_name ? `${i.trade_name} Budget` : i.draft_name || i.name,
              trade_id: i.trade_id,
              fixed_properties: JSON.stringify(i.fixed_properties),
              percent_cost: +i.percent_cost,
              sqft: i.sqft,
              cost_per_sqft: +i.cost_per_sqft,
              subtotal: +i.subtotal,
            };
            promises.push(this.pebService.updatePEBItem(i.id, itemToUpdate, this.itemFields).toPromise());
          }
        }
      }
    }
    if (
      (this.selectedPEB?.managementFeePercentage || this.selectedPEB?.managementFeePercentage === 0) &&
      this.selectedPEB.managementFeePercentage !== this.selectedPEB.originalManagementFeePercentage
    ) {
      promises.push(
        this.projectTenantService
          .updateProjectTenant(this.selectedPEB.tenant_id, {
            peb_management_fee_percentage: this.selectedPEB.managementFeePercentage,
            cb_management_fee_percentage: this.selectedPEB.managementFeePercentage,
          })
          .toPromise()
      );
      const foundTenant = this.projectTenants.find((t) => t.id === this.selectedPEB?.tenant_id);
      foundTenant.peb_management_fee_percentage = this.selectedPEB.managementFeePercentage;
      this.selectedPEB.originalManagementFeePercentage = this.selectedPEB.managementFeePercentage;
    }
    Promise.all(promises).then(async () => {
      this.unsavedChangesExist = false;
      this.snackbar.open('PEB saved!');
      if (refresh) {
        await this.refresh();
      }
      this.progressIndicatorService.close();
    });
  }

  public fillConstructionSF(ownerTypeId) {
    const items = this.selectedPEB.costItems
      ? this.selectedPEB.costItems.filter((i) => i.owner_type_id === ownerTypeId)
      : [];
    for (const i of items) {
      i.sqft = +this.selectedPEB.construction_sqft;
      this.modifyField(i, 'sqft');
    }
  }

  public getTradeName(tradeId: number) {
    const foundTrade = this.allTrades.find((t) => t.id === tradeId);
    if (foundTrade) {
      return foundTrade.name;
    } else {
      return null;
    }
  }

  /**
   * Awaitable Delay function. Could probably be moved out to a Utility Service
   * @param ms Milliseconds to delay for.
   */
  private delay(ms: number): Promise<{}> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Attach commas to a number. TODO: Move to utility service
   * @param x Number to apply commas to
   */
  public numberWithCommas(x: number): string {
    if (!x) {
      return '';
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public getCurrentProject(): ProjectConstruction {
    return this.projectService.currentSelectedProject;
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment().format('LTS');
  }

  /**
   * Get the current date in the format August 15, 2019
   */
  public getCurrentDate(): string {
    return moment().format('LL');
  }

  public blurUSDollarInput(oldValue, item?, propertyName?) {
    const newValue = this.formatDollar(oldValue, item, propertyName);
    // only format input if property was changed during formatting
    if (oldValue !== newValue) {
      this.modifyField(item, propertyName);
    }
  }

  public formatDollar(oldValue, object?, propertyName?) {
    if (oldValue !== null) {
      const newValue = this.USDollarPipe.transform(oldValue, 2, false, false, false);
      if (object && propertyName) {
        object[propertyName] = newValue;
      }
      return newValue;
    } else {
      return null;
    }
  }

  public async openFinalizePebDialog(tenant: ProjectTenantConstruction) {
    this.SubmitForApprovalDialog.open(FinalizePEBDialogComponent, {
      width: '500px',
      data: {
        tenant,
        tenantPebs: this.pebs.filter((p) => p.tenant_id === tenant.id),
      },
    })
      .afterClosed()
      .subscribe((submitted) => {
        if (submitted) {
          this.project.peb_status = PEBStatus.Finalized;
          this.refresh();
        }
      });
  }

  public gotoTask(taskId: number) {
    if (taskId) {
      this.routingService.gotoProjectTask(taskId);
    }
  }

  public isSelectedPeb(tenant: ProjectTenantConstruction, p: PEB): boolean {
    return +tenant.selected_peb_id === +p.id;
  }

  // ---------------------------- Approval Process --------------------------------------------//

  public viewStaffTask(link) {
    window.open(link, '_blank');
  }
  public viewTenantTask(link) {
    window.open(link, '_blank');
  }

  public getTenantById(tenantId: number) {
    return this.projectTenants.find((t) => t.department_id === tenantId);
  }

  public getTenantStatus(tenantId: number): ProjectTenantPEBStatus {
    const tenant = this.projectTenants.find((t) => t.id === tenantId);
    if (!tenant) {
      console.error('Project Tenant with given id from peb does not exist');
      return 0;
    }
    return tenant.peb_status;
  }

  public finalizePEB(tenant) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Finalize PEB',
        headerText: 'Finalize PEB',
        confirmationButtonText: 'Finalize PEB',
        descriptionText:
          'Warning: Finalizing is permanent. Please verify that the selected PEB is 100% correct, as no more changes can be made after finalizing. Are you sure you want to finalize the PEB?',
      })
      .subscribe(async (closeEvent) => {
        if (closeEvent) {
          this.project.peb_status = PEBStatus.Finalized;
          this.projectTenantService
            .updateProjectTenant(tenant.id, { peb_status: ProjectTenantPEBStatus.Finalized })
            .subscribe();
          const tasksToLock = [tenant.peb_approval_task_id, tenant.tenant_approval_task_id];
          for (const taskId of tasksToLock) {
            if (taskId) {
              await this.lockTask(taskId);
            }
          }

          this.refresh();
        }
      });
  }

  public async lockTask(taskId) {
    const task = {
      id: taskId,
      is_locked: 1,
    };

    await this.projectService.updateTask(task).toPromise();
  }

  /**
   * Open the dialog that is responsible for starting the Approvals Process. Creating the initial tasks, activities, and assigning followers.
   * @param tenant The tenant to start the process for
   * @param isInternalApproval Whether the approval is for internal or tenant approval
   */
  public async submitApproval(tenant: ProjectTenantConstruction, isInternalApproval: boolean) {
    if (await this.breakForUnsavedChanges()) {
      return;
    }
    if (!tenant) {
      this.snackbar.open('The selected tenant is invalid.');
      return;
    }
    if (
      (isInternalApproval && tenant.saved_peb_approval_task_id) ||
      (!isInternalApproval && tenant.saved_tenant_approval_task_id)
    ) {
      await this.restartReviewProcess(tenant, isInternalApproval);
      return;
    }

    let currentPEB;
    for (const peb of this.pebs) {
      if (+peb.tenant_id === +tenant.id) {
        currentPEB = peb;
      }
    }

    if (!currentPEB) {
      this.snackbar.open('No PEBs exist for this tenant!');
      return;
    }

    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Generating files for task creation...');

    // Generate PDF Files from this tenants PDFs
    // const pebFiles = await this.getPebsToExport(tenant, currentPEB);
    const pebFiles = [];
    pebFiles.push(this.allCombinedFiles[tenant.id] || (await this.combineFiles(tenant, currentPEB)));

    let newTaskId;
    if (isInternalApproval) {
      const reviewers = await this.displayReviewersService.getInternalReviewers();
      const reviewIds = reviewers.reviewIds;
      const selectedReviewers = reviewers.selectedReviewers;

      const linkedFiles = [];
      for (const fileId of tenant.bubble_drawing_ids) {
        linkedFiles.push(await this.fileService.getIdAndName(fileId).toPromise());
      }

      this.progressIndicatorService.close();
      // Opens create task dialog while passing some predetirmined info
      newTaskId = await tenant.approval_process.beginStaffReview({
        pebName:
          (this.project.title ? this.project.title : 'Project ' + this.project.code) +
          ' (' +
          (currentPEB.tenant_type_id === 3 ? 'UHAT' : currentPEB.tenant_name) +
          ')',
        files: pebFiles,
        linkedFiles,
        reviewIds,
        isTypeLocked: true,
        isReviewersLocked: true,
        areFilesLinked: false,
        selectedReviewers,
      });

      // If the user creating the review task is the same as the first reviewers this creates a task activity on the new task
      if (this.authService.getLoggedInUser().id === selectedReviewers[0].id) {
        await this.eventService
          .createTaskApprovalEvent(newTaskId, TaskReviewStatus.Approved, 'Approved upon creation')
          .toPromise();
      }
      tenant.peb_approval_task_id = newTaskId;
      await this.projectTenantService.updateProjectTenant(tenant.id, { peb_approval_task_id: newTaskId }).toPromise();
    } else {
      const reviewIds = [{ id: tenant.representative_id, status: TaskReviewStatus.Pending }];
      const user = await this.userService
        .getUserById(tenant.representative_id, ['id', 'first_name', 'last_name', 'title', 'user_type_id'])
        .toPromise();
      const assigned_user = {
        id: tenant.representative_id,
        firstName: user.first_name,
        lastName: user.last_name,
        title: user.title,
        user_type_id: user.user_type_id,
        reviewer: true,
      };

      this.progressIndicatorService.close();
      newTaskId = await tenant.approval_process.beginTenantReview({
        pebName:
          (this.project.title ? this.project.title : 'Project ' + this.project.code) +
          ' (' +
          (currentPEB.tenant_type_id === 3 ? 'UHAT' : currentPEB.tenant_name) +
          ')',
        files: pebFiles,
        reviewIds,
        isTypeLocked: true,
        isReviewersLocked: true,
        areFilesLinked: false,
        assigned_user,
      });
      this.projectTenantService
        .updateProjectTenant(tenant.id, {
          tenant_approval_task_id: newTaskId,
          peb_status: ProjectTenantPEBStatus.AwaitingTenantApproval,
        })
        .subscribe();
    }
    if (newTaskId) {
      this.snackbar.open('Approval Process Started and Task Created');
      await this.refresh();
    }
  }

  // private async getPebsToExport(tenant: ProjectTenantConstruction, currentPEB?: PEB) {
  //   if (!currentPEB) {
  //     for (const peb of this.pebs) {
  //       if (+peb.tenant_id === +tenant.id) {
  //         currentPEB = peb;
  //       }
  //     }
  //   }
  //   const pebFiles = [];
  //   this.PEBsToExport = this.pebs.filter((peb) => peb.tenant_id === tenant.id);
  //   // For each PEB we want to set it as the exporting PEB and convert the page into a Kendo PDF. This must be done synchronously
  //   for (const peb of this.PEBsToExport) {
  //     this.exportingPEB = peb;
  //     await this.delay(100); // Delay so that the HTML has time to update from the (exportingPEB binding reassignment)
  //     // We must export the Kendo PDF which returns a Kendo Group object
  //     await this.pdf.export().then(async (groupData) => {
  //       let file: File;
  //       // Contain the toBlob function inside of a promise so we can wait on its completion and push the resulting file into our array.
  //       await new Promise<File>((resolve) => {
  //         pdf.toBlob(groupData, (data) => {
  //           let title =
  //             this.exportingPEB.title != null && this.exportingPEB.title !== ''
  //               ? this.exportingPEB.title
  //               : `PEB_${this.exportingPEB.sequence}`;
  //           title = title.replace(/ /g, '_');
  //           file = new File([data], `${title}_v${tenant.peb_revision}_PRJ${this.project.code}.pdf`);
  //           pebFiles.push(file);
  //           resolve(file);
  //         });
  //       });
  //     });
  //   }
  //
  //   // export the cover letter
  //   await this.delay(100); // Delay so that the HTML has time to update from the (exportingPEB binding reassignment)
  //   // We must export the Kendo PDF which returns a Kendo Group object
  //   await this.coverLetter.export().then(async (groupData) => {
  //     let file: File;
  //     // Contain the toBlob function inside of a promise so we can wait on its completion and push the resulting file into our array.
  //     await new Promise<File>((resolve) => {
  //       pdf.toBlob(groupData, (data) => {
  //         let title =
  //           'Cover_Letter_' + (currentPEB.tenant_type_id === 3 ? 'UHAT' : tenant.tenant_name);
  //         // replaced with this regex to replace ALL occurances, rather than just the first
  //         // '/ /g' means every space, not just the first
  //         title = title.replace(/ /g, '_');
  //         file = new File([data], `${title}_v${tenant.peb_revision}.pdf`);
  //         pebFiles.push(file);
  //         resolve(file);
  //       });
  //     });
  //   });
  //   return pebFiles;
  // }

  public async combineFiles(tenant: ProjectTenantConstruction, currentPEB?: PEB) {
    if (!currentPEB) {
      for (const peb of this.pebs) {
        if (+peb.tenant_id === +tenant.id) {
          currentPEB = peb;
        }
      }
    }
    const files = [];
    this.PEBsToExport = this.pebs.filter((peb) => peb.tenant_id === tenant.id);

    // Generate Cover Letter Export
    const coverLetterGroup = await this.coverLetter.export();
    const coverLetterBase64 = (await exportPDF(coverLetterGroup)).replace('data:application/pdf;base64,', '');
    const coverLetterByteCharacters = atob(coverLetterBase64);
    const coverLetterData = new Array(coverLetterByteCharacters.length);
    for (let i = 0; i < coverLetterByteCharacters.length; i++) {
      coverLetterData[i] = coverLetterByteCharacters.charCodeAt(i);
    }
    let coverLetterTitle = 'Cover_Letter_' + (currentPEB.tenant_type_id === 3 ? 'UHAT' : tenant.tenant_name);
    // replaced with this regex to replace ALL occurances, rather than just the first
    // '/ /g' means every space, not just the first
    coverLetterTitle = coverLetterTitle.replace(/ /g, '_');
    // Combine Files
    files.push({
      file: new Blob([new Uint8Array(coverLetterData)]),
      name: `${coverLetterTitle}_v${tenant.peb_revision}.pdf`,
    });
    for (const peb of this.PEBsToExport) {
      this.exportingPEB = peb;

      // refreshes the html component to assure all data is up-to-date.
      this.appRef.tick();

      // We must export the Kendo PDF which returns a Kendo Group object
      const pebGroup = await this.pdf.export();

      const pebBase64 = (await exportPDF(pebGroup)).replace('data:application/pdf;base64,', '');
      const pebByteCharacters = atob(pebBase64);
      const pebData = new Array(pebByteCharacters.length);
      for (let i = 0; i < pebByteCharacters.length; i++) {
        pebData[i] = pebByteCharacters.charCodeAt(i);
      }

      let title =
        this.exportingPEB.title != null && this.exportingPEB.title !== ''
          ? this.exportingPEB.title
          : `PEB_${this.exportingPEB.sequence}`;
      title = title.replace(/ /g, '_');

      files.push({
        file: new Blob([new Uint8Array(pebData)]),
        name: `${title}_v${tenant.peb_revision}.pdf`,
      });
    }
    const linkedFiles = await this.getLinkedFiles(tenant);

    for (const f of linkedFiles) {
      const linkedFile = await this.fileService.downloadFile({ id: f.id }).toPromise();
      files.push({
        file: new Blob([new Uint8Array(linkedFile.file.data)]),
        name: linkedFile.name,
      });
    }

    let combinedFile;
    try {
      combinedFile = await this.fileService.combinePDFs(files).toPromise();
    } catch (e) {
      const errorSnack = this.snackbar.open(e.error.message, 'Close', { duration: undefined });
      errorSnack.onAction().subscribe(async () => {
        this.snackbar.dismiss();
      });
      return;
    }

    const blob = new Blob([new Uint8Array(combinedFile.data)], { type: 'application/pdf' });
    const file = new File([blob], `PEB_v${tenant.peb_revision}_PRJ${this.project.code}.pdf`);

    return file;
    // saveAs(
    //   new Blob([new Uint8Array(combinedFile.data)]),
    //   `PEB_1_v${tenant.peb_revision}_PRJ${this.project.code}.pdf`
    // );
  }

  private getLinkedFiles(tenant: ProjectTenantConstruction) {
    const linkedFiles = [];
    if (tenant.reimbursement_file_id != null) {
      linkedFiles.push({ id: tenant.reimbursement_file_id, name: 'Reimbursement' });
    }
    if (tenant.exhibit_b_file_id != null) {
      linkedFiles.push({ id: tenant.exhibit_b_file_id, name: 'Exhibit B' });
    }
    if (tenant.sublease_contract_file_id != null) {
      linkedFiles.push({ id: tenant.sublease_contract_file_id, name: 'Sublease Contract' });
    }
    return linkedFiles;
  }

  public async getPdfPackage(tenant) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Generating pdf package...');

    const file = await this.combineFiles(tenant);
    this.progressIndicatorService.updateStatus('Downloading File...');
    await saveAs(file);
    this.progressIndicatorService.close();
  }

  /**
   * Approve a set of PEBs for a tenant. This should update the ProjectTenantConstruction peb status, create an approval task activity,
   * and reassign the task.
   * @param tenant The tenant to approve the set of pebs for
   */
  // public approvePeb(tenant: ProjectTenantConstruction) {
  //   this.modalService.openConfirmationDialog( {
  //       titleBarText: `${tenant.tenant_name} - Approve PEBs`,
  //       confirmationButtonText: 'Approve',
  //       cancelButtonText: 'Cancel',
  //       descriptionText: 'You are about to approve this tenants PEBs. You can enter comments that you would like to communicate in the box below. These will only be visible to staff.',
  //       userInput: {
  //         placeholder: 'Additional Comments', required: false
  //       }
  //   }).subscribe(async (comment) => {
  //     if (comment !== false) {
  //       if (!comment || comment.length <= 0) { comment = null; }
  //       if (tenant.peb_status === ProjectTenantPEBStatus.AwaitingWMApproval) {
  //         if (!this.userIsWorkspaceManager()) {
  //           console.warn('WM must approve peb in its current status');
  //           return;
  //         }
  //         this.projectTenantService.updateProjectTenantPEBStatus(tenant, ProjectTenantPEBStatus.AwaitingArchitectApproval, true, true, comment).subscribe(result => {
  //           this.projectService.updateTask({ id: tenant.peb_approval_task_id, assigned_user_id: this.projectService.currentSelectedProject.architect_id }).subscribe();  // Update assigned user.
  //           tenant.peb_status = ProjectTenantPEBStatus.AwaitingArchitectApproval;
  //           this.snackbar.open('WM Approval Successful!');
  //         });
  //       } else if (tenant.peb_status === ProjectTenantPEBStatus.AwaitingArchitectApproval) {
  //         if (!this.userIsArchitect()) {
  //           console.warn('Architect must approve peb in its current status');
  //           return;
  //         }
  //         this.projectTenantService.updateProjectTenantPEBStatus(tenant, ProjectTenantPEBStatus.AwaitingCFMOApproval, true, true, comment).subscribe(result => {
  //           this.projectService.updateTask({ id: tenant.peb_approval_task_id, assigned_user_id: this.projectService.currentSelectedProject.cfmo_id }).subscribe();  // Update assigned user.
  //           tenant.peb_status = ProjectTenantPEBStatus.AwaitingCFMOApproval;
  //           this.snackbar.open('Architect Approval Successful!');
  //         });
  //       } else if (tenant.peb_status === ProjectTenantPEBStatus.AwaitingCFMOApproval) {
  //         if (!this.userIsChiefFacilitiesManagementOfficer()) {
  //           console.warn('CFMO must approve peb in its current status');
  //           return;
  //         }
  //         // Reassign the task to the PM
  //         this.projectService.updateTask({ id: tenant.peb_approval_task_id, assigned_user_id: this.projectService.currentSelectedProject.project_manager_id }).subscribe();  // Update assigned user.
  //         this.projectTenantService.updateProjectTenantPEBStatus(tenant, ProjectTenantPEBStatus.ApprovedInternally, true, true, comment).subscribe(result => {
  //           tenant.peb_status = ProjectTenantPEBStatus.ApprovedInternally;
  //           this.snackbar.open('CFMO Approval Successful!');
  //         });
  //       }
  //     } else {
  //       // Cancel was clicked
  //     }
  //   });
  // }

  /**
   * Internally reject a tenants PEBs. This should update the Project Tenant status to Internal Rejection and reassign the task associated
   * with the PEBs
   * @param tenant The tenant to reject pebs for
   */
  // public rejectPeb(tenant: ProjectTenantConstruction) {
  //   this.modalService.openConfirmationDialog({
  //       titleBarText: `${tenant.tenant_name} - Reject PEBs`,
  //       confirmationButtonText: 'Reject',
  //       cancelButtonText: 'Cancel',
  //       descriptionText: 'You are about to reject this tenants PEBs. Please enter your reason in the box below. These comments are only visible to staff.',
  //       userInput: { placeholder: 'Rejection Reason', required: true }
  //   }).subscribe(async (comment) => {
  //     if (comment !== false) {
  //       if (!comment || comment.length <= 0) { return; }
  //       // If the user is not the user who is supposed to approve we want to ignore this request
  //       if (!this.currentUserCanApprove(tenant)) {
  //         console.warn(`This user cannot approve or reject the peb in its current state`);
  //         this.snackbar.open('This user cannot approve or reject the peb in its current state');
  //         return;
  //       }
  //       this.projectTenantService.updateProjectTenantPEBStatus(tenant, ProjectTenantPEBStatus.InternalRejection, true, false, comment).subscribe(result => {
  //         tenant.peb_status = ProjectTenantPEBStatus.InternalRejection;
  //         this.snackbar.open('PEBs have been rejected and are waiting for review');
  //       });
  //         // Reassign Task Assignee to project manager
  //       this.projectService.updateTask({ id: tenant.peb_approval_task_id, assigned_user_id: this.projectService.currentSelectedProject.project_manager_id }).subscribe();
  //     } else {
  //       // Cancel was clicked
  //     }
  //   });
  // }

  /**
   * Give the provided project tenants PEB status, can the current logged in user Approve or Reject the PEBs?
   * @param tenant Tenant status to use
   */
  // public currentUserCanApprove(tenant: ProjectTenantConstruction): boolean {
  //   return (tenant.peb_status === ProjectTenantPEBStatus.AwaitingWMApproval && this.userIsWorkspaceManager()) ||
  //          (tenant.peb_status === ProjectTenantPEBStatus.AwaitingArchitectApproval && this.userIsArchitect()) ||
  //          (tenant.peb_status === ProjectTenantPEBStatus.AwaitingCFMOApproval && this.userIsChiefFacilitiesManagementOfficer());
  // }

  public userIsWorkspaceManager(): boolean {
    return this.authService.currentUserIsOfWorkspaceRole(
      ApplicationRole.WorkspaceManager,
      this.projectService.currentSelectedProject?.module_id
    );
  }

  public userIsProjectManager(): boolean {
    return this.authService.currentUserIsOfProjectRole(
      ApplicationRole.ProjectManager,
      this.projectService.currentSelectedProjectId
    );
  }

  public userIsChiefFacilitiesManagementOfficer(): boolean {
    return this.authService.currentUserIsOfAppRole(ApplicationRole.ChiefFacilitiesManagementOfficer);
  }

  public userIsArchitect(): boolean {
    return this.authService.currentUserIsOfProjectRole(
      ApplicationRole.ProjectArchitect,
      this.projectService.currentSelectedProjectId
    );
  }

  public getPebStatusText(tenant: ProjectTenantConstruction) {
    return tenant.approval_process.statusText;
    // switch (tenant.peb_status) {
    //   case ProjectTenantPEBStatus.ApprovedByTenant: return 'Approved By Tenant';
    //   case ProjectTenantPEBStatus.ApprovedInternally: return 'Approved By UHAT/1Call';
    //   case ProjectTenantPEBStatus.AwaitingArchitectApproval: return 'Waiting for Architect approval';
    //   case ProjectTenantPEBStatus.AwaitingCFMOApproval: return 'Waiting for CFMO approval';
    //   case ProjectTenantPEBStatus.AwaitingWMApproval: return 'Waiting for Workspace Manager approval';
    //   case ProjectTenantPEBStatus.AwaitingTenantApproval: return 'Waiting for Tenant approval';
    //   case ProjectTenantPEBStatus.Finalized: return 'Finalized';
    //   case ProjectTenantPEBStatus.InternalRejection: return 'Rejected by UHAT/1Call';
    //   case ProjectTenantPEBStatus.PreApproval: return  'Gathering data';
    //   case 0: return  'Gathering data';
    //   case ProjectTenantPEBStatus.TenantRejection: return 'Rejected by tenant';
    //   default: return 'In Progress';
    // }
  }

  /**
   * If a PEB was rejected then we want resubmission to happen instead of a new submission.
   * @param tenant Tenant to resubmit for
   */
  // public async resubmitForApproval(tenant: ProjectTenantConstruction) {
  //   this.modalService.openConfirmationDialog({
  //       titleBarText: `Submit Revised PEB's For UHAT/1Call Review`,
  //       confirmationButtonText: 'Yes',
  //       cancelButtonText: 'Cancel',
  //       descriptionText: 'You are about to APPROVE and RESUBMIT this tenants PEBs for staff review. Is this what you want to do?'
  //   }).subscribe(result => {
  //     if (result) {
  //       const currentProject = this.projectService.currentSelectedProject;
  //       const constructionAndProjectManagerIsSamePerson = currentProject.project_manager_id === currentProject.workspace_manager_id;
  //       if (constructionAndProjectManagerIsSamePerson) {
  //         this.projectTenantService.updateProjectTenantPEBStatus(tenant, ProjectTenantPEBStatus.AwaitingArchitectApproval).subscribe(statusResult => {
  //           this.eventService.createTaskApprovalEvent(tenant.peb_approval_task_id, true, ProjectTenantService.PROJECT_MANAGER_APPROVAL_COMMENT).toPromise();
  //           this.eventService.createTaskApprovalEvent(tenant.peb_approval_task_id, true, ProjectTenantService.WORKSPACE_MANAGER_APPROVAL_COMMENT).subscribe(eventResult => {
  //             tenant.peb_status = ProjectTenantPEBStatus.AwaitingArchitectApproval;
  //             this.snackbar.open('PEB(s) submitted for internal review');
  //             this.refresh();
  //           });
  //         });
  //       } else {
  //         this.projectTenantService.updateProjectTenantPEBStatus(tenant, ProjectTenantPEBStatus.AwaitingWMApproval).subscribe(statusResult => {
  //           this.eventService.createTaskApprovalEvent(tenant.peb_approval_task_id, true, ProjectTenantService.PROJECT_MANAGER_APPROVAL_COMMENT).subscribe(approvalResult => {
  //             tenant.peb_status = ProjectTenantPEBStatus.AwaitingWMApproval;
  //             this.snackbar.open('PEB(s) submitted for internal review');
  //             this.refresh();
  //           });
  //         });
  //       }
  //     }
  //   });
  // }

  public async markPEBasSelected(peb, tenant) {
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Select PEB',
        descriptionText:
          'Please verify that this PEB matches the final one selected by the tenant. Please also verify the tenant has signed the PEB they uploaded.',
        confirmationButtonText: 'Submit',
      })
      .toPromise()
      .then(async (confirmResult) => {
        if (confirmResult) {
          this.projectTenantService.updateProjectTenant(tenant.id, { selected_peb_id: peb.id }).subscribe((data) => {
            tenant.selected_peb_id = peb.id;
          });
        }
      });
  }

  // Helper function for attaching supporting documents
  private _attachFileHelper(preSelectedTags?: { id: number }[]) {
    let data;
    if (preSelectedTags) {
      data = {
        parentResourceType: ResourceType.Project,
        parentResourceId: this.projectService.currentSelectedProjectId,
        maxFiles: 1,
        allowComment: false,
        verifyFileExtension: true,
        preSelectedTags,
      };
    } else {
      data = {
        parentResourceType: ResourceType.Project,
        parentResourceId: this.projectService.currentSelectedProjectId,
        maxFiles: 1,
        allowComment: false,
        verifyFileExtension: true,
      };
    }
    return this.dialog
      .open(FileAttachmentDialogComponent, {
        data,
        disableClose: true,
      })
      .afterClosed();
  }

  /**
   * Attach a sublease contract file to the Project Tenant
   * @param tenant Project Tenant to attach contract to
   */
  public attachSubleaseContract(tenant: ProjectTenantConstruction) {
    this._attachFileHelper([{ id: 46 }]).subscribe((filesToAttach: UhatFileReference[]) => {
      if (filesToAttach) {
        const file = filesToAttach[0];
        tenant.sublease_contract_file_id = file.file_id;
        tenant.sublease_contract_file_name = file.name;
        this.snackbar.open('Sublease Contract Has Been Attached');
        this.projectTenantService
          .updateProjectTenant(tenant.id, { sublease_contract_file_id: file.file_id })
          .subscribe();
        // adds the sublease agreement tag to this file (tag id = 46)
        this.fileService.addTags(file.file_id, [46]).subscribe();
      }
    });
  }

  /**
   * Attach Exhibit B file to Project Tenant
   * @param tenant Project Tenant to attach exhibit B to
   */
  public attachExhibitB(tenant: ProjectTenantConstruction) {
    this._attachFileHelper([{ id: 44 }]).subscribe((filesToAttach: UhatFileReference[]) => {
      if (filesToAttach) {
        const file = filesToAttach[0];
        tenant.exhibit_b_file_id = file.file_id;
        tenant.exhibit_b_file_name = file.name;
        this.snackbar.open('Exhibit B Has Been Attached');
        this.projectTenantService.updateProjectTenant(tenant.id, { exhibit_b_file_id: file.file_id }).subscribe();
        // adds the exhibit b tag to this file (tag id = 44)
        this.fileService.addTags(file.file_id, [44]).subscribe();
      }
    });
  }

  /**
   * Attach Bubble Drawing File to Project Tenant
   * @param tenant The project tenant to attach bubble drawing for
   */
  public attachBubbleDrawingFile(tenant: ProjectTenantConstruction) {
    this._attachFileHelper([{ id: 13 }]).subscribe((filesToAttach: UhatFileReference[]) => {
      if (filesToAttach) {
        const file = filesToAttach[0];
        tenant.bubble_drawing_ids.push(file.file_id);
        this.snackbar.open('Bubble Drawing Has Been Attached');
        this.projectTenantService
          .updateProjectTenant(tenant.id, {
            bubble_drawing_ids: `[${tenant.bubble_drawing_ids.join(',')}]`,
          })
          .subscribe((addResult) => {
            tenant.approval_process
              .loadData(tenant, this.modalService, this.fileService, this.dateService, this.taskActionsService)
              .then();
          });
        // adds the bubble drawing tag to this file (tag id = 13)
        this.fileService.addTags(file.file_id, [13]).subscribe();
      }
    });
  }

  /**
   * Attach Reimbursement File to Project Tenant
   * @param tenant The project tenant to attach reimbursement for
   */
  public attachReimbursementFile(tenant: ProjectTenantConstruction) {
    this._attachFileHelper([{ id: 43 }]).subscribe((filesToAttach: UhatFileReference[]) => {
      if (filesToAttach) {
        const file = filesToAttach[0];
        tenant.reimbursement_file_id = file.file_id;
        tenant.reimbursement_file_name = file.name;
        this.snackbar.open('Reimbursement Has Been Attached');
        this.projectTenantService.updateProjectTenant(tenant.id, { reimbursement_file_id: file.file_id }).subscribe();
        // adds the reimbursement agt tag to this file (tag id = 43)
        this.fileService.addTags(file.file_id, [43]).toPromise();
      }
    });
  }

  public removeBubbleDrawing(tenant: ProjectTenantConstruction, file: number) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Bubble Drawing',
        headerText: 'Remove Bubble Drawing',
        confirmationButtonText: 'Remove',
        descriptionText:
          'Are you sure you want to detach the bubble drawing? It will still be available in Project Files.',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          // remove the bubble drawing tag from this file (id = 13)
          this.fileService.removeTags(file, [13]).subscribe();
          tenant.bubble_drawing_ids.splice(tenant.bubble_drawing_ids.indexOf(file), 1);
          this.snackbar.open('Bubble Drawing Has Been Removed');
          tenant.approval_process
            .loadData(tenant, this.modalService, this.fileService, this.dateService, this.taskActionsService)
            .then();
          this.projectTenantService
            .updateProjectTenant(tenant.id, {
              bubble_drawing_ids: `[${tenant.bubble_drawing_ids.join(',')}]`,
            })
            .subscribe();
        }
      });
  }

  public removeSubleaseContract(tenant: ProjectTenantConstruction) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Sublease Contract',
        descriptionText:
          'Are you sure you want to detach the Sublease Contract? It will still be available in Project Files.',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          // remove the bubble drawing tag from this file (id = 46)
          this.fileService.removeTags(tenant.sublease_contract_file_id, [46]).subscribe();
          tenant.sublease_contract_file_id = null;
          tenant.sublease_contract_file_name = null;
          this.snackbar.open('Sublease contract has been removed');
          this.projectTenantService.updateProjectTenant(tenant.id, { sublease_contract_file_id: null }).subscribe();
        }
      });
  }
  public removeExhibitBFromTenant(tenant: ProjectTenantConstruction) {
    this.modalService
      .openConfirmationDialog({
        headerText: 'Remove Exhibit B',
        descriptionText: 'Are you sure you want to detach the Exhibit B? It will still be available in Project Files.',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          // remove the bubble drawing tag from this file (id = 44)
          this.fileService.removeTags(tenant.exhibit_b_file_id, [44]).subscribe();
          tenant.exhibit_b_file_id = null;
          tenant.exhibit_b_file_name = null;
          this.snackbar.open('Exhibit B has been removed');
          this.projectTenantService.updateProjectTenant(tenant.id, { exhibit_b_file_id: null }).subscribe();
        }
      });
  }

  // stops the review process and adds to revision if not reset
  public async resetReview(tenant, reset: boolean) {
    if (await this.breakForUnsavedChanges()) {
      return;
    }

    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Reset Approval Process',
        descriptionText:
          'You are about to reset the approval process, are you sure? This will invalidate any existing approvals and require a new approval.',
        confirmationButtonText: 'Submit',
        userInput: {
          required: true,
          placeholder: 'Reason for Reset',
        },
      })
      .toPromise()
      .then(async (res) => {
        if (res) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Resetting Approval');

          if (tenant.peb_approval_task_id) {
            await this.reviewRevisionService.pebInternalRevision(tenant, reset, res);
          }
          if (tenant.tenant_approval_task_id) {
            await this.reviewRevisionService.pebTenantRevision(tenant, reset, res);
          }

          if (reset) {
            const taskId = tenant.peb_approval_task_id;
            const updatedAccessoryData = await this.displayReviewersService.getCurrentReviewers(taskId);

            if (!updatedAccessoryData.isTheSame) {
              const taskData = {
                id: taskId,
                accessory_data: JSON.stringify(updatedAccessoryData.accessoryData),
                assigned_user_id: updatedAccessoryData.accessoryData.reviewChain[0].id,
                status_id: 1,
              };
              await this.projectService.updateTask(taskData).toPromise();
            }
          }

          this.progressIndicatorService.updateStatus('Refreshing Tenant Info');

          await this.refresh();
          this.progressIndicatorService.close();
          this.snackbar.open('The review process has been reset');
        }
      });
  }

  // restarts a review process that has been stopped. Resets all the reviewers to pending and recreates the attached file
  private async restartReviewProcess(tenant, isInternal) {
    if (await this.breakForUnsavedChanges()) {
      return;
    }

    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Generating files for task creation...');
    this.snackbar.open('Restarting Review');

    const combinedFile = [await this.combineFiles(tenant)];
    if (isInternal) {
      const linkedFiles = [];
      for (const fileId of tenant.bubble_drawing_ids) {
        linkedFiles.push(await this.fileService.getIdAndName(fileId).toPromise());
      }

      await this.reviewRevisionService.internalPebSubmitRevision(tenant, combinedFile, 48, linkedFiles);
    } else {
      await this.reviewRevisionService.tenantPebSubmitRevision(tenant, combinedFile, 48);
    }
    await this.refresh();
  }

  public removeReimbursementFromTenant(tenant: ProjectTenantConstruction) {
    this.modalService
      .openConfirmationDialog({
        headerText: 'Remove Reimbursement Agreement',
        descriptionText:
          'Are you sure you want to detach the Reimbursement? It will still be available in Project Files.',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          // remove the bubble drawing tag from this file (id = 43)
          this.fileService.removeTags(tenant.reimbursement_file_id, [43]).subscribe();
          tenant.reimbursement_file_id = null;
          tenant.reimbursement_file_name = null;
          this.snackbar.open('Reimbursement has been removed');
          this.projectTenantService.updateProjectTenant(tenant.id, { reimbursement_file_id: null }).subscribe();
        }
      });
  }

  async addUHATManaged() {
    if (await this.breakForUnsavedChanges()) {
      return;
    }
    await this.projectTenantService
      .linkTenantToProject(this.projectService.currentSelectedProjectId, null, null, 3)
      .toPromise();
    this.snackbar.open(`UHAT Managed Budget added!`);
    this.refresh();
  }

  public async openNewCustomerDialog() {
    if (await this.breakForUnsavedChanges()) {
      return;
    }
    const dialogRef = this.dialog.open(CustomerDialogComponent, {
      width: '440px',
      data: {
        projectId: this.projectService.currentSelectedProjectId,
      },
    });

    dialogRef.afterClosed().subscribe((createdCustomer) => {
      if (createdCustomer) {
        this.refresh();
      }
    });
  }
  /**
   * Should the user be allowed to edit the additional forms (Sublease contract and Exhibit B)
   * Not if they have been sent to the tenant already!
   * @param tenant Project Tenant to check for
   */
  //                                   (tenant.approval_process.canReset && !tenant.a) ||
  //                                   tenant.approval_process.isFinalized
  public allowAdditionalFormEdit(tenant: ProjectTenantConstruction) {
    return !tenant.approval_process.canReset && !tenant.approval_process.isFinalized;
    // return ![
    //   ProjectTenantPEBStatus.AwaitingTenantApproval,
    //   ProjectTenantPEBStatus.ApprovedByTenant,
    //   ProjectTenantPEBStatus.Finalized,
    // ].find((n) => n === tenant.peb_status);
  }

  /**
   * Is the tenant status one that is followed by Finalization?
   * @param tenant The tenant to check the status of
   */
  // public tenantPebIsAwaitingFinalization(tenant: ProjectTenantConstruction): boolean {
  //   // return tenant.peb_status === ProjectTenantPEBStatus.ApprovedByTenant ||
  //   //        tenant.peb_status === ProjectTenantPEBStatus.ApprovedInternallySkipTenant ||
  //   //        tenant.peb_status === ProjectTenantPEBStatus.ApprovedInternally;
  //   return tenant.selected_peb_id != null && (tenant.peb_status === ProjectTenantPEBStatus.ApprovedByTenant ||
  //     tenant.peb_status === ProjectTenantPEBStatus.ApprovedInternallySkipTenant);
  // }

  public gotoDirectoryPage() {
    this.routingService.gotoProjectDirectoryPage();
  }

  exportCoverLetter() {
    this.downloading = true;
    this.coverLetter.saveAs(`PEB_Cover_Letter.pdf`);
    this.downloading = false;
  }

  public async exportPEB(peb) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Exporting...');
    this.downloading = true;
    this.PEBsToExport = peb;
    const originalTenantId = this.selectedTenantId;
    const originalPEB = this.selectedPEB;

    this.exportingPEB = peb;
    await this.selectPEB(this.exportingPEB);
    this.exportingTenant = this.projectTenants.find((t) => t.id === this.selectedTenantId);

    // refreshes the html component to assure all data is up-to-date.
    this.appRef.tick();

    this.pdf.saveAs(`PEB_${this.exportingPEB.sequence}_v${this.project.peb_revision}_PRJ${this.project.code}.pdf`);
    await this.delay(1000); // Delay so that the PDF has time to download. Unfortunately, there is no saveAs callback.

    // Take the user back to the PEB they were on.
    this.selectTenant(originalTenantId, null, null, false);
    this.selectPEB(originalPEB);
    this.downloading = false;
    this.progressIndicatorService.close();
  }

  // Cover letter functions to get the current data
  get currentTenant() {
    return this.projectTenants.find((tenant) => tenant.id === this.selectedTenantId) || null;
  }

  get currentReviewers() {
    return this.reviewers && this.currentTenant && this.currentTenant.id && this.reviewers[this.currentTenant.id]
      ? this.reviewers[this.currentTenant.id]
      : [];
  }

  get currentTenantInfo(): { first_name: string; name: string; title: string; department: string } {
    if (this.currentTenant && this.tenantRep) {
      return {
        first_name: this.tenantRep.first_name,
        name: `${this.tenantRep.first_name} ${this.tenantRep.last_name}`,
        title: this.tenantRep.title,
        department: this.currentTenant.tenant_name,
      };
    } else if (
      this.projectTenants &&
      this.selectedTenantId &&
      this.projectTenants.find((t) => t.id === this.selectedTenantId).type_id === 3
    ) {
      const first = this.projectService.currentSelectedProject.cfmo_first_name
        ? this.projectService.currentSelectedProject.cfmo_first_name
        : 'Not Given';
      const last = this.projectService.currentSelectedProject.cfmo_last_name
        ? this.projectService.currentSelectedProject.cfmo_last_name
        : '';
      return {
        first_name: first,
        name: `${first} ${last}`,
        title: 'Chief Facilities Management Officer',
        department: 'University Hospitals Authority & Trust',
      };
    } else {
      return { first_name: '', name: '', title: '', department: '' };
    }
  }

  openCoverLetter(): void {
    const dialogRef = this.dialog.open(CoverLetterDialogComponent, {
      width: '700px',
      data: {
        title: 'Preliminary Estimated Budget',
        tenant: {
          ...this.currentTenantInfo,
          tenant_id: this.currentTenant.id,
          type_id: this.currentTenant.type_id,
          cover_letter_text: this.coverLetterDesc,
        },
        pmInfo: this.pmInfo,
        architectInfo: this.architectInfo,
      },
    });

    dialogRef.afterClosed().subscribe(async (tenant) => {
      if (tenant) {
        if (tenant.id) {
          await this.saveCoverLetter(tenant);
          await this.refresh();
        }
      }
    });
  }

  get coverLetterDesc(): string {
    if (this.currentTenant) {
      return this.allCoverLetterDesc[this.currentTenant.id];
    }
    return 'Loading...';
  }

  get pmInfo(): { name: string; title: string; email: string; phone: string } {
    const first = this.projectService.currentSelectedProject.project_manager_first_name
      ? this.projectService.currentSelectedProject.project_manager_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.project_manager_last_name
      ? this.projectService.currentSelectedProject.project_manager_last_name
      : '';
    const email = this.projectService.currentSelectedProject.project_manager_email
      ? this.projectService.currentSelectedProject.project_manager_email
      : '';
    const phone = this.projectService.currentSelectedProject.project_manager_office_phone
      ? this.projectService.currentSelectedProject.project_manager_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Project Manager',
      email,
      phone,
    };
  }
  get wmInfo(): { name: string; title: string; email: string; phone: string } {
    const first = this.projectService.currentSelectedProject.workspace_manager_first_name
      ? this.projectService.currentSelectedProject.workspace_manager_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.workspace_manager_last_name
      ? this.projectService.currentSelectedProject.workspace_manager_last_name
      : '';
    const email = this.projectService.currentSelectedProject.workspace_manager_email
      ? this.projectService.currentSelectedProject.workspace_manager_email
      : '';
    const phone = this.projectService.currentSelectedProject.workspace_manager_office_phone
      ? this.projectService.currentSelectedProject.workspace_manager_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Construction Manager',
      email,
      phone,
    };
  }
  get architectInfo(): { name: string; title: string; email: string; phone: string } {
    if (!this.projectService.currentSelectedProject.architect_first_name) {
      return null;
    }
    const first = this.projectService.currentSelectedProject.architect_first_name
      ? this.projectService.currentSelectedProject.architect_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.architect_last_name
      ? this.projectService.currentSelectedProject.architect_last_name
      : '';
    const email = this.projectService.currentSelectedProject.architect_email
      ? this.projectService.currentSelectedProject.architect_email
      : '';
    const phone = this.projectService.currentSelectedProject.architect_office_phone
      ? this.projectService.currentSelectedProject.architect_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Architect',
      email,
      phone,
    };
  }

  get getCoverLetterStatus(): string {
    if (
      !this.currentTenant ||
      (this.currentTenant.type_id !== 3 &&
        (!this.currentTenant.peb_cover_letter_text || this.currentTenant.peb_cover_letter_text === ''))
    ) {
      return 'Not Started';
    } else if (this.currentTenant.peb_status === ProjectTenantPEBStatus.Finalized) {
      return 'Approved';
    } else {
      return 'In Progress';
    }
  }
  save(file) {
    saveAs(file);
  }

  public viewTask(taskId: number) {
    const dialogRef = this.dialog.open(ViewTaskDialogComponent, {
      data: {
        taskId,
      },
      autoFocus: false,
    });
    dialogRef.componentInstance.reviewChanged.subscribe((data) => {
      this.refresh();
    });
  }
}

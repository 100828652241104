<!-- <header class="mb-4">
  <div class="d-flex align-items-center">
    <span class="d-flex flex-column mr-auto">
      <p class="dkgray font-weight-normal mb-2">View account details by user type or team.</p>
    </span>
    <div class="d-flex align-items-center">
      <button
        mat-button
        color="accent"
        class="ml-3"
        matRipple
        [routerLink]="['/account-review']"
        *ngIf="canReviewAccounts"
      >
        <span>Account Requests</span>
      </button>
      <button
        mat-button
        color="accent"
        class="ml-3"
        *ngIf="this.authService.isAppAdmin"
        matRipple
        (click)="openLoginHelp()"
      >
        <span>Login Help</span>
      </button>
      <button
        mat-button
        class="ml-3"
        *ngIf="authService.isAppAdmin || authService.isARManager"
        color="accent"
        [routerLink]="['/ar-manager']"
      >
        AR Manager
      </button>
    </div>
    <div class="d-flex align-items-center">
      <button
        mat-flat-button
        (click)="addAccount()"
        class="ml-3"
        *ngIf="authService.isStaffOnAnyModule"
        color="success"
      >
        + User
      </button>
    </div>
  </div>
</header> -->

<div class="row align-items-center">
  <div class="col-lg-7">
    <mat-form-field appearance="outline" class="mr-3 mb-4">
      <mat-select [(ngModel)]="selectedView" (selectionChange)="selectionChange($event)">
        <mat-option value="/directory">Accounts</mat-option>
        <mat-option value="/directory/companies">Companies</mat-option>
        <mat-option value="/directory/departments">Departments</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-button-toggle-group class="mb-4" name="userType" aria-label="List">
      <mat-button-toggle
        [ngClass]="{ selectedTab: selectedUserType === user.id }"
        (click)="setUserType(user.id)"
        *ngFor="let user of userTypes"
        class="px-2 lh-34 font-weight-semibold align-items-center d-flex"
        value="Active"
      >
        {{ user.name }}{{ addSToUserType(user) }}
        <span
          *ngIf="userTypesCount[user.id]"
          class="small-badge white ml-2 lh-18"
          [ngClass]="{ 'bg-ltblue': selectedUserType === user.id, 'bg-gray': selectedUserType !== user.id }"
        >
          {{ userTypesCount[user.id] }}
        </span>
      </mat-button-toggle>
      <mat-button-toggle
        [ngClass]="{ selectedTab: selectedUserType === 0 }"
        (click)="setUserType(0)"
        class="px-2 lh-34 font-weight-semibold align-items-center d-flex"
        value="Active"
      >
        All Accounts
        <span
          *ngIf="userTypesCount[0]"
          class="small-badge white ml-2 lh-18"
          [ngClass]="{ 'bg-ltblue': selectedUserType === 0, 'bg-gray': selectedUserType !== 0 }"
        >
          {{ userTypesCount[0] }}
        </span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="col-auto d-flex flex-grow-1 align-items-center justify-content-end mb-4">
    <button
      mat-stroked-button
      [matMenuTriggerFor]="account_filter_menu"
      class="ml-3 pr-2 bg-white"
      color="primary"
      matBadgePosition="after"
      matBadge=" {{
        showInactiveUsers && !showARsOnly
          ? '1'
          : showARsOnly && !showInactiveUsers
          ? '1'
          : showARsOnly && showInactiveUsers
          ? '2'
          : ''
      }}"
      matBadgeColor="warn"
    >
      Filters
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #account_filter_menu="matMenu" xPosition="before">
      <button *ngIf="selectedUserType !== 3" mat-menu-item (click)="filterARsOnly()">
        <mat-icon class="{{ showARsOnly ? 'green' : 'gray' }}">{{ showARsOnly ? 'toggle_on' : 'toggle_off' }}</mat-icon>
        <span>AR's Only</span>
      </button>
      <button *ngIf="authService.isStaffOnAnyModule" mat-menu-item (click)="filterInactiveUsersOnly()">
        <mat-icon class="{{ showInactiveUsers ? 'green' : 'gray' }}">{{
          showInactiveUsers ? 'toggle_on' : 'toggle_off'
        }}</mat-icon>
        <span>Show Inactive Accounts</span>
      </button>
    </mat-menu>

    <mat-form-field appearance="outline" class="m-0 ml-4">
      <mat-icon class="dkblue" matPrefix>search</mat-icon>
      <input matInput [(ngModel)]="searchString" placeholder="Search Directory" (ngModelChange)="searchChanged()" />
      <mat-icon *ngIf="searchString" (click)="setSearchString()" class="ltgray pointer" matSuffix>cancel </mat-icon>
    </mat-form-field>
    <button mat-flat-button (click)="addAccount()" class="ml-3" *ngIf="authService.isStaffOnAnyModule" color="success">
      + Account
    </button>
  </div>
</div>

<main>
  <div class="m-portlet mb-0">
    <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
      <div class="row align-items-center">
        <div
          class="col-2 p-1 pl-3 pr-3 d-flex header-item align-items-center"
          [ngClass]="{ sortedBy: fieldToSortBy === 'first_name' }"
        >
          <h6 (click)="updateSortByField('first_name')" class="m-0 text-truncate header-title mr-auto">Person</h6>
          <i *ngIf="fieldToSortBy === 'first_name'" matRipple class="material-icons">
            {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
          </i>
        </div>
        <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
          <h6 class="m-0 text-truncate header-title mr-auto">Account Type</h6>
        </div>
        <div
          class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
          [ngClass]="{ sortedBy: fieldToSortBy === 'company_name' }"
          *ngIf="selectedUserType === 3"
        >
          <h6 (click)="updateSortByField('company_name')" class="m-0 text-truncate header-title mr-auto">Company</h6>
          <i *ngIf="fieldToSortBy === 'company_name'" matRipple class="material-icons">
            {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
          </i>
        </div>
        <div
          class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
          [ngClass]="{ sortedBy: fieldToSortBy === 'department_name' }"
          *ngIf="selectedUserType !== 3 && selectedUserType !== 0"
        >
          <h6 (click)="updateSortByField('department_name')" class="m-0 text-truncate header-title mr-auto">
            Department
          </h6>
          <i *ngIf="fieldToSortBy === 'department_name'" matRipple class="material-icons">
            {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
          </i>
        </div>
        <div
          class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
          [ngClass]="{ sortedBy: fieldToSortBy === 'department_name' }"
          *ngIf="selectedUserType === 0"
        >
          <h6 (click)="updateSortByField('department_company')" class="m-0 text-truncate header-title mr-auto">
            Department/Company
          </h6>
          <i *ngIf="fieldToSortBy === 'department_company'" matRipple class="material-icons">
            {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
          </i>
        </div>
        <div
          class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
          [ngClass]="{ sortedBy: fieldToSortBy === 'building_name' }"
        >
          <h6 (click)="updateSortByField('building_name')" class="m-0 text-truncate header-title mr-auto">Location</h6>
          <i *ngIf="fieldToSortBy === 'building_name'" matRipple class="material-icons">
            {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
          </i>
        </div>
        <div *ngIf="selectedUserType !== 4" class="col p-1 pl-3 pr-3 d-flex align-items-center header-item">
          <h6 class="m-0 text-truncate header-title mr-auto">Phone</h6>
        </div>
        <div class="col p-1 pl-3 pr-3 d-flex align-items-center header-item">
          <h6 class="m-0 text-truncate header-title mr-auto">Email</h6>
        </div>
        <div class="col p-1 pl-3 pr-3 d-flex align-items-center header-item">
          <h6 class="m-0 text-truncate header-title mr-auto">Status</h6>
        </div>
        <div
          class="col-auto p-1 pl-3 pr-3 d-flex align-items-center header-item"
          style="width: 220px"
          [ngClass]="{ sortedBy: fieldToSortBy === 'status' }"
        >
          <h6 class="m-0 text-truncate header-title mr-auto">Actions</h6>
        </div>
      </div>
    </div>
    <div
      class="m-portlet__body item pointer p-3 pt-4 pb-4"
      data-test-id="work-order-list-item"
      *ngFor="
        let user of users
          | isEnabled: showInactiveUsers
          | directoryUsersFilter: selectedUserType:searchString:showARsOnly:filteredCount
          | orderByField: fieldToSortBy:sortDirection
          | slice: startIndex:endIndex
      "
    >
      <div class="row pr-0 align-items-center">
        <div class="col-2 pl-3 pr-3 d-flex align-items-center" [ngClass]="{ opacity5: !user.is_enabled }">
          <app-profile-info
            [user]="user"
            [additionalUserFields]="['title']"
            [avatarSize]="40"
            [avatarOverlayIcon]="{
              opacity: 1,
              icon: (user.is_ar && 'verified_user') || '',
              iconColor: 'dkgray',
              iconBackground: 'white',
              iconTooltip: 'Authorized Requester'
            }"
            [disableApiCall]="true"
          ></app-profile-info>
        </div>
        <div class="col pl-3 pr-3 d-flex data-item" [ngClass]="{ opacity5: !user.is_enabled }">
          <p class="m-0 dkblue font-weight-normal">
            {{ user.user_type_id === 1 ? 'UHAT/1CALL' : user.user_type_name }}
          </p>
        </div>
        <div
          class="col pl-3 pr-3 d-flex flex-column data-item"
          *ngIf="selectedUserType === 3"
          [ngClass]="{ opacity5: !user.is_enabled }"
        >
          <p class="m-0 dkblue font-weight-normal lh-18">
            {{ user.company_name }}
          </p>
          <p class="lh-18 m-0 dkblue" *ngIf="user.suite_name">
            {{ user.suite_name }}
          </p>
        </div>
        <div
          class="col pl-3 pr-3 d-flex flex-column data-item"
          *ngIf="selectedUserType !== 3 && selectedUserType !== 0"
          [ngClass]="{ opacity5: !user.is_enabled }"
        >
          <p class="m-0 dkblue font-weight-normal d-block lh-18">
            {{ user.department_name }}
          </p>

          <p class="m-0 lh-18 dkblue" *ngIf="user.suite_name">
            {{ user.suite_name }}
          </p>
        </div>
        <div
          class="col pl-3 pr-3 d-flex flex-column justify-content-center data-item"
          *ngIf="selectedUserType === 0"
          [ngClass]="{ opacity5: !user.is_enabled }"
        >
          <p class="m-0 dkblue font-weight-normal d-block">
            {{
              user.user_type_id !== 1
                ? user.department_name || user.company_name || ''
                : user.company_name || user.department_name || ''
            }}
          </p>
          <p *ngIf="user.suite_name">
            <small>#{{ user.suite_name }}</small>
          </p>
        </div>
        <div class="col pl-3 pr-3 d-flex flex-column data-item" [ngClass]="{ opacity5: !user.is_enabled }">
          <p class="m-0 dkblue font-weight-normal d-block">
            {{ user.building_name }}
          </p>
          <p class="m-0 dkgray font-weight-normal d-block">Floor {{ user.floor_name }}</p>
        </div>
        <div
          *ngIf="selectedUserType !== 4"
          class="col pl-3 pr-3 d-flex data-item"
          [ngClass]="{ opacity5: !user.is_enabled }"
        >
          <p class="m-0 dkblue font-weight-normal d-block">
            {{ user.cell_phone ? 'C:' : '' }} {{ user?.cell_phone | phoneNumber }} <br />
            {{ user.office_phone ? 'O:' : '' }} {{ user?.office_phone | phoneNumber }}
          </p>
        </div>
        <div class="col pl-3 pr-3 d-flex data-item" [ngClass]="{ opacity5: !user.is_enabled }">
          <p class="m-0 dkblue font-weight-normal d-block text-break">
            {{ user.email }}
          </p>
        </div>
        <div
          *ngIf="selectedUserType === 4"
          class="col pl-3 pr-3 d-flex data-item"
          [ngClass]="{ opacity5: !user.is_enabled }"
        >
          <p class="m-0 dkblue font-weight-normal d-block">
            {{ user.user_type_name }}
          </p>
        </div>
        <div class="col pl-3 pr-3 d-flex data-item">
          <p class="m-0 lh-20">
            <span class="dkgreen bg-shade-green px-2 py-1 lh-20 br-10" *ngIf="user.is_enabled && user.is_login_enabled">
              Active
            </span>
            <span
              class="dkorange bg-shade-orange px-2 py-1 lh-20 br-10"
              *ngIf="user.is_enabled && !user.is_login_enabled"
            >
              Pending
            </span>
            <span class="gray bg-shade-gray px-2 py-1 lh-20 br-10" *ngIf="!user.is_enabled"> Disabled </span>
          </p>
        </div>
        <div class="col-auto pl-3 pr-3 d-flex data-item" style="width: 220px">
          <div class="ml-auto">
            <button
              mat-flat-button
              (click)="inviteUser(user)"
              *ngIf="authService.canInviteUser && !user.is_login_enabled && user.is_enabled"
              color="success"
              class="lh-28 px-2"
            >
              Invite
            </button>
            <button
              mat-icon-button
              class="accent ml-2"
              (click)="impersonateUser(user)"
              matTooltip="Impersonate {{ user.first_name }}"
              matTooltipPosition="above"
              *ngIf="authService.isAppAdmin && (!currentUser || currentUser.id !== user.id)"
            >
              <mat-icon>bug_report</mat-icon>
            </button>
            <button mat-button class="ml-2 lh-28 px-3" color="accent" [routerLink]="['/profile/' + user.id]">
              Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-paginator
    class="bg-transparent dkblue"
    color="primary"
    #paginator
    showFirstLastButtons
    [length]="filteredCount.count"
    [pageSize]="pageSize"
    (page)="pageChange($event)"
  >
  </mat-paginator>
</main>

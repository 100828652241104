import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { of, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-update-available',
  templateUrl: './update-available.component.html',
  styleUrls: ['./update-available.component.scss'],
})
export class UpdateAvailableComponent implements OnInit {
  buildNumberAtStartup;
  updateFound = false;
  updateDelay = false;
  delayTime = 15 * 60 * 1000;
  updateCheckFrequency = 60 * 1000;
  constructor(private httpClient: HttpClient) {}

  async ngOnInit(): Promise<void> {
    this.buildNumberAtStartup = await this.getBuildVersion().toPromise();
    timer(this.updateCheckFrequency, this.updateCheckFrequency).subscribe(() => {
      if (this.updateDelay || this.updateFound) return;
      this.getBuildVersion()
        .toPromise()
        .then((response) => {
          if (response && this.buildNumberAtStartup !== response) {
            this.updateFound = true;
          }
        });
    });
  }

  getBuildVersion() {
    return this.httpClient
      .get('assets/build-version', {
        responseType: 'text',
      })
      .pipe(
        catchError((error) => {
          return of(null);
        })
      );
  }

  reload() {
    window.location.reload();
  }

  cancel() {
    this.updateFound = false;
    this.updateDelay = true;
    setTimeout(() => {
      this.updateDelay = false;
    }, this.delayTime);
  }
}

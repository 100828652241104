import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { isEmpty, xor } from 'lodash';
import * as moment from 'moment';
import { merge } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  AssignUserDialogComponent,
  ConfirmationDialogComponent,
  EditorComponent,
  FileAttachmentDialogComponent,
  FileRenameDialogComponent,
  TimeLogDialogComponent,
  WorkOrderChecklistComponent,
  WorkOrderDialogComponent,
  WorkOrderUpdateDialogComponent,
} from 'src/app/components';
import { ResourceType, TAG_IDS, WorkOrderStatus } from 'src/app/enums';
import {
  AppRoutingService,
  AuthService,
  FileService,
  FormService,
  HandleErrorService,
  ModalService,
  ProgressIndicatorService,
  SidenavLinksService,
  TimeLogService,
  WorkOrderService,
} from 'src/app/services';
import { ActivityService } from 'src/app/services/activity.service';
import {
  APIFilter,
  RequestMethod,
  Tag,
  TimeLog,
  Topic,
  TopicCategory,
  TopicGroup,
  UhatFileReference,
  User,
  UserAction,
  WorkOrder,
  WorkOrderUpdate,
} from 'src/app/types';

@Component({
  selector: 'app-work-order',
  templateUrl: './work-order.component.html',
  styleUrls: ['./work-order.component.scss'],
})
export class WorkOrderComponent implements OnInit {
  @ViewChild('summaryEditor', { static: true })
  private _summary_editor_component: EditorComponent;
  @ViewChild('workorderChecklist') private workorderChecklist: WorkOrderChecklistComponent;
  private _downloading = false;
  private _workOrderUpdateFields: string[] = [
    'access',
    'code',
    'created_by{id,first_name,last_name,user_type_id}',
    'created_by_id',
    'created_datetime',
    'id',
    'message',
    'notify_followers',
    'work_order_health_type{id,name}',
    'work_order_id',
    'files',
  ];
  private _workOrderFields = [
    'id',
    'code',
    'module_id',
    'module{id,name,workspace_type}',
    'title',
    'status{id,name}',
    'building{id,name,code}',
    'closed_datetime',
    'floor{id,name,code}',
    'suite',
    'department',
    'landmark',
    'due_date',
    'priority{id,name}',
    'summary',
    'topic_group{id,name,building_ids,topic_categories{id,name,topic_group_id,topic_group{id,name},topics{id,name,topic_group_id,topic_type_id,workspace_id}}}',
    'topic_category{id,name,topic_group_id,topic_group{id,name},topics{id,topic_category_id,name,topic_type_id,workspace_id}}',
    'topic{id,name,topic_category_id,topic_category{id,name,topic_group_id,topic_group{id,name,building_ids}},topic_type_id,topic_type{id,name},workspace_id,workspace{id,name}}',
    'followers',
    'assigned_user{id,first_name,last_name,title,department_name}',
    'converted_to_request{id,code}',
    'created_by',
    'created_datetime',
    'requester_id',
    'requester{id,first_name,last_name,title,department_name}',
    'files{id,name,created_datetime,tags,file_id}',
    'landmark',
    'form_submission',
    'request_method_id',
    'request_method{id,name,is_enabled,is_default,icon}',
    'tag_ids',
    'tags{is_enabled,resource_type_ids}',
    'cms_work_order_id',
  ];
  public canEditKeyControls = false;
  public currentUser: User;
  public isAssignedToMe = false;
  public isDownloadingAllFiles = false;
  public isEditingSummary = false;
  public isEditingTitle = false;
  public linkedFiles: UhatFileReference[] = [];
  public openDialog = false;
  public showAssignedToView = true;
  public showDetailsView = true;
  public collapseDetailView = true;
  public showCollapseDetailViewButton = false;
  public showFileView = true;
  public showTimeLogView = true;
  public showUpdatesView = true;
  public showActivityView = false;
  public timeLogEntries: TimeLog[] = [];
  public activityEntries: any = [];
  public activityPagination = { pageIndex: 0, pageSize: 10, maxPage: 0, currentPages: '' };
  public filePagination = { pageIndex: 0, pageSize: 5, maxPage: 0, currentPages: '' };
  public title = new FormControl('');
  public workOrder: WorkOrder;
  public workOrderId: number;
  public workOrderUpdates: WorkOrderUpdate[];

  // auth
  public isWorkspaceStaff: boolean;
  public isWorkOrderAdmin: boolean;
  public timeLogTotal: { hoursWorked: number; minutesWorked: number } = { hoursWorked: 0, minutesWorked: 0 };

  constructor(
    public authService: AuthService,
    private _dialog: MatDialog,
    private _fileService: FileService,
    private _handleErrorService: HandleErrorService,
    private _modalService: ModalService,
    private _progressIndicatorService: ProgressIndicatorService,
    private _route: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private _workOrderService: WorkOrderService,
    private _routerService: AppRoutingService,
    private progressIndicatorService: ProgressIndicatorService,
    private sidenavLinksService: SidenavLinksService,
    private timeLogService: TimeLogService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private formService: FormService,
    private activityService: ActivityService
  ) {}
  ngOnInit(): void {
    setTimeout(async () => {
      this.sidenavLinksService.selectLink(this.sidenavLinksService.workOrders);
    });

    merge(this._workOrderService.refreshNeeded$, this._route.params).subscribe(async (pair: any) => {
      if (pair?.id) {
        this.workOrderId = pair.id;
      }
      await this._refresh();
    });
  }

  get assignedToUser(): User {
    return this.workOrder?.assigned_user;
  }

  get buildingCode(): string {
    return this.workOrder?.building?.code || '';
  }

  get canReactivate() {
    return (
      this.workOrder?.status_id === 3 &&
      !this.workOrder?.converted_to_request?.id &&
      (this.workspaceAdmin || (this.closedLessThan24Hours && (this.isStaff || this.isRequester)))
    );
  }

  get files(): UhatFileReference[] {
    return this.workOrder?.files || [];
  }

  get floorCode(): string {
    return this.workOrder?.floor?.code || '';
  }

  get departmentName(): string {
    return this.workOrder?.department?.name || '-';
  }

  get landmark(): string {
    return this.workOrder?.landmark || '-';
  }

  get formattedOpenDate(): string {
    return this.workOrderOpenDate ? moment(this.workOrderOpenDate).format('dddd, MMMM Do YYYY') : '';
  }

  get formattedOpenTime(): string {
    return this.workOrderOpenDate ? moment(this.workOrderOpenDate).format('LT') : '';
  }

  get isStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.workOrder?.module_id);
  }

  get isRequester() {
    return this.workOrder?.requester.id === this.authService?.currentUser?.id;
  }

  get closedLessThan24Hours() {
    return moment().subtract(24, 'hours').isBefore(this.workOrder?.closed_datetime);
  }

  get created_by(): User {
    return this.workOrder?.created_by;
  }

  get createdByName(): string {
    return `${this.created_by?.first_name || ''} ${this.created_by?.last_name || ''}`.trim();
  }

  get requester(): User {
    return this.workOrder?.requester;
  }

  get requestMethod(): RequestMethod {
    return this.workOrder?.request_method;
  }

  get suiteName(): string {
    return this.workOrder?.suite?.name || '-';
  }

  get topicGroup(): TopicGroup {
    return this.workOrder?.topic_group;
  }

  get topicCategory(): TopicCategory {
    return this.workOrder?.topic_category;
  }

  get topic(): Topic {
    return this.workOrder?.topic;
  }

  get workOrderOpenDate(): Date {
    return this.workOrder?.created_datetime;
  }

  get workOrderTitle(): string {
    return this.workOrder?.title || '';
  }

  get workOrderSummary(): string {
    return this.workOrder?.summary || '';
  }

  get formTemplate(): string {
    return this.workOrder?.form_template || null;
  }

  get workOrderUpdateFileIds(): number[] {
    let ids = [];
    this.workOrderUpdates?.forEach((update) => {
      if (update?.files) {
        ids = [...ids, ...update.files.map((file) => Number(file.id))];
      }
    });
    return ids;
  }

  get formSubmission(): string {
    return this.workOrder?.form_submission || null;
  }

  get workspaceAdmin(): boolean {
    return this.authService.isUserWorkspaceAdmin(this.workOrder?.module_id);
  }

  private async _changeAssignment(assigned_user_id: number) {
    this._openProgress('Changing work order assignment...');
    const updatedWorkOrder = await this._workOrderService
      .updateWorkOrder(this.workOrderId, { assigned_user_id }, this._workOrderFields)
      .toPromise();

    // replace some fields with new data
    this.workOrder = { ...this.workOrder, ...updatedWorkOrder };
    this.getWorkOrderActivity();
    this._closeProgress();
    this._snackbar.open('Work Order assigned!');
  }

  private _checkAssignment() {
    this.isAssignedToMe = this.currentUser?.id && this.currentUser?.id === this.workOrder?.assigned_user_id;
  }

  private _closeProgress() {
    this._progressIndicatorService.close();
  }

  private _openProgress(action: string) {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus(action);
  }

  private async _removeAssignment() {
    this._openProgress('Removing work order assignment...');
    await this._workOrderService
      .updateWorkOrder(this.workOrderId, { assigned_user_id: null }, this._workOrderFields)
      .toPromise();

    this.workOrder.assigned_user_id = null;
    this.workOrder.assigned_user = null;
    this.getWorkOrderActivity();
    this._closeProgress();
    this._snackbar.open('Assignment changed!');
  }

  private async _refresh() {
    this._openProgress('Loading work order..');

    this.currentUser = this.authService.getLoggedInUser();
    this._setEditControls();
    this.workOrder = await this._workOrderService.getWorkOrderById(this.workOrderId, this._workOrderFields).toPromise();

    this.workOrder.form_submission = this.workOrder?.form_submission
      ? JSON.parse(this.workOrder.form_submission)
      : null;
    if (this.workOrder.form_submission) {
      const formTemplate = await this.formService
        .getFormTemplateById(this.workOrder.form_submission.form_template_id)
        .toPromise();
      this.workOrder.form_template = formTemplate?.content ? JSON.parse(formTemplate.content) : null;
      if (this.workOrder?.form_template?.sections && this.workOrder?.form_submission?.elements) {
        for (const s of this.workOrder.form_template.sections) {
          for (const e of s.elements) {
            const foundElement = this.workOrder?.form_submission?.elements.find((se) => se.id === e.id);
            e.value = foundElement?.value;
          }
        }
      }
    }

    // update the file paginator
    this.paginateFile(0);

    this._checkAssignment();
    // get activity
    this.getWorkOrderActivity();

    // gets all updates and assigns them to this.workOrderUpdates
    await this.getAllUpdates();

    const userFilter: APIFilter[] = [
      { type: 'field', field: 'parent_id', value: this.workOrderId.toString() },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'parent_type_id', value: ResourceType.WorkOrder.toString() },
    ];
    this.timeLogEntries = await this.timeLogService.getTimeLogs(userFilter).toPromise();
    this.timeLogTotal = this.calculateTimeLogTotal(this.timeLogEntries);
    this.isWorkOrderAdmin = this.isAssignedToMe || this.authService.isUserWorkspaceAdmin(this.workOrder.module_id);
    this.isWorkspaceStaff = this.authService.isUserWorkspaceStaff(this.workOrder.module_id);

    this.workorderChecklist?.refresh();
    this._closeProgress();
  }

  public async getAllUpdates() {
    this.workOrderUpdates = await this._workOrderService
      .getWorkOrderUpdates(
        this._workOrderUpdateFields,
        [{ type: 'field', field: 'work_order_id', value: `${this.workOrderId}` }],
        undefined,
        'created_datetime',
        'desc'
      )
      .toPromise()
      .catch((e) => {
        this._closeProgress();
        return e;
      });

    // set the show message to false
    this.workOrderUpdates.forEach((workOrderUpdate: WorkOrderUpdate) => {
      workOrderUpdate.collapseMessageView = true;
      workOrderUpdate.showCollapseMessageViewButton = false;
    });
  }

  private _setEditControls() {
    this.canEditKeyControls =
      this.authService.isAppAdmin || this.authService.isUserWorkspaceAdmin(this.workOrder?.module_id);
  }

  private async _unlinkFile(file: UhatFileReference) {
    await this._fileService.unlinkFile(file?.id).toPromise();
    await this._fileService.removeTags(file.file_id || file.id, [TAG_IDS.Update]).toPromise();
  }

  public addWorkOrderFile() {
    this.openDialog = true;
    this._dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.WorkOrder,
          parentResourceId: this.workOrderId,
          allowComment: false,
          workOrderFile: true,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        this.openDialog = false;
        if (Array.isArray(result) && result[0].id) {
          this.workOrder.files = [...result, ...(this.workOrder.files || [])];
          // update the pages count nad go to the first page
          this.paginateFile(0);
          this._snackbar.open('Work Order file has been attached!');
        }
      });
  }

  public addWorkOrderUpdate() {
    this.openDialog = true;
    this._dialog
      .open(WorkOrderUpdateDialogComponent, {
        width: '500px',
        autoFocus: false,
        disableClose: true,
        data: {
          work_order_id: this.workOrderId,
        },
      })
      .afterClosed()
      .subscribe(async (workOrderUpdate) => {
        this.openDialog = false;
        if (workOrderUpdate?.id) {
          this._refresh();
        }
      });
  }

  public async assignWorkOrderToMe() {
    this._openProgress('Assigning work order to you...');
    if (this.currentUser?.id) {
      await this._workOrderService
        .updateWorkOrder(this.workOrderId, { assigned_user_id: this.currentUser?.id }, ['assigned_user_id'])
        .toPromise();
      this.workOrder.assigned_user_id = this.currentUser.id;
      this._snackbar.open('Work Order ' + this.workOrder?.code + ' has been assigned to you');
    }
  }

  public cancelEditingTitle() {
    this.isEditingTitle = false;
    this.title.setValue(this.workOrderTitle);
  }

  public cancelEditingSummary() {
    this.isEditingSummary = false;
    this._summary_editor_component?.content?.setValue(this.workOrderSummary);
  }

  public changeWorkOrderAssignment() {
    this._openProgress('Changing work order assignement...');
    this._dialog
      .open(AssignUserDialogComponent, {
        autoFocus: false,
        width: '560px',
        disableClose: true,
        data: {
          displayLabel: 'User Assignment',
          displayTitle: 'Assign Work Order',
          initialValueId: this.workOrder.assigned_user_id,
        },
      })
      .afterClosed()
      .subscribe(async (selectedUser) => {
        if (selectedUser?.id) {
          this._changeAssignment(selectedUser?.id);
        } else if (selectedUser === null) {
          // we removing assignment
          this._removeAssignment();
        }
      });
    this._closeProgress();
  }

  public changeWorkOrderRequester() {
    this._openProgress('Changing work order requester...');
    this._dialog
      .open(AssignUserDialogComponent, {
        autoFocus: false,
        width: '560px',
        disableClose: true,
        data: {
          displayLabel: 'User Requestor',
          displayTitle: 'Work Order Requestor',
          displayPlaceHolder: 'Requestor',
          isARequestorUser: true,
        },
      })
      .afterClosed()
      .subscribe(async (selectedUser) => {
        // if undefined do nothing, canceled
        if (selectedUser?.id && +this.requester?.id !== +selectedUser.id) {
          // this._changeAssignment(selectedUser?.id);
          this._openProgress('Changing work order requester...');
          const updatedWorkOrder = await this._workOrderService
            .updateWorkOrder(this.workOrderId, { requester_id: selectedUser.id }, this._workOrderFields)
            .toPromise();

          // replace some fields with new data
          this.workOrder = { ...this.workOrder, ...updatedWorkOrder };
          this.getWorkOrderActivity();
          this._closeProgress();
          this._snackbar.open('Work Order requester changed!');
        } else if (selectedUser === null) {
          // do we want to removing requester ??
        }
      });
    this._closeProgress();
  }

  public async closeWorkOrder() {
    this._openProgress('Closing work order...');
    const updatedWorkOrder = await this._workOrderService
      .updateWorkOrder(this.workOrderId, { status_id: WorkOrderStatus.CLOSED }, ['status_id', 'status'])
      .toPromise();
    this.workOrder.closed_datetime = updatedWorkOrder?.closed_datetime;
    this.workOrder.status_id = updatedWorkOrder.status_id;
    this.workOrder.status = updatedWorkOrder.status;
    this.getWorkOrderActivity();
    this._closeProgress();
    this._snackbar.open('Work Order ' + this.workOrder?.code + ' has been closed!');
  }

  public createTimeLogEntry() {
    this.openDialog = true;
    this.dialog
      .open(TimeLogDialogComponent, {
        width: '600px',
        data: {
          initialParent: {
            id: this.workOrderId,
            type_id: 34,
            code: this.workOrder?.code,
            title: this.workOrder?.title,
            module_id: this.workOrder?.module_id,
          },
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        this.openDialog = false;
        if (result) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Creating entry..');
          // modify the data to match the api and update the db value
          result.activity_id = result.activity.id;
          delete result.activity;
          result.worker_id = result.worker.id;
          delete result.worker;
          result.company_id = result.company.id;
          delete result.company;
          delete result.parent_code;
          delete result.parent_title;
          delete result.module_id;
          this.timeLogEntries.push(await this.timeLogService.createTimeLogEntry(result).toPromise());
          this.timeLogTotal = this.calculateTimeLogTotal(this.timeLogEntries);
          this.progressIndicatorService.close();
          this.snackBar.open('Time log entry created!');
        }
      });
  }

  public async deleteFile(file: UhatFileReference): Promise<void> {
    const fileDeleted = await this._fileService.deleteFile(file);
    if (fileDeleted) {
      this.workOrder.files = this.workOrder.files.filter((f) => (f.file_id || f.id) !== (file.file_id || file.id));
    }
  }

  public deleteTimeLogEntry(entryId: number) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          titleBarText: 'Time Log',
          headerText: `Delete Time Log Entry`,
          descriptionText:
            'Warning: You will not be able to recover time log entry. Are you sure you want to permanently delete this entry?',
        },
      })
      .afterClosed()
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.timeLogService.deactivateTimeLogEntry(entryId).subscribe();
          this.timeLogEntries.splice(
            this.timeLogEntries.findIndex((entry) => +entry.id === +entryId),
            1
          );
          this.timeLogTotal = this.calculateTimeLogTotal(this.timeLogEntries);
          this.snackBar.open('Time log entry deleted!');
        }
      });
  }

  public deleteWorkOrderUpdate(workOrderUpdateId: number) {
    this._modalService
      .openConfirmationDialog({
        titleBarText: 'Delete update',
        descriptionText: 'Are you sure you want to delete the update? ',
      })
      .subscribe(async (confirmed) => {
        if (confirmed) {
          this._openProgress('Deleting update...');

          // get all the update files
          const foundFiles = await this._fileService
            .getFilesByParentId(ResourceType.WorkOrderUpdate, workOrderUpdateId)
            .toPromise();

          // unlink all the found files
          foundFiles?.forEach((file: UhatFileReference) => {
            this._fileService.deleteFileWithoutConfirmation(file).toPromise();
            this.workOrder.files = this.workOrder?.files.filter((f) => f.file_id !== file?.file_id);
          });

          this._workOrderService
            .deleteWorkOrderUpdate(workOrderUpdateId)
            .pipe(
              map((result) => result),
              catchError((e) => {
                this._closeProgress();
                return e;
              })
            )
            .subscribe(() => {
              this.workOrderUpdates = this.workOrderUpdates.filter(
                (workOrderUpdate) => +workOrderUpdate.id !== +workOrderUpdateId
              );
              this._closeProgress();
            });
        }
      });
  }

  public async downloadFile(file: UhatFileReference) {
    /*
    this._downloading = true;
    this._fileService
      .downloadFile(file)
      .pipe(
        map((result) => result),
        catchError((e) => {
          this._downloading = false;
          this._handleErrorService.handleError(e);
          return e;
        })
      )
      .subscribe((result) => {
        saveAs(new Blob([new Uint8Array(result.file.data)]), result.name);
        this._snackbar.open(`${result.name} has been downloaded`);
        this._downloading = false;
      });
      */
    this._downloading = true;
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Downloading file....');
    await this._fileService.download(file);
    this._downloading = false;
    this._progressIndicatorService.close();
  }

  public async editFile(file: UhatFileReference) {
    const allTags = await this._fileService.getTags([this.workOrder.module_id]).toPromise();
    this._dialog
      .open(FileRenameDialogComponent, {
        width: '480px',
        data: {
          file,
          allTags,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }
        if (
          file?.name !== result?.name ||
          !isEmpty(
            xor(
              file?.tags?.map((t) => +t?.id),
              result?.tags?.map((t) => +t?.id)
            )
          )
        ) {
          const tag_ids = `[${result?.tags.map((t) => +t.id).join(',')}]`;
          const updateFile = {
            name: result?.name,
            tag_ids,
          };
          this._fileService.updateFileFields(file?.id, updateFile).subscribe((updatedFile) => {
            this.workOrder.files = this.workOrder.files.map((WOFile) => {
              if (+WOFile.id === +updatedFile.id) {
                const updatedTags = JSON.parse(updatedFile.tag_ids)
                  ?.map((tag_id: number) => allTags.find((aTag: Tag) => +aTag.id === +tag_id))
                  .filter((unfilteredTag: Tag) => unfilteredTag);
                return { ...WOFile, ...updatedFile, tags: updatedTags };
              }
              return { ...WOFile };
            });
            this._snackbar.open(`${updatedFile?.name || 'File'} has been updated`);
          });
        }
      });
  }

  public editSummary() {
    if (this.isStaff) {
      this.isEditingSummary = true;
      this._summary_editor_component?.content?.setValue(this.workOrderSummary);
    }
  }

  public editTitle() {
    if (this.isStaff) {
      this.isEditingTitle = true;
      this.title.setValue(this.workOrderTitle);
    }
  }

  public editTimeLogEntry(entry: TimeLog) {
    this.openDialog = true;
    this.dialog
      .open(TimeLogDialogComponent, {
        width: '600px',
        data: {
          timeLog: entry,
          initialParent: {
            id: this.workOrderId,
            type_id: 34,
            code: this.workOrder?.code,
            title: this.workOrder?.title,
            module_id: this.workOrder?.module_id,
          },
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        this.openDialog = false;
        if (result) {
          // update the local entry to the new values
          this.updateLocalEntry(entry.id, result);

          // modify the data to match the api and update the db value
          result.id = entry.id;
          result.activity_id = result.activity.id;
          delete result.activity;
          result.worker_id = result.worker.id;
          delete result.worker;
          result.company_id = result.company.id;
          delete result.company;
          delete result.parent_id;
          delete result.parent_type_id;
          delete result.parent_code;
          delete result.parent_title;
          delete result.module_id;
          this.timeLogService.updateTimeLogEntry(result).subscribe();
          this.timeLogTotal = this.calculateTimeLogTotal(this.timeLogEntries);
          this.snackBar.open('Time log entry updated!');
        }
      });
  }

  public editWorkOrder() {
    this.openDialog = true;
    this._dialog
      .open(WorkOrderDialogComponent, {
        width: '780px',
        disableClose: true,
        data: {
          ...this.workOrder,
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((updatedWorkOrder) => {
        if (updatedWorkOrder) {
          this._refresh();
          this._snackbar.open('Work Order Updated!');
        }
        this.openDialog = false;
      });
  }

  public editWorkOrderUpdate(workOrderUpdate: WorkOrderUpdate) {
    this.openDialog = true;
    this._dialog
      .open(WorkOrderUpdateDialogComponent, {
        width: '500px',
        disableClose: true,
        data: {
          ...workOrderUpdate,
          update: true,
        },
      })
      .afterClosed()
      .subscribe((newWorkOrderUpdate) => {
        this.openDialog = false;
        if (newWorkOrderUpdate?.id) {
          this._refresh();
        }
      });
  }

  public async followWorkOrder() {
    this._openProgress('Following work order...');
    await this._workOrderService
      .updateWorkOrderFollower(this.workOrderId, {
        follower_id: this.currentUser?.id,
        action: 'add',
      })
      .toPromise();
    this._refresh();
    this._closeProgress();
    this._snackbar.open('You are now following Work Order ' + this.workOrder?.code);
  }

  public async unfollowWorkOrder() {
    this._openProgress('Unfollowing work order...');
    await this._workOrderService
      .updateWorkOrderFollower(this.workOrderId, {
        follower_id: this.currentUser?.id,
        action: 'remove',
      })
      .toPromise();
    if (!this.isStaff && !this.isRequester) {
      await this._routerService.gotoWorkOrdersListPage();
    } else {
      this._refresh();
    }
    this._closeProgress();
    this._snackbar.open('You have unfollowed Work Order ' + this.workOrder?.code);
  }

  public getUserTitle(user_type_id: number) {
    if (user_type_id === 1) {
      return 'Staff';
    } else if (user_type_id === 2) {
      return 'Tenant';
    } else if (user_type_id === 3) {
      return 'Vendor';
    }
    return '';
  }

  public headerRefresh() {
    // header refresh
    this._refresh();
  }

  public async openWorkOrder() {
    this._openProgress('Opening work order...');
    const updatedWorkOrder = await this._workOrderService
      .updateWorkOrder(this.workOrderId, { status_id: WorkOrderStatus.ACTIVE }, ['status_id', 'status'])
      .toPromise();
    this.workOrder.status_id = updatedWorkOrder.status_id;
    this.workOrder.status = updatedWorkOrder.status;
    this.getWorkOrderActivity();
    this._closeProgress();
    this._snackbar.open('Work Order' + this.workOrder?.code + ' has been opened!');
  }

  public async reactivateWorkOrder() {
    this._openProgress('Reactivating work order...');
    const updatedWorkOrder = await this._workOrderService.reactivateWorkOrder(this.workOrderId).toPromise();
    this._refresh();
    this.getWorkOrderActivity();
    this._closeProgress();
    this._snackbar.open('Work Order' + this.workOrder?.code + ' has been reactivated!');
  }

  public async previewFile(file: UhatFileReference) {
    await this._fileService.previewFile(file);
  }

  public async putOnHoldWorkOrder() {
    this._openProgress('Putting work order on hold...');
    const updatedWorkOrder = await this._workOrderService
      .updateWorkOrder(this.workOrderId, { status_id: WorkOrderStatus.ON_HOLD }, ['status_id', 'status'])
      .toPromise();
    this.workOrder.status_id = updatedWorkOrder.status_id;
    this.workOrder.status = updatedWorkOrder.status;
    this.getWorkOrderActivity();
    this._closeProgress();
    this._snackbar.open('Work Order' + this.workOrder?.code + '  has been put on hold!');
  }

  public async readyForPickupWorkOrder() {
    this._openProgress('Marking work order as ready for pickup...');
    const updatedWorkOrder = await this._workOrderService
      .updateWorkOrder(this.workOrderId, { status_id: WorkOrderStatus.READY_FOR_PICKUP }, ['status_id', 'status'])
      .toPromise();
    this.workOrder.status_id = updatedWorkOrder.status_id;
    this.workOrder.status = updatedWorkOrder.status;
    this.getWorkOrderActivity();
    this._closeProgress();
    this._snackbar.open('Work Order' + this.workOrder?.code + '  has been marked as ready for pickup!');
  }

  public async setToPlanned() {
    this._openProgress('Setting work order to planned...');
    const updatedWorkOrder = await this._workOrderService
      .updateWorkOrder(this.workOrderId, { status_id: WorkOrderStatus.PLANNED }, ['status_id', 'status'])
      .toPromise();
    this.workOrder.status_id = updatedWorkOrder.status_id;
    this.workOrder.status = updatedWorkOrder.status;
    this.getWorkOrderActivity();
    this._closeProgress();
    this._snackbar.open('Work Order' + this.workOrder?.code + '  has been set to planned!');
  }

  public toggleAssignedToView() {
    this.showAssignedToView = !this.showAssignedToView;
  }

  public toggleDetailsView() {
    this.showDetailsView = !this.showDetailsView;
  }

  public toggleFilesView() {
    this.showFileView = !this.showFileView;
  }

  public toggleTimeLogView() {
    this.showTimeLogView = !this.showTimeLogView;
  }

  public toggleUpdatesView() {
    this.showUpdatesView = !this.showUpdatesView;
  }

  public toggleActivityView() {
    this.showActivityView = !this.showActivityView;
  }

  private updateLocalEntry(entryId: number, timeLog: TimeLog) {
    for (const entry of this.timeLogEntries) {
      if (+entry.id === +entryId) {
        entry.worker = timeLog.worker;
        entry.worker_id = timeLog.worker.id;
        entry.activity = timeLog.activity;
        entry.activity_id = timeLog.activity.id;
        entry.company = timeLog.company;
        entry.company_id = timeLog.company.id;
        entry.date_worked = timeLog.date_worked;
        entry.hours_worked = timeLog.hours_worked;
        entry.minutes_worked = timeLog.minutes_worked;
        entry.parent_id = timeLog.parent_id;
        entry.parent_type_id = timeLog.parent_type_id;
        entry.parent_code = timeLog.parent_code;
        entry.parent_title = timeLog.parent_title;
        entry.module_id = timeLog.module_id;
        entry.notes = timeLog.notes;
      }
    }
  }

  public async updateSummary() {
    this._openProgress('Editing work order summary...');
    this.isEditingSummary = false;
    const updatedWorkOrder = await this._workOrderService
      .updateWorkOrder(this.workOrderId, { summary: this._summary_editor_component?.content?.value ?? '' }, ['summary'])
      .toPromise();
    this.workOrder.summary = updatedWorkOrder.summary || '';
    this.getWorkOrderActivity();
    this._closeProgress();
    this._snackbar.open('Work Order Summary has been updated!');
  }

  public async updateTitle() {
    if (this.title?.value) {
      this._openProgress('Editing work order title...');
      this.isEditingTitle = false;

      const updatedWorkOrder = await this._workOrderService
        .updateWorkOrder(this.workOrderId, { title: this.title?.value }, ['title'])
        .toPromise();

      this.workOrder.title = updatedWorkOrder.title || '';
      this.getWorkOrderActivity();
      this._closeProgress();
      this._snackbar.open('Work Order Title has been updated!');
    }
  }

  public async updateWorkOrderDueDate(due_date) {
    this._openProgress('Editing Work Order Due Date...');
    this.isEditingTitle = false;
    const updatedWorkOrder = await this._workOrderService
      .updateWorkOrder(this.workOrderId, due_date ?? '', ['due_date'])
      .toPromise();

    this.workOrder.due_date = updatedWorkOrder.due_date;
    this.getWorkOrderActivity();
    this._closeProgress();
    this._snackbar.open('Work Order Due Date has been updated!');
  }

  public freezeScroll(isDialogOpen) {
    this.openDialog = isDialogOpen;
  }

  public async updateWorkOrderfollowers({ selectedUsers, deSelectedUsers }: UserAction) {
    const removalIds = deSelectedUsers?.map((deSelectedUser: User) => deSelectedUser.id) || [];
    const additionIds = selectedUsers?.map((selectedUser: User) => selectedUser.id) || [];

    this._openProgress('Updating Followers...');
    if (removalIds?.length > 0) {
      await this._workOrderService
        .updateWorkOrderFollower(this.workOrderId, { followers: removalIds, action: 'remove' })
        .toPromise();
    }

    if (additionIds?.length > 0) {
      await this._workOrderService
        .updateWorkOrderFollower(this.workOrderId, { followers: additionIds, action: 'add' })
        .toPromise();
    }

    // so many scenario's, straight forward just to get a fresh copy
    this._refresh();
    this._snackbar.open(`You have updated this Work Order's followers!`);
  }

  public async updateWorkOrderPriority({ id }) {
    this._openProgress('Editing Work Order Priority...');
    this.isEditingTitle = false;
    const updatedWorkOrder = await this._workOrderService
      .updateWorkOrder(this.workOrderId, { priority_id: id ?? null }, ['priority'])
      .toPromise();
    this.workOrder.priority_id = updatedWorkOrder.priority_id;
    this.workOrder.priority = updatedWorkOrder.priority;
    this.getWorkOrderActivity();
    this._closeProgress();
    this._snackbar.open('Work Order Priority has been updated!');
  }

  async getWorkOrderActivity() {
    const activityFilter: APIFilter[] = [
      { type: 'field', field: 'item_name', value: 'Work Order' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'item_id', value: this.workOrder.id.toString() },
    ];
    this.activityEntries = await this.activityService.getActivity(activityFilter).toPromise();
    this.paginateActivity(0);
  }

  paginateActivity(inc) {
    const pageSize = this.activityPagination.pageSize;
    const total = this.activityEntries.length;
    const maxPage = (this.activityPagination.maxPage = Math.ceil(total / pageSize));
    let pageIndex = this.activityPagination.pageIndex;
    if (inc === 0) {
      pageIndex = 0;
    }
    if (pageIndex + inc < maxPage && pageIndex + inc >= 0) {
      pageIndex += inc;
    }
    this.activityPagination.pageIndex = pageIndex;
    const currentPagesStart = pageIndex * pageSize + 1;
    const currentPagesEnd = pageIndex + 1 === maxPage ? total : (pageIndex + 1) * pageSize;
    this.activityPagination.currentPages = currentPagesStart + '-' + currentPagesEnd;
  }

  checklistUpdated() {
    this.getWorkOrderActivity();
  }

  public paginateFile(increment: number) {
    const pageSize = this.filePagination.pageSize;
    const total = this.files.length;
    const maxPage = (this.filePagination.maxPage = Math.ceil(total / pageSize));
    let pageIndex = this.filePagination.pageIndex;
    if (increment === 0) {
      pageIndex = 0;
    }
    if (pageIndex + increment < maxPage && pageIndex + increment >= 0) {
      pageIndex += increment;
    }
    this.filePagination.pageIndex = pageIndex;
    const currentPagesStart = pageIndex * pageSize + 1;
    const currentPagesEnd = pageIndex + 1 === maxPage ? total : (pageIndex + 1) * pageSize;
    this.filePagination.currentPages = currentPagesStart + '-' + currentPagesEnd;
  }

  public async downloadAllFiles(): Promise<void> {
    this.isDownloadingAllFiles = true;
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Downloading files....');

    // get all the work order files
    await this._fileService
      .downloadAllFilesByParentId(this.workOrderId, ResourceType.WorkOrder, this.workOrder.code)
      .toPromise();

    this.isDownloadingAllFiles = false;
    this._progressIndicatorService.close();
  }

  private calculateTimeLogTotal(timeLogEntries: TimeLog[]): { hoursWorked: number; minutesWorked: number } {
    const totalMinutes = timeLogEntries.reduce((totalMinutes, timeLog) => {
      let minutes = 0;
      minutes += timeLog.hours_worked * 60;
      minutes += timeLog.minutes_worked;
      return totalMinutes + minutes;
    }, 0);

    return { hoursWorked: Math.floor(totalMinutes / 60), minutesWorked: totalMinutes % 60 };
  }
}

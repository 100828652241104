import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationRole, ResourceType, WorkspaceType } from '../../enums';
import { AuthService, UserService } from '../../services';
import { ArfCustodyChain, User, Workspace } from '../../types';

@Component({
  selector: 'app-edit-staff-dialog',
  templateUrl: './edit-staff-dialog.component.html',
  styleUrls: ['./edit-staff-dialog.component.scss'],
})
export class EditStaffDialogComponent implements OnInit {
  public staffFormGroup: FormGroup = this.fb.group({
    wm: ['', [Validators.required]],
    ac: [''],
    ocd: [''],
    ico: [''],
    cto: [''],
    bsd: ['', [Validators.required]],
    cfmo: ['', [Validators.required]],
    ceo: ['', [Validators.required]],
    dfs_required: [null],
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<EditStaffDialogComponent>,
    private authService: AuthService,
    private fb: FormBuilder,
    private userService: UserService
  ) {}

  workspaceManagers: User[];
  accountCoordinators: User[];
  dfsManagers: User[];
  allCFMOs: User[];
  allICOs: User[];
  allOCDs: User[];
  allBSDs: User[];
  allCEOs: User[];
  custodyChain: ArfCustodyChain;
  workspace: Workspace;
  isLoading = true;
  viewOnly: boolean;

  async ngOnInit(): Promise<void> {
    this.custodyChain = this.data?.custodyChain;
    this.workspace = this.data?.workspace;
    this.viewOnly = this.data?.viewOnly;

    if (this.viewOnly) {
      this.staffFormGroup.disable();
    }

    if (this.workspace?.id) {
      this.accountCoordinators = await this.userService
        .getUsersByRole(ApplicationRole.AccountCoordinator, this.workspace.id, this.userService.userDisplayFields)
        .toPromise();

      if (this.accountCoordinators.length) {
        this.ac.setValidators([Validators.required]);
        this.ac.updateValueAndValidity();
      }

      if (this.custodyChain?.ac_id || this.accountCoordinators.length === 1) {
        const ac =
          (this.custodyChain?.ac_id && this.accountCoordinators.find((u) => u.id === this.custodyChain.ac_id)) ||
          this.accountCoordinators[0];
        this.ac.setValue(ac);
      }

      this.workspaceManagers = await this.userService
        .getUsersByRole(
          ApplicationRole.WorkspaceManager,
          this.workspace.id,
          this.userService.userDisplayFields,
          null,
          false
        )
        .toPromise();

      if (this.custodyChain?.wm_id || this.workspaceManagers.length === 1) {
        const wm =
          (this.custodyChain?.wm_id && this.workspaceManagers.find((w) => w.id === this.custodyChain.wm_id)) ||
          this.workspaceManagers[0];
        this.wm.setValue(wm);
      }

      if (this.needsDFSManager) {
        this.dfs.setValidators([Validators.required]);
        this.dfs.updateValueAndValidity();

        this.dfsManagers = await this.userService
          .getUsersByRole(
            ApplicationRole.DirectorOfFacilitySolutions,
            this.workspace.id,
            this.userService.userDisplayFields,
            null,
            false
          )
          .toPromise();

        this.dfsRequired.setValue(!!this.custodyChain?.dfs_required);
        if (this.custodyChain?.cto_id || this.dfsManagers.length === 1) {
          const cto =
            (this.custodyChain?.cto_id && this.dfsManagers.find((u) => u.id === this.custodyChain.cto_id)) ||
            this.dfsManagers[0];
          this.dfs.setValue(this.custodyChain?.dfs_required === 0 ? '' : cto);
          this.dfsRequired.setValue(this.custodyChain?.dfs_required !== 0);
        }
      }

      if (this.isOneCallWorkspace) {
        this.ocd.setValidators([Validators.required]);
        this.ocd.updateValueAndValidity();

        this.allOCDs = await this.userService
          .getUsersByRole(
            ApplicationRole.OneCallDirector,
            ResourceType.App,
            this.userService.userDisplayFields,
            null,
            false
          )
          .toPromise();

        if (this.custodyChain?.ocd_id || this.allOCDs.length === 1) {
          const ocd =
            (this.custodyChain?.ocd_id && this.allOCDs.find((u) => u.id === this.custodyChain.ocd_id)) ||
            this.allOCDs[0];
          this.ocd.setValue(ocd);
        }
      }

      this.allICOs = await this.userService
        .getUsersByRole(ApplicationRole.InventoryControlOfficer, ResourceType.App, null, null, false)
        .toPromise();

      if (this.allICOs?.length) {
        this.ico.setValidators([Validators.required]);
        this.ico.updateValueAndValidity();
      }

      if (this.custodyChain?.ico_id || this.allICOs.length === 1) {
        const ico =
          (this.custodyChain?.ico_id && this.allICOs.find((u) => u.id === this.custodyChain.ico_id)) || this.allICOs[0];
        this.ico.setValue(ico);
      }

      this.allBSDs = await this.userService.getBSDUsers(this.workspace.workspace_type_id).toPromise();

      if (this.custodyChain?.bsd_id || this.allBSDs.length === 1) {
        const bsd =
          (this.custodyChain?.bsd_id && this.allBSDs.find((u) => u.id === this.custodyChain.bsd_id)) || this.allBSDs[0];
        this.bsd.setValue(bsd);
      }

      this.allCFMOs = await this.userService
        .getUsersByRole(ApplicationRole.ChiefFacilitiesManagementOfficer, ResourceType.App, null, null, false)
        .toPromise();

      if (this.custodyChain?.cfmo_id || this.allCFMOs.length === 1) {
        const cfmo =
          (this.custodyChain?.cfmo_id && this.allCFMOs.find((u) => u.id === this.custodyChain.cfmo_id)) ||
          this.allCFMOs[0];
        this.cfmo.setValue(cfmo);
      }

      this.allCEOs = [this.authService.ceo];
      if (this.custodyChain?.ceo_id || this.allCEOs.length === 1) {
        const ceo =
          (this.custodyChain?.ceo_id && this.allCEOs.find((u) => u.id === this.custodyChain.ceo_id)) || this.allCEOs[0];
        this.ceo.setValue(ceo);
      }
    }

    this.isLoading = false;
  }

  public get wm() {
    return this.staffFormGroup.get('wm');
  }

  public get ac() {
    return this.staffFormGroup.get('ac');
  }

  public get ico() {
    return this.staffFormGroup.get('ico');
  }

  public get bsd() {
    return this.staffFormGroup.get('bsd');
  }

  public get dfs() {
    return this.staffFormGroup.get('cto');
  }

  public get ocd() {
    return this.staffFormGroup.get('ocd');
  }

  public get cfmo() {
    return this.staffFormGroup.get('cfmo');
  }

  public get ceo() {
    return this.staffFormGroup.get('ceo');
  }

  public get needsDFSManager(): boolean {
    return !!this.workspace?.show_dfs_toggle;
  }

  public get dfsRequired() {
    return this.staffFormGroup.get('dfs_required');
  }

  public get isOneCallWorkspace(): boolean {
    return this.workspace?.workspace_type_id === WorkspaceType.OneCall;
  }

  changeDFSData() {
    if (this.dfsRequired.value) {
      this.dfs.enable();
    } else {
      this.dfs.setValue(0);
      this.dfs.disable();
    }
  }

  close(res = null): void {
    this.dialogRef.close(res);
  }

  submit(): void {
    for (const key in this.staffFormGroup.controls) {
      if (this.staffFormGroup.controls.hasOwnProperty(key)) {
        this.staffFormGroup.controls[key].markAsTouched();
      }
    }
    if (this.staffFormGroup.valid) {
      this.close(this.staffFormGroup.value);
    }
  }
}

<div #mainScreen id="home" class="app-container p-0 m-0" style="color: test">
  <div class="d-flex">
    <main class="full-width">
      <!-- <div id="home-header" class="p-4">
          <div class="row">
            <div class="col">
              <h1 class="m-0 white font-weight-semibold">
                Good Morning, Adam
              </h1>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <h4 class="m-0 white font-weight-bold">
                Recents
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <div class="m-portlet m-0" style="height: 151px;">
                <div class="m-portlet__body d-flex flex-column item-last p-3" style="height: 134px;">
                  <div class="row align-items-top">
                    <div class="col">
                      <h6 class="m-0 dkblue font-weight-bold">
                        <i class="material-icons dkblue ws-icon d-inline-block align-middle mr-3">
                          gavel
                        </i>
                        <span class="m-0 d-inline-block align-middle mr-3">
                          20019
                        </span>
                        <span class="m-0 d-inline-block align-middle">
                          NIC-06
                        </span>
                      </h6>
                    </div>
                    <div class="col-auto">
                      <i matRipple class="material-icons pointer ltblue ws-icon d-inline-block align-middle br-50"
                        style="font-size: 20px; height: 20px; width: 20px;">
                        push_pin
                      </i>
                      <i matRipple class="material-icons pointer ltblue ws-icon d-inline-block align-middle br-50 ml-3"
                        style="font-size: 20px; height: 20px; width: 20px;">
                        more_horiz
                      </i>
                    </div>
                  </div>
                  <div class="row mb-3 mt-3">
                    <div class="col">
                      <h5 class="m-0 dkblue font-weight-bold pointer">
                        PULMONOLOGY EXAM ROOM MODIFICATION
                      </h5>
                    </div>
                  </div>
                  <div class="row mt-auto align-items-center">
                    <div class="col">
                      <h6 class="m-0 mr-3 d-inline-block align-middle">
                        <span class="badge white bg-green text-uppercase p-2" matTooltip="Status:" matTooltipPosition="above"
                          [matTooltipShowDelay]="300">
                          Active
                        </span>
                      </h6>
                      <h6 class="m-0 mr-3 d-inline-block align-middle">
                        <span class="badge text-uppercase p-2 bg-shade-red red" matTooltip="Priority:"
                          matTooltipPosition="above" [matTooltipShowDelay]="300">
                          Priority
                        </span>
                      </h6>
                      <h6 class="m-0 mr-3 d-inline-block align-middle">
                        <span class="badge text-uppercase p-2 ltblue" matTooltip="Phase:" matTooltipPosition="above"
                          [matTooltipShowDelay]="300">
                          Phase
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="m-portlet__foot bg-ltblue p-3"></div>
              </div>
            </div>
          </div>
      </div> -->
      <header class="p-4">
        <div class="row align-items-center">
          <div class="col">
            <h1 class="m-0 dkblue font-weight-semibold">Good {{ timeOfDay }}, {{ currentUser.first_name }}</h1>
          </div>
          <div class="col-auto"></div>
        </div>
      </header>
      <section id="home_stats" class="pl-4 pr-4 mb-4">
        <div class="row">
          <div class="col-auto pr-3">
            <div class="m-portlet m-0" [ngClass]="{ 'border-dkblue bg-ltltltblue': shownSection === 'all' }"
              (click)="selectSection('all')">
              <div id="forecast" class="m-portlet__body item item-last justify-content-center p-3 d-flex">
                <mat-icon class="material-icons-two-tone two">language</mat-icon>
              </div>
            </div>
          </div>
          <div class="col pl-3 pr-3">
            <div class="m-portlet m-0" [ngClass]="{ 'border-dkblue bg-ltltltblue': shownSection === 'tasks' }"
              (click)="selectSection('tasks'); expandTasksSection = true">
              <div class="m-portlet__body item item-last p-3 d-flex">
                <i class="material-icons green mr-2">check_circle</i>
                <p class="m-0 dkblue font-weight-semibold d-block text-truncate">My Tasks</p>
                <h4 class="m-0 green font-weight-semibold ml-auto text-nowrap">
                  <span *ngIf="!loading && ['all', 'tasks'].indexOf(shownSection) > -1">{{ myTaskFilteredCount }} of
                  </span>{{ myTaskCount }}
                </h4>
              </div>
            </div>
          </div>
          <div class="col pl-3 pr-3">
            <div class="m-portlet m-0" [ngClass]="{ 'border-dkblue bg-ltltltblue': shownSection === 'projects' }"
              (click)="selectSection('projects'); expandProjectsSection = true">
              <div class="m-portlet__body item item-last p-3 d-flex">
                <i class="material-icons ltblue mr-2">assignment_turned_in</i>
                <p class="m-0 dkblue font-weight-semibold d-block text-truncate">My Projects</p>
                <h4 class="m-0 ltblue font-weight-semibold ml-auto text-nowrap">
                  <span *ngIf="!loading && ['all', 'projects'].indexOf(shownSection) > -1">{{ myProjectFilteredCount }}
                    of </span>{{ myProjectCount }}
                </h4>
              </div>
            </div>
          </div>
          <div class="col pl-3 pr-3" *ngIf="!authService.isVendorOnAnyProject || isStaffOnAnyModule">
            <div class="m-portlet m-0" [ngClass]="{ 'border-dkblue bg-ltltltblue': shownSection === 'workOrders' }"
              (click)="selectSection('workOrders'); expandWorkOrdersSection = true">
              <div class="m-portlet__body item item-last p-3 d-flex">
                <i class="material-icons orange mr-2">description</i>
                <p class="m-0 dkblue font-weight-semibold d-block text-truncate">My Work Orders</p>
                <h4 class="m-0 orange font-weight-semibold ml-auto text-nowrap">
                  <span *ngIf="!loading && ['all', 'workOrders'].indexOf(shownSection) > -1">{{ myWorkOrderFilteredCount
                    }} of </span>{{ myWorkOrderCount }}
                </h4>
              </div>
            </div>
          </div>
          <div class="col pl-3">
            <div class="m-portlet m-0" [ngClass]="{ 'border-dkblue bg-ltltltblue': shownSection === 'reviews' }"
              (click)="selectSection('reviews')">
              <div class="m-portlet__body item item-last p-3 d-flex">
                <i class="material-icons green mr-2">library_add_check</i>
                <p class="m-0 dkblue font-weight-semibold d-block text-truncate">My Reviews</p>
                <h4 class="m-0 green font-weight-semibold ml-auto text-nowrap">
                  <span *ngIf="!loading && ['all', 'reviews'].indexOf(shownSection) > -1">{{ myReviewFilteredCount }} of
                  </span>{{ myReviewCount }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section #tasksAnchor id="tasks" class="pl-4 pr-4 mb-4"
        *ngIf="['all', 'tasks', 'reviews'].indexOf(shownSection) > -1">
        <div class="row align-items-center mb-2">
          <div (click)="expandTasksSection = !expandTasksSection" class="col-auto d-flex align-items-center">
            <mat-icon class="dkblue mr-1 pointer"> {{ expandTasksSection ? 'expand_more' : 'chevron_right' }}</mat-icon>
            <h5 class="m-0 dkblue font-weight-semibold pointer mr-3">
              My Tasks
              <small class="ml-3 dkgray">
                <span *ngIf="!loading && ['all', 'tasks', 'reviews'].indexOf(shownSection) > -1">{{ shownSection ===
                  'reviews' ? myReviewFilteredCount : myTaskFilteredCount }} of </span>{{ myTaskCount }}
              </small>
            </h5>
          </div>
          <div class="col text-right">
            <button (click)="collapseExpandedTasks()" mat-button color="accent" class="pl-3 pr-3 ml-3 lh-28"
              *ngIf="isStaffOnAnyModule && expandedTaskProjectIds.length">
              Collapse All
            </button>
            <button *ngIf="!expandedTaskProjectIds?.length" (click)="expandAllTasks()" mat-button color="accent"
              class="pl-3 pr-3 ml-3 lh-28">
              Expand All
            </button>
            <mat-spinner *ngIf="loading" class="d-inline-block align-middle m-0 ml-2" color="accent"
              [diameter]="16"></mat-spinner>
            <button mat-stroked-button color="primary" [matMenuTriggerFor]="role_menu_task"
              class="bg-white pl-3 pr-3 ml-3 lh-28">
              {{ selectedTaskRole?.selectedLabel || selectedTaskRole?.menuLabel }}
              <i class="fa fa-caret-down ml-2"></i>
            </button>
            <mat-menu #role_menu_task="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let o of taskRoleOptions" (click)="selectTaskRole(o)">
                <mat-icon>{{ o.icon }}</mat-icon>
                <span>{{ o.menuLabel }}</span>
              </button>
            </mat-menu>
            <button mat-stroked-button color="primary" [matMenuTriggerFor]="type_menu_task"
              class="bg-white pl-3 pr-3 ml-3 lh-28" [disabled]="shownSection === 'reviews'">
              {{ selectedTaskType?.selectedLabel || selectedTaskType?.menuLabel }}
              <i class="fa fa-caret-down ml-2"></i>
            </button>
            <mat-menu #type_menu_task="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let o of taskTypeOptions" (click)="selectTaskType(o)">
                <mat-icon>{{ o.icon }}</mat-icon>
                <span>{{ o.menuLabel }}</span>
              </button>
            </mat-menu>
            <button mat-stroked-button color="primary" [matMenuTriggerFor]="due_menu_task"
              class="bg-white pl-3 pr-3 ml-3 lh-28" *ngIf="isStaffOnAnyModule">
              {{ selectedTaskTimespan?.selectedLabel || selectedTaskTimespan?.menuLabel }}
              <i class="fa fa-caret-down ml-2"></i>
            </button>
            <mat-menu #due_menu_task="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let o of timespanOptions" (click)="selectTaskTimespan(o)">
                <mat-icon>{{ o.icon }}</mat-icon>
                <span>{{ o.menuLabel }}</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="m-portlet mb-0">
          <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3" *ngIf="expandTasksSection">
            <div class="row d-flex align-items-center">
              <div class="col-auto pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                <h6 class="m-0 text-truncate header-title mr-auto">Status</h6>
              </div>
              <div class="col-auto pt-1 pb-1 px-3 d-flex align-items-center header-item">
                <h6 class="m-0 text-truncate header-title mr-auto px-1">
                  <i class="material-icons primary">account_circle</i>
                </h6>
              </div>
              <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                <h6 class="m-0 text-truncate header-title mr-auto">Task / Parent</h6>
              </div>
              <div class="col-auto pt-1 pb-1 pr-4 pl-3 d-flex align-items-center header-item"
                (click)="updateSortByField('due_date')">
                <h6 class="m-0 text-truncate header-title mr-5 pr-5">Due Date</h6>
                <i *ngIf="fieldToSortBy === 'due_date'" matRipple class="material-icons">
                  {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                </i>
              </div>
              <div class="col-auto pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                <h6 class="m-0 text-truncate header-title mr-auto">Menu</h6>
              </div>
            </div>
          </div>
          <!--Display if no tasks and expanded-->
          <div *ngIf="!loading && myTaskFilteredCount <= 0" class="row my-5" [hidden]="!expandTasksSection">
            <div class="col-md-5 py-5 mx-auto text-center">
              <!--Default is tasks otherwise, reviews-->
              <h5 *ngIf="shownSection !== 'reviews'" class="dkblue lh-24">
                No tasks {{ myTaskCount > 0 ? 'match your current filters' : 'at this time' }}.
              </h5>
              <h5 *ngIf="shownSection === 'reviews'" class="dkblue lh-24">
                No tasks {{ myReviewCount > 0 ? 'match your current filters' : 'at this time' }}.
              </h5>
              <!-- <button
                mat-stroked-button
                color="accent"
                class="mt-2 bg-white"
                (click)="resetFilters()"
              >
                Clear Filters
              </button> -->
            </div>
          </div>
          <div *ngIf="expandTasksSection">
            <div *ngFor="let t of myTasks | paginator: taskPaginator.pageIndex:taskPaginator.pageSize" class="item">
              <app-task-view [isReAssigningUserAndTaskId]="isReAssigningUserAndTaskId" [taskData]="t"
                [preventTaskSelection]="true" [showUndoTaskStatus]="true"
                [previousTaskStatusId]="t.previousTaskStatusId" (taskUpdate)="taskUpdate(t, $event)"
                (taskAssignmentChanged)="taskAssignmentChanged(t, $event)" (taskDeleted)="removeTask(p, $event)"
                (click)="viewTask(t)" [showParent]="true"></app-task-view>
            </div>
          </div>
        </div>
        <mat-paginator [hidden]="!expandTasksSection" #taskPaginator class="bg-transparent dkblue" color="primary"
          [length]="myTasks.length" [pageSize]="tasksPageSize" [pageSizeOptions]="pageSizeOptions"
          (page)="handleTasksPageEvent($event, tasksAnchor)">
        </mat-paginator>
      </section>
      <!-- This section is hidden from suppliers -->
      <section #woAnchor id="work_orders" class="pl-4 pr-4 mb-4"
        *ngIf="currentUser?.user_type_id !== 3 && ['all', 'workOrders'].indexOf(shownSection) > -1">
        <div *ngIf="!authService.isVendorOnAnyProject || isStaffOnAnyModule" class="row align-items-center mb-2">
          <div (click)="expandWorkOrdersSection = !expandWorkOrdersSection" class="col-auto d-flex align-items-center">
            <mat-icon class="dkblue mr-1 pointer">
              {{ expandWorkOrdersSection ? 'expand_more' : 'chevron_right' }}</mat-icon>
            <h5 class="m-0 dkblue font-weight-semibold pointer">
              My Work Orders
              <small class="ml-3 dkgray">
                <span *ngIf="!loading && ['all', 'workOrders'].indexOf(shownSection) > -1">{{ myWorkOrderFilteredCount
                  }} of </span>{{ myWorkOrderCount }}
              </small>
            </h5>
          </div>
          <div class="col text-right">
            <mat-spinner *ngIf="loading" class="d-inline-block align-middle m-0 ml-2" color="accent"
              [diameter]="16"></mat-spinner>
            <button mat-stroked-button color="primary" [matMenuTriggerFor]="role_menu_wo"
              class="bg-white pl-3 pr-3 ml-3 lh-28">
              {{ selectedWorkOrderRole?.selectedLabel || selectedWorkOrderRole?.menuLabel }}
              <i class="fa fa-caret-down ml-2"></i>
            </button>
            <mat-menu #role_menu_wo="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let o of workOrderRoleOptions" (click)="selectWorkOrderRole(o)">
                <mat-icon>{{ o.icon }}</mat-icon>
                <span>{{ o.menuLabel }}</span>
              </button>
            </mat-menu>
            <button *ngIf="isStaffOnAnyModule" mat-stroked-button color="primary" [matMenuTriggerFor]="due_menu_wo"
              class="bg-white pl-3 pr-3 ml-3 lh-28">
              {{ selectedWorkOrderTimespan?.selectedLabel || selectedWorkOrderTimespan?.menuLabel }}
              <i class="fa fa-caret-down ml-2"></i>
            </button>
            <mat-menu #due_menu_wo="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let o of timespanOptions" (click)="selectWorkOrderTimespan(o)">
                <mat-icon>{{ o.icon }}</mat-icon>
                <span>{{ o.menuLabel }}</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div *ngIf="!authService.isVendorOnAnyProject || isStaffOnAnyModule">
          <div class="m-portlet m-0">
            <div *ngIf="myWorkOrderFilteredCount > 0 && expandWorkOrdersSection"
              class="m-portlet__body bg-shade-ltblue table-header p-0 pl-3 pr-3">
              <div class="row align-items-center">
                <div class="pointer col-auto pt-2 pb-2 pr-3 d-flex align-items-center header-item" style="width: 100px"
                  (click)="sortWorkOrders('id')">
                  <h6 class="m-0 text-truncate header-title mr-auto">ID</h6>
                  <i *ngIf="workOrderSortProperty === 'id'" matRipple class="material-icons">
                    {{ workOrderSortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
                  </i>
                </div>
                <div class="pointer col pt-2 pb-2 pl-3 pr-3 d-flex align-items-center header-item"
                  style="max-width: 110px" (click)="sortWorkOrders('location')">
                  <h6 class="m-0 text-truncate header-title mr-auto">Location</h6>
                  <i *ngIf="workOrderSortProperty === 'location'" matRipple class="material-icons">
                    {{ workOrderSortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
                  </i>
                </div>
                <div class="pointer col pt-2 pb-2 pl-3 pr-3 d-flex align-items-center header-item"
                  style="max-width: 160px" (click)="sortWorkOrders('module.name')">
                  <h6 class="m-0 text-truncate header-title mr-auto">Workspace</h6>
                  <i *ngIf="workOrderSortProperty === 'module.name'" matRipple class="material-icons">
                    {{ workOrderSortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
                  </i>
                </div>
                <div class="pointer col-3 pt-2 pb-2 pl-3 pr-3 d-flex header-item align-items-center"
                  (click)="sortWorkOrders('title')">
                  <h6 class="m-0 text-truncate header-title mr-auto">Short Description</h6>
                  <i *ngIf="workOrderSortProperty === 'title'" matRipple class="material-icons">
                    {{ workOrderSortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
                  </i>
                </div>
                <div class="pointer col pt-2 pb-2 pl-3 pr-3 d-flex header-item align-items-center"
                  (click)="sortWorkOrders('priority.id')">
                  <h6 class="m-0 text-truncate header-title mr-auto">Priority</h6>
                  <i *ngIf="workOrderSortProperty === 'priority.id'" matRipple class="material-icons">
                    {{ workOrderSortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
                  </i>
                </div>
                <div class="pointer col pt-2 pb-2 pl-3 pr-3 d-flex header-item align-items-center"
                  (click)="sortWorkOrders('due_date')">
                  <h6 class="m-0 text-truncate header-title mr-auto">Due Date</h6>
                  <i *ngIf="workOrderSortProperty === 'due_date'" matRipple class="material-icons">
                    {{ workOrderSortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
                  </i>
                </div>
                <div class="pointer col pt-2 pb-2 pl-3 pr-3 d-flex header-item align-items-center"
                  (click)="sortWorkOrders('assigned_user_id')">
                  <h6 class="m-0 text-truncate header-title mr-auto">Assigned To</h6>
                  <i *ngIf="workOrderSortProperty === 'assigned_user_id'" matRipple class="material-icons">
                    {{ workOrderSortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
                  </i>
                </div>
                <div class="pointer col pt-2 pb-2 pl-3 pr-3 d-flex header-item align-items-center">
                  <h6 class="m-0 text-truncate header-title mr-auto">Last Update</h6>
                </div>
              </div>
            </div>

            <!--the wrapper fixes the 'ExpressionChangedAfterItHasBeenCheckedError'-->
            <div *ngIf="pageSize">
              <div [hidden]="!expandWorkOrdersSection" matRipple class="m-portlet__body item p-3 pointer"
                *ngFor="let wo of myWorkOrders | paginator: workOrderPaginator.pageIndex:workOrderPaginator.pageSize"
                [ngClass]="{ 'bg-shade-red': isOverdue(wo.due_date) }" [routerLink]="['/work-orders/' + wo.id]">
                <div class="row align-items-center">
                  <div class="col-auto pr-3 d-flex align-items-center data-item" style="width: 100px">
                    <h6 class="m-0 mr-auto dkblue pointer font-weight-semibold">
                      {{ wo.code }}
                    </h6>
                  </div>
                  <div class="col pl-3 pr-3 d-flex align-items-center data-item" style="max-width: 110px">
                    <h6 class="m-0 mr-auto dkblue pointer font-weight-semibold">
                      {{ wo.building?.code }}-{{ wo.floor?.code }}
                    </h6>
                  </div>
                  <div class="col pl-3 pr-3 d-flex align-items-center data-item" style="max-width: 160px">
                    <h6
                      class="m-0 bg-shade-gray dkgray lh-20 br-5 pl-2 pr-2 font-weight-semibold d-block text-truncate">
                      {{ wo.module?.name }}
                    </h6>
                  </div>
                  <div class="col-3 pl-3 pr-3 d-flex flex-column data-item">
                    <a [routerLink]="['/work-orders/' + wo.id]" (click)="stopPropagation($event)"
                      class="text-decoration-none hover-u">
                      <h6 class="m-0 dkblue font-weight-semibold d-block">
                        {{ wo.title }}
                      </h6>
                      <span class="m-0 dkgray font-weight-normal d-block" *ngIf="wo.topic">
                        {{ wo.topic?.topic_category?.topic_group?.name }} > {{ wo.topic?.topic_category?.name }} >
                        {{ wo.topic?.name }}
                      </span>
                    </a>
                  </div>
                  <div class="col pl-3 pr-3 d-flex align-items-center data-item">
                    <h6 class="m-0 white lh-20 br-5 pl-2 pr-2 font-weight-semibold d-block text-truncate mr-auto"
                      [ngClass]="{
                      'bg-dkred': wo.priority?.id === 4,
                      'bg-red': wo.priority?.id === 3,
                      'bg-ltblue': wo.priority?.id === 2,
                      'bg-gray': wo.priority?.id === 1
                    }">
                      {{ wo.priority?.name }}
                    </h6>
                  </div>
                  <div class="col pl-3 pr-3 d-flex data-item align-items-center">
                    <i *ngIf="isOverdue(wo.due_date)" class="material-icons red mr-2">error</i>
                    <p class="m-0 dkblue font-weight-semibold d-block" [ngClass]="{
                      gray: !wo.due_date,
                      red: isOverdue(wo.due_date),
                      dkblue: !isOverdue(wo.due_date)
                    }">
                      {{ wo.due_date ? (wo.due_date | date: 'EEE, MMM d yyyy') : 'Not Set' }}
                    </p>
                  </div>
                  <div class="col pl-3 pr-3 d-flex data-item align-items-center">
                    <i *ngIf="!wo.assigned_user" class="material-icons red mr-2 ml-1">error</i>
                    <app-profile-thumbnail class="d-inline-block align-middle mr-2" [height]="26" [width]="26"
                      *ngIf="wo.assigned_user" [userId]="wo.assigned_user.id"></app-profile-thumbnail>
                    <p class="dkblue m-0 d-inline-block align-middle font-weight-normal text-wrap text-truncate"
                      [ngClass]="{ 'red font-weight-semibold': !wo.assigned_user }">
                      {{
                      wo.assigned_user
                      ? wo.assigned_user?.first_name + ' ' + wo.assigned_user?.last_name
                      : 'Not Assigned'
                      }}
                    </p>
                  </div>
                  <div class="col pl-3 pr-3 d-flex data-item align-items-center">
                    <p class="m-0 font-weight-semibold gray" *ngIf="!wo?.updates?.[0]">No Updates</p>
                    <p (click)="$event.stopPropagation()" [matMenuTriggerFor]="workOrderUpdateMenu"
                      class="m-0 font-weight-semibold ltblue hover-u d-block" *ngIf="wo?.updates?.[0]">
                      {{ wo?.lastUpdate?.created_datetime | date: 'EEE, MMM d yyyy' }}
                      <br />
                      <span class="m-0 font-weight-normal font-italic dkgray" style="font-size: 80%"
                        *ngIf="wo?.updates?.[0] && wo?.lastUpdate?.created_by">
                        By {{ wo?.lastUpdate?.created_by.first_name }} {{ wo?.lastUpdate?.created_by.last_name }}
                      </span>
                    </p>

                    <mat-menu #workOrderUpdateMenu="matMenu" xPosition="before">
                      <div class="d-flex py-3 px-4" *ngIf="wo?.updates?.[0]">
                        <app-profile-thumbnail class="mr-3" [noBorder]="true" [height]="30" [width]="30"
                          [userId]="wo?.lastUpdate?.created_by?.id"></app-profile-thumbnail>
                        <div class="flex-grow-1">
                          <div class="d-flex align-items-start mb-3">
                            <div class="d-flex flex-column mr-4">
                              <h6 class="mb-0 dkblue font-weight-semibold text-nowrap">
                                {{ wo?.lastUpdate?.created_by?.first_name }}
                                {{ wo?.lastUpdate?.created_by?.last_name }}
                              </h6>
                              <h6 class="mb-0 dkgray font-weight-normal text-nowrap">
                                {{ wo?.lastUpdate?.created_datetime | date: 'EEE, MMM d • h:mmaa' }}
                              </h6>
                            </div>
                            <p *ngIf="wo?.lastUpdate?.work_order_health_type?.id"
                              class="mb-0 px-2 br-4 ml-auto bg-green white font-weight-semibold" [ngClass]="{
                            'bg-green': wo?.lastUpdate?.work_order_health_type?.id === 1,
                            'bg-orange': wo?.lastUpdate?.work_order_health_type?.id === 2,
                            'bg-red': wo?.lastUpdate?.work_order_health_type?.id === 3
                          }" style="font-size: 11px; line-height: 20px">
                              {{ wo?.lastUpdate?.work_order_health_type?.name }}
                            </p>
                          </div>
                          <a class="m-0 ltblue font-weight-semibold hover-u mb-3 text-decoration-none"
                            [routerLink]="['/work-orders/' + wo.id]">
                            {{ wo.code }}{{ wo.title ? ' - ' : '' }} {{ wo.title }}
                          </a>
                          <p class="m-0 mt-3 dkblue font-weight-normal" [innerHTML]="wo?.lastUpdate?.message"></p>
                        </div>
                      </div>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Display if no work orders and expanded-->
          <div *ngIf="!loading && myWorkOrders?.length <= 0" class="row my-5" [hidden]="!expandWorkOrdersSection">
            <div class="col-md-5 py-5 mx-auto text-center">
              <h5 class="dkblue lh-24">
                No Work Orders {{ myWorkOrderCount > 0 ? 'match your current filters' : 'at this time' }}.
              </h5>
              <!-- <button
              mat-stroked-button
              color="accent"
              class="mt-2 bg-white"
              (click)="resetFilters()"
            >
              Clear Filters
            </button> -->
            </div>
          </div>
          <mat-paginator [hidden]="!expandWorkOrdersSection" #workOrderPaginator class="bg-transparent dkblue"
            color="primary" [length]="myWorkOrders.length" [pageSize]="workOrdersPageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="handleWorkOrdersPageEvent($event, woAnchor)">
          </mat-paginator>
        </div>
      </section>
      <!-- PROJECTS THAT I AM THE PM -->
      <!-- TODO - IF I AM A STAFF IT SHOULD ONLY SHOW MY REQUESTED PROJECTS -->
      <!-- TODO - IF I AM A TENANT IT SHOULD ONLY SHOW MY REQUESTED PROJECTS -->
      <section #projectsAnchor id="projects" class="pl-4 pr-4 mb-4"
        *ngIf="['all', 'projects'].indexOf(shownSection) > -1">
        <div class="row align-items-center mb-2">
          <div (click)="expandProjectsSection = !expandProjectsSection" class="col-auto d-flex align-items-center">
            <mat-icon class="dkblue mr-1 pointer">
              {{ expandProjectsSection ? 'expand_more' : 'chevron_right' }}</mat-icon>
            <h5 class="m-0 dkblue font-weight-semibold pointer">
              My Projects
              <small class="dkgray ml-3">
                <span *ngIf="!loading && ['all', 'projects'].indexOf(shownSection) > -1">{{ myProjectFilteredCount }} of
                </span>{{ myProjectCount }}
              </small>
            </h5>
          </div>
          <div class="col text-right">
            <mat-spinner *ngIf="loading" class="d-inline-block align-middle m-0 ml-2" color="accent"
              [diameter]="16"></mat-spinner>
            <button *ngIf="isStaffOnAnyModule" mat-stroked-button color="primary"
              [matMenuTriggerFor]="role_menu_project" class="bg-white pl-3 pr-3 ml-3 lh-28">
              {{ selectedProjectRole?.selectedLabel || selectedProjectRole?.menuLabel }}
              <i class="fa fa-caret-down ml-2"></i>
            </button>
            <mat-menu #role_menu_project="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let o of projectRoleOptions" (click)="selectProjectRole(o)">
                <mat-icon>{{ o.icon }}</mat-icon>
                <span>{{ o.menuLabel }}</span>
              </button>
            </mat-menu>
            <button *ngIf="isStaffOnAnyModule" mat-stroked-button color="primary"
              [matMenuTriggerFor]="status_menu_project" class="bg-white pl-3 pr-3 ml-3 lh-28">
              {{ selectedProjectStatus?.selectedLabel || selectedProjectStatus?.menuLabel }}
              <i class="fa fa-caret-down ml-2"></i>
            </button>
            <mat-menu #status_menu_project="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let o of projectStatusOptions" (click)="selectProjectStatus(o)">
                <mat-icon>{{ o.icon }}</mat-icon>
                <span>{{ o.menuLabel }}</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="m-portlet mb-0">
          <div *ngIf="myProjectFilteredCount > 0 && expandProjectsSection"
            class="m-portlet__body bg-shade-ltblue table-header p-0 pl-3 pr-3">
            <div class="row align-items-center">
              <div class="pointer col-1 pt-2 pb-2 pr-3 d-flex align-items-center header-item"
                (click)="sortProjects('id')">
                <h6 class="m-0 text-truncate header-title mr-auto">ID</h6>
                <i *ngIf="projectSortProperty === 'id'" matRipple class="material-icons">
                  {{ projectSortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
                </i>
              </div>
              <div class="pointer col-1 pt-2 pb-2 pl-3 pr-3 d-flex align-items-center header-item"
                (click)="sortProjects('location')">
                <h6 class="m-0 text-truncate header-title mr-auto">Location</h6>
                <i *ngIf="projectSortProperty === 'location'" matRipple class="material-icons">
                  {{ projectSortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
                </i>
              </div>
              <div class="pointer col-1 pt-2 pb-2 pl-3 pr-3 d-flex align-items-center header-item"
                (click)="sortProjects('module.name')">
                <h6 class="m-0 text-truncate header-title mr-auto">Workspace</h6>
                <i *ngIf="projectSortProperty === 'module.name'" matRipple class="material-icons">
                  {{ projectSortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
                </i>
              </div>
              <div class="pointer col-3 pt-2 pb-2 pl-3 pr-3 d-flex header-item align-items-center"
                (click)="sortProjects('short_description')">
                <h6 class="m-0 text-truncate header-title mr-auto">Short Description</h6>
                <i *ngIf="projectSortProperty === 'short_description'" matRipple class="material-icons">
                  {{ projectSortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
                </i>
              </div>
              <div *ngIf="+currentUser?.user_type_id !== 3"
                class="col-4 pt-2 pb-2 pl-3 pr-3 d-flex header-item align-items-center">
                <h6 class="m-0 text-truncate header-title mr-auto">Progress</h6>
              </div>
              <div *ngIf="isWorkspaceStaff"
                class="pointer col-2 pt-2 pb-2 pl-3 pr-3 d-flex align-items-center header-item">
                <h6 class="m-0 text-truncate header-title mr-auto">Last Update</h6>
              </div>
            </div>
          </div>
          <!--the wrapper fixes the 'ExpressionChangedAfterItHasBeenCheckedError'-->
          <div *ngIf="pageSize">
            <div [hidden]="!expandProjectsSection" class="m-portlet__body item pointer p-3"
              *ngFor="let p of myProjects | paginator: projectPaginator.pageIndex:projectPaginator.pageSize"
              [routerLink]="['/projects/' + p.id]">
              <div class="row align-items-center">
                <div class="col-1 pr-3 d-flex align-items-center data-item">
                  <p class="m-0 mr-auto dkblue pointer font-weight-semibold">PJ-{{ p.code }}</p>
                </div>
                <div class="col-1 pl-3 pr-3 d-flex align-items-center data-item">
                  <p class="m-0 mr-auto dkblue pointer font-weight-semibold">
                    {{ p.building?.code }}-{{ p.floor?.code }}
                  </p>
                </div>
                <div class="col-1 pl-3 pr-3 d-flex align-items-center data-item">
                  <h6 class="m-0 bg-shade-gray dkgray lh-20 br-5 pl-2 pr-2 font-weight-semibold d-block text-truncate">
                    {{ p.module?.name }}
                  </h6>
                </div>
                <div class="col-3 pl-3 pr-3 d-flex flex-column data-item">
                  <a [routerLink]="['/projects/' + p.id]" (click)="stopPropagation($event)"
                    class="text-decoration-none hover-u">
                    <p class="m-0 dkblue font-weight-semibold d-block">
                      {{ getProjectShortDescription(p) }}
                    </p>
                  </a>
                </div>
                <div *ngIf="+currentUser?.user_type_id !== 3" class="col-4 pl-3 pr-3 d-flex align-items-center">
                  <mat-icon class="mr-2" [ngClass]="{ red: !p.on_schedule, green: p.on_schedule }"
                    [matTooltip]="p.on_schedule ? 'On Schedule' : 'Behind Schedule'" matTooltipPosition="above"
                    [matTooltipShowDelay]="300">{{ p.on_schedule ? 'arrow_forward' : 'arrow_back' }}</mat-icon>
                  <h6 class="m-0 dkblue font-weight-normal mr-auto b-block text-truncate full-width">
                    <span class="font-weight-semibold mr-2">
                      {{ p.current_phase?.name }}
                    </span>
                    <span class="font-weight-normal mr-2"> | </span>
                    <span class="font-weight-normal mr-2">
                      <strong>{{ p.complete_tasks_count }}</strong> of {{ p.all_tasks_count }} Task{{
                      p.all_tasks_count === 1 ? '' : 's'
                      }}
                      Complete;
                      <span class="red"><strong>{{ p.overdue_tasks_count }}</strong> Task{{
                        p.overdue_tasks_count === 1 ? '' : 's'
                        }}
                        Overdue</span>
                    </span>
                    <mat-progress-bar class="full-width mt-2" color="primary" mode="buffer"
                      [value]="(100 * p.complete_tasks_count) / p.all_tasks_count"
                      [bufferValue]="(100 * (p.complete_tasks_count + p.overdue_tasks_count)) / p.all_tasks_count">
                    </mat-progress-bar>
                  </h6>
                  <!-- <button mat-icon-button color="accent" class="ml-2">
                  <mat-icon>more_vert</mat-icon>
                </button> -->
                </div>
                <div *ngIf="isWorkspaceStaff" class="col-2 pl-3 pr-3 d-flex data-item align-items-center">
                  <p class="m-0 font-weight-semibold gray" *ngIf="!p.updates?.[0]">No Updates</p>
                  <p (click)="$event.stopPropagation()" [matMenuTriggerFor]="projectUpdateMenu"
                    class="m-0 font-weight-semibold ltblue hover-u d-block" *ngIf="p?.updates?.[0]">
                    {{ p?.lastUpdate.created_datetime | date: 'EEE, MMM d yyyy' }}
                    <br />
                    <span class="m-0 font-weight-normal font-italic dkgray" style="font-size: 80%"
                      *ngIf="p?.lastUpdate && p?.lastUpdate.created_by_id">
                      By {{ p?.lastUpdate.created_by_first_name }}
                      {{ p?.lastUpdate.created_by_last_name }}
                    </span>
                  </p>
                  <mat-menu #projectUpdateMenu="matMenu" xPosition="before">
                    <div class="d-flex py-3 px-4" *ngIf="p.updates?.[0]">
                      <app-profile-thumbnail class="mr-3" [noBorder]="true" [height]="30" [width]="30"
                        [userId]="p.lastUpdate?.created_by_id"></app-profile-thumbnail>
                      <div class="flex-grow-1">
                        <div class="d-flex align-items-start mb-3">
                          <div class="d-flex flex-column mr-4">
                            <h6 class="mb-0 dkblue font-weight-semibold text-nowrap">
                              {{ p.lastUpdate?.created_by_first_name }}
                              {{ p.lastUpdate?.created_by_last_name }}
                            </h6>
                            <h6 class="mb-0 dkgray font-weight-normal text-nowrap">
                              {{ p.lastUpdate?.created_datetime | date: 'EEE, MMM d • h:mmaa' }}
                            </h6>
                          </div>
                          <p *ngIf="p.lastUpdate?.project_health_type?.id"
                            class="mb-0 px-2 br-4 ml-auto bg-green white font-weight-semibold" [ngClass]="{
                                              'bg-green': p.lastUpdate?.project_health_type?.id === 1,
                                              'bg-orange': p.lastUpdate?.project_health_type?.id === 2,
                                              'bg-red': p.lastUpdate?.project_health_type?.id === 3
                                            }" style="font-size: 11px; line-height: 20px">
                            {{ p.lastUpdate?.project_health_type?.name }}
                          </p>
                        </div>
                        <a class="m-0 ltblue font-weight-semibold hover-u mb-3 text-decoration-none"
                          [routerLink]="['/projects/' + p.id]">
                          {{ p?.code }}{{ p?.title ? ' - ' : '' }} {{ p?.title }}
                        </a>
                        <p class="m-0 mt-3 dkblue font-weight-normal" [innerHTML]="p.lastUpdate?.message"></p>
                      </div>
                    </div>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
          <!--Display if no projects and expanded-->
          <div *ngIf="!loading && myProjects?.length <= 0" class="row my-5" [hidden]="!expandProjectsSection">
            <div class="col-md-5 py-5 mx-auto text-center">
              <h5 class="dkblue lh-24">
                No Projects {{ myProjectCount > 0 ? 'match your current filters' : 'at this time' }}.
              </h5>
              <!-- <button
                mat-stroked-button
                color="accent"
                class="mt-2 bg-white"
                (click)="resetFilters()"
              >
                Clear Filters
              </button> -->
            </div>
          </div>
        </div>
        <mat-paginator [hidden]="!expandProjectsSection" #projectPaginator class="bg-transparent dkblue" color="primary"
          [length]="myProjects.length" [pageSize]="projectsPageSize" [pageSizeOptions]="pageSizeOptions"
          (page)="handleProjectsPageEvent($event, projectsAnchor)">
        </mat-paginator>
      </section>
    </main>
    <!-- <section id="home-side" class="p-4">
      <section id="alerts" class="mb-5">
        <div class="row mb-2">
          <div class="col">
            <h5 class="m-0 dkblue font-weight-bold">
              1Call Alerts
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="m-portlet m-0">
              <div class="m-portlet__body item p-2 pl-3">
                <div class="row d-flex align-items-center">
                  <div class="col d-flex">
                    <p class="m-0 dkblue font-weight-semibold">
                      [AR - NIC] ALERT - Garrison Tower Fire Alarm Test Tuesday Morning
                    </p>
                  </div>
                  <div class="col-auto text-right">
                    <p class="font-weight-normal d-inline-block align-middle m-0 dkgray">
                      Jul 14
                    </p>
                    <button mat-icon-button color="accent" class="ml-1">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="m-portlet__body item item-last p-2 pl-3">
                <div class="row d-flex align-items-center">
                  <div class="col d-flex">
                    <p class="m-0 dkblue font-weight-semibold">
                      ALERT - Covid19 - Mask REQUIRED outside of your office
                    </p>
                  </div>
                  <div class="col-auto text-right">
                    <p class="font-weight-normal d-inline-block align-middle m-0 dkgray">
                      Jun 1
                    </p>
                    <button mat-icon-button color="accent" class="ml-1">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="reminders" class="mb-5">
        <div class="row mb-2">
          <div class="col">
            <h5 class="m-0 dkblue font-weight-bold">
              Upcoming Reminders
            </h5>
          </div>
          <div class="col-auto">
            <button mat-button color="accent" class="lh-24 pl-2 pr-2">
              View All
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="m-portlet m-0">
              <div class="m-portlet__body item p-2">
                <div class="row d-flex align-items-center">
                  <div class="col d-flex align-items-center">
                    <button mat-icon-button class="gray mr-2 align-middle">
                      <mat-icon>radio_button_unchecked</mat-icon>
                    </button>
                    <p class="m-0 dkblue font-weight-semibold align-middle limit-2">
                      Remind me to call Tony Stark back
                    </p>
                  </div>
                  <div class="col-auto text-right">
                    <p class="main-label d-inline-block align-middle m-0" style="font-size: 0.8rem;">
                      <span class="font-weight-semibold dkblue">
                        Jun 1 @ 8:30am
                      </span>
                      <br />
                      <span class="font-weight-normal red">
                        Jun 3 @ 8:00am
                      </span>
                    </p>
                    <button mat-icon-button color="accent" class="ml-1">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="meetings" class="mb-5">
        <div class="row mb-2">
          <div class="col">
            <h5 class="m-0 dkblue font-weight-bold">
              Meetings This Week
            </h5>
          </div>
          <div class="col-auto">
            <button mat-button color="accent" class="lh-24 pl-2 pr-2">
              View All
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="m-portlet m-0">
              <div class="m-portlet__body item p-2 pl-3">
                <div class="row d-flex align-items-center">
                  <div class="col d-flex align-items-center">
                    <p class="m-0 dkblue font-weight-semibold align-middle text-wrap">
                      Weekly Construction Meeting
                    </p>
                  </div>
                  <div class="col-auto text-right">
                    <p class="main-label d-inline-block align-middle m-0" style="font-size: 0.8rem;">
                      <span class="font-weight-semibold dkblue">
                        Mon @ 8:30am
                      </span>
                      <br />
                      <span class="font-weight-normal dkgray">
                        Conference Room
                      </span>
                    </p>
                    <button mat-icon-button color="accent" class="ml-1">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section> -->
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

import { Task } from '../types/task';
import { LinkedWOTask } from '../types/linked-wo-task';

/**
 * This pipe is for filtering the tasks of the tasks page.
 * TODO: Performance can be incresed by allowing it to be "pure". Currently, the pipe is refreshed on every detection change. Happens too much.
 */
@Pipe({
  name: 'tasksFilter',
  pure: false,
})
export class TasksFilterPipe implements PipeTransform {
  transform(tasks: (Task | LinkedWOTask)[], tasksFilter: TasksFilter): any {
    if (tasksFilter.getForHideCompleted()) {
      tasks = tasks.filter((task) => task.status_id !== 3);
    }

    if (tasksFilter.getForApprovalsOnly()) {
      tasks = tasks.filter(
        (task) => 'accessory_data' in task && task.accessory_data && JSON.parse(task.accessory_data).isReviewItem
      );
    }

    const userIds = tasksFilter.getShowForUserIds();
    if (userIds.length > 0) {
      if (userIds.includes(0)) {
        tasks = tasks.filter((task) => !task.assigned_user_id);
      } else {
        tasks = tasks.filter((task) => userIds.includes(task.assigned_user_id));
      }
    }

    const searchTerm = tasksFilter.getSearchTerm();
    if (searchTerm) {
      tasks = tasks.filter(
        (task) =>
          task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          ('description' in task &&
            task.description &&
            task.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
          tasksFilter.assignedUser(task.assigned_user_first_name, task.assigned_user_last_name)
      );
    }

    // Removing this sort by due date in order to sort by nodes, to allow the drag and drop
    // tasks.map( task => tasksFilter.assignedUser(task.assigned_user_first_name, task.assigned_user_last_name));
    // added 1/17/20 to filter by due date
    // order by overdue (which means not complete) => complete => rest and then secondary sort by due_date
    // tasks.sort((a, b) => {
    //   if (!a.due_date && b.due_date) {
    //     return 1;
    //   } else if (!b.due_date && a.due_date) {
    //     return -1;
    //   } else if (!a.due_date && !b.due_date) {
    //     return 0;
    //   }
    //   return moment(a.due_date).startOf('day').diff(moment(b.due_date).startOf('day'));
    // });
    tasks.sort((a, b) => {
      return a.display_order_hash >= b.display_order_hash ? 1 : -1;
    });

    return tasks;
  }
}

/**
 * TasksFilter is used by the TasksFilterPipe. These are settings and data used by the pipe to filter out... the tasks.
 */
export class TasksFilter {
  private showForUserIds: number[] =
    (JSON.parse(localStorage.getItem('preferences')) &&
      JSON.parse(localStorage.getItem('preferences')).filter_task_by_user) ||
    [];

  private hideCompleted =
    JSON.parse(localStorage.getItem('preferences')) && JSON.parse(localStorage.getItem('preferences')).filter_complete;

  private approvalsOnly =
    JSON.parse(localStorage.getItem('preferences')) && JSON.parse(localStorage.getItem('preferences')).approvals_only;

  private searchTerm = '';

  private filterByDate = true;

  public toggleHideCompleted() {
    localStorage.setItem('preferences', JSON.stringify(this.addToPreferences('filter_complete', !this.hideCompleted)));
    this.hideCompleted = !this.hideCompleted;
  }

  public toggleApprovalsOnly() {
    localStorage.setItem('preferences', JSON.stringify(this.addToPreferences('approvals_only', !this.approvalsOnly)));
    this.approvalsOnly = !this.approvalsOnly;
  }

  public addUserIdTaskFilter(userId: number) {
    // replace current Ids
    this.showForUserIds = [userId];

    localStorage.setItem(
      'preferences',
      JSON.stringify(this.addToPreferences('filter_task_by_user', this.showForUserIds))
    );
  }

  public removeUserIdTasksFilter() {
    this.showForUserIds = [];
    localStorage.setItem(
      'preferences',
      JSON.stringify(this.addToPreferences('filter_task_by_user', this.showForUserIds))
    );
  }

  public updateSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
  }

  public getShowForUserIds(): number[] {
    return this.showForUserIds;
  }

  public shownForUserId(userId: number): boolean {
    return this.showForUserIds.includes(userId);
  }

  public getForHideCompleted(): boolean {
    return this.hideCompleted;
  }

  public getForApprovalsOnly(): boolean {
    return this.approvalsOnly;
  }

  public getSearchTerm(): string {
    return this.searchTerm;
  }

  public assignedUser(firstName: string, lastName: string) {
    const fullName = firstName + lastName;
    const searchTerm = this.searchTerm.replace(/\s/g, '');
    return fullName && fullName.toLowerCase().includes(searchTerm.toLowerCase());
  }

  private addToPreferences(key: string, value: any) {
    const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
    preferences[key] = value;

    return preferences;
  }
}

<div
  #mainScreen
  (window:resize)="onResize($event)"
  id="bid-packages"
  data-test-id="bid-packages"
  class="p-4 bid-packages"
>
  <div class="d-flex align-items-center full-width p-1 br-5 bg-shade-gray mb-4">
    <button [routerLink]="['/projects/' + project?.id + '/peb']" mat-button color="primary" class="flex-grow-1 mr-1">
      PEB
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/bids']"
      mat-button
      color="primary"
      class="flex-grow-1 bg-white mx-1"
    >
      Bid Packages
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/construction-budget']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Construction Budget
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/proposal-requests']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Proposal Requests
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/change-orders']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Change Orders
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/invoices']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Invoices
    </button>
    <button [routerLink]="['/projects/' + project?.id + '/budget']" mat-button color="primary" class="flex-grow-1 ml-1">
      Budget Overview
    </button>
  </div>
  <div class="d-flex full-width align-items-top mt-1 border-bottom border-gray mb-4">
    <div class="d-flex flex-column mr-auto">
      <h4 class="dkblue">Bid Packages</h4>
      <p class="dkgray">View and manage bid packages and contracts from here.</p>
    </div>
    <mat-form-field appearance="outline" class="ml-3">
      <mat-select
        [formControl]="selectedBidFilters"
        multiple
        (selectionChange)="bidFilterChanged()"
        placeholder="Filter Bid Packages"
      >
        <mat-option *ngFor="let bidFilter of bidFilters" [value]="bidFilter"> {{ bidFilter | titlecase }} </mat-option>
        <button (click)="removeBidFilters()" mat-button color="accent" class="w-100 py-2" type="button">
          Show All Bid Packages
        </button>
      </mat-select>
      <mat-icon class="dkblue" matPrefix>filter_list</mat-icon>
    </mat-form-field>
    <div>
      <mat-form-field appearance="outline" class="ml-3">
        <mat-icon class="dkblue" matPrefix>search</mat-icon>
        <input matInput placeholder="Search Bid Packages" [formControl]="searchTerm" />
        <mat-icon *ngIf="searchTerm.value" (click)="clearSearchTerm()" class="gray pointer" matSuffix>cancel</mat-icon>
      </mat-form-field>
      <button mat-flat-button color="success" class="ml-3" (click)="updateBidPackage()" *ngIf="isWorkspaceStaff">
        + Bid Package
      </button>
    </div>
    <!-- TODO: PEB | Adam - Where should this statusText go? -->
    <!-- {{ statusText }} -->
  </div>
  <div class="position-absolute pr-5 overflow-scroll" style="width: calc(100% - 400px); height: 100%">
    <!-- <div class="d-flex align-items-center mt-1 mb-4">
      <h4 class="m-0 dkblue font-weight-semibold mr-auto">Bid Packages</h4>
      <mat-form-field appearance="outline" class="ml-3">
        <mat-icon class="dkblue" matPrefix>search</mat-icon>
        <input matInput placeholder="Search Bid Packages" [formControl]="searchTerm" />
        <mat-icon *ngIf="searchTerm.value" (click)="clearSearchTerm()" class="gray pointer" matSuffix>cancel</mat-icon>
      </mat-form-field>
      <button mat-flat-button color="success" class="ml-3" (click)="updateBidPackage()" *ngIf="isStaff()">
        + Bid Package
      </button>
    </div> -->
    <div class="row">
      <div class="col">
        <div class="row" *ngIf="bidPackages?.length === 0">
          <h4 class="col-12 gray text-center font-weight-semibold">There are no current bid packages to view.</h4>
        </div>

        <!--If filtering returns 0-->
        <div class="row" *ngIf="bidPackages?.length && !filteredDisplayBidPackagesLength">
          <h4 class="col-12 gray text-center font-weight-semibold">The bid filter returned no bid packages to view.</h4>
        </div>

        <div *ngFor="let bidTypes of filteredDisplayBidPackages; let last = last">
          <div
            *ngFor="let p of bidTypes | filter: searchTerm.value:searchFields | orderByField: 'number':'asc'"
            class="bid-package-portlet m-portlet p-0 mb-4 col-12"
            m-portlet="true"
          >
            <div
              class="m-portlet__body item-no-hover p-3 d-flex align-items-center"
              [ngClass]="{ 'item-last': !p.showBids }"
            >
              <button mat-icon-button (click)="toggleBidView(p)" color="primary" type="button" class="mr-2">
                <mat-icon>{{ p.showBids ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
              </button>
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2">
                  <h5
                    (click)="toggleBidView(p)"
                    class="mb-0 bp-trade text-wrap dkblue font-weight-semibold hover-u pointer mr-2"
                  >
                    {{ p.number }} - {{ p.trade_name }}
                  </h5>
                  <mat-icon *ngIf="p.has_re_bid" class="ltblue pointer" [matMenuTriggerFor]="reBidMessage">
                    sync
                  </mat-icon>
                  <mat-menu class="p-3 mt-2" #reBidMessage="matMenu" xPosition="after">
                    <div class="d-flex flex-column">
                      <i class="ltblue mb-2 material-icons">sync</i>
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold">{{ p.trade_name }} was rebid</p>
                        <span class="m-0 dkgray font-weight-normal" [innerHTML]="p.re_bid_message"> </span>
                      </div>
                    </div>
                  </mat-menu>
                  <mat-icon *ngIf="p.awardedBidCompanyId && !p.child_request?.id && !p.child_project?.id" class="green">
                    verified
                  </mat-icon>
                  <mat-icon *ngIf="p.child_request?.id || p.child_project?.id" class="ltblue">
                    {{ p.child_project?.id ? 'assignment_turned_in' : 'assignment_late' }}
                  </mat-icon>
                </div>
                <p class="dkgray m-0">
                  {{ p.square_footage | number }} SF • {{ p.cost_per_sqft | USDollar }}/SF •
                  {{ p.budget_amount | USDollar }} PEB
                </p>
              </div>
              <div class="d-flex ml-auto align-items-center">
                <h6
                  *ngIf="
                    !p.awardedBidCompanyId &&
                    isWorkspaceStaff &&
                    p.trade_allows_bids &&
                    !p.child_request?.id &&
                    !p.child_project?.id
                  "
                  class="m-0 font-weight-semibold dkblue"
                >
                  {{ p.companies ? p.companies.length : 0 }}
                  {{ p.companies && p.companies.length !== 1 ? 'Bids' : 'Bid' }}
                </h6>
                <h6
                  *ngIf="!p.trade_allows_bids && !p.child_request?.id && !p.child_project?.id"
                  class="m-0 dkblue font-weight-semibold d-inline-block align-middle"
                >
                  Bids N/A
                </h6>
                <button
                  *ngIf="
                    !p.awardedBidCompanyId &&
                    isWorkspaceStaff &&
                    p.trade_allows_bids &&
                    !p.child_request?.id &&
                    !p.child_project?.id
                  "
                  (click)="openDraftBid(p)"
                  mat-flat-button
                  color="primary"
                  type="button"
                  class="ml-4"
                >
                  Add Bid
                </button>
                <div
                  *ngIf="
                    (p.awardedBidCompanyId && isWorkspaceStaff && p.trade_allows_bids) ||
                    p.child_request?.id ||
                    p.child_project?.id
                  "
                  class="d-flex flex-column align-items-end ml-4"
                >
                  <h5 class="dkblue mb-1 font-weight-bold" *ngIf="!p.child_request?.id && !p.child_project?.id">
                    {{ p.awarded_bid_company && p.awarded_bid_company.selectedBidsTotal | USDollar }}
                  </h5>
                  <h5 class="dkblue mb-1 font-weight-bold" *ngIf="p.child_request?.id || p.child_project?.id">
                    {{ p.child_project?.budget_data?.awardedBidTotal || 0 | USDollar }}
                  </h5>
                  <p
                    class="font-weight-normal dkblue font-italic m-0"
                    *ngIf="!p.child_request?.id && !p.child_project?.id"
                  >
                    {{ contractStatus(p) }}
                  </p>
                  <a
                    target="_blank"
                    [routerLink]="
                      p.child_project?.id ? '/projects/' + p.child_project?.id : '/requests/' + p.child_request?.id
                    "
                    href="#"
                    class="text-decoration-none ltblue pointer"
                    *ngIf="p.child_request?.id || p.child_project?.id"
                  >
                    <p class="font-weight-normal m-0 d-flex align-items-center" *ngIf="p.child_project?.id">
                      <mat-icon class="mr-2 mat-small-icon">assignment_turned_in</mat-icon>
                      <span class="hover-u"> PJ-{{ p.child_project?.code }} | {{ p.child_project?.title }} </span>
                    </p>
                    <p
                      class="font-weight-normal m-0 d-flex align-items-center"
                      *ngIf="!p.child_project?.id && p.child_request?.id"
                    >
                      <mat-icon class="mr-2 mat-small-icon">assignment_late</mat-icon>
                      <span class="hover-u">
                        {{ p.child_request?.code }} | {{ p.child_request?.short_description }}
                      </span>
                    </p>
                  </a>
                </div>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="bid_package_main_menu"
                  color="primary"
                  type="button"
                  class="ml-4"
                  *ngIf="isWorkspaceStaff && !p.editable"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #bid_package_main_menu="matMenu" xPosition="before">
                  <button
                    *ngIf="
                      !p.awardedBidCompanyId &&
                      isWorkspaceStaff &&
                      p.trade_allows_bids &&
                      !p.child_request?.id &&
                      !p.child_project?.id
                    "
                    mat-menu-item
                    (click)="openRebidDialog(p)"
                  >
                    <mat-icon> sync </mat-icon>
                    <span> Rebid {{ p.trade_name }}</span>
                  </button>
                  <!-- <a
                    *ngIf="
                      isAdmin &&
                      !p.child_request?.id &&
                      !p.child_project?.id &&
                      +p.companies?.length === 0
                    "
                    mat-menu-item
                    href="#"
                    routerLink="/quick-request"
                    [queryParams]="{ linkedBidPackageId: p.id }"
                    target="_blank"
                  >
                    <mat-icon> assignment_turned_in </mat-icon>
                    <span> Link To Project </span>
                  </a> -->
                  <button *ngIf="isAdmin" mat-menu-item (click)="updateBidPackage(p)">
                    <mat-icon> edit </mat-icon>
                    <span> Edit Bid Package </span>
                  </button>
                  <button
                    *ngIf="isWorkspaceStaff && p.awardedBidCompanyId"
                    mat-menu-item
                    (click)="toggleContractView(p)"
                  >
                    <mat-icon> {{ p.contractView ? 'request_quote' : 'history_edu' }} </mat-icon>
                    <span> {{ p.contractView ? 'View Bids' : 'View Contracts' }} </span>
                  </button>
                  <button
                    *ngIf="isAdmin && p.awardedBidCompanyId && !p.child_request?.id && !p.child_project?.id"
                    (click)="updateBidAward(p, p.awarded_bid_company, 'unaward')"
                    mat-menu-item
                    color="primary"
                  >
                    <mat-icon>undo</mat-icon>
                    <span> Undo Award </span>
                  </button>
                  <button
                    *ngIf="
                      p.awardedBidCompanyId &&
                      !p.child_request?.id &&
                      !p.child_project?.id &&
                      p.awarded_bid_company?.selectedBidsTotal > 24999.99
                    "
                    mat-menu-item
                    (click)="openSolicitationDialog(null, p, p.awarded_bid_company)"
                  >
                    <mat-icon> sync </mat-icon>
                    <span> {{ isAdmin ? 'Edit' : 'View' }} Solicitation</span>
                  </button>
                  <button
                    *ngIf="isAdmin && !p.child_request?.id && !p.child_project?.id"
                    mat-menu-item
                    (click)="removeBidPackage(p)"
                  >
                    <mat-icon> delete </mat-icon>
                    <span> Remove Bid Package </span>
                  </button>
                </mat-menu>
              </div>
            </div>

            <div *ngIf="p.showBids">
              <div
                class="m-portlet__body pl-4 py-2 pr-3 bg-shade-ltblue border-bottom border-gray"
                *ngIf="p.companies?.length && !p.child_request?.id && !p.child_project?.id"
              >
                <div class="row">
                  <div class="col">
                    <h6 class="m-0 dkblue font-weight-semibold">
                      {{ p.contractView ? 'Awarded Bid' : 'Supplier Name' }}
                    </h6>
                  </div>
                  <div class="col" [ngClass]="{ 'text-right': !p.contractView }">
                    <h6 class="m-0 dkblue font-weight-semibold">
                      {{
                        p.contractView
                          ? p.manually_add_contract || p.contract_task_id || p.contract_id || p.trade?.is_consultant
                            ? 'Supplier Contract'
                            : 'Contract'
                          : 'Base Bid'
                      }}
                    </h6>
                  </div>
                  <div class="col" [ngClass]="{ 'text-right': !p.contractView }">
                    <h6 class="m-0 dkblue font-weight-semibold">
                      {{
                        p.contractView
                          ? p.manually_add_contract || p.contract_task_id || p.contract_id || p.trade?.is_consultant
                            ? 'Executed Contract'
                            : 'Timeline'
                          : 'Alt Bids'
                      }}
                    </h6>
                  </div>
                  <div class="col" [ngClass]="{ 'text-right': !p.contractView }">
                    <h6 class="m-0 dkblue font-weight-semibold">{{ p.contractView ? 'Submittals' : 'Total Bid' }}</h6>
                  </div>
                  <div *ngIf="!p.contractView" class="col"></div>
                </div>
              </div>
              <section *ngIf="p.companies?.length && !p.child_request?.id && !p.child_project?.id">
                <div
                  *ngFor="let c of p.companies; index as cindex"
                  [hidden]="p.contractView && p.awardedBidCompanyId !== c.id"
                  class="m-portlet__body bg-ltltgray p-0 ease"
                >
                  <div *ngIf="!p.contractView" class="d-flex flex-column item">
                    <div class="row align-items-center pl-4 py-2 pr-3">
                      <div class="col d-flex align-items-center" (click)="openDraftBid(p, c, true)">
                        <p class="m-0 ltblue text-underline font-weight-semibold pointer mr-3">{{ c.name }}</p>
                      </div>
                      <div class="col text-right">
                        <p class="dkblue m-0">{{ c.baseBidTotal | USDollar }}</p>
                      </div>
                      <div class="col text-right">
                        <p class="dkblue m-0">{{ c.altBidTotal | USDollar }}</p>
                      </div>
                      <div class="col d-flex align-items-center justify-content-end">
                        <mat-icon *ngIf="p.awardedBidCompanyId === c.id" class="green mr-2"> verified </mat-icon>
                        <p class="dkblue m-0 font-weight-bold">
                          {{ c.selectedBids[0].did_not_bid ? 'DNB' : (c.selectedBidsTotal | USDollar) }}
                        </p>
                      </div>
                      <div class="col text-right" *ngIf="isWorkspaceStaff">
                        <button
                          mat-button
                          *ngIf="
                            !p.awardedBidCompanyId && !c.is_voided && !c.selectedBids[0]?.did_not_bid && !canAward(c, p)
                          "
                          (click)="openDraftBid(p, c, true)"
                          color="warn"
                          type="button"
                          class="lh-26 px-3"
                        >
                          Cannot Award
                        </button>
                        <button
                          mat-flat-button
                          color="success"
                          type="button"
                          *ngIf="
                            !p.awardedBidCompanyId && !c.is_voided && !c.selectedBids[0]?.did_not_bid && canAward(c, p)
                          "
                          (click)="updateBidAward(p, c, 'award')"
                          [disabled]="p.awardedBidCompanyId"
                          class="lh-26 ml-2 px-3"
                        >
                          Award
                        </button>
                        <button
                          mat-flat-button
                          color="warn"
                          *ngIf="c.is_voided"
                          disabled
                          type="button"
                          class="lh-26 px-3"
                        >
                          Void
                        </button>
                        <i
                          *ngIf="p.awardedBidCompanyId && p.awarded_bid_comment && p.awardedBidCompanyId === c.id"
                          [matMenuTriggerFor]="reviewOrderHelp"
                          class="red material-icons d-inline-block align-middle pointer ml-3"
                          >warning</i
                        >
                        <mat-menu class="p-3 mt-2" #reviewOrderHelp="matMenu" xPosition="before">
                          <div class="d-flex px-2">
                            <i class="red mr-3 material-icons">warning</i>
                            <div class="d-flex flex-column">
                              <p class="m-0 dkblue font-weight-semibold">Lowest bid was not accepted</p>
                              <span class="m-0 dkblue font-weight-normal" [innerHTML]="p.awarded_bid_comment"> </span>
                            </div>
                          </div>
                        </mat-menu>
                        <button
                          mat-icon-button
                          [matMenuTriggerFor]="bid_main_menu"
                          color="primary"
                          type="button"
                          class="ml-2"
                          *ngIf="isWorkspaceStaff"
                        >
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #bid_main_menu="matMenu" xPosition="before">
                          <button
                            *ngIf="isAdmin && p.awardedBidCompanyId === c.id"
                            (click)="updateBidAward(p, c, 'unaward')"
                            mat-menu-item
                            color="primary"
                          >
                            <mat-icon>undo</mat-icon>
                            <span> Undo Award </span>
                          </button>
                          <button
                            mat-menu-item
                            color="primary"
                            *ngIf="!p.awardedBidCompanyId && isAdmin"
                            (click)="openDraftBid(p, c)"
                          >
                            <mat-icon>edit</mat-icon>
                            <span> Edit Bid </span>
                          </button>
                          <button
                            mat-menu-item
                            color="primary"
                            *ngIf="p.awardedBidCompanyId"
                            (click)="openDraftBid(p, c, true)"
                          >
                            <mat-icon>preview</mat-icon>
                            <span> View Bid </span>
                          </button>
                          <button
                            mat-menu-item
                            color="primary"
                            *ngIf="!p.awardedBidCompanyId || p.awardedBidCompanyId !== c.id"
                            (click)="updateCompanyVoid(c, c.is_voided ? 'unvoid' : 'void')"
                          >
                            <mat-icon>block</mat-icon>
                            <span> {{ c.is_voided ? 'Unvoid' : 'Void' }} Bids </span>
                          </button>
                          <button mat-menu-item (click)="deleteCompanyBids(p, c)" *ngIf="p.awardedBidCompanyId != c.id">
                            <mat-icon>delete</mat-icon>
                            <span> Delete Bid </span>
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="p.contractView" class="m-portlet__body p-4 ease">
                    <div class="row align-items-top">
                      <div class="col d-flex flex-column">
                        <h5
                          class="m-0 ltblue font-weight-semibold pointer mr-3 mb-2 align-items-start d-flex"
                          (click)="openDraftBid(p, c, true)"
                        >
                          <span class="hover-u"> {{ c.name }} {{ c.is_msa_agreement }} </span>
                          <mat-icon *ngIf="c.verified === 1" class="green ml-2 small-icon">verified</mat-icon>
                          <h6
                            *ngIf="c.selectedBids[0].is_msa_agreement"
                            class="px-1 d-inline-block lh-18 bg-gold br-4 white white m-0"
                          >
                            MSA
                          </h6>
                        </h5>
                        <span class="d-flex align-items-center mb-4">
                          <app-profile-thumbnail
                            (click)="openUserProfilePreview(c.contact_id)"
                            class="mr-2"
                            [noBorder]="true"
                            [height]="24"
                            [width]="24"
                            [userId]="c.contact_id"
                            [overlayIconOpacity]="1"
                            [overlayIcon]="c.contact?.is_login_enabled === 0 ? 'priority_high' : ''"
                            [overlayIconColor]="'white'"
                            [overlayIconBackground]="'bg-red'"
                            [overlayIconTooltip]="'Account Pending'"
                          ></app-profile-thumbnail>
                          <p class="dkblue m-0 font-weight-semibold text-wrap">
                            <span (click)="openUserProfilePreview(c.contact_id)" class="mr-1 hover-u pointer">
                              {{ c.contact?.first_name }} {{ c.contact?.last_name }}
                            </span>
                            <small
                              (click)="changeCompanyContact(c, p.contract_task_id)"
                              *ngIf="!c.selectedBids[0].bid_contract?.vendor_signed_datetime"
                              class="hover-u accent pointer"
                            >
                              Change
                            </small>
                          </p>
                        </span>
                        <h5 class="m-0 dkblue font-weight-bold mb-1 mt-3">
                          {{ c.selectedBids[0].did_not_bid ? 'DNB' : (c.selectedBidsTotal | USDollar) }}
                        </h5>
                        <span class="d-flex align-items-center mb-0" *ngIf="c.selectedBids[0].is_awarded">
                          <mat-icon class="small-icon green mr-1">verified</mat-icon>
                          <p class="dkgray m-0 font-weight-normal">
                            {{ (c.selectedBids[0].awarded_datetime | date: 'MMM dd YYYY') ?? 'No Awarded Date' }}
                          </p>
                        </span>
                      </div>

                      <div
                        class="col d-flex flex-column"
                        *ngIf="
                          c.selectedBids[0].is_awarded &&
                          (p.manually_add_contract || p.contract_task_id || p.contract_id || p.trade?.is_consultant)
                        "
                      >
                        <div class="d-flex flex-column mb-3">
                          <app-split-button
                            *ngIf="p.awardedBidCompanyId && !p.contract_task_id && !p.contract_id"
                            lineHeight="24"
                            [matMenu]="vendorMenu"
                            (clickEvent)="createBidTask(p, 'contract_task_id')"
                            [disabled]="
                              !p.trade?.is_consultant &&
                              (!project?.tenants?.length || finalizedCBs !== project?.tenants?.length)
                            "
                          >
                            Create Task
                          </app-split-button>
                          <mat-menu #vendorMenu="matMenu" xPosition="before">
                            <button mat-menu-item (click)="openContractFileUpload(p, false)">
                              <mat-icon>attach_file</mat-icon>
                              <span> Attach File </span>
                            </button>
                          </mat-menu>
                          <app-open-task-button
                            *ngIf="p.awardedBidCompanyId && p.contract_task_id && !p.contract_id"
                            [taskId]="p.contract_task_id"
                            lineHeight="28"
                          ></app-open-task-button>
                          <app-file-chip
                            *ngIf="p.contract_id && p.awardedBidCompanyId"
                            [file]="{ id: p.contract_id, name: p.contract_name }"
                            [removable]="isAdmin"
                            (removeEvent)="removeContractFile(p, false)"
                          ></app-file-chip>
                          <p
                            *ngIf="
                              !p.trade?.is_consultant &&
                              (!project?.tenants?.length || finalizedCBs !== project?.tenants?.length) &&
                              p.awardedBidCompanyId &&
                              !p.contract_task_id &&
                              !p.contract_id
                            "
                            class="red d-flex align-items-center mb-0 mt-1"
                          >
                            <small>CB must be Finalized</small>
                          </p>
                        </div>
                      </div>
                      <div
                        class="col d-flex flex-column"
                        *ngIf="
                          c.selectedBids[0].is_awarded &&
                          !p.manually_add_contract &&
                          !p.contract_task_id &&
                          !p.contract_id &&
                          !p.trade?.is_consultant
                        "
                      >
                        <div class="d-flex flex-wrap mb-2">
                          <button
                            *ngIf="!c.selectedBids[0].bid_contract"
                            mat-flat-button
                            color="primary"
                            (click)="openEditContractDialog(c.selectedBids[0].bid_contract ?? null, c.selectedBids[0])"
                            [ngClass]="{
                              'bg-gold white':
                                !c.selectedBids[0].bid_contract &&
                                project?.tenants?.length &&
                                finalizedCBs === project?.tenants?.length,
                              'bg-ltgray gray white':
                                !c.selectedBids[0].bid_contract &&
                                project?.tenants?.length &&
                                finalizedCBs !== project?.tenants?.length,
                              gold: c.selectedBids[0].bid_contract
                            }"
                            class="lh-30 px-3 mr-3"
                            [disabled]="
                              !project?.tenants?.length ||
                              (finalizedCBs !== project?.tenants?.length && !c.selectedBids[0].bid_contract?.id)
                            "
                          >
                            Create Contract
                          </button>
                          <button
                            *ngIf="c.selectedBids[0].bid_contract"
                            mat-stroked-button
                            color="primary"
                            (click)="openEditContractDialog(c.selectedBids[0].bid_contract ?? null, c.selectedBids[0])"
                            class="lh-30 px-3 mr-3 mt-1"
                            [ngClass]="{
                              'bg-gold white':
                                c.selectedBids[0].bid_contract.status_id === BidContractStatus.RevisionRequested,
                              'gold bg-white':
                                c.selectedBids[0].bid_contract.status_id !== BidContractStatus.RevisionRequested
                            }"
                            [disabled]="
                              finalizedCBs !== project?.tenants?.length && !c.selectedBids[0].bid_contract?.id
                            "
                          >
                            {{
                              c.selectedBids[0].bid_contract.status_id === BidContractStatus.RevisionRequested
                                ? 'Resend'
                                : 'View'
                            }}
                            Contract
                          </button>
                          <app-open-task-button
                            *ngIf="c.selectedBids[0].contract_task_id"
                            [taskId]="c.selectedBids[0].contract_task_id"
                            lineHeight="30"
                            class="mt-1"
                          ></app-open-task-button>
                          <!-- <p
                            *ngIf="c.selectedBids[0].contract_task_id"
                            mat-button
                            class="font-weight-semibold hover-u mb-0 lh-30 ltblue"
                            type="button"
                            (click)="openTask(c.selectedBids[0].contract_task_id)"
                          >
                            View Task
                          </p> -->
                        </div>
                        <p
                          *ngIf="
                            !p.trade?.is_consultant &&
                            (!project?.tenants?.length || finalizedCBs !== project?.tenants?.length) &&
                            p.awardedBidCompanyId &&
                            !p.manually_add_contract &&
                            !p.contract_task_id &&
                            !p.contract_id
                          "
                          class="red d-flex align-items-center mb-0"
                        >
                          <small>CB must be Finalized</small>
                        </p>
                        <p
                          *ngIf="c.selectedBids[0]?.bid_contract?.sent_to_vendor_datetime"
                          class="dkblue font-weight-semibold d-flex align-items-center lh-22 m-0"
                        >
                          <span class="dkblue mr-2"> Sent To Supplier </span>
                          <span class="dkgray mr-2" *ngIf="c.selectedBids[0]?.bid_contract?.sent_to_vendor_datetime">
                            {{ c.selectedBids[0].bid_contract.sent_to_vendor_datetime | date: 'MMM dd YYYY' }}
                          </span>
                        </p>
                        <p
                          *ngIf="c.selectedBids[0]?.bid_contract?.vendor_signed_datetime"
                          class="dkblue font-weight-semibold d-flex align-items-center lh-22 m-0"
                        >
                          <span class="dkblue mr-2"> Supplier Signed </span>
                          <span
                            class="dkgray mr-2"
                            *ngIf="
                              c.selectedBids[0]?.bid_contract?.vendor_signed_datetime &&
                              c.selectedBids[0]?.bid_contract?.status_id !== 2
                            "
                          >
                            {{ c.selectedBids[0].bid_contract.vendor_signed_datetime | date: 'MMM dd YYYY' }}
                          </span>
                        </p>
                        <p
                          *ngIf="c.selectedBids[0]?.bid_contract?.executed_datetime"
                          class="dkblue font-weight-semibold lh-22 m-0 d-flex align-items-center"
                        >
                          <span class="dkblue mr-2"> Executed </span>
                          <span class="dkgray mr-2" *ngIf="c.selectedBids[0]?.bid_contract?.executed_datetime">
                            {{ c.selectedBids[0].bid_contract.executed_datetime | date: 'MMM dd YYYY' }}
                          </span>
                          <mat-icon class="gold small-icon" *ngIf="c.selectedBids[0]?.bid_contract?.executed_datetime">
                            verified</mat-icon
                          >
                        </p>
                        <p
                          *ngIf="
                            c.selectedBids[0].contract_task?.status_id === 3 && !p.not_awarded_email_sent && isAdmin
                          "
                          (click)="sendVendorEmail(p)"
                          class="accent mb-0 pointer hover-u lh-22"
                        >
                          Notify Non-Winning Suppliers
                          <i class="fas fa-arrow-right"></i>
                        </p>
                        <p
                          *ngIf="
                            c.selectedBids[0].contract_task?.status_id === 3 && p.not_awarded_email_sent && isAdmin
                          "
                          class="dkgray mb-0 lh-22"
                        >
                          Non-winning Suppliers Notified
                          <span (click)="sendVendorEmail(p)" class="ml-2 accent hover-u pointer"> Send Again </span>
                        </p>
                      </div>

                      <div
                        class="col"
                        *ngIf="
                          c.selectedBids[0].is_awarded &&
                          (p.manually_add_contract || p.contract_task_id || p.contract_id || p.trade?.is_consultant)
                        "
                      >
                        <app-split-button
                          *ngIf="p.awardedBidCompanyId && !p.executed_contract_task_id && !p.executed_contract_id"
                          lineHeight="24"
                          [matMenu]="menu"
                          (clickEvent)="createBidTask(p, 'executed_contract_task_id')"
                          [disabled]="!p.contract_id && p.contract_task?.status_id !== 3"
                        >
                          Create Task
                        </app-split-button>
                        <mat-menu #menu="matMenu" xPosition="before">
                          <button
                            mat-menu-item
                            (click)="openContractFileUpload(p, true)"
                            [disabled]="!p.contract_id && p.contract_task?.status_id !== 3"
                          >
                            <mat-icon>attach_file</mat-icon>
                            <span> Attach File </span>
                          </button>
                        </mat-menu>
                        <app-open-task-button
                          *ngIf="p.awardedBidCompanyId && p.executed_contract_task_id && !p.executed_contract_id"
                          [taskId]="p.executed_contract_task_id"
                          lineHeight="28"
                        ></app-open-task-button>
                        <app-file-chip
                          *ngIf="p.executed_contract_id"
                          [file]="{ id: p.executed_contract_id, name: p.executed_contract_name }"
                          [removable]="isAdmin"
                          (removeEvent)="removeContractFile(p)"
                        ></app-file-chip>
                        <button
                          class="ml-3 lh-28 px-3"
                          mat-flat-button
                          type="button"
                          color="primary"
                          *ngIf="p.executed_contract_task_id && !p.executed_contract_id"
                          [disabled]="p.executed_contract_task?.status_id !== 3"
                          (click)="openContractFilesDialog(p.id, p.executed_contract_task?.id, true)"
                        >
                          <span *ngIf="p.executed_contract_task?.status_id === 3" class="pulse-after-dot-success">
                          </span>
                          Select Contract
                        </button>
                      </div>
                      <div
                        class="col"
                        *ngIf="
                          c.selectedBids[0].is_awarded &&
                          !p.manually_add_contract &&
                          !p.contract_task_id &&
                          !p.contract_id &&
                          !p.trade?.is_consultant
                        "
                      >
                        <p
                          *ngIf="!c.selectedBids[0].timeline_task_id && !project?.project_schedule_is_not_applicable"
                          class="dkblue m-0"
                        >
                          Attach Construction Schedule to the right to continue.
                        </p>
                        <app-open-task-button
                          *ngIf="c.selectedBids[0].timeline_task_id && !project?.project_schedule_is_not_applicable"
                          [taskId]="c.selectedBids[0].timeline_task_id"
                        ></app-open-task-button>
                        <div *ngIf="project.project_schedule_is_not_applicable">
                          Project Schedule file is not available
                        </div>
                      </div>

                      <div class="col d-flex align-items-top">
                        <div class="mr-auto" *ngIf="c.selectedBids[0].is_awarded">
                          <button
                            mat-stroked-button
                            class="bg-white lh-30 px-3"
                            color="accent"
                            type="button"
                            (click)="openAddSubmittalsDialog(p)"
                            *ngIf="!p.submittal_task_id"
                            [disabled]="
                              !isAdmin || (!p.executed_contract_id && c.selectedBids[0].contract_task?.status_id !== 3)
                            "
                          >
                            Request Submittals
                          </button>
                          <p
                            *ngIf="
                              !p.submittal_task_id &&
                              isAdmin &&
                              !p.executed_contract_id &&
                              c.selectedBids[0].contract_task?.status_id !== 3
                            "
                            class="red d-flex align-items-center mb-0 mt-1"
                          >
                            <small *ngIf="c.selectedBids[0]?.bid_contract?.status_id !== 4"
                              >Pending Contract in Progress</small
                            >
                            <small *ngIf="c.selectedBids[0]?.bid_contract?.status_id === 4"
                              >Contract Task must be complete</small
                            >
                          </p>
                          <app-open-task-button
                            *ngIf="p.submittal_task_id"
                            [taskId]="p.submittal_task_id"
                            lineHeight="30"
                          ></app-open-task-button>
                          <button
                            mat-stroked-button
                            class="bg-white lh-30 px-3 mt-2"
                            color="accent"
                            type="button"
                            (click)="openAddSubmittalsDialog(p)"
                            *ngIf="p.submittal_task_id && isAdmin"
                          >
                            Edit Submittals
                          </button>
                        </div>
                        <button
                          mat-icon-button
                          [matMenuTriggerFor]="bid_main_menu"
                          color="primary"
                          type="button"
                          class="ml-2"
                          *ngIf="isWorkspaceStaff"
                        >
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #bid_main_menu="matMenu" xPosition="before">
                          <button
                            *ngIf="isAdmin && p.awardedBidCompanyId === c.id"
                            (click)="updateBidAward(p, c, 'unaward')"
                            mat-menu-item
                            color="primary"
                          >
                            <mat-icon>undo</mat-icon>
                            <span> Undo Award </span>
                          </button>
                          <button
                            mat-menu-item
                            color="primary"
                            *ngIf="!p.awardedBidCompanyId && isAdmin"
                            (click)="openDraftBid(p, c)"
                          >
                            <mat-icon>edit</mat-icon>
                            <span> Edit Bid </span>
                          </button>
                          <button
                            mat-menu-item
                            color="primary"
                            *ngIf="p.awardedBidCompanyId"
                            (click)="openDraftBid(p, c, true)"
                          >
                            <mat-icon>preview</mat-icon>
                            <span> View Bid </span>
                          </button>
                          <button
                            mat-menu-item
                            color="primary"
                            *ngIf="!p.awardedBidCompanyId || p.awardedBidCompanyId !== c.id"
                            (click)="updateCompanyVoid(c, c.is_voided ? 'unvoid' : 'void')"
                          >
                            <mat-icon>block</mat-icon>
                            <span> {{ c.is_voided ? 'Unvoid' : 'Void' }} Bids </span>
                          </button>
                          <button
                            mat-menu-item
                            *ngIf="!p.awardedBidCompanyId || p.awardedBidCompanyId !== c.id"
                            (click)="deleteCompanyBids(p, c)"
                          >
                            <mat-icon>delete</mat-icon>
                            <span> Delete Bid </span>
                          </button>
                          <button
                            *ngIf="
                              p.awardedBidCompanyId &&
                              !p.contract_task_id &&
                              !p.contract_id &&
                              !p.trade?.is_consultant &&
                              (!c.selectedBids[0]?.bid_contract ||
                                [
                                  BidContractStatus.NotSent,
                                  BidContractStatus.SentToVendor,
                                  BidContractStatus.RevisionRequested
                                ].includes(c.selectedBids[0]?.bid_contract?.status_id)) &&
                              !p.child_request?.id &&
                              !p.child_project?.id
                            "
                            (click)="updateContractMethod(p, c.selectedBids[0]?.bid_contract?.id)"
                            mat-menu-item
                            color="primary"
                          >
                            <mat-icon>{{ p.manually_add_contract ? 'history_edu' : 'upload_file' }}</mat-icon>
                            <span>
                              {{ p.manually_add_contract ? 'Use Digital Signatures' : 'Add Contract Manually' }}</span
                            >
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div
                class="m-portlet__body pl-4 py-2 pr-3 bg-shade-ltblue border-bottom border-dkgray"
                *ngIf="p.child_request?.id || p.child_project?.id"
              >
                <div class="row">
                  <div class="col">
                    <h6 class="m-0 dkblue font-weight-semibold">{{ p.child_project?.id ? 'Project' : 'Request' }}</h6>
                  </div>
                  <div class="col-3 text-right" *ngIf="p.child_project?.id">
                    <h6 class="m-0 dkblue font-weight-semibold">Total Cost</h6>
                  </div>
                  <div class="col-3 text-left" *ngIf="p.child_project?.id">
                    <h6 class="m-0 dkblue font-weight-semibold">Last Update</h6>
                  </div>
                </div>
              </div>
              <section *ngIf="p.child_request?.id || p.child_project?.id">
                <div class="m-portlet__body bg-ltltgray p-0 ease">
                  <div class="d-flex flex-column item">
                    <div class="row d-flex align-items-top pl-4 py-4 pr-3" *ngIf="p.child_project?.id">
                      <div class="col d-flex flex-column">
                        <h5 class="m-0 font-weight-semibold mr-3 d-flex align-items-top">
                          <mat-icon class="mr-3 ltblue">assignment_turned_in</mat-icon>
                          <span class="dkgray mr-2 text-nowrap"> PJ-{{ p.child_project?.code }} | </span>
                          <span class="dkgray mr-2 text-nowrap">
                            {{ p.child_project?.building_code }}{{ p.child_project?.building_code ? ' - ' : ''
                            }}{{ p.child_project?.floor_code }} |
                          </span>
                          <span class="dkblue"> {{ p.child_project?.title }} </span>
                        </h5>
                        <div class="d-flex align-items-center pl-5 pt-2">
                          <h6 class="white m-0 px-2 lh-18 br-4 bg-dkblue text-uppercase mr-2" style="font-size: 0.9rem">
                            {{ p.child_project?.module?.name }}
                          </h6>
                          <h6
                            class="white m-0 px-2 lh-18 br-4 text-uppercase mr-2"
                            style="font-size: 0.9rem"
                            [ngClass]="{
                              'bg-green': p.child_project?.status?.id === 1,
                              'bg-ltblue': p.child_project?.status?.id === 2,
                              'bg-red': p.child_project?.status?.id === 3,
                              'bg-gray': p.child_project?.status?.id === 4
                            }"
                          >
                            {{ p.child_project?.status?.name }}
                          </h6>
                          <h6
                            class="white m-0 px-2 lh-18 br-4 text-uppercase mr-2"
                            style="font-size: 0.9rem"
                            [ngClass]="{
                              'bg-dkred': p.child_project?.priority?.id === 4,
                              'bg-red': p.child_project?.priority?.id === 3,
                              'bg-ltblue': p.child_project?.priority?.id === 2,
                              'bg-ltgray': p.child_project?.priority?.id === 1
                            }"
                          >
                            {{ p.child_project?.priority?.name }}
                          </h6>
                          <h6 class="dkblue m-0 px-2 lh-18 br-4 mr-2" style="font-size: 0.9rem">
                            <span class="mr-2"> ECD: </span>
                            <span class="mr-2 dkgray">{{
                              p.child_project?.end_date
                                ? (p.child_project?.end_date | date: 'MMM d yyyy')
                                : 'Not
                              Set'
                            }}</span>
                          </h6>
                          <h6 class="dkblue m-0 px-2 lh-18 br-4 mr-2" style="font-size: 0.9rem">
                            <span class="mr-2"> PM: </span>
                            <span class="mr-2 dkgray" *ngIf="p.child_project?.project_manager?.id"
                              >{{ p.child_project?.project_manager?.first_name }}
                              {{ p.child_project?.project_manager?.last_name }}</span
                            >
                            <span class="mr-2 dkgray" *ngIf="!p.child_project?.project_manager?.id">Not Set</span>
                          </h6>
                        </div>
                      </div>
                      <div class="col-auto pl-5 d-flex justify-content-end">
                        <h5 class="dkblue m-0 font-weight-bold">
                          {{ p.child_project?.budget_data?.awardedBidTotal || 0 | USDollar }}
                        </h5>
                      </div>
                      <div class="col-3 d-flex align-items-top">
                        <div class="mr-auto">
                          <p class="m-0 font-weight-semibold gray" *ngIf="!p.child_project?.latestUpdate">No Updates</p>
                          <p
                            (click)="$event.stopPropagation()"
                            [matMenuTriggerFor]="projectUpdateMenu"
                            class="m-0 font-weight-semibold ltblue hover-u d-block"
                            *ngIf="p.child_project?.latestUpdate"
                          >
                            {{ p.child_project?.latestUpdate.created_datetime | date: 'EEE, MMM d yyyy' }}
                          </p>
                          <p
                            class="m-0 font-weight-normal font-italic dkgray"
                            style="font-size: 80%"
                            *ngIf="p.child_project?.latestUpdate && p.child_project?.latestUpdate.created_by"
                          >
                            By {{ p.child_project?.latestUpdate.created_by.first_name }}
                            {{ p.child_project?.latestUpdate.created_by.last_name }}
                          </p>
                          <mat-menu #projectUpdateMenu="matMenu" xPosition="before">
                            <div class="d-flex py-3 px-4" *ngIf="p.child_project?.latestUpdate">
                              <app-profile-thumbnail
                                class="mr-3"
                                [noBorder]="true"
                                [height]="30"
                                [width]="30"
                                [userId]="p.child_project?.latestUpdate.created_by?.id"
                              ></app-profile-thumbnail>
                              <div class="flex-grow-1">
                                <div class="d-flex align-items-start mb-3">
                                  <div class="d-flex flex-column mr-4">
                                    <h6 class="mb-0 dkblue font-weight-semibold text-nowrap">
                                      {{ p.child_project?.latestUpdate.created_by?.first_name }}
                                      {{ p.child_project?.latestUpdate.created_by?.last_name }}
                                    </h6>
                                    <h6 class="mb-0 dkgray font-weight-normal text-nowrap">
                                      {{ p.child_project?.latestUpdate.created_datetime | date: 'EEE, MMM d • h:mmaa' }}
                                    </h6>
                                  </div>
                                  <p
                                    *ngIf="p.child_project?.latestUpdate.project_health_type?.id"
                                    class="mb-0 px-2 br-4 ml-auto bg-green white font-weight-semibold"
                                    [ngClass]="{
                                      'bg-green': p.child_project?.latestUpdate.project_health_type?.id === 1,
                                      'bg-orange': p.child_project?.latestUpdate.project_health_type?.id === 2,
                                      'bg-red': p.child_project?.latestUpdate.project_health_type?.id === 3
                                    }"
                                    style="font-size: 11px; line-height: 20px"
                                  >
                                    {{ p.child_project?.latestUpdate.project_health_type?.name }}
                                  </p>
                                </div>
                                <a
                                  class="m-0 ltblue font-weight-semibold hover-u mb-3 text-decoration-none"
                                  [routerLink]="['/projects/' + p.child_project?.id]"
                                >
                                  PJ-{{ p.child_project?.code }}{{ p.child_project?.title ? ' - ' : '' }}
                                  {{ p.child_project?.title }}
                                </a>
                                <p
                                  class="m-0 mt-3 dkblue font-weight-normal"
                                  [innerHTML]="p.child_project?.latestUpdate.message"
                                ></p>
                              </div>
                            </div>
                          </mat-menu>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row d-flex align-items-top pl-4 py-3 pr-3"
                      *ngIf="!p.child_project?.id && p.child_request?.id"
                    >
                      <div class="col d-flex flex-column">
                        <h5 class="m-0 font-weight-semibold mr-3 d-flex align-items-center">
                          <mat-icon class="mr-3 ltblue">assignment_late</mat-icon>
                          <span class="dkgray mr-2"> {{ p.child_request?.code }} | </span>
                          <span class="dkgray mr-2">
                            {{ p.child_request?.building_code }}{{ p.child_request?.building_code ? ' - ' : ''
                            }}{{ p.child_request?.floor_code }} |
                          </span>
                          <span class="dkblue"> {{ p.child_request?.short_description }} </span>
                        </h5>
                        <div class="d-flex align-items-center pl-5 py-3">
                          <h6 class="white m-0 px-2 lh-18 br-4 bg-dkblue text-uppercase mr-2" style="font-size: 0.9rem">
                            {{ p.child_request?.workspace?.name }}
                          </h6>
                          <h6 class="dkblue m-0 px-2 lh-18 br-4 mr-2" style="font-size: 0.9rem">
                            <span class="mr-2"> PM: </span>
                            <span class="mr-2 dkgray" *ngIf="p.child_request?.project_manager?.id"
                              >{{ p.child_request?.project_manager?.first_name }}
                              {{ p.child_request?.project_manager?.last_name }}</span
                            >
                            <span class="mr-2 dkgray" *ngIf="!p.child_request?.project_manager?.id">Not Set</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div *ngIf="!last && bidTypes?.length" class="m-portlet"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="pl-4 pr-4 position-absolute overflow-auto" style="width: 400px; right: 0; height: 100%">
    <section class="mb-4">
      <div class="m-portlet m-0">
        <div class="m-portlet__body item d-flex align-items-center p-3">
          <h6 class="m-0 dkblue font-weight-semibold">Total Awarded</h6>
          <h6 class="m-0 dkblue font-weight-semibold ml-auto">{{ constructionCost | USDollar }}</h6>
        </div>
        <div class="m-portlet__body item d-flex align-items-center p-3">
          <h6 class="m-0 dkblue font-weight-semibold">Awarded Bids</h6>
          <h6 class="m-0 dkblue font-weight-semibold ml-auto">
            {{ numberOfAwardedContracts || 0 }} / {{ biddableBidPackageCount || 0 }}
          </h6>
        </div>
        <div class="m-portlet__body item item-last d-flex align-items-center p-3">
          <h6 class="m-0 dkblue font-weight-semibold">Executed Contracts</h6>
          <h6 class="m-0 dkblue font-weight-semibold ml-auto">
            {{ numberOfExecutedContracts || 0 }} / {{ biddableBidPackageCount || 0 }}
          </h6>
        </div>
        <!-- <div class="m-portlet__body item p-3">
              <div class="row">
                <div class="col">
                  <h6 class="m-0 dkblue font-weight-semibold">Supplier Signatures</h6>
                </div>
                <div class="col text-right">
                  <h6 class="m-0 dkblue font-weight-semibold">
                    {{ numberOfInitialContracts || 0 }} / {{ biddableBidPackageCount || 0 }}
                  </h6>
                </div>
              </div>
            </div>-->

        <!-- <div class="m-portlet__body item-last p-3">
              <div class="row">
                <div class="col">
                  <h6 class="m-0 dkblue font-weight-semibold">Linked Projects</h6>
                </div>
                <div class="col text-right">
                  <h6 class="m-0 dkblue font-weight-semibold">
                    {{ linkedProjectCount }}
                  </h6>
                </div>
              </div>
            </div> -->
      </div>
    </section>
    <section class="mb-4">
      <div class="row">
        <div class="col">
          <div class="m-portlet m-0">
            <!-- <div class="m-portlet__body item p-3 d-flex align-items-center">
              <h5 class="m-0 dkblue font-weight-semibold mr-auto my-1">Bid Details</h5>
            </div> -->
            <div class="m-portlet__body item-no-hover p-3 d-flex align-items-center">
              <h6 class="m-0 dkblue font-weight-semibold mr-auto">RFP</h6>
              <button
                *ngIf="isAdmin && rfp_data && !rfp_data.file"
                mat-flat-button
                (click)="openUploadModal('rfp')"
                color="success"
                class="lh-28 px-3"
              >
                Add
              </button>
              <!-- <button mat-icon-button *ngIf="isAdmin && rfp_data && !rfp_data.file" (click)="openUploadModal('rfp')"
                color="accent">
                <mat-icon>attach_file</mat-icon>
              </button> -->
              <mat-chip-list class="d-inline-block align-middle">
                <app-file-chip
                  *ngIf="rfp_data && rfp_data.file"
                  [file]="rfp_data.file"
                  [removable]="isAdmin"
                  (removeEvent)="removeFileFromBidDetails('rfp', rfp_data.file, true)"
                ></app-file-chip>
              </mat-chip-list>
            </div>
            <div class="m-portlet__body item-no-hover p-3 d-flex align-items-center">
              <h6 class="m-0 dkblue font-weight-semibold mr-auto">Requirements</h6>

              <button
                *ngIf="isAdmin && requirement_data && !requirement_data.requirement_files"
                mat-button
                (click)="openUploadModal('requirement')"
                color="success"
                class="lh-28 px-3"
              >
                Add
              </button>
              <!-- <button
                *ngIf="isAdmin && requirement_data"
                mat-icon-button
                (click)="openUploadModal('requirement')"
                color="accent"
              >
                <mat-icon>attach_file</mat-icon>
              </button> -->
              <mat-chip-list *ngIf="requirement_data">
                <app-file-chip
                  *ngFor="let f of requirement_data.requirement_files"
                  [file]="f"
                  [removable]="isAdmin"
                  (removeEvent)="removeFileFromBidDetails('requirement', f, true)"
                ></app-file-chip>
              </mat-chip-list>
            </div>
            <div class="m-portlet__body item d-flex align-items-center p-3">
              <h6 class="m-0 dkblue font-weight-semibold mr-auto">Bid Dates</h6>

              <button mat-button (click)="bidStartDatePicker.open()" color="accent" class="lh-28 px-2">
                <span class="dkblue" *ngIf="schedule_data && schedule_data.bid_start_date">
                  {{ schedule_data.bid_start_date | date: 'MMM dd yyyy' }} -
                  {{ schedule_data.bid_end_date | date: 'MMM
                  dd yyyy' }}
                </span>
                <span *ngIf="schedule_data && !schedule_data.bid_start_date"> Set Dates </span>
              </button>

              <mat-form-field
                class="d-inline-block align-middle"
                style="visibility: hidden; width: 1px; height: 24px"
                appearance="outline"
              >
                <mat-date-range-input [rangePicker]="bidStartDatePicker">
                  <input
                    [disabled]="!isAdmin"
                    [(ngModel)]="schedule_data && schedule_data.bid_start_date"
                    matStartDate
                    placeholder="Start date"
                  />
                  <input
                    [disabled]="!isAdmin"
                    [(ngModel)]="schedule_data && schedule_data.bid_end_date"
                    (dateChange)="saveSchedule()"
                    matEndDate
                    placeholder="End date"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="bidStartDatePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #bidStartDatePicker></mat-date-range-picker>

                <!-- <input matInput [matDatepicker]="bidStartDatePicker" autocomplete="off"
                  [(ngModel)]="schedule_data && schedule_data.bid_start_date" (ngModelChange)="saveSchedule()"
                  [disabled]="!isAdmin" />
                <mat-datepicker-toggle matSuffix [for]="bidStartDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #bidStartDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker> -->
              </mat-form-field>
            </div>

            <div class="m-portlet__body item d-flex align-items-center p-3">
              <h6 class="m-0 dkblue font-weight-semibold mr-auto">Bid End Time</h6>

              <button
                (click)="show_time_input = true"
                *ngIf="show_time_input === false"
                mat-button
                color="accent"
                class="lh-28 px-2"
              >
                <span *ngIf="schedule_data">
                  <span class="dkblue" *ngIf="schedule_data.bid_end_time"> {{ schedule_data.bid_end_time }} </span>
                  <span class="ltblue" *ngIf="!schedule_data.bid_end_time"> Set Time </span>
                </span>
              </button>
              <span *ngIf="show_time_input === true">
                <button (click)="saveSchedule(); show_time_input = false" mat-icon-button color="success">
                  <mat-icon>check</mat-icon>
                </button>
                <button (click)="loadSchedule(); show_time_input = false" mat-icon-button class="ltgray">
                  <mat-icon>clear</mat-icon>
                </button>
                <mat-form-field appearance="outline" class="mr-2" style="width: 120px">
                  <input
                    matInput
                    type="time"
                    [(ngModel)]="schedule_data && schedule_data.bid_end_time"
                    (ngModelChange)="scheduleUnsavedChanges = true"
                    [disabled]="!isAdmin"
                  />
                </mat-form-field>
              </span>
            </div>
            <div class="m-portlet__body item d-flex align-items-center p-3">
              <h6 class="m-0 dkblue font-weight-semibold mr-auto">Est Construction Dates</h6>

              <button mat-button (click)="constructionStartDatePicker.open()" color="accent" class="lh-28 px-2">
                <span class="dkblue" *ngIf="schedule_data && schedule_data.construction_start_date">
                  {{ schedule_data.construction_start_date | date: 'MMM dd yyyy' }} -
                  {{ schedule_data.construction_end_date | date: 'MMM dd yyyy' }}
                </span>
                <span *ngIf="schedule_data && !schedule_data.construction_start_date"> Set Dates </span>
              </button>
              <mat-form-field
                class="d-inline-block align-middle"
                style="visibility: hidden; width: 1px; height: 24px"
                appearance="outline"
              >
                <mat-date-range-input [rangePicker]="constructionStartDatePicker">
                  <input
                    [disabled]="!isAdmin"
                    [(ngModel)]="schedule_data && schedule_data.construction_start_date"
                    matStartDate
                    placeholder="Start date"
                  />
                  <input
                    [disabled]="!isAdmin"
                    [(ngModel)]="schedule_data && schedule_data.construction_end_date"
                    (dateChange)="saveSchedule()"
                    matEndDate
                    placeholder="End date"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="constructionStartDatePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #constructionStartDatePicker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="m-portlet__body item d-flex align-items-center p-3">
              <h6 class="m-0 dkblue font-weight-semibold mr-auto">Construction Schedule</h6>
              <button
                *ngIf="isAdmin && (!timelineFile || timelineFile?.file_id !== project?.project_schedule_file_id)"
                mat-button
                color="accent"
                (click)="toggleProjectScheduleIsNotApplicable()"
                class="lh-28 px-3"
              >
                {{ project?.project_schedule_is_not_applicable ? 'Undo' : 'Mark N/A' }}
              </button>
              <button
                *ngIf="
                  isAdmin &&
                  (!timelineFile || timelineFile?.file_id !== project?.project_schedule_file_id) &&
                  !project?.project_schedule_is_not_applicable
                "
                mat-flat-button
                (click)="openTimelineSelectDialog()"
                color="success"
                class="lh-28 px-3 ml-2"
              >
                Add
              </button>
              <mat-chip-list
                class="ml-2"
                *ngIf="
                  timelineFile &&
                  timelineFile?.file_id === project?.project_schedule_file_id &&
                  !project?.project_schedule_is_not_applicable
                "
              >
                <app-file-chip
                  [file]="timelineFile"
                  [removable]="isAdmin"
                  (removeEvent)="deleteTimelineFile(timelineFile)"
                >
                </app-file-chip>
              </mat-chip-list>
            </div>
            <div class="m-portlet__body item item-last d-flex align-items-center p-3">
              <h6 class="m-0 dkblue font-weight-semibold mr-auto text-truncate">Schedule Contracts</h6>
              <!-- TODO DISABLED UNTIL CONSTRUCTION TIMELINE EXISTS ABOVE -->
              <button
                *ngIf="
                  (!project?.project_schedule_file_id ||
                    project?.project_schedule_file_id !== project?.sent_project_schedule_file_id) &&
                  !project?.project_schedule_is_not_applicable
                "
                (click)="openProjectScheduleConfirmationDialog()"
                mat-flat-button
                color="success"
                class="lh-28 px-3"
                [ngClass]="{
                  'bg-ltgray':
                    !project?.project_schedule_file_id ||
                    project?.project_schedule_file_id === project?.sent_project_schedule_file_id,
                  'bg-gold':
                    project?.project_schedule_file_id &&
                    project?.project_schedule_file_id !== project?.sent_project_schedule_file_id
                }"
                [disabled]="
                  !project?.project_schedule_file_id ||
                  project?.project_schedule_file_id === project?.sent_project_schedule_file_id
                "
              >
                Send
              </button>
              <h6
                *ngIf="
                  project?.project_schedule_file_id &&
                  project?.project_schedule_file_id === project?.sent_project_schedule_file_id &&
                  !project?.project_schedule_is_not_applicable
                "
                class="green lh-28 mb-0"
              >
                <i class="fa fa-check mr-1"></i>
                Sent
              </h6>
              <h6 class="gray m-0" *ngIf="project?.project_schedule_is_not_applicable">N/A</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="mb-4">
      <div class="mb-2 d-flex align-items-center">
        <h5 class="m-0 dkblue font-weight-semibold mr-auto">Bid Meetings</h5>
        <button mat-button *ngIf="isAdmin" (click)="createMeeting()" color="success" class="lh-28 px-2">
          + Meeting
        </button>
      </div>
      <div class="m-portlet m-0">
        <div
          *ngFor="let m of meeting_data; let last = last"
          class="m-portlet__body item p-3 d-flex align-items-center"
          [ngClass]="{ 'item-last': last }"
        >
          <div class="d-flex flex-column mr-auto">
            <p class="dkblue font-weight-semibold m-0">{{ m.title }}</p>
            <p class="m-0 font-weight-normal dkgray">{{ m.start_datetime | date: 'MMM dd • h:mmaa' }}</p>
            <!-- <p class="m-0 mt-1 font-weight-normal dkblue">
              {{ m.location }}
            </p> -->
          </div>
          <span *ngIf="m.is_mandatory" class="mx-2 br-4 lh-18 white px-1 font-weight-bold bg-red"> M </span>
          <div class="d-flex flex-column">
            <button mat-icon-button color="primary" *ngIf="isAdmin" [matMenuTriggerFor]="bid_meeting_menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #bid_meeting_menu="matMenu">
              <button (click)="editMeeting(m)" mat-menu-item>
                <mat-icon>edit</mat-icon>
                <span>Edit Meeting</span>
              </button>
              <button [routerLink]="['/meetings/' + m.id]" mat-menu-item>
                <mat-icon>event</mat-icon>
                <span>Go to Meeting</span>
              </button>
            </mat-menu>
            <!-- <label class="main-label m-0 mt-1 red font-weight-bold" *ngIf="m.is_mandatory"> MANDATORY </label>
            <label class="main-label m-0 mt-1 ltblue font-weight-bold" *ngIf="!m.is_mandatory"> OPTIONAL </label> -->
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="projectSolicitations?.length" class="mb-4">
      <div class="mb-2 d-flex align-items-center">
        <h5 class="m-0 dkblue font-weight-semibold mr-auto">Bid Solicitations</h5>
      </div>
      <div class="m-portlet m-0">
        <div *ngFor="let s of projectSolicitations" class="m-portlet__body item p-3 d-flex align-items-center">
          <div class="d-flex flex-column mr-auto">
            <p class="dkblue font-weight-semibold m-0">{{ s.type_name && s.type_name }}</p>
            <p class="m-0 font-weight-normal dkgray mb-2">
              {{ s.start_date | date: 'MMM dd yyyy' }} - {{ s.end_date | date: 'MMM dd yyyy' }}
            </p>
            <mat-chip-list>
              <app-file-chip *ngFor="let file of s.files" [file]="file"></app-file-chip>
            </mat-chip-list>
          </div>

          <!-- <p class="m-0 mt-1 font-weight-normal dkblue">
            {{ s.location }}
          </p> -->

          <button
            mat-icon-button
            [matMenuTriggerFor]="s_menu"
            color="primary"
            matTooltip="More"
            matTooltipPosition="above"
            [matTooltipShowDelay]="300"
            *ngIf="isWorkspaceStaff"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #s_menu="matMenu" xPosition="before">
            <button (click)="openSolicitationDialog(s)" mat-menu-item>
              <mat-icon>edit</mat-icon>
              <span> Edit </span>
            </button>
            <button (click)="removeSolicitation(s)" mat-menu-item>
              <mat-icon>delete</mat-icon>
              <span> Delete </span>
            </button>
          </mat-menu>
        </div>
      </div>
    </section>
  </div>
</div>
<app-bid-contract-export #pdf [contract]="currentContract"></app-bid-contract-export>

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { catchError, map } from 'rxjs/operators';
import { DatepickerHeaderComponent, EditorComponent, FileAttachmentDialogComponent } from 'src/app/components';
import { ApplicationRole, ResourceType, TAG_IDS, UserType } from 'src/app/enums';
import {
  AuthService,
  FileService,
  ProgressIndicatorService,
  ProjectOverviewService,
  ProjectService,
  UserService,
} from 'src/app/services';
import { HealthType, Project, ProjectUpdate, UhatFileReference, User } from 'src/app/types';

@Component({
  selector: 'app-work-order-update-dialog',
  templateUrl: './project-update-dialog.component.html',
  styleUrls: ['./project-update-dialog.component.scss'],
})
export class ProjectUpdateDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  private _filesToUnlink: UhatFileReference[] = [];
  private _projectHealthTypesFields = ['id', 'name'];
  private _projectSubmissionFields = ['message', 'notify_followers', 'project_health_type_id'];
  public _projectUsersToNotify: User[] = [];

  public auto_focus = true;
  public dialogTitle = 'Add Update';
  public existingFiles: UhatFileReference[] = [];
  public isLoading = false;
  public newFiles: UhatFileReference[] = [];
  public linkedFiles: UhatFileReference[] = [];
  public isUpdating = false;
  public projectHealthTypes: HealthType[] = [];
  public projectHealthTypeId: number;
  public projectId: number;
  public projectUpdateId: number;
  public projectUpdateFormGroup: FormGroup = this._fb.group({
    everyoneVisibility: [1],
    notify_followers: [1],
  });
  public endDateHasChanged = false;
  public endDateEstimateHasChanged = false;
  public projectEndDateEstimate = new FormControl();
  public projectEndDate = new FormControl();
  public maxValue = 100;
  public minValue = 0;
  public projectEndDateEstimateStep = 10;
  public thumbLabel = true;
  public showTicks = true;

  // public projectEndDate = "01/20/2024"
  public customHeader = DatepickerHeaderComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    private _dialogRef: MatDialogRef<ProjectUpdateDialogComponent>,
    private _dialog: MatDialog,
    private _fb: FormBuilder,
    private _fileService: FileService,
    private _progressIndicatorService: ProgressIndicatorService,
    private _snackbar: MatSnackBar,
    private _projectService: ProjectService,
    private _projectOverviewService: ProjectOverviewService,
    private _userService: UserService,
    public authService: AuthService,
    private completionSlider: MatSliderModule
  ) {}

  async ngOnInit(): Promise<any> {
    this._activateEditor();
    this.projectHealthTypes = await this._projectOverviewService
      .getProjectHealthTypes(this._projectHealthTypesFields)
      .toPromise();
    if (this.projectHealthTypes.length > 0) {
      // insert in a not set
      this.projectHealthTypes = [{ id: null, name: 'Not Set' }, ...this.projectHealthTypes];
    }

    this.projectId = this._projectService.currentSelectedProjectId;
    this.projectHealthTypeId = this._data?.project_health_type_id;

    if (this?._data?.update) {
      this.isUpdating = true;
      this.dialogTitle = 'Edit Project Update';
      this.projectUpdateId = this._data?.id;
      this.message.setValue(this._data?.message || '');

      // get files
      this.existingFiles = await this._fileService
        .getFilesByParentId(ResourceType.ProjectUpdate, this._data?.id)
        .toPromise();
    }

    // only do this on new updates
    if (!this.projectUpdateId) {
      this._projectUsersToNotify = await this._userService
        .getUsers(
          [
            { type: 'operator', value: '(' },
            {
              type: 'field',
              field: 'id',
              value: JSON.parse(this._data?.follower_ids || '[]')?.join('^'),
            },
            { type: 'operator', value: ')' },

            { type: 'operator', value: 'OR' },

            { type: 'operator', value: '(' },
            {
              type: 'field',
              field: 'role_id',
              value: `${ApplicationRole.ProjectRequester}^${ApplicationRole.ProjectArchitect}^${ApplicationRole.ProjectManager}^${ApplicationRole.ProjectContact}^${ApplicationRole.CustomerProjectRepresentative}`,
            }, // 5^9^11^22^23
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'role_resource_id', value: `${this.projectId}` },
            { type: 'operator', value: ')' },
          ],
          ['id', 'first_name', 'last_name', 'user_type_id']
        )
        .toPromise();
    }

    if (this._data?.endDate) {
      this.projectEndDate.setValue(this._data.endDate);
    }

    if (this._data?.endDateEstimate) {
      this.projectEndDateEstimate.setValue(this._data.endDateEstimate);
    }
  }

  get formatedProjectEndDate(): string {
    return this.projectEndDate?.value ? moment(this.projectEndDate.value).format('MMM Do, YYYY') : null;
  }

  get everyoneVisibility(): AbstractControl {
    return this.projectUpdateFormGroup.get('everyoneVisibility');
  }

  public get hasRequiredDetails(): boolean {
    return (
      !!this.projectUpdateFormGroup?.value?.message || !!this.newFiles?.length || !!this.existingFiles?.length || false
    );
  }

  get message(): AbstractControl {
    return this.projectUpdateFormGroup?.get('message');
  }

  get notify_followers(): AbstractControl {
    return this.projectUpdateFormGroup?.get('notify_followers');
  }

  get projectUpdate(): ProjectUpdate {
    return this._data;
  }

  get addedFiles() {
    return [...this.newFiles, ...this.linkedFiles];
  }

  get projectUsersToNotify(): User[] {
    if (!this?.notify_followers.value) {
      return [];
    }

    // checks for access
    if (this.everyoneVisibility?.value) {
      return this._projectUsersToNotify.filter(
        (projectUser: User) => projectUser.user_type_id > 0 && projectUser.id !== this.authService.currentUser?.id
      );
    } else {
      // staff only
      return this._projectUsersToNotify.filter(
        (projectUser: User) =>
          projectUser.user_type_id === UserType.Staff && projectUser.id !== this.authService.currentUser?.id
      );
    }
  }

  get validForSubmission(): boolean {
    return this.projectUpdateFormGroup.valid;
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this._projectService.currentSelectedProject?.module_id);
  }
  private _accessHelper() {
    if (this.authService.currentUser.user_type_id === UserType.Staff && this.everyoneVisibility.value) {
      return JSON.stringify([UserType.Staff, UserType.Tenant, UserType.Vendor]);
    }

    if (this.authService.currentUser.user_type_id === UserType.Tenant) {
      return JSON.stringify([UserType.Staff, UserType.Tenant]);
    }

    return JSON.stringify([UserType.Staff]);
  }

  private async _activateEditor() {
    this.projectUpdateFormGroup.addControl('message', this._editor_component.content);
  }

  private _closeProgress() {
    this._progressIndicatorService.close();
    this.isLoading = false;
  }

  private async _handleFiles(projectUpdateId) {
    // create files
    const newFiles = [];
    for (const file of this.newFiles) {
      const newFile = await this._fileService.createFile(file, this.projectId, ResourceType.Project).toPromise();
      await this._fileService.linkFile(newFile.id, this.projectId, ResourceType.Project).toPromise();
      newFiles.push(newFile);
    }

    for (const file of [...this.linkedFiles, ...newFiles]) {
      await this._linkTags(file.file_id || file.id, [TAG_IDS.Update]);
      await this._fileService
        .linkFile(file.file_id || file.id, projectUpdateId, ResourceType.ProjectUpdate)
        .toPromise();
    }

    // files to unlink
    this._filesToUnlink.forEach((file: UhatFileReference) => this._unlinkFile(file));
  }

  private async _linkTags(fileId: number, tagIds: number[]) {
    await this._fileService.addTags(fileId, tagIds).toPromise();
  }

  private _openProgress(action: string) {
    this.isLoading = true;
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus(action);
  }

  public openUploadModal(projectUpdateId: number) {
    this._dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.projectId,
          project_id: this.projectId,
          allowComment: false,
          projectFile: true,
          skipUpload: true,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (files: any) => {
        this.newFiles = [...this.newFiles, ...(files.computerFiles || [])];
        this.linkedFiles = [...this.linkedFiles, ...(files.linkedFiles || [])];
      });
  }

  private _save(projectUpdate: ProjectUpdate) {
    this._projectOverviewService
      .createProjectUpdate(projectUpdate, [
        ...this._projectSubmissionFields,
        'access',
        'notify_followers',
        'project_id',
      ])
      .pipe(
        map((result) => result),
        catchError((e) => {
          this._closeProgress();
          return e;
        })
      )
      .subscribe(async (result: ProjectUpdate) => {
        this.projectUpdateId = result?.id;
        await this._handleFiles(this.projectUpdateId);
        this._closeProgress();
        this._dialogRef.close(result);
      });
  }

  private async _unlinkFile(file: UhatFileReference) {
    await this._fileService.unlinkFile(file?.id).toPromise();
    await this._fileService.removeTags(file.file_id || file.id, [TAG_IDS.Update]).toPromise();
  }

  private async _update(projectUpdate: ProjectUpdate) {
    await this._handleFiles(this.projectUpdateId);
    this._projectOverviewService
      .updateProjectUpdate(this.projectUpdateId, projectUpdate, this._projectSubmissionFields)
      .pipe(
        map((result) => result),
        catchError((e) => {
          this._closeProgress();
          return e;
        })
      )
      .subscribe((result) => {
        this._closeProgress();
        this._dialogRef.close(result);
      });
  }

  public close(): void {
    this._dialogRef.close();
  }

  public removeExistingFile(file: UhatFileReference) {
    // remove from existing files
    this.existingFiles = this.existingFiles?.filter((existingFile: UhatFileReference) => existingFile?.id !== file?.id);

    // add it to files to unlink
    this._filesToUnlink = [...this._filesToUnlink, file];
  }

  public removeNewFile(file: UhatFileReference) {
    this.newFiles = this.newFiles.filter((f) => f.file_id !== file.file_id);
  }

  public selectHealth(healthId: number) {
    this.projectHealthTypeId = healthId;
  }

  public async submit(): Promise<void> {
    if (this.validForSubmission && this.hasRequiredDetails) {
      const projectChanges: Project = {};
      if (this.endDateHasChanged) {
        projectChanges.end_date = moment(this.projectEndDate?.value).format('YYYY-MM-DD HH:mm:ss');
      }

      if (this.endDateEstimateHasChanged) {
        projectChanges.end_date_estimate = `${this.projectEndDateEstimate?.value}`;
      }

      // update the project itself
      if ('end_date' in projectChanges || 'end_date_estimate' in projectChanges) {
        this._openProgress(
          `Updating ${
            this.endDateHasChanged && this.endDateEstimateHasChanged
              ? 'end date and estimate'
              : this.endDateHasChanged
              ? 'end date'
              : 'estimate'
          }`
        );
        await this._projectService.updateProject(this.projectId, projectChanges).toPromise();
        this._closeProgress();
      }

      this._openProgress(`${this.isUpdating ? 'Updating' : 'Creating'} a Project update...`);
      const projectUpdate: ProjectUpdate = {
        message: this.projectUpdateFormGroup?.value?.message || '',
        project_health_type_id: this.projectHealthTypeId,
      };

      // making changes to the project updates
      if (this.isUpdating) {
        await this._update(projectUpdate);
      } else {
        this._save({
          ...projectUpdate,
          project_id: this.projectId,
          access: this._accessHelper(),
          notify_followers: this.notify_followers.value,
        });
      }
    } else {
      this._snackbar.open('A message or file is required for all updates.');
    }
  }

  public updateEndDate(): void {
    this.endDateHasChanged = true;
  }

  public updateEndDateEstimation(): void {
    this.endDateEstimateHasChanged = true;
  }
}

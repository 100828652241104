<div #mainScreen id="projects" data-test-id="projects" class="projects app-container">
  <!--the backdrop closes the aside as well-->
  <mat-drawer-container
    (backdropClick)="closeAside()"
    class="projects-container full-height bg-transparent"
    cdkScrollable="true"
    hasBackdrop="true"
    autosize
  >
    <mat-drawer-content>
      <section id="main-project-list-wrapper" class="ease p-4">
        <header class="mb-4">
          <div class="row align-items-center">
            <div class="col-auto">
              <h2 class="m-0 dkblue font-weight-semibold d-inline-block align-bottom mr-4">Projects</h2>
            </div>
            <div class="col text-right">
              <button (click)="drawer.toggle()" mat-button color="primary" type="button" class="ml-3">
                See Latest Updates
              </button>
              <button
                mat-stroked-button
                class="bg-white ml-3"
                color="primary"
                (click)="exportData()"
                *ngIf="authService.isStaffOnAnyModule"
              >
                <i class="material-icons-outlined"> file_download </i>
                <span> Download CSV </span>
              </button>
              <app-split-button class="ml-3" lineHeight="34" [matMenu]="menu" *ngIf="isWorkspaceStaff">
                <span *ngIf="!selectedMenuItem || selectedMenuItem === ProjectListMenu.default"> Default View </span>
                <span *ngIf="selectedMenuItem === ProjectListMenu.keyControls"> Key Controls View </span>
                <span *ngIf="selectedMenuItem === ProjectListMenu.CAPX"> CAPX View </span>
              </app-split-button>
              <mat-menu #menu="matMenu" xPosition="before">
                <button (click)="updateMenuItem(ProjectListMenu.default)" mat-menu-item>Default</button>
                <button (click)="updateMenuItem(ProjectListMenu.keyControls)" mat-menu-item>Key Controls</button>
                <button (click)="updateMenuItem(ProjectListMenu.CAPX)" mat-menu-item>CAPX</button>
              </mat-menu>
            </div>
          </div>
          <p class="dkgray font-weight-normal mb-2">View projects assigned to you or a workspace.</p>
          <div class="row list-filters align-items-center" *ngIf="isDispatch"></div>
        </header>

        <div class="row align-items-center">
          <div *ngIf="isDispatch" class="col-auto mb-4 pr-0">
            <app-workspace-selector (workspaceSelectedChanged)="updateWorkspaces($event)"></app-workspace-selector>
          </div>
          <div class="col-auto pr-0 mb-4 list-filters">
            <mat-button-toggle-group name="projectStatus" aria-label="List" [formControl]="status">
              <mat-button-toggle
                *ngFor="let s of projectStatuses"
                [value]="s.id"
                [ngClass]="{ selectedTab: s.id === filter.statusId }"
                class="px-2 lh-34 font-weight-semibold align-items-center d-flex"
              >
                <i *ngIf="s.id === 1" class="material-icons green mr-2">label_important</i>
                <i *ngIf="s.id === 3" class="material-icons red mr-2">flag</i>
                <i *ngIf="s.id === 2" class="material-icons ltblue mr-2">next_plan</i>
                <i *ngIf="s.id === 4" class="material-icons gray mr-2">done_all</i>
                <i *ngIf="s.id === -1" class="material-icons gray mr-2">language</i>
                {{ s.name }}
                <span
                  class="small-badge white ml-2 lh-18"
                  [ngClass]="{ 'bg-ltblue': s.id === filter.statusId, 'bg-gray': s.id !== filter.statusId }"
                >
                  {{ projectStatusCounts[s.id] || 0 }}
                </span>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col pr-0 mb-4">
            <mat-slide-toggle
              *ngIf="authService.isStaffOnAnyModule"
              color="accent"
              labelPosition="after"
              [checked]="!!filter.userId"
              (change)="filterByCurrentUser()"
            >
              <span class="ltblue font-weight-semibold"> My Projects Only </span>
            </mat-slide-toggle>
          </div>
          <div class="col-auto mb-4">
            <button
              mat-stroked-button
              (click)="showFilters = !showFilters"
              color="primary"
              class="mr-3 bg-white lh-40"
              type="button"
              matBadgePosition="before"
              [matBadge]="filtersAppliedCount > 0 && !showFilters ? filtersAppliedCount : null"
              matBadgeColor="warn"
            >
              <i class="material-icons mr-2" style="margin-top: -3px">filter_list</i>
              Filters
            </button>

            <mat-form-field appearance="outline" style="width: 230px">
              <mat-icon class="dkblue" matPrefix>search</mat-icon>
              <input matInput [formControl]="search" placeholder="Search Projects" />
              <mat-icon *ngIf="search.value" (click)="search.setValue(null)" class="ltgray pointer" matSuffix>
                cancel
              </mat-icon>
            </mat-form-field>
          </div>
        </div>

        <div [hidden]="!showFilters" class="m-portlet mb-3 p-3 full-width bg-shade-ltblue">
          <div class="row d-flex align-items-end">
            <div class="col-lg-2 pt-2 pb-3">
              <h6 class="dkblue">Topic</h6>
              <div class="d-flex">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    multiple
                    [(ngModel)]="filter.topics"
                    placeholder="Topic"
                    (selectionChange)="getProjects()"
                  >
                    <mat-option *ngFor="let option of projectFilterOptions.topics" [value]="option.value">{{
                      option.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="col-lg-2 pt-2 pb-3">
              <h6 class="dkblue">Project Manager</h6>
              <div class="d-flex">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    multiple
                    [(ngModel)]="filter.projectManagers"
                    placeholder="Project Manager"
                    (selectionChange)="getProjects()"
                  >
                    <mat-option *ngFor="let option of projectFilterOptions.projectManagers" [value]="option.value">{{
                      option.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="currentWorkspace === workspace.Construction" class="col-lg-2 pt-2 pb-3">
              <h6 class="dkblue">Architect</h6>
              <div class="d-flex">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    multiple
                    [(ngModel)]="filter.architects"
                    placeholder="Architect"
                    (selectionChange)="getProjects()"
                  >
                    <mat-option *ngFor="let option of projectFilterOptions.architects" [value]="option.value">{{
                      option.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="col-lg-2 pt-2 pb-3">
              <h6 class="dkblue">Requestor</h6>
              <div class="d-flex">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    multiple
                    [(ngModel)]="filter.requesters"
                    placeholder="Requestor"
                    (selectionChange)="getProjects()"
                  >
                    <mat-option *ngFor="let option of projectFilterOptions.requesters" [value]="option.value">{{
                      option.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="row d-flex">
                <div class="col-7 pr-0 pt-2 pb-3">
                  <h6 class="dkblue">Building</h6>
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-select
                      multiple
                      [(ngModel)]="filter.buildings"
                      placeholder="Building"
                      (selectionChange)="handledSelectedBuildingChange()"
                    >
                      <mat-option *ngFor="let option of projectFilterOptions.buildings" [value]="option.value">{{
                        option.name
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-5 pt-2 pb-3">
                  <h6 class="dkblue">Floor</h6>
                  <div class="d-flex">
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-select
                        multiple
                        [(ngModel)]="filter.floors"
                        [placeholder]="!filter.buildings.length ? 'Select Buildings' : 'Select Floors'"
                        (selectionChange)="getProjects()"
                        [disabled]="!filter.buildings.length"
                      >
                        <mat-option *ngFor="let option of floorOptions" [value]="option.value"
                          >{{ option.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-2 pt-2 pb-3" *ngIf="isStaff">
              <h6 class="dkblue">Tags</h6>
              <div class="d-flex">
                <mat-form-field
                  id="tag-filter"
                  appearance="outline"
                  class="full-width"
                  matBadgePosition="before"
                  matBadgeColor="warn"
                >
                  <mat-select placeholder="Filter Tags" [(ngModel)]="filter.tags" multiple>
                    <mat-select-trigger>
                      <span *ngIf="filter?.tags?.length !== 1">
                        {{ tags && tags.length === filter?.tags?.length ? 'All' : +filter?.tags?.length }} Tags
                      </span>
                      <span *ngIf="filter?.tags?.length === 1">
                        {{ selectedTags[0]?.abbreviation }}
                      </span>
                    </mat-select-trigger>
                    <ng-container *ngIf="filter.tags.length">
                      <mat-checkbox class="mat-option mt-2" color="primary" (click)="this.filter.tags = []">
                        Deselect All
                      </mat-checkbox>
                      <mat-divider class="my-2"></mat-divider>
                    </ng-container>
                    <mat-option *ngFor="let tag of tags" [value]="tag.id" (click)="getProjects()">{{
                      tag.abbreviation
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="col-lg-2 pt-2 pb-3" *ngIf="currentWorkspace !== workspace.Construction">
              <h6 class="dkblue">Substatus</h6>
              <div class="d-flex">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    multiple
                    [(ngModel)]="filter.subStatuses"
                    placeholder="Substatus"
                    (selectionChange)="getProjects()"
                  >
                    <mat-option *ngFor="let option of projectFilterOptions.subStatuses" [value]="option.value">{{
                      option.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="col text-right">
              <button mat-button color="primary" class="mb-2" type="button" (click)="showFilters = false">Hide</button>
              <button
                mat-stroked-button
                color="primary"
                class="bg-white ml-3 mb-2"
                type="button"
                (click)="handleClearAllSelectedFilters(); getProjects()"
              >
                Clear All
              </button>
            </div>
          </div>
        </div>

        <p *ngIf="showFilters">Showing {{ projectsCount }} of {{ projectStatusCounts[filter.statusId] }}</p>

        <!-- TABLE VIEWS START HERE -->

        <main
          id="default_view"
          [hidden]="selectedMenuItem !== ProjectListMenu.default && selectedMenuItem !== ProjectListMenu.CAPX"
        >
          <div class="m-portlet mb-0">
            <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
              <div class="row align-items-center">
                <div
                  (click)="updateSortByField('code')"
                  class="col-auto pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item"
                  style="width: 110px"
                  [ngClass]="{ sortedBy: fieldToSortBy === 'code' }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Code</h6>
                  <i *ngIf="fieldToSortBy === 'code'" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField('building_code,floor_name')"
                  class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
                  style="max-width: 110px"
                  [ngClass]="{ sortedBy: fieldToSortBy === 'building_code,floor_name' }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Location</h6>
                  <i *ngIf="fieldToSortBy === 'building_code,floor_name'" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField('title')"
                  class="p-1 pl-3 pr-3 d-flex header-item align-items-center"
                  [ngClass]="{
                    sortedBy: fieldToSortBy === 'title',
                    'col-2': currentWorkspace === workspace.Construction,
                    'col-3': currentWorkspace !== workspace.Construction
                  }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Short Description</h6>
                  <i *ngIf="fieldToSortBy === 'title'" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField('priority_id')"
                  *ngIf="isWorkspaceStaff"
                  class="col-auto p-1 pl-3 pr-3 d-flex header-item align-items-center"
                  style="width: 115px"
                  [ngClass]="{ sortedBy: fieldToSortBy === 'priority_id' }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Priority</h6>
                  <i *ngIf="fieldToSortBy === 'priority_id'" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField('substatus_name')"
                  *ngIf="isWorkspaceStaff && currentWorkspace !== workspace.Construction"
                  class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
                  style="width: 115px"
                  [ngClass]="{ sortedBy: fieldToSortBy === 'substatus_name' }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Substatus</h6>
                  <i *ngIf="fieldToSortBy === 'substatus_name'" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField('current_phase_name')"
                  *ngIf="currentWorkspace === workspace.Construction"
                  class="col-1 p-1 pl-3 pr-3 d-flex header-item align-items-center"
                  [ngClass]="{ sortedBy: fieldToSortBy === 'current_phase_name' }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Phase</h6>
                  <i *ngIf="fieldToSortBy === 'current_phase_name'" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div class="col-1 p-1 pl-3 pr-3 d-flex header-item align-items-center" *ngIf="isStaff">
                  <h6 class="m-0 text-truncate header-title mr-auto">Tags</h6>
                </div>
                <div
                  class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
                  *ngIf="isStaff && selectedMenuItem === ProjectListMenu.CAPX"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">CAPX ID</h6>
                </div>
                <div
                  (click)="updateSortByField('project_manager_full_name')"
                  class="p-1 pl-3 pr-3 d-flex header-item align-items-center"
                  [ngClass]="{
                    sortedBy: fieldToSortBy === 'project_manager_full_name',
                    'col-2': selectedMenuItem !== ProjectListMenu.CAPX,
                    'col-1': selectedMenuItem === ProjectListMenu.CAPX
                  }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Project Manager</h6>
                  <i *ngIf="fieldToSortBy === 'project_manager_full_name'" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField('architect_full_name')"
                  *ngIf="currentWorkspace === workspace.Construction"
                  class="p-1 pl-3 pr-3 d-flex header-item align-items-center"
                  [ngClass]="{
                    sortedBy: fieldToSortBy === 'architect_full_name',
                    'col-2': selectedMenuItem !== ProjectListMenu.CAPX,
                    'col-1': selectedMenuItem === ProjectListMenu.CAPX
                  }"
                >
                  <h6
                    class="m-0 text-truncate header-title mr-auto"
                    [ngClass]="{ 'mx-auto': selectedMenuItem === ProjectListMenu.CAPX }"
                  >
                    Architect
                  </h6>
                  <i *ngIf="fieldToSortBy === 'architect_full_name'" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField('project_requester_full_name')"
                  *ngIf="currentWorkspace !== workspace.Construction"
                  class="col-2 p-1 pl-3 pr-3 d-flex header-item align-items-center"
                  [ngClass]="{ sortedBy: fieldToSortBy === 'project_requester_full_name' }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Requester</h6>
                  <i *ngIf="fieldToSortBy === 'project_requester_full_name'" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField(update_dot_created_datetime)"
                  *ngIf="isWorkspaceStaff"
                  class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
                  [ngClass]="{ sortedBy: fieldToSortBy === update_dot_created_datetime }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Last Update</h6>
                  <i *ngIf="fieldToSortBy === update_dot_created_datetime" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
              </div>
            </div>
            <div
              matRipple
              data-test-id="project-list-item"
              [routerLink]="['/projects/' + p.id]"
              *ngFor="let p of allProjects"
              class="m-portlet__body item pointer p-3 pt-4 pb-4"
            >
              <div class="row align-items-center">
                <div class="col-auto pr-3 d-flex align-items-center data-item" style="width: 110px">
                  <p class="m-0 dkblue pointer font-weight-semibold d-inline-block align-middle">
                    {{ p.code }}
                  </p>
                  <i *ngIf="p.status.id === 1" class="material-icons green ml-auto">label_important</i>
                  <i *ngIf="p.status.id === 3" class="material-icons red ml-auto">flag</i>
                  <i *ngIf="p.status.id === 2" class="material-icons ltblue ml-auto">next_plan</i>
                  <i *ngIf="p.status.id === 4" class="material-icons gray ml-auto">done_all</i>
                </div>
                <div class="col pl-3 pr-3 d-flex align-items-center data-item" style="max-width: 110px">
                  <p class="m-0 mr-auto dkblue pointer font-weight-semibold">
                    {{ p.building?.code }}{{ p.floor?.code ? '-' : '' }}{{ p.floor?.code }}
                  </p>
                </div>
                <div
                  class="pl-3 pr-3 d-flex flex-column data-item"
                  [ngClass]="{
                    'col-2': currentWorkspace === workspace.Construction,
                    'col-3': currentWorkspace !== workspace.Construction
                  }"
                >
                  <a
                    [routerLink]="['/projects/' + p.id]"
                    (click)="stopPropagation($event)"
                    class="text-decoration-none hover-u lh-16"
                  >
                    <p class="m-0 dkblue font-weight-semibold">
                      {{ p.title }}
                      <span class="m-0 dkgray font-weight-normal d-block">
                        {{ p.topic_group ? p.topic_group + ' >' : '' }}

                        {{ p.topic_category ? p.topic_category + ' >' : '' }}
                        {{ p.topic_name }}
                      </span>
                    </p>
                  </a>
                </div>
                <div *ngIf="isWorkspaceStaff" class="col-auto pl-3 pr-3 d-flex data-item" style="width: 115px">
                  <h6
                    *ngIf="p.priority"
                    class="m-0 badge text-uppercase py-0 px-2 mr-1 white lh-18"
                    [ngClass]="{
                      'bg-dkred': p.priority.id === 4,
                      'bg-red': p.priority.id === 3,
                      'bg-ltblue': p.priority.id === 2,
                      'bg-gray': p.priority.id === 1
                    }"
                  >
                    {{ p.priority.abbreviation }}
                  </h6>
                </div>
                <div
                  *ngIf="isWorkspaceStaff && currentWorkspace !== workspace.Construction"
                  class="col pl-3 pr-3 d-flex align-items-center data-item"
                >
                  <p class="m-0 dkblue font-weight-normal pointer d-inline-block text-wrap text-truncate">
                    {{ p.substatus?.name || '-' }}
                  </p>
                </div>
                <div
                  *ngIf="currentWorkspace === workspace.Construction"
                  class="col-1 pl-3 pr-3 d-flex align-items-center data-item"
                >
                  <p class="m-0 mr-auto dkblue font-weight-normal">
                    {{ (p.current_phase && p.current_phase.name) || '-' }}
                  </p>
                </div>
                <div class="col-1 pl-3 pr-3 d-flex align-items-center data-item" *ngIf="isStaff">
                  <p class="m-0 mr-auto dkblue font-weight-normal">
                    <span *ngFor="let tag of p.tags || []"> {{ tag.abbreviation }} </span>
                  </p>
                </div>
                <div
                  class="col pl-3 pr-3 d-flex align-items-center data-item"
                  *ngIf="isStaff && selectedMenuItem === ProjectListMenu.CAPX"
                >
                  <p
                    [title]="p.capx_budget?.capx_id + ' | ' + p.capx_budget?.description"
                    class="m-0 mr-auto px-3 capx-pill text-truncate"
                    *ngIf="p.capx_budget"
                    (click)="openCAPXpanel(p.capx_budget, $event)"
                  >
                    {{ p.capx_budget?.capx_id }} | {{ p.capx_budget?.description }}
                  </p>
                </div>
                <div
                  class="pl-3 pr-3 d-flex align-items-center data-item"
                  [ngClass]="{
                    'col-2': selectedMenuItem !== ProjectListMenu.CAPX,
                    'col-1': selectedMenuItem === ProjectListMenu.CAPX
                  }"
                >
                  <app-profile-thumbnail
                    [noBorder]="true"
                    class="d-inline-block mr-2"
                    [height]="22"
                    [width]="22"
                    [userId]="p.project_manager?.id"
                    [ngClass]="{ 'mx-auto': selectedMenuItem === ProjectListMenu.CAPX }"
                  ></app-profile-thumbnail>
                  <p
                    *ngIf="selectedMenuItem !== ProjectListMenu.CAPX"
                    class="m-0 dkblue font-weight-normal pointer d-inline-block text-wrap text-truncate"
                  >
                    {{ p.project_manager?.first_name }} {{ p.project_manager?.last_name }}
                  </p>
                </div>
                <div
                  *ngIf="currentWorkspace === workspace.Construction"
                  class="pl-3 pr-3 d-flex align-items-center data-item"
                  [ngClass]="{
                    'col-2': selectedMenuItem !== ProjectListMenu.CAPX,
                    'col-1': selectedMenuItem === ProjectListMenu.CAPX
                  }"
                >
                  <app-profile-thumbnail
                    [noBorder]="true"
                    class="d-inline-block mr-2"
                    [height]="22"
                    [width]="22"
                    [userId]="p.architect?.id"
                    *ngIf="p.is_architect_required"
                    [ngClass]="{ 'mx-auto': selectedMenuItem === ProjectListMenu.CAPX }"
                  ></app-profile-thumbnail>
                  <p
                    class="m-0 dkblue font-weight-normal pointer d-inline-block text-wrap text-truncate"
                    *ngIf="p.is_architect_required && selectedMenuItem !== ProjectListMenu.CAPX"
                  >
                    {{ p.architect?.first_name }} {{ p.architect?.last_name }}
                  </p>
                  <p
                    class="m-0 dkblue font-weight-normal pointer d-inline-block text-wrap text-truncate"
                    [ngClass]="{ 'mx-auto': selectedMenuItem === ProjectListMenu.CAPX }"
                    *ngIf="!p.is_architect_required"
                  >
                    Not Needed
                  </p>
                </div>
                <div
                  *ngIf="currentWorkspace !== workspace.Construction"
                  class="col-2 pl-3 pr-3 d-flex align-items-center data-item"
                >
                  <app-profile-thumbnail
                    [noBorder]="true"
                    class="d-inline-block mr-2"
                    [height]="22"
                    [width]="22"
                    [userId]="p.requester_id"
                  ></app-profile-thumbnail>
                  <p class="m-0 dkblue font-weight-normal pointer d-inline-block text-wrap text-truncate">
                    {{ p.requester?.first_name }} {{ p.requester?.last_name }}
                  </p>
                </div>
                <div *ngIf="isWorkspaceStaff" class="col pl-3 d-flex flex-column data-item">
                  <p class="m-0 font-weight-semibold gray" *ngIf="!p.updates?.[0]">No Updates</p>
                  <p
                    (click)="$event.stopPropagation()"
                    [matMenuTriggerFor]="projectUpdateMenu"
                    class="m-0 font-weight-semibold ltblue hover-u d-block"
                    *ngIf="p?.updates?.[0]"
                  >
                    {{ p?.updates?.[0].created_datetime | date: 'EEE, MMM d yyyy' }}
                  </p>
                  <p
                    class="m-0 font-weight-normal font-italic dkgray"
                    style="font-size: 80%"
                    *ngIf="p?.updates?.[0] && p?.updates?.[0].created_by_id"
                  >
                    By {{ p?.updates?.[0].created_by_first_name }}
                    {{ p?.updates?.[0].created_by_last_name }}
                  </p>
                  <mat-menu #projectUpdateMenu="matMenu" xPosition="before">
                    <div class="d-flex py-3 px-4" *ngIf="p.updates?.[0]">
                      <app-profile-thumbnail
                        class="mr-3"
                        [noBorder]="true"
                        [height]="30"
                        [width]="30"
                        [userId]="p?.updates[0].created_by_id"
                      ></app-profile-thumbnail>
                      <div class="flex-grow-1">
                        <div class="d-flex align-items-start mb-3">
                          <div class="d-flex flex-column mr-4">
                            <h6 class="mb-0 dkblue font-weight-semibold text-nowrap">
                              {{ p?.updates[0].created_by_first_name }}
                              {{ p?.updates[0].created_by_last_name }}
                            </h6>
                            <h6 class="mb-0 dkgray font-weight-normal text-nowrap">
                              {{ p?.updates[0]?.created_datetime | date: 'EEE, MMM d • h:mmaa' }}
                            </h6>
                          </div>
                          <p
                            *ngIf="p?.updates[0].project_health_type?.id"
                            class="mb-0 px-2 br-4 ml-auto bg-green white font-weight-semibold"
                            [ngClass]="{
                              'bg-green': p?.updates[0].project_health_type?.id === 1,
                              'bg-orange': p?.updates[0].project_health_type?.id === 2,
                              'bg-red': p?.updates[0].project_health_type?.id === 3
                            }"
                            style="font-size: 11px; line-height: 20px"
                          >
                            {{ p?.updates[0]?.project_health_type?.name }}
                          </p>
                        </div>
                        <a
                          class="m-0 ltblue font-weight-semibold hover-u mb-3 text-decoration-none"
                          [routerLink]="['/projects/' + p.id]"
                        >
                          {{ p?.code }}{{ p?.title ? ' - ' : '' }} {{ p?.title }}
                        </a>
                        <p class="m-0 mt-3 dkblue font-weight-normal" [innerHTML]="p?.updates[0].message"></p>
                      </div>
                    </div>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
          <div class="not-found-prompt" [hidden]="isLoading || allProjects.length > 0">
            <div class="row my-5">
              <div class="col-md-5 py-5 mx-auto text-center">
                <h5 class="dkblue lh-24">Well, this is awkward... Your search didn't yield any results.</h5>
                <p class="dkgray lh-24">Perhaps broaden your search, or double-check your spelling.</p>
                <button
                  *ngIf="filtersAppliedCount > 0 || search.value"
                  mat-stroked-button
                  color="accent"
                  class="mt-2 bg-white"
                  (click)="handleClearAllSelectedFilters(); search.setValue(null)"
                >
                  Clear Filters & Search
                </button>
              </div>
            </div>
          </div>
        </main>

        <main id="key_controls_view_title_sixty_one" *ngIf="isTitleSixtyOneKeyControlsView">
          <app-title-sixy-one-key-control-columns
            (emitOpenViewKeyControlsDialog)="openViewKeyControlsDialog($event)"
            (emitUpdateSortByField)="updateSortByField($event)"
            [projectKeyControlTemplates]="projectKeyControlTemplates"
            [filter]="filter"
            [projectFilter]="projectFilter"
            [projects]="allProjects"
            [fieldToSortBy]="fieldToSortBy"
            [sortDirection]="sortDirection"
            [startIndex]="startIndex"
            [endIndex]="endIndex"
          ></app-title-sixy-one-key-control-columns>
        </main>
        <main id="key_controls_view_title_seventy_four" *ngIf="isTitleSeventyFourKeyControlsView">
          <app-title-seventy-four-key-control-columns
            (emitOpenViewKeyControlsDialog)="openViewKeyControlsDialog($event)"
            (emitUpdateSortByField)="updateSortByField($event)"
            [projectKeyControlTemplates]="projectKeyControlTemplates"
            [filter]="filter"
            [projectFilter]="projectFilter"
            [projects]="allProjects"
            [fieldToSortBy]="fieldToSortBy"
            [sortDirection]="sortDirection"
            [startIndex]="startIndex"
            [endIndex]="endIndex"
          ></app-title-seventy-four-key-control-columns>
        </main>
        <mat-paginator
          #paginator
          [hidden]="projectsCount <= pageSize"
          showFirstLastButtons
          class="bg-transparent dkblue"
          color="primary"
          [length]="projectsCount"
          [pageSize]="pageSize"
          (page)="pageChange($event)"
        >
        </mat-paginator>
      </section>
    </mat-drawer-content>
    <mat-drawer #drawer mode="over" position="end" autoFocus="false">
      <app-latest-update
        *ngIf="drawer.opened"
        [projectUpdates]="projectUpdates"
        [drawer]="drawer"
        (panelOpened)="getProjectUpdates()"
      ></app-latest-update>
    </mat-drawer>
  </mat-drawer-container>
</div>

import { Pipe, PipeTransform } from '@angular/core';

import { User } from 'src/app/types';

@Pipe({
  name: 'directoryUsersFilter',
})
export class DirectoryUsersFilterPipe implements PipeTransform {
  transform(users: User[], userType: number, searchString: string, isAR: boolean, filteredCount: any): User[] {
    if (!users) {
      return users;
    }

    if (userType && userType === 4) {
      users = users.filter((user) => user.is_guest_user === 1);
    } else if (userType) {
      users = users.filter((user) => user.user_type_id === userType && user.is_guest_user === 0);
    }

    if (searchString) {
      searchString = searchString.toLowerCase();
      users = users.filter(
        (user) =>
          `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchString) ||
          (user.company_name && user.company_name.toLowerCase().includes(searchString)) ||
          (user.department_name && user.department_name.toLowerCase().includes(searchString)) ||
          (user.building_name && user.building_name.toLowerCase().includes(searchString)) ||
          (user.floor_name && user.floor_name.toLowerCase().includes(searchString)) ||
          (user.suite_name && user.suite_name.toLowerCase().includes(searchString)) ||
          (user.cell_phone && user.cell_phone.toString().includes(searchString)) ||
          (user.title && user.title.toLowerCase().includes(searchString)) ||
          (user.department_name && user.department_name.toLowerCase().includes(searchString)) ||
          (user.email && user.email.toLowerCase().includes(searchString))
      );
    }

    if (isAR) {
      users = users.filter((user) => user.is_ar);
    }
    filteredCount.count = users.length;
    return users;
  }
}

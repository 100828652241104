import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import {
  AgendaItemDialogComponent,
  DatepickerHeaderComponent,
  MeetingSelectDialogComponent,
  ReminderDialogComponent,
  WorkOrderDialogComponent,
} from 'src/app/components';
import { ResourceType, TaskAccessoryType, TaskReviewStatus, TaskStatus } from 'src/app/enums';
import {
  AuthService,
  LinkedTaskService,
  MeetingService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
  RemindersService,
  TaskActionsService,
  UserService,
  WorkOrderService,
} from 'src/app/services';
import { LinkedWOTask, Task } from 'src/app/types';

@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss'],
})
export class TaskViewComponent implements OnInit {
  @Input() taskData: Task | LinkedWOTask = {}; // The data for the task that this component is handling
  @Input() preventTaskSelection = false; // This prevents the task from being selected which is useful when using this component outside of a project.
  @Input() previousTaskStatusId: number = null;
  @Input() projectManagerId: number;
  @Input() showUndoTaskStatus = false;
  @Input() isReAssigningUserAndTaskId: { isReAssigningUser: boolean; taskId: number }; // Used when the view task is the one changing the assigned user
  @Input() showParent = false;
  @Output() taskAssignmentChanged = new EventEmitter<Task>();
  @Output() taskUpdate = new EventEmitter<Task>();
  @Output() convertedToLinkedTask = new EventEmitter<{ task: LinkedWOTask; oldTaskId: number }>();
  @Output() clickedTask = new EventEmitter<Task | LinkedWOTask>();
  @Output() taskDeleted = new EventEmitter<number>();

  currentProjectId: number; // Project Id gathered by route currently
  isReAssigningUser = false;
  isAssigningUser: boolean; // Are we currently assigning a new user via the search bar?

  dueDateText: string; // Text string for the due date

  // conversationCount: number;

  // conversations$: Observable<UhatFileReference[]>;

  isFollowing = false;

  customHeader = DatepickerHeaderComponent;

  public reviewIsEditable: boolean;

  public get taskStatus() {
    return TaskStatus;
  }

  public get TaskReviewStatus() {
    return TaskReviewStatus;
  }

  public get canConvertToLinkedTask() {
    return (
      !this.isLinkedTask &&
      'can_delete' in this.taskData &&
      this.taskData.can_delete &&
      !this.isLocked &&
      (!('accessory_data' in this.taskData) || !this.taskData.accessory_data)
    );
  }

  public get isLocked() {
    return this.taskData && 'is_locked' in this.taskData && +this.taskData.is_locked === 1;
  }

  public get isKeyControlTask() {
    return +this.accessoryData?.type === TaskAccessoryType.KeyControls;
  }

  public get isContractTask() {
    return this.authService.reviewedByVendor(this.accessoryData?.type);
  }

  public get isLinkedTask() {
    return 'work_order_id' in this.taskData && this.taskData.work_order_id !== null;
  }

  public get isDeletedLinkedTask() {
    return 'work_order_id' in this.taskData && this.taskData.work_order_id !== null && !this.taskData.work_order;
  }

  public get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.taskData?.module_id);
  }
  public get canEditWO() {
    return (
      'work_order' in this.taskData &&
      'module_id' in this.taskData.work_order &&
      this.authService.isUserWorkspaceStaff(this.taskData?.work_order?.module_id)
    );
  }

  public get followers() {
    return 'followers' in this.taskData && this.taskData.followers ? this.taskData.followers : null;
  }

  public get noteCount() {
    return 'note_count' in this.taskData ? this.taskData.note_count : 0;
  }

  public get fileCount() {
    return 'file_count' in this.taskData ? this.taskData.file_count : 0;
  }

  public get accessoryData() {
    return 'accessory_data' in this.taskData ? JSON.parse(this.taskData.accessory_data) : null;
  }

  public get currentReviewer() {
    return this.accessoryData?.reviewChain?.find((review) => review.id === this.authService.currentUser.id);
  }

  public get fileApprovals() {
    return 'file_approvals' in this.taskData ? this.taskData.file_approvals : null;
  }

  public get workOrderCode() {
    return 'work_order' in this.taskData ? this.taskData.work_order.code : '';
  }

  public get parent() {
    const taskData = this.taskData as any;
    const parent = [
      taskData.arf?.module?.name,
      taskData.arf?.code,
      taskData.arf?.title,
      taskData.project?.module?.name,
      taskData.project?.code,
      taskData.project?.building?.code,
      taskData.project?.floor?.code,
      taskData.project?.title,
    ];
    return parent.filter((p) => p).join(' | ');
  }

  constructor(
    private projectService: ProjectService,
    private remindersService: RemindersService,
    private taskService: ProjectTaskService,
    private taskActionsService: TaskActionsService,
    private meetingService: MeetingService,
    private modalService: ModalService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private userService: UserService,
    public authService: AuthService,
    private linkedTaskService: LinkedTaskService,
    private router: Router,
    private location: Location,
    private progressIndicatorService: ProgressIndicatorService,
    private workOrderService: WorkOrderService
  ) {}

  async ngOnInit() {
    // TODO refactor files into conversations
    // this.conversations$ = await this.fileService.loadFilesForResourceType(
    //   ResourceType.Task,
    //   this.taskData.id
    // );
    this.isFollowing = this.followers
      ? this.followers.find((f) => f.id.toString() === this.authService.getLoggedInUser().id.toString()) != null
      : false;

    // removing this as it is just unnecessary api calls
    // add back in if we need better real-time updating (but websockets should fix this issue)
    // this.taskService.taskSelectedEvent.subscribe(taskSelected => {
    //   if (taskSelected && this.taskData.id === taskSelected.id) {
    //     this.noteService.getNoteCountForTask(this.taskData.id).subscribe(count => this.noteCount = count);
    //     this.messageService.getConversationCountForTask(this.taskData.id).subscribe(count => this.conversationCount = count);
    //   }
    // });

    // TODO: Have Milestone Container Set Value In Service
    this.currentProjectId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.dueDateText = this.getDueDateFormattedText();

    // TODO TODO add these to the milestone load?
    // this.conversationCount = this.taskData.conversation_count;

    this.reviewIsEditable = this.authService.reviewIsEditable(this.accessoryData?.type);
  }

  openAddReminderDialog() {
    const dialogRef = this.dialog.open(ReminderDialogComponent, {
      data: {
        parent_type_id: ResourceType.Task,
        parent_id: this.taskData.id,
        due_datetime: this.taskData.due_date
          ? moment(this.taskData.due_date).hour(8).format('YYYY-MM-DD HH:mm:ss')
          : null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Creating Reminder..');
        this.remindersService.createReminder(result).subscribe((reminder) => {
          this.snackbar.open('Reminder created!');
        });
        this.progressIndicatorService.close();
      }
    });
  }

  public async remindTask() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Sending Reminder..');
    const updatedTask = await this.taskService.sendTaskReminder(this.taskData.id).toPromise();
    this.updateTask(updatedTask, true);
    await this.taskService.selectTaskById(updatedTask.id, false).toPromise();
    this.progressIndicatorService.close();
    this.snackbar.open('Task reminder sent');
  }

  createMeeting() {
    const linkedTask = this.taskData as LinkedWOTask;
    this.modalService.createMeeting({
      type_id: 1,
      parent_type_id: this.isLinkedTask ? ResourceType.WorkOrder : ResourceType.Task,
      parent_id: this.isLinkedTask ? linkedTask.work_order_id : this.taskData.id,
    });
  }

  public async changeTaskStatus(taskStatusId) {
    this.taskData = await this.taskActionsService.changeTaskStatus(
      taskStatusId,
      this.taskData,
      this.accessoryData.type
    );
  }

  public async convertToLinkedWOTask(taskData) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus(`Loading...`);
    const taskInfo = await this.projectService
      .getTaskById(taskData.id, [
        'milestone_id',
        'display_order_hash',
        'module_id',
        'title',
        'description',
        'assigned_user_id',
        'due_date',
        'files',
        'project{building{id,name},floor{id,name},suite{id,name},department{id,name},landmark,request{short_description,topic_id},project_manager_id}',
      ])
      .toPromise();
    this.progressIndicatorService.close();

    // including topic causes module to be locked, so don't include module
    const workOrder = {
      building: taskInfo.project?.building,
      title: taskInfo.title,
      floor: taskInfo.project?.floor,
      suite: taskInfo.project?.suite,
      department: taskInfo.project?.department,
      landmark: taskInfo.project?.landmark,
      summary: taskInfo.description,
      // topic_id: taskInfo.project?.request?.topic_id,
      assigned_user_id: taskInfo.assigned_user_id,
      requester: this.authService.currentUser,
      due_date: taskInfo.due_date,
      follower_ids: `[${taskInfo.project?.project_manager_id}]`,
      module_id: taskInfo.module_id,
    };

    this.dialog
      .open(WorkOrderDialogComponent, {
        width: '780px',
        disableClose: true,
        data: {
          ...workOrder,
          linkedTaskId: taskData.id,
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(async (createdWorkOrder) => {
        if (createdWorkOrder) {
          this.snackbar
            .open('Work Order Created!', 'View Work Order', { duration: 8000 })
            .onAction()
            .subscribe(() => {
              this.router.navigateByUrl(`/work-orders/${createdWorkOrder.id}`);
            });

          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus(`Creating Linked Task...`);
          const newTask = await this.linkedTaskService.createLinkedWOTask(taskData.id, createdWorkOrder.id).toPromise();
          this.progressIndicatorService.close();
          this.convertedToLinkedTask.emit({ task: newTask, oldTaskId: taskData.id });
          this.goToLinkedTask(newTask);
        }
      });
  }

  public convertTaskClicked() {
    this.modalService
      .openRequestTaskReviewDialog({
        task: this.taskData as Task,
      })
      .subscribe(({ task }) => {
        if (task) {
          this.updateTask(task);
          this.taskService.selectTaskById(task.id, false).subscribe();
        }
      });
  }

  changeTaskDueDate(dueDate) {
    this.taskData.due_date = dueDate || null;
    this.dueDateText = this.getDueDateFormattedText();
    const formattedDueDate = (dueDate && moment(dueDate).format('YYYY-MM-DD')) || null;
    this.taskData.due_date = formattedDueDate;

    if (!this.isLinkedTask) {
      this.projectService
        .updateTask({ id: this.taskData.id, due_date: formattedDueDate })
        .toPromise()
        .then((updatedTask) => {
          this.updateTask(updatedTask);
        });
    } else {
      const task = this.taskData as LinkedWOTask;
      const work_order_id = task.work_order_id;
      if (work_order_id) {
        this.workOrderService.updateWorkOrder(work_order_id, { due_date: formattedDueDate }).subscribe();
        task.work_order.due_date = dueDate || null;
        this.taskData = task;
        this.goToLinkedTask(this.taskData);
      }
    }
  }

  deactivateTask() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Delete Task',
        headerText: 'Delete Task',
        descriptionText: 'Are you sure that you want to delete this task? This action cannot be undone.',
        confirmationButtonText: 'Delete Task',
        cancelButtonText: 'Never Mind',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this.location.replaceState(`projects/${this.projectService.currentSelectedProjectId}/tasks`);
          this.clickedTask.emit();
          if (!this.isLinkedTask) {
            this.taskService.taskSelectedEvent.emit();
            this.projectService.deactivateTask(this.taskData.id).subscribe();
          } else {
            this.linkedTaskService.deactivateTask(this.taskData.id).subscribe();
          }
          this.taskDeleted.emit(this.taskData.id);
          this.snackbar.open('Task Removed');
        }
      });
  }

  public get daysUntilDueDate() {
    return this.taskData.due_date && this.daysUntilDate(this.taskData.due_date);
  }
  public get shouldShowDueDateText(): boolean {
    return this.daysUntilDueDate && this.taskData.status_id !== 3;
  }

  public updateTask(taskData: Task, reAssignedUser = false) {
    this.taskData = taskData;
    this.dueDateText = this.getDueDateFormattedText();
    this.taskService.updateTask(taskData);

    if (reAssignedUser) {
      this.taskAssignmentChanged.emit(taskData);
    } else {
      this.taskUpdate.emit(taskData);
    }
  }

  private getDueDateFormattedText(): string {
    const daysUntilDueDate = this.daysUntilDueDate;
    const dueInFuture = daysUntilDueDate > 0;
    const pastDue = daysUntilDueDate < 0;
    const oneDayDiff = daysUntilDueDate === 1 || daysUntilDueDate === -1;
    const dueDateText = `Due${dueInFuture ? ' in' : ''} ${pastDue ? -1 * daysUntilDueDate : daysUntilDueDate} Day${
      oneDayDiff ? '' : 's'
    }${pastDue ? ' ago' : ''}`;

    return dueDateText;
  }

  private daysUntilDate(date) {
    return moment(date).startOf('day').diff(moment().startOf('day'), 'days');
  }

  async selectThisTask(clickEvent?) {
    if (!this.preventTaskSelection) {
      if (clickEvent) {
        clickEvent.stopPropagation();
      }
      if (!this.isLinkedTask) {
        this.taskService.taskSelectedEvent.emit({ task: this.taskData as Task, navigate: true });
        this.clickedTask.emit(this.taskData);
      } else {
        this.goToLinkedTask(this.taskData);
      }
    }
  }

  public goToLinkedTask(newTask: LinkedWOTask | Task) {
    this.taskService.taskSelectedEvent.emit();
    if (
      this.location.path().includes(`projects/${newTask.project_id}`) ||
      this.location.path().includes(`linked-tasks/${newTask.id}`)
    ) {
      this.location.replaceState(`projects/${newTask.project_id}/linked-tasks/${newTask.id}`);
    } else {
      this.router.navigateByUrl(`projects/${newTask.project_id}/linked-tasks/${newTask.id}`);
    }
    this.clickedTask.emit(newTask);
  }

  public stopPropagation(event) {
    event.stopPropagation();
  }

  copyShareTaskLink(task) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${window.location.origin}/tasks/${task.id}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  createMeetingAgendaFromTask(task) {
    const linkedTask = this.taskData as LinkedWOTask;
    const meetingSelectDialogRef = this.dialog.open(MeetingSelectDialogComponent, {
      data: {
        title: 'Select Meeting for New Agenda Item',
        parent_type_id: this.isLinkedTask ? ResourceType.WorkOrder : ResourceType.Task,
        parent_id: this.isLinkedTask ? linkedTask.work_order.id : task.id,
      },
    });

    meetingSelectDialogRef.afterClosed().subscribe((returnedMeeting) => {
      if (returnedMeeting) {
        const agendaDialogRef = this.dialog.open(AgendaItemDialogComponent, {
          width: '480px',
          data: {
            meeting_id: returnedMeeting.id,
            meeting_title: returnedMeeting.title,
            meeting_code: returnedMeeting.code,
          },
        });

        agendaDialogRef.afterClosed().subscribe((agendaItem) => {
          if (agendaItem) {
            const agendaItemToAdd = {
              meeting_id: returnedMeeting.id,
              description: agendaItem.description,
              duration: agendaItem.duration,
              parent_type_id: this.isLinkedTask ? ResourceType.WorkOrder : ResourceType.Task,
              parent_id: this.isLinkedTask ? linkedTask.work_order.id : task.id,
            };
            this.meetingService.addAgendaItem(agendaItemToAdd).subscribe((newAgendaItem) => {
              this.snackbar.open('Agenda item added!');
            });
          }
        });
      }
    });
  }

  public get isApproval() {
    return this.accessoryData?.isReviewItem;
  }

  public allowedToDeleteTask(task) {
    return (
      !this.isLinkedTask &&
      task.can_delete &&
      this.authService.allowedToDeleteTasks(task.project_id, task.created_by_id, task.module_id)
    );
  }

  public openAssignUserDialog() {
    if ((!this.isLinkedTask && !this.isWorkspaceStaff) || (this.isLinkedTask && !this.canEditWO)) {
      return;
    }
    this.modalService.openUserSearchModal(this.taskData).subscribe(async (selectedUser) => {
      // the user canceled the search
      if (selectedUser === false) {
        return;
      }
      // otherwise, set the user (null = unassigned)
      if (
        selectedUser == null ||
        selectedUser.is_login_enabled ||
        (await this.modalService
          .openInviteUserModal({
            users: [selectedUser],
            parentId: this.taskData.id,
            parentTypeId: ResourceType.Task,
          })
          .toPromise())
      ) {
        // Assigned user id can be null if is to be cleared
        const assigned_user_id = selectedUser ? selectedUser.id : null;

        if (!this.isLinkedTask) {
          // Update the task assignee
          this.isReAssigningUser = true;
          this.projectService.updateTask({ id: this.taskData.id, assigned_user_id }).subscribe((task) => {
            this.updateTask(task, true);
            this.isReAssigningUser = false;
            this.taskService.selectTaskById(task.id, false).subscribe(); // This to refresh the task panel    :/   - Yes, it is an emoji
          });
        } else {
          const task = this.taskData as LinkedWOTask;
          const workOrderId = task.work_order_id;
          if (workOrderId) {
            this.workOrderService.updateWorkOrder(workOrderId, { assigned_user_id }).subscribe();
            task.assigned_user_id = assigned_user_id;
            task.work_order.assigned_user = selectedUser;
            task.assigned_user_first_name = selectedUser?.first_name;
            task.assigned_user_last_name = selectedUser?.last_name;
            task.assigned_user_login_enabled = true;
            this.taskData = task;
            this.goToLinkedTask(this.taskData);
          }
        }
      }
    });
  }

  public async undoTaskStatusChange() {
    await this.changeTaskStatus(this.previousTaskStatusId);
    this.previousTaskStatusId = null;
  }

  public getTaskStatusText(taskStatusId) {
    switch (taskStatusId) {
      case TaskStatus.Open:
        return 'Incomplete';
      case TaskStatus.Pending:
        return 'Pending';
      case TaskStatus.OnHold:
        return 'On Hold';
      case TaskStatus.Complete:
        return 'Complete';
      default:
        return 'Unknown';
    }
  }

  async editWorkOrder() {
    const task = this.taskData as LinkedWOTask;
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading work order...');
    const workOrder = await this.workOrderService.getWorkOrderById(task.work_order_id).toPromise();
    this.progressIndicatorService.close();
    this.dialog
      .open(WorkOrderDialogComponent, {
        width: '780px',
        disableClose: true,
        data: {
          ...workOrder,
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(async (updatedWorkOrder) => {
        if (updatedWorkOrder) {
          const work_order_id = task.work_order_id;
          if (work_order_id) {
            const linkedTask = await this.linkedTaskService.getLinkedWOTaskForWorkOrder(work_order_id).toPromise();
            this.taskData.assigned_user_id = linkedTask.work_order.assigned_user?.id;
            this.taskData.assigned_user_first_name = linkedTask.work_order.assigned_user?.first_name;
            this.taskData.assigned_user_last_name = linkedTask.work_order.assigned_user?.last_name;
            this.taskData.due_date = linkedTask.work_order.due_date;
            this.dueDateText = this.getDueDateFormattedText();
            this.taskData.title = linkedTask.work_order.title;
            Object.assign(task.work_order, linkedTask.work_order);
            if (!linkedTask.work_order.assigned_user_id) {
              task.work_order.assigned_user = null;
            }
            if (!linkedTask.work_order.priority_id) {
              task.work_order.priority = null;
            }
            this.goToLinkedTask(this.taskData);
          }
        }
      });
  }
}

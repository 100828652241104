<div
  class="search-overlay-panel ease d-flex"
  [ngClass]="{
    'panel-closed': !isPanelOpen(),
    'panel-open': isPanelOpen(),
    'sidenav-closed': !isSidenavOpen()
  }"
>
  <div class="search-panel-container w-100">
    <div class="d-flex m-0 py-4 pr-3 pl-3 search_header align-items-center">
      <mat-form-field class="flex-grow-1" appearance="outline">
        <!-- <span *ngIf="searchProject" class="font-weight-semibold white bg-ltblue pointer p-1 pr-2 pl-2 mr-2 br-5"
          matPrefix>PRJ 20007:</span> -->
        <mat-icon class="dkblue" matPrefix>search</mat-icon>
        <mat-icon
          id="cancelSearch"
          class="gray pointer"
          *ngIf="searchTerm"
          matSuffix
          (click)="clearSearch()"
          matTooltip="Clear Search"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
          >cancel</mat-icon
        >
        <!-- TODO AUTOFOCUS AFTER CLICKING SEARCH -->
        <input
          matInput
          #searchInput
          id="searchInput"
          type="text"
          minLength="3"
          placeholder="What are you looking for?"
          [(ngModel)]="searchTerm"
          (keyup.enter)="search()"
        />
      </mat-form-field>
      <button
        mat-flat-button
        color="success"
        class="lh-34 ease px-3 ml-2 mb-1"
        [disabled]="!searchTerm || searchTerm?.trim().length < 3"
        [ngClass]="{ 'bg-dkgray gray opacity5': !searchTerm || searchTerm?.trim().length < 3 }"
        (click)="search()"
      >
        Search
      </button>
      <button (click)="closeSearchPanel()" type="button" mat-button class="gray ml-2 lh-34 px-2 mb-1">Close</button>
    </div>
    <!-- <div class="row p-0 pr-3 pl-3 pb-3 pt-0">
      <div class="col">
        <mat-chip-list class="d-inline-block align-middle">
          <mat-chip class="dkblue" *ngFor="let fs of filteredSections">
            <span class="pointer">
              {{ fs.label }}
            </span>
            <mat-icon matChipRemove (click)="removeFilter(fs)">cancel</mat-icon>
          </mat-chip>
          <button mat-button [matMenuTriggerFor]="filter_menu" class="ltblue" matTooltip="Add Filter"
            matTooltipPosition="above" [matTooltipShowDelay]="300">
            + Filter
          </button>
          <mat-menu #filter_menu="matMenu" xPosition="after">
            <button mat-menu-item *ngFor="let s of sections" (click)="addFilter(s)">
              {{ s.label }}
            </button>
          </mat-menu>
        </mat-chip-list>
      </div>
    </div> -->

    <div id="search-results" class="row p-0 m-0">
      <div class="col">
        <div class="p-5 text-center w-100" *ngIf="loaders.search">
          <mat-spinner class="mb-4 mx-auto" diameter="40" color="accent"></mat-spinner>
          <span class="ltblue"> Looking through all the<br />nooks and crannies </span>
        </div>
        <div class="p-4 text-center w-100" *ngIf="searchPerformed && !hasResults">
          Oops, I didn't find anything for '{{ lastSearchTerm }}'
        </div>

        <div *ngIf="searchPerformed && hasResults">
          <section
            *ngFor="let s of filteredSections && filteredSections.length > 0 ? filteredSections : sections"
            class="row search-section"
          >
            <div *ngIf="results[s.name] && results[s.name].length > 0" class="col">
              <div class="row section-header align-items-center p-0 pt-5 pb-3">
                <div class="col">
                  <h4 (click)="s.hidden = !s.hidden" class="dkblue pointer font-weight-semibold m-0">
                    {{ s.label }}
                    <span class="ml-2 gray">
                      {{ results[s.name].length > 100 ? '100+' : results[s.name].length }}
                    </span>
                  </h4>
                </div>
                <div class="col-auto">
                  <div class="pointer" (click)="s.hidden = !s.hidden">
                    <h6 class="gray m-0 d-inline-block align-middle">
                      {{ s.hidden ? 'Show' : 'Hide' }}
                    </h6>
                    <mat-icon class="align-middle gray ml-2">
                      {{ s.hidden ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="!s.hidden">
                <div class="col p-0">
                  <div *ngFor="let r of results[s.name] | slice: 0:100" class="d-flex item ease p-3">
                    <!-- ARFs -->
                    <div class="col p-0" *ngIf="s.name === 'arfs'">
                      <div class="d-flex align-items-top">
                        <div class="d-flex pr-3">
                          <mat-icon class="ltblue">request_quote</mat-icon>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkblue m-0 font-weight-semibold hover-u pointer lh-18">
                              {{ pipeJoin([r.code, r.company?.name]) }}
                            </p>
                          </a>
                          <a [routerLink]="r?.link || '#'">
                            <h6 class="dkgray m-0 font-weight-normal lh-18 mt-3 hover-u pointer">
                              {{ r.title }}
                            </h6>
                          </a>
                          <div class="d-flex mt-3">
                            <span *ngFor="let badge of [r.module?.name, r.arf_status?.name, undefined]">
                              <h6
                                *ngIf="badge"
                                class="
                                  badge
                                  dkblue
                                  bg-shade-ltblue
                                  text-uppercase
                                  m-0
                                  font-weight-semibold
                                  lh-14
                                  px-2
                                  mr-2
                                "
                              >
                                {{ badge }}
                              </h6>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- INVOICES -->
                    <div class="col p-0" *ngIf="s.name === 'invoices'">
                      <div class="d-flex align-items-top">
                        <div class="d-flex pr-3">
                          <mat-icon class="ltblue">monetization_on</mat-icon>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <div (click)="viewInvoice(r)">
                            <p class="dkblue m-0 font-weight-semibold hover-u pointer lh-18">
                              {{ pipeJoin([r.code, r.company?.name]) }}
                            </p>
                          </div>
                          <div (click)="viewInvoice(r)">
                            <h6 class="dkgray m-0 font-weight-normal lh-18 mt-3 hover-u pointer">
                              {{ pipeJoin([r.number, r.title]) }}
                            </h6>
                          </div>
                          <div class="d-flex mt-3">
                            <span
                              *ngFor="
                                let badge of [
                                  r?.module_id
                                    ? r?.arf?.module?.name ||
                                      r?.bid_package?.project?.module?.name ||
                                      r?.quote?.module?.name
                                    : '',
                                  r?.status_name || ''
                                ]
                              "
                            >
                              <h6
                                *ngIf="badge"
                                class="
                                  badge
                                  dkblue
                                  bg-shade-ltblue
                                  text-uppercase
                                  m-0
                                  font-weight-semibold
                                  lh-14
                                  px-2
                                  mr-2
                                "
                              >
                                {{ badge }}
                              </h6>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- PROJECTS -->
                    <div *ngIf="s.name === 'projects'">
                      <div class="d-flex align-items-top">
                        <div class="d-flex pr-3">
                          <mat-icon class="ltblue">assignment_turned_in</mat-icon>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkblue m-0 font-weight-semibold hover-u pointer lh-18">
                              {{ r.code }} | {{ r.building_code }}{{ r.floor?.code ? '-' + r.floor?.code : ''
                              }}{{ r.title ? ' | ' + r.title : '' }}
                            </p></a
                          >
                          <h6 class="dkblue m-0 font-weight-normal lh-18 mt-2">
                            {{ r.request?.topic_group_name || '' }}
                            <span *ngIf="r?.request?.topic_group_name && r?.request?.topic_category_name"> > </span>
                            {{ r.request?.topic_category_name || '' }}
                            <span
                              *ngIf="
                                (r?.request?.topic_group_name || r?.request?.topic_category_name) &&
                                r?.request?.topic_name
                              "
                            >
                              >
                            </span>
                            {{ r.request?.topic_name || '' }}
                          </h6>
                          <h6
                            class="dkgray m-0 font-weight-normal lh-18 mt-2"
                            [innerHTML]="r.summary | htmlEncodeDecode"
                            *ngIf="r.summary"
                          >
                            <!--                            <span class="ltblue pointer">View More</span>-->
                          </h6>
                          <div class="d-flex mt-3">
                            <h6
                              class="
                                badge
                                dkblue
                                bg-shade-ltblue
                                text-uppercase
                                m-0
                                font-weight-semibold
                                lh-14
                                px-2
                                mr-2
                              "
                            >
                              {{ r.module?.name }}
                            </h6>
                            <h6
                              class="
                                badge
                                dkblue
                                bg-shade-ltblue
                                text-uppercase
                                m-0
                                font-weight-semibold
                                lh-14
                                px-2
                                mr-2
                              "
                            >
                              {{ r.status?.name }}
                            </h6>
                            <h6
                              class="
                                badge
                                dkblue
                                bg-shade-ltblue
                                text-uppercase
                                m-0
                                font-weight-semibold
                                lh-14
                                px-2
                                mr-2
                              "
                              *ngIf="r.priority_name"
                            >
                              {{ r.priority_name }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- WORK ORDERS  -->
                    <div *ngIf="s.name === 'workorders'">
                      <div class="d-flex align-items-top">
                        <div class="d-flex pr-3">
                          <mat-icon class="ltblue">description</mat-icon>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkblue m-0 font-weight-semibold hover-u pointer lh-18">
                              {{ r.code }} | {{ r.building_code }}{{ r.floor?.code ? '-' + r.floor?.code : ''
                              }}{{ r.title ? ' | ' + r.title : '' }}
                            </p></a
                          >
                          <h6
                            class="dkblue m-0 font-weight-normal lh-18 mt-2"
                            *ngIf="r.topic_group_name && r.topic_category_name && r.topic_name"
                          >
                            {{ r.topic_group_name }} > {{ r.topic_category_name }} >
                            {{ r.topic_name }}
                          </h6>
                          <h6
                            class="dkgray m-0 font-weight-normal lh-18 mt-2"
                            [innerHTML]="r.summary | htmlEncodeDecode"
                            *ngIf="r.summary"
                          >
                            <!--                            <span class="ltblue pointer">View More</span>-->
                          </h6>
                          <div class="d-flex mt-3">
                            <h6
                              class="
                                badge
                                dkblue
                                bg-shade-ltblue
                                text-uppercase
                                m-0
                                font-weight-semibold
                                lh-14
                                px-2
                                mr-2
                              "
                            >
                              {{ r.module?.name }}
                            </h6>
                            <h6
                              class="
                                badge
                                dkblue
                                bg-shade-ltblue
                                text-uppercase
                                m-0
                                font-weight-semibold
                                lh-14
                                px-2
                                mr-2
                              "
                            >
                              {{ r.status?.name }}
                            </h6>
                            <h6
                              class="
                                badge
                                dkblue
                                bg-shade-ltblue
                                text-uppercase
                                m-0
                                font-weight-semibold
                                lh-14
                                px-2
                                mr-2
                              "
                              *ngIf="r.priority_name"
                            >
                              {{ r.priority_name }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- TASKS -->
                    <div class="col p-0" *ngIf="s.name === 'tasks'">
                      <div class="d-flex align-items-top">
                        <div class="d-flex pr-3">
                          <mat-icon class="ltblue">{{
                            r.status_id === 3 ? 'check_circle' : r.status_id === 2 ? 'flag' : 'radio_button_unchecked'
                          }}</mat-icon>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkblue m-0 font-weight-semibold hover-u pointer lh-18">
                              {{ r.title }}
                              <span
                                *ngIf="r.accessory_data"
                                class="badge white bg-orange text-uppercase m-0 font-weight-semibold lh-14 px-2 ml-2"
                              >
                                REVIEW
                              </span>
                            </p></a
                          >
                          <p
                            *ngIf="r.description"
                            [innerHTML]="r.description | htmlEncodeDecode"
                            class="dkblue m-0 font-weight-normal lh-18 mt-3"
                          ></p>
                          <div class="d-flex mt-3">
                            <h6 class="dkblue m-0 font-weight-semibold lh-18 mr-4">
                              Assigned To:
                              {{
                                r.assigned_user
                                  ? r.assigned_user.first_name + ' ' + r.assigned_user.last_name
                                  : 'Unassigned'
                              }}
                            </h6>
                            <h6 class="dkblue m-0 font-weight-semibold lh-18">
                              Due:
                              {{ r.due_date ? (r.due_date | date: 'MMM d yyyy') : 'No Due Date' }}
                            </h6>
                          </div>
                          <a [routerLink]="r?.link || '#'">
                            <h6 class="dkgray m-0 font-weight-normal lh-18 mt-3 hover-u pointer" *ngIf="r.project">
                              {{ r.project?.code }} | {{ r.project?.building_code
                              }}{{ r.project?.floor_code ? '-' + r.project?.floor_code : ''
                              }}{{ r.project?.title ? ' | ' + r.project?.title : '' }}
                            </h6></a
                          >
                        </div>
                      </div>
                    </div>

                    <!-- MEETING AGENDAS -->
                    <!-- removing this for now, because there is minimal data here, and the agenda notes and parent meeting can both be searched -->
                    <!--                    <div class="col" *ngIf="s.name === 'agendas'">-->
                    <!--                      <div class="row align-items-center">-->
                    <!--                        <div class="col-auto pr-0" style="width: 30px">-->
                    <!--                          <mat-icon class="ltgray mt-2"> event_note </mat-icon>-->
                    <!--                        </div>-->
                    <!--                        <div class="col">-->
                    <!--                          <a [routerLink]="r?.link || '#'">-->
                    <!--                          <p-->
                    <!--                            class="dkblue m-0 font-weight-semibold hover-u pointer lh-18"-->
                    <!--                          >-->
                    <!--                            {{ r.code }} | {{ r.description }}-->
                    <!--                          </p>-->
                    <!--                        </a>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->

                    <!-- BIDS -->
                    <!-- <div class="col" *ngIf="s.name === 'bids'">
                      <div class="row align-items-center">
                        <div class="col-auto" style="width: 30px;">
                          <mat-icon class="ltgray mt-2">
                            file_copy
                          </mat-icon>
                        </div>
                        <div class="col item-message-content">
                          <h6 class="font-weight-semibold m-0 dkblue">
                            {{ r.description }}
                            <small>
                              {{ r.code }}
                            </small>
                            <br />
                            <small *ngIf="r.project_code" class="ltblue">
                              PRJ: {{ r.project_code }}
                              <i class="la la-angle-right ml-1 mr-1"></i>
                              <span *ngIf="r.task_code">
                                {{ r.task_code }}
                              </span>
                            </small>
                          </h6>
                        </div>
                      </div>
                    </div> -->

                    <!-- <div class="col" *ngIf="s.name === 'change_orders'">
                      <div class="row align-items-center">
                        <div class="col-auto" style="width: 30px;">
                          <mat-icon class="ltgray mt-2">
                            fiber_manual_record
                          </mat-icon>
                        </div>
                        <div class="col item-message-content">
                          <h6 class="font-weight-semibold m-0 dkblue">
                            {{ r.description }}
                            <small>
                              r.code
                            </small>
                            <br />
                            <small *ngIf="r.project_code" class="ltblue">
                              {{ r.project_code }}{{ r.project_title ? ' • ' : ''
                              }}{{ r.project_title }}
                              <i class="la la-angle-right ml-1 mr-1"></i>
                              {{ r.task_code }}
                            </small>
                          </h6>
                        </div>
                      </div>
                    </div> -->

                    <!-- <div class="col" *ngIf="s.name === 'conversations'">
                      <div class="row align-items-center">
                        <div class="col-auto" style="width: 30px;">
                          <mat-icon class="ltgray mt-2">
                            forum
                          </mat-icon>
                        </div>
                        <div class="col item-message-content">
                          <h6 class="font-weight-semibold m-0 dkblue">
                            {{ r.subject }}
                            <br />
                            <small *ngIf="r.code" class="ltblue">
                              {{ r.code }}
                            </small>
                          </h6>
                        </div>
                      </div>
                    </div> -->

                    <!-- FILES -->
                    <div class="col p-0" *ngIf="s.name === 'files'">
                      <div class="d-flex align-items-top">
                        <div class="d-flex pr-3">
                          <mat-icon class="gray">file_present</mat-icon>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <!-- click to go to parent -->
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkblue m-0 font-weight-semibold hover-u pointer lh-18">
                              {{ r.name }}
                            </p></a
                          >
                          <h6 class="dkgray m-0 font-weight-normal lh-18">
                            {{ r.created_datetime | date: 'MMM d yyyy • h:mm aa' }} by {{ r.created_by_first_name }}
                            {{ r.created_by_last_name }}
                          </h6>
                          <!-- IF TASK -->
                          <!--                          Hide this for now, because the parent is usually project, not task, resulting in incorrect data shown-->
                          <!--                          <h6 class="dkblue m-0 font-weight-semibold lh-18 mt-4">-->
                          <!--                            <span *ngIf="r.task_code">{{ r.task_code }} | {{ r.task_title }}</span>-->
                          <!--                          </h6>-->
                          <!-- WO OR PROJECT PARENT -->
                          <a [routerLink]="r?.link || '#'">
                            <h6 class="dkgray m-0 font-weight-normal lh-18 pointer hover-u">
                              <span *ngIf="r.project_code">
                                {{ r.project_code }} | {{ r.project_building_code
                                }}{{ r.project_floor_code ? '-' + r.project_floor_code : ''
                                }}{{ r.project_title ? ' | ' + r.project_title : '' }}
                              </span>
                            </h6></a
                          >
                        </div>
                      </div>
                    </div>

                    <!-- WORK ORDER UPDATES -->
                    <div class="col p-0" *ngIf="s.name === 'workorderupdates'">
                      <div class="d-flex align-items-top">
                        <div class="d-flex table-avatar pr-3">
                          <app-profile-thumbnail
                            class="d-inline-block align-middle mr-3"
                            [userId]="r.created_by.id"
                            [overlayIconOpacity]="1"
                            [overlayIcon]="r.created_by.is_ar ? 'verified_user' : ''"
                            [overlayIconColor]="'dkgray'"
                            [overlayIconBackground]="'white'"
                            [overlayIconTooltip]="'Authorized Requester'"
                          ></app-profile-thumbnail>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkblue m-0 font-weight-semibold hover-u pointer lh-18">
                              {{ r.created_by.first_name }} {{ r.created_by.last_name }}
                              <br />
                              <small class="dkgray">{{ r.created_datetime | date: 'MMM d yyyy • h:mm aa' }}</small>
                            </p></a
                          >
                          <p
                            *ngIf="r.message"
                            [innerHTML]="r.message | htmlEncodeDecode"
                            class="dkblue m-0 font-weight-normal lh-18 mt-2"
                          ></p>
                          <a [routerLink]="r?.link || '#'">
                            <h6 class="dkgray m-0 font-weight-normal lh-18 mt-3 hover-u pointer">
                              <span>
                                {{ r.work_order?.code }} | {{ r.work_order?.building_code
                                }}{{ r.work_order?.floor_code ? '-' + r.work_order?.floor_code : ''
                                }}{{ r.work_order?.title ? ' | ' + r.work_order?.title : '' }}
                              </span>
                            </h6></a
                          >
                        </div>
                      </div>
                    </div>

                    <!-- PROJECT UPDATES -->
                    <div class="col p-0" *ngIf="s.name === 'projectupdates'">
                      <div class="d-flex align-items-top">
                        <div class="d-flex table-avatar pr-3">
                          <app-profile-thumbnail
                            class="d-inline-block align-middle mr-3"
                            [userId]="r.created_by.id"
                            [overlayIconOpacity]="1"
                            [overlayIcon]="r.created_by.is_ar ? 'verified_user' : ''"
                            [overlayIconColor]="'dkgray'"
                            [overlayIconBackground]="'white'"
                            [overlayIconTooltip]="'Authorized Requester'"
                          ></app-profile-thumbnail>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkblue m-0 font-weight-semibold lh-18 pointer hover-u">
                              {{ r.created_by.first_name }} {{ r.created_by.last_name }}
                              <br />
                              <small class="dkgray">{{ r.created_datetime | date: 'MMM d yyyy • h:mm aa' }}</small>
                            </p></a
                          >
                          <p
                            *ngIf="r.message"
                            [innerHTML]="r.message | htmlEncodeDecode"
                            class="dkblue m-0 font-weight-normal lh-18 mt-2"
                          ></p>
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkgray m-0 font-weight-normal lh-18 mt-3 hover-u pointer">
                              <span>
                                {{ r.project?.code }} | {{ r.project?.building_code
                                }}{{ r.project?.floor_code ? '-' + r.project?.floor_code : ''
                                }}{{ r.project?.title ? ' | ' + r.project?.title : '' }}
                              </span>
                            </p></a
                          >
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col" *ngIf="s.name === 'file'">
                      <div class="row align-items-center">
                        <div class="col-auto" style="width: 30px;">
                          <mat-icon class="ltgray mt-2">
                            insert_drive_file
                          </mat-icon>
                        </div>
                        <div class="col item-message-content">
                          <h6 class="font-weight-semibold m-0 dkblue">
                            {{ r.name }}
                            <br />
                            <small *ngIf="r.project_code" class="ltblue">
                              PRJ: {{ r.project_code }}
                              <i class="la la-angle-right ml-1 mr-1"></i>
                              <span *ngIf="r.task_code">
                                {{ r.task_code }}
                              </span>
                            </small>
                          </h6>
                        </div>
                      </div>
                    </div> -->

                    <!-- MEETINGS -->
                    <div class="col p-0" *ngIf="s.name === 'meetings'">
                      <div class="d-flex align-items-top">
                        <div class="d-flex pr-3">
                          <mat-icon class="purple">event</mat-icon>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkblue m-0 font-weight-semibold hover-u pointer lh-18">
                              {{ r.title }}
                            </p></a
                          >
                          <h6 class="dkblue m-0 font-weight-semibold lh-18 mr-4 mt-2">
                            {{ r.start_datetime ? (r.start_datetime | date: 'EEEE, MMM d yyyy • h:mm aa') : 'Not Set' }}
                          </h6>
                          <h6 class="dkgray m-0 font-weight-normal lh-18 mt-2">
                            {{ r.purpose ? r.purpose : 'N/A' }}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <!-- MESSAGES -->
                    <!-- <div class="col" *ngIf="s.name === 'messages'">
                      <div class="row align-items-center">
                        <div class="col-auto pr-0 table-avatar">
                          <img [src]="getProfileThumbnailUrl(r.created_by_id)" />
                        </div>
                        <div class="col item-message-content">
                          <p class="dkblue m-0 font-weight-semibold">
                            <span class="mr-2">
                              {{ r.created_by_first_name }} {{ r.created_by_last_name }}
                            </span>
                            <small class="gray font-weight-normal">
                              {{ r.created_datetime | date: 'MMM d yyyy • h:mm aa' }}
                            </small>
                          </p>
                          <p class="dkblue m-0 font-weight-normal">
                            {{ r.conversation_subject }}
                          </p>
                          <p class="gray m-0 font-weight-normal">
                            {{ r.content }}
                          </p>
                        </div>
                      </div>
                    </div> -->

                    <!-- MILESTONES -->
                    <!-- <div class="col" *ngIf="s.name === 'milestones'">
                      <div class="row align-items-center">
                        <div class="col-auto" style="width: 30px;">
                          <mat-icon class="ltgray mt-2">
                            fiber_manual_record
                          </mat-icon>
                        </div>
                        <div class="col">
                          <p class="font-weight-semibold m-0 dkblue">
                            {{ r.name }}
                            <small>
                              {{ r.code }}
                            </small>
                            <br />
                            <small class="ltblue">
                              <span *ngIf="r.project_code">
                                PRJ: {{ r.project_code }}{{ r.project_title ? ' • ' : ''
                                }}{{ r.project_title }}
                              </span>
                              <span *ngIf="r.task_code"> | TSK {{ r.task_code }} </span>
                            </small>
                          </p>
                        </div>
                      </div>
                    </div> -->

                    <!-- TASK NOTES -->
                    <div *ngIf="s.name === 'tasknotes'">
                      <div class="d-flex align-items-top">
                        <div class="d-flex table-avatar pr-3">
                          <app-profile-thumbnail
                            class="d-inline-block align-middle mr-3"
                            [userId]="r.created_by.id"
                            [overlayIconOpacity]="1"
                            [overlayIcon]="r.created_by.is_ar ? 'verified_user' : ''"
                            [overlayIconColor]="'dkgray'"
                            [overlayIconBackground]="'white'"
                            [overlayIconTooltip]="'Authorized Requester'"
                          ></app-profile-thumbnail>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkblue m-0 font-weight-semibold hover-u pointer lh-18">
                              {{ r.created_by.first_name }} {{ r.created_by.last_name }}
                              <br />
                              <small class="dkgray">{{ r.created_datetime | date: 'MMM d yyyy • h:mm aa' }}</small>
                            </p></a
                          >
                          <p
                            *ngIf="r.message"
                            [innerHTML]="r.message | htmlEncodeDecode"
                            class="dkblue m-0 font-weight-normal lh-18 mt-3"
                          ></p>
                          <a [routerLink]="r?.link || '#'">
                            <h6 class="dkblue m-0 font-weight-semibold lh-18 hover-u pointer">
                              <span>{{ r.task_title }}</span>
                            </h6></a
                          >
                          <a [routerLink]="r?.link || '#'">
                            <h6 class="dkgray m-0 font-weight-normal lh-18 hover-u pointer">
                              <span>
                                {{ r.project_code }} | {{ r.project_building_code
                                }}{{ r.project_floor_code ? '-' + r.project_floor_code : ''
                                }}{{ r.project_title ? ' | ' + r.project_title : '' }}
                              </span>
                            </h6></a
                          >
                        </div>
                      </div>
                    </div>

                    <!-- AGENDA NOTES -->
                    <div *ngIf="s.name === 'agendanotes'">
                      <div class="d-flex align-items-top">
                        <div class="d-flex table-avatar pr-3">
                          <app-profile-thumbnail
                            class="d-inline-block align-middle mr-3"
                            [userId]="r.created_by.id"
                            [overlayIconOpacity]="1"
                            [overlayIcon]="r.created_by.is_ar ? 'verified_user' : ''"
                            [overlayIconColor]="'dkgray'"
                            [overlayIconBackground]="'white'"
                            [overlayIconTooltip]="'Authorized Requester'"
                          ></app-profile-thumbnail>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkblue m-0 font-weight-semibold hover-u pointer lh-18">
                              {{ r.created_by.first_name }} {{ r.created_by.last_name }}
                              <br />
                              <small class="dkgray">{{ r.created_datetime | date: 'MMM d yyyy • h:mm aa' }}</small>
                            </p>
                          </a>
                          <p
                            *ngIf="r.message"
                            [innerHTML]="r.message | htmlEncodeDecode"
                            class="dkblue m-0 font-weight-normal lh-18 mt-3"
                          >
                            {{ r.message }}
                          </p>
                          <h6 class="dkblue m-0 font-weight-semibold lh-18 mt-4">
                            <span>{{ r.agenda_code }} | {{ r.agenda_description }}</span>
                          </h6>
                          <a [routerLink]="r?.link || '#'">
                            <h6 class="dkgray m-0 font-weight-normal lh-18 hover-u pointer">
                              <span> {{ r.meeting_code }} | {{ r.meeting_title }} </span>
                            </h6></a
                          >
                        </div>
                      </div>
                    </div>

                    <!-- PROPOSAL REQUESTS -->
                    <!-- <div class="col" *ngIf="s.name === 'proposal_requests'">
                      <div class="row align-items-center">
                        <div class="col-auto" style="width: 30px;">
                          <mat-icon class="ltgray mt-2">
                            fiber_manual_record
                          </mat-icon>
                        </div>
                        <div class="col item-message-content">
                          <h6 class="font-weight-semibold m-0 dkblue">
                            {{ r.title }}
                            <small>
                              r.code
                            </small>
                            <br />
                            <small *ngIf="r.project_code" class="ltblue">
                              {{ r.project_code }}{{ r.project_title ? ' • ' : ''
                              }}{{ r.project_title }}
                              <i class="la la-angle-right ml-1 mr-1"></i>
                              {{ r.task_code }}
                            </small>
                          </h6>
                        </div>
                      </div>
                    </div> -->

                    <!-- PUNCHLISTS -->
                    <!-- <div class="col" *ngIf="s.name === 'punchlists'">
                      <div class="row align-items-center">
                        <div class="col-auto" style="width: 30px;">
                          <mat-icon class="ltgray mt-2">
                            fiber_manual_record
                          </mat-icon>
                        </div>
                        <div class="col item-message-content">
                          <h6 class="font-weight-semibold m-0 dkblue">
                            {{ r.description }}
                            <br />
                            <small *ngIf="r.project_code" class="ltblue">
                              {{ r.project_code }}{{ r.project_title ? ' • ' : ''
                              }}{{ r.project_title }}
                              <i class="la la-angle-right ml-1 mr-1"></i>
                              {{ r.task_code }}
                            </small>
                          </h6>
                        </div>
                      </div>
                    </div> -->

                    <!-- <div class="col" *ngIf="s.name === 'reminders'">
                      <div class="row align-items-center">
                        <div class="col-auto mt-2" style="width: 30px;">
                          <mat-icon class="ltgray">{{ r.status_id === 2 ? 'check_circle' : 'radio_button_unchecked' }}
                          </mat-icon>
                        </div>
                        <div class="col item-message-content">
                          <h6 class="font-weight-semibold m-0 dkblue">
                            {{ r.description }}
                            <small>
                              {{ r.code }}
                            </small>
                            <br />
                            <small *ngIf="r.project_code" class="ltblue">
                              {{ r.project_code }}{{ r.project_title ? ' • ' : ''
                              }}{{ r.project_title }}
                              <i class="la la-angle-right ml-1 mr-1"></i>
                              {{ r.task_code }}
                            </small>
                          </h6>
                        </div>
                      </div>
                    </div> -->

                    <!-- REQUESTS -->
                    <div *ngIf="s.name === 'requests'">
                      <div class="d-flex align-items-top">
                        <div class="d-flex pr-3">
                          <mat-icon class="ltblue">assignment_late</mat-icon>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkblue m-0 font-weight-semibold hover-u pointer lh-18">
                              {{ r.code }} | {{ r.building_code }}{{ r.floor_code ? '-' + r.floor_code : ''
                              }}{{ r.short_description ? ' | ' + r.short_description : '' }}
                            </p></a
                          >
                          <h6
                            class="dkblue m-0 font-weight-normal lh-18 mt-2"
                            *ngIf="r.topic_group_name && r.topic_category_name && r.topic_name"
                          >
                            {{ r.topic_group_name }} > {{ r.topic_category_name }} >
                            {{ r.topic_name }}
                          </h6>
                          <h6
                            class="dkgray m-0 font-weight-normal lh-18 mt-2"
                            [innerHTML]="r.summary | htmlEncodeDecode"
                            *ngIf="r.summary"
                          >
                            <!--                            <span class="ltblue pointer">View More</span>-->
                          </h6>
                          <div class="d-flex">
                            <h6
                              class="
                                badge
                                dkblue
                                bg-shade-ltblue
                                text-uppercase
                                m-0
                                font-weight-semibold
                                lh-14
                                px-2
                                mr-2
                              "
                            >
                              {{ r.workspace?.name }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- RFIS -->
                    <!-- <div class="col" *ngIf="s.name === 'rfis'">
                      <div class="row align-items-center">
                        <div class="col-auto" style="width: 30px;">
                          <mat-icon class="ltgray mt-2">
                            fiber_manual_record
                          </mat-icon>
                        </div>
                        <div class="col item-message-content">
                          <h6 class="font-weight-semibold m-0 dkblue">
                            {{ r.description }}
                            <small>
                              {{ r.code }}
                            </small>
                            <br />
                            <small *ngIf="r.project_code" class="ltblue">
                              {{ r.project_code }}{{ r.project_title ? ' • ' : ''
                              }}{{ r.project_title }}
                              <i class="la la-angle-right ml-1 mr-1"></i>
                              {{ r.task_code }}
                            </small>
                          </h6>
                        </div>
                      </div>
                    </div> -->

                    <!-- USERS -->
                    <div *ngIf="s.name === 'users'">
                      <div class="d-flex table-avatar align-items-top">
                        <app-profile-thumbnail
                          class="d-inline-block align-middle mr-3"
                          height="40"
                          width="40"
                          [userId]="r.id"
                          [overlayIconOpacity]="1"
                          [overlayIcon]="r.is_ar ? 'verified_user' : ''"
                          [overlayIconColor]="'dkgray'"
                          [overlayIconBackground]="'white'"
                          [overlayIconTooltip]="'Authorized Requester'"
                        ></app-profile-thumbnail>
                        <div class="d-flex flex-column flex-grow-1 pl-3">
                          <!-- click go to profile -->
                          <a [routerLink]="r?.link || '#'">
                            <p class="dkblue m-0 font-weight-semibold hover-u pointer lh-18">
                              {{ r.first_name }} {{ r.last_name }}
                            </p></a
                          >
                          <h6 class="dkblue m-0 font-weight-normal lh-18 mt-1">
                            {{ r.title }}
                          </h6>
                          <h6 class="dkblue m-0 font-weight-normal lh-18 mt-1">
                            {{ r.department_name ? r.department_name : r.company_name ? r.company_name : '' }}
                          </h6>
                          <div class="d-flex mt-2">
                            <h6
                              class="
                                badge
                                dkblue
                                bg-shade-ltblue
                                text-uppercase
                                m-0
                                font-weight-semibold
                                lh-14
                                px-2
                                mr-2
                              "
                            >
                              {{ r.user_type_name != 'Staff' ? r.user_type_name : 'UHAT/1Call' }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<div
  class="ease"
  (click)="closeSearchPanel()"
  [ngClass]="{ 'search-overlay': isPanelOpen() }"
></div> -->

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgendaItem, Note } from 'src/app/types';

@Component({
  selector: 'app-meeting-export-dialog',
  templateUrl: './meeting-export-dialog.component.html',
  styleUrls: ['./meeting-export-dialog.component.scss'],
})
export class MeetingExportDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MeetingExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public agendaItems
  ) {}

  async ngOnInit() {
    this.agendaItems.forEach((agendaItem: AgendaItem) => {
      agendaItem.selected = true;
      agendaItem.hasAttachments = !!agendaItem.notes?.find((note: Note) => note.files?.length);
      agendaItem.selectedAttachments = !!agendaItem.notes?.find((note: Note) => note.files?.length);
    });
  }

  submit() {
    this.dialogRef.close(this.agendaItems.filter((item: AgendaItem) => item.selected === true));
  }

  close() {
    this.dialogRef.close(false);
  }
}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { maxBy, orderBy, uniqBy } from 'lodash';
import * as moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subscription } from 'rxjs';
import {
  MilestoneDialogComponent,
  MilestoneTaskContainerComponent,
  UserSelectDropdownComponent,
} from 'src/app/components';
import { ProjectStatus, ResourceType } from 'src/app/enums';
import {
  AuthService,
  ExportService,
  LinkedTaskService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
} from 'src/app/services';
import { LinkedWOTask, Milestone, Phase, Task, User } from 'src/app/types';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit, AfterViewChecked, OnDestroy {
  public assignedUsers: User[] = [];
  public phases: Phase[];

  public phase: Phase;

  public loadingMilestones = true;
  public milestones: Milestone[] = [];

  public phaseLength: string;

  public currentUser: User;

  public taskPanelOpen = false;

  private scrolled = false;

  public editTasks = false;

  public showFilters = false;

  public hideComplete = this.taskService?.filterSettings?.getForHideCompleted();
  public approvalsOnly = this.taskService?.filterSettings?.getForApprovalsOnly();
  public myTasksOnly = this.taskService?.filterSettings?.getShowForUserIds();

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  @ViewChild('userSelectDropDown') _userSelectDropDown: UserSelectDropdownComponent;

  @Output() public phaseUpdated = new EventEmitter();

  divWidth: number;
  showDesktop: boolean;
  showIpad: boolean;
  overlay_task_panel = false;

  allowedToMoveLeft: boolean;
  allowedToMoveRight: boolean;

  public showSearch: boolean;
  public searchTerm: string;
  public showAssigneeFilter: boolean;

  private loadedPhases = {};
  public isLastPhase = false;

  public filterMenuOpen = true;

  public isLinkedWOTask = false;

  forms = [
    { name: 'Project Details', phase_name: 'Program', route: '/details' },
    { name: 'Program Data', phase_name: 'Program', route: '/programming' },
    { name: 'PEB', phase_name: 'Budget', route: '/peb' },
    { name: 'Drawings', phase_name: 'Design', route: '/drawings' },
    { name: 'Addendums', phase_name: 'Design', route: '/addendums' },
    { name: 'Bids', phase_name: 'Bidding', route: '/bids' },
    { name: `RFIs`, phase_name: 'Bidding', route: '/rfi' },
    { name: 'Construction Budget', phase_name: 'Bidding', route: '/construction-budget' },
    { name: 'Submittals', phase_name: 'Construction', route: '/submittals' },
    { name: `RFIs`, phase_name: 'Construction', route: '/rfi' },
    { name: `PRs`, phase_name: 'Construction', route: '/proposal-requests' },
    { name: 'Change Orders', phase_name: 'Construction', route: '/change-orders' },
    { name: 'Punch List', phase_name: 'Closeout', route: '/punchlist' },
    // { name: 'Invoices', phase_name: 'Construction', route: '/invoices' },
    { name: 'As-Builts', phase_name: 'Closeout', route: '/as-builts' },
    // { name: 'Invoices', phase_name: 'Closeout', route: '/invoices' },
  ];

  private subscriptions: Subscription[] = [];
  public currentLinkedTask: LinkedWOTask = null;

  @ViewChildren('taskContainer') milestoneContainers: QueryList<MilestoneTaskContainerComponent>;

  constructor(
    public authService: AuthService,
    public projectService: ProjectService,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    public taskService: ProjectTaskService,
    private activeRoute: ActivatedRoute,
    private progressIndicator: ProgressIndicatorService,
    private modalService: ModalService,
    private exportService: ExportService,
    private location: Location,
    private linkedTaskService: LinkedTaskService
  ) {}

  ngOnInit() {
    const mainScreen = document.querySelector('#aos_trigger');
    const ps = new PerfectScrollbar(mainScreen);
    setTimeout(async () => {
      const routeSubscription = this.activeRoute.params.subscribe(async (params) => {
        this.getDivWidth();

        // the task guard takes care of getting the project id if not passed
        if (
          params.subcomponent === 'tasks' ||
          params.subcomponent === 'linked-tasks' ||
          params.task_id ||
          params.linked_task_id ||
          params.phase_id
        ) {
          this.currentUser = this.authService.getLoggedInUser();
          await this.getPhases();
          this.getCurrentPhasePosition();

          // set this.phase
          if (params.task_id) {
            const task = await this.taskService.selectTaskById(params.task_id).toPromise();
            this.taskClicked(task);
            await this.getPhases();
            this.getCurrentPhasePosition();
            this.selectPhase(task.phase_id, null, false);
            this.taskPanelOpen = true;
          } else if (params.linked_task_id) {
            const task = await this.taskService.selectLinkedTaskById(params.linked_task_id).toPromise();
            this.taskClicked(task);
            await this.getPhases();
            this.getCurrentPhasePosition();
            this.selectPhase(task.phase_id, null, false);
            this.taskPanelOpen = true;
          } else if (params.phase_id) {
            // if there is a phase in params, select it
            this.selectPhase(+params.phase_id);
          } else {
            // if there is no task id in params, select current phase
            if (
              this.projectService.currentSelectedProject &&
              this.projectService.currentSelectedProject.current_phase_id
            ) {
              this.selectPhase(+this.projectService.currentSelectedProject.current_phase_id);
            } else if (!this.phase) {
              this.selectPhase(null, 0);
            }
          }
        }
      });
      this.subscriptions.push(routeSubscription);
      const refreshSubscription = this.projectService.refreshNeeded$.subscribe(async () => {
        this.refreshMilestones();
      });
      this.subscriptions.push(refreshSubscription);
    });
  }

  get canChangePhase() {
    return this.authService?.isProjectAdmin(
      this.projectService.currentSelectedProjectId,
      this.projectService.currentSelectedProject?.module_id
    );
  }

  get moduleId() {
    return this.projectService?.currentSelectedProject?.module_id;
  }

  get isModuleStaff() {
    return this.authService.isUserWorkspaceStaff(this.moduleId);
  }

  get isModuleAdmin() {
    return this.authService.isUserWorkspaceAdmin(this.moduleId);
  }

  ngAfterViewChecked() {
    // TODO: This scroll no longer works. I think it's because it's scrolling before task data is loaded.
    if (!this.scrolled && !this.isLinkedWOTask && this.taskService.currentSelectedTask) {
      const taskElement = document.getElementById(`taskElement${this.taskService.currentSelectedTask.id}`);
      if (taskElement) {
        taskElement.scrollIntoView({ behavior: 'smooth' });
        this.scrolled = true;
      }
    } else if (!this.scrolled && this.isLinkedWOTask && this.currentLinkedTask) {
      const taskElement = document.getElementById(`taskElement${this.currentLinkedTask.id}`);
      if (taskElement) {
        taskElement.scrollIntoView({ behavior: 'smooth' });
        this.scrolled = true;
      }
    }
  }

  async getPhases() {
    this.phases = await this.projectService
      .getPhasesByProjectId(this.projectService?.currentSelectedProjectId)
      .toPromise();
  }

  // Checks to see if the current selected phase is the last phase in the array.
  getCurrentPhasePosition() {
    const currentPhaseId = this.projectService.currentSelectedProject.current_phase_id;
    const phasePosition = this.phases.findIndex((phase) => phase.id === currentPhaseId);
    this.isLastPhase = phasePosition >= this.phases?.length - 1;
  }

  // Finds the next phase in the sequence and sends it to setPhase. This function does not need to check and see if the phase is last because of this.isLastPhase
  async completePhase(currentPhase) {
    const newCurrentPhase = this.phases.find((phase) => phase.sequence === currentPhase.sequence + 1);
    await this.selectPhaseInDirection('right');
    await this.setPhase(newCurrentPhase);
  }

  async setPhase(phase) {
    await this.projectService.setPhaseIdForProject(this.projectService.currentSelectedProjectId, phase.id).toPromise();
    this.snackbar.open('Project Phase set to ' + phase.name);
    await this.projectService.selectProjectById(this.projectService.currentSelectedProjectId);
    this.getCurrentPhasePosition();
    this.phaseUpdated.emit(phase);
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
    if (this.divWidth < 1100) {
      this.overlay_task_panel = true;
    }
    if (this.divWidth > 1100) {
      this.overlay_task_panel = false;
    }
    if (this.divWidth > 900) {
      this.showDesktop = true;
      this.showIpad = false;
    } else {
      this.showDesktop = false;
      this.showIpad = true;
    }
  }

  getDivWidthDelay() {
    setTimeout(() => {
      this.getDivWidth();
    }, 1000);
  }

  toggleTaskFilters() {
    this.editTasks = false;
    this.showFilters = !this.showFilters;
  }

  toggleTaskEdit() {
    this.showFilters = false;
    this.editTasks = !this.editTasks;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.milestones, event.previousIndex, event.currentIndex);

    const prev = event.currentIndex - 1 >= 0 ? this.milestones[event.currentIndex - 1] : null;
    const after = event.currentIndex + 1 < this.milestones?.length ? this.milestones[event.currentIndex + 1] : null;
    this.updateHash(this.milestones[event.currentIndex], prev, after);
    this.projectService.moveMilestoneNodeBefore(this.milestones[event.currentIndex], prev, after);
  }

  // locally set the new hash to correct value
  // if back end returns an error (because outdated data), we will refresh
  private updateHash(moved, prev, after) {
    const min = prev && prev.display_order_hash ? prev.display_order_hash : 0;
    const max = after && after.display_order_hash ? after.display_order_hash : 99999999;
    moved.display_order_hash = Math.round((min + max) / 2);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  public openAddMilestoneDialog() {
    const lastMilestone = maxBy(this.milestones, 'sequence');
    const dialogRef = this.dialog.open(MilestoneDialogComponent, {
      width: '380px',
      data: {
        phase_id: this.phase.id,
        sequence: (lastMilestone ? lastMilestone.sequence : 0) + 1,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.progressIndicator.openAwaitIndicatorModal();
        this.progressIndicator.updateStatus('Saving Milestone..');
        await this.projectService
          .createMilestone(result)
          .toPromise()
          .then(async (milestone) => {
            if (milestone) {
              if (!milestone.tasks) {
                milestone.tasks = [];
              }
              this.milestones.push(milestone);
              this.snackbar.open('Milestone Added Successfully');
            } else {
              console.error('No milestone added');
            }
          });
        this.progressIndicator.close();
      }
    });
  }

  shouldShowCompletePhaseButton(): boolean {
    return (
      this.phase &&
      this.projectService.currentSelectedProject.current_phase_id === this.phase.id &&
      this.projectService.currentSelectedProject.status_id !== ProjectStatus.CLOSED
    );
  }

  public async selectPhase(phaseId: number, phaseIndex?: number, deselectTask: boolean = true) {
    this.progressIndicator.openAwaitIndicatorModal();
    this.progressIndicator.updateStatus('Loading Tasks..');
    // phase fields = code,name
    if (!this.phases) {
      return;
    }
    if (phaseId) {
      const foundPhase = this.phases?.find((p) => p.id === phaseId);
      if (foundPhase) {
        this.phase = foundPhase;
      }
    } else if (phaseIndex >= 0 && phaseIndex <= this.phases?.length - 1) {
      this.phase = this.phases[phaseIndex];
    }
    // TODO: fallback in case id or Index isn't present
    this.allowedToMoveLeft = this.allowedToMovePhase('left');
    this.allowedToMoveRight = this.allowedToMovePhase('right');
    await this.refreshMilestones();
    if (deselectTask) {
      this.taskService.taskSelectedEvent.emit(null);
    }
    this.progressIndicator.close();
  }

  public taskClicked(task) {
    this.isLinkedWOTask = !!task?.work_order_id;
    if (this.isLinkedWOTask) {
      this.currentLinkedTask = task;
    } else {
      this.currentLinkedTask = null;
    }

    this.taskPanelOpen = !!task;
  }

  public drawerClosed() {
    this.taskPanelOpen = false;
    this.currentLinkedTask = null;
    this.location.replaceState(`projects/${this.projectService.currentSelectedProjectId}/tasks`);
  }

  public convertedWOLinkedTask(data: { task: LinkedWOTask; oldTaskId: number }) {
    const { task, oldTaskId } = data;
    this.milestones.forEach((milestone) => {
      if (+milestone.id === +task.milestone_id) {
        if (!milestone.tasks) {
          milestone.tasks = [];
        }
        milestone.tasks.push(task);
        const foundTask = milestone.tasks.find((t) => +t.id === +oldTaskId);
        if (foundTask) {
          milestone.tasks.splice(milestone.tasks.indexOf(foundTask), 1);
        }
      }
    });

    this.taskService.taskSelectedEvent.emit();
    this.location.replaceState(`projects/${this.projectService.currentSelectedProjectId}/linked-tasks/${task.id}`);
    this.taskClicked(task);
  }

  public deleteLinkedTask(task: LinkedWOTask) {
    this.location.replaceState(`projects/${this.projectService.currentSelectedProjectId}/tasks`);
    this.linkedTaskService.deactivateTask(task.id).subscribe();
    this.taskPanelOpen = false;
    this.milestones.forEach((milestone) => {
      if (+milestone.id === +task.milestone_id) {
        if (!milestone.tasks) {
          milestone.tasks = [];
        }
        const foundTask = milestone.tasks.find((t) => +t.id === +task.id);
        if (foundTask) {
          milestone.tasks.splice(milestone.tasks.indexOf(foundTask), 1);
        }
      }
    });
    this.taskClicked(null);
    this.snackbar.open('Task Removed');
  }

  public allowedToMovePhase(direction: 'left' | 'right'): boolean {
    let currentIndex = this.phase && this.phases.map((phase) => phase.id).indexOf(this.phase.id);
    let allowedToMove = true;
    if (direction === 'left') {
      currentIndex -= 1;
      // don't allow wrapping
      if (currentIndex < 0) {
        allowedToMove = false;
      }
      // if (currentIndex < 0) { currentIndex = this.phases.length - 1; }
    } else {
      currentIndex += 1;
      if (currentIndex >= this.phases?.length) {
        allowedToMove = false;
      }
      // if (currentIndex >= this.phases.length) { currentIndex = 0; }
    }
    return allowedToMove;
  }

  public async selectPhaseInDirection(direction: 'left' | 'right') {
    let currentIndex = this.phases.map((phase) => phase.id).indexOf(this.phase.id);
    if (direction === 'left') {
      currentIndex -= 1;
      // don't allow wrapping
      if (currentIndex < 0) {
        currentIndex = 0;
      }
    } else {
      currentIndex += 1;
      if (currentIndex >= this.phases?.length) {
        currentIndex = this.phases?.length;
      }
    }
    this.selectPhase(null, currentIndex);
  }

  public removeMilestone(milestone: Milestone) {
    this.milestones = this.milestones.filter((m) => m !== milestone);
  }

  public addUserIdTasksFilter(userId: number) {
    this.taskService.filterSettings.addUserIdTaskFilter(userId);
    this.myTasksOnly = this.taskService.filterSettings.getShowForUserIds();
  }

  public removeUserIdTasksFilter() {
    this.taskService.filterSettings.removeUserIdTasksFilter();
    this.myTasksOnly = this.taskService.filterSettings.getShowForUserIds();
  }

  public toggleHideCompleteFilter() {
    this.hideComplete = !this.hideComplete;
    this.taskService.filterSettings.toggleHideCompleted();
  }

  public toggleApprovalsOnlyFilter() {
    this.approvalsOnly = !this.approvalsOnly;
    this.taskService.filterSettings.toggleApprovalsOnly();
  }

  public updateSearchTerm(clear: boolean = false) {
    this.searchTerm = clear ? '' : this.searchTerm?.trim();
    this.taskService?.filterSettings?.updateSearchTerm(this.searchTerm?.trim());
  }

  public filterIndicator() {
    let count = 0;
    count += this.taskService?.filterSettings?.getForHideCompleted() ? 1 : 0;
    count += this.taskService?.filterSettings?.getForApprovalsOnly() ? 1 : 0;
    return count;
  }

  public async createTask() {
    this.modalService.openCreateTaskModal({ phaseName: this.phase.name }).subscribe(async (task) => {
      if (task) {
        this.milestones.forEach((milestone) => {
          if (+milestone.id === +task.milestone_id) {
            milestone.tasks.push(task);
          }
        });
        await this.refreshMilestones();
      }
    });
  }

  uploadFile() {
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    const attachmentData: any = {
      parentResourceType: ResourceType.Project,
      parentResourceId: this.projectService.currentSelectedProjectId,
      allowComment: false,
      allowSearchFromProject: false,
    };
    this.modalService.openFileAttachmentDialog(attachmentData).subscribe((resultData) => {
      if (resultData) {
        this.snackbar.open('Your upload is complete');
      }
    });
  }

  /**
   * This is done after you select a phase, create a milestone, or create a task
   * It assumes this.phase is set
   */
  private async refreshMilestones() {
    this.loadingMilestones = true;
    let milestones;
    if (
      this.phase &&
      this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject?.module_id) &&
      this.phase.id
    ) {
      milestones = await this.projectService.getMilestonesByPhaseIdMin(this.phase.id).toPromise();
      this.loadedPhases[this.phase.id] = milestones;
    } else {
      this.taskService.clearTasksForProject(); // Need to clear out cached data because of an issue where a task would make a milestone populate.
      // if the task is removed from the supplier/tenant due to it being unassigned to them.
      milestones = await this.projectService
        .getMilestonesByProjectId(this.projectService.currentSelectedProjectId)
        .toPromise();
    }

    milestones.forEach((m) => {
      if (!m.tasks) {
        m.tasks = [];
      }
    });

    this.milestones = milestones;
    this.taskService.sortTasksForMilestones(milestones);

    // reduce the task milestones to assigned user list through the
    const users: User[] = milestones.reduce((assigned_users: User[], milestone: Milestone): User[] => {
      return [
        ...assigned_users,
        ...milestone.tasks.map((task: Task) => ({
          id: +task.assigned_user_id,
          first_name: task.assigned_user_first_name,
          last_name: task.assigned_user_last_name,
        })),
      ];
    }, []);

    // remove duplicates and sort them
    this.assignedUsers = orderBy(uniqBy(users, 'id'), ['first_name', 'last_name'], ['asc', 'asc']);

    this.phaseLength = this.projectService.getPhaseLength(this.milestones);
    this.loadingMilestones = false;
  }

  ngOnDestroy(): void {
    // attempt to close all active subscriptions
    try {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
    } catch (e) {}
  }

  // ------------------------------------------------------------------
  // exporting functionality
  // ------------------------------------------------------------------

  public exportData() {
    if (!this.milestones || this.milestones.length === 0) {
      this.snackbar.open('No entries available to export with the current filter selection!');
      return;
    }
    const formattedDate = moment().format(`YYYY-MM-DD`);
    const projectName = this.projectService.currentSelectedProject?.code || 'Error';
    this.exportService.exportDataWithConfirmation(
      this.getExportData(),
      `PRJ_${projectName}_task_milestone_export_${formattedDate}.csv`,
      'Confirm Data Export',
      `This data export will use the currently selected filter settings. Click 'Yes' to confirm your download.`
    );
  }

  // format the current displayed data in csv
  private getExportData(): string[] {
    const dataToReturn: string[] = [
      'Milestone, Milestone Start Date, Milestone End Date, Task Title, Task Code, Assigned User, Task Due Date, Task Status, Task Description',
    ];
    for (const milestone of this.milestones) {
      for (const task of milestone.tasks) {
        // sanitize and push the data
        dataToReturn.push(
          this.exportService.sanitizeItems([
            milestone.name,
            milestone.start_date ? moment(milestone.start_date).format('MM/DD/YYYY') : null,
            milestone.end_date ? moment(milestone.end_date).format('MM/DD/YYYY') : null,
            task.title,
            task.code,
            task.assigned_user_first_name && task.assigned_user_last_name
              ? `${task.assigned_user_first_name} ${task.assigned_user_last_name}`
              : null,
            task.due_date ? moment(task.due_date).format('MM/DD/YYYY') : null,
            task.status_name,
            task.description,
          ])
        );
      }
    }

    return dataToReturn;
  }
}

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import {
  AgendaItemDialogComponent,
  DatepickerHeaderComponent,
  MeetingSelectDialogComponent,
  ProjectFollowersComponent,
  UserProfilePreviewComponent,
} from 'src/app/components';
import { ProjectDetailsViews, ProjectStatus as PROJECT_STATUS_TYPE, ResourceType, UserType } from 'src/app/enums';
import {
  AuthService,
  MeetingService,
  ModalService,
  NotificationService,
  ProgressIndicatorService,
  ProjectService,
  SearchService,
  SidenavService,
} from 'src/app/services';
import {
  Project,
  ProjectPriority,
  ProjectStatus,
  ProjectSubstatus,
  ProjectSubstatusCategory,
  Workspace,
} from 'src/app/types';
const NOT_SET_PRIORITY: ProjectPriority = { id: null, name: 'Not Set', abbreviation: 'None' };
@Component({
  selector: 'app-project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.scss'],
})
export class ProjectHeaderComponent implements OnInit, OnDestroy {
  @Output() closeProject = new EventEmitter();
  @Input() isClosedProject: boolean;
  @Input() project: Project;
  @Output() reActivateProject = new EventEmitter();
  @Output() setProjectToActive = new EventEmitter();
  @Output() setProjectToPlanned = new EventEmitter();
  @Output() setProjectToOnHold = new EventEmitter();
  @ViewChild(MatCalendar) _datePicker: MatCalendar<Date>;
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;

  constructor(
    private _sidenavService: SidenavService,
    private authService: AuthService,
    private dialog: MatDialog,
    private meetingService: MeetingService,
    private modalService: ModalService,
    public notificationService: NotificationService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    public searchService: SearchService,
    private snackbar: MatSnackBar
  ) {}

  customHeader = DatepickerHeaderComponent;
  @ViewChild('projectFollowers') projectFollowers: ProjectFollowersComponent;

  private refreshSubscription;
  public divWidth: number;
  public input_estimated_completion_date = new FormControl('');
  public projectPriorities: ProjectPriority[] = [];
  public projectSubstatusCategories: ProjectSubstatusCategory[] = [];

  ngOnInit(): void {
    this.projectService
      .getProjectPriorities(['id', 'name', 'abbreviation'])
      .subscribe((priorities: ProjectPriority[]) => {
        this.projectPriorities = [NOT_SET_PRIORITY, ...priorities];
      });

    this.projectService
      .getProjectSubStatusCategories(['id', 'name', 'substatuses{id,name,is_enabled}'])
      .toPromise()
      .then((res) => {
        res.forEach((c) => {
          c.substatuses = c.substatuses.filter((s) => s.is_enabled);
          this.projectSubstatusCategories.push(c);
        });
      });

    this.refreshSubscription = this.projectService.refreshNeeded$.subscribe(() => {
      if (this.project?.id) {
        this.projectService.getProjectById(this.project.id).subscribe((project) => (this.project = project));
      }
    });

    this.input_estimated_completion_date.valueChanges.subscribe((result) => {
      this.updateEstimatedCompletionDate((result && moment(result).format('YYYY-MM-DD')) ?? null);
    });
    this.getDivWidth();
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  get buildAndFloorCodes(): string {
    if (this.buildingCode && this.floorCode) {
      return `${this.buildingCode}-${this.floorCode}`;
    }
    return this?.buildingCode || this?.floorCode || '';
  }

  get buildingCode(): string {
    return this.project?.building_code || '';
  }

  get canReactivate() {
    return (
      this.project?.status_id === PROJECT_STATUS_TYPE.CLOSED &&
      (this.workspaceAdmin || (this.closedLessThan24Hours && (this.isTenant || this.isRequester)))
    );
  }

  get closedDate() {
    return moment(this.project?.closed_datetime).format('dddd, MMMM Do, YYYY');
  }

  get closedLessThan24Hours() {
    return moment().subtract(24, 'hours').isBefore(this.project?.closed_datetime);
  }

  get closedTime() {
    return moment(this.project?.closed_datetime).format('h:mm a');
  }

  get code(): string {
    return this.project?.code || '';
  }

  get estimatedCompletionDate(): string | Date {
    return this.project?.end_date;
  }

  get formattedEstimatedCompletionDate(): string {
    return moment(this.estimatedCompletionDate).isValid()
      ? moment(this.estimatedCompletionDate).format('ddd, MMM DD, YYYY')
      : 'Not Set';
  }

  get floorCode(): string {
    return this.project?.floor_code || '';
  }

  get isProjectAdmin() {
    return this.authService?.isProjectAdmin(this.project?.id, this.project?.module_id);
  }

  get isRequester() {
    return +this.project?.requester_id === +this.authService?.currentUser?.id;
  }

  get isSidenavClosed(): boolean {
    return this._sidenavService.isSidenavClosed;
  }

  get isStaff(): boolean {
    return this.authService.isStaffOnAnyModule;
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.project?.module_id);
  }

  get isTenant() {
    return this.authService.isTenant;
  }

  get isVendor(): boolean {
    return this.authService.currentUser?.user_type_id === UserType.Vendor;
  }

  get priority(): string {
    return this.project?.priority_name || '';
  }

  get priorityId(): number {
    return this.project?.priority_id;
  }

  get projectEndDateEstimation(): string {
    return this.project?.end_date_estimate || '';
  }

  get projectManagerName(): string {
    return `${this.project?.project_manager_first_name || ''} ${this.project?.project_manager_last_name || ''}`.trim();
  }

  get PROJECT_STATUSES() {
    return PROJECT_STATUS_TYPE;
  }

  get status(): ProjectStatus {
    return this.project?.status;
  }

  get statusName(): string {
    return this.status?.name || '';
  }

  get statusId(): number {
    return this.status?.id;
  }

  get substatus(): ProjectSubstatus {
    return this.project?.substatus;
  }

  get title(): string {
    return this.project?.title || '';
  }

  get workspace(): Workspace {
    return this.project?.module;
  }

  get parentProjectId(): number {
    return this.project?.parent_bid_package?.project_id;
  }

  get parentProjectCode(): number {
    return this.project?.parent_bid_package?.project_code;
  }

  get workspaceAdmin(): boolean {
    return this.authService.isUserWorkspaceAdmin(this.project?.module_id);
  }

  get workspaceName() {
    return this.workspace?.name || '';
  }

  async createTask() {
    const phaseName = await this.getPhaseName();
    this.modalService.openCreateTaskModal({ phaseName }).subscribe((task) => {
      this.projectService.taskCreatedEvent.emit(task);
    });
  }

  createMeeting() {
    this.modalService.createMeeting({
      type_id: this.project.module_id,
      parent_type_id: ResourceType.Project,
      parent_id: this.project.id,
    });
  }

  createMeetingAgendaFromProject() {
    const meetingSelectDialogRef = this.dialog.open(MeetingSelectDialogComponent, {
      data: {
        title: 'Select Meeting for New Agenda Item',
        parent_type_id: ResourceType.Project,
        parent_id: this.project.id,
      },
    });

    meetingSelectDialogRef.afterClosed().subscribe((returnedMeeting) => {
      if (returnedMeeting) {
        const agendaDialogRef = this.dialog.open(AgendaItemDialogComponent, {
          width: '480px',
          data: {
            meeting_id: returnedMeeting.id,
            meeting_title: returnedMeeting.title,
            meeting_code: returnedMeeting.code,
          },
        });

        agendaDialogRef.afterClosed().subscribe((agendaItem) => {
          if (agendaItem) {
            const agendaItemToAdd = {
              meeting_id: returnedMeeting.id,
              description: agendaItem.description,
              duration: agendaItem.duration,
              parent_type_id: ResourceType.Project,
              parent_id: this.project.id,
            };
            this.meetingService.addAgendaItem(agendaItemToAdd).subscribe((newAgendaItem) => {
              this.snackbar.open('Agenda item added!');
            });
          }
        });
      }
    });
  }

  private async getPhaseName() {
    const phases = await this.projectService.getPhasesByProjectId(this.project.id).toPromise();
    const phase = phases[0];

    return phase.name;
  }

  public getDivWidth() {
    setTimeout(() => {
      this.divWidth = this.elementView.nativeElement.offsetWidth;
    }, 300);
  }

  get isOverdue(): boolean {
    if (moment(this.estimatedCompletionDate).isValid() && moment() > moment(this.estimatedCompletionDate)) {
      return true;
    } else {
      return false;
    }
  }

  public onResize(event) {
    this.getDivWidth();
  }

  public openProjectDetailsDialog() {
    this.modalService
      .openProjectDetailsDialog(this.project.id, ProjectDetailsViews.Details)
      .toPromise()
      .then((updatedProject) => {
        if (updatedProject) {
          this.project = updatedProject;
        }
      });
  }

  public reActivate() {
    if (this.canReactivate) {
      this.modalService
        .openConfirmationDialog({
          titleBarText: 'Reactivate Project',
          headerText: 'Reactivate Project',
          descriptionText:
            'Please give a reason for reactivating. Your reason will be added as an update and will notify everyone on the project.',
          userInput: {
            required: true,
            placeholder: 'Reason For reactivation',
          },
          confirmationButtonText: 'Reactivate',
        })
        .subscribe(async (confirmationMessage) => {
          if (confirmationMessage) {
            this.reActivateProject.emit(confirmationMessage);
          }
        });
    }
  }

  public toActive() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Project Status',
        headerText: 'Set Project to Active',
        confirmationButtonText: 'Set to Active',
        descriptionText: 'Are you sure you want to set this project to active?',
        cancelButtonText: 'Cancel',
      })
      .subscribe(async (answer) => {
        if (answer) {
          this.setProjectToActive.emit();
        }
      });
  }

  public markAsPlanned() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Project Status',
        headerText: 'Mark As Planned',
        confirmationButtonText: 'Mark As Planned',
        descriptionText: 'Are you sure you want to mark this project as planned?',
        cancelButtonText: 'Cancel',
      })
      .subscribe(async (answer) => {
        if (answer) {
          this.setProjectToPlanned.emit();
        }
      });
  }

  public placeOnHold() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Project Status',
        headerText: 'Place On Hold',
        confirmationButtonText: 'Place On Hold',
        descriptionText: 'Are you sure you want to place this project on hold?',
        cancelButtonText: 'Cancel',
      })
      .subscribe(async (answer) => {
        if (answer) {
          this.setProjectToOnHold.emit();
        }
      });
  }

  public async close() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Checking all documents...');
    const hasUnprocessedInvoices = await this.projectService.checkForUnprocessedInvoices(this.project.id).toPromise();
    this.progressIndicatorService.close();

    if (hasUnprocessedInvoices) {
      this.modalService
        .openConfirmationDialog({
          titleBarText: 'Close Project',
          headerText: 'Warning: Pending Invoices',
          descriptionText:
            'This project still contains invoices pending review. All invoices must be processed or rejected before the project can be closed. ',
          hideConfirmationButton: true,
          cancelButtonText: 'Dismiss',
        })
        .subscribe();
    } else {
      this.modalService
        .openConfirmationDialog({
          titleBarText: 'Project Status',
          headerText: 'Close Project',
          confirmationButtonText: 'Close Project',
          descriptionText: 'Are you sure you want to close this project?',
          cancelButtonText: 'Cancel',
        })
        .subscribe(async (answer) => {
          if (answer) {
            this.closeProject.emit();
          }
        });
    }
  }

  public async updateEstimatedCompletionDate(end_date: string) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Updating Estimated Completion Date...');
    await this.projectService.updateProject(this.project.id, { end_date }, ['end_date']).toPromise();
    this.progressIndicatorService.close();
    this.snackbar.open('Estimated Completion Date Updated!');
  }

  public async updatePriority(priority: ProjectPriority) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Updating Priority...');
    await this.projectService.updateProject(this.project.id, { priority_id: priority.id }, ['priority_id']).toPromise();
    this.progressIndicatorService.close();
    this.snackbar.open('Priority Updated!');
  }

  public async updateSubstatus(substatus: ProjectSubstatus) {
    if (substatus?.id !== this.substatus?.id) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Updating Substatus...');
      await this.projectService
        .updateProject(this.project.id, { substatus_id: substatus.id }, ['substatus_id,substatus'])
        .toPromise();
      this.progressIndicatorService.close();
      this.snackbar.open('Substatus Updated!');
    }
  }

  public openUserProfilePreview(userId) {
    if (userId) {
      this.dialog.open(UserProfilePreviewComponent, {
        width: '400px',
        data: {
          userId,
        },
      });
    }
  }
}

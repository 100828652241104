import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, sortBy } from 'lodash';
import * as moment from 'moment';
import { DatepickerHeaderComponent, EditorComponent, RequestMethodComponent } from 'src/app/components';
import {
  ApplicationRole,
  ProjectDetailsViews,
  ProjectStatus,
  ResourceTags,
  ResourceType,
  Workspace,
  WorkspaceType,
} from 'src/app/enums';
import {
  AuthService,
  KeyControlsService,
  LocationService,
  ModuleService,
  ProgressIndicatorService,
  ProjectService,
  RequestService,
  ResourceTagsService,
  UserService,
} from 'src/app/services';
import {
  APIFilter,
  Building,
  Department,
  Floor,
  ProjectSubstatusCategory,
  RequestMethod,
  ResourceTag,
  Suite,
  User,
} from 'src/app/types';
import { ProjectRequestDetailsModalComponent } from 'src/app/workspaces/construction/components';
import { ConstructionBudget, ProjectConstruction, Trade } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-project-details-dialog',
  templateUrl: './project-details-dialog.component.html',
  styleUrls: ['./project-details-dialog.component.scss'],
})
export class ProjectDetailsDialogComponent implements OnInit {
  @ViewChild('descriptionEditor', { static: true })
  private _description_editor_component: EditorComponent;
  @ViewChild('fycReasonEditor', { static: true })
  private _fyc_reason_editor_component: EditorComponent;
  @ViewChild('requestMethod', { static: true })
  private _requestMethodComponent: RequestMethodComponent;
  @ViewChild('costCodeDescriptionEditor', { static: true })
  private _cost_code_description_editor_component: EditorComponent;
  ResourceType = ResourceType;
  public customHeader = DatepickerHeaderComponent;
  constructor(
    public authService: AuthService,
    private projectService: ProjectService,
    private fb: FormBuilder,
    private userService: UserService,
    private locationService: LocationService,
    private progressIndicatorService: ProgressIndicatorService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ProjectDetailsDialogComponent>,
    private snackbar: MatSnackBar,
    private moduleService: ModuleService,
    public keyControlsService: KeyControlsService,
    private requestService: RequestService,
    private resourceTagsService: ResourceTagsService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  buildings;
  floors;
  suites;
  departments;
  trades;
  shownProject;
  years = [];
  projectTags = [];
  CAPXids = [];
  capxDisplayFn = (o: any) => {
    return o ? `${o.capx_id} | ${o.description}` : 'None';
  };
  projectFields = [
    'title',
    'code',
    'scope_of_work',
    'request_id',
    'request_type_id',
    'request_type_name',
    'building_id',
    'building_code',
    'building_name',
    'floor_id',
    'floor_code',
    'floor_name',
    'suite_id',
    'suite_name',
    'department_id',
    'department_name',
    'square_footage',
    'trades',
    'uses_new_peb',
    'cfmo_id',
    'cfmo_first_name',
    'cfmo_last_name',
    'bsd',
    'dfs',
    'ico',
    'one_call_director',
    'project_manager_id',
    'project_manager_first_name',
    'project_manager_last_name',
    'workspace_manager_id',
    'workspace_manager_first_name',
    'workspace_manager_last_name',
    'architect_id',
    'architect_first_name',
    'architect_last_name',
    'is_architect_required',
    'account_coordinator_id',
    'account_coordinator',
    'is_dfs_required',
    'engineer_ids',
    'floor_plans',
    'floor_plan_ids',
    'priority_id',
    'priority_name',
    'construction_start_date',
    'end_date',
    'landmark',
    'misc_files',
    'fyc_reason',
    'fyc_approved_fiscal_year',
    'fyc_project_year',
    'fyc_approved_budget',
    'fyc_priority_id',
    'fyc_impact',
    'fyc_likely',
    'status{name}',
    'on_schedule',
    'module{name,show_dfs_toggle,workspace_type_id}',
    'construction_timeline',
    'design_timeline',
    'occupied_sqft',
    'total_timeline',
    'request{id,request_method_id,request_method{id,name,is_enabled,is_default,icon}}',
    'substatus',
    'tag_ids',
    'tags{is_enabled,resource_type_ids}',
    'cost_codes',
    'cost_code_description',
    'capx_id',
    'parent_id',
    'parent',
  ];

  userFields = [
    'email',
    'first_name',
    'last_name',
    'user_type_id',
    'company_id',
    'company_name',
    'title',
    'cell_phone',
    'is_login_enabled',
  ];

  @ViewChild('pdf', { static: true }) pdf;
  currentView: ProjectDetailsViews;
  unsavedChangesExist = false;
  downloading = false;
  loading = false;
  descriptionSubscription;
  fycReasonSubscription;
  workspace = Workspace;
  costCodesLength = 50;

  allWorkspaceManagers: User[];
  allArchitects: User[];
  allEngineers: User[];
  allCFMOs: User[];
  allProjectManagers: User[];
  allBSDs: User[];
  allDFSManagers: User[];
  allICOs: User[];
  allOneCallDirectors: User[];
  allAccountCoordinators: User[];

  project: ProjectConstruction;
  projectStatuses = [
    { label: 'Active', value: ProjectStatus.ACTIVE },
    { label: 'Planned', value: ProjectStatus.PLANNED },
    { label: 'On Hold', value: ProjectStatus.ON_HOLD },
    { label: 'Closed', value: ProjectStatus.CLOSED },
  ];

  public projectSubstatusCategories: ProjectSubstatusCategory[] = [];
  public tags: ResourceTag[] = [];
  projectDetailsForm = this.fb.group({
    title: [null, [Validators.required]],
    priorityLevelId: [null],
    usesNewPEB: [null],
    requestType: [null],
    buildingId: [null],
    floorId: [null],
    suiteId: [null],
    departmentId: [null],
    squareFootage: [null, [Validators.required, Validators.min(0), Validators.pattern(/\-?\d*\.?\d{1,2}/)]],
    occupiedSqft: [null, [Validators.pattern(/\-?\d*\.?\d{1,2}/)]], // number
    workspaceManagerId: [null],
    projectManagerId: [null],
    dfsManagerId: [null],
    oneCallDirectorId: [null],
    cfmoId: [null],
    architectId: [{ value: null, disabled: false }],
    architectRequired: [null],
    accountCoordinatorId: [null],
    dfsRequired: [null],
    engineerIds: [null],
    bsdId: [null],
    icoId: [null],
    tags: new FormArray([]),
    fycApprovedFiscalYear: [null],
    fycProjectYear: [null],
    fycApprovedBudget: [null],
    fycPriority: [null],
    fycImpact: [null],
    fycLikely: [null],
    landmark: [null],
    estimatedStartDate: [null],
    estimatedCompletionDate: [null],
    design_timeline: [null],
    construction_timeline: [null],
    total_timeline: [null],
    status: [null],
    substatus: [null],
    onSchedule: [null],
    moduleId: [null],
    checkedTrades: this.fb.array([]),
    costCodes: [null, [Validators.maxLength(this.costCodesLength)]],
    capx_id: [null],
    parent_id: [null],
  });

  isParentProject = new FormControl();
  // public projectKeyControls: KeyControlsBridge[] = [];

  private updatedProject: ConstructionBudget;

  get title() {
    return this.projectDetailsForm.get('title');
  }

  get description() {
    return this._description_editor_component.content;
  }

  get costCodeDescription() {
    return this._cost_code_description_editor_component.content;
  }

  get priorityLevelId() {
    return this.projectDetailsForm.get('priorityLevelId');
  }

  get usesNewPEB() {
    return this.projectDetailsForm.get('usesNewPEB');
  }

  get requestType() {
    return this.projectDetailsForm.get('requestType');
  }

  get buildingId() {
    return this.projectDetailsForm.get('buildingId');
  }

  get floorId() {
    return this.projectDetailsForm.get('floorId');
  }

  get suiteId() {
    return this.projectDetailsForm.get('suiteId');
  }

  get departmentId() {
    return this.projectDetailsForm.get('departmentId');
  }

  get squareFootage() {
    return this.projectDetailsForm.get('squareFootage');
  }

  get occupiedSqft() {
    return this.projectDetailsForm.get('occupiedSqft');
  }

  get design_timeline() {
    return this.projectDetailsForm.get('design_timeline');
  }

  get construction_timeline() {
    return this.projectDetailsForm.get('construction_timeline');
  }

  get total_timeline() {
    return this.projectDetailsForm.get('total_timeline');
  }

  get workspaceManagerId() {
    return this.projectDetailsForm.get('workspaceManagerId');
  }

  get projectManagerId() {
    return this.projectDetailsForm.get('projectManagerId');
  }

  get bsdId() {
    return this.projectDetailsForm.get('bsdId');
  }

  get dfsManagerId() {
    return this.projectDetailsForm.get('dfsManagerId');
  }

  get oneCallDirectorId() {
    return this.projectDetailsForm.get('oneCallDirectorId');
  }

  get cfmoId() {
    return this.projectDetailsForm.get('cfmoId');
  }

  get icoId() {
    return this.projectDetailsForm.get('icoId');
  }

  get architectId() {
    return this.projectDetailsForm.get('architectId');
  }

  get architectRequired() {
    return this.projectDetailsForm.get('architectRequired');
  }

  get accountCoordinatorId() {
    return this.projectDetailsForm.get('accountCoordinatorId');
  }

  get dfsRequired() {
    return this.projectDetailsForm.get('dfsRequired');
  }

  get engineerIds() {
    return this.projectDetailsForm.get('engineerIds');
  }

  get isEditing(): boolean {
    return (
      !this.projectDetailsForm.pristine ||
      !this.description.pristine ||
      !this.costCodeDescription.pristine ||
      !this.requestMethod.pristine
    );
  }

  get selectedTags() {
    return this.projectDetailsForm.controls.tags as FormArray;
  }

  get isWorkspaceStaff(): boolean {
    return this.authService?.isUserWorkspaceStaff(this.project?.module_id);
  }

  get fycReason() {
    return this._fyc_reason_editor_component.content;
  }

  get fycApprovedFiscalYear() {
    return this.projectDetailsForm.get('fycApprovedFiscalYear');
  }

  get fycProjectYear() {
    return this.projectDetailsForm.get('fycProjectYear');
  }

  get fycApprovedBudget() {
    return this.projectDetailsForm.get('fycApprovedBudget');
  }

  get fycPriority() {
    return this.projectDetailsForm.get('fycPriority');
  }

  get fycImpact() {
    return this.projectDetailsForm.get('fycImpact');
  }

  get fycLikely() {
    return this.projectDetailsForm.get('fycLikely');
  }

  get fycMatrix() {
    return this.fycImpact.value * this.fycLikely.value;
  }

  get landmark() {
    return this.projectDetailsForm.get('landmark');
  }

  get estimatedStartDate() {
    return this.projectDetailsForm.get('estimatedStartDate');
  }

  get estimatedCompletionDate() {
    return this.projectDetailsForm.get('estimatedCompletionDate');
  }

  get status() {
    return this.projectDetailsForm.get('status');
  }

  get substatus() {
    return this.projectDetailsForm.get('substatus');
  }

  get onSchedule() {
    return this.projectDetailsForm.get('onSchedule');
  }

  get moduleId() {
    return this.projectDetailsForm.get('moduleId');
  }

  get checkedTrades(): FormArray {
    return this.projectDetailsForm?.get('checkedTrades') as FormArray;
  }

  get costCodes() {
    return this.projectDetailsForm?.get('costCodes');
  }
  get capx_id() {
    return this.projectDetailsForm?.get('capx_id');
  }
  get parent_id() {
    return this.projectDetailsForm?.get('parent_id');
  }

  get databaseRequestMethod(): RequestMethod {
    return this.shownProject?.request?.request_method;
  }

  get databaseRequestMethodId(): number {
    return this.shownProject?.request?.request_method_id;
  }

  get requestMethod(): AbstractControl {
    return this._requestMethodComponent?.requestMethod;
  }

  get closeOnSave() {
    return this.data?.closeOnSave;
  }

  get workspaces() {
    return this.moduleService.allWorkspaces;
  }

  get isConstruction(): boolean {
    return this.moduleId.value === Workspace.Construction;
  }

  get needsDFSManager(): boolean {
    return this.authService.needsDFSManager(this.project?.module_id);
  }

  get isOneCallWorkspace(): boolean {
    return this.project.module.workspace_type_id === WorkspaceType.OneCall;
  }

  get ProjectDetailsViews() {
    return ProjectDetailsViews;
  }

  public get onlyRequestMethodChanged() {
    return !this.requestMethod.pristine && this.description.pristine && this.projectDetailsForm.pristine;
  }

  get projectHasCAPX() {
    return this.projectTags.includes(ResourceTags.CAPX);
  }

  get valid() {
    return (
      this.isValidCostCodesLength &&
      this.description?.valid &&
      (!this.description.pristine ||
        !this.projectDetailsForm.pristine ||
        !this.costCodeDescription?.pristine ||
        !this.requestMethod?.pristine)
    );
  }

  get isValidCostCodesLength(): boolean {
    return (this.costCodes?.value?.length || 0) <= this.costCodesLength;
  }

  fiscalYearOptions = [2024, 2025, 2026, 2027, 2028, 2029];
  budgetForm = new FormArray([new FormControl('fiscal_year'), new FormControl('budget')], [Validators.required]);
  editingBudget = false;
  sub;
  async ngOnInit() {
    this.sub = this.capx_id.valueChanges.subscribe(async (value) => {
      if (value?.capx_id) {
        this.project.capx_budget = await this.projectService.getCAPXBudgets(value.id).toPromise();
      }
      this.initBudgetArray();
    });
    this.isParentProject.valueChanges.subscribe((value) => {
      if (value === false) {
        this.parent_id.setValue(null);
        this.parent_id.markAsDirty();
      }
    });
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Data...');

    this.currentView = this.data.view || ProjectDetailsViews.Details;
    this.project = await this.projectService.getProjectById(this.data.projectId, this.projectFields).toPromise();

    const disabledTags =
      this.project?.tags?.filter(
        (tag) => !Number(tag.is_enabled) || !JSON.parse(tag.resource_type_ids.toString()).includes(ResourceType.Project)
      ) || [];
    const tagFilter: APIFilter[] = [
      { type: 'field', field: `resource_type_ids`, value: ResourceType.Project, match: 'exact' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_enabled', value: 1 },
      { type: 'operator', value: 'OR' },
      { type: 'field', field: 'id', value: disabledTags.map((t) => t.id).join('^') },
    ];
    this.tags = await this.resourceTagsService.getResourceTags(tagFilter).toPromise();

    await this.getStaticData();
    await this.refresh(this.project);
    await this.getRoleUsers();
  }

  private _disableEditors() {
    if (this.descriptionSubscription) {
      this.descriptionSubscription.unsubscribe();
    }
    this.description.reset();
    this.description.disable();
    if (this.fycReasonSubscription) {
      this.fycReasonSubscription.unsubscribe();
    }
  }

  private _enableEditors() {
    this.description.enable();
    this.description.valueChanges.subscribe(() => {
      this.unsavedChangesExist = true;
    });
  }

  private _setInitialTrades() {
    if (!this.checkedTrades.pristine) {
      this.checkedTrades.reset();
      // reset the values
      this.trades = this.trades.map((trade: Trade) => {
        return { ...trade, is_selected: false };
      });
    }
    const projectTrades = JSON.parse(this.shownProject.trades);
    this.shownProject.selected_trades = filter(
      this.trades,
      (t) => this.shownProject.trades && projectTrades.indexOf(+t.id) > -1
    );
    if (projectTrades) {
      for (const t of this.trades) {
        t.is_selected = projectTrades.indexOf(t.id) > -1;
        if (t.is_selected) {
          // push on the form
          this.checkedTrades.push(new FormControl(t));
        }
      }
    }
  }

  setView(v: ProjectDetailsViews): void {
    this.currentView = v;
  }

  getFloors(buildingId: number): void {
    if (buildingId) {
      const floorFilters: APIFilter[] = [
        { type: 'field', field: 'building_id', value: this.buildingId.value },
        { type: 'operator', value: 'AND' },
        { type: 'operator', value: '(' },
        { type: 'field', field: 'is_enabled', value: '1' },
        { type: 'operator', value: 'OR' },
        { type: 'field', field: 'id', value: this.shownProject.floor_id },
        { type: 'operator', value: ')' },
      ];
      this.locationService.getFloors(['id', 'name', 'code'], floorFilters).subscribe((floors: Floor[]) => {
        this.floors = floors;
        this.floorId.setValue(null);
        this.suites = [];
        this.suiteId.setValue(null);
        this.departments = [];
        this.departmentId.setValue(null);
      });
    } else {
      [this.floors, this.suites, this.departments] = [null, null, null];
      this.floorId.setValue(null);
      this.suites = [];
      this.suiteId.setValue(null);
      this.departments = [];
      this.departmentId.setValue(null);
    }
  }

  getSuites(floorId: number) {
    if (floorId) {
      const suiteFilters: APIFilter[] = [
        { type: 'field', field: 'floor_id', value: this.floorId.value },
        { type: 'operator', value: 'AND' },
        { type: 'operator', value: '(' },
        { type: 'field', field: 'is_enabled', value: '1' },
        { type: 'operator', value: 'OR' },
        { type: 'field', field: 'id', value: this.shownProject.suite_id },
        { type: 'operator', value: ')' },
      ];
      this.locationService.getSuites(['id', 'name'], suiteFilters).subscribe((suites: Suite[]) => {
        this.suites = suites;
        this.suiteId.setValue(null);
        this.departments = [];
        this.departmentId.setValue(null);
      });
    } else {
      [this.suites, this.departments] = [null, null];
      this.suiteId.setValue(null);
      this.departments = [];
      this.departmentId.setValue(null);
    }
  }

  getDepartments(suiteId: number) {
    if (suiteId) {
      const departmentFilters: APIFilter[] = [
        { type: 'field', field: 'suite_id', value: this.suiteId.value },
        { type: 'operator', value: 'AND' },
        { type: 'operator', value: '(' },
        { type: 'operator', value: '(' },
        { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'is_enabled', value: '1' },
        { type: 'operator', value: ')' },
        { type: 'operator', value: 'OR' },
        { type: 'field', field: 'id', value: this.shownProject.department_id },
        { type: 'operator', value: ')' },
      ];
      this.locationService.getDepartments(['id', 'name'], departmentFilters).subscribe((departments: Department[]) => {
        this.departments = departments;
        this.departmentId.setValue(null);
      });
    } else {
      this.departments = null;
      this.departmentId.setValue(null);
    }
  }

  getYears() {
    const currentYear = new Date().getFullYear();
    this.years.push(currentYear);
    for (let i = 1; i <= 5; i++) {
      this.years.push(currentYear - i);
    }
    for (let i = 1; i <= 10; i++) {
      this.years.push(currentYear + i);
    }

    this.years = this.years.sort((n1, n2) => n1 - n2);
  }

  getNumberRange(end: number): number[] {
    const nums = [];
    for (let i = 1; i <= end; i++) {
      nums.push(i);
    }
    return nums;
  }

  async populateLocationData() {
    [this.buildings, this.floors] = [
      [{ id: null, name: null }],
      [{ id: null, name: null }],
      [{ id: null, name: null }],
      [{ id: null, name: null }],
    ];
    const buildingFilters: APIFilter[] = [
      { type: 'field', field: 'is_enabled', value: '1' },
      { type: 'operator', value: 'OR' },
      { type: 'field', field: 'id', value: this.shownProject.building_id },
    ];

    const buildings: Building[] = await this.locationService
      .getBuildings(['id', 'name', 'code'], buildingFilters)
      .toPromise();

    const floorFilters: APIFilter[] = [
      { type: 'field', field: 'building_id', value: this.buildingId.value },
      { type: 'operator', value: 'AND' },
      { type: 'operator', value: '(' },
      { type: 'field', field: 'is_enabled', value: '1' },
      { type: 'operator', value: 'OR' },
      { type: 'field', field: 'id', value: this.shownProject.floor_id },
      { type: 'operator', value: ')' },
    ];

    const floors: Floor[] = await this.locationService.getFloors(['id', 'name', 'code'], floorFilters).toPromise();

    const suiteFilters: APIFilter[] = [
      { type: 'field', field: 'floor_id', value: this.floorId.value },
      { type: 'operator', value: 'AND' },
      { type: 'operator', value: '(' },
      { type: 'field', field: 'is_enabled', value: '1' },
      { type: 'operator', value: 'OR' },
      { type: 'field', field: 'id', value: this.shownProject.suite_id },
      { type: 'operator', value: ')' },
    ];

    const suites: Suite[] = await this.locationService.getSuites(['id', 'name'], suiteFilters).toPromise();

    const departmentFilters: APIFilter[] = [
      { type: 'field', field: 'suite_id', value: this.suiteId.value },
      { type: 'operator', value: 'AND' },
      { type: 'operator', value: '(' },
      { type: 'operator', value: '(' },
      { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_enabled', value: '1' },
      { type: 'operator', value: ')' },
      { type: 'operator', value: 'OR' },
      { type: 'field', field: 'id', value: this.shownProject.department_id },
      { type: 'operator', value: ')' },
    ];
    const departments: Department[] = await this.locationService
      .getDepartments(['id', 'name'], departmentFilters)
      .toPromise();

    this.buildings = buildings;
    this.floors = floors;
    this.suites = suites;
    this.departments = departments;
  }

  toggleTradeSelection(trade, isSelected) {
    if (isSelected) {
      this.checkedTrades.push(new FormControl({ ...trade, is_selected: isSelected }));
    } else {
      // find control and remove it
      const index = this.checkedTrades?.controls?.findIndex(
        (checkedControl: AbstractControl) => +checkedControl.value?.id === +trade.id
      );
      this.checkedTrades.removeAt(index);
    }

    // since we have touched the selection
    this.checkedTrades.markAsDirty();
  }

  async getStaticData() {
    const trades = await this.projectService.getTrades().toPromise();
    this.trades = sortBy(trades, (trade) => trade.name);

    this.projectSubstatusCategories = await this.projectService
      .getProjectSubStatusCategories(['id', 'name', 'substatuses{id,name,is_enabled}'])
      .toPromise();
    this.CAPXids = await this.projectService.getCAPXids().toPromise();

    this.getYears();
  }

  async getRoleUsers() {
    if (this.project.module_id === 1) {
      this.allArchitects = await this.userService
        .getUsersByRole(ApplicationRole.ModuleArchitect, 1, null, null, false, [this.shownProject?.architect_id])
        .toPromise();

      this.allEngineers = await this.userService
        .getUsersByRole(
          ApplicationRole.ModuleEngineer,
          1,
          null,
          null,
          false,
          this.shownProject?.engineer_ids ? JSON.parse(this.shownProject?.engineer_ids) : null
        )
        .toPromise();
    }

    this.allWorkspaceManagers = await this.userService
      .getUsersByRole(ApplicationRole.WorkspaceManager, this.project.module_id, null, null, false, [
        this.shownProject?.workspace_manager_id,
      ])
      .toPromise();

    this.allCFMOs = await this.userService
      .getUsersByRole(ApplicationRole.ChiefFacilitiesManagementOfficer, 1, null, null, false, [
        this.shownProject?.cfmo_id,
      ])
      .toPromise();

    this.allAccountCoordinators = await this.userService
      .getUsersByRole(ApplicationRole.AccountCoordinator, this.project.module_id, null, null, false, [
        this.shownProject?.account_coordinator,
      ])
      .toPromise();

    this.allProjectManagers = await this.userService
      .getUsersByRole(ApplicationRole.ModuleProjectManager, this.project.module_id, null, null, false, [
        this.shownProject?.project_manager_id,
      ])
      .toPromise();

    if (this.needsDFSManager) {
      this.allDFSManagers = await this.userService
        .getUsersByRole(ApplicationRole.DirectorOfFacilitySolutions, this.project.module_id, null, null, false, [
          this.shownProject?.dfs_id,
        ])
        .toPromise();
    }

    if (this.isOneCallWorkspace) {
      this.allOneCallDirectors = await this.userService
        .getUsersByRole(ApplicationRole.OneCallDirector, 1, null, null, false, [
          this.shownProject?.one_call_director_id,
        ])
        .toPromise();
    }

    if (!this.isConstruction) {
      this.allICOs = await this.userService
        .getUsersByRole(ApplicationRole.InventoryControlOfficer, 1, null, null, false, [this.shownProject?.ico_id])
        .toPromise();

      this.allBSDs = await this.userService
        .getBSDUsers(this.project.module.workspace_type_id, [this.shownProject?.bsd_id])
        .toPromise();
    }
  }

  async refresh(project?: ProjectConstruction) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Refreshing...');

    if (project) {
      this.shownProject = project;
    } else if (this.project && this.project.id) {
      this.shownProject = await this.projectService
        .getProjectById(this.project.id, this.projectFields, true)
        .toPromise();
    }

    if (this.shownProject) {
      this.title.setValue(this.shownProject.title);
      this.description.setValue(this.shownProject.scope_of_work);
      this.priorityLevelId.setValue(this.shownProject.priority_id);
      this.usesNewPEB.setValue(this.shownProject.uses_new_peb);
      this.requestType.setValue(this.shownProject.request_type_id);
      this.buildingId.setValue(this.shownProject.building_id);
      this.floorId.setValue(this.shownProject.floor_id);
      this.suiteId.setValue(this.shownProject.suite_id);
      this.departmentId.setValue(this.shownProject.department_id);
      this.populateLocationData();
      this.squareFootage.setValue(this.shownProject.square_footage);
      this.occupiedSqft.setValue(this.shownProject.occupied_sqft);
      this.design_timeline.setValue(this.shownProject.design_timeline);
      this.construction_timeline.setValue(this.shownProject.construction_timeline);
      this.total_timeline.setValue(this.shownProject.total_timeline);
      this.architectId.setValue(this.shownProject.architect_id);
      this.architectRequired.setValue(this.shownProject.is_architect_required);
      this.accountCoordinatorId.setValue(this.shownProject?.account_coordinator_id);
      this.dfsRequired.setValue(this.shownProject.is_dfs_required);
      this.workspaceManagerId.setValue(this.shownProject.workspace_manager_id);
      this.cfmoId.setValue(this.shownProject.cfmo_id);
      this.projectManagerId.setValue(this.shownProject.project_manager_id);
      this.bsdId.setValue(this.shownProject.bsd_id);
      this.dfsManagerId.setValue(this.shownProject.dfs_id);
      this.icoId.setValue(this.shownProject.ico_id);
      this.oneCallDirectorId.setValue(this.shownProject.one_call_director_id);
      this.engineerIds.setValue(JSON.parse(this.shownProject.engineer_ids));
      this.costCodes.setValue(this.shownProject.cost_codes);
      this.costCodeDescription.setValue(this.shownProject.cost_code_description);
      const capx = this.CAPXids.find((ci) => ci.id == this.shownProject?.capx_id);
      if (capx) this.capx_id.setValue(capx);
      this.initBudgetArray();
      this.parent_id.setValue(this.shownProject?.parent);
      this.isParentProject.setValue(this.shownProject?.parent ? true : false);

      // Gets currently selected tags
      this.projectTags = (this.shownProject?.tag_ids && JSON.parse(this.shownProject.tag_ids)) || [];
      if (this.selectedTags.value?.length) {
        const values = [];
        this.tags.forEach((tag) => values.push(this.projectTags.includes(tag.id)));
        this.selectedTags.setValue(values);
      } else {
        this.tags.forEach((tag) =>
          this.selectedTags.push(
            new FormControl({
              value: this.projectTags.includes(tag?.id),
              disabled: tag.id === ResourceTags.FourDX && this.shownProject.module_id === Workspace.Construction,
            })
          )
        );
      }

      this.fycApprovedFiscalYear.setValue(this.shownProject.fyc_approved_fiscal_year);
      this.fycProjectYear.setValue(this.shownProject.fyc_project_year);
      this.fycApprovedBudget.setValue(this.shownProject.fyc_approved_budget);
      this.fycPriority.setValue(this.shownProject.fyc_priority_id);
      this.fycImpact.setValue(this.shownProject.fyc_impact);
      this.fycLikely.setValue(this.shownProject.fyc_likely);
      this.estimatedStartDate.setValue(this.shownProject.construction_start_date);
      this.estimatedCompletionDate.setValue(this.shownProject.end_date);
      this.status.setValue(this.shownProject.status_id);
      this.substatus.setValue(this.shownProject.substatus?.id);
      this.onSchedule.setValue(this.shownProject.on_schedule as boolean);
      this.landmark.setValue(this.shownProject.landmark);

      this.moduleId.setValue(this.shownProject.module_id);

      if (this.architectRequired.value) {
        this.architectId.enable();
      } else {
        this.architectId.disable();
      }

      if (this.dfsRequired.value) {
        this.dfsManagerId.enable();
      } else {
        this.dfsManagerId.disable();
      }

      this.shownProject.engineers = filter(
        this.allEngineers,
        (e) => this.shownProject.engineer_ids && this.shownProject.engineer_ids.indexOf(e.id) > -1
      );
      this._setInitialTrades();

      // if its set, we update the request method component
      // otherwise, the component starts with a default value
      // if no default, we then set it
      this._requestMethodComponent.shownRequestMethods = this._requestMethodComponent.requestMethods.filter(
        (requestMethod: RequestMethod) =>
          requestMethod.is_enabled ||
          (+this.databaseRequestMethodId && +requestMethod?.id === +this.databaseRequestMethodId)
      );
      if (this.databaseRequestMethodId) {
        const foundRequestMethod = this._requestMethodComponent.shownRequestMethods.find(
          (requestMethod: RequestMethod) => +requestMethod.id === +this.databaseRequestMethodId
        );
        this.requestMethod.setValue(foundRequestMethod);
      } else {
        this.requestMethod.setValue(this._requestMethodComponent?.defaultRequestMethod);
      }
      this.projectDetailsForm.markAsPristine();
      this.requestMethod.updateValueAndValidity();
    }

    // if this is not a project admnin, disable forms
    if (!this.isWorkspaceStaff) {
      this._disableEditors();
      this.projectDetailsForm.disable();
    }

    this.loading = false;
    this.downloading = false;
    this.progressIndicatorService.close();
  }

  changeArchitectData() {
    if (!this.architectRequired.value) {
      this.architectId.setValue(null);
      this.architectId.disable();
    } else {
      this.architectId.enable();
    }
  }

  changeDFSData() {
    if (!this.dfsRequired.value) {
      this.dfsManagerId.setValue(null);
      this.dfsManagerId.disable();
    } else {
      this.dfsManagerId.enable();
    }
  }

  changeTagData(event, tagId) {
    this.unsavedChangesExist = true;
    this.projectTags = event
      ? !this.projectTags?.includes(tagId)
        ? [...this.projectTags, tagId]
        : this.projectTags
      : this.projectTags.filter((id) => id !== tagId);
  }

  viewProjectDetails() {
    this.dialog
      .open(ProjectRequestDetailsModalComponent)
      .afterClosed()
      .subscribe((result) => {});
  }

  exportProjectDetails() {
    this.downloading = true;
    if (this.unsavedChangesExist || this.isEditing) {
      this.snackbar.open('You have unsaved changes. The latest saved version of the form will be exported.');
    }
    this.pdf.saveAs(`Project_Details_${this.shownProject.code}.pdf`);
    this.downloading = false;
  }

  async saveProjectDetails() {
    if (this.architectRequired.value && !this.architectId.value) {
      this.snackbar.open('Architect Required');
      return;
    }

    if (this.dfsRequired.value && !this.dfsManagerId.value) {
      this.snackbar.open(' CTO Required');
      return;
    }

    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Saving...');
    const projectToUpdate: ProjectConstruction = {};
    if (this.onlyRequestMethodChanged) {
      await this.requestService
        .updateRequest(this.shownProject?.request?.id, { request_method_id: this.requestMethod?.value?.id || null }, [
          'id',
          'request_method_id',
          'request_method{id,name,is_enabled,is_default,icon}',
        ])
        .toPromise();

      this.projectService.refreshNeeded$.next();
      // get  a fresh copy of the project
      this.updatedProject = await this.projectService
        .getProjectById(this.shownProject.id, this.projectFields)
        .toPromise();
      this.snackbar.open('Project Request Method Saved!');
      this.resetIsEditing(this.updatedProject);
    } else if (this.unsavedChangesExist && this.shownProject && this.shownProject.id) {
      this.loading = true;
      // we also update if the databaseRequestMethodId is different from the request method
      // we also want this function to run first, so when project updates, it will have the latest
      if (+this.requestMethod?.value?.id !== +this.databaseRequestMethodId) {
        // update the request method
        await this.requestService
          .updateRequest(this.shownProject?.request?.id, { request_method_id: this.requestMethod?.value?.id || null }, [
            'id',
            'request_method_id',
            'request_method{id,name,is_enabled,is_default,icon}',
          ])
          .toPromise();
      }
      if (this.title.value && this.shownProject.title !== this.title.value) {
        projectToUpdate.title = this.title.value;
      }
      if (this.description.value && this.shownProject.scope_of_work !== this.description.value) {
        projectToUpdate.scope_of_work = this.description.value;
      }
      if (this.priorityLevelId.value && this.shownProject.priority_id !== this.priorityLevelId.value) {
        projectToUpdate.priority_id = this.priorityLevelId.value;
      }
      if (this.shownProject.uses_new_peb !== this.usesNewPEB.value) {
        projectToUpdate.uses_new_peb = this.usesNewPEB.value ? 1 : 0;
      }
      if (this.shownProject.fyc_approved_fiscal_year !== this.fycApprovedFiscalYear.value) {
        projectToUpdate.fyc_approved_fiscal_year = this.fycApprovedFiscalYear.value;
      }
      if (this.shownProject.fyc_project_year !== this.fycProjectYear.value) {
        projectToUpdate.fyc_project_year = this.fycProjectYear.value;
      }
      if (this.shownProject.fyc_approved_budget !== this.fycApprovedBudget.value) {
        projectToUpdate.fyc_approved_budget = this.fycApprovedBudget.value;
      }
      if (this.shownProject.fyc_priority_id !== this.fycPriority.value) {
        projectToUpdate.fyc_priority_id = this.fycPriority.value;
      }
      if (this.shownProject.fyc_impact !== this.fycImpact.value) {
        projectToUpdate.fyc_impact = this.fycImpact.value;
      }
      if (this.shownProject.fyc_likely !== this.fycLikely.value) {
        projectToUpdate.fyc_likely = this.fycLikely.value;
      }
      if (
        this.isConstruction &&
        this.requestType.value &&
        this.shownProject.request_type_id !== this.requestType.value
      ) {
        projectToUpdate.request_type_id = this.requestType.value;
      }
      if (this.buildingId.value && this.shownProject.building_id !== this.buildingId.value) {
        projectToUpdate.building_id = this.buildingId.value;
      }
      if (this.floorId.value && this.shownProject.floor_id !== this.floorId.value) {
        projectToUpdate.floor_id = this.floorId.value;
      }
      if (this.suiteId.value && this.shownProject.suite_id !== this.suiteId.value) {
        projectToUpdate.suite_id = this.suiteId.value;
      }
      if (this.departmentId.value && this.shownProject.department_id !== this.departmentId.value) {
        projectToUpdate.department_id = this.departmentId.value;
      }
      if (
        this.isConstruction &&
        this.estimatedStartDate.value &&
        this.shownProject.construction_start_date !== this.estimatedStartDate.value
      ) {
        projectToUpdate.construction_start_date = moment(this.estimatedStartDate.value).format('YYYY-MM-DD HH:mm:ss');
      }
      if (this.estimatedCompletionDate.value && this.shownProject.end_date !== this.estimatedCompletionDate.value) {
        projectToUpdate.end_date = moment(this.estimatedCompletionDate.value).format('YYYY-MM-DD HH:mm:ss');
      }
      if (this.status.value && this.shownProject.status_id !== this.status.value) {
        projectToUpdate.status_id = this.status.value;
      }
      if (this.substatus.value && this.shownProject.substatus_id !== this.substatus.value) {
        projectToUpdate.substatus_id = this.substatus.value;
      }
      if (
        (this.onSchedule.value || this.onSchedule.value === 0) &&
        this.shownProject.on_schedule !== this.onSchedule.value
      ) {
        projectToUpdate.on_schedule = this.onSchedule.value;
      }
      if (this.moduleId.value && this.shownProject.module_id !== this.moduleId.value) {
        projectToUpdate.module_id = this.moduleId.value;
      }
      if (!this.isConstruction && this.landmark.value && this.shownProject.landmark !== this.landmark.value) {
        projectToUpdate.landmark = this.landmark.value;
      }
      if (
        this.isConstruction &&
        this.squareFootage.value &&
        this.shownProject.square_footage !== this.squareFootage.value
      ) {
        projectToUpdate.square_footage = this.squareFootage.value;
      }
      if (
        this.isConstruction &&
        this.occupiedSqft.value &&
        this.shownProject.square_footage !== this.occupiedSqft.value
      ) {
        projectToUpdate.occupied_sqft = this.occupiedSqft.value;
      }
      // empty arrays allowed
      if (this.isConstruction && this.checkedTrades?.value) {
        projectToUpdate.trades = JSON.stringify(
          this.checkedTrades?.value?.map((checkedTrade: Trade) => checkedTrade?.id).filter((trade) => trade)
        );
      }
      if (this.workspaceManagerId.value && this.shownProject.workspace_manager_id !== this.workspaceManagerId.value) {
        projectToUpdate.workspace_manager_id = this.workspaceManagerId.value;
      }
      if (this.projectManagerId.value && this.shownProject.project_manager_id !== this.projectManagerId.value) {
        projectToUpdate.project_manager_id = this.projectManagerId.value;
      }
      if (this.cfmoId.value && this.shownProject.cfmo_id !== this.cfmoId.value) {
        projectToUpdate.cfmo_id = this.cfmoId.value;
      }
      if (
        this.isConstruction &&
        (this.architectId.value || !this.architectRequired.value) &&
        this.shownProject.architect_id !== this.architectId.value
      ) {
        projectToUpdate.architect_id = this.architectId.value;
      }
      if (this.isConstruction && this.architectRequired.value !== this.shownProject.is_architect_required) {
        projectToUpdate.is_architect_required = this.architectRequired.value ? 1 : 0;
      }
      if (this.shownProject.account_coordinator_id !== this.accountCoordinatorId) {
        projectToUpdate.account_coordinator_id = this.accountCoordinatorId.value;
      }
      if (
        this.isConstruction &&
        this.engineerIds.value &&
        this.shownProject.engineer_ids !== JSON.stringify(this.engineerIds.value)
      ) {
        projectToUpdate.engineer_ids = JSON.stringify(this.engineerIds.value);
      }
      if (this.needsDFSManager && this.shownProject?.dfs_id !== this.dfsManagerId.value) {
        projectToUpdate.dfs_id = this.dfsManagerId.value;
      }
      if (this.shownProject?.module?.show_dfs_toggle && this.dfsRequired.value !== this.shownProject.is_dfs_required) {
        projectToUpdate.is_dfs_required = this.dfsRequired.value ? 1 : 0;
      }
      if (this.isOneCallWorkspace && this.shownProject?.one_call_director_id !== this.oneCallDirectorId.value) {
        projectToUpdate.one_call_director_id = this.oneCallDirectorId.value;
      }
      if (
        this.isConstruction &&
        this.construction_timeline?.value &&
        this.shownProject.construction_timeline !== this.construction_timeline.value
      ) {
        projectToUpdate.construction_timeline = this.construction_timeline.value;
      }

      if (
        this.isConstruction &&
        this.design_timeline?.value &&
        this.shownProject.design_timeline !== this.design_timeline.value
      ) {
        projectToUpdate.design_timeline = this.design_timeline.value;
      }

      if (
        this.isConstruction &&
        this.total_timeline?.value &&
        this.shownProject.total_timeline !== this.total_timeline.value
      ) {
        projectToUpdate.total_timeline = this.total_timeline.value;
      }

      if (this.isConstruction) {
        if (this.shownProject.cost_code_description !== this.costCodeDescription.value) {
          projectToUpdate.cost_code_description = this.costCodeDescription.value;
        }

        if (this.shownProject.cost_codes !== this.costCodes.value) {
          projectToUpdate.cost_codes = this.costCodes.value;
        }
      }

      if (!this.isConstruction) {
        if (this.icoId.value !== this.shownProject?.ico_id) {
          projectToUpdate.ico_id = this.icoId.value;
        }

        if (this.bsdId.value !== this.shownProject?.bsd_id) {
          projectToUpdate.bsd_id = this.bsdId.value;
        }
      }

      // Gets selected tags and adds them to the tag_ids field
      projectToUpdate.tag_ids = JSON.stringify(this.projectTags);

      projectToUpdate.capx_id = this.capx_id.value?.id || null;
      if (this.capx_id.value?.id && !projectToUpdate.tag_ids.includes(ResourceTags.CAPX)) {
        projectToUpdate.capx_id = null;
      }
      projectToUpdate.parent_id = this.parent_id.value?.id || null;

      // update 12/15/20 by tom
      // deleting module_id property because that field is no longer editable
      delete projectToUpdate.module_id;
      if (Object.keys(projectToUpdate).length > 0) {
        this.updatedProject = await this.projectService
          .updateProject(this.shownProject.id, projectToUpdate, this.projectFields)
          .toPromise();
      }

      this.snackbar.open('Project Details Saved!');
      this.resetIsEditing(this.updatedProject);
    }
    this.progressIndicatorService.close();

    if (this.closeOnSave) {
      this.close();
    }
  }

  public resetIsEditing(project?) {
    if (!this.checkedTrades.pristine) {
      this._setInitialTrades();
    }

    if (!this.projectDetailsForm.pristine) {
      this.projectDetailsForm.reset();
    }

    if (!this.description.pristine) {
      this.description.reset();
    }

    if (!this.costCodeDescription.pristine) {
      this.costCodeDescription.reset();
    }

    if (!this.requestMethod.pristine) {
      this._requestMethodComponent.requestMethod.reset();
    }
    if (project) {
      this.refresh(project);
    } else {
      this.refresh(this.project);
    }
  }

  close() {
    this.dialogRef.close(null);
  }

  initBudgetArray() {
    this.budgetForm = new FormArray([]);
    this.project?.capx_budget?.forEach((b) => {
      this.addFormGroup(b);
    });
  }

  addFormGroup(budget) {
    const group = new FormGroup({
      id: new FormControl(budget.id, [Validators.required]),
      fiscal_year: new FormControl(budget.fiscal_year, [Validators.required]),
      budget: new FormControl(budget.budget, [Validators.required]),
    });
    this.budgetForm.push(group);
  }

  subTotal() {
    let total = 0;
    this.budgetForm.controls.forEach((c: any) => {
      total += Number(c.get('budget')?.value) || 0;
    });
    return total;
  }

  cancelBudgetSave() {
    this.initBudgetArray();
    this.editingBudget = false;
  }

  async saveBudget() {
    if (!this.budgetForm.valid) return;
    const save = this.budgetForm.value;
    await this.projectService.updateCAPXBudgets(save).toPromise();
    this.editingBudget = false;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

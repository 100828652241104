export enum CompanyTypeEnum {
  Staff = 1,
  Tenant = 2,
  Supplier = 3,
}

export enum CompanyVerificationStatus {
  Pending = 1,
  'UHAT Only' = 2,
  '1CALL & UHAT' = 3,
  'Suspended' = 4,
}

<div
  id="task-view"
  data-test-id="task-view"
  class="d-flex align-items-center m-0 task-view p-2"
  [ngClass]="{
    'bg-shade-red': daysUntilDueDate < 0 && taskData.status_id !== taskStatus.Complete
  }"
  (click)="selectThisTask()"
>
  <!-- TASK STATUS -->
  <app-task-status-icon [accessoryData]="accessoryData" [task]="taskData" (taskStatusChanged)="updateTask($event)">
  </app-task-status-icon>
  <!--User Assignment spinner from  home-->
  <mat-spinner
    *ngIf="isReAssigningUser"
    color="success"
    class="position-absolute opacity6"
    [diameter]="31"
    style="left: 4px; top: 10px"
  ></mat-spinner>

  <!--User Assignment spinner from  view task-->
  <mat-spinner
    *ngIf="isReAssigningUserAndTaskId?.isReAssigningUser && +isReAssigningUserAndTaskId?.taskId === +taskData?.id"
    color="success"
    class="position-absolute opacity6"
    [diameter]="31"
    style="left: 4px; top: 10px"
  ></mat-spinner>
  <button mat-icon-button *ngIf="isLinkedTask && !taskData?.status_id">
    <mat-icon class="ltgray" matTooltip="Linked Work Order Task" matTooltipPosition="above" [matTooltipShowDelay]="300"
      >description</mat-icon
    >
  </button>

  <div *ngIf="!taskData.assigned_user_id && !isAssigningUser" class="addUser ml-1 mr-3">
    <button
      (click)="openAssignUserDialog(); stopPropagation($event)"
      mat-icon-button
      class="ltblue"
      matTooltip="Assign Task"
      matTooltipPosition="above"
      [matTooltipShowDelay]="300"
    >
      <mat-icon>person_add</mat-icon>
    </button>
  </div>

  <app-profile-thumbnail
    (click)="openAssignUserDialog(); stopPropagation($event)"
    *ngIf="taskData.assigned_user_id && !isAssigningUser"
    [borderActive]="true"
    class="mr-3 ml-2"
    [width]="38"
    [height]="38"
    [userId]="taskData.assigned_user_id"
    [ngClass]="{ '': taskData.status_id === taskStatus.Complete }"
    matTooltip="{{ taskData.assigned_user_first_name }} {{ taskData.assigned_user_last_name }}"
    matTooltipPosition="above"
    [matTooltipShowDelay]="300"
    [overlayIconTooltip]="'Account Pending'"
    [overlayIconOpacity]="1"
    [overlayIcon]="taskData.assigned_user_login_enabled === 0 ? 'priority_high' : ''"
    [overlayIconColor]="'white'"
    [overlayIconBackground]="'bg-red'"
  ></app-profile-thumbnail>
  <span class="d-flex flex-column">
    <h6
      id="task_title"
      class="m-0 dkblue font-weight-semibold"
      [ngClass]="{
        'opacity4 font-weight-normal strike': taskData.status_id === taskStatus.Complete
      }"
    >
      <!-- {{ isLinkedTask ? workOrderCode + ' | ' : '' }} -->
      {{ taskData.title | stripHtml }}
    </h6>
    <!-- ADD PARENT INPUT AND ADD CONTENT HERE -->
    <div *ngIf="showParent === true">
      <p *ngIf="taskData.project || taskData.arf" class="dkgray opacity8 mb-0">
        {{ parent }}
      </p>
    </div>
  </span>

  <h6
    *ngIf="isApproval && !isKeyControlTask && !isContractTask"
    class="m-0 badge white bg-green text-uppercase px-2 ml-3"
  >
    Review
  </h6>
  <h6 *ngIf="isApproval && isContractTask" class="m-0 badge white bg-gold text-uppercase px-2 ml-3">Contract</h6>
  <h6
    *ngIf="isLinkedTask"
    class="m-0 ml-2 badge white text-uppercase px-2"
    [ngClass]="{
      'bg-ltgray ml-0': !workOrderCode,
      'bg-orange ml-3': workOrderCode
    }"
  >
    {{ workOrderCode ? workOrderCode : 'Work Order Deleted' }}
  </h6>
  <h6 *ngIf="isKeyControlTask" class="m-0 badge white bg-dkblue text-uppercase px-2 ml-3">Key Control</h6>
  <div class="d-flex align-items-center ml-auto">
    <span class="task-extras">
      <mat-icon
        *ngIf="isLocked && !isApproval"
        class="ltblue ml-2 d-inline-block align-middle"
        matTooltip="Task is locked"
        matTooltipPosition="above"
        >lock</mat-icon
      >

      <mat-icon
        *ngIf="isFollowing && !isApproval"
        class="ltblue ml-2 d-inline-block align-middle"
        matTooltip="Following Task"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        >how_to_reg</mat-icon
      >

      <mat-icon
        *ngIf="fileCount > 0 && !isApproval"
        class="ltblue ml-2 d-inline-block align-middle"
        (click)="selectThisTask()"
        [matTooltip]="(fileCount || 0) + ' File' + (fileCount !== 1 ? 's' : '')"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        >attach_file</mat-icon
      >

      <mat-icon
        *ngIf="noteCount > 0 && !isApproval"
        class="ltblue ml-2 d-inline-block align-middle"
        (click)="selectThisTask()"
        [matTooltip]="(noteCount || 0) + ' Note' + (noteCount !== 1 ? 's' : '')"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        >question_answer</mat-icon
      >
    </span>

    <button
      *ngIf="showUndoTaskStatus && previousTaskStatusId && taskData.status_id === 3"
      (click)="undoTaskStatusChange(); stopPropagation($event)"
      mat-button
      color="warn"
      class="ml-auto px-3 lh-32"
      [matTooltip]="'Change Status Back to \'' + getTaskStatusText(previousTaskStatusId) + '\''"
      matTooltipPosition="above"
      [matTooltipShowDelay]="300"
    >
      UNDO
    </button>
    <button
      (click)="dueDatePicker.open(); stopPropagation($event)"
      mat-button
      [disabled]="taskData.status_id === 3"
      class="font-weight-semibold px-3 lh-32 ml-4"
      [ngClass]="{
        green: taskData.status_id === 3,
        'bg-shade-red': taskData.status_id !== taskStatus.Complete && daysUntilDueDate < 0
      }"
    >
      <div class="ltblue" *ngIf="shouldShowDueDateText && daysUntilDueDate > 6">
        {{ dueDateText }}
      </div>

      <!-- <div class="ltblue" *ngIf="shouldShowDueDateText && daysUntilDueDate > 14">
        Due {{ taskData.due_date | date: 'MMM dd' }}
      </div> -->

      <div class="ltblue" *ngIf="shouldShowDueDateText && daysUntilDueDate > 1 && daysUntilDueDate <= 6">
        Due {{ taskData.due_date | date: 'EEEE' }}
      </div>

      <div
        (click)="dueDatePicker.open()"
        class="red"
        *ngIf="daysUntilDueDate == 0 && taskData.status_id !== taskStatus.Complete"
      >
        Due Today
      </div>

      <div
        (click)="dueDatePicker.open()"
        class="red"
        *ngIf="daysUntilDueDate < 0 && taskData.status_id !== taskStatus.Complete"
      >
        OVERDUE
      </div>

      <div
        (click)="dueDatePicker.open()"
        class="ltblue"
        *ngIf="daysUntilDueDate == 1 && taskData.status_id !== taskStatus.Complete"
      >
        Due Tomorrow
      </div>

      <div
        (click)="dueDatePicker.open()"
        class="gray"
        *ngIf="
          !daysUntilDueDate &&
          taskData.status_id != taskStatus.Complete &&
          daysUntilDueDate != 0 &&
          ((!isLinkedTask && isWorkspaceStaff) || (isLinkedTask && canEditWO))
        "
      >
        Set due date
      </div>

      <div
        (click)="dueDatePicker.open()"
        class="gray"
        *ngIf="
          !daysUntilDueDate &&
          taskData.status_id != taskStatus.Complete &&
          daysUntilDueDate != 0 &&
          ((!isLinkedTask && !isWorkspaceStaff) || (isLinkedTask && !isDeletedLinkedTask && !canEditWO))
        "
      >
        No Due Date
      </div>

      <div *ngIf="taskData.status_id === 3">Complete</div>
    </button>
    <input
      matInput
      [matDatepicker]="dueDatePicker"
      [(ngModel)]="taskData.due_date"
      autocomplete="off"
      style="visibility: hidden; width: 0"
      (ngModelChange)="changeTaskDueDate(taskData.due_date)"
      [disabled]="(!isLinkedTask && !isWorkspaceStaff) || (isLinkedTask && !canEditWO)"
    />
    <mat-datepicker #dueDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
    <button
      (click)="dueDatePicker.open(); stopPropagation($event)"
      mat-button
      type="button"
      color="accent"
      *ngIf="taskData.due_date"
      class="lh-32 ml-2 px-2 gray"
      matTooltip="{{ taskData.due_date | date: 'MMM dd, yyyy' }}"
      matTooltipPosition="above"
      [matTooltipShowDelay]="300"
    >
      {{ taskData.due_date | date: 'M/dd' }}
    </button>
    <button
      mat-icon-button
      class="ml-1"
      [matMenuTriggerFor]="task_menu"
      color="primary"
      matTooltip="More"
      matTooltipPosition="above"
      [matTooltipShowDelay]="300"
      (click)="stopPropagation($event)"
      [disabled]="!isWorkspaceStaff"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #task_menu="matMenu" xPosition="before">
      <button (click)="openAddReminderDialog()" mat-menu-item color="primary" *ngIf="!isLinkedTask">
        <mat-icon>add_alert</mat-icon>
        <span> Create Reminder </span>
      </button>
      <button
        (click)="remindTask()"
        mat-menu-item
        color="primary"
        *ngIf="
          isWorkspaceStaff && taskData.assigned_user_id && taskData.assigned_user_id !== authService.currentUser.id
        "
      >
        <mat-icon>notifications_active</mat-icon>
        <span> Remind Assignee </span>
      </button>
      <button mat-menu-item color="primary" (click)="editWorkOrder()" *ngIf="isLinkedTask">
        <mat-icon>event</mat-icon>
        <span> Edit Work Order </span>
      </button>
      <button mat-menu-item color="primary" (click)="createMeeting()">
        <mat-icon>event</mat-icon>
        <span> Create Meeting </span>
      </button>
      <button mat-menu-item color="primary" (click)="createMeetingAgendaFromTask(taskData)">
        <mat-icon>event_notes</mat-icon>
        <span> Add to Meeting Agenda </span>
      </button>
      <button
        (click)="changeTaskStatus(taskStatus.Open)"
        *ngIf="taskData && taskData.status_id === taskStatus.OnHold && !isLinkedTask"
        mat-menu-item
        color="primary"
      >
        <mat-icon>outlined_flag</mat-icon>
        <span> Remove Hold </span>
      </button>
      <button
        (click)="changeTaskStatus(taskStatus.OnHold)"
        *ngIf="taskData && taskData.status_id !== taskStatus.OnHold && !isLinkedTask"
        mat-menu-item
        color="primary"
      >
        <mat-icon>flag</mat-icon>
        <span> Place on Hold </span>
      </button>
      <button
        (click)="convertToLinkedWOTask(taskData)"
        *ngIf="taskData.status_id !== taskStatus.Complete && isWorkspaceStaff && canConvertToLinkedTask"
        mat-menu-item
        color="primary"
      >
        <mat-icon>description</mat-icon>
        <span> Convert to Work Order </span>
      </button>
      <button
        (click)="convertTaskClicked()"
        mat-menu-item
        color="primary"
        [disabled]="isLocked"
        *ngIf="isWorkspaceStaff && reviewIsEditable && !isLinkedTask"
      >
        <mat-icon>library_add_check</mat-icon>
        <span>
          {{ isApproval ? 'Edit Review' : 'Add Review' }}
        </span>
      </button>
      <button (click)="deactivateTask()" mat-menu-item *ngIf="allowedToDeleteTask(taskData)">
        <mat-icon>delete</mat-icon>
        <span> Delete Task </span>
      </button>
    </mat-menu>
  </div>
</div>

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { DatepickerHeaderComponent, EditorComponent, FileAttachmentDialogComponent } from 'src/app/components';
import { ResourceType, Workspace } from 'src/app/enums';
import { AuthService, FileService, ProgressIndicatorService, ProjectService } from 'src/app/services';
import { BidPackage, ProjectConstruction, Solicitation } from 'src/app/workspaces/construction/types';
import { Company, ProjectTenant } from '../../../../types';

@Component({
  selector: 'app-new-solicitation-dialog',
  templateUrl: './new-solicitation-dialog.component.html',
  styleUrls: ['./new-solicitation-dialog.component.scss'],
})
export class NewSolicitationDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<NewSolicitationDialogComponent>,
    private authService: AuthService,
    private projectService: ProjectService,
    private progressIndicatorService: ProgressIndicatorService,
    private fileService: FileService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  public customHeader = DatepickerHeaderComponent;
  public project: ProjectConstruction;
  public bidPackage: BidPackage;
  public tenant: ProjectTenant;
  public company: Company;
  public dialogTitle: string;
  public warningText: string;
  public originalSolicitation: Solicitation;
  public solicitation: Solicitation;
  public solicitationTypes: { id: number; name: string }[] = [];
  public selectedSolicitationTypes: number[] = [];
  public update: boolean;

  async ngOnInit(): Promise<void> {
    this.update = !!this.data.solicitation;
    this.originalSolicitation = this.data.solicitation && JSON.parse(JSON.stringify(this.data.solicitation));
    this.project = this.data.project;
    this.bidPackage = this.data.bidPackage;
    this.tenant = this.data.tenant;
    this.company = this.data.company;
    this.dialogTitle = `${this.originalSolicitation ? (this.isAdmin ? 'Edit' : 'View') : 'New'} Solicitation`;
    const supplierInfo = this.company
      ? `<br/>Supplier: <b>${this.company?.name}</b><br/>Amount: <b>${this.company?.selectedBidsTotal}</b>`
      : '';
    const total = this.company?.selectedBidsTotal || this.tenant?.purchase_total;
    const isConstruction = this.project.module_id === Workspace.Construction;

    if (total > 24999.99 && total < 99999.99) {
      this.warningText = `This bid exceeds <b>$25,000.00</b> and will require a <b>minimum of three solicitations</b> to be recorded when possible.<br/><br/>If you cannot meet these requirements, please do not proceed with this action.<br/> ${supplierInfo}`;
    } else if (total < 249999.99 && !isConstruction) {
      this.warningText = `This bid exceeds <b>$100,000.00</b> and will require a <b>minimum of five solicitations</b> to be recorded when possible.<br/><br/>If you cannot meet these requirements, please do not proceed with this action.<br/> ${supplierInfo}`;
    } else {
      const amount = isConstruction ? '100,000.00' : '250,000.00';
      this.warningText = `This bid exceeds <b>${amount}</b> and will require a <b>formal competitive solicitation</b>, up to and including sealed solicitation.<br/><br/>If you cannot meet these requirements, please do not proceed with this action.<br/> ${supplierInfo}`;
    }

    this.solicitation = { ...this.data.solicitation } || {
      type_id: null,
      start_date: null,
      end_date: null,
      project_id: this.project.id,
      bid_package_id: this.bidPackage?.id,
      files: [],
    };

    if (this.solicitation.comment) {
      this._editor_component.content.setValue(this.solicitation.comment, { emitEvent: false });
    }

    this.selectedSolicitationTypes =
      typeof this.solicitation.type_ids === 'string' ? JSON.parse(this.solicitation.type_ids) : [];
    this.solicitationTypes = await this.projectService.getSolicitationTypes().toPromise();
  }

  public openSolicitationUploadModal(solicitation) {
    const additionalParents = [{ type: ResourceType.Solicitation, id: solicitation.id }];
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.project.id,
          allowComment: false,
          additionalParents,
          preSelectedTags: [{ id: 79 }],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          resultData.forEach((file) => {
            if (!solicitation.files) {
              solicitation.files = [];
            }
            solicitation.files.push({ id: file.file_id, name: file.name });
          });
        }
      });
  }

  removeFileFromSolicitation(solicitation, file) {
    solicitation.files.splice(solicitation.files.indexOf(file), 1);
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public async save() {
    this.solicitation.comment = this._editor_component?.content.value;

    if (this.selectedSolicitationTypes?.length && this.solicitation.comment && this.solicitation?.files?.length) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Updating Solicitation..');

      this.solicitation.type_ids =
        (this.selectedSolicitationTypes && JSON.stringify(this.selectedSolicitationTypes)) || '[]';

      // added this promise in to await before returning, otherwise the id of the solicitation won't be waited for
      const finishBeforeReturning = [];
      if (!this.originalSolicitation) {
        this.solicitation.project_id = this.project?.id;
        this.solicitation.bid_package_id = this.bidPackage?.id;
        this.solicitation.tenant_id = this.tenant?.id;

        // make the created item promise
        const createdObjectPromise = this.projectService.createSolicitation(this.solicitation);
        finishBeforeReturning.push(createdObjectPromise);
        // set the relevant fields now that we have the id
        createdObjectPromise.subscribe(async (created) => {
          this.solicitation.id = created.id;
          if (this.solicitation.files) {
            for (const file of this.solicitation.files) {
              await this.fileService.linkFile(file.id, created.id, ResourceType.Solicitation).toPromise();
            }
          }
        });
      } else {
        await this.projectService.updateSolicitation(this.solicitation).toPromise();
        const currentFiles = this.solicitation.files || [];
        const originalFiles = this.originalSolicitation.files || [];
        const filesToAdd = [];
        for (const f of currentFiles) {
          if (!originalFiles.find((original) => original.id === f.id)) {
            filesToAdd.push(f);
            await this.fileService.addTags(f.id, [79]).toPromise();
          }
        }
        const filesToRemove = [];
        for (const f of originalFiles) {
          if (!currentFiles.find((current) => current.id === f.id)) {
            filesToRemove.push(f);
            this.fileService.removeTags(f.file_id || f.id, [79]).subscribe();
          }
        }

        for (const f of filesToAdd) {
          await this.fileService.linkFile(f.id, this.solicitation.id, ResourceType.Solicitation).toPromise();
        }
        for (const f of filesToRemove) {
          const bridge = await this.fileService
            .getBridgeFile(f.id, this.solicitation.id, ResourceType.Solicitation)
            .toPromise();
          for (const file of bridge) {
            await this.fileService.unlinkFile(file.id).toPromise();
          }
        }
      }
      // we need to wait for the id before returning
      Promise.all(finishBeforeReturning).then(() => {
        this.dialogRef.close(this.solicitation);
      });

      this.progressIndicatorService.close();
    } else {
      let fieldsToFillOut = '';
      if (!this.selectedSolicitationTypes?.length) {
        fieldsToFillOut = 'solicitations types';
      }

      if (!this.solicitation.comment) {
        fieldsToFillOut += `${fieldsToFillOut ? ', ' : ' '}comment`;
      }

      if (!this.solicitation?.files?.length) {
        fieldsToFillOut += `${fieldsToFillOut ? ', ' : ' '}files`;
      }

      this.snackBar.open(`Please fill out: ${fieldsToFillOut}.`);
    }
  }

  updateSolicitationTypes(selectedOptions: number[]): void {
    this.selectedSolicitationTypes = selectedOptions;
  }

  getMinRange(solicitation) {
    return solicitation && solicitation.start_date
      ? moment(solicitation.start_date).add(22, 'days').format('YYYY-MM-DD')
      : null;
  }

  get isAdmin() {
    // check if the user is an architect for this project, a PM for this project, a module admin, or app admin
    return this.authService.isProjectAdmin(this.project.id, this.project.module_id);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import heic2any from 'heic2any';
import * as JSZip from 'jszip';
import { intersection } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ResourceType } from 'src/app/enums';
import { APIFilter, ServiceResponse, Tag, UhatFileReference } from 'src/app/types';
import { environment } from 'src/environments/environment';
import { ApiFilterService } from './api-filter.service';
import { HandleErrorService } from './handle-error.service';
import { ModalService } from './modal.service';
import { ProgressIndicatorService } from './progress-indicator.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  host: string = environment.serviceHost;

  private _fileThumbnailPreviewUrls = new Map<number, BehaviorSubject<SafeResourceUrl>>(); // Cache file thumbnails Urls To Prevent Database Hit (fileId, url)

  public filesUrl = `${this.host}/api/v1/files`;
  public tagsUrl = `${this.host}/api/v1/tags`;

  fileFields =
    'file_id,parent_id,parent_type_id,name,path,created_by_id,created_by_first_name,created_by_last_name,created_by_user_company,created_datetime,tag_ids,tags';
  tagFields = 'id,name,tag_parent_id,is_system_generated,blocks_delete,folder_name,is_enabled';

  _combinableExtensions = ['jpeg', 'jpg', 'png', 'pdf'];

  // private _defaultTags: Tag[];

  get combinableExtensions() {
    return this._combinableExtensions;
  }

  constructor(
    private apiFilterService: ApiFilterService,
    private dialog: MatDialog,
    private handleErrorService: HandleErrorService,
    private http: HttpClient,
    private modalService: ModalService,
    private _progressIndicatorService: ProgressIndicatorService,
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar
  ) {
    // this.getDefaultTags().subscribe(tags => {
    //   this._defaultTags = tags;
    // });
  }

  /**
   * Store image data from a blob into the cached file thumbnail
   * @param fileId Of the file
   * @param thumbnail Blob data of the thumbnail to be stored. This will be converted to base64
   */
  private _storeFileThumbnailPreview(fileId: number, thumbnail: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const trustedThumbnailPreviewData = this.sanitizer.bypassSecurityTrustUrl(reader.result.toString());
        this._fileThumbnailPreviewUrls.get(fileId).next(trustedThumbnailPreviewData);
      },
      false
    );
    if (thumbnail) {
      reader.readAsDataURL(thumbnail);
    }
  }

  checkIfFileExists(fileName: string, parentId?: number, parentTypeId?: number, fileId?: number): Observable<boolean> {
    // let body;
    // // have to do it this stupid way because of the errors
    // if (parentId && parentTypeId) {
    //   body = { name: fileName, parentId, parentTypeId };
    // } else if (parentTypeId) {
    //   body = { name: fileName, parentTypeId };
    // } else if (parentId) {
    //   body = { name: fileName, parentId };
    // } else {
    //   body = { name: fileName };
    // }
    return this.http
      .get(
        `${this.filesUrl}/doesFileExist?${`name=${encodeURIComponent(fileName)}`}${
          parentTypeId ? `&parent_type_id=${parentTypeId}` : ``
        }${parentId ? `&parent_id=${parentId}` : ``}${fileId ? `&file_id=${fileId}` : ``}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.file_exists;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getFileParentsModules(fileId: number): Observable<number[]> {
    return this.http.get(`${this.filesUrl}/${fileId}/getFileParentsModules`).pipe(
      map((result: ServiceResponse) => {
        return result.data.parents;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getParentsModules(parents: { parent_id: number; parent_type_id: ResourceType }[]): Observable<number[]> {
    return this.http.post(`${this.filesUrl}/getParentsModules`, { parents }).pipe(
      map((result: ServiceResponse) => {
        return result.data.parents;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getBridgeFile(fileId: number, parentId: number, parentType: ResourceType): Observable<UhatFileReference[]> {
    return this.http
      .get(
        `${this.filesUrl}?fields=${this.fileFields}&filter=file_id=${fileId},parent_id=${parentId},parent_type_id=${parentType}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const files: UhatFileReference[] = result.data.file;
          return files;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getParentsForFiles(fileIds: number[]): Observable<UhatFileReference[]> {
    const ids = fileIds.join('^');
    return this.http.get(`${this.filesUrl}?fields=file_id,parent_type_id&filter=file_id=${ids}`).pipe(
      map((result: ServiceResponse) => {
        const files: UhatFileReference[] = result.data.file;
        return files;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getFilesByParentId(parentTypeId: ResourceType, parentId: number, cursor?: string, limit?: number): Observable<any> {
    return this.http
      .get(
        `${this.filesUrl}?filter=parent_type_id=${parentTypeId},parent_id=${parentId}&fields=${this.fileFields}&limit=${
          limit || 1000
        }${cursor ? `&cursor=${cursor}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.file;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getTagsForFile(fileId: number): Observable<any> {
    return this.http.get(`${this.filesUrl}/${fileId}/name?&fields=id,name,tags`).pipe(
      map((result: ServiceResponse) => {
        return result.data.file[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // getDefaultTags(): Observable<Tag[]> {
  //   return this.http.get(`${this.tagsUrl}?filter=workspace_id=NULL&fields=${this.tagFields}`).pipe(
  //     map((result: ServiceResponse) => {
  //       return result.data.tags;
  //     }),
  //     catchError((e) => this.handleErrorService.handleError(e))
  //   );
  // }

  getTags(workspace_ids: number[], apiFilters: APIFilter[] = []): Observable<Tag[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.tagsUrl}?fields=${this.tagFields},workspace_ids&${filterString}`).pipe(
      map((result: ServiceResponse) => {
        // each tag has a field, workspace_ids, that is either null, or a comma separated list of ints, like [1,2,3,4]
        // return the tags that are either null (any workspace), or that have a tag that intersects with one of the passed ones
        const tags = result.data.tags.filter(
          (tag) =>
            !tag.workspace_ids ||
            intersection(
              JSON.parse(tag.workspace_ids).map((i) => +i),
              workspace_ids
            ).length > 0
        );

        // then delete the workspace_ids field after parsing, before returning
        delete tags.workspace_ids;
        return tags;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // adds the given tagIds to the given fileId
  // Note that this also adds the `Current` tag if applicable for any passed tagIds
  // I.e. if PEB tag is passed, this function will also add the `Current` tag
  addTags(fileId: number, tagIds: number[]): Observable<File> {
    return this.http.post(`${this.tagsUrl}/addTags/${fileId}`, { tagIds }).pipe(
      map((result: ServiceResponse) => {
        return result.data.file;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // removes the given tagIds from the given fileId
  // Note, this also removes any applicable secondary tags from any given tagIds that are primary tags
  // This function is used when a given file is removed from an area.
  // For example, if a bubble drawing is removed, this function will remove the Bubble Drawing and Current tags, if applicable, from the passed fileId
  removeTags(fileId: number, tagIds: number[]): Observable<File> {
    return this.http.post(`${this.tagsUrl}/removeTags/${fileId}`, { tagIds }).pipe(
      map((result: ServiceResponse) => {
        return result.data.file;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // Adds the `Current` tag to the most recently attached file to the passed task
  // If the file doesn't have the passed primary tag of tagId, it will add that as well
  // In addition, removes the `Current` tag from any peer files attached to the given task
  // This function is used for files such as PEB, where there is only 1 most current version
  // When a new PEB file is generated, pass that task id with the PEB tag id
  // This will set the most recent file as the current version, and verify all previous versions no longer have the current tag
  makeCurrent(tagId: number, taskId: number): Observable<File> {
    return this.http.post(`${this.tagsUrl}/makeCurrent`, { tagId, taskId }).pipe(
      map((result: ServiceResponse) => {
        return result.data.file;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getFilesByParentType(parentTypeId: ResourceType, cursor?: string, limit?: number): Observable<any> {
    return this.http
      .get(
        `${this.filesUrl}?filter=parent_type_id=${parentTypeId}&fields=${this.fileFields}&limit=${limit || 1000}${
          cursor ? `&cursor=${cursor}` : ''
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.file;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createFile(file, parentId: number, parentResourceType: ResourceType, fileName?) {
    const formData = new FormData();
    formData.append('parent_type_id', parentResourceType.toString());
    formData.append('parent_id', parentId.toString());
    formData.append('file', file, fileName || file.name);
    if (file.tag_ids) {
      formData.append('tag_ids', file.tag_ids);
    }
    const fileFields =
      'parent_id,parent_type_id,name,path,created_by_id,created_by_first_name,created_by_last_name,created_datetime,tag_ids,tags';
    return this.http.post(`${this.filesUrl}?fields=${fileFields}`, formData).pipe(
      map((result: ServiceResponse) => {
        return result.data.file;
      }),
      catchError((err) => {
        if (err && err.error && err.error.data && err.error.data.duplicate) {
          return throwError(err.error);
        } else {
          return this.handleErrorService.handleError(err);
        }
      })
    );
  }

  // updateFile(fileId, file, parent, fileName?) {
  //   const formData = new FormData();
  //   formData.append('file', file, fileName || file.name);
  //   return this.http.put(`${this.filesUrl}/${fileId}?fields=${this.fileFields}`, formData).pipe(
  //     map((result: ServiceResponse) => {
  //       const returnedFile: File = result.data.file;
  //       return returnedFile;
  //     }),
  //     catchError((e) => this.handleErrorService.handleError(e))
  //   );
  // }

  updateFileFields(fileId, newFile) {
    return this.http.put(`${this.filesUrl}/${fileId}/fields`, newFile).pipe(
      map((result: ServiceResponse) => {
        const returnedFile: File = result.data.file;
        return returnedFile;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  /** Links a file to a parent object */
  linkFile(fileId: number, parentId: number, parentResourceType: ResourceType) {
    const body = {
      file_id: fileId,
      parent_id: parentId,
      parent_type_id: parentResourceType,
    };
    return this.http.post(`${this.filesUrl}/link?fields=file_id,parent_id,parent_type_id`, body).pipe(
      map((result: ServiceResponse) => {
        const file: File = result.data.file;
        return file;
      }),
      catchError((e) => this.handleErrorService.handleError(e, [409]))
    );
  }

  /** Removes an entry from the bridge table (by setting active to 0) in order to sever a link between a file and its parent */
  // verify that you are passing the bridge table id, not the file id
  // this delete requires the actual bridge table entry (although this is returned when asking for files, so it should be available)
  unlinkFile(bridgeId: number) {
    return this.http.delete(`${this.filesUrl}/link/${bridgeId}`).pipe(
      map((result: ServiceResponse) => {
        return result;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  downloadFile(file) {
    return this.http.get(`${this.filesUrl}/${+file.file_id || +file.id}/download`).pipe(
      map((result: ServiceResponse) => {
        const downloadedFile = result.data;
        return downloadedFile;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public downloadAllFilesByParentId(
    parentId: number,
    parentTypeId: ResourceType,
    parentCode: string,
    fileIds?: string
  ): Observable<any> {
    const file_ids = fileIds ? `,file_ids=${fileIds}` : '';
    return this.http
      .get(`${this.filesUrl}/download-files?filter=parent_type_id=${parentTypeId},parent_id=${parentId}${file_ids}`)
      .pipe(
        map((result: ServiceResponse) => {
          const zip = new JSZip();
          result?.data?.forEach((resultFile) => {
            zip.file(resultFile?.name || 'unknown', resultFile?.file?.data);
          });

          void zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
            saveAs(content, `${parentCode}_${moment().format('YYYY-MM-DD')}-download.zip`);
          });
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  async download(file: UhatFileReference) {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Downloading file...');
    await this.downloadFile(file)
      .toPromise()
      .then(async (fileData) => {
        const [filename, fileExtension] = fileData.name.split('.');
        if (fileExtension?.toLowerCase() === 'heic') {
          const blob = new Blob([new Uint8Array(fileData.file.data)]);
          await heic2any({ blob, toType: 'image/png', quality: 1 }).then(async (pngBlob: Blob) => {
            await saveAs(pngBlob, filename.toLowerCase());
            this.snackbar.open(`${filename?.toLowerCase()}.png has been downloaded`);
          });
        } else {
          await saveAs(new Blob([new Uint8Array(fileData.file.data)]), fileData.name);
          this.snackbar.open(`${fileData.name} has been downloaded`);
        }
        this._progressIndicatorService.close();
      });
  }

  /**
   * Returns an observable of SafeResourceUrl that is linked with the given file. If no image is cached then it is fetched
   * @param file UserId to get the thumbnail image of
   */
  public getCachedFileThumbnailPreview(file: UhatFileReference): Observable<SafeResourceUrl> {
    const fileId = file?.file_id || file?.id;
    const needsFetch = !this._fileThumbnailPreviewUrls.has(fileId);
    if (needsFetch) {
      this._fileThumbnailPreviewUrls.set(fileId, new BehaviorSubject<SafeResourceUrl>(''));
      const getObservable = this.http.get(`${this.filesUrl}/${+fileId || 0}/get_thumbnail`, {
        responseType: 'blob',
      });

      getObservable.subscribe((result) =>
        this._storeFileThumbnailPreview(fileId, result.slice(0, result.size, 'image/png'))
      );
    }
    return this._fileThumbnailPreviewUrls.get(fileId).asObservable();
  }

  async previewFile(file) {
    await this.modalService.openPreviewFileDialog(file).toPromise();
  }

  async deleteFile(file): Promise<boolean> {
    const confirmationData = {
      titleBarText: 'Delete File',
      headerText: `Delete ${file.name}`,
      descriptionText:
        'Warning: You will not be able to recover this file. Are you sure you want to permanently delete this file?',
    };
    const isConfirmed = await this.modalService.openConfirmationDialog(confirmationData).toPromise();

    if (isConfirmed) {
      this.snackbar.open(`Deletion in progress...`);
      await this.deleteFileWithoutConfirmation(file).toPromise();
      this.snackbar.open(`File deleted.`);
    }

    return isConfirmed;
  }

  deleteFileWithoutConfirmation(file): Observable<void> {
    return this.http.delete(`${this.filesUrl}/${file.file_id || file.id}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  combinePDFs(pdfs) {
    const formData = new FormData();
    for (const p of pdfs) {
      formData.append('file', p.file, p.name);
    }
    return this.http.post(`${this.filesUrl}/combine-pdfs`, formData).pipe(
      map((result: ServiceResponse) => {
        return result.data.file;
      }),
      catchError((err) => {
        return this.handleErrorService.handleError(err);
      })
    );
  }

  /*
   * Converts ".ai, .doc, .docm, .docx, .eps, .odp, .odt, .pps, .ppsm, .ppsx, .ppt, .pptm, .pptx, .rtf" files to pdf.
   * Converts ".csv, .ods, .xls, .xlsm, .xlsx" files to html.
   */
  makeFilePreviewable(file) {
    return this.http.post(`${this.filesUrl}/${+file.file_id || +file.id}/make-file-previewable`, file).pipe(
      map((result: ServiceResponse) => {
        return result.data;
      }),
      catchError((err) => {
        return this.handleErrorService.handleError(err);
      })
    );
  }

  // doesFileExist(url: string): Observable<boolean> {
  //   return this.http.get(url, {responseType: 'blob'})
  //     .pipe(
  //       map((result) => {
  //         return true;
  //       }),
  //       catchError((error: HttpErrorResponse) => {
  //         return of(false);
  //       })
  //     );
  // }

  getFilesByProjectId(projectId: number): Observable<UhatFileReference[]> {
    return this.getFilesByParentId(ResourceType.Project, projectId, null, 10000);
  }

  getFilesByTaskId(taskId: number): Observable<UhatFileReference[]> {
    return this.http.get(`${this.filesUrl}?filter=task_id=${taskId}&fields=${this.fileFields}`).pipe(
      map((result: ServiceResponse) => {
        return result.data.files;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // Connects files to a note using the info passed to it
  public async addFilesToNote(note, taskId, attachedFiles, linkedFiles) {
    const createdFiles = [];

    for (let file of attachedFiles) {
      // we need to create each file if they aren't already linked
      file = await this.createFile(file, taskId, ResourceType.Task, file.name).toPromise();
      createdFiles.push({ id: file.file_id || file.id, name: file.name });
      // now link them to the note
      await this.linkFile(file.file_id || file.id, note.id, ResourceType.Note).toPromise();
    }
    for (const file of linkedFiles) {
      // now link them to the note
      await this.linkFile(file.file_id || file.id, note.id, ResourceType.Note).toPromise();
      // we need to also link these to the task as sometimes they will be attached to the project instead
      await this.linkFile(file.file_id || file.id, taskId, ResourceType.Task).toPromise();
    }

    return createdFiles;
  }

  /**
   * Given a File Id (Not from the bridge table) return the name and id of the file
   * @param fileId File Id to get name of
   */
  public getIdAndName(fileId: number): Observable<{ id: number; name: string }> {
    return this.http.get(`${this.filesUrl}/${fileId}/name?fields=id,name`).pipe(
      map((result: ServiceResponse) => {
        const file = result.data.file[0];
        return file;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  /**
   * This will take a file, and if it is an image will get the image data, convert to base64 and store it as base64 property on the file object.
   * @param file The file to get the base64 data for
   */
  public fillFileWithBase64(file: UhatFileReference) {
    if (file.name.endsWith('.jpg') || file.name.endsWith('.png') || file.name.endsWith('.img')) {
      this.downloadFile(file).subscribe((downloadedFile) => {
        file.base64 = this._arrayBufferToBase64(downloadedFile.file.data);
      });
    }
  }

  /**
   * Convert an array buffer to base64 string
   * @param buffer The buffer to convert
   */
  private _arrayBufferToBase64(buffer) {
    const base64 =
      'data:image/jpg;base64,' + btoa([].reduce.call(new Uint8Array(buffer), (p, c) => p + String.fromCharCode(c), ''));
    return base64;
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';
import {
  NewAccountModalComponent,
  TroubleshootLoginDialogComponent,
  UserProfilePreviewComponent,
} from 'src/app/components';
import {
  AuthService,
  MessagingSystemService,
  ModalService,
  ProgressIndicatorService,
  SidenavService,
  UserService,
} from 'src/app/services';
import { APIFilter, User } from 'src/app/types';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
})
export class AccountsComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;

  private userFields = [
    'email',
    'first_name',
    'last_name',
    'user_type_id',
    'user_type_name',
    'company_name',
    'department_name',
    'building_name',
    'floor_name',
    'suite_name',
    'title',
    'cell_phone',
    'office_phone',
    'is_guest_user',
    'is_enabled',
    'is_login_enabled',
    'is_ar',
  ];

  canReviewAccounts: boolean;
  public users: User[];
  public currentUser: User;

  public selectedUserType: number;

  private _searchString = ''; // switched to a get since its binded to ngModel and we need it trimed when used in the list

  public showARsOnly = false;
  showInactiveUsers = false;

  public userTypes: { id: number; name: string }[];
  public userTypesCount = {};

  public fieldToSortBy: string;
  public sortDirection: SortDirection = 'desc';

  public isSidebarClosed: boolean;

  length = 100;
  pageSizeOptions: number[] = [5, 10, 20];
  pageSize = 20;
  startIndex = 0;
  endIndex = this.pageSize;
  filteredCount = { count: 0 };

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;

  public showDesktop: boolean;

  public showIpad: boolean;

  public divWidth: number;

  private currentSubscription;

  selectedView;
  constructor(
    private userService: UserService,
    private sidenavService: SidenavService,
    private snackbar: MatSnackBar,
    private messagingService: MessagingSystemService,
    private dialog: MatDialog,
    private progressIndicatorService: ProgressIndicatorService,
    public authService: AuthService,
    private modalService: ModalService,
    private router: Router
  ) {
    this.isSidebarClosed = sidenavService.isSidenavClosed;
    // keep track of subscription so we can destroy it when the component is destroyed
    this.currentSubscription = sidenavService.sidenavVisibilityChange.subscribe((value) => {
      this.isSidebarClosed = value;
    });
  }

  async ngOnInit() {
    this.selectedView = this.router.url;
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Users..');
    this.currentUser = this.authService.getLoggedInUser();
    this.userTypes = await this.userService.getUserTypes().toPromise();
    this.userTypes.map((userType) => {
      if (userType.name === 'Staff') {
        userType.name = 'UHAT/1Call';
      }
    });
    this.userTypes.push({ id: 4, name: 'Guest' });

    await this.refresh();

    const preferences = JSON.parse(localStorage.getItem('preferences'));
    this.selectedUserType = Number.isInteger(preferences?.filter_directory_by_user_type)
      ? preferences.filter_directory_by_user_type
      : 1;
    this.sortDirection = preferences ? preferences.sort_directory_order : null;
    this.fieldToSortBy = preferences ? preferences.sort_directory_by_field : null;

    this.progressIndicatorService.close();

    this.canReviewAccounts = this.authService.isAppAdmin || this.authService.currentUserIsOfWorkspaceRole(2, null);
  }

  async refresh() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Users..');
    const userFilters: APIFilter[] = [];
    this.users = await this.userService.getUsers(userFilters, this.userFields, null, true).toPromise();
    this.getUserTypeCounts();
    this.progressIndicatorService.close();
  }

  ngOnDestroy(): void {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }
  }

  selectionChange(event) {
    this.router.navigateByUrl(event.value);
  }

  get searchString() {
    return this._searchString?.trim() || '';
  }

  set searchString(value: string) {
    this._searchString = value?.trim() || '';
  }

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  private async getUserTypeCounts() {
    this.userTypes.forEach((status) => {
      if (status.id !== 4) {
        this.userTypesCount[status.id] =
          (this.users &&
            this.users.filter((user) => {
              const showARsOnly = this.showARsOnly === true ? user.is_ar === 1 : true;
              const showInactiveUsers = this.showInactiveUsers === true ? true : user.is_enabled === 1;
              return user.user_type_id === status.id && !user.is_guest_user && showARsOnly && showInactiveUsers;
            }).length) ||
          0;
      } else {
        this.userTypesCount[status.id] =
          (this.users &&
            this.users.filter((user) => {
              const showARsOnly = this.showARsOnly === true ? user.is_ar === 1 : true;
              const showInactiveUsers = this.showInactiveUsers === true ? true : user.is_enabled === 1;
              return user.is_guest_user && showARsOnly && showInactiveUsers;
            }).length) ||
          0;
      }
    });

    this.userTypesCount['0'] =
      this.users.filter((user) => {
        const showARsOnly = this.showARsOnly === true ? user.is_ar === 1 : true;
        const showInactiveUsers = this.showInactiveUsers === true ? true : user.is_enabled === 1;
        return showARsOnly && showInactiveUsers;
      }).length || 0;
  }

  addAccount() {
    const dialogRef = this.dialog.open(NewAccountModalComponent, {
      width: '540px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.refresh();
      }
    });
  }

  public addSToUserType(userType) {
    return userType.name !== 'UHAT/1Call' ? 's' : '';
  }

  openUserProfilePreview(userId) {
    this.dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      data: {
        userId,
      },
    });
  }

  public setSearchString(string = '') {
    this.searchString = string?.trim();
    this.goToFirstPage();
  }

  public setUserType(userTypeId) {
    this.selectedUserType = userTypeId;

    localStorage.setItem(
      'preferences',
      JSON.stringify(this.addToPreferences('filter_directory_by_user_type', this.selectedUserType))
    );
    this.goToFirstPage();
  }

  async impersonateUser(user: User) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Confirm Impersonation',
        descriptionText: `Are you sure you would like to begin impersonating ${user.first_name} ${user.last_name}?`,
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          await this.authService.impersonateUser(user.id).toPromise();
        }
      });
  }

  inviteUser(user: User) {
    this.modalService.openInviteUserModal({ users: [user] })?.subscribe();
  }

  public updateSortByField(field: string) {
    if (field === this.fieldToSortBy) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      localStorage.setItem(
        'preferences',
        JSON.stringify(this.addToPreferences('sort_directory_order', this.sortDirection))
      );
    } else {
      this.sortDirection = 'asc';
    }
    this.fieldToSortBy = field;
    localStorage.setItem(
      'preferences',
      JSON.stringify(this.addToPreferences('sort_directory_by_field', this.fieldToSortBy))
    );
  }

  pageChange(event) {
    this.startIndex = event.pageIndex * event.pageSize;
    this.endIndex = this.startIndex + event.pageSize;
  }

  goToFirstPage() {
    this.paginator.firstPage();
    this.startIndex = 0;
    this.endIndex = this.pageSize;
  }

  searchChanged() {
    this.goToFirstPage();
  }

  private addToPreferences(key: string, value: any) {
    const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
    preferences[key] = value;

    return preferences;
  }

  public openLoginHelp() {
    this.dialog.open(TroubleshootLoginDialogComponent, {
      data: {},
      width: '400px',
    });
  }

  filterARsOnly() {
    this.showARsOnly = !this.showARsOnly;
    this.getUserTypeCounts();
    this.goToFirstPage();
  }

  filterInactiveUsersOnly() {
    this.showInactiveUsers = !this.showInactiveUsers;
    this.getUserTypeCounts();
    this.goToFirstPage();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { sortBy, uniqBy } from 'lodash';
import { map } from 'rxjs/operators';
import { EditorComponent, FormComponent, UserSelectModalComponent } from 'src/app/components';
import { ResourceType, TopicType, UserType, Workspace } from 'src/app/enums';
import {
  AuthService,
  FileService,
  LocationService,
  ModalService,
  ProgressIndicatorService,
  RequestService,
  TopicService,
  UserService,
  WorkOrderService,
} from 'src/app/services';
import {
  APIFilter,
  Building,
  Department,
  Floor,
  Request,
  Suite,
  Topic,
  TopicCategory,
  TopicGroup,
  UhatFileReference,
  User,
  WorkOrder,
} from 'src/app/types';
import { ConstructionAccessComponent } from './construction-access/construction-access.component';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss'],
})
export class NewRequestComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  constructor(
    private fb: FormBuilder,
    private requestService: RequestService,
    private userService: UserService,
    private locationService: LocationService,
    private authService: AuthService,
    private workOrderService: WorkOrderService,
    private dialog: MatDialog,
    private modalService: ModalService,
    private snackbar: MatSnackBar,
    private router: Router,
    private fileService: FileService,
    private topicService: TopicService,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  @ViewChild('stepper') private stepper: MatStepper;
  showStepper = false;
  currentUser: User;
  isStaff: boolean;
  isAR: boolean;
  isOUPD: boolean;
  ExtSecurity: boolean;
  searchTerm: any;
  allTopicGroups: TopicGroup[] = [];
  allTopicCategories: TopicCategory[] = [];
  filteredTopicCategories: TopicCategory[] = [];
  allTopics: Topic[] = [];
  filteredTopics: Topic[] = [];
  selectedGroup: TopicGroup;
  selectedCategory: TopicCategory;
  selectedTopic: Topic;
  locationEditable = false;
  buildings: Building[] = [];
  allBuildings: Building[] = [];
  floors: Floor[] = [];
  suites: Suite[] = [];
  departments: Department[] = [];
  contacts: User[] = [];
  attachedFiles: UhatFileReference[] = [];
  @ViewChild('topicForm') topicForm: FormComponent;
  @ViewChild('topicForms') topicForms: ConstructionAccessComponent;
  topicFormElements;

  locationFormGroup: FormGroup = this.fb.group({
    building: ['', [Validators.required]],
    floor: ['', [Validators.required]],
    suite: [''],
    department: [''],
    landmark: [''],
  });

  private hoverOptions = {};

  get building() {
    return this.locationFormGroup.get('building');
  }
  get floor() {
    return this.locationFormGroup.get('floor');
  }
  get suite() {
    return this.locationFormGroup.get('suite');
  }
  get department() {
    return this.locationFormGroup.get('department');
  }
  get landmark() {
    return this.locationFormGroup.get('landmark');
  }

  get summary(): AbstractControl {
    return this._editor_component.content;
  }

  async ngOnInit() {
    this.isStaff = this.authService.isStaffOnAnyModule;
    this.isAR = this.authService.isAR;
    this.isOUPD = this.authService.isOUPD;
    this.ExtSecurity = this.authService.isExtSecurity;
    const visibleTo = ['null'];
    if (this.isStaff) {
      visibleTo.push('1');
    }
    if (this.isAR) {
      visibleTo.push('2');
    }
    if (this.isOUPD) {
      visibleTo.push('4');
    }
    if (this.ExtSecurity) {
      visibleTo.push('7');
    }
    const topicFilter: APIFilter[] = [{ type: 'field', field: 'visible_to', value: visibleTo.join('^') }];
    const allTopics = (
      await this.topicService.getTopics(this.topicService.newRequestTopicFields, topicFilter).toPromise()
    ).filter((topic) => topic.visible_to !== undefined && topic.visible_to !== '[]');
    this.allTopics = sortBy(allTopics, (t) => [t.topic_category?.topic_group?.name, t.topic_category?.name, t.name]);
    for (const t of allTopics) {
      if (t.visible_to) {
        t.visible_to = JSON.parse(t.visible_to);
      }
      if (t.selectable_by) {
        t.selectable_by = JSON.parse(t.selectable_by);
        if (t.selectable_by?.length > 0 && t.selectable_by?.indexOf(2) > -1) {
          t.requiresAR = true;
        }
      }
    }
    this.filteredTopics = this.allTopics;
    this.allTopicCategories = sortBy(
      uniqBy(
        this.allTopics.map((t) => t.topic_category),
        'id'
      ),
      'name'
    );
    for (const c of this.allTopicCategories) {
      if (!this.allTopics.find((t) => !t.requiresAR && t.topic_category?.id === c.id)) {
        c.requiresAR = true;
      }
    }
    this.allTopicGroups = sortBy(
      uniqBy(
        this.allTopicCategories.map((c) => c.topic_group),
        'id'
      ),
      'name'
    );
    for (const g of this.allTopicGroups) {
      if (!this.allTopicCategories.find((c) => !c.requiresAR && c.topic_group?.id === g.id)) {
        g.requiresAR = true;
      }
    }

    this.currentUser = await this.userService
      .getUserById(this.authService.getLoggedInUser().id, [
        'first_name',
        'last_name',
        'building{id,name}',
        'floor{id,name}',
        'suite{id,name}',
        'department{id,name}',
      ])
      .toPromise();
    this.populateLocationData(this.currentUser);
  }

  filterTopics() {
    if (this.searchTerm) {
      if (this.searchTerm.id) {
        this.searchTerm = '';
      } else {
        this.filteredTopics = this.allTopics.filter(
          (t) =>
            (t.name || '').toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            (t.topic_category?.name || '').toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
    } else {
      this.filteredTopics = this.allTopics;
    }
  }

  selectGroup(group: TopicGroup, fromCategory = false) {
    if (group?.requiresAR && !this.isAR && !this.isStaff) {
      return;
    }
    if (!fromCategory && this.selectedGroup?.id !== group?.id) {
      this.selectCategory(null);
    }
    this.selectedGroup = group;
    if (this.selectedGroup) {
      this.filteredTopicCategories = this.allTopicCategories.filter((c) => c.topic_group_id === this.selectedGroup.id);
      if (this.selectedGroup.building_ids) {
        const buildingIds = JSON.parse(this.selectedGroup.building_ids);
        this.buildings = this.allBuildings.filter((b) => buildingIds.indexOf(b.id) > -1);
      } else {
        this.buildings = this.allBuildings;
      }
    } else {
      this.showStepper = false;
      this.filteredTopics = this.allTopics;
      this.selectCategory(null);
      this.selectTopic(null);
      this.buildings = this.allBuildings;
    }
  }

  selectCategory(category: TopicCategory, fromTopic = false) {
    if (category?.requiresAR && !this.isAR && !this.isStaff) {
      return;
    }
    if (!fromTopic && this.selectedCategory?.id !== category?.id) {
      this.selectTopic(null);
    }
    this.selectedCategory = category;
    if (this.selectedCategory) {
      this.filteredTopics = this.allTopics.filter((t) => t.topic_category_id === this.selectedCategory.id);
      if (this.selectedCategory?.topic_group) {
        this.selectGroup(this.selectedCategory.topic_group, true);
      }
    } else {
      this.selectTopic(null);
    }
  }

  selectTopic(topic: Topic, stepToSelect?: number) {
    if (topic?.requiresAR && !this.isAR && !this.isStaff) {
      return;
    }
    this.selectedTopic = topic;
    if (this.selectedTopic?.topic_category) {
      this.selectCategory(this.selectedTopic.topic_category, true);
    }
    if (stepToSelect) {
      if (this.selectedTopic?.id === 46) {
        this.router.navigate([`/construction-request`, { requestTypeId: 1 }]);
      } else if (this.selectedTopic?.id === 47) {
        this.router.navigate([`/construction-request`, { requestTypeId: 2 }]);
      } else if (this.selectedTopic?.workspace_id === Workspace.Construction && !this.selectedTopic?.is_4dx) {
        this.router.navigate([`/construction-request`]);
      }
      this.showStepper = true;
      this.stepper.linear = false;
      this.stepper.selectedIndex = stepToSelect;
      setTimeout(() => {
        this.stepper.linear = true;
      });
    }
  }

  completeTopicStep() {
    if (this.selectedTopic?.id === 46) {
      this.router.navigate([`/construction-request`, { requestTypeId: 1 }]);
    } else if (this.selectedTopic?.id === 47) {
      this.router.navigate([`/construction-request`, { requestTypeId: 2 }]);
    }
  }

  stepChanged(event) {
    if (event?.selectedIndex === this.stepper?.steps?.length - 1 && this.selectedTopic?.form) {
      if (this.topicForm) {
        const [topicFormSubmission, parentValues] = this.topicForm.getSubmissionValue();
        let elements = [];
        for (const s of this.selectedTopic.form.content.sections) {
          elements = elements.concat(s.elements);
        }
        if (topicFormSubmission.content.elements) {
          for (const e of elements) {
            const foundElement = topicFormSubmission.content.elements.find((se) => e.id === se.id);
            e.value = foundElement?.value;
          }
        }
        this.topicFormElements = elements;
      } else if (this.topicForms) {
        this.topicFormElements = this.topicForms.getValue();
      }
    }
  }

  move(stepper: MatStepper, index: number) {
    stepper.selectedIndex = index;
  }

  getFloors() {
    if (this.building.value) {
      const floorFilters: APIFilter[] = [
        { type: 'field', field: 'building_id', value: this.building.value.id },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'is_enabled', value: '1' },
      ];
      this.locationService.getFloors(['id', 'name'], floorFilters).subscribe((floors: Floor[]) => {
        this.floors = floors;
        this.floor.setValue(null);
        this.suites = [];
        this.suite.setValue(null);
        this.departments = [];
        this.department.setValue(null);
      });
    } else {
      [this.floors, this.suites, this.departments] = [null, null, null];
      this.floor.setValue(null);
      this.suites = [];
      this.suite.setValue(null);
      this.departments = [];
      this.department.setValue(null);
    }
  }

  getSuites() {
    if (this.floor.value) {
      const suiteFilters: APIFilter[] = [
        { type: 'field', field: 'floor_id', value: this.floor.value.id },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'is_enabled', value: '1' },
      ];
      this.locationService.getSuites(['id', 'name'], suiteFilters).subscribe((suites: Suite[]) => {
        this.suites = suites;
        this.suite.setValue(null);
        this.departments = [];
        this.department.setValue(null);
      });
    } else {
      [this.suites, this.departments] = [null, null];
      this.suite.setValue(null);
      this.departments = [];
      this.department.setValue(null);
    }
  }

  getDepartments() {
    if (this.suite.value) {
      const departmentFilters: APIFilter[] = [
        { type: 'field', field: 'suite_id', value: this.suite.value.id },
        { type: 'operator', value: 'AND' },
        { type: 'operator', value: '(' },
        { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'is_enabled', value: '1' },
        { type: 'operator', value: ')' },
      ];
      this.locationService
        .getDepartments(['id', 'name', 'is_enabled'], departmentFilters)
        .pipe(map((departments: Department[]) => departments.filter((d) => !!d.is_enabled)))
        .subscribe((departments: Department[]) => {
          this.departments = departments;
          this.department.setValue(null);
        });
    } else {
      this.departments = null;
      this.department.setValue(null);
    }
  }

  populateLocationData(user: User) {
    [this.buildings, this.floors, this.suites, this.departments] = [
      [{ id: null, name: null }],
      [{ id: null, name: null }],
      [{ id: null, name: null }],
      [{ id: null, name: null }],
    ];
    const buildingFilters: APIFilter[] = [{ type: 'field', field: 'is_enabled', value: '1' }];
    this.locationService.getBuildings(['id', 'name'], buildingFilters).subscribe((buildings: Building[]) => {
      this.allBuildings = buildings;
      this.buildings = this.allBuildings;
      const foundBuilding = this.buildings.find((b) => b.id === user.building?.id);
      if (foundBuilding) {
        this.building.setValue(foundBuilding);
      }
      if (this.building.value && this.building.value.id) {
        const floorFilters: APIFilter[] = [
          { type: 'field', field: 'building_id', value: this.building.value.id },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_enabled', value: '1' },
        ];
        this.locationService.getFloors(['id', 'name'], floorFilters).subscribe((floors: Floor[]) => {
          this.floors = floors;
          const foundFloor = this.floors.find((f) => f.id === user.floor?.id);
          if (foundFloor) {
            this.floor.setValue(foundFloor);
          }
          if (this.floor.value && this.floor.value.id) {
            const suiteFilters: APIFilter[] = [
              { type: 'field', field: 'floor_id', value: this.floor.value.id },
              { type: 'operator', value: 'AND' },
              { type: 'field', field: 'is_enabled', value: '1' },
            ];
            this.locationService.getSuites(['id', 'name'], suiteFilters).subscribe((suites: Suite[]) => {
              this.suites = suites;
              const foundSuite = this.suites.find((s) => s.id === user.suite?.id);
              if (foundSuite) {
                this.suite.setValue(foundSuite);
              }
              if (this.suite.value && this.suite.value.id) {
                const departmentFilters: APIFilter[] = [
                  { type: 'field', field: 'suite_id', value: this.suite.value.id },
                  { type: 'operator', value: 'AND' },
                  { type: 'operator', value: '(' },
                  { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
                  { type: 'operator', value: 'AND' },
                  { type: 'field', field: 'is_enabled', value: '1' },
                  { type: 'operator', value: ')' },
                ];
                this.locationService
                  .getDepartments(['id', 'name', 'is_enabled'], departmentFilters)
                  .pipe(map((departments: Department[]) => departments.filter((d) => !!d.is_enabled)))
                  .subscribe((departments: Department[]) => {
                    this.departments = departments;
                    const foundDepartment = this.departments.find((d) => d.id === user.department?.id);
                    if (foundDepartment) {
                      this.department.setValue(foundDepartment);
                    }
                  });
              }
            });
          }
        });
      }
    });
  }

  clearLocationData() {
    this.department.setValue(null);
    this.departments = [];
    this.suite.setValue(null);
    this.suites = [];
    this.floor.setValue(null);
    this.floors = [];
    this.building.setValue(null);
  }

  openBulkSelectModal() {
    this.dialog
      .open(UserSelectModalComponent, {
        data: {
          title: 'Add Contacts',
          createUser: { title: 'Guest', guestUser: false },
          includeGuestUsers: true,
          allowedUserTypeIds: [UserType.Staff, UserType.Tenant],
          preSelectedUsers: this.contacts,
          excludeVendors: true,
          defaultUserTypeId: UserType.Everyone,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async ({ selectedUsers, deSelectedUsers }) => {
        if (deSelectedUsers?.length) {
          for (const deSelectedUser of deSelectedUsers) {
            this.removeContact(deSelectedUser);
          }
        }

        if (selectedUsers?.length) {
          for (const selectedUser of selectedUsers) {
            this.addContact(selectedUser);
          }
        }
      });
  }

  addContact(user) {
    const foundContact = this.contacts?.find((c) => c.id === user.id);
    if (foundContact || user.id === this.currentUser.id) {
      this.snackbar.open(`One or more users have already been added`);
    } else {
      // pure
      this.contacts = [
        ...this.contacts,
        {
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        },
      ];
    }
  }

  removeContact(contact) {
    this.contacts = this.contacts?.filter((c) => c.id !== contact.id);
  }

  isRequestValid() {
    const isValid = this.currentUser && this.selectedTopic && this.locationFormGroup.valid && this.summary.value;
    return isValid;
  }

  async resetForm() {
    this.stepper.reset();
    this.showStepper = false;
    this._editor_component.content.setValue(null);
    this.selectedGroup = null;
    this.selectedCategory = null;
    this.selectedTopic = null;
    this.contacts = [];
    await this.populateLocationData(this.currentUser);
    this.attachedFiles = [];
  }

  async submitRequest() {
    if (this.isRequestValid()) {
      try {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Submitting..');
        if (this.selectedTopic.topic_type_id === TopicType.Request) {
          const request: Request = {
            requester_id: this.currentUser.id,
            module_id: this.selectedTopic.workspace_id,
            building_id: this.building.value?.id,
            floor_id: this.floor.value?.id,
            suite_id: this.suite.value?.id,
            department_id: this.department.value?.id,
            landmark: this.landmark.value,
            summary: this.summary.value,
            contact_ids: JSON.stringify(this.contacts.map((c) => c.id)),
            topic_id: this.selectedTopic.id,
            form_submission: this.selectedTopic.form
              ? JSON.stringify({
                  form_template_id: this.selectedTopic.form.id,
                  elements: this.topicFormElements.map((e) => ({ id: e.id, value: e.value })),
                })
              : null,
          };
          const createdRequest = await this.requestService.createRequest(request).toPromise();
          this.attachedFiles.forEach((file) => {
            this.fileService.createFile(file, createdRequest.id, ResourceType.Request).subscribe();
          });
          this.snackbar.open('Request created!');

          const requestData = {
            request: createdRequest,
            files: this.attachedFiles,
            contacts: this.contacts,
          };
          this.modalService
            .openRequestReceiptDialog(requestData)
            .toPromise()
            .then(async () => {
              await this.resetForm();
            });
        } else if (this.selectedTopic.topic_type_id === TopicType.WorkOrder) {
          const workOrder: WorkOrder = {
            module_id: this.selectedTopic?.target_workspace_id || this.selectedTopic.workspace_id,
            building_id: this.building.value?.id,
            floor_id: this.floor.value?.id,
            suite_id: this.suite.value?.id,
            department_id: this.department.value?.id,
            landmark: this.landmark.value,
            summary: this.summary.value,
            follower_ids: JSON.stringify(this.contacts.map((c) => c.id)),
            topic_id: this.selectedTopic.id,
            requester_id: this.currentUser.id,
            form_submission: this.selectedTopic.form
              ? JSON.stringify({
                  form_template_id: this.selectedTopic.form.id,
                  elements: this.topicFormElements.map((e) => ({ id: e.id, label: e.label, value: e.value })),
                })
              : null,
          };
          const createdWorkOrder = await this.workOrderService.createWorkOrder(workOrder).toPromise();

          for (const file of this.attachedFiles) {
            await this.fileService.createFile(file, createdWorkOrder.id, ResourceType.WorkOrder).toPromise();
          }

          this.snackbar.open('Work order created!');

          const workOrderData = {
            workOrder: createdWorkOrder,
            files: this.attachedFiles,
            contacts: this.contacts,
          };
          this.modalService
            .openRequestReceiptDialog(workOrderData)
            .toPromise()
            .then(async () => {
              await this.resetForm();
            });
        }
        this.progressIndicatorService.close();
      } catch (e) {
        this.progressIndicatorService.close();
        throw e;
      }
    } else {
      this.snackbar.open('Please fill out required fields');
    }
  }

  public async openUploadModal() {
    const data = {
      parentResourceType: null,
      parentResourceId: null,
      preSelectedTags: [],
      allowComment: false,
      skipUpload: true,
      allowSearchFromProject: false,
      currentAttachedFiles: this.attachedFiles,
    };
    const files = await this.modalService.openFileAttachmentDialog(data).toPromise();
    if (files?.computerFiles?.length) {
      this.attachedFiles = [...this.attachedFiles, ...files.computerFiles];
    }
  }

  public removeAttachedFile(file) {
    this.attachedFiles.splice(this.attachedFiles.indexOf(file), 1);
  }

  menuEnter(topicId) {
    this.hoverOptions[topicId].isMatMenuOpen = true;
  }

  menuLeave(trigger, topicId) {
    setTimeout(() => {
      if (!this.hoverOptions[topicId]?.enteredButton) {
        this.hoverOptions[topicId].isMatMenuOpen = false;
        trigger.closeMenu();
      } else {
        this.hoverOptions[topicId].isMatMenuOpen = false;
      }
    }, 80);
  }

  buttonEnter(trigger, topicId) {
    setTimeout(() => {
      if (!this.hoverOptions[topicId]?.isMatMenuOpen) {
        this.hoverOptions[topicId] = { ...this.hoverOptions[topicId], ...{ enteredButton: true } };
        trigger.openMenu();
      } else {
        this.hoverOptions[topicId] = { ...this.hoverOptions[topicId], ...{ enteredButton: true } };
      }
    });
  }

  buttonLeave(trigger, topicId) {
    setTimeout(() => {
      if (this.hoverOptions[topicId]?.enteredButton && !this.hoverOptions[topicId]?.isMatMenuOpen) {
        trigger.closeMenu();
      }
      if (!this.hoverOptions[topicId]?.isMatMenuOpen) {
        trigger.closeMenu();
      } else {
        this.hoverOptions[topicId].enteredButton = false;
      }
    }, 200);
  }
}

<div id="dispatch-request" data-test-id="dispatch-request" class="dispatch-request">
  <form [formGroup]="dispatchFormGroup">
    <header id="item-header" class="d-flex align-items-center bg-green p-4">
      <h4 *ngIf="!isEditing" class="white mb-0 font-weight-semibold mr-auto">New Item</h4>
      <h4 *ngIf="isEditing" class="white mb-0 font-weight-semibold mr-auto">
        {{ request_code }}
      </h4>
      <button
        *ngIf="!isEditing && !dispatchFormGroup.pristine"
        type="button"
        mat-stroked-button
        color="accent"
        class="white lh-30 ml-3"
        (click)="clear()"
      >
        Clear Form
      </button>
      <button *ngIf="isDraft && isEditing" type="button" mat-stroked-button class="white lh-30 ml-3" (click)="delete()">
        <span>Delete Item</span>
      </button>
      <i matRipple class="material-icons white br-50 pointer ml-3" (click)="close()">close</i>
    </header>

    <section cdkScrollable id="item-form" class="p-4" style="position: relative">
      <div class="row mb-4 align-items-center">
        <div class="col-3">
          <h6 class="m-0 dkblue font-weight-semibold">Requestor*</h6>
        </div>
        <div class="col-9">
          <app-search-user-input
            #requested_by
            [userTypes]="notVendors"
            [placeholder]="requestorPlaceholder"
            [show_department]="true"
            [initialValueId]="initialRequestingUserId"
            [showAddAccount]="true"
          ></app-search-user-input>
        </div>
      </div>

      <div class="row mb-4 align-items-center">
        <div class="col-3">
          <h6 class="m-0 dkblue font-weight-semibold mb-2">Request Method</h6>
        </div>
        <div class="col-9">
          <app-request-method [showTitle]="false" [defaultRequestMethodId]="2" #requestMethod></app-request-method>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-12">
          <app-dispatch-topic #dispatchTopic (convert)="convert()"> </app-dispatch-topic>
        </div>
      </div>
      <div class="row mb-4 align-items-top">
        <div class="col-3">
          <h6 class="dkblue m-0 lh-46 mb-4">Building/Floor*</h6>
          <h6 class="dkblue m-0 lh-46 mb-4">Suite/Department</h6>
          <h6 class="dkblue m-0 lh-46 mb-0">Room #/Landmark</h6>
        </div>
        <div class="col-9">
          <app-location #locations [buildingIds]="buildingIds" [isDispatch]="true" [showLandmarkField]="true">
          </app-location>
        </div>
      </div>

      <div [hidden]="!isWorkOrderTopic" class="row mb-4 align-items-center">
        <div class="col-3">
          <h6 class="m-0 dkblue">
            <span class="mr-2"> Priority Level: </span>
          </h6>
        </div>
        <div class="col-9">
          <mat-button-toggle-group
            type="button"
            name="priority"
            aria-label="Priority"
            class="full-width"
            formControlName="priority"
          >
            <mat-button-toggle
              type="button"
              *ngFor="let priorityOption of priorities"
              [ngClass]="{
                dkgray: !priorityOption?.id,
                dkgray: priorityOption?.id === 1,
                ltblue: priorityOption?.id === 2,
                red: priorityOption?.id === 3,
                dkred: priorityOption?.id === 4
              }"
              class="font-weight-semibold"
              [value]="priorityOption"
              >{{ priorityOption.name }}</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div [hidden]="!isWorkOrderTopic" class="row mb-4 align-items-center">
        <div class="col-3">
          <h6 class="m-0 dkblue">Assigned To</h6>
        </div>
        <div class="col-9">
          <app-search-user-input
            #assigned_user
            [userTypes]="staffOnly"
            [placeholder]="assignToPlaceholder"
            [show_department]="true"
            [initialValueId]="initialAssignedUserId"
          ></app-search-user-input>
        </div>
      </div>
      <div [hidden]="!isWorkOrderTopic" class="row mb-4 align-items-center">
        <div id="status" class="col-3">
          <h6 class="m-0 dkblue font-weight-semibold">Status</h6>
        </div>
        <div class="col-9">
          <mat-form-field class="full-width" appearance="outline">
            <mat-select placeholder="Set Status" formControlName="status">
              <mat-select-trigger>
                <mat-icon *ngIf="statusId === 1" class="green d-inline-block align-middle mr-2"
                  >label_important
                </mat-icon>
                <mat-icon *ngIf="statusId === 2" class="red d-inline-block align-middle mr-2">flag</mat-icon>
                <mat-icon *ngIf="statusId === 3" class="gray d-inline-block align-middle mr-2">done_all</mat-icon>
                <span class="d-inline-block align-middle">{{ statusName }}</span>
              </mat-select-trigger>

              <mat-option *ngFor="let statusOption of statusTypes" [value]="statusOption">
                <mat-icon
                  [ngClass]="{
                    green: statusOption?.id === 1,
                    red: statusOption?.id === 2,
                    gray: statusOption?.id === 3
                  }"
                  class="d-inline-block align-middle mr-2"
                  >{{ statusLabel(statusOption) }}</mat-icon
                >
                <span class="d-inline-block align-middle">{{ statusOption.name }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="!isEditing" class="row mb-4 align-items-top">
        <div class="col-3">
          <h6 class="mb-3 dkblue font-weight-semibold lh-32">Attachments</h6>
        </div>
        <div class="col-9">
          <mat-chip-list>
            <app-file-chip
              *ngFor="let file of attachedFiles"
              [file]="file"
              [removable]="true"
              (removeEvent)="removeAttachedFile(file)"
            ></app-file-chip>
          </mat-chip-list>
          <button type="button" mat-button color="accent" class="mr-3 lh-32 my-2" (click)="openUploadModal()">
            + Files
          </button>
        </div>
      </div>
      <div *ngIf="isEditing" class="row mb-4 align-items-top">
        <div class="col-3">
          <h6 class="mb-3 dkblue font-weight-semibold lh-32">Attachments</h6>
        </div>
        <div class="col-9">
          <mat-chip-list>
            <app-file-chip
              *ngFor="let f of existingFiles"
              [file]="f"
              [removable]="true"
              (removeEvent)="removeExistingFile(f)"
            ></app-file-chip>
            <app-file-chip *ngFor="let f of newFiles" [file]="f" [removable]="true" (removeEvent)="removeNewFile(f)">
            </app-file-chip>
          </mat-chip-list>
          <button type="button" mat-button color="accent" class="mr-3 lh-32 my-2" (click)="openUploadModal()">
            + Files
          </button>
        </div>
      </div>
      <div class="row mb-4 align-items-top">
        <div class="col-3">
          <h6 class="m-0 lh-40 dkblue">Followers</h6>
        </div>
        <div class="col">
          <app-add-followers
            #contacts
            [displayTitle]="followersDisplayTitle"
            [allowedUserTypeIds]="notVendors"
            [defaultUserTypeId]="UserType.Everyone"
            saveOnClose="false"
          ></app-add-followers>
        </div>
      </div>
      <div [hidden]="!(isDraft && isWorkOrderTopic)" class="row mb-4 align-items-top">
        <div class="col-3">
          <h6 class="m-0 lh-40 dkblue">Update</h6>
        </div>
        <div *ngIf="!showWorkOrderInitialUpdateEditor" class="col">
          <button
            *ngIf="!showWorkOrderInitialUpdateEditor"
            type="button"
            mat-button
            color="accent"
            class="mr-3 lh-32 my-2"
            (click)="showWorkOrderInitialUpdateEditor = true"
          >
            + Update
          </button>
        </div>
        <div [hidden]="!showWorkOrderInitialUpdateEditor" class="col text-area">
          <app-editor
            #workOrderInitialUpdateEditor
            [class_height]="'small-fixed'"
            placeholder="Work order update"
          ></app-editor>
        </div>
      </div>
      <div *ngIf="isEditing" class="row mb-5 align-items-center">
        <div class="col">
          <app-dispatch-activities [activity]="dispatchItem?.reassignment_activity"></app-dispatch-activities>
        </div>
      </div>
    </section>
    <section id="item-actions" class="p-4">
      <div class="row mb-4 align-items-center">
        <div class="col">
          <h6 class="m-0 dkblue font-weight-semibold mb-2">Short Description*</h6>
          <mat-form-field appearance="outline" class="full-width relative-button-clear-parent">
            <input
              type="text"
              placeholder="Enter Short Description"
              aria-label="description"
              matInput
              formControlName="short_description"
            />
            <mat-icon *ngIf="short_description?.value" matSuffix class="gray pointer" (click)="clearShortDescription()">
              cancel</mat-icon
            >
            <mat-hint
              align="end"
              [ngClass]="{
                red: short_description?.value?.length > short_description_length
              }"
              >{{ short_description_counter }}</mat-hint
            >
            <mat-error *ngIf="short_description.errors?.required"
              >A short description is required to create item
            </mat-error>
            <mat-error *ngIf="short_description.errors?.maxlength"
              >Max length of {{ short_description_length }} characters</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4 align-items-center">
        <div class="col text-area">
          <h6 class="m-0 dkblue font-weight-semibold mb-2">Summary*</h6>
          <app-editor
            #commentEditor
            [class_height]="'small-fixed'"
            placeholder="Summary of the issue or need"
          ></app-editor>
          <mat-error *ngIf="comments?.errors?.required">A comment is required</mat-error>
        </div>
      </div>

      <div class="row mb-4 align-items-center">
        <div
          *ngIf="isDraft"
          class="col"
          [ngClass]="{
            'pr-0': isEditing
          }"
        >
          <button
            [disabled]="!valid_draft_submission"
            mat-flat-button
            type="button"
            color="warn"
            class="bg-orange full-width"
            (click)="save(false)"
          >
            <span>Save As Draft</span>
          </button>
        </div>
        <div
          *ngIf="!isDraft"
          class="col"
          [ngClass]="{
            'pr-0': isEditing && !isClosedWorkOrder
          }"
        >
          <a
            target="_blank"
            [routerLink]="isWorkOrderTopic ? '/work-orders/' + dispatchItem?.id : '/requests/' + dispatchItem?.id"
          >
            <button [disabled]="!dispatchItem?.type" mat-stroked-button type="button" color="accent" class="full-width">
              <mat-icon class="mr-2">open_in_new</mat-icon>
              <span>{{ viewItemText }}</span>
            </button>
          </a>
        </div>
        <div *ngIf="!isClosedWorkOrder" class="col">
          <button
            *ngIf="!isClosedWorkOrder && (!isEditing || !isDraft)"
            [disabled]="!valid_submission || !topic_type_id"
            mat-flat-button
            type="button"
            color="success"
            class="full-width"
            (click)="save(true)"
          >
            <span>{{ saveText }}</span>
          </button>
          <button
            *ngIf="isEditing && isDraft && !isClosedWorkOrder"
            [disabled]="disableConversion"
            mat-flat-button
            type="button"
            color="success"
            class="full-width"
            (click)="convert()"
          >
            <span>{{ conversionText }}</span>
          </button>
        </div>
      </div>
    </section>
  </form>
</div>

<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<ng-container *ngIf="!moduleServiceIsLoading">
  <div class="purchasing-budget" id="purchasing-budget">
    <div class="d-flex align-items-center mb-4">
      <div class="card flex-fill mr-4 br-10">
        <div class="card-body p-3 text-nowrap d-flex flex-column">
          <div class="d-flex align-items-center">
            <h6 class="primary mr-auto">UHAT Budget</h6>
            <h6 class="ltblue mb-0 bg-shade-gray px-2 lh-20 br-4">
              <span class="hover-u" (click)="handleBudgetDifferenceClicked('allCodes', 'uhat')">
                {{ uhatBudgetChange }}
              </span>
            </h6>
          </div>
          <div class="h2 mb-0">
            {{ uhatTotal }}
          </div>
        </div>
      </div>

      <div class="card flex-fill mr-4 br-10">
        <div class="card-body p-3 text-nowrap d-flex flex-column">
          <div class="d-flex align-items-center">
            <h6 class="primary mr-auto">1Call Budget</h6>
            <h6 class="ltblue mb-0 bg-shade-gray px-2 lh-20 br-4">
              <span class="hover-u" (click)="handleBudgetDifferenceClicked('allCodes', 'oneCall')">
                {{ oneCallBudgetChange }}
              </span>
            </h6>
          </div>
          <div class="h2 mb-0">
            {{ oneCallTotal }}
          </div>
        </div>
      </div>

      <div class="card flex-fill mr-4 br-10">
        <div class="card-body p-3 text-nowrap d-flex flex-column">
          <div class="d-flex align-items-center">
            <h6 class="primary mr-auto">Total Budget</h6>
            <h6 class="mb-0 bg-shade-gray px-2 lh-20 br-4">
              <span>
                {{ totalChange }}
              </span>
            </h6>
          </div>
          <div class="h2 mb-0">
            {{ total }}
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center mb-4">
      <div class="mr-3">
        <mat-form-field appearance="outline">
          <mat-select #fiscalYear [formControl]="fiscalYearControl">
            <mat-option *ngFor="let fiscalYearOption of fiscalYearOptions" [value]="fiscalYearOption">
              FY {{ fiscalYearOption.year }}
            </mat-option>
            <div
              *ngIf="authService.isCFMO || authService.isFinanceManager || authService.isAppAdmin"
              style="display: flex; align-items: center; cursor: pointer; margin: 10px"
              (click)="addFiscalYear()"
            >
              <mat-icon>add</mat-icon><span>Create Fiscal Year Budget</span>
            </div>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="authService.isCFMO || authService.isFinanceManager || authService.isAppAdmin" class="mr-3">
        <mat-form-field appearance="outline">
          <mat-select [formControl]="selectedModuleIds" placeholder="Workspace" multiple>
            <button mat-button (click)="handleSelectAll('moduleIds')" style="width: 100%">
              {{ selectedModuleIds.value.length === workspaceModules.length ? 'Select None' : 'Select All' }}
            </button>
            <mat-option *ngFor="let module of workspaceModules" [value]="module.id">
              {{ module.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-slide-toggle color="accent" labelPosition="after" [formControl]="showDeactivatedCCs">
        <span class="ltblue font-weight-semibold"> Deactivated Cost Codes </span>
      </mat-slide-toggle>

      <div class="ml-auto">
        <mat-form-field appearance="outline" style="width: 240px">
          <input matInput placeholder="Search Cost Codes" [(ngModel)]="searchTerm" />
          <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm = ''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="ml-2">
        <button
          color="primary"
          class="bg-white border border-gray"
          mat-icon-button
          type="button"
          (click)="exportARF()"
          [disabled]="isExporting"
          matTooltip="Export List to CSV"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
        >
          <mat-icon *ngIf="!isExporting" color="primary"> file_download</mat-icon>
          <span class="d-inline-flex">
            <mat-spinner *ngIf="isExporting" color="accent" [diameter]="20"></mat-spinner>
          </span>
        </button>
      </div>

      <div *ngIf="!isEditModeActivating" class="ml-2">
        <ng-container *ngIf="isEditModeEnabled; else editButton">
          <button type="button" mat-button (click)="handleCancelClicked()">Cancel</button>
          <button
            class="ml-3 lh-40"
            type="button"
            mat-flat-button
            color="success"
            (click)="handleSaveClicked()"
            [disabled]="costCodeFormArray.invalid"
          >
            Save
          </button>
        </ng-container>

        <ng-template #editButton>
          <button
            *ngIf="authService.isCFMO || authService.isFinanceManager || authService.isAppAdmin"
            type="button"
            mat-flat-button
            color="primary"
            class="lh-40"
            (click)="handleEditClicked()"
          >
            Edit
          </button>
          <!-- <button
            *ngIf="authService.isCFMO || authService.isFinanceManager || authService.isAppAdmin"
            type="button"
            mat-flat-button
            color="accent"
            class="lh-40 ml-4"
            (click)="handleFinalizeClicked()"
            [disabled]="fiscalYearControl.value.budget_finalized === 1"
          >
            {{ fiscalYearControl.value.budget_finalized === 1 ? 'Finalized' : 'Finalize' }}
          </button> -->
        </ng-template>
      </div>

      <div *ngIf="isEditModeActivating" class="ml-3">
        <ng-container *ngIf="isEditModeEnabled">
          {{ reactivateButtons() }}
        </ng-container>
        <button type="button" mat-flat-button color="primary" class="lh-40" disabled>
          Activating Edit
          <mat-icon>hourglass_bottom</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="costCodeFormArray.controls.length" class="m-portlet bg-transparent my-4">
      <div class="table-responsive">
        <table class="table bg-white">
          <thead class="bg-shade-accent">
            <tr>
              <th colspan="2" class="border-bottom border-top border-right border-gray primary text-left text-nowrap">
                Cost Code
              </th>
              <th class="border-bottom border-top border-right border-gray primary">Label</th>
              <th class="border-bottom border-top border-right border-gray primary">Description</th>
              <th class="border-bottom border-top border-right border-gray primary">Type</th>
              <th class="border-bottom border-top border-right border-gray primary">Workspaces</th>
              <th class="text-right border-bottom border-top border-right border-gray primary text-nowrap">
                UHAT Budget
              </th>
              <th class="text-right border-bottom border-top border-right border-gray primary text-nowrap">
                1Call Budget
              </th>
              <th class="text-right border-bottom border-top border-right border-gray primary">Total</th>
              <th class="text-center border-bottom border-top border-gray primary">
                <button
                  *ngIf="isEditModeEnabled; else activeHeader"
                  type="button"
                  mat-button
                  color="accent"
                  class="lh-18 px-2"
                  (click)="handleToggleAll()"
                >
                  Toggle All
                </button>
                <ng-template #activeHeader>Active</ng-template>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="
                let costCode of costCodeFormArray.controls
                  | filterCostCodeFormArrayByModuleId: selectedModuleIds.value
                  | filterCostCodeFormArrayBySearchTerm: searchTerm:workspaceModules
              "
            >
              <tr class="h5 bg-shade-accent border-top border-gray" [formGroup]="costCode | asFormGroup">
                <th
                  colspan="2"
                  class="text-left"
                  [innerHTML]="costCode.get('costCode').value || '[NULL]' | highlight: searchTerm"
                ></th>
                <th [innerHTML]="costCode.get('costCodeLabel').value || '[NULL]' | highlight: searchTerm"></th>
                <th></th>
                <th></th>
                <th></th>
                <th class="text-right">
                  {{ calculateSubCostCodesTotal(costCode, 'uhatBudget') | currency }}
                  <ng-container>
                    <div
                      class="d-flex flex-column"
                      (click)="handleBudgetDifferenceClicked('costCodes', 'uhat', costCode.get('costCode').value)"
                    >
                      <h6
                        class="d-flex align-items-center accent opacity5"
                        (click)="
                          handleBudgetDifferenceClicked('subCostCodes', 'uhat', subCostCode?.get('subCostCode').value)
                        "
                      >
                        <small class="d-flex align-items-center ml-auto hover-u pointer">
                          {{ getCostCodeActivityChange(costCode, 'uhatBudget') }}
                        </small>
                      </h6>
                    </div>
                  </ng-container>
                </th>
                <th class="text-right">
                  {{ calculateSubCostCodesTotal(costCode, 'oneCallBudget') | currency }}
                  <div
                    class="d-flex flex-column"
                    (click)="handleBudgetDifferenceClicked('costCodes', 'oneCall', costCode.get('costCode').value)"
                  >
                    <h6
                      class="d-flex align-items-center accent opacity5"
                      (click)="
                        handleBudgetDifferenceClicked('subCostCodes', 'uhat', subCostCode?.get('subCostCode').value)
                      "
                    >
                      <small class="d-flex align-items-center ml-auto hover-u pointer">
                        {{ getCostCodeActivityChange(costCode, 'oneCallBudget') }}
                      </small>
                    </h6>
                  </div>
                </th>
                <th class="text-right">
                  {{
                    calculateSubCostCodesTotal(costCode, 'uhatBudget') +
                      calculateSubCostCodesTotal(costCode, 'oneCallBudget') | currency
                  }}
                  <div class="d-flex flex-column">
                    <h6 class="d-flex align-items-center opacity5">
                      <small class="d-flex align-items-center ml-auto">
                        {{ getCostCodeActivityChange(costCode) }}
                      </small>
                    </h6>
                  </div>
                </th>
                <th class="text-center">
                  <button
                    *ngIf="isEditModeEnabled"
                    type="button"
                    class="lh-18 px-2"
                    mat-button
                    color="accent"
                    (click)="handleToggleGroup(costCode)"
                  >
                    Toggle Group
                  </button>
                </th>
              </tr>
              <ng-container *ngFor="let subCostCode of (costCode.get('subCostCodes') | asFormArray).controls">
                <tr
                  *ngIf="shouldShowSubCostCodeRow(subCostCode.value, selectedModuleIds.value)"
                  class="sub-cost-code-row"
                  [ngClass]="{
                    'bg-shade-orange': subCostCode.value.subCostCodeTypeId === 1,
                    'bg-white': subCostCode.value.subCostCodeTypeId !== 1,
                    'row-disabled': subCostCode.value.budgetIsEnabled === false
                  }"
                  [formGroup]="subCostCode | asFormGroup"
                >
                  <td class="text-center">
                    <button
                      class="p-0"
                      *ngIf="isEditModeEnabled && subCostCode.get('isNewSubCostCode').value"
                      type="button"
                      mat-button
                      (click)="handleNewSubCostCodeDelete(costCode.get('subCostCodes'), subCostCode)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                  <td>
                    <mat-form-field
                      *ngIf="isEditModeEnabled; else subCostCodeDisplay"
                      appearance="outline"
                      style="width: 90px"
                    >
                      <input matInput formControlName="subCostCode" />
                    </mat-form-field>
                    <ng-template #subCostCodeDisplay>
                      <span
                        class="text-nowrap text-right"
                        [innerHTML]="subCostCode.get('subCostCode').value || '[NULL]' | highlight: searchTerm"
                      ></span>
                    </ng-template>
                  </td>
                  <td>
                    <mat-form-field
                      *ngIf="isEditModeEnabled; else subCostCodeLabelDisplay"
                      appearance="outline"
                      class="full-width"
                    >
                      <input matInput formControlName="subCostCodeLabel" />
                    </mat-form-field>
                    <ng-template #subCostCodeLabelDisplay>
                      <span
                        [innerHTML]="subCostCode.get('subCostCodeLabel').value || '[NULL]' | highlight: searchTerm"
                      ></span>
                    </ng-template>
                  </td>
                  <td>
                    <mat-form-field
                      *ngIf="isEditModeEnabled; else subCostCodeDescriptionDisplay"
                      appearance="outline"
                      class="full-width"
                    >
                      <textarea
                        matInput
                        formControlName="subCostCodeDescription"
                        rows="1"
                        cols="24"
                        placeholder="Description"
                      ></textarea>
                    </mat-form-field>
                    <ng-template #subCostCodeDescriptionDisplay>
                      <span
                        [innerHTML]="subCostCode.get('subCostCodeDescription').value | highlight: searchTerm"
                      ></span>
                    </ng-template>
                  </td>
                  <td>
                    <mat-form-field
                      *ngIf="isEditModeEnabled; else subCostCodeTypeDisplay"
                      appearance="outline"
                      style="width: 85px"
                    >
                      <mat-select formControlName="subCostCodeTypeId" placeholder="Type">
                        <mat-option *ngFor="let type of subCostCodeTypes" [value]="type.id">
                          {{ type.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <ng-template #subCostCodeTypeDisplay>
                      {{ getSubCostCodeTypeName(subCostCode.get('subCostCodeTypeId').value) }}
                    </ng-template>
                  </td>
                  <td>
                    <mat-form-field
                      *ngIf="isEditModeEnabled; else moduleIdsDisplay"
                      appearance="outline"
                      class="full-width"
                    >
                      <mat-select formControlName="moduleIds" placeholder="Workspaces" multiple>
                        <mat-option *ngFor="let module of workspaceModules" [value]="module.id">
                          {{ module.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <ng-template #moduleIdsDisplay>
                      <ng-container *ngIf="getModuleNames(subCostCode.get('moduleIds').value) as moduleNames">
                        <ng-container *ngFor="let moduleName of moduleNames; let i = index">
                          <span class="text-nowrap" [innerHTML]="moduleName | highlight: searchTerm"></span>
                          <span *ngIf="moduleNames.length > 1 && moduleName !== moduleNames[moduleNames.length - 1]"
                            >,
                          </span>
                        </ng-container>
                      </ng-container>
                    </ng-template>
                  </td>
                  <td class="text-right pr-3">
                    <mat-form-field
                      *ngIf="isEditModeEnabled; else uhatBudgetDisplay"
                      appearance="outline"
                      style="width: 130px"
                    >
                      <input matInput currencyMask formControlName="uhatBudget" [options]="{ allowNegative: false }" />
                    </mat-form-field>
                    <ng-template #uhatBudgetDisplay>
                      <h6 class="m-0">
                        {{ subCostCode.get('uhatBudget').value | currency }}
                      </h6>
                    </ng-template>
                    <div
                      class="d-flex align-items-center accent opacity5"
                      (click)="
                        handleBudgetDifferenceClicked('subCostCodes', 'uhat', subCostCode.get('subCostCode').value)
                      "
                    >
                      <small class="d-flex align-items-center ml-auto hover-u pointer">
                        {{ getSubCostCodeActivityChange(costCode, subCostCode, 'uhatBudget') }}
                      </small>
                    </div>
                  </td>
                  <td class="text-right pr-3">
                    <mat-form-field
                      *ngIf="isEditModeEnabled; else oneCalBudgetDisplay"
                      appearance="outline"
                      style="width: 130px"
                    >
                      <input
                        matInput
                        currencyMask
                        formControlName="oneCallBudget"
                        [options]="{ allowNegative: false }"
                      />
                    </mat-form-field>

                    <ng-template #oneCalBudgetDisplay>
                      <h6 class="m-0">
                        {{ subCostCode.get('oneCallBudget').value | currency }}
                      </h6>
                    </ng-template>
                    <div
                      class="d-flex align-items-center accent opacity5"
                      (click)="
                        handleBudgetDifferenceClicked('subCostCodes', 'oneCall', subCostCode.get('subCostCode').value)
                      "
                    >
                      <small class="d-flex align-items-center ml-auto hover-u pointer">
                        {{ getSubCostCodeActivityChange(costCode, subCostCode, 'oneCallBudget') }}
                      </small>
                    </div>
                  </td>
                  <td class="text-right pr-3">
                    <h6 class="mb-0">
                      {{ subCostCode.get('uhatBudget').value + subCostCode.get('oneCallBudget').value | currency }}
                    </h6>
                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-center opacity5">
                        <small class="d-flex align-items-center ml-auto">
                          {{ getSubCostCodeActivityChange(costCode, subCostCode) }}
                        </small>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <mat-slide-toggle
                      *ngIf="isEditModeEnabled; else budgetIsEnabledDisplay"
                      formControlName="budgetIsEnabled"
                    ></mat-slide-toggle>
                    <ng-template #budgetIsEnabledDisplay>
                      <mat-slide-toggle
                        [checked]="!!subCostCode.get('budgetIsEnabled').value"
                        disabled
                      ></mat-slide-toggle>
                    </ng-template>
                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="isEditModeEnabled" class="bg-white sub-cost-code-row">
                <th colspan="10">
                  <button
                    type="button"
                    class="ml-5"
                    mat-button
                    color="success"
                    (click)="handleAddSubCostCode(costCode)"
                  >
                    + Sub Cost Code
                  </button>
                </th>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>

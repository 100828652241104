import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';
import { cloneDeep, maxBy } from 'lodash';
import * as moment from 'moment';
import {
  DatepickerHeaderComponent,
  EditorComponent,
  FileAttachmentDialogComponent,
  MilestoneDialogComponent,
  UserSelectModalComponent,
} from 'src/app/components';
import { ResourceType, TaskAccessoryType, TaskReviewStatus } from 'src/app/enums';
import {
  AppRoutingService,
  AuthService,
  DateService,
  DisplayReviewersService,
  FileService,
  LoadReviewersService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
  TaskActionsService,
  UserSearchService,
  UserService,
} from 'src/app/services';
import { Milestone, Phase, Task, TaskAccessoryData, UhatFileReference, User } from 'src/app/types';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-create-task-dialog',
  templateUrl: './create-task-dialog.component.html',
  styleUrls: ['./create-task-dialog.component.scss'],
})
export class CreateTaskDialogComponent implements OnInit {
  @ViewChild('taskDescriptionEditor', { static: true })
  private _task_description_editor_component: EditorComponent;
  @ViewChild('taskCommentEditor', { static: true })
  private _task_comment_editor_component: EditorComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public authService: AuthService,
    public dialogRef: MatDialogRef<CreateTaskDialogComponent>,
    private modalService: ModalService,
    public progressIndicatorService: ProgressIndicatorService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    private displayReviewersService: DisplayReviewersService,
    public userSearchService: UserSearchService,
    private userService: UserService,
    public fileService: FileService,
    public projectService: ProjectService,
    public taskActionsService: TaskActionsService,
    public taskService: ProjectTaskService,
    public routingService: AppRoutingService,
    private loadReviewersService: LoadReviewersService,
    private dateService: DateService
  ) {}

  public currentUser: User;

  public myUserId: number;

  public phases: Phase[] = [];

  public can_delete;

  public milestones: Milestone[] = [];

  public toFollowerSearchValue: string;

  get filteredUsers(): User[] {
    return this.userSearchService.getFilteredUsers();
  }
  customHeader = DatepickerHeaderComponent;

  // Fields --------------------------------------------------------
  public assignedUser: User;

  public dueDate: Date;

  public selectedMilestone: Milestone;

  public taskTitle: string;

  public followers: User[] = [];

  public attachedFiles: UhatFileReference[] = [];
  public reviewFiles: UhatFileReference[] = [];

  public requiresReview: boolean;

  public selectedPhase: Phase;

  public isTaskNote: boolean;

  public isMilestone: boolean;
  public isPhase: boolean;

  public accessoryData: TaskAccessoryData;
  private currentProject: ProjectConstruction;
  public isStaff = true;
  private staffRep = [];
  private tenantRep = [];
  public downloading = false;
  private phaseName: string;
  private milestoneName: string;
  public reviewerNames = {};
  public reviewTurnaround: number;
  public taskSubmitted = false;

  public linkedFiles: { id: number; name: string }[] = [];

  public showEngineers = false;
  public hasEngineers: boolean;

  get taskDescriptionEditor(): AbstractControl {
    return this._task_description_editor_component?.content;
  }

  get taskDescription(): string {
    return this.taskDescriptionEditor.value || '';
  }

  set taskDescription(value: string) {
    this.taskDescriptionEditor.setValue(value);
  }

  get taskNote(): string {
    return this._task_comment_editor_component?.content?.value || '';
  }

  set taskNote(value: string) {
    this._task_comment_editor_component?.content?.setValue(value);
  }

  // specifies whether we don't want to type of task to be changed (so choosing between action and review task)
  get isTypeLocked(): boolean {
    // default is false
    return this.data && this.data.isTypeLocked != null ? this.data.isTypeLocked : false;
  }

  // specifies whether we don't want the reviewers to be changed (i.e. locked to uhat/1call or tenants, and the specific users locked as selected)
  get isReviewersLocked(): boolean {
    // default is false
    return this.data?.isReviewersLocked;
  }

  // specifies that the passed files are already linked, or if they will be generated files
  get areFilesLinked(): boolean {
    // default is true
    return this.data?.areFilesLinked ?? true;
  }

  async ngOnInit() {
    this.currentUser = this.authService.getLoggedInUser();
    this.myUserId = this.authService.getLoggedInUser()?.id;
    this.isStaff = this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject.module_id);

    this.loadProjectPhases().then(() => {});

    await this.projectService
      .getProjectById(this.projectService.currentSelectedProjectId)
      .toPromise()
      .then((project) => {
        this.currentProject = project;
      });

    // Load injected data for modal.
    if (this.data) {
      if (this.data.followers) {
        this.followers = this.data.followers;
      }
      if (this.data.taskTitle) {
        this.taskTitle = this.data.taskTitle;
      }
      if (this.data.taskDescription) {
        this.taskDescription = this.data.taskDescription;
      }
      if (this.data.taskNote) {
        this.taskNote = this.data.taskNote;
      }
      if (this.data.accessoryData) {
        this.accessoryData = this.data.accessoryData;
        this.accessoryData.reviewFiles = [];
      }
      if (this.data.accessoryData?.isReviewItem && this.data.selectedReviewers) {
        this.accessoryData.reviewChain = [];
      }
      if (this.data.accessoryData) {
        this.requiresReview = this.accessoryData.isReviewItem;
      }
      if (this.data.attachedFiles) {
        if (this.requiresReview) {
          this.reviewFiles = this.data.attachedFiles;
        } else {
          this.attachedFiles = this.data.attachedFiles;
        }
      }
      if (this.data.staffRep && this.data.staffRep.length) {
        this.staffRep = this.data.staffRep;
      }
      if (this.data.tenantRep && this.data.tenantRep.length) {
        this.tenantRep = this.data.tenantRep;
      }
      if (this.data.linkedFiles) {
        this.linkedFiles = this.data.linkedFiles;
      }
      if (this.data.assigned_user) {
        this.data.assigned_user_id = this.data.assigned_user.id;
        this.data.assigned_user_first_name = this.data.assigned_user.firstName || this.data.assigned_user.first_name;
        this.data.assigned_user_last_name = this.data.assigned_user.lastName || this.data.assigned_user.first_name;
        this.data.assigned_user_type_id = this.data.assigned_user.user_type_id;
        // This was added to trigger the assigne-user-button component. Since it wasn't running when the assigned user info was added.
        this.data = { ...this.data };
      }
      if (this.data.reviewTurnaround) {
        this.reviewTurnaround = this.data.reviewTurnaround;
      }
      if (this.data.selectedReviewers) {
        await this.initReviewers(this.data.selectedReviewers);
      }
      if (this.data.phaseName) {
        this.phaseName = this.data.phaseName;
      }
      if (this.data.milestoneName) {
        this.milestoneName = this.data.milestoneName;
      }
      if (this.data.hasOwnProperty('can_delete')) {
        this.can_delete = this.data.can_delete;
      }

      if (this.data.assignedUser) {
        this.assignUser(this.data.assignedUser);
        this.toFollowerSearchValue = this.data.assignedUser;
      }

      if (this.data.dueDate) {
        this.dueDate = this.data.dueDate;
      }

      if (this.requiresReview && this.accessoryData.type !== TaskAccessoryType.Other) {
        this.reviewTurnaround = this.reviewTurnaround || 0;
        this.dueDate = this.dateService.addWeekdays(this.reviewTurnaround).toDate();
      }

      if (this.accessoryData?.reviewChain?.length) {
        this.accessoryData.reviewFiles = this.reviewFiles || [];
        this.accessoryData.reviewChain = await this.displayReviewersService.addUserInfoToReviewChain(
          this.accessoryData.reviewChain
        );

        // set the assigned user if it is missing or incorrect
        await this.assignToUser();
      }
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  removeFile(file: UhatFileReference) {
    this.attachedFiles = this.attachedFiles.filter((f) => f.id !== file.id);
  }

  openFileAttachmentDialog() {
    const maxFiles = 10;
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.projectService.currentSelectedProjectId,
          allowComment: false,
          // additionalParents,
          maxFiles,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          const files: UhatFileReference[] = resultData;
          for (const file of files) {
            if (this.attachedFiles.find((f) => f.id === file.id) == null) {
              this.attachedFiles.push(file);
            }
          }
        }
      });
  }

  /** Create The Task */
  async createTask() {
    this.taskSubmitted = true;
    const unlinkedFiles = [...this.attachedFiles, ...this.reviewFiles] || [];
    // Create note and link files to it
    const shouldCreateNote = unlinkedFiles.length > 0 || this.taskNote?.length > 0 || this.linkedFiles?.length > 0;
    const reviewComment = this.taskNote;

    if (shouldCreateNote && !this.taskNote) {
      this.taskNote = this.taskActionsService.getTaskNoteText(
        this.accessoryData?.type,
        unlinkedFiles?.length + this.linkedFiles?.length
      );
    }

    if (this.accessoryData) {
      if (this.accessoryData.type === TaskAccessoryType.ProposalRequest) {
        if (this.assignedUser && this.accessoryData?.isReviewItem) {
          this.accessoryData.reviewChain = [{ id: this.assignedUser.id, status: TaskReviewStatus.Pending }];
        } else {
          this.snackbar.open('Assigned User is Required...');
          return;
        }
      }

      this.accessoryData.reviewComment = reviewComment;
      if (this.areFilesLinked) {
        this.accessoryData.reviewFiles =
          this.reviewFiles.map((file) => ({ id: file.file_id || file.id, name: file.name })) || [];
      } else {
        this.accessoryData.reviewFiles = [];
      }
      this.accessoryData.reviewChain = this.displayReviewersService.removeUserInfoFromReviewChain(
        this.accessoryData.reviewChain
      );
    }

    const toCreate: Task = {
      title: this.taskTitle,
      milestone_id: this.selectedMilestone.id,
      due_date: this.dueDate ? moment(this.dueDate).format('YYYY-MM-DD') : null,
      description: this.taskDescription,
      assigned_user_id: this.assignedUser?.id || this.data?.assigned_user_id || null,
      accessory_data: (this.accessoryData && JSON.stringify(this.accessoryData)) || null,
      review_turnaround: this.reviewTurnaround,
    };
    if (this.data && this.data.hasOwnProperty('can_delete')) {
      toCreate.can_delete = +this.data.can_delete;
    }

    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Creating Task..');
    // Create file signing and upload task. Create Note and Link Files to the note.
    const createdTask = await this.taskActionsService.createTaskAndAttachFiles(
      toCreate,
      this.taskNote,
      !this.areFilesLinked ? unlinkedFiles : [],
      this.areFilesLinked ? unlinkedFiles : [],
      this.followers
    );
    this.progressIndicatorService.close();
    this.dialogRef.close(createdTask);
    if (createdTask) {
      this.snackbar
        .open(`${createdTask.title} Task Created!`, 'Go to Task', { duration: 8000 })
        .onAction()
        .subscribe(() => {
          this.routingService.gotoProjectTask(createdTask.id);
        });
    }
  }

  public stopPropagation(event) {
    event.stopPropagation();
  }

  public async initReviewers(reviewers) {
    this.accessoryData.reviewChain = [];
    // add all the followers
    for (const reviewer of reviewers) {
      const taskReviewStatus =
        this.currentUser.id === reviewer.id && reviewer.id === reviewers[0].id
          ? TaskReviewStatus.Approved
          : TaskReviewStatus.Pending;
      this.accessoryData.reviewChain.push({
        id: reviewer.id,
        status: taskReviewStatus,
        date: taskReviewStatus === TaskReviewStatus.Approved ? moment().toDate() : null,
      });
    }
  }

  private async assignToUser(userId = null) {
    if (!userId && this.accessoryData?.reviewChain?.length) {
      userId =
        this.currentUser.id === this.accessoryData?.reviewChain[0].id && this.accessoryData?.reviewChain.length > 1
          ? this.accessoryData?.reviewChain[1].id
          : this.accessoryData?.reviewChain[0].id;
    }

    if (userId && this.assignedUser?.id !== userId) {
      const assignToUser = await this.userService.getUserById(userId).toPromise();
      this.assignedUser = assignToUser;
      this.data = { ...this.data, ...{ assigned_user: this.assignedUser, assigned_user_id: this.assignedUser.id } };
    }
  }

  public addReviewClicked() {
    this.modalService
      .openRequestTaskReviewDialog({
        task: {
          id: null,
          review_turnaround: this.reviewTurnaround,
        },
        accessoryData: this.accessoryData,
        isReviewersLocked: this.isReviewersLocked,
      })
      .subscribe(async ({ task, accessoryData }) => {
        if (task) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Updating Task..');
          this.accessoryData = accessoryData;
          this.reviewFiles = this.accessoryData?.reviewFiles || [];
          this.reviewTurnaround = task.review_turnaround;
          this.dueDate = moment(task.due_date).toDate();
          await this.assignToUser(task.assigned_user_id);
          this.progressIndicatorService.close();
        }
      });
  }

  private async addFollower(userId: number) {
    const user = await this.userService.getUserById(userId).toPromise();
    this.followers.push(user);
  }

  addFollowers(): void {
    this.dialog
      .open(UserSelectModalComponent, {
        data: {
          title: 'Update Followers',
          preSelectedUsers: cloneDeep(this.followers),
          hasProject: true,
          createUser: { title: 'Guest', guestUser: false },
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(({ selectedUsers = [], deSelectedUsers = [] }: { selectedUsers: User[]; deSelectedUsers: User[] }) => {
        const deselectedUserIds: number[] = deSelectedUsers.map((d) => d.id);

        const filteredFollowers = this.followers.filter((f) => !deselectedUserIds.includes(f.id));

        this.followers = [...filteredFollowers, ...selectedUsers];
      });
  }

  public async removeFollower(userId: number) {
    this.followers = this.followers.filter((follower) => follower.id !== userId);
    if (!this.isReviewersLocked && this.accessoryData && this.accessoryData.reviewChain) {
      this.accessoryData.reviewChain = this.accessoryData.reviewChain.filter((item) => item.id !== userId);
    }
    if (this.assignedUser && this.assignedUser.id === userId) {
      if (this.accessoryData.reviewChain.length) {
        this.assignedUser = await this.userService.getUserById(this.accessoryData.reviewChain[0].id).toPromise();
      } else {
        this.assignedUser = null;
      }
    }
  }

  async loadProjectPhases() {
    await this.projectService
      .getPhasesByProjectId(this.projectService.currentSelectedProjectId)
      .toPromise()
      .then(async (phases) => {
        this.phases = phases;

        if (this.data && this.data.phaseName) {
          this.selectedPhase = this.phases.find((phase) => phase.name === this.data.phaseName);
          await this.loadPhaseMilestones(this.selectedPhase.id);
        }
      });
  }

  async loadPhaseMilestones(phaseId) {
    await this.projectService
      .getMilestonesByPhaseId(phaseId)
      .toPromise()
      .then((milestones) => {
        this.milestones = milestones;
        if (this.data && this.data.phaseName && this.data.milestoneName) {
          this.selectedMilestone = this.milestones.find((milestone) => milestone.name === this.data.milestoneName);
        }
      });
  }

  public selectPhase(phase: Phase) {
    this.isPhase = true;
    this.selectedPhase = phase;
    this.selectedMilestone = null;
    this.loadPhaseMilestones(phase.id);
  }

  public openAddMilestoneDialog() {
    const lastMilestone = maxBy(this.milestones, 'sequence');
    const dialogRef = this.dialog.open(MilestoneDialogComponent, {
      width: '380px',
      data: {
        phase_id: this.selectedPhase.id,
        sequence: (lastMilestone ? lastMilestone.sequence : 0) + 1,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.projectService
          .createMilestone(result)
          .toPromise()
          .then(async (milestone) => {
            if (milestone) {
              await this.loadPhaseMilestones(this.selectedPhase.id);
              this.selectedMilestone = this.milestones.find((mile) => mile.id === milestone.id);
              this.snackbar.open('Milestone Added Successfully');
            } else {
              console.error('No milestone added');
            }
          });
      }
    });
  }
  public searchBoxValueChange(value: string) {
    this.userSearchService.filterUsers(value);
  }

  public clearAssigneeIfSearchEmpty() {
    if (!this.toFollowerSearchValue || this.toFollowerSearchValue === '') {
      // Filter out the assigned user since it is cleared. But not if it is the current user
      if (this.assignedUser && this.assignedUser.id !== this.currentUser.id) {
        this.followers = this.followers.filter((f) => f.id !== this.assignedUser.id);
      }
      this.assignedUser = null;
    }
  }
  public assignUser({ user }) {
    if (user !== this.assignedUser && this.assignedUser && this.assignedUser.id !== this.currentUser.id) {
      this.followers = this.followers.filter((f) => f.id !== this.assignedUser.id);
    }
    this.assignedUser = user;
  }

  // TODO should we verify that attachedFiles.length > 0. It doesn't make sense to create a review task for no files?
  public formIsValid(): boolean {
    if ((this.accessoryData?.isReviewItem && !this.accessoryData?.reviewChain?.length) || this.taskSubmitted) {
      return false;
    }
    return this.selectedPhase && this.selectedMilestone && !!this.taskTitle;
  }

  // custom functions for non linked files - duplicate code from the other location (should we build this into the file chip??)
  save(file) {
    this.downloading = true;
    saveAs(file);
    this.downloading = false;
  }

  // custom functions for non linked files - this removes the file from the list
  remove(file) {
    this.attachedFiles.splice(this.attachedFiles.map((f) => f.name).indexOf(file.name), 1);
  }

  getShortenedFileName(file) {
    if (!file.name) {
      return 'Name Unknown.unk';
    }
    if (file.name.length > 35) {
      return file.name.substring(0, 35) + '..';
    } else {
      return file.name;
    }
  }

  public getDueDateText(): string {
    if (!this.dueDate) {
      return 'Set Due Date';
    } else {
      return moment(this.dueDate).format('ddd,  MMM D YYYY').toString();
    }
  }

  async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.accessoryData.reviewChain, event.previousIndex, event.currentIndex);
    const prev = event.currentIndex - 1 >= 0 ? this.accessoryData.reviewChain[event.currentIndex - 1] : null;
    const after =
      event.currentIndex + 1 < this.accessoryData.reviewChain.length
        ? this.accessoryData.reviewChain[event.currentIndex + 1]
        : null;
    this.updateHash(this.accessoryData.reviewChain[event.currentIndex], prev, after);
    // This will call the api. I'm not there yet
    // this.meetingService.moveNodeBefore(this.accessoryData.reviewChain[event.currentIndex], prev, after);
    if (this.accessoryData.reviewChain && this.accessoryData.reviewChain.length > 0) {
      if (!this.assignedUser || this.assignedUser.id !== this.accessoryData.reviewChain[0].id) {
        const assignToUser = await this.userService.getUserById(this.accessoryData.reviewChain[0].id).toPromise();
        this.assignedUser = assignToUser;
      }
    }
  }
  // locally set the new hash to correct value
  // if back end returns an error (because outdated data), we will refresh
  private updateHash(moved, prev, after) {
    const min = prev && prev.display_order_hash ? prev.display_order_hash : 0;
    const max = after && after.display_order_hash ? after.display_order_hash : 99999999;
    moved.display_order_hash = Math.round((min + max) / 2);
  }
}

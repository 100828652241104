import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { orderBy } from 'lodash';

import { PEBService, ProjectTenantService } from 'src/app/workspaces/construction/services';
import { TenantType } from 'src/app/workspaces/construction/enums';
import { PEBOption } from '../../types';

@Component({
  selector: 'app-change-order-approval-dialog',
  templateUrl: './change-order-approval-dialog.component.html',
  styleUrls: ['./change-order-approval-dialog.component.scss'],
})
export class ChangeOrderApprovalDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangeOrderApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private projectTenantService: ProjectTenantService,
    private pebService: PEBService
  ) {}

  public changeOrder = this.data.changeOrder;
  public viewOnly = this.data.viewOnly;
  public fundingSources: any[];
  private projectUsesNewPEB: boolean;

  changeOrderApprovalFormGroup: FormGroup = this.fb.group({
    funding_source: [
      this.changeOrder && this.changeOrder.funding_source_name ? this.changeOrder.funding_source_fee_id || -1 : '',
      [Validators.required],
    ],
    time_change_unit: [this.changeOrder ? this.changeOrder.time_change_unit : '', [Validators.required]],
    time_change_amount: [this.changeOrder ? this.changeOrder.time_change_amount : '', [Validators.required]],
  });
  pebItemFields = ['name', 'owner_type_id', 'peb_tenant_id', 'peb_tenant_name', 'type_id'];
  projectTenantFields = ['id', 'selected_peb_id', 'type_id', 'tenant_name', 'project_uses_new_peb'];

  get funding_source() {
    return this.changeOrderApprovalFormGroup.get('funding_source');
  }
  get time_change_unit() {
    return this.changeOrderApprovalFormGroup.get('time_change_unit');
  }
  get time_change_amount() {
    return this.changeOrderApprovalFormGroup.get('time_change_amount');
  }

  async ngOnInit() {
    const projectTenants = await this.projectTenantService
      .getTenantsForProject(this.changeOrder.project_id, this.projectTenantFields)
      .toPromise();
    const selectedPEBIds = [];
    this.projectUsesNewPEB = projectTenants?.[0]?.project_uses_new_peb ?? false;
    for (const t of projectTenants) {
      if (t.selected_peb_id) {
        selectedPEBIds.push(t.selected_peb_id);
      }
    }
    if (!this.projectUsesNewPEB) {
      let pebFees;
      if (selectedPEBIds && selectedPEBIds.length > 0) {
        pebFees = await this.pebService
          .getPEBItems(this.pebItemFields, [
            { type: 'field', field: 'peb_id', value: selectedPEBIds.join('^') },
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'type_id', value: '2^3^5' },
          ])
          .toPromise();
      }
      this.fundingSources = [
        {
          label: 'Trust Investment',
          fee_id: null,
          fee_name: null,
          tenant_id: null,
          tenant_name: 'Trust Investment',
        },
      ];
      for (const t of projectTenants) {
        if (t.type_id !== TenantType.Internal) {
          this.fundingSources.push({
            label: t.tenant_name,
            fee_id: null,
            fee_name: null,
            tenant_id: t.id,
            tenant_name: t.tenant_name,
          });
        }
      }
      for (const f of pebFees) {
        this.fundingSources.push({
          label: `${f.owner_type_id === 2 ? 'UHAT' : f.peb_tenant_name}: ${f.name}`,
          fee_id: f.id,
          fee_name: f.name,
          tenant_id: f.peb_tenant_id,
          tenant_name: f.peb_tenant_name,
        });
      }
    } else {
      const selectedPEBId = projectTenants?.[0]?.selected_peb_id;
      const pebData: PEBOption = await this.pebService.getPEBData(selectedPEBId).toPromise();
      this.fundingSources = [];
      for (const s of pebData.sections) {
        for (const fs of s.funding_sources) {
          this.fundingSources.push({
            label: `${s.tenant_name} (${fs.abbreviation}): Added Cost`,
            fee_id: null,
            fee_name: null,
            tenant_id: s.tenant_id,
            tenant_name: s.tenant_name,
          });
          const foundFeeLines = s.lines?.filter((l) => [2, 3, 5].indexOf(l.type_id) > -1);
          for (const l of foundFeeLines) {
            const foundItem = fs.items?.find((i) => i.line_id === l.id);
            this.fundingSources.push({
              label: `${s.tenant_name} (${fs.abbreviation}): ${l.name}`,
              fee_id: foundItem.id,
              fee_name: l.name,
              tenant_id: s.tenant_id,
              tenant_name: s.tenant_name,
            });
          }
        }
      }
    }
    this.fundingSources = orderBy(this.fundingSources, (f) => [f.value?.tenant_id, f.label]);
  }

  submit(): void {
    if (this.changeOrderApprovalFormGroup.valid) {
      const changeOrderToUpdate: any = {
        tenant_id: this.funding_source.value?.tenant_id ?? null,
        funding_source_name: this.funding_source.value?.fee_id
          ? this.funding_source.value?.fee_name
          : this.funding_source.value?.tenant_name,
        time_change_unit: this.time_change_unit.value,
        time_change_amount: this.time_change_amount.value,
      };
      if (!this.projectUsesNewPEB) {
        changeOrderToUpdate.funding_source_fee_old_id = this.funding_source.value?.fee_id ?? null;
      } else {
        changeOrderToUpdate.funding_source_fee_new_id = this.funding_source.value?.fee_id ?? null;
      }
      this.dialogRef.close(changeOrderToUpdate);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}

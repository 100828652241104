<app-base-dialog [title]="action + ' Department'" (closeDialog)="close()"> </app-base-dialog>
<div class="department-modal" [ngStyle]="{ visibility: loading ? 'hidden' : 'visible' }">
  <form [formGroup]="departmentFormGroup">
    <div mat-dialog-content>
      <div class="row mb-4">
        <div class="col-12">
          <h6 class="primary mb-2">Department Name*</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput required="true" type="text" placeholder="e.g. UHAT Construction" formControlName="name" />
            <mat-error *ngIf="name.errors?.required">Name is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <h6 class="primary mb-2">Company Type*</h6>
          <mat-form-field class="full-width" appearance="outline">
            <mat-select
              placeholder="Company"
              formControlName="company_type_id"
              (selectionChange)="companyTypeChanged()"
            >
              <mat-option [value]="1">Staff</mat-option>
              <mat-option [value]="2">Customer</mat-option>
              <mat-option [value]="3">Supplier</mat-option>
            </mat-select>
            <mat-error *ngIf="name.errors?.required">Company Type is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4" *ngIf="departmentFormGroup.get('company_type_id').value">
        <div class="col-12">
          <h6 class="primary mb-2">Company*</h6>
          <mat-form-field class="full-width" appearance="outline">
            <mat-select placeholder="Company" formControlName="company_id">
              <mat-option *ngFor="let company of companies" [value]="company.id">
                {{ company.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="name.errors?.required">Company is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="suiteOccupancies.length > 0" class="row mb-0">
        <div class="col-11">
          <div class="row">
            <div class="col-6">
              <h6 class="primary mb-2">Building</h6>
            </div>
            <div class="col-3">
              <h6 class="primary mb-2">Floor</h6>
            </div>
            <div class="col-3">
              <h6 class="primary mb-2">Suite</h6>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center" *ngFor="let suiteOccupancy of suiteOccupancies">
        <app-department-manager-dialog-suite
          class="col"
          [buildings]="buildings"
          [suites]="suites"
          [suiteOccupancy]="suiteOccupancy"
        ></app-department-manager-dialog-suite>
        <div class="col-1 pl-1 d-flex align-items-center">
          <mat-icon class="py-1" id="remove-mat-icon" (click)="removeSuiteOccupancy(suiteOccupancy)">delete</mat-icon>
        </div>
      </div>
      <div class="my-2">
        <button mat-button (click)="addSuite()" color="accent">+ Location</button>
      </div>
      <div class="row my-4" *ngIf="action == 'Edit'">
        <div class="col-12 text-center">
          <mat-slide-toggle class="mx-auto" color="accent" formControlName="is_enabled">
            <span class="font-weight-semibold">Department is Active</span>
          </mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-button class="full-width" type="button" color="accent" (click)="close()">Cancel</button>
      </div>
      <div class="col">
        <button
          mat-flat-button
          type="submit"
          color="success"
          class="full-width"
          (click)="save()"
          [disabled]="!departmentFormGroup.valid"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, startWith, tap } from 'rxjs/operators';
import { AuthService, UserService } from 'src/app/services';
import { APIFilter, User } from 'src/app/types';
import { UserType } from '../../enums';
import { NewAccountModalComponent } from '../new-account-modal/new-account-modal.component';

@Component({
  selector: 'app-search-user-input',
  templateUrl: './search-user-input.component.html',
  styleUrls: ['./search-user-input.component.scss'],
})
export class SearchUserInputComponent implements OnInit {
  @Input() arialLabel = 'Search user';
  @Input() assignment_option = false;
  @Input() assignToMeText = 'Assign To me';
  @Input() close_on_selection = false;
  @Input() disabled = false;
  @Input() height = 24;
  @Input() isARequestorUser = false;
  @Input() placeholder = 'Search user';
  @Input() selectedUserType: UserType = UserType.Staff;
  @Input() show_department = false;
  @Input() userTypes: UserType[] = [];
  @Input() width = 24;
  @Input() initialValueId = null;
  @Input() showAddAccount: boolean = false;
  @Output() selection = new EventEmitter();
  loading = false;

  private _seperator = '|';
  private _users: User[] = [];

  public searchEntry = new FormControl('');

  constructor(
    private authService: AuthService,
    private _userService: UserService,
    private dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit(): void {
    this._subscribe();
    if (this.disabled) {
      this.searchEntry.disable();
    }
  }

  get assigned(): User {
    return this.searchEntry?.value?.id;
  }

  get filteredUsers(): User[] {
    return this.hasPassedInUserTypes
      ? this._getFilteredUsers(this.userTypes)
      : this._getFilteredUsers(this.userTypeList);
  }

  get hasPassedInUserTypes(): boolean {
    return this.userTypes.length > 0;
  }

  get selectStaff(): boolean {
    return this.selectedUserType === UserType.Staff;
  }

  get selectTenant(): boolean {
    return this.selectedUserType === UserType.Tenant;
  }

  get selectVendor(): boolean {
    return this.selectedUserType === UserType.Vendor;
  }

  get selectedUser(): User {
    return this.searchEntry?.value?.id ? this.searchEntry?.value : null;
  }

  get staff(): UserType {
    return UserType.Staff;
  }

  get tenant(): UserType {
    return UserType.Tenant;
  }

  get userTypeList(): UserType[] {
    return this.selectedUserType ? [this.selectedUserType] : [];
  }

  get vendor(): UserType {
    return UserType.Vendor;
  }

  handleAddAccount() {
    const dialogRef = this.dialog.open(NewAccountModalComponent, {
      width: '540px',
      disableClose: true,
      data: {
        userInfo: this.searchEntry.value,
      },
    });
    dialogRef.afterClosed().subscribe(async (createdUser: User) => {
      if (createdUser) {
        this._users.push(createdUser);
      }
    });
  }

  private _getFilteredUsers(userTypes: UserType[] = []): User[] {
    return userTypes.length > 0 ? this._users.filter((user) => userTypes.includes(+user.user_type_id)) : this._users;
  }

  private _subscribe() {
    this.searchEntry.valueChanges
      .pipe(
        tap(() => (this.loading = true)),
        startWith(''),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(async (searchTerm) => {
        if (searchTerm && !searchTerm.id && searchTerm.trim()) {
          const cleanedSearchTerm = (searchTerm.split(this._seperator)?.[0] ?? '').trim();
          const userFilter: APIFilter[] = [
            { type: 'operator', value: '(' },
            { type: 'field', field: 'email', value: cleanedSearchTerm, match: 'any' },
            { type: 'operator', value: 'OR' },
            { type: 'field', field: 'full_name', value: cleanedSearchTerm, match: 'any' },
            { type: 'operator', value: ')' },
          ];
          if (this.initialValueId) {
            userFilter.push(
              { type: 'operator', value: 'AND' },
              { type: 'operator', value: '(' },
              { type: 'field', field: 'is_enabled', value: '1' },
              { type: 'operator', value: 'OR' },
              { type: 'field', field: 'id', value: this.initialValueId },
              { type: 'operator', value: ')' }
            );
          } else {
            userFilter.push({ type: 'operator', value: 'AND' }, { type: 'field', field: 'is_enabled', value: '1' });
          }
          this._users = (await this._userService.searchUsers(userFilter).toPromise()) || [];
          this.loading = false;
        } else {
          this._users = [];
        }
      });
  }

  public assignToMe() {
    this.searchEntry.setValue(this.authService.currentUser);
    this.selected(this.authService.currentUser);
  }

  public clearInput() {
    this.searchEntry.reset();
  }

  public selected(user: User) {
    if (this.close_on_selection) {
      this.selection.emit(user);
    }
  }

  public toggleUserType(selectedUserType: UserType) {
    this.selectedUserType = this.selectedUserType === selectedUserType ? null : selectedUserType;
  }

  public unAssign() {
    this.clearInput();
    if (this.close_on_selection) {
      this.selection.emit(null);
    }
  }

  public userMapper(user: User): string {
    if (user) {
      return `${user.first_name || ''} ${user.last_name || ''} ${
        this.show_department && (user.company_name || user.department_name) ? this._seperator : ''
      } ${user.user_type_id === 3 ? user.company_name || '' : user.department_name || ''}`.trim();
    } else {
      return '';
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SubCostCodeFormValue } from '../types';

@Pipe({
  name: 'filterSubCostCodeFormArrayByModuleId',
})
export class FilterSubCostCodeFormArrayByModuleIdPipe implements PipeTransform {
  transform(controls: AbstractControl[], selectedModuleIds: number[]): AbstractControl[] {
    if (!selectedModuleIds.length) return controls;

    return controls.filter((control) => {
      const subCostCodeBudgetValue = control.value as SubCostCodeFormValue;

      return (
        subCostCodeBudgetValue.isNewSubCostCode ||
        subCostCodeBudgetValue.moduleIds.some((moduleId) => selectedModuleIds.includes(moduleId))
      );
    });
  }
}

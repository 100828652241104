import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { maxBy, sortBy } from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import {
  ConfirmationDialogComponent,
  ProjectUpdateDialogComponent,
  UserProfilePreviewComponent,
} from 'src/app/components';
import {
  KeyControlTypes,
  ProjectDetailsViews,
  ResourceType,
  TaskReviewStatus,
  TaskStatus,
  UserType,
} from 'src/app/enums';
import {
  AppRoutingService,
  AuthService,
  DisplayReviewersService,
  FileService,
  KeyControlsService,
  ModalService,
  ProgressIndicatorService,
  ProjectOverviewService,
  ProjectService,
  ProjectTaskService,
} from 'src/app/services';
import {
  KeyControlsBridge,
  ProjectUpdate,
  RequestMethod,
  TaskAccessoryData,
  UhatFileReference,
  User,
} from 'src/app/types';
import { keyControlAuditCounts } from 'src/app/utils';
import { ProjectConstruction, ProjectOverviewConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
})
export class ProjectOverviewComponent implements OnInit, OnDestroy {
  constructor(
    private dialog: MatDialog,
    public displayReviewersService: DisplayReviewersService,
    private projectService: ProjectService,
    private projectOverviewService: ProjectOverviewService,
    private fileService: FileService,
    private progressIndicatorService: ProgressIndicatorService,
    public authService: AuthService,
    private appRoutingService: AppRoutingService,
    private snackbar: MatSnackBar,
    private activeRoute: ActivatedRoute,
    public modalService: ModalService,
    public keyControlsService: KeyControlsService,
    private taskService: ProjectTaskService
  ) {}

  @ViewChild('mainScreen', { static: true }) mainScreen: ElementRef;
  private manuallyEnteredKeyControlTasks = [
    KeyControlTypes.FIRE_MARSHAL_61,
    KeyControlTypes.CONTRACT_COMPLETION_61,
    KeyControlTypes.PROJECT_PROPERLY_BID_74,
    KeyControlTypes.CUSTOMER_SATISFACTION_74,
  ];
  divWidth: number;
  currentUser: User;
  projectId: number;
  project: ProjectConstruction;
  overview: ProjectOverviewConstruction;
  taskStatusFilter: string;
  taskUserFilter: string;
  latestTimeline: UhatFileReference;
  taskStatusChartData: any[];
  shownStatusTasks: any;
  taskUserChartData: any[];
  shownUserTasks: any;
  keyControlsSubscription: any;
  public TASK_REVIEW_STATUS = TaskReviewStatus;
  public TASK_STATUS = TaskStatus;

  // only select fields editable from overview
  projectFields: string[] = [
    'kc_budget',
    'kc_bids',
    'kc_vendor_contracts',
    'kc_fire_marshal',
    'kc_substantial_completion',
    'kc_invoices',
    'request_id',
    'request{request_method_id,request_method{id,name,is_enabled,is_default,icon}}',
    'is_architect_required',
    'end_date',
    'end_date_estimate',
    'parent{building_code,floor_code}',
    'capx_budget{capx_id,description}',
    'cost_codes',
  ];
  projectUpdateFields: string[] = [
    'id',
    'created_by_id',
    'created_by_first_name',
    'created_by_last_name',
    'created_by_user_type_id',
    'created_datetime',
    'project_health_type_id',
    'project_health_type_name',
    'message',
    'notify_followers',
    'access',
    'files',
  ];
  projectUpdates: ProjectUpdate[];
  projectKeyControls: KeyControlsBridge[] = [];
  budgetChartData;
  canEditKeyControls = false;
  tasksData;
  public costPerSF = 0;

  routeSubscription: Subscription;
  private refreshSubscription;

  ngOnInit() {
    this.currentUser = this.authService.getLoggedInUser();
    this.canEditKeyControls = this.authService.isProjectAdmin(
      this.projectService.currentSelectedProjectId,
      this.projectService.currentSelectedProject?.module_id
    );
    setTimeout(() => {
      this.refresh();
      this.getDivWidth();
      this.routeSubscription = this.activeRoute.params.subscribe(async (value) => {
        if (+value.id !== +this.projectService.currentSelectedProjectId) {
          this.refresh(+value.id);
        }
      });
    });

    this.refreshSubscription = this.projectService.refreshNeeded$.subscribe(async () => {
      const projectId = Number(this.activeRoute.snapshot.paramMap.get('id'));
      await this.refresh(projectId);
    });

    this.keyControlsSubscription = this.keyControlsService.keyControlCountUpdated.subscribe(async (task) => {
      const keyControlIndex = this.projectKeyControls.findIndex((keyControl) => keyControl.task?.id === task.id);
      if (keyControlIndex !== -1) {
        this.projectKeyControls[keyControlIndex].task.accessory_data = task.accessory_data;
        await this.populateKeyControlReviewers(keyControlIndex);
      }
    });
  }

  get isNotTenant(): boolean {
    return !this.authService.isTenant;
  }

  get isSupplier(): boolean {
    return this.authService.currentUser?.user_type_id === UserType.Vendor;
  }

  get isWorkspaceStaff() {
    return this.authService?.isUserWorkspaceStaff(this.project?.module_id);
  }

  get requestMethod(): RequestMethod {
    return this.project?.request?.request_method || null;
  }

  async refresh(projectId?: number) {
    if (this.progressIndicatorService) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus();
    }
    this.projectId = projectId || this.projectService.currentSelectedProjectId;
    this.project = await this.projectService.getProjectById(this.projectId, this.projectFields).toPromise();
    const schedules: UhatFileReference[] = await this.fileService
      .getFilesByParentId(ResourceType.Timeline, this.projectId)
      .toPromise();
    this.latestTimeline = maxBy(schedules, 'created_datetime');
    this.overview = await this.projectOverviewService.getProjectOverview(this.projectId).toPromise();
    this.overview.total_contract_cost = this.overview.contract_amount + this.overview.change_order_amount;

    if (this.isWorkspaceStaff) {
      this.changeTaskStatusFilter('all');
    } else {
      this.changeTaskStatusFilter('mine');
    }
    this.changeTaskUserFilter('staff');
    this.budgetChartData = [
      {
        label: 'PB',
        value: this.overview.peb_amount,
      },
      {
        label: 'A',
        value: this.overview.total_contract_cost,
      },
      {
        label: 'CO',
        value: this.overview.change_order_amount,
      },
      {
        label: 'BTD',
        value: this.overview.invoices_billed_amount,
      },
      {
        label: 'RC',
        value: this.overview.contingency_remaining_amount,
      },
    ];
    const projectUpdates = await this.projectOverviewService
      .getProjectUpdates(this.projectUpdateFields, [
        { type: 'field', field: 'project_id', value: this.projectId.toString() },
      ])
      .toPromise();

    // add in the fields for updates
    projectUpdates.forEach((projectUpdate: ProjectUpdate) => {
      projectUpdate.collapseMessageView = true;
      projectUpdate.showCollapseMessageViewButton = false;
    });

    this.projectUpdates = sortBy(projectUpdates, (u) => u.created_datetime).reverse();
    if (this.overview?.request_summary || this.overview?.original_request_files?.length) {
      this.projectUpdates.push({
        is_original_request: true,
        created_by_id: this.overview.request_requester?.id || this.overview.requester?.id,
        created_by_first_name: this.overview.request_requester?.first_name || this.overview.requester?.first_name,
        created_by_last_name: this.overview.request_requester?.last_name || this.overview.requester?.last_name,
        created_datetime:
          this.overview.request_converted_from_wo_created_datetime || this.overview.request_created_datetime,
        message: this.overview.request_summary,
        access: [2, 3],
        files: this.overview?.original_request_files || [],
      });
    }

    // get all tasks data for dialog in advance
    this.projectService
      .getTasksByProject(this.projectId, [
        'id',
        'code',
        'title',
        'due_date',
        'status_name',
        'assigned_user_id',
        'accessory_data',
        'status_id',
        'is_locked',
      ])
      .subscribe((data) => {
        this.tasksData = data;
      });

    if (this.authService.isStaffOnAnyModule) {
      await this._getProjectKeyControls();
    }

    this.getCostPerSF();
    this.progressIndicatorService.close();
  }

  getDivWidth() {
    this.divWidth = this.mainScreen.nativeElement.offsetWidth;
  }

  private async _getProjectKeyControls() {
    const keyControlBridges: KeyControlsBridge[] = await this.keyControlsService
      .getKeyControlsForProject(this.projectId)
      .toPromise();

    this.projectKeyControls = keyControlBridges.map((keyControlBridge: KeyControlsBridge) => {
      if (keyControlBridge?.task?.accessory_data) {
        const accessoryData: TaskAccessoryData = JSON.parse(keyControlBridge?.task?.accessory_data);
        // add it to the bridge
        keyControlBridge.task.key_control_accessory_data = accessoryData;

        // get count and add it to the bridge
        const AuditCounts = keyControlAuditCounts(accessoryData?.reviewChain);
        keyControlBridge.task.key_control_status_counts = AuditCounts;

        return keyControlBridge;
      }
      // do nothing and just return
      return keyControlBridge;
    });

    this.projectKeyControls = this.projectKeyControls.sort(
      (a, b) => a.key_control_template?.sequence - b.key_control_template?.sequence
    );

    // because its async, do it seperately
    for (const index in this.projectKeyControls) {
      // This if is so i can use the index without lint errors
      if (this.projectKeyControls && this.projectKeyControls[index].task?.accessory_data) {
        await this.populateKeyControlReviewers(index);

        // only ran this for completed tasks
        if (+this.projectKeyControls[index]?.task?.status_id === TaskStatus.Complete) {
          // handle historic data if there is
          let keyControlTaskHistory = [];
          // Otherwise, use the completed tasks
          let keyControlCompleted = [];
          if (
            this.manuallyEnteredKeyControlTasks.find(
              (manuallyEnteredKCTask: KeyControlTypes) =>
                manuallyEnteredKCTask === this.projectKeyControls[index].key_control_template.display_data
            )
          ) {
            keyControlCompleted = await this.taskService
              .getEventsForTaskId(this.projectKeyControls[index].task_id, 'complete')
              .toPromise();
          } else {
            // try getting historical data using history service, if nothing, then try using the task service
            keyControlTaskHistory = await this.keyControlsService
              .getKeyControlHistory(this.projectKeyControls[index].id)
              .toPromise();

            // check if there is task history
            if (!keyControlTaskHistory?.length) {
              // grab the task by task service
              keyControlCompleted = await this.taskService
                .getEventsForTaskId(this.projectKeyControls[index].task_id, 'complete')
                .toPromise();
            }
          }

          // if there is history data,
          // find the approval and get the person who created the event
          if (keyControlTaskHistory?.length && keyControlTaskHistory.filter((entry) => entry.event_id === 1).length) {
            const foundCompletedEvent = keyControlTaskHistory.find(
              (kcHistory) => +kcHistory.event_id === TaskReviewStatus.Approved
            );
            this.projectKeyControls[index].completed_datetime = foundCompletedEvent?.created_datetime;
            this.projectKeyControls[index].completed_by = {
              id: foundCompletedEvent.created_by_id,
              first_name: foundCompletedEvent?.created_by?.first_name,
              last_name: foundCompletedEvent?.created_by?.last_name,
            };
          } else if (keyControlCompleted?.length) {
            // sort it and pick the latest
            const sortedkeyControlCompleted = keyControlCompleted.sort((a, b) => {
              return moment(moment(b.completed_datetime)).diff(moment(a.completed_datetime));
            });

            this.projectKeyControls[index].completed_datetime = sortedkeyControlCompleted[0].created_datetime;
            this.projectKeyControls[index].completed_by = {
              id: sortedkeyControlCompleted[0].created_by_id,
              first_name: sortedkeyControlCompleted[0].created_by_first_name,
              last_name: sortedkeyControlCompleted[0].created_by_last_name,
            };
          }
        }
      }
    }
  }

  private async populateKeyControlReviewers(index) {
    const accessoryData: TaskAccessoryData = JSON.parse(this.projectKeyControls[index].task?.accessory_data);

    if (accessoryData?.reviewChain?.length) {
      const reviewers = await this.displayReviewersService.addUserInfoToReviewChain(accessoryData?.reviewChain);

      if (reviewers.length) {
        // Order them by date, from newest to old
        const orderedReviewers = reviewers.sort((a, b) => {
          return moment(moment(b.date)).diff(moment(a.date));
        });

        this.projectKeyControls[index].key_control_reviewers = [...orderedReviewers];
      }
    }
  }

  onResize(event) {
    this.getDivWidth();
  }

  private getCostPerSF() {
    const costPerSF = this.overview.total_contract_cost / this.overview.square_footage;

    this.costPerSF = isFinite(costPerSF) && costPerSF;
  }

  async openOverviewDialog(taskStatus: string, showUsers = false, selectedUser = null) {
    const data = {
      status: taskStatus,
      mine: this.taskStatusFilter === 'mine' && !showUsers && !selectedUser,
      tasks: this.tasksData,
      showUsers,
      taskUserFilter: this.taskUserFilter,
      users: this.overview.tasks,
      selectedUser,
    };
    await this.modalService.openTaskOverviewDialog(data).toPromise();
  }

  addProjectUpdate() {
    const dialogRef = this.dialog.open(ProjectUpdateDialogComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: {
        project_id: this.projectId,
        follower_ids: this.overview?.follower_ids,
        endDate: this?.project?.end_date,
        endDateEstimate: this.project?.end_date_estimate,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refresh();
      }
    });
  }

  editProjectUpdate(projectUpdate?: ProjectUpdate) {
    const dialogRef = this.dialog.open(ProjectUpdateDialogComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: {
        ...projectUpdate,
        endDate: this?.project?.end_date,
        endDateEstimate: this.project?.end_date_estimate,
        update: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refresh();
      }
    });
  }

  async deleteUpdate(projectUpdate?: ProjectUpdate) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        confirmationButtonText: 'Delete',
        titleBarText: 'Delete Project Update',
        descriptionText: 'Are you sure you want to delete this update? This action cannot be undone.',
      },
    });

    dialogRef.afterClosed().subscribe(async (isConfirmed) => {
      if (isConfirmed) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Deleting Update..');
        if (projectUpdate.files) {
          projectUpdate.files.forEach((file) => {
            // for each file on this deleted update, remove the Update tag (id 77)
            this.fileService.removeTags(file.file_id || file.id, [77]).subscribe();
          });
        }
        await this.projectOverviewService.deleteProjectUpdate(projectUpdate.id).toPromise();
        this.snackbar.open(`Project Update deleted!`);
        this.refresh();
      }
    });
  }

  downloadFile(file) {
    file.downloading = true;
    this.fileService.downloadFile(file).subscribe((downloadedFileResult) => {
      saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
      file.downloading = false;
    });
  }

  openUserProfilePreview(userId: number) {
    if (userId) {
      this.dialog.open(UserProfilePreviewComponent, {
        width: '400px',
        data: {
          userId,
        },
      });
    }
  }

  changeTaskStatusFilter(filter) {
    this.taskStatusFilter = filter;
    this.shownStatusTasks = this.overview.tasks[this.taskStatusFilter];
    this.taskStatusChartData = [
      { value: this.shownStatusTasks.todo, color: '#9ca6bb' },
      { value: this.shownStatusTasks.coming_due, color: '#f9aa33' },
      { value: this.shownStatusTasks.overdue, color: '#f44336' },
      { value: this.shownStatusTasks.complete, color: '#24d19e' },
    ];
  }

  changeTaskUserFilter(filter) {
    this.taskUserFilter = filter;
    const allUserTasks = this.overview.tasks[this.taskUserFilter];
    this.shownUserTasks = allUserTasks.slice(0, 4);
    this.taskUserChartData = allUserTasks.map((u) => ({ value: u.task_count }));
  }

  getUserTitle(userId, userTypeId) {
    if (userId === this.overview.project_manager.id) {
      return 'Project Manager';
    } else if (userId === this.overview.workspace_manager.id) {
      return 'Construction Manager';
    } else if (userId === this.overview.architect.id) {
      return 'Architect';
    } else if (userId === this.overview.cfmo.id) {
      return 'Chief Facilities Management Officer';
    } else if (this.overview.engineers.map((e) => e.id).indexOf(userId) > -1) {
      return 'Engineer';
    } else if (userTypeId === 1) {
      return 'Staff';
    } else if (userTypeId === 2) {
      return 'Tenant';
    } else if (userTypeId === 3) {
      return 'Vendor';
    }
  }

  async editKeyControl(name, value) {
    if (this.canEditKeyControls) {
      const projectToSend = {};
      projectToSend[name] = value ? 1 : 0;
      await this.projectService.updateProject(this.projectId, projectToSend).toPromise();
      this.refresh();
    }
  }

  ngOnDestroy() {
    try {
      this.routeSubscription.unsubscribe();
      this.keyControlsSubscription.unsubscribe();
      if (this.refreshSubscription) {
        this.refreshSubscription.unsubscribe();
      }
    } catch {}
  }

  public stopPropagation(event) {
    event.stopPropagation();
  }

  openProjectDetailsDialog() {
    this.modalService
      .openProjectDetailsDialog(this.project.id, ProjectDetailsViews.Details)
      .toPromise()
      .then((updatedProject) => {
        if (updatedProject) {
          this.project = updatedProject;
        }
      });
  }

  public formatDate(date) {
    return moment(date).format('MMMM D YYYY');
  }

  viewKeyControls(keyControlId?: number) {
    this.modalService
      .openViewKeyControlsDialog(this.project.id, keyControlId)
      .toPromise()
      .then((result) => {
        if (result) {
          console.log(result);
        }
      });
  }

  get parentProject() {
    const parent = this.project?.parent;
    const location = this.stringJoin([parent.building_code, parent.floor_code], '-');
    return this.stringJoin([parent?.code, location, parent?.title], ' | ');
  }

  public stringJoin(arr: string[], string): string {
    return arr.reduce((a, c) => (a ? (c ? a + string + c : a) : c), '');
  }

  openCAPXpanel(capx, e) {
    e.stopPropagation();
    this.projectService.capxIDEvent.emit(capx);
  }
}

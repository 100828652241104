import { AfterContentInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import {
  AgendaItemDialogComponent,
  MeetingSelectDialogComponent,
  ProjectFollowersComponent,
  UserProfilePreviewComponent,
} from 'src/app/components';
import { ProjectDetailsViews, ProjectStatus, ResourceTags, ResourceType, UserType } from 'src/app/enums';
import {
  AuthService,
  LinkedTaskService,
  MeetingService,
  ModalService,
  NotificationService,
  ProgressIndicatorService,
  ProjectService,
  SearchService,
  SidenavLinksService,
  SidenavService,
  UserService,
} from 'src/app/services';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-construction-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild('detailsComponent', { static: false }) detailsComponent;
  constructor(
    public authService: AuthService,
    public projectService: ProjectService,
    private route: ActivatedRoute,
    private navService: SidenavService,

    // private messageService: MessagingSystemService,
    private meetingService: MeetingService,
    public notificationService: NotificationService,
    private userService: UserService,
    private dialog: MatDialog,
    public searchService: SearchService,
    private sidenavLinksService: SidenavLinksService,
    private snackbar: MatSnackBar,
    private progressIndicatorService: ProgressIndicatorService,
    private modalService: ModalService,
    private linkedTaskService: LinkedTaskService
  ) {}

  ProjectStatus = ProjectStatus;
  @ViewChild('projectFollowers') projectFollowers: ProjectFollowersComponent;
  public usesNewPEB;

  projectId: number;
  statusId: number;

  selectedTaskId: number;

  shownProject: ProjectConstruction;

  subcomponent;

  phaseLength: string;

  projectLoading = true;
  refresh = false;
  private subscriptions: Subscription[] = [];

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  divWidth: number;
  showDesktop: boolean;
  showIpad: boolean;
  titleWidth: number;
  architectName;
  pmName;

  subcomponents = [
    { name: 'overview', phase_type_id: 0 },
    { name: 'tasks', phase_type_id: 0 },
    { name: 'files', phase_type_id: 0 },
    { name: 'messages', phase_type_id: 0 },
    { name: 'meetings', phase_type_id: 0 },
    { name: 'budget', phase_type_id: 0 },
    { name: 'project-directory', phase_type_id: 0 },
    { name: 'schedule', phase_type_id: 0 },
    // { name: 'details', phase_type_id: 1 },
    { name: 'programming', phase_type_id: 1 },
    { name: 'peb', phase_type_id: 2 },
    { name: 'addendums', phase_type_id: 3 },
    { name: 'drawings', phase_type_id: 3 },
    { name: 'bids', phase_type_id: 4 },
    { name: 'rfi', phase_type_id: 4 },
    { name: 'construction-budget', phase_type_id: 4 },
    { name: 'submittals', phase_type_id: 5 },
    // { name: 'budget-report', phase_type_id: 5 },
    { name: 'proposal-requests', phase_type_id: 5 },
    { name: 'change-orders', phase_type_id: 5 },
    { name: 'invoices', phase_type_id: 5 },
    { name: 'punchlist', phase_type_id: 6 },
    { name: 'as-builts', phase_type_id: 6 },
    // { name: 'budget-report', phase_type_id: 6 }
  ];

  async ngOnInit() {
    this.projectLoading = true;
    setTimeout(() => {
      this.sidenavLinksService.selectLink(this.sidenavLinksService.projectView);
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus();
    });
    this.route.params.subscribe(
      async (params: Params) => {
        this.projectId = params.id;
        this.selectedTaskId = params.task_id;
        // now grabs the correct project id based on the selected task if there is one
        // this fixes an issue where going to a different task by notification would use the old project id
        // due to selectProjectById being called before the project id was refreshed
        if (this.selectedTaskId) {
          const task = await this.projectService.getTaskById(this.selectedTaskId, ['project_id']).toPromise();
          this.projectId = task.project_id;
        } else if (params.linked_task_id) {
          this.selectedTaskId = params.linked_task_id;
          const task = await this.linkedTaskService.getLinkedWOTask(this.selectedTaskId).toPromise();
          this.projectId = task.project_id;
        }
        this.projectService.selectProjectById(this.projectId);
        // const isTask = params && params.task_id && params.subcompnent = 'tasks';
        let foundSubcomponent;
        if (params && (params.task_id || params.linked_task_id)) {
          foundSubcomponent = { name: 'tasks', phase_type_id: 0 };
        } else {
          foundSubcomponent = this.subcomponents.find(
            (s) => s.name === params.subcomponent || (params.subcomponent === 'linked-tasks' && s.name === 'tasks')
          );
        }
        this.subcomponent = foundSubcomponent || this.subcomponents[0];
        await this.getDivWidth();
      },
      () => {
        this.projectLoading = false;
      }
    );

    const refreshSubscription = this.projectService.refreshNeeded$.subscribe(() => {
      this.refresh = true;
      this.refreshProject();
    });
    this.subscriptions.push(refreshSubscription);

    const projectSelectedSubscription = this.projectService.projectSelectedEvent.subscribe((value) => {
      if (this.refresh || !this.shownProject || (value && this.shownProject.id !== value.id)) {
        this.shownProject = value;
        this.usesNewPEB = this.shownProject.uses_new_peb;
        this.statusId = this.shownProject.status_id;
        this.architectName = this.shownProject.architect_first_name + ' ' + this.shownProject.architect_last_name;
        this.pmName = this.shownProject.project_manager_first_name + ' ' + this.shownProject.project_manager_last_name;
        this.projectLoading = false;
        this.setPhaseLength();
        this.refresh = false;
      }
    });
    this.subscriptions.push(projectSelectedSubscription);
  }

  ngOnDestroy(): void {
    // attempt to close all active subscriptions
    try {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
    } catch (e) {}
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.getDivWidth();
    });
  }

  get isStaff(): boolean {
    return this.authService.isStaffOnAnyModule;
  }

  get isVendor(): boolean {
    return this.authService.currentUser?.user_type_id === UserType.Vendor;
  }
  get isWorkspaceStaff(): boolean {
    return this.authService?.isUserWorkspaceStaff(this.shownProject?.module_id);
  }
  get closedDate(): string {
    return moment(this.shownProject?.closed_datetime).format('dddd, MMMM Do, YYYY');
  }
  get closedTime(): string {
    return moment(this.shownProject?.closed_datetime).format('h:mm a');
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
    // if (this.divWidth > 940) {
    //   this.showDesktop = true;
    //   this.showIpad = false;
    // } else {
    //   this.showDesktop = false;
    //   this.showIpad = true;
    // }
    // console.log(this.divWidth)
  }

  // @HostListener('document:keydown.ctrl.n')

  public get is4DX() {
    return this.shownProject?.tags?.find((t) => t.id === ResourceTags.FourDX);
  }

  @HostListener('document:keydown.control.n', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.createTask();
  }

  onResize(event) {
    this.getDivWidth();
  }

  debug() {}

  refreshProject() {
    this.projectService.selectProjectById(this.projectId);
  }

  isSidenavClosed(): boolean {
    return this.navService.isSidenavClosed;
  }

  shouldShowNav() {
    return this.authService.isLoggedIn;
  }

  copyShareLink() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${window.location.origin}/projects/${this.projectId}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  openProjectDetailsDialog() {
    this.modalService
      .openProjectDetailsDialog(this.shownProject.id, ProjectDetailsViews.Details)
      .toPromise()
      .then((updatedProject) => {
        if (updatedProject) {
          this.shownProject = updatedProject;
        }
      });
  }

  public viewProjectConversations() {
    const projectId = parseInt(this.projectService.currentSelectedProject.id.toString(), 10);
    // this.messageService.events.updateProjectFilterEvent.emit(projectId);
  }

  // startConversation(message: string) {
  //   this.messageService.events.openCreateConversationPanelEvent.emit({
  //     subject: message,
  //     projectId: this.projectService.currentSelectedProject.id,
  //     followers: [],
  //   });
  // }

  createMeeting() {
    this.modalService.createMeeting({
      type_id: 1,
      parent_type_id: ResourceType.Project,
      parent_id: this.projectId,
    });
  }

  createMeetingAgendaFromProject(project) {
    const meetingSelectDialogRef = this.dialog.open(MeetingSelectDialogComponent, {
      data: {
        title: 'Select Meeting for New Agenda Item',
        parent_type_id: ResourceType.Project,
        parent_id: this.projectId,
      },
    });

    meetingSelectDialogRef.afterClosed().subscribe((returnedMeeting) => {
      if (returnedMeeting) {
        const agendaDialogRef = this.dialog.open(AgendaItemDialogComponent, {
          width: '480px',
          data: {
            meeting_id: returnedMeeting.id,
            meeting_title: returnedMeeting.title,
            meeting_code: returnedMeeting.code,
          },
        });

        agendaDialogRef.afterClosed().subscribe((agendaItem) => {
          if (agendaItem) {
            const agendaItemToAdd = {
              meeting_id: returnedMeeting.id,
              description: agendaItem.description,
              duration: agendaItem.duration,
              parent_type_id: ResourceType.Project,
              parent_id: project.id,
            };
            this.meetingService.addAgendaItem(agendaItemToAdd).subscribe((newAgendaItem) => {
              this.snackbar.open('Agenda item added!');
            });
          }
        });
      }
    });
  }

  openUserProfilePreview(userId) {
    if (userId) {
      this.dialog.open(UserProfilePreviewComponent, {
        width: '400px',
        data: {
          userId,
        },
      });
    }
  }

  createTask() {
    this.modalService.openCreateTaskModal().subscribe((task) => {
      this.projectService.taskCreatedEvent.emit(task);
    });
  }

  private phaseUpdated(phase) {
    if (phase) {
      this.shownProject.current_phase_name = phase.name;
      this.shownProject.current_phase_id = phase.id;
    }
    this.setPhaseLength();
  }

  private async setPhaseLength() {
    const duration = this.shownProject.current_phase_id
      ? await this.projectService.getPhaseDuration(this.shownProject.current_phase_id).toPromise()
      : null;
    const phaseStart = duration?.start_date ? moment(duration.start_date).format('M/D') : 'Not Defined';
    const phaseEnd = duration?.end_date ? moment(duration.end_date).format('M/D') : 'Not Defined';
    if (phaseStart === 'Not Defined' && phaseEnd === 'Not Defined') {
      this.phaseLength = 'Dates Not Defined';
    } else {
      this.phaseLength = `${phaseStart} - ${phaseEnd}`;
    }
    if (!this.phaseLength) {
      this.phaseLength = 'Dates Not Defined';
    }
  }

  async setProjectToActive() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Project Status',
        headerText: 'Set Project to Active',
        confirmationButtonText: 'Set to Active',
        descriptionText: 'Are you sure you want to set this project to active?',
        cancelButtonText: 'Cancel',
      })
      .subscribe(async (answer) => {
        if (answer) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Setting to active...');
          await this.projectService.updateProject(this.projectId, { status_id: 1 }).toPromise();
          this.refreshProject();
          this.progressIndicatorService.close();
        }
      });
  }

  async markAsPlanned() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Project Status',
        headerText: 'Mark As Planned',
        confirmationButtonText: 'Mark As Planned',
        descriptionText: 'Are you sure you want to mark this project as planned?',
        cancelButtonText: 'Cancel',
      })
      .subscribe(async (answer) => {
        if (answer) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Marking as planned...');
          await this.projectService.updateProject(this.projectId, { status_id: 2 }).toPromise();
          this.refreshProject();
          this.progressIndicatorService.close();
        }
      });
  }

  async placeOnHold() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Project Status',
        headerText: 'Place On Hold',
        confirmationButtonText: 'Place On Hold',
        descriptionText: 'Are you sure you want to place this project on hold?',
        cancelButtonText: 'Cancel',
      })
      .subscribe(async (answer) => {
        if (answer) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Placing on hold...');
          await this.projectService.updateProject(this.projectId, { status_id: 3 }).toPromise();
          this.refreshProject();
          this.progressIndicatorService.close();
        }
      });
  }

  async closeProject() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Checking all documents...');
    const hasUnprocessedInvoices = await this.projectService
      .checkForUnprocessedInvoices(this.shownProject.id)
      .toPromise();
    this.progressIndicatorService.close();

    if (hasUnprocessedInvoices) {
      this.modalService
        .openConfirmationDialog({
          titleBarText: 'Close Project',
          headerText: 'Warning: Pending Invoices',
          descriptionText:
            'This project still contains invoices pending review. All invoices must be processed or rejected before the project can be closed. ',
          hideConfirmationButton: true,
          cancelButtonText: 'Dismiss',
        })
        .subscribe();
    } else {
      this.modalService
        .openConfirmationDialog({
          titleBarText: 'Project Status',
          headerText: 'Close Project',
          confirmationButtonText: 'Close Project',
          descriptionText: 'Are you sure you want to close this project?',
          cancelButtonText: 'Cancel',
        })
        .subscribe(async (answer) => {
          if (answer) {
            this.progressIndicatorService.openAwaitIndicatorModal();
            this.progressIndicatorService.updateStatus('Closing project...');
            await this.projectService.updateProject(this.projectId, { status_id: 4 }).toPromise();
            this.refreshProject();
            this.progressIndicatorService.close();
          }
        });
    }
  }

  get workspaceName() {
    return this.shownProject?.module.name || '';
  }
}

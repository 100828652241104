import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ERROR_STATUS, FOUND_PAGE } from 'src/app/enums';
import { AuthService, WorkOrderService } from 'src/app/services';

const REDIRECT_APAGE = '/404';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _workOrderService: WorkOrderService
  ) {}

  private _checkError(err) {
    if (err.status === ERROR_STATUS.NOT_FOUND) {
      this._notFound();
      return false;
    }

    if (err.status === ERROR_STATUS.NOT_AUTHORIZED) {
      this._notAuthorized();
      return false;
    }

    this._unknown();
    return false;
  }

  private _notAuthorized(): void {
    void this._router.navigate([REDIRECT_APAGE], {
      queryParams: {
        status: FOUND_PAGE.NOT_AUTHORIZED,
      },
    });
  }

  private _notFound(): void {
    void this._router.navigate([REDIRECT_APAGE], {
      queryParams: {
        status: FOUND_PAGE.NOT_FOUND,
      },
    });
  }

  private _unknown(): void {
    void this._router.navigate([REDIRECT_APAGE], {
      queryParams: {
        status: FOUND_PAGE.UN_KNOWN,
      },
    });
  }

  private async _workOrderExist(workOrderId: number): Promise<boolean | void> {
    try {
      const foundWorkOrder = await this._workOrderService.getWorkOrderById(workOrderId).toPromise();
      if (foundWorkOrder?.id) {
        return true;
      } else {
        this._unknown();
        return false;
      }
    } catch (error) {
      return this._checkError(error);
    }
  }

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    if (this._authService.isLoggedIn) {
      return (await this._workOrderExist(route?.params?.id)) || false;
    }
  }
}

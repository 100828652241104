import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep, round, sortBy } from 'lodash';
import { FileAttachmentDialogComponent, NewAccountModalComponent } from 'src/app/components';
import {
  CompanyVerificationStatus,
  QuoteItemStatus as QuoteItemStatusEnum,
  ResourceType,
  UserType,
  WorkspaceType,
} from 'src/app/enums';
import {
  CompanyService,
  FileService,
  ModalService,
  ProductService,
  ProgressIndicatorService,
  UserService,
} from 'src/app/services';
import {
  APIFilter,
  Company,
  ProjectProduct,
  ProjectTenant,
  Quote,
  QuoteItem,
  QuoteItemStatus,
  UhatFileReference,
} from 'src/app/types';
import { ProjectTenantService } from 'src/app/workspaces/construction/services';

@Component({
  selector: 'app-quote-dialog',
  templateUrl: './quote-dialog.component.html',
  styleUrls: ['./quote-dialog.component.scss'],
})
export class QuoteDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<QuoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private productService: ProductService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private fileService: FileService,
    private modalService: ModalService,
    private projectTenantService: ProjectTenantService,
    private progressIndicatorService: ProgressIndicatorService,
    private userService: UserService
  ) {}

  @ViewChild('company_auto_input', { read: MatAutocompleteTrigger }) autocomplete: MatAutocompleteTrigger;

  public tenant: ProjectTenant;
  private projectProductFields: string[] = [
    `id`,
    'name',
    `quantity`,
    `product{id,name}`,
    `size`,
    `color`,
    `model_number`,
    `is_enabled`,
    `is_in_stock`,
    'selected_quote_item_id',
    'total_price',
  ];
  private quoteFields: string[] = [
    'arf_approval_task_id',
    'description',
    `company{id,name}`,
    'contact_id',
    `tax`,
    `quote_items{project_product_id,status_id,unit_price,total_price}`,
    `quote_file_id`,
    `quote_file{id,name}`,
    `solicitation_file_id`,
    `solicitation_file{id,name}`,
  ];
  private tenantFields: string[] = [
    `id`,
    `project_products{${this.projectProductFields.join(',')}}`,
    'project_id',
    'project{module_id}',
    `quotes{${this.quoteFields.join(',')}}`,
    'budget_tenant_approval_task_id',
  ];

  private userFields = ['id', 'email', 'first_name', 'last_name', 'company_id'];

  public quoteFormGroup: FormGroup = this.fb.group({
    company: [null, [Validators.required]],
    company_id: [null, [Validators.required]],
    companyContactId: [{ value: null, disabled: true }],
    description: [null],
    tax: [null],
  });

  get company() {
    return this.quoteFormGroup.get('company');
  }
  get companyId() {
    return this.quoteFormGroup.get('company_id');
  }
  get companyContactId() {
    return this.quoteFormGroup.get('companyContactId');
  }
  get description() {
    return this.quoteFormGroup.get('description');
  }
  get tax() {
    return this.quoteFormGroup.get('tax');
  }

  arfNeedsToBeUpdated = false;
  filteredCompanies: Company[] = [];
  total = 0;
  quoteCompanyIds: number[] = [];
  selectedCompanyContacts = [];
  quote: Quote;
  quoteItemStatuses: QuoteItemStatus[] = [];
  projectProducts: any[] = [];

  private projectId;
  public bidFile: UhatFileReference = null;
  public originalBidFile: UhatFileReference = null;
  public solicitationFile: UhatFileReference = null;
  public originalSolicitationFile: UhatFileReference = null;
  public miscFiles: UhatFileReference[] = [];
  public originalMiscFiles: UhatFileReference[] = [];
  public bidFileMissing = false;
  public solicitationFileMissing = false;
  public allCompanies = [];
  CompanyVerificationStatus = CompanyVerificationStatus;
  public isLoading = true;
  public companyInputName = '';

  async ngOnInit() {
    if (this.data.tenantId) {
      this.quoteItemStatuses = await this.productService.getQuoteItemStatuses(['name']).toPromise();
      this.tenant = await this.projectTenantService.getTenantById(this.data.tenantId, this.tenantFields).toPromise();

      const quotes = this.tenant?.quotes || [];
      this.quoteCompanyIds = quotes.filter((q) => q.id !== this.data?.quoteId).map((q) => q.company_id);
      this.projectProducts = this.tenant?.project_products?.filter((pp) => pp.is_enabled && !pp.is_in_stock) || [];
      for (const projectProduct of this.projectProducts) {
        projectProduct.form = new FormGroup({
          status_id: new FormControl(null, Validators.required),
          unit_price: new FormControl(null, [Validators.required]),
          total_price: new FormControl(null, [Validators.required]),
        });
        projectProduct.status_id = projectProduct.form.get('status_id');
        projectProduct.unit_price = projectProduct.form.get('unit_price');
        projectProduct.total_price = projectProduct.form.get('total_price');
      }

      this.projectId = this.tenant.project_id;

      if (this.data.quoteId) {
        this.quote = await this.productService.getQuoteById(this.data.quoteId, this.quoteFields).toPromise();

        const quote: any = {
          company: this.quote?.company,
          company_id: this.quote?.company_id,
          companyContactId: this.quote?.contact_id,
          tax: this.roundDecimal(this.quote.tax, 2),
          description: this.quote?.description,
        };

        this.filteredCompanies = this.quote.company ? [this.quote.company] : [];
        this.quoteFormGroup.setValue(quote);

        if (this.companyId.value) {
          await this.updateCompanyUsers();
          this.quoteFormGroup.controls.companyContactId.enable();
        }

        if (this.projectProducts.length > 0 && this.quote.quote_items && this.quote.quote_items.length > 0) {
          for (const projectProduct of this.projectProducts) {
            const foundQuoteItem = this.quote.quote_items.find((q) => projectProduct.id === q.project_product_id);
            if (foundQuoteItem) {
              projectProduct.quoteItemId = foundQuoteItem.id;
              projectProduct.status_id.setValue(foundQuoteItem.status_id);
              projectProduct.unit_price.setValue(this.roundDecimal(foundQuoteItem.unit_price, 3));
              projectProduct.total_price.setValue(this.roundDecimal(foundQuoteItem.total_price, 2));
            }
          }
          this.calculateTotal();
        }

        const files = await this.fileService.getFilesByParentId(ResourceType.Quote, this.data.quoteId).toPromise();

        if (this.quote.quote_file) {
          this.bidFile = this.quote.quote_file;
          this.bidFileMissing = false;
          this.originalBidFile = cloneDeep(this.bidFile);
        }
        if (this.quote.solicitation_file) {
          this.solicitationFile = this.quote.solicitation_file;
          this.solicitationFileMissing = false;
          this.originalSolicitationFile = cloneDeep(this.solicitationFile);
        }
        this.miscFiles = files;
        this.originalMiscFiles = cloneDeep(files);
      } else {
        const companiesFilter: APIFilter[] = [
          { type: 'field', field: 'type_id', value: '1^3' }, // Staff and Supplier companies
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_enabled', value: 1 },
        ];
        this.companyService
          .getCompanies(['id', 'name', 'verification_status'], companiesFilter)
          .subscribe((companies: Company[]) => {
            this.filteredCompanies = this.allCompanies = sortBy(companies, (c) => c?.name?.toLocaleLowerCase());
          });
      }
    }

    if (this.companyUsedInAnotherQuote) {
      this.description.addValidators([Validators.required]);
      this.description.updateValueAndValidity();
    }

    this.isLoading = false;
  }

  get companyUsedInAnotherQuote(): boolean {
    return this.companyId.value && this.quoteCompanyIds.includes(this.companyId.value);
  }

  openUploadModal(fileType: 'bidFile' | 'solicitationFile' | 'miscFiles') {
    const data: {
      parentResourceType: ResourceType;
      parentResourceId: number;
      allowComment: boolean;
      preSelectedTags?: { id: number }[];
      maxFiles?: number;
      verifyFileExtension?: boolean;
    } = {
      parentResourceType: ResourceType.Project,
      parentResourceId: this.projectId,
      allowComment: false,
      verifyFileExtension: true,
    };
    if (fileType === 'bidFile') {
      data.maxFiles = 1;
      data.preSelectedTags = [{ id: 11 }];
    } else if (fileType === 'solicitationFile') {
      data.maxFiles = 1;
      data.preSelectedTags = [{ id: 79 }];
    }
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data,
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (resultData) => {
        if (resultData) {
          if (fileType === 'bidFile') {
            this.bidFile = resultData[0];
            this.bidFileMissing = false;
          } else if (fileType === 'solicitationFile') {
            this.solicitationFile = resultData[0];
            this.solicitationFileMissing = false;
          } else {
            this.miscFiles = this.miscFiles.concat(resultData);
          }
        }
      });
  }

  removeFile(fileType: 'bidFile' | 'solicitationFile' | 'miscFiles', file) {
    if (fileType === 'bidFile') {
      this.bidFile = null;
      this.bidFileMissing = true;
    } else if (fileType === 'solicitationFile') {
      this.solicitationFile = null;
      // TODO: Only require solicitation above $7,500 - but we have to determine which value to base that on
      // this.solicitationFileMissing = true;
    } else {
      this.miscFiles.splice(this.miscFiles.indexOf(file), 1);
    }
  }

  getProjectProductDetails(projectProduct: ProjectProduct) {
    const details = [];
    // if (projectProduct.size) {
    //   details.push(projectProduct.size);
    // }
    // if (projectProduct.color) {
    //   details.push(projectProduct.color);
    // }
    // if (projectProduct.model_number) {
    //   details.push(projectProduct.model_number);
    // }
    return details.join(' • ');
  }

  async companySelected(company: Company) {
    this.companyId.setValue(company?.id);
    this.company.setValue(company);
    if (this.companyUsedInAnotherQuote) {
      // Make the description field mandatory here
      this.snackbar.open(`A description is mandatory for this quote`);
    }

    await this.updateCompanyUsers();
    this.quoteFormGroup.controls.companyContactId.enable();

    if (this.companyUsedInAnotherQuote) {
      this.description.addValidators([Validators.required]);
    } else {
      this.description.clearValidators();
    }

    this.description.updateValueAndValidity();
  }

  companyValueMapper(company: Company) {
    return company?.name;
  }

  public createCompany(): void {
    const companyData = {
      company: { type_id: 3, name: this.companyInputName },
      showTrades: false,
      companies: this.allCompanies,
    };
    void this.modalService
      .openCompanyDialog(companyData)
      .toPromise()
      .then((createdCompany: Company) => {
        if (createdCompany?.id) {
          this.allCompanies = sortBy([...this.allCompanies, createdCompany], (company: Company) =>
            company?.name?.toLowerCase()
          );
          void this.company.setValue(createdCompany);
          void this.companyId.setValue(createdCompany?.id);
          void this.autocomplete.closePanel();
        }
      });
  }

  public disableContactField(input) {
    this.companyInputName = input.target.value || '';
    if (!this.companyId.value && this.companyContactId?.value) {
      this.quoteFormGroup.controls.companyContactId.disable();
      this.companyContactId.setValue(null);
    }

    if (this.companyInputName) {
      this.filteredCompanies = this.allCompanies.filter(
        (c) => c.name && c.name.toLowerCase().includes(this.companyInputName.toLowerCase())
      );
    } else {
      this.filteredCompanies = this.allCompanies;
      this.companyId.setValue(null);
      this.company.setValue(null);
    }
  }
  async updateCompanyUsers() {
    // grab the relevant users for each company to allow correct filtering for the dropdown
    if (this.companyId.value) {
      this.selectedCompanyContacts = await this.userService
        .getUsersByCompany([this.companyId.value], this.userFields)
        .toPromise();
    }
  }

  // opens the dialog to create a new user and connect that user to the current selected company.
  addNewUser() {
    const dialogRef = this.dialog.open(NewAccountModalComponent, {
      width: '580px',
      disableClose: true,
      data: { company: this.company.value, userType: { id: UserType.Vendor } },
    });

    dialogRef.afterClosed().subscribe(async (createdUser) => {
      await this.updateCompanyUsers();
      this.companyContactId.setValue(createdUser.id);
    });
  }
  /** Converts a number or string into an incomplete decimal format. This is generally used for USD inputs.
   *
   * @param value The value to convert to USD
   */
  formatDecimal(value, digits) {
    // Remove characters that aren't numbers or decimals
    const clean = value ? value.toString().replace(/[^0-9.]+/g, '') : '';
    // Remove all decimals after the first one
    const decPos = clean.indexOf('.');
    const dec = decPos >= 0 ? `${clean.substring(0, decPos)}.${clean.substring(decPos + 1).replace('.', '')}` : clean;
    // remove all characters after the hundredths place
    const res = decPos >= 0 && decPos < dec.length - digits - 1 ? dec.substring(0, decPos + digits + 1) : dec;
    return res;
  }

  roundDecimal(value, digits: number) {
    return round(value, digits).toFixed(digits);
  }

  totalPriceChanged(projectProduct) {
    const selectedQuoteItem = this.quote?.quote_items?.find(
      (item) => item.id === projectProduct.selected_quote_item_id
    );
    if (
      this.tenant.budget_tenant_approval_task_id &&
      projectProduct.selected_quote_item_id &&
      selectedQuoteItem?.total_price < projectProduct.total_price.value
    ) {
      projectProduct.total_price.setValue(this.formatDecimal(selectedQuoteItem?.total_price, 2));
      this.snackbar.open('Product total cannot be increased while Tenant Approval is in progress');
    } else {
      const totalPrice = projectProduct.total_price;
      const unitPrice = projectProduct.unit_price;
      totalPrice.setValue(this.formatDecimal(totalPrice.value, 2));
      unitPrice.setValue(this.roundDecimal(+totalPrice.value / +projectProduct.quantity, 3));
      this.calculateTotal();

      this.checkArfStatus(projectProduct);
    }
  }

  totalPriceBlur(projectProduct) {
    const totalPrice = projectProduct.total_price;
    totalPrice.setValue(this.roundDecimal(totalPrice.value, 2));
  }

  unitPriceChanged(projectProduct) {
    const selectedQuoteItem = this.quote?.quote_items?.find(
      (item) => item.id === projectProduct.selected_quote_item_id
    );
    if (
      this.tenant.budget_tenant_approval_task_id &&
      projectProduct.selected_quote_item_id &&
      selectedQuoteItem?.unit_price < projectProduct.unit_price.value
    ) {
      projectProduct.unit_price.setValue(this.formatDecimal(selectedQuoteItem?.unit_price, 2));
      this.snackbar.open('Unit price cannot be increased while Tenant Approval is in progress');
    } else {
      const unitPrice = projectProduct.unit_price;
      const totalPrice = projectProduct.total_price;
      unitPrice.setValue(this.formatDecimal(unitPrice.value, 3));
      totalPrice.setValue(this.roundDecimal(+projectProduct.quantity * +unitPrice.value, 2));
      this.calculateTotal();

      this.checkArfStatus(projectProduct);
    }
  }

  unitPriceBlur(projectProduct) {
    const unitPrice = projectProduct.unit_price;
    unitPrice.setValue(this.roundDecimal(unitPrice.value, 3));
  }

  quoteItemStatusChanged(projectProduct) {
    if (
      [QuoteItemStatusEnum.DidNotBid, QuoteItemStatusEnum.NotSolicited].indexOf(
        projectProduct.form.get('status_id')?.value
      ) > -1
    ) {
      projectProduct.unit_price.setValue(0);
      this.unitPriceChanged(projectProduct);
      this.unitPriceBlur(projectProduct);
      projectProduct.unit_price.disable();
      projectProduct.total_price.disable();
    } else {
      projectProduct.unit_price.enable();
      projectProduct.total_price.enable();
    }

    this.checkArfStatus(projectProduct);
  }

  taxChanged() {
    this.tax.setValue(this.formatDecimal(this.tax.value, 2));
    this.calculateTotal();
  }

  taxBlur() {
    this.tax.setValue(this.roundDecimal(this.tax.value, 2));
  }

  calculateTotal() {
    let total = +this.tax.value;
    for (const projectProduct of this.projectProducts ?? []) {
      if (projectProduct.is_enabled && !projectProduct.is_in_stock) {
        total += +projectProduct.total_price?.value;
      }
    }
    this.total = total;
  }

  nonZeroPriceValidator(control): { [key: string]: boolean } {
    const group = control.parent;
    const status_id = group?.get('status_id');
    if ([QuoteItemStatusEnum.BidReceived].indexOf(status_id?.value) > -1 && +control?.value <= 0) {
      return { isValidTotal: true };
    }
    return null;
  }

  isValid() {
    let isValid = this.quoteFormGroup.valid;
    for (const p of this.projectProducts ?? []) {
      if (isValid && !p.form.valid) {
        isValid = false;
      }
    }
    return isValid;
  }

  private checkArfStatus(projectProduct: ProjectProduct) {
    if (!this.arfNeedsToBeUpdated && projectProduct.selected_quote_item?.quote_id === this.quote?.id) {
      this.arfNeedsToBeUpdated = true;
    }
  }

  async saveQuote() {
    let projectProductsFormsAreValid = true;
    const companyExists = !!this.companyId.value;
    let bidReceived = false;
    let didNotBid = false;

    for (const p of this.projectProducts ?? []) {
      p.form.markAllAsTouched();
      if (!p.form.valid) {
        projectProductsFormsAreValid = false;
      }
      bidReceived = p.status_id?.value === 1 || bidReceived;
      didNotBid = p.status_id?.value === 2 || didNotBid;
    }

    this.quoteFormGroup.markAllAsTouched();
    if (bidReceived && !this.bidFile?.file_id && !this.bidFile?.id) {
      this.bidFileMissing = true;
    }
    if ((bidReceived || didNotBid) && !this.solicitationFile?.file_id && !this.solicitationFile?.id) {
      // TODO: Only require solicitation above $7,500 - but we have to determine which value to base that on
      // this.solicitationFileMissing = true;
    }
    if (this.quoteFormGroup.valid && projectProductsFormsAreValid && companyExists) {
      if (
        (bidReceived && !this.bidFile?.file_id && !this.bidFile?.id) ||
        ((bidReceived || didNotBid) && this.solicitationFileMissing)
      ) {
        this.snackbar.open('Please add required files');
      } else {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Saving Bid...');
        if (this.data?.quoteId) {
          const quote: Quote = {
            description: this.description.value,
            company_id: this.companyId.value,
            contact_id: this.companyContactId.value,
            tax: this.tax.value,
            quote_file_id: this.bidFile?.file_id || this.bidFile?.id,
            solicitation_file_id: this.solicitationFile?.file_id || this.solicitationFile?.id,
          };
          const updatedQuote = await this.productService.updateQuote(this.data.quoteId, quote).toPromise();

          await this.updateQuoteFiles(this.data?.quoteId);

          updatedQuote.quote_items = [];
          for (const projectProduct of this.projectProducts) {
            const quoteItem: QuoteItem = {
              status_id: projectProduct.status_id?.value,
              unit_price: +projectProduct.unit_price?.value,
              total_price: +projectProduct.total_price?.value,
            };
            if (projectProduct.quoteItemId) {
              await this.productService.updateQuoteItem(projectProduct.quoteItemId, quoteItem).toPromise();
              quoteItem.id = projectProduct.quoteItemId;

              if (projectProduct.selected_quote_item_id === quoteItem.id && quoteItem.status_id !== 1) {
                await this.productService
                  .updateProjectProduct(projectProduct.id, { selected_quote_item_id: null })
                  .toPromise();
              }
            } else {
              quoteItem.quote_id = updatedQuote.id;
              quoteItem.project_product_id = projectProduct.id;
              quoteItem.quantity = projectProduct.quantity;
              await this.productService.createQuoteItem(quoteItem).toPromise();
            }
            updatedQuote.quote_items.push(quoteItem);
          }
          this.progressIndicatorService.close();
          this.snackbar.open('Quote Updated!');
          this.dialogRef.close(updatedQuote);
        } else {
          const quote = {
            description: this.description.value,
            tenant_id: this.tenant?.id,
            project_id: this.tenant?.project_id,
            company_id: this.companyId.value,
            contact_id: this.companyContactId.value,
            tax: this.tax.value,
            quote_file_id: this.bidFile?.file_id || this.bidFile?.id || null,
            solicitation_file_id: this.solicitationFile?.file_id || this.solicitationFile?.id || null,
          };
          const createdQuote = await this.productService.createQuote(quote).toPromise();
          await this.updateQuoteFiles(createdQuote.id);

          for (const projectProduct of this.projectProducts ?? []) {
            const quoteItem: QuoteItem = {
              quote_id: createdQuote.id,
              project_product_id: projectProduct.id,
              status_id: projectProduct.status_id?.value,
              quantity: projectProduct.quantity,
              unit_price: +projectProduct.unit_price?.value,
              total_price: +projectProduct.total_price?.value,
            };
            await this.productService.createQuoteItem(quoteItem).toPromise();
          }
          this.progressIndicatorService.close();
          this.snackbar.open('Quote Created!');
          this.dialogRef.close(createdQuote);
        }
      }
    } else {
      if (companyExists || !this.companyId.value) {
        this.snackbar.open('Please fill out all required fields');
      } else {
        this.snackbar.open('Please select a Supplier Company or create a new one');
      }
    }
  }

  async updateQuoteFiles(quoteId: number) {
    // fix the bid file
    if (this.bidFile !== this.originalBidFile) {
      if (this.originalBidFile) {
        this.fileService.removeTags(this.originalBidFile.file_id || this.originalBidFile.id, [11]).subscribe();
      }
    }

    // fix the solicitation file
    if (this.solicitationFile !== this.originalSolicitationFile) {
      if (this.originalSolicitationFile) {
        this.fileService
          .removeTags(this.originalSolicitationFile.file_id || this.originalSolicitationFile.id, [79])
          .subscribe();
      }
    }

    // fix the misc files
    const filesToAdd = [];
    const filesToRemove = [];
    this.miscFiles.forEach((file) => {
      if (!this.originalMiscFiles.map((f) => +f.file_id).includes(+file.file_id)) {
        filesToAdd.push(file);
      }
    });
    this.originalMiscFiles.forEach((file) => {
      if (!this.miscFiles.map((f) => +f.file_id).includes(+file.file_id)) {
        filesToRemove.push(file);
      }
    });

    for (const file of filesToAdd) {
      await this.fileService.linkFile(file.file_id, quoteId, ResourceType.Quote).toPromise();
    }
    for (const file of filesToRemove) {
      await this.fileService.unlinkFile(file.id).toPromise();
    }
  }

  close() {
    this.dialogRef.close(null);
  }

  isCompanyAllowed(company: Company) {
    return (
      company.verification_status === CompanyVerificationStatus.Pending ||
      company.verification_status === CompanyVerificationStatus['1CALL & UHAT'] ||
      (company.verification_status === CompanyVerificationStatus['UHAT Only'] &&
        (this.data?.workspace_type_id === WorkspaceType.Uhat ||
          this.data?.workspace_type_id === WorkspaceType.UhatAdmin))
    );
  }
}

<app-base-dialog
  [title]="
    (can_edit ? (data && data.proposalRequest && data.proposalRequest.id ? 'Edit' : 'Add') : 'View') +
    ' Proposal Request'
  "
  (closeDialog)="cancel()"
></app-base-dialog>
<div
  id="proposal-request-dialog"
  data-test-id="proposal-request-dialog"
  class="proposal-request-dialog project-modal p-0 proposal-request-form"
>
  <form [formGroup]="proposalRequestFormGroup">
    <div mat-dialog-content>
      <div class="row mb-3">
        <div class="col-12">
          <h6 class="mb-2 dkblue font-weight-semibold">Title</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput type="text" placeholder="Title" required="true" formControlName="title" />
            <mat-error *ngIf="title.errors?.required">Title is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <h6 class="mb-2 dkblue font-weight-semibold">Description</h6>
          <app-editor #editor placeholder="Description"></app-editor>
          <mat-error *ngIf="description?.errors?.required && description?.touched">Description is required</mat-error>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <h6 class="mb-2 dkblue font-weight-semibold">Due Date*</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input
              matInput
              required="true"
              placeholder="MM/DD/YYYY"
              (click)="datePicker.open()"
              [matDatepicker]="datePicker"
              autocomplete="off"
              formControlName="due_date"
            />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
            <mat-error *ngIf="due_date.errors?.required">Due date is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <h6 class="mb-2 dkblue font-weight-semibold">Reason</h6>
          <mat-form-field appearance="outline" class="full-width">
            <mat-select matNativeControl formControlName="reason_id">
              <mat-option [value]="null">-</mat-option>
              <mat-option *ngFor="let r of proposalRequestReasons" [value]="r.id">{{ r.name }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <button mat-stroked-button (click)="openUploadModal()" *ngIf="can_edit">Upload Files</button>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <mat-chip-list class="d-inline-block align-middle">
            <mat-chip-list class="d-inline-block align-middle">
              <app-file-chip
                *ngFor="let f of existingFiles"
                [file]="f"
                [removable]="can_edit"
                (removeEvent)="removeFile(f, true)"
              ></app-file-chip>
              <app-file-chip
                *ngFor="let f of files"
                [file]="f"
                [removable]="can_edit"
                (removeEvent)="removeFile(f, false)"
              ></app-file-chip>
            </mat-chip-list>
          </mat-chip-list>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="can_edit">
      <div class="col">
        <button mat-button type="button" (click)="cancel()" class="full-width gray">Cancel</button>
      </div>
      <div *ngIf="can_edit" class="col">
        <button mat-flat-button type="submit" color="success" class="full-width" (click)="submit()">Save PR</button>
      </div>
    </div>
  </form>
</div>

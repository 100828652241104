import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { includes } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiFilterService, HandleErrorService } from '.';
import { APIFilter, Module, ServiceResponse } from '../types';
@Injectable({
  providedIn: 'root',
})
export class ModuleService {
  private _fields = ['id', 'name', 'icon', 'needs_dfs_approval', 'workspace_type_id'];
  private _host: string = environment.serviceHost;
  private _workspaceUrl = `${this._host}/api/v1/workspaces`;

  public selectWorkspaceEvent = new EventEmitter<Module>();
  private _STORAGE_NAME = 'previous-workspace';
  private _workspace_id: number;
  private _workspace: Module;
  private _allWorkspaces: Module[];
  private _userWorkspaces: Module[];

  get workspace(): Module {
    return this._workspace;
  }
  get allWorkspaces(): Module[] {
    return this._allWorkspaces;
  }
  get userWorkspaces(): Module[] {
    return this._userWorkspaces;
  }
  get dfsWorkspaces(): Module[] {
    return this.allWorkspaces?.filter((wo) => wo.needs_dfs_approval);
  }

  default_module_id;

  constructor(
    private _http: HttpClient,
    private _handleErrorService: HandleErrorService,
    private _apiFilterService: ApiFilterService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {
    // if the route changes to go to a workspace, select it as the currentworkspace
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && includes(this._addSlashes(), event.url)) {
        // we only want to set the workspace for construction routes
        // this is to integrate old notifications which still have the /construction route in the links
        if (event.url === '/construction') {
          this._selectWorkspace(event.url);
        }
      }
    });
  }

  // loads all workspaces, the workspaces visible to the user, and sets the current workspace
  public async initWorkspaces(isStaffOnAnyModule: boolean, isLoggedIn: boolean, default_module_id?: number) {
    this.default_module_id = default_module_id;
    this._allWorkspaces = await this.getAllWorkspaces().toPromise();
    this._userWorkspaces = await this.getUserWorkspaces().toPromise();

    if ((!this._userWorkspaces || !this._userWorkspaces.length) && isStaffOnAnyModule && isLoggedIn) {
      const errorSnack = this.snackbar.open(
        'It looks like you have no workspaces associated with your account. Likely, this means that your account is still under review. Feel free to Contact Support (leftmost button above + New Request button) and we can resolve this issue.',
        'Close',
        { duration: undefined }
      );
      errorSnack.onAction().subscribe(async () => {
        this.snackbar.dismiss();
      });
    }

    if (isStaffOnAnyModule) {
      await this.loadWorkspace();
    } else {
      this.setWorkspace(null);
    }
  }

  // converts the workspace name to the url version
  private _addSlashes(): string[] {
    return this.allWorkspaces?.map((workspace: Module) => `/${workspace?.name?.toLowerCase()}`);
  }

  // allows selecting a workspace from routing name
  private async _selectWorkspace(workspaceName: string) {
    const workspace = this.userWorkspaces?.find((ws: Module) => `/${ws?.name?.toLowerCase()}` === workspaceName);
    this.setWorkspaceFromId(workspace.id);
  }

  private _storeToLocal(workspace: Module) {
    localStorage.setItem(this._STORAGE_NAME, JSON.stringify(workspace));
  }

  public clearWorkspace() {
    this._workspace = null;
    this.workspace_id = null;
    // remove from local storage
    localStorage.removeItem(this._STORAGE_NAME);
  }

  // NOTE: This field is only to be used when the parent object module_id is not available
  // this field represents the workspace id selected by the user, not the workspace id of the object the user is viewing
  // in most cases they will match, but it is possible for the user to bypass the parent object module_id through url manipulation
  get workspace_id() {
    return this._workspace_id;
  }

  set workspace_id(value: number) {
    this._workspace_id = value;
  }

  private _resetWorkspace() {
    // clear everything and initialize a workspace
    this.clearWorkspace();
    // only do this part if the user has workspace ids
    if (this.userWorkspaces.map((w) => +w.id)?.length > 0) {
      const defaultWorkspace = this.userWorkspaces.find((ws) => ws.id === this.default_module_id);
      this.setWorkspaceFromId(defaultWorkspace ? defaultWorkspace.id : this.userWorkspaces.map((w) => +w.id)[0]);
    }
  }

  public loadWorkspace() {
    try {
      // load the initial workspace
      const storageWorkspace: Module = JSON.parse(localStorage.getItem(this._STORAGE_NAME));
      if (this.userWorkspaces.map((w) => +w.id).includes(storageWorkspace?.id)) {
        this.setWorkspaceFromId(storageWorkspace.id);
      } else {
        this._resetWorkspace();
      }
    } catch (e) {
      // silently fail
      // reset workspace
      this._resetWorkspace();
    }
  }

  private setWorkspace(workspace: Module) {
    // if the workspace has changed
    if (!workspace || !this.workspace_id || +workspace?.id !== +this.workspace_id) {
      // set the new workspace data and emit the change
      if (workspace) {
        this.workspace_id = workspace?.id;
        this._workspace = workspace;
        this._storeToLocal(workspace);
      } else {
        this.clearWorkspace();
      }
      this.selectWorkspaceEvent.emit(workspace);
    }
  }

  // sets the current workspace based on id
  public setWorkspaceFromId(workspace_id: number) {
    const workspace: Module = this.userWorkspaces?.find((ws: Module) => ws.id === workspace_id);
    this.setWorkspace(workspace);
  }

  // allow getting workspace data
  public getWorkspaces(
    fields?: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField = 'name',
    sortOrder = 'asc'
  ): Observable<Module[]> {
    const filterString = this._apiFilterService.getFilterString(apiFilters);
    return this._http
      .get(
        `${this._workspaceUrl}?limit=${limit || 1000}&fields=${fields ? fields.join(',') : this._fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map(({ data: { modules } }: ServiceResponse) => {
          return modules;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  // loads all workspaces in the db
  public getAllWorkspaces(sortField = 'name', sortOrder = 'asc'): Observable<Module[]> {
    return this._http
      .get(
        `${this._workspaceUrl}?limit=1000&fields=${this._fields.join(',')}${sortField ? `&sort=${sortField}` : ''}${
          sortOrder ? `&order=${sortOrder}` : ''
        }`
      )
      .pipe(
        map(({ data: { modules } }: ServiceResponse) => {
          return modules;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  // loads all workspaces visible to the user
  public getUserWorkspaces(sortField = 'name', sortOrder = 'asc'): Observable<Module[]> {
    return this._http
      .get(
        `${this._workspaceUrl}/mine?limit=1000&fields=${this._fields.join(',')}${
          sortField ? `&sort=${sortField}` : ''
        }${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map(({ data: { modules } }: ServiceResponse) => {
          return modules;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public executeAfterServiceInitialization(
    callBackFn: Function,
    onTimeout: () => void = () => null,
    timeout = 10000
  ): void {
    const waitForInit = setInterval(() => {
      if (this.workspace_id) {
        clearInterval(waitForInit);
        callBackFn();
      }

      timeout -= 100;

      if (timeout <= 0) {
        clearInterval(waitForInit);
        if (onTimeout) {
          onTimeout();
        } else {
          console.error(`executeAfterServiceInitialization timed out after ${timeout} milliseconds`);
        }
      }
    }, 100);
  }
}

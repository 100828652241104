<div id="search-user-input" data-test-id="search-user-input" class="search-user-input">
  <div *ngIf="!hasPassedInUserTypes" class="d-flex align-items-center mb-3">
    <span>
      <button
        mat-button
        [class.mat-flat-button]="selectStaff"
        color="accent"
        class="mr-2 pl-3 pr-3 lh-28"
        (click)="toggleUserType(staff)"
      >
        Staff
      </button>
      <button
        mat-button
        [class.mat-flat-button]="selectTenant"
        color="accent"
        class="mr-2 pl-3 pr-3 lh-28"
        (click)="toggleUserType(tenant)"
      >
        Tenants
      </button>
      <button
        mat-button
        [class.mat-flat-button]="selectVendor"
        color="accent"
        class="mr-2 pl-3 pr-3 lh-28"
        (click)="toggleUserType(vendor)"
      >
        Suppliers
      </button>
    </span>
    <span class="ml-auto">
      <button mat-flat-button color="success" class="lh-28 pl-3 pr-3 ml-3" (click)="assignToMe()">
        {{ assignToMeText }}
      </button>
      <button
        *ngIf="!isARequestorUser"
        mat-stroked-button
        color="accent"
        class="lh-28 pl-3 pr-3 ml-3"
        (click)="unAssign()"
      >
        Unassign
      </button>
    </span>
  </div>
  <mat-form-field appearance="outline" class="full-width">
    <app-profile-thumbnail
      *ngIf="selectedUser?.id"
      matPrefix
      [noBorder]="true"
      class="d-inline-block align-middle mr-3"
      [height]="height"
      [width]="height"
      [userId]="selectedUser?.id"
    ></app-profile-thumbnail>
    <!-- [overlayIconOpacity]="1"
      [overlayIcon]="'verified_user'"
      [overlayIconColor]="'dkgray'"
      [overlayIconBackground]="'white'"
      [overlayIconTooltip]="'Authorized Requester'"-->
    <input
      type="text"
      [placeholder]="placeholder"
      [attr.aria-label]="arialLabel"
      matInput
      [matAutocomplete]="user_auto"
      [formControl]="searchEntry"
      appFocus
    />
    <mat-icon
      matRipple
      *ngIf="searchEntry?.value && !disabled"
      matSuffix
      aria-label="Clear"
      (click)="clearInput()"
      class="gray pointer"
      >cancel</mat-icon
    >
    <mat-error *ngIf="searchEntry?.errors?.required">Selection required</mat-error>
    <mat-error *ngIf="searchEntry?.errors?.userId">Select an existing user from the search results</mat-error>
    <mat-autocomplete #user_auto="matAutocomplete" autoActiveFirstOption="true" [displayWith]="userMapper.bind(this)">
      <mat-option
        class="d-flex align-items-center"
        *ngFor="let user of filteredUsers"
        [value]="user"
        (onSelectionChange)="selected(user)"
      >
        <span class="d-inline-block align-middle">
          <p class="dkblue font-weight-semibold">{{ user.first_name }} {{ user.last_name }}</p>
          <h6 class="m-0 dkgray font-weight-normal" *ngIf="show_department">
            <span *ngIf="(user?.first_name || user?.last_name) && (user?.department_name || user?.company_name)">
              {{ user?.user_type_id === 3 ? user?.company_name : user?.department_name }}
            </span>
          </h6>
        </span>
      </mat-option>
      <mat-option
        *ngIf="showAddAccount && searchEntry.value && !loading"
        class="ltblue"
        (click)="handleAddAccount()"
        [value]="searchEntry.value"
      >
        <i class="la la-plus"></i> &nbsp; Add Account
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { find, remove, uniq } from 'lodash';
import { SearchUserInputComponent } from 'src/app/components';
import { UserType } from 'src/app/enums';
import {
  AuthService,
  CompanySearchService,
  CompanyService,
  FileService,
  LocationService,
  ModalService,
  ProgressIndicatorService,
  SidenavLinksService,
  UserService,
} from 'src/app/services';
import { APIFilter, Building, Company, Department, Floor, Suite, User } from 'src/app/types';
import { TradeSelectModalComponent } from 'src/app/workspaces/construction/components';
import { Trade } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('manager', { static: true })
  private _manager_component: SearchUserInputComponent;
  public managers = [UserType.Staff];
  public managerPlaceholder = 'Manager';
  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private locationService: LocationService,
    private fileService: FileService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private tradeSelectDialog: MatDialog,
    private modalService: ModalService,
    // private messagingService: MessagingSystemService,
    public companySearchService: CompanySearchService,
    private sidenavLinksService: SidenavLinksService,
    public authService: AuthService,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  currentUserId: number;
  profileEditor: boolean = false;
  shownUser: User = {};
  profileEditable: boolean;
  userFields = [
    'user_type_id',
    'user_type_name',
    'first_name',
    'last_name',
    'company_name',
    'company_id',
    'trades',
    'title',
    'manager_id',
    'manager_first_name',
    'manager_last_name',
    'email',
    'office_phone',
    'cell_phone',
    'building_id',
    'building_name',
    'floor_id',
    'floor_name',
    'suite_id',
    'suite_name',
    'department_id',
    'department_name',
    'is_enabled',
    'is_email_enabled',
    'is_login_enabled',
    'department{company_id}',
  ];
  companyFields = ['id', 'trade_ids'];
  companies: Company[] = [];
  public userIsEditable: boolean;

  public trimFirstName(): void {
    const untrimmedFirstName = this.first_name?.value;
    const trimmedFirstName = this.first_name?.value?.trim();

    if (untrimmedFirstName?.length && untrimmedFirstName?.length !== trimmedFirstName?.length) {
      this.first_name?.setValue(trimmedFirstName);
    }
  }

  public trimLastName(): void {
    const untrimmedLastName = this.last_name?.value;
    const trimmedLastName = this.last_name?.value?.trim();

    if (untrimmedLastName?.length && untrimmedLastName?.length !== trimmedLastName?.length) {
      this.last_name?.setValue(trimmedLastName);
    }
  }

  profileFormGroup = this.fb.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    company_name: [{ value: '', disabled: true }],
    title: [''],
    email: [{ value: '', disabled: true }],
    office_phone: [''],
    cell_phone: [''],
    building_id: [''],
    department_id: [''],
    company_id: [''],
    floor_id: [''],
    suite_id: [''],
    is_enabled: [''],
    is_email_enabled: [''],
    is_login_enabled: [''],
  });

  // TODO: implement company autocomplete

  buildings: Building[] = [];
  floors: Floor[] = [];
  suites: Suite[] = [];
  departments: Department[] = [];

  private _isAR = false;
  get isAR() {
    return this._isAR;
  }

  async ngOnInit() {
    const token = this.authService.getAuthToken();
    if (this.authService.isAppAdmin || this.authService.isProfileEditor) {
      this.profileEditor = true;
    }
    setTimeout(() => {
      this.sidenavLinksService.selectLink(this.sidenavLinksService.directory);
    });

    if (token) {
      const claims = JSON.parse(
        atob(token.substr(token.indexOf('.') + 1, token.lastIndexOf('.') - token.indexOf('.') - 1))
      );
      if (claims) {
        this.currentUserId = claims.id;
      }
    }
    let userIdToShow: number;
    this.route.params.subscribe(async (params: Params) => {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Loading profile...');
      userIdToShow = params.id || this.currentUserId;
      await this.getProfile(userIdToShow);
      this.userEditable();

      await this.userService
        .getUserById(userIdToShow, ['is_ar'])
        .toPromise()
        .then((data) => (this._isAR = !!data?.is_ar));

      const companyFilters: APIFilter[] = [{ type: 'field', field: 'type_id', value: this.shownUser.user_type_id }];
      this.companies = await this.companyService.getCompanies(['id', 'name'], companyFilters).toPromise();
      this.progressIndicatorService.close();
    });

    this.profileFormGroup.addControl('manager', this._manager_component.searchEntry);
  }

  testEmail() {
    this.userService.testEmail().subscribe();
  }

  async getProfile(userId: number) {
    const user: User = await this.userService.getUserById(userId, this.userFields).toPromise();
    this.shownUser = user;
    this.populateLocationData(this.shownUser);
  }

  getProfilePictureUrl(userId: number) {
    return this.userService.getProfilePictureUrl(userId);
  }

  async changeProfilePicture(files) {
    const fileToUpload = files[0];
    // TODO: validate file type and shownUser.id
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Updating profile...');
    await this.userService.updateProfilePicture(this.shownUser.id, fileToUpload).toPromise();
    this.progressIndicatorService.close();
    window.location.reload();
  }

  async makeProfileEditable() {
    this.profileEditable = true;
    const manager = this.shownUser.manager_id
      ? await this.userService.getUserById(this.shownUser.manager_id).toPromise()
      : null;
    const userToEdit = {
      first_name: this.shownUser.first_name,
      last_name: this.shownUser.last_name,
      company_name: this.shownUser.company_name,
      title: this.shownUser.title,
      email: this.shownUser.email,
      office_phone: this.shownUser.office_phone,
      cell_phone: this.shownUser.cell_phone,
      building_id: this.shownUser.building_id,
      department_id: this.shownUser.department_id,
      company_id: this.shownUser.company_id,
      floor_id: this.shownUser.floor_id,
      manager,
      suite_id: this.shownUser.suite_id,
      is_enabled: this.shownUser.is_enabled ? 1 : 0,
      is_email_enabled: this.shownUser.is_email_enabled ? 1 : 0,
      is_login_enabled: this.shownUser.is_login_enabled ? 1 : 0,
    };

    await this.populateLocationData(userToEdit);
    this.profileFormGroup.setValue(userToEdit);
  }

  async populateLocationData(user: User) {
    [this.buildings, this.floors, this.suites, this.departments] = [
      [{ id: null, name: null }],
      [{ id: null, name: null }],
      [{ id: null, name: null }],
      [{ id: null, name: null }],
    ];
    const buildingFilters: APIFilter[] = !user.building_id
      ? [{ type: 'field', field: 'is_enabled', value: '1' }]
      : [
          { type: 'field', field: 'is_enabled', value: '1' },
          { type: 'operator', value: 'OR' },
          { type: 'field', field: 'id', value: user.building_id.toString() },
        ];
    this.locationService.getBuildings(['id', 'name'], buildingFilters).subscribe((buildings: Building[]) => {
      this.buildings = buildings;
      const foundBuilding = find(this.buildings, { id: user.building_id });
      this.building_id.setValue(foundBuilding ? foundBuilding.id : null);
      if (this.building_id.value) {
        const floorFilters: APIFilter[] = !user.floor_id
          ? [
              { type: 'field', field: 'building_id', value: user.building_id.toString() },
              { type: 'operator', value: 'AND' },
              { type: 'field', field: 'is_enabled', value: '1' },
            ]
          : [
              { type: 'field', field: 'building_id', value: user.building_id.toString() },
              { type: 'operator', value: 'AND' },
              { type: 'operator', value: '(' },
              { type: 'field', field: 'is_enabled', value: '1' },
              { type: 'operator', value: 'OR' },
              { type: 'field', field: 'id', value: user.floor_id.toString() },
              { type: 'operator', value: ')' },
            ];
        this.locationService.getFloors(['id', 'name'], floorFilters).subscribe((floors: Floor[]) => {
          this.floors = floors;
          const foundFloor = find(this.floors, { id: user.floor_id });
          this.floor_id.setValue(foundFloor ? foundFloor.id : null);
          if (this.floor_id.value) {
            const suiteFilters: APIFilter[] = !user.suite_id
              ? [
                  { type: 'field', field: 'floor_id', value: user.floor_id.toString() },
                  { type: 'operator', value: 'AND' },
                  { type: 'field', field: 'is_enabled', value: '1' },
                ]
              : [
                  { type: 'field', field: 'floor_id', value: user.floor_id.toString() },
                  { type: 'operator', value: 'AND' },
                  { type: 'operator', value: '(' },
                  { type: 'field', field: 'is_enabled', value: '1' },
                  { type: 'operator', value: 'OR' },
                  { type: 'field', field: 'id', value: user.suite_id.toString() },
                  { type: 'operator', value: ')' },
                ];
            this.locationService.getSuites(['id', 'name'], suiteFilters).subscribe((suites: Suite[]) => {
              this.suites = suites;
              const foundSuite = find(this.suites, { id: user.suite_id });
              this.suite_id.setValue(foundSuite ? foundSuite.id : null);
              const departmentFilters: APIFilter[] = !user.department_id
                ? [
                    { type: 'field', field: 'suite_id', value: user.suite_id?.toString() },
                    { type: 'operator', value: 'AND' },
                    { type: 'operator', value: '(' },
                    { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
                    { type: 'operator', value: 'AND' },
                    { type: 'field', field: 'is_enabled', value: '1' },
                    { type: 'operator', value: ')' },
                  ]
                : [
                    { type: 'field', field: 'suite_id', value: user.suite_id.toString() },
                    { type: 'operator', value: 'AND' },
                    { type: 'operator', value: '(' },
                    { type: 'operator', value: '(' },
                    { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
                    { type: 'operator', value: 'AND' },
                    { type: 'field', field: 'is_enabled', value: '1' },
                    { type: 'operator', value: ')' },
                    { type: 'operator', value: 'OR' },
                    { type: 'field', field: 'id', value: user.department_id.toString() },
                    { type: 'operator', value: ')' },
                  ];
              if (this.suite_id.value) {
                this.locationService
                  .getDepartments(['id', 'name'], departmentFilters)
                  .subscribe((departments: Department[]) => {
                    this.departments = departments;
                    const foundDepartment = find(this.departments, {
                      id: user.department_id,
                    });
                    this.department_id.setValue(foundDepartment ? foundDepartment.id : null);
                  });
              }
            });
          }
        });
      }
    });
  }
  get first_name() {
    return this.profileFormGroup.get('first_name');
  }
  get invalidFirstName() {
    return this.first_name?.errors?.required || !this.first_name?.value?.trim();
  }

  get invalidLastName() {
    return this.last_name?.errors?.required || !this.last_name?.value?.trim();
  }
  get last_name() {
    return this.profileFormGroup.get('last_name');
  }
  get company_name() {
    return this.profileFormGroup.get('company_name');
  }
  get building_id() {
    return this.profileFormGroup.get('building_id');
  }
  get floor_id() {
    return this.profileFormGroup.get('floor_id');
  }
  get suite_id() {
    return this.profileFormGroup.get('suite_id');
  }
  get department_id() {
    return this.profileFormGroup.get('department_id');
  }
  get company_id() {
    return this.profileFormGroup.get('company_id');
  }
  get is_enabled() {
    return this.profileFormGroup.get('is_enabled');
  }
  get is_email_enabled() {
    return this.profileFormGroup.get('is_email_enabled');
  }
  get is_login_enabled() {
    return this.profileFormGroup.get('is_login_enabled');
  }
  getBuildings() {
    this.locationService.getBuildings().subscribe((buildings: Building[]) => {
      this.buildings = buildings;
    });
  }

  getFloors() {
    if (this.building_id.value) {
      const floorFilters: APIFilter[] = !this.shownUser.floor_id
        ? [
            { type: 'field', field: 'building_id', value: this.building_id.value.toString() },
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'is_enabled', value: '1' },
          ]
        : [
            { type: 'field', field: 'building_id', value: this.building_id.value.toString() },
            { type: 'operator', value: 'AND' },
            { type: 'operator', value: '(' },
            { type: 'field', field: 'is_enabled', value: '1' },
            { type: 'operator', value: 'OR' },
            { type: 'field', field: 'id', value: this.shownUser.floor_id.toString() },
            { type: 'operator', value: ')' },
          ];

      this.locationService.getFloors(['id', 'name'], floorFilters).subscribe((floors: Floor[]) => {
        this.floors = floors;
        this.floor_id.setValue(null);
        this.suites = [];
        this.suite_id.setValue(null);
        this.departments = [];
        this.department_id.setValue(null);
      });
    } else {
      [this.floors, this.suites, this.departments] = [null, null, null];
      this.floor_id.setValue(null);
      this.suites = [];
      this.suite_id.setValue(null);
      this.departments = [];
      this.department_id.setValue(null);
    }
  }

  getSuites() {
    if (this.floor_id.value) {
      const suiteFilters: APIFilter[] = !this.shownUser.suite_id
        ? [
            { type: 'field', field: 'floor_id', value: this.floor_id.value.toString() },
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'is_enabled', value: '1' },
          ]
        : [
            { type: 'field', field: 'floor_id', value: this.floor_id.value.toString() },
            { type: 'operator', value: 'AND' },
            { type: 'operator', value: '(' },
            { type: 'field', field: 'is_enabled', value: '1' },
            { type: 'operator', value: 'OR' },
            { type: 'field', field: 'id', value: this.shownUser.suite_id.toString() },
            { type: 'operator', value: ')' },
          ];
      this.locationService.getSuites(['id', 'name'], suiteFilters).subscribe((suites: Suite[]) => {
        this.suites = suites;
        this.suite_id.setValue(null);
        this.departments = [];
        this.department_id.setValue(null);
      });
    } else {
      [this.suites, this.departments] = [null, null];
      this.suite_id.setValue(null);
      this.departments = [];
      this.department_id.setValue(null);
    }
  }

  getDepartments() {
    if (this.suite_id.value) {
      const departmentFilters: APIFilter[] = !this.shownUser.department_id
        ? [
            { type: 'field', field: 'suite_id', value: this.suite_id.value.toString() },
            { type: 'operator', value: 'AND' },
            { type: 'operator', value: '(' },
            { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'is_enabled', value: '1' },
            { type: 'operator', value: ')' },
          ]
        : [
            { type: 'field', field: 'suite_id', value: this.suite_id.value.toString() },
            { type: 'operator', value: 'AND' },
            { type: 'operator', value: '(' },
            { type: 'operator', value: '(' },
            { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'is_enabled', value: '1' },
            { type: 'operator', value: ')' },
            { type: 'operator', value: 'OR' },
            { type: 'field', field: 'id', value: this.shownUser.department_id.toString() },
            { type: 'operator', value: ')' },
          ];
      this.locationService.getDepartments(['id', 'name'], departmentFilters).subscribe((departments: Department[]) => {
        this.departments = departments;
        this.department_id.setValue(null);
      });
    } else {
      this.departments = null;
      this.department_id.setValue(null);
    }
  }

  get profilePictureEditable(): boolean {
    return this.profileEditable && this.profileEditor;
  }

  async updateProfile() {
    if (!this.first_name?.errors?.required && !this.last_name?.errors?.required) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Updating profile...');
      const formUser = this.profileFormGroup.getRawValue();

      const userToUpdate: User = {
        first_name: formUser.first_name,
        last_name: formUser.last_name,
        title: formUser.title,
        office_phone: formUser.office_phone,
        cell_phone: formUser.cell_phone,
        building_id: formUser.building_id,
        floor_id: formUser.floor_id,
        manager_id: formUser?.manager?.id || null,
        suite_id: formUser.suite_id,
        department_id: formUser.department_id,
        company_id: formUser.company_id,
        is_enabled: this.shownUser.is_enabled ? 1 : 0,
        is_email_enabled: formUser.is_email_enabled ? 1 : 0,
      };
      if (userToUpdate.is_enabled === 0) {
        userToUpdate.is_login_enabled = 0;
      }
      await this.userService.updateUser(this.shownUser.id, userToUpdate, this.userFields).toPromise();
      this.snackbar.open('User updated');
      this.profileEditable = false;
      this.getProfile(this.shownUser.id);
      this.progressIndicatorService.close();
    }
  }

  cancelUpdate() {
    this.profileEditable = false;
    this.getProfile(this.shownUser.id);
  }

  addTrade() {
    const tradeSelectDialog = this.tradeSelectDialog.open(TradeSelectModalComponent, {
      data: {
        title: 'Select Trades',
        allow_companies: true,
      },
    });

    tradeSelectDialog.afterClosed().subscribe(async (trades: Trade[]) => {
      await this.updateCompanyTrades(this.shownUser.company_id, trades, 'add');
      await this.getProfile(this.shownUser.id);
    });
  }

  async removeTrade(trade: Trade) {
    await this.updateCompanyTrades(this.shownUser.company_id, [trade], 'remove');
    this.getProfile(this.shownUser.id);
  }

  async updateCompanyTrades(companyId: number, trades: Trade[], action: string) {
    const tradeIds = trades.map((t) => +t.id);
    const currentCompany = await this.companyService.getCompanyById(companyId, this.companyFields).toPromise();
    const companyToUpdate = { trade_ids: JSON.parse(currentCompany.trade_ids) };
    if (action === 'add') {
      companyToUpdate.trade_ids = companyToUpdate.trade_ids.concat(tradeIds);
      companyToUpdate.trade_ids = uniq(companyToUpdate.trade_ids);
    } else if (action === 'remove') {
      remove(companyToUpdate.trade_ids, (t: number) => tradeIds.indexOf(t) > -1);
    }
    companyToUpdate.trade_ids = JSON.stringify(companyToUpdate.trade_ids);
    await this.userService.updateCompany(companyId, companyToUpdate).toPromise();
  }

  // startConversation(toUser: User) {
  //   this.userService.getUserById(toUser.id).subscribe((user) =>
  //     this.messagingService.events.openCreateConversationPanelEvent.emit({
  //       subject: '',
  //       followers: [user],
  //     })
  //   );
  // }

  public get canEditeSettings(): boolean {
    return (
      this.authService?.isAppAdmin ||
      (this.shownUser?.department && this.authService?.isCompanyEditor(this.shownUser?.department?.company_id)) ||
      (this.shownUser?.company_id && this.authService?.isCompanyEditor(this.shownUser?.company_id))
    );
  }

  public userEditable() {
    const isCurrentUser = this.shownUser.id === this.currentUserId;
    const canEditOthers =
      (this.authService.isStaffOnAnyModule && this.shownUser.user_type_id !== UserType.Staff) ||
      this.authService.isAppAdmin ||
      this.authService.isCompanyEditor(this.shownUser?.department?.company_id) ||
      this.authService.isCompanyEditor(this.shownUser?.company_id) ||
      this.authService.isUserAccountEditor;

    this.userIsEditable = isCurrentUser || canEditOthers || this.profileEditor;
  }

  inviteUser(user: User) {
    this.modalService.openInviteUserModal({ users: [user] }).subscribe();
  }

  disableAcct() {
    this.shownUser.is_enabled = false;
    this.shownUser.is_login_enabled = false;
    this.profileFormGroup.controls.is_email_enabled.setValue(false);
  }

  public impersonateUser(user: User): void {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Confirm Impersonation',
        descriptionText: `Are you sure you would like to begin impersonating ${user.first_name} ${user.last_name}?`,
      })
      .subscribe((isConfirmed: boolean): void => {
        if (isConfirmed) {
          void this.authService.impersonateUser(user.id).toPromise();
        }
      });
  }

  async resetPhoto() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Confirm Profile Photo Reset',
        descriptionText: `Are you sure you would like to reset the profile photo?`,
      })
      .subscribe(async (isConfirmed: boolean): Promise<void> => {
        if (isConfirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Resetting profile photo...');
          await this.userService.resetProfilePhoto(this.shownUser.id).toPromise();
          window.location.reload();
        }
      });
  }
}

import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar, MatDatepicker } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { DateService } from 'src/app/services';

@Component({
  selector: 'app-datepicker-header',
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.component.scss'],
})
export class DatepickerHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  picker;
  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    private datepicker: MatDatepicker<D>,
    private dateService: DateService,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar?.stateChanges?.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public get periodLabel() {
    if (this._dateAdapter?.format) {
      return this._dateAdapter
        .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
        ?.toLocaleUpperCase();
    }
  }

  public previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  public nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }

  public clearDate() {
    // this._calendar.activeDate = null;
    this.datepicker.select(null);
    this.datepicker.close();
  }

  public incrementDate(increment) {
    const startDate = moment(this.datepicker.startAt || moment(), 'DDD MMM YYYY HH:mm:ss');
    let momentEndDate;
    switch (increment) {
      case 'today':
        momentEndDate = moment();
        break;
      case '+1 day':
        momentEndDate = this.dateService.addWeekdays(1, startDate);
        break;
      case '+1 week':
        momentEndDate = this.dateService.addWeekdays(5, startDate);
        break;
      case '+2 weeks':
        momentEndDate = this.dateService.addWeekdays(10, startDate);
        break;
    }

    const endDate = this._dateAdapter.createDate(
      +momentEndDate.format('YYYY'),
      +momentEndDate.format('M') - 1,
      +momentEndDate.format('D')
    );
    this._calendar.activeDate = endDate;
    this.datepicker.select(endDate);
    this.datepicker.close();
  }
}

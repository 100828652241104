import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { intersection, isEmpty, xor } from 'lodash';
import { FileRenameDialogComponent } from 'src/app/components';
import { ResourceType } from 'src/app/enums';
import {
  AuthService,
  FileService,
  HandleErrorService,
  ModalService,
  ProgressIndicatorService,
  ProjectFilesService,
  ProjectService,
} from 'src/app/services';
import { LinkedWOTask, Milestone, Phase, Project, Tag, UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit {
  @Input() phase: Phase;

  @Input() project: Project;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ResourceType = ResourceType;

  constructor(
    private projectService: ProjectService,
    private projectFilesService: ProjectFilesService,
    private filesService: FileService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private handleErrorService: HandleErrorService,
    public authService: AuthService,
    private progressIndicatorService: ProgressIndicatorService,
    private modalService: ModalService
  ) {}

  shownFiles: UhatFileReference[] = [];
  filesIds: number[] = [];

  parentId: number;

  // whether to display all files or just the project folders
  displayProjectFiles = true;

  // allTags stores the current view on the page
  allTags: Tag[] = [];
  // originalTags stores the original data from the database about the tags
  originalTags: Tag[] = [];

  filesCount = 0;

  public isComplete = {};
  public isDownloadingAllFiles = false;

  public projectSortDirection: 'asc' | 'desc' = 'asc';

  public projectFieldToSortBy = 'created_datetime';

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  divWidth: number;
  showDesktop: boolean;
  showIpad: boolean;
  private _searchString = '';

  public get filesBeingDownloaded(): string {
    return this.selectedPrimaryTagNames.length
      ? this.selectedPrimaryTagNames.join('/')
      : this.selectedSecondaryTags.length
      ? this.selectedSecondaryTagNames.join('/')
      : 'All';
  }

  public get searchString(): string {
    return this._searchString?.trim() || '';
  }

  public set searchString(value: string) {
    this._searchString = value || '';
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject?.module_id);
  }

  // this returns all tags which at least 1 file has
  get primaryTagsWithFiles(): Tag[] {
    const tags = [];
    this.shownFiles.forEach((file: UhatFileReference) => {
      file.tags.forEach((t) => {
        if (!tags.includes(+t.id)) {
          tags.push(+t.id);
        }
      });
    });

    // returns all the primary tags with files
    return this.primaryTags.filter((t) => tags.includes(+t.id));
  }

  // all possible secondary tags (based on the selected primary) which have at least 1 file with the tag
  get possibleSecondaryTagsWithFiles(): Tag[] {
    const tags = [];
    this.shownFiles.forEach((file: UhatFileReference) => {
      file.tags.forEach((t) => {
        if (!tags.includes(+t.id)) {
          tags.push(+t.id);
        }
      });
    });

    return this.possibleSecondaryTags.filter((t) => tags.includes(+t.id));
  }

  // returns the list of folders to display when in Project Files view
  get displayFolders(): Tag[] {
    if (this.displayProjectFiles) {
      if (this.selectedPrimaryTags.length === 0 && this.selectedSecondaryTags.length === 0) {
        return this.primaryTagsWithFiles.sort((a, b) => (a.name > b.name ? 1 : -1));
      } else if (this.selectedSecondaryTags.length === 0) {
        return this.possibleSecondaryTagsWithFiles.sort((a, b) => (a.name > b.name ? 1 : -1));
      }
    }
    return [];
  }

  // this only returns the files that contain at least one bottom level tag
  get filteredFiles(): UhatFileReference[] {
    if (this.displayProjectFiles && this.selectedTags.length === 0) {
      return this.shownFiles.filter((f) => f.tags.length === 0);
    } else if (this.selectedTags.length > 0 || this.displayProjectFiles) {
      return this.shownFiles.filter(
        (f) =>
          intersection(
            f.tags.map((t) => +t.id),
            this.bottomLevelTags.map((t) => +t.id)
          ).length > 0
      );
    } else {
      return this.shownFiles;
    }
  }

  // this is the search criteria tags.
  // basically, this means the tags at the lowest level of the chain
  // for a car example, if we had [ford, dodge, dodge charger, dodge challenger], we would expect [ford, dodge charger, dodge challenger]
  // If we added ford mustang, so [ford, dodge, dodge charger, dodge challenger, ford mustang], we would expect [dodge charger, dodge challenger, ford mustang]
  get bottomLevelTags(): Tag[] {
    return this.selectedTags.filter((t) => !this.selectedTags.map((tag) => +tag.tag_parent_id).includes(+t.id));
  }

  // returns the tags that have been selected on the search panel on the right
  get selectedTags(): Tag[] {
    return this.allTags.filter((t) => t.is_selected);
  }

  // filters the selected tags into primary and secondary (i.e. whether they have a parent)
  get selectedPrimaryTags(): Tag[] {
    return this.primaryTags.filter((t) => t.is_selected);
  }
  get selectedSecondaryTags(): Tag[] {
    return this.secondaryTags.filter((t) => t.is_selected);
  }

  get selectedPrimaryTagNames(): string[] {
    return this.primaryTags.filter((t) => t.is_selected).map((t) => t.folder_name);
  }

  get selectedSecondaryTagNames(): string[] {
    return this.secondaryTags.filter((t) => t.is_selected).map((t) => t.folder_name);
  }

  get primaryTags(): Tag[] {
    return this.allTags.filter((t) => +t.tag_parent_id === 0);
  }

  get secondaryTags(): Tag[] {
    return this.allTags.filter((t) => +t.tag_parent_id !== 0);
  }

  // this returns the possible choices for secondary tags based on the primary tags chosen
  get possibleSecondaryTags(): Tag[] {
    return this.allTags.filter(
      (t) =>
        +t.tag_parent_id !== 0 &&
        this.allTags[this.allTags.indexOf(this.allTags.find((parent) => +parent.id === +t.tag_parent_id))].is_selected
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      void this.refresh();
      this.getDivWidth();
    });
  }

  onResize(): void {
    this.getDivWidth();
  }

  getDivWidth(): void {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
    if (this.divWidth > 800) {
      this.showDesktop = true;
      this.showIpad = false;
    } else {
      this.showDesktop = false;
      this.showIpad = true;
    }
  }

  editFile(file: UhatFileReference): void {
    const dialogRef = this.dialog.open(FileRenameDialogComponent, {
      width: '480px',
      data: {
        file,
        allTags: this.originalTags,
      },
    });

    dialogRef.afterClosed().subscribe((event: { tags: { id: unknown }[]; name: unknown }) => {
      if (event) {
        // if the name isn't the same, or the tag array ids have switched, then update the file
        if (
          file.name !== event.name ||
          !isEmpty(
            xor(
              file.tags.map((t) => +t.id),
              event.tags.map((t) => +t.id)
            )
          )
        ) {
          const tag_ids = `[${event.tags.map((t) => +t.id).join(',')}]`;
          const updateFile = {
            name: event.name,
            tag_ids,
          };
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Saving Changes..');
          void this.filesService
            .updateFileFields(file.file_id, updateFile)
            .toPromise()
            .then(() => this.refresh());
        }
      }
    });
  }

  clearPrimary(): void {
    this.allTags.forEach((t) => {
      if (+t.tag_parent_id === 0) {
        t.is_selected = false;
      }
    });
  }

  clearSecondary(): void {
    this.allTags.forEach((t) => {
      if (+t.tag_parent_id !== 0) {
        t.is_selected = false;
      }
    });
  }

  // toggles whether the tag is selected, if it is a primary tag it also sets all children secondary tags to not selected
  toggleTag(tag: Tag): void {
    tag.is_selected = !tag.is_selected;
    if (+tag.tag_parent_id === 0) {
      this.allTags.filter((t) => +t.tag_parent_id === +tag.id).forEach((t) => (t.is_selected = false));
    }
  }

  async refresh(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Files..');
    this.filesCount = 0;

    this.shownFiles = await this.getShownFiles();

    const allTags = await this.filesService.getTags([this.projectService.currentSelectedProject.module_id]).toPromise();
    for (const t of allTags) {
      t.is_selected = !!this.selectedTags.find((st) => st.id === t.id);
    }
    this.allTags = allTags;
    this.originalTags = this.allTags;
    this.progressIndicatorService.close();
  }

  allowedToDeleteFile(file: UhatFileReference): boolean {
    return (
      file.tags.filter((t) => +t.blocks_delete).length === 0 &&
      this.authService.allowedToDeleteFiles(this.project.id, file.created_by_id, this.project?.module_id)
    );
  }

  async downloadFile(file: UhatFileReference): Promise<void> {
    file.isDownloading = true;
    await this.filesService.download(file);
    file.isDownloading = false;
  }

  public async previewFile(file: UhatFileReference): Promise<void> {
    await this.filesService.previewFile(file);
  }

  async deleteFile(file: UhatFileReference): Promise<void> {
    const res = await this.filesService.deleteFile(file);
    if (res) {
      await this.refresh();
    }
  }

  public updateSortByField(field: string): void {
    if (field === this.projectFieldToSortBy) {
      this.projectSortDirection = this.projectSortDirection === 'desc' ? 'asc' : 'desc';
    }
    this.projectFieldToSortBy = field;
  }

  openFileSelectDialog(): void {
    // since we don't 'allowComment', this just links the files to the parent and the additionalParents
    this.modalService
      .openFileAttachmentDialog({
        parentResourceType: ResourceType.Project,
        parentResourceId: this.project.id,
        allowComment: false,
        allowSearchFromProject: false,
        preSelectedTags: [],
      })
      .subscribe((resultData) => {
        if (resultData) {
          void this.refresh();
        }
      });
  }

  // when the Project Files button is clicked
  changeFileDisplay(toProjectFiles: boolean): void {
    this.displayProjectFiles = toProjectFiles;

    this.clearPrimary();
    this.clearSecondary();
  }

  private async getShownFiles(): Promise<UhatFileReference[]> {
    let files: UhatFileReference[] = [...(await this.getProjectFiles()), ...(await this.getWorkOrderFiles())];

    files = files.filter((f) => f.name && (f.id || f.file_id));
    this.filesCount = files.length;
    files.forEach((f) => {
      this.isComplete[f.id] = true;
      f.extension = f.name.split('.')[1];
      if (!f.tags) {
        f.tags = [];
      }
    });

    return files;
  }

  private async getProjectFiles(): Promise<UhatFileReference[]> {
    return (
      (await this.projectFilesService
        .loadFilesForResourceType(
          ResourceType.Project,
          this.project?.id || this.projectService.currentSelectedProjectId
        )
        .toPromise()) || []
    );
  }

  private async getWorkOrderFiles(): Promise<UhatFileReference[]> {
    let files: UhatFileReference[] = [];

    const milesStones: Milestone[] =
      (await this.projectService
        .getMilestonesByProject(this.project?.id || this.projectService.currentSelectedProjectId, ['linked_wo_tasks'])
        .toPromise()) || [];

    const linkedWOTasks: LinkedWOTask[] = milesStones.map((milestone) => milestone.linked_wo_tasks).flat();

    for (const workOrderTask of linkedWOTasks) {
      const workOrderFiles: UhatFileReference[] =
        (await this.projectFilesService
          .loadFilesForResourceType(ResourceType.WorkOrder, workOrderTask.work_order_id)
          .toPromise()) || [];

      files = [...files, ...workOrderFiles];
    }
    return files;
  }

  public async downloadAllFiles(): Promise<void> {
    if (!this.displayProjectFiles || this.displayFolders?.length) {
      // viewing all the files, download them all
      this.filesIds = this.shownFiles?.map?.((file: UhatFileReference) => file.file_id);
    } else {
      // view a folder, so just download the folder files
      this.filesIds = this.filteredFiles?.map?.((file: UhatFileReference) => file.file_id);
    }

    this.isDownloadingAllFiles = true;
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Creating a Zip File. This may take a few seconds.');

    // get all the work order files
    await this.filesService
      .downloadAllFilesByParentId(
        this.project?.id || this.projectService.currentSelectedProjectId,
        ResourceType.Project,
        this.project.code || this.projectService.currentSelectedProject.code,
        this.filesIds?.join('^')
      )
      .toPromise();

    this.isDownloadingAllFiles = false;
    this.progressIndicatorService.close();
  }
}

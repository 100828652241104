import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SortDirection } from '@angular/material/sort';
import * as moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  KeyControlDisplayData,
  ORDER_FIELD,
  ProjectListMenu,
  ProjectStatus as Status,
  ResourceType,
  UserType,
  Workspace,
} from 'src/app/enums';
import { ProjectListFilter } from 'src/app/models';
import {
  AuthService,
  ExportService,
  KeyControlsService,
  ModalService,
  ModuleService,
  ProgressIndicatorService,
  ProjectOverviewService,
  ProjectService,
  ResourceTagsService,
  SidenavLinksService,
} from 'src/app/services';
import {
  APIFilter,
  KeyControlsBridge,
  Project,
  ProjectFilterOptions,
  ProjectListPreferences,
  ProjectStatus,
  ProjectUpdate,
  ResourceTag,
  SelectOption,
  TaskAccessoryData,
} from 'src/app/types';
import { keyControlAuditCounts, noTagsTag, PreferenceStorage } from 'src/app/utils';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer: MatDrawer;
  public allProjects: ProjectConstruction[] = [];
  startIndex = 0;
  pageSize = 50;
  endIndex = this.pageSize;
  public projectKeyControlTemplates: any = {};
  public fieldToSortBy: string;
  public showFilters = false;
  public filter: ProjectListFilter = new ProjectListFilter();
  public projectFilterOptions: ProjectFilterOptions = {
    architects: [],
    projectManagers: [],
    requesters: [],
    buildings: [],
    topics: [],
    subStatuses: [],
  };
  public floorOptions: SelectOption[] = [];
  public sortDirection: SortDirection = 'desc';
  public isLoading = false;
  public projectFields = [
    'id',
    'code',
    'module_id',
    'module{id,name}',
    'title',
    'status{id,name}',
    'architect',
    'is_architect_required',
    'building{id,name,code}',
    'current_phase{id,name}',
    'floor{id,name,code}',
    'suite{id,name}',
    'department{id,name}',
    'priority{id,name,abbreviation}',
    'project_manager',
    'project_manager_first_name',
    'project_requester_first_name',
    'architect_first_name',
    'created_by{id,first_name,last_name}',
    'created_datetime',
    'closed_datetime',
    'kc_budget',
    'kc_bids',
    'kc_vendor_contracts',
    'kc_substantial_completion',
    'kc_fire_marshal',
    'kc_invoices',
    'updates.sort(created_datetime).order(desc).limit(1){code,created_by,created_by_first_name,created_by_id,created_by_last_name,created_datetime,id,message,project_id,project_health_type{id,name}}',
    'requester_id',
    'requester{first_name,last_name}',
    'request{request_method_id,request_method{id,name,is_enabled,is_default,icon}}',
    'substatus',
    'tags',
    'topic_id',
    'topic_name',
    'topic_group',
    'topic_category',
    'latest_update_created_datetime',
    'current_phase_name',
    'capx_budget{capx_id,description}',
  ];
  public projectStatuses: ProjectStatus[];
  public selectedMenuItem: ProjectListMenu;
  public ProjectListMenu = ProjectListMenu;
  public projectStatusCounts = {};

  public projectUpdates: ProjectUpdate[] = [];
  public UserType = UserType;
  public tags: ResourceTag[] = [];

  private subscriptions = [];

  @ViewChild('paginator', { static: false }) paginator: any;

  projectsCount = 0;

  search = new FormControl('');
  status = new FormControl('');

  get filtersAppliedCount(): number {
    let filtersAppliedCount = 0;
    filtersAppliedCount += this.filter.topics.length;
    filtersAppliedCount += this.filter.projectManagers.length;
    filtersAppliedCount += this.filter.architects.length;
    filtersAppliedCount += this.filter.requesters.length;
    filtersAppliedCount += this.filter.buildings.length;
    filtersAppliedCount += this.filter.floors.length;
    filtersAppliedCount += this.filter.tags.length;
    filtersAppliedCount += this.filter.subStatuses.length;
    return filtersAppliedCount;
  }

  // --------------------------------------------------------------------------
  // these functions handle the mat-selects, specifically with toggle all/one
  // --------------------------------------------------------------------------
  public get currentWorkspace() {
    return this.moduleService?.workspace_id;
  }

  public get isDispatch() {
    return this.moduleService?.workspace_id === 2;
  }

  public get isTitleSeventyFour() {
    return (
      !isNaN(this.moduleService?.workspace_id) && ![Workspace.Construction].includes(+this.moduleService?.workspace_id)
    );
  }

  public get isTitleSeventyFourKeyControlsView() {
    return this.selectedMenuItem === ProjectListMenu.keyControls && this.isTitleSeventyFour;
  }

  public get isTitleSixtyOne() {
    return (
      !isNaN(this.moduleService?.workspace_id) && [Workspace.Construction].includes(+this.moduleService?.workspace_id)
    );
  }

  public get isTitleSixtyOneKeyControlsView() {
    return this.selectedMenuItem === ProjectListMenu.keyControls && this.isTitleSixtyOne;
  }

  public get update_dot_created_datetime() {
    return ORDER_FIELD.UPDATE_DOT_CREATED_DATETIME;
  }

  public get workspace() {
    return Workspace;
  }

  private preferences = new PreferenceStorage<ProjectListPreferences>('preferences_project_list', {
    fieldToSortBy: 'code',
    selectedMenuItem: ProjectListMenu.default,
    sortDirection: 'desc',
    statusId: Status.ACTIVE,
    version: 2,
  });

  constructor(
    public authService: AuthService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private moduleService: ModuleService,
    private sidenavLinksService: SidenavLinksService,
    private exportService: ExportService,
    private _modalService: ModalService,
    private _keyControlsService: KeyControlsService,
    private resourceTagsService: ResourceTagsService,
    private _snackbar: MatSnackBar,
    private projectOverviewService: ProjectOverviewService
  ) {}

  async ngOnInit(): Promise<void> {
    const mainProjectListWrapper = document.querySelector('#main-project-list-wrapper');
    const ps = new PerfectScrollbar(mainProjectListWrapper);
    this.projectStatuses = await this.projectService
      .getProjectStatuses(['id', 'name'], null, null, 'id', 'asc', true)
      .toPromise();
    const tagFilter: APIFilter[] = [
      { type: 'field', field: `resource_type_ids`, value: ResourceType.Project, match: 'exact' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_enabled', value: 1 },
    ];
    this.tags = [noTagsTag, ...(await this.resourceTagsService.getResourceTags(tagFilter).toPromise())];
    // When you switch types of projects, 74 vs 61
    setTimeout(() => {
      this.sidenavLinksService.selectLink(this.sidenavLinksService.projectView);
      const preferences = this.preferences.currentValue;
      this.filter.userId = preferences?.userId;
      this.filter.statusId = preferences?.statusId;
      this.sortDirection = preferences?.sortDirection;
      this.fieldToSortBy = preferences?.fieldToSortBy;
      this.selectedMenuItem = preferences?.selectedMenuItem;
      // none staff, incase the local storage is in a different view
      if (+this.selectedMenuItem !== ProjectListMenu.default && !this.isStaff) {
        this.updateMenuItem(ProjectListMenu.default);
      }

      this.refresh();
    });

    // keep track of subscription so we can destroy it when the component is destroyed
    this.subscriptions.push(
      this.moduleService.selectWorkspaceEvent.subscribe(() => {
        this.refresh();
        // check in here, because we now have the workspace
        if (+this.selectedMenuItem === ProjectListMenu.fourDX && +this.currentWorkspace === Workspace.Construction) {
          this.updateMenuItem(ProjectListMenu.default);
        }
      })
    );

    this.subscriptions.push(
      this.search.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((v) => {
        if (!v || v?.trim()) {
          this.getProjects();
          this.goToFirstPage();
        }
      })
    );

    this.subscriptions.push(
      this.status.valueChanges.pipe(distinctUntilChanged()).subscribe((statusId) => {
        this.filter.statusId = statusId;
        this.handleClearAllSelectedFilters();
        this.refresh();
        this.preferences.setPartialValue({ statusId: this.filter.statusId });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  public async refresh() {
    if (typeof this.currentWorkspace !== 'undefined') {
      // Gets the total counts for the different project statuses
      this.getProjectsStatusCounts();

      await this.getProjects();
      this.projectFilterOptions = await this.getProjectFilterOptions();
      this.floorOptions = await this.getFloorOptions();
      this.goToFirstPage();
    }
  }

  async getProjects(pageIndex = 0) {
    const tid = setTimeout(() => {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Loading...');
    }, 500);

    this.isLoading = true;
    const filter = this.getAPIFilter();

    const offset = this.pageSize * pageIndex;
    await this.projectService
      .getProjectsData(this.projectFields, filter, this.pageSize, this.fieldToSortBy, this.sortDirection, offset)
      .toPromise()
      .then((res) => {
        this.allProjects = res.data.projects;
        this.projectsCount = res.count;
      });

    if (this.selectedMenuItem === ProjectListMenu.keyControls) {
      await this._createKeyControlTemplate();
    }

    this.isLoading = false;
    clearTimeout(tid);
    this.progressIndicatorService.close();
  }

  async getProjectsStatusCounts() {
    const promises = [];
    this.projectStatuses.forEach((s) => {
      let filter: APIFilter[] = this.getTabFilter(s.id);
      if (this.filter.userId) {
        filter.push(
          { type: 'operator', value: 'AND' },
          { type: 'operator', value: '(' },
          { type: 'field', field: 'architect_id', value: this.authService.getLoggedInUser().id },
          { type: 'operator', value: 'OR' },
          { type: 'field', field: 'project_manager_id', value: this.authService.getLoggedInUser().id },
          { type: 'operator', value: ')' }
        );
      }

      filter = this.cleanFilterOption(filter);

      const promise = this.projectService
        .getProjectsData(['id'], filter, 1)
        .toPromise()
        .then((res) => {
          this.projectStatusCounts[s.id] = res.count;
        });
      promises.push(promise);
    });
    await Promise.all(promises);
  }

  private _addKeyControl(bridge: KeyControlsBridge) {
    switch (bridge.key_control_template.abbreviated_name) {
      case KeyControlDisplayData.BUDGET_FINALIZED_61:
        return (this.projectKeyControlTemplates[bridge.task_project_id].BUDGET_FINALIZED_61 = bridge);
      case KeyControlDisplayData.PROJECT_PROPERLY_BID_61:
        return (this.projectKeyControlTemplates[bridge.task_project_id].PROJECT_PROPERLY_BID_61 = bridge);
      case KeyControlDisplayData.VENDOR_CONTRACTS_61:
        return (this.projectKeyControlTemplates[bridge.task_project_id].VENDOR_CONTRACTS_61 = bridge);
      case KeyControlDisplayData.FIRE_MARSHAL_61:
        return (this.projectKeyControlTemplates[bridge.task_project_id].FIRE_MARSHAL_61 = bridge);
      case KeyControlDisplayData.CONTRACT_COMPLETION_61:
        return (this.projectKeyControlTemplates[bridge.task_project_id].CONTRACT_COMPLETION_61 = bridge);
      case KeyControlDisplayData.ALL_INVOICES__PROCESSED_61:
        return (this.projectKeyControlTemplates[bridge.task_project_id].ALL_INVOICES__PROCESSED_61 = bridge);
      case KeyControlDisplayData.BUDGET_APPROVED_74:
        return (this.projectKeyControlTemplates[bridge.task_project_id].BUDGET_APPROVED_74 = bridge);
      case KeyControlDisplayData.PROJECT_PROPERLY_BID_74:
        return (this.projectKeyControlTemplates[bridge.task_project_id].PROJECT_PROPERLY_BID_74 = bridge);
      case KeyControlDisplayData.ARFS_FINALIZED_74:
        return (this.projectKeyControlTemplates[bridge.task_project_id].ARFS_FINALIZED_74 = bridge);
      case KeyControlDisplayData.ALL_INVOICES_PROCESSED_74:
        return (this.projectKeyControlTemplates[bridge.task_project_id].ALL_INVOICES_PROCESSED_74 = bridge);
      case KeyControlDisplayData.CUSTOMER_SATISFACTION_74:
        return (this.projectKeyControlTemplates[bridge.task_project_id].CUSTOMER_SATISFACTION_74 = bridge);
      default:
        return null;
    }
  }

  private async _createKeyControlTemplate() {
    const projectIds: number[] = this.allProjects.map((project: Project) => project.id);
    let keyControlBridges: KeyControlsBridge[] = await this._keyControlsService
      .getKeyControlsForProjects(projectIds)
      .toPromise();

    keyControlBridges = keyControlBridges.sort(
      (a, b) => a.key_control_template?.sequence - b.key_control_template?.sequence
    );

    keyControlBridges.forEach((keyControlBridge: KeyControlsBridge) => {
      // Parse access data if it exists
      if (keyControlBridge?.task?.accessory_data) {
        const accessoryData: TaskAccessoryData = JSON.parse(keyControlBridge?.task?.accessory_data);
        // add it to the bridge
        keyControlBridge.task.key_control_accessory_data = accessoryData;

        // get count and add it to the bridge
        const AuditCounts = keyControlAuditCounts(accessoryData?.reviewChain);
        keyControlBridge.task.key_control_status_counts = AuditCounts;
      }

      // add key control
      if (this.projectKeyControlTemplates[keyControlBridge.task_project_id]) {
        this._addKeyControl(keyControlBridge);
      } else {
        // build template
        this.projectKeyControlTemplates[keyControlBridge.task_project_id] = {
          BUDGET_FINALIZED_61: null,
          PROJECT_PROPERLY_BID_61: null,
          VENDOR_CONTRACTS_61: null,
          FIRE_MARSHAL_61: null,
          CONTRACT_COMPLETION_61: null,
          ALL_INVOICES__PROCESSED_61: null,
          BUDGET_APPROVED_74: null,
          PROJECT_PROPERLY_BID_74: null,
          ARFS_FINALIZED_74: null,
          ALL_INVOICES_PROCESSED_74: null,
          CUSTOMER_SATISFACTION_74: null,
        };

        // then add key control
        this._addKeyControl(keyControlBridge);
      }
    });
  }

  public get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.currentWorkspace);
  }

  public get isStaff(): boolean {
    return this.authService.isStaffOnAnyModule;
  }

  public updateSortByField(field: string) {
    if (field === this.fieldToSortBy) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortDirection = 'asc';
    }
    this.fieldToSortBy = field;
    this.preferences.setPartialValue({ sortDirection: this.sortDirection, fieldToSortBy: this.fieldToSortBy });
    this.getProjects();
  }

  public updateWorkspaces(newWorkspaceIds) {
    this.filter.workspaceIds = newWorkspaceIds;
    this.refresh();
  }

  public filterByCurrentUser() {
    this.filter.userId ? (this.filter.userId = null) : (this.filter.userId = this.authService.getLoggedInUser().id);
    this.preferences.setPartialValue({ userId: this.filter.userId });
    this.getProjectsStatusCounts();
    this.getProjects();
  }

  public async handledSelectedBuildingChange(): Promise<void> {
    this.floorOptions = await this.getFloorOptions();
    this.getProjects();
  }

  public handleClearAllSelectedFilters(): void {
    this.filter = {
      ...this.filter,
      architects: [],
      requesters: [],
      buildings: [],
      floors: [],
      projectManagers: [],
      tags: [],
      topics: [],
      subStatuses: [],
    };
  }

  get selectedTags() {
    return this.tags?.filter((t) => this.filter?.tags?.includes(t.id)) || [];
  }

  private async getProjectFilterOptions(): Promise<ProjectFilterOptions> {
    const projectFilterOptions: ProjectFilterOptions = {
      architects: [],
      projectManagers: [],
      requesters: [],
      buildings: [],
      topics: [],
      subStatuses: [],
    };

    const distinctFields = [
      {
        filter_id: 'topics',
        id: 'topic_id',
        fields: ['topic_group', 'topic_category', 'topic_name'],
        createOption: (p) => {
          return `${p.topic_group} > ${p.topic_category} > ${p.topic_name}`;
        },
      },
      {
        filter_id: 'projectManagers',
        id: 'project_manager_id',
        fields: ['project_manager_first_name', 'project_manager_last_name'],
        createOption: (p) => {
          return `${p.project_manager_first_name} ${p.project_manager_last_name}`;
        },
      },
      {
        filter_id: 'architects',
        id: 'architect_id',
        fields: ['architect_first_name', 'architect_last_name'],
        createOption: (p) => {
          return `${p.architect_first_name} ${p.architect_last_name}`;
        },
      },
      {
        filter_id: 'requesters',
        id: 'requester_id',
        fields: ['project_requester_first_name', 'project_requester_last_name'],
        createOption: (p) => {
          return `${p.project_requester_first_name} ${p.project_requester_last_name}`;
        },
      },
      {
        filter_id: 'buildings',
        id: 'building_id',
        fields: ['building_name', 'building_code'],
        createOption: (p) => {
          return `${p.building_name} - ${p.building_code}`;
        },
      },
      {
        filter_id: 'subStatuses',
        id: 'substatus_id',
        fields: ['substatus_name'],
        createOption: (p) => {
          return `${p.substatus_name}`;
        },
      },
    ];
    const promises = [];
    const isViewAll = this.filter.statusId == -1;
    let APIFilter: APIFilter[] = isViewAll ? [] : [{ type: 'field', field: 'status_id', value: this.filter.statusId }];
    if ((this.currentWorkspace === 2 && this.filter?.workspaceIds?.length > 0) || this.currentWorkspace !== 2) {
      if (APIFilter.length > 0) {
        APIFilter.push({ type: 'operator', value: 'AND' });
      }
      APIFilter.push({
        type: 'field',
        field: 'module_id',
        value: this.currentWorkspace === 2 ? this.filter.workspaceIds.join('^') : this.currentWorkspace,
      });
    }
    distinctFields.forEach((ds) => {
      const promise = this.projectService
        .getProjects(ds.fields.concat([ds.id]), APIFilter, null, ds.fields.join(','), 'asc', true)
        .toPromise()
        .then((res) => {
          res.forEach((r) => {
            if (r[ds.id]) {
              const option = { value: r[ds.id], name: ds.createOption(r) };
              projectFilterOptions[ds.filter_id].push(option);
            }
          });
        });
      promises.push(promise);
    });

    await Promise.all(promises);
    return projectFilterOptions;
  }

  private async getFloorOptions(): Promise<SelectOption[]> {
    const floorOptions: SelectOption[] = [];
    const isViewAll = this.filter.statusId == -1;

    const APIFilter: APIFilter[] = isViewAll
      ? [{ type: 'field', field: 'building_id', value: this.filter.buildings.join('^') }]
      : [
          { type: 'field', field: 'status_id', value: this.filter.statusId },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'building_id', value: this.filter.buildings.join('^') },
        ];
    const distinctFloors = await this.projectService
      .getProjects(['floor_name'], APIFilter, null, 'floor_name', 'asc', true)
      .toPromise();

    distinctFloors.forEach((floor) => {
      if (floor?.floor_name) {
        floorOptions.push({
          name: floor.floor_name,
          value: floor.floor_name,
        });
      }
    });
    // clear filter values that do not exist in options anymore
    this.filter.floors = this.filter.floors.filter((fn) => distinctFloors.map((df) => df.floor_name).indexOf(fn) > -1);
    return floorOptions;
  }

  public updateMenuItem(newMenuItem: ProjectListMenu) {
    this.selectedMenuItem = newMenuItem;

    // Sets the selected menu item in local storage
    this.preferences.setPartialValue({ selectedMenuItem: this.selectedMenuItem });
    this.getProjectsStatusCounts();
  }

  public stopPropagation(event) {
    event.stopPropagation();
  }

  // ------------------------------------------------------------------
  // exporting functionality
  // ------------------------------------------------------------------

  public async exportData() {
    this._modalService
      .openConfirmationDialog({
        titleBarText: 'Confirm Data Export',
        descriptionText:
          'Data export will use the currently selected filter settings. Are you sure you wish to continue?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Exporting...');
          // get the filter options
          let workspace;
          if (this.isDispatch) {
            if (this.filter?.workspaceIds.length === 1) {
              workspace =
                ExportService.getWorkspaceNameFromEnum(Workspace, this.filter.workspaceIds[0]).toLowerCase() + '_';
            } else if (this.filter?.workspaceIds.length === 0) {
              workspace = `all_workspaces_`;
            } else if (this.filter?.workspaceIds.length > 1) {
              workspace = `multiple_workspaces_`;
            }
          } else {
            workspace = this.moduleService.workspace?.name?.toLowerCase() + '_';
          }
          const sortField = this.fieldToSortBy ? `_${this.fieldToSortBy.replace(/\./g, '_')}` : null;
          const sanitizedData = await this.getExportData();
          this.exportService.exportToCsv(
            `${workspace || ''}project_list_export_${moment().format('MM/DD/YY')}${sortField || ''}.csv`,
            sanitizedData
          );
          this.progressIndicatorService.close();
        }
      });
  }

  // format the current displayed data in csv
  private async getExportData(): Promise<string[]> {
    const apiFilter = this.getAPIFilter();
    const dataToExport = await this.projectService
      .getProjects(this.projectFields, apiFilter, this.projectsCount, this.fieldToSortBy, this.sortDirection)
      .toPromise();
    // set the initial headers
    const dataToReturn: string[] = [
      'Code, Building, Floor, Suite, Department, Workspace, Short Description, Priority, Project Manager, Date Created, Date Closed, Status, Current Phase, Last Update',
    ];
    for (const entry of dataToExport) {
      // const location = `${entry.building?.code || 'N/A'}-${entry.floor?.code || 'N/A'}`;
      const projectManager = `${entry?.project_manager?.first_name || ''} ${entry?.project_manager?.last_name}`.trim();
      const update = entry?.updates?.[0];
      const lastUpdate = update
        ? `Last Update by ${update.created_by_first_name} ${update.created_by_last_name} on ${moment(
            update.created_datetime
          ).format('MM/DD/YYYY HH:mm:ss')}: ${update.message}`
        : null;
      const dateClosed = entry?.closed_datetime ? moment(entry.closed_datetime).format('MM/DD/YYYY HH:mm:ss') : null;
      dataToReturn.push(
        this.exportService.sanitizeItems([
          entry?.code || '',
          entry?.building?.code || '-',
          entry?.floor?.code || '-',
          entry?.suite?.name || '-',
          entry?.department?.name || '-',
          entry?.module?.name || '',
          entry?.title || '',
          entry?.priority?.name || '',
          projectManager || '-',
          entry?.created_datetime ? moment(entry.created_datetime).format('MM/DD/YYYY HH:mm:ss') : '',
          dateClosed || '',
          entry?.status?.name || '',
          entry?.current_phase?.name || '',
          lastUpdate || '',
        ])
      );
    }
    return dataToReturn;
  }

  public openViewKeyControlsDialog({ projectId, keyControlId }: { projectId: number; keyControlId: number }) {
    if (projectId && keyControlId) {
      this._modalService.openViewKeyControlsDialog(projectId, keyControlId);
    } else {
      this._snackbar.open('This project key control does not exist!');
    }
  }

  public closeAside() {
    this.drawer.close();
  }

  async pageChange(event) {
    const element = document.querySelector('#main-project-list-wrapper');
    element.scrollTo(0, 0);
    await this.getProjects(event.pageIndex);
  }

  goToFirstPage() {
    this.paginator.firstPage();
    this.startIndex = 0;
  }

  getTabFilter(statusId: number = -1) {
    //Default to "View All" if statusId argument is not passed.
    const isViewAll = statusId == -1;

    // if dispatch workspace, grab all projects, since they will be further filtering by workspace
    let filter: APIFilter[] = isViewAll ? [] : [{ type: 'field', field: 'status_id', value: statusId }];

    const andOperator = [{ type: 'operator', value: 'AND' }];

    if (this.isStaff && this.filter.workspaceIds.length == 0) {
      const filterByStaff = [{ type: 'field', field: 'module_id', value: this.currentWorkspace.toString() }];
      filter = isViewAll ? filterByStaff : [...filter, ...andOperator, ...filterByStaff];
    }

    if (this.filter.workspaceIds.length > 0) {
      const filterByWorkspace = [{ type: 'field', field: 'module_id', value: this.filter.workspaceIds.join('^') }];
      filter = isViewAll ? filterByWorkspace : [...filter, ...andOperator, ...filterByWorkspace];
    } else if (this.isDispatch) {
      filter = isViewAll ? [] : [{ type: 'field', field: 'status_id', value: statusId }];
    }
    return filter;
  }

  getAPIFilter() {
    let filter: APIFilter[] = this.getTabFilter(this.filter.statusId);

    if (this.search.value?.trim()) {
      let searchFilter: APIFilter[] = [
        { type: 'operator', value: 'AND' },
        { type: 'operator', value: '(' },
      ];
      const searchFields = [
        'title',
        'code',
        'building_name',
        'building_code',
        'priority_name',
        'floor_code',
        'floor_name',
        'project_manager_full_name',
        'project_requester_full_name',
        'current_phase_name',
      ];
      searchFields.forEach((sf, i) => {
        searchFilter.push({ type: 'field', field: sf, value: this.search.value.trim(), match: 'any' });
        if (i < searchFields.length - 1) searchFilter.push({ type: 'operator', value: 'OR' });
      });
      searchFilter.push({ type: 'operator', value: ')' });
      filter = [...filter, ...searchFilter];
    }

    const optionFilters = [
      { name: 'topics', field: 'topic_id' },
      { name: 'projectManagers', field: 'project_manager_id' },
      { name: 'architects', field: 'architect_id' },
      { name: 'requesters', field: 'requester_id' },
      { name: 'buildings', field: 'building_id' },
      { name: 'floors', field: 'floor_name' },
      { name: 'subStatuses', field: 'substatus_id' },
    ];
    optionFilters.forEach((of) => {
      if (this.filter[of.name].length > 0) {
        const ofFilter: APIFilter[] = [
          { type: 'operator', value: 'AND' },
          { type: 'field', field: of.field, value: this.filter[of.name].join('^') },
        ];
        filter = [...filter, ...ofFilter];
      }
    });

    if (this.filter.tags.length > 0) {
      const tagsFilter: APIFilter[] = [{ type: 'operator', value: 'AND' }];
      tagsFilter.push({ type: 'operator', value: '(' });
      this.filter.tags.forEach((tag, i) => {
        if (tag === -666) {
          // no tags
          tagsFilter.push({ type: 'field', field: 'tag_ids', value: 'null' });
          if (i < this.filter.tags.length - 1) tagsFilter.push({ type: 'operator', value: 'OR' });
        } else {
          tagsFilter.push({ type: 'field', field: 'tag_ids', value: tag });
        }
        if (i < this.filter.tags.length - 1 && tag !== -666) tagsFilter.push({ type: 'operator', value: 'AND' });
      });
      tagsFilter.push({ type: 'operator', value: ')' });
      filter = [...filter, ...tagsFilter];
    }

    if (this.filter.userId) {
      const myProjectsFilter: APIFilter[] = [
        { type: 'operator', value: 'AND' },
        { type: 'operator', value: '(' },
        { type: 'field', field: 'project_manager_id', value: this.filter.userId },
        { type: 'operator', value: 'OR' },
        { type: 'field', field: 'architect_id', value: this.filter.userId },
        { type: 'operator', value: ')' },
      ];
      filter = [...filter, ...myProjectsFilter];
    }
    return this.cleanFilterOption(filter);
  }

  cleanFilterOption(filter: APIFilter[]) {
    //If first filter command is the AND operator without any preceding condition.
    let size = filter.length || 0;
    if (size > 0 && filter[0].type == 'operator' && filter[0].value == 'AND') {
      filter.shift();
    }
    return filter;
  }

  async getProjectUpdates() {
    let propjectUpdateFilter: APIFilter[] = [
      { type: 'field', field: 'project_status_id', value: this.filter.statusId },
    ];
    if ((this.currentWorkspace === 2 && this.filter?.workspaceIds?.length > 0) || this.currentWorkspace !== 2) {
      const wsFilter: APIFilter[] = [
        { type: 'operator', value: 'AND' },
        {
          type: 'field',
          field: 'module_id',
          value: this.currentWorkspace === 2 ? this.filter.workspaceIds.join('^') : this.currentWorkspace,
        },
      ];
      propjectUpdateFilter = [...propjectUpdateFilter, ...wsFilter];
    }

    this.projectUpdates = await this.projectOverviewService
      .getProjectUpdates(
        [
          'project_code',
          'project_title',
          'created_by',
          'created_by_first_name',
          'created_by_id',
          'created_by_last_name',
          'created_datetime',
          'id',
          'message',
          'project_id',
          'project_health_type{id,name}',
        ],
        propjectUpdateFilter,
        100
      )
      .toPromise();
  }

  openCAPXpanel(capx, e) {
    e.stopPropagation();
    this.projectService.capxIDEvent.emit(capx);
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HandleErrorService } from './handle-error.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { APIFilter, ServiceResponse } from '../types';
import { LinkedWOTask } from '../types/linked-wo-task';
import { of } from 'rxjs';
import { ApiFilterService } from './api-filter.service';

@Injectable({
  providedIn: 'root',
})
export class LinkedTaskService {
  host: string = environment.serviceHost;
  public linkedWOTaskURL = `${this.host}/api/v1/linked-wo-tasks`;
  linkedWOTaskFields =
    'id,work_order_id,milestone_id,work_order{id,follower_ids,followers,code,title,due_date,module{name},status{name},priority{name},topic_group_name,topic_category_name,topic_name,summary,assigned_user{id,first_name,last_name,title},updates{message,files,created_by{id,first_name,last_name},created_datetime},files},display_order_hash,milestone_id,phase_id,project_id,project_code';

  constructor(
    private apiFilterService: ApiFilterService,
    private handleErrorService: HandleErrorService,
    private http: HttpClient
  ) {}

  getLinkedWOTasks(
    fields?: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<any[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);

    return this.http
      .get(
        `${this.linkedWOTaskURL}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const linkedWOTasks: any[] = result.data.linked_wo_tasks;
          return linkedWOTasks;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getLinkedWOTask(id: number): Observable<LinkedWOTask> {
    return this.http.get(`${this.linkedWOTaskURL}/${id}?fields=${this.linkedWOTaskFields}`).pipe(
      map((result: ServiceResponse) => {
        return result.data['linked work order tasks'] && result.data['linked work order tasks'].length
          ? result.data['linked work order tasks'][0]
          : null;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getLinkedWOTaskForWorkOrder(work_order_id: number): Observable<LinkedWOTask> {
    return this.http
      .get(`${this.linkedWOTaskURL}?fields=${this.linkedWOTaskFields}&filter=work_order_id=${work_order_id}`)
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.linked_wo_tasks && result.data.linked_wo_tasks.length
            ? result.data.linked_wo_tasks[0]
            : null;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // converts a current task into a linked wo task
  createLinkedWOTask(task_id: number, work_order_id: number, loadData = false): Observable<LinkedWOTask> {
    return this.http.post(`${this.linkedWOTaskURL}/convert`, { task_id, work_order_id }).pipe(
      map((result: ServiceResponse) => {
        const task = result.data.linkedWOTask;

        task.status_id = task.work_order.status?.id;
        task.title = task.work_order.title;
        task.due_date = task.work_order.due_date;
        task.assigned_user_id = task.work_order.assigned_user?.id;
        task.assigned_user_first_name = task.work_order.assigned_user?.first_name;
        task.assigned_user_last_name = task.work_order.assigned_user?.last_name;
        task.assigned_user_login_enabled = true;

        return task;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // WARNING: This function is meant to not catch any errors and to instead allow the calling function to catch them
  public loadLinkedTaskSuppressed(id: number, fields?: string[]): Observable<LinkedWOTask> {
    return this.http.get(`${this.linkedWOTaskURL}/${id}?fields=${fields ?? this.linkedWOTaskFields}`).pipe(
      map((result: ServiceResponse) => {
        return result.data['linked work order tasks'] && result.data['linked work order tasks'].length
          ? result.data['linked work order tasks'][0]
          : null;
      })
    );
  }

  // checks whether the given WO ids are deleted or inaccessible
  // sorts the given WO ids into their appropriate buckets
  checkWorkOrderStatus(work_order_ids: number[]): Observable<number[]> {
    // if we were given no work_order_ids, return an observable that resolves to empty array
    if (!work_order_ids || !work_order_ids.length) {
      return of([]);
    }
    return this.http.get(`${this.linkedWOTaskURL}/checkWOs?filter=ids=${work_order_ids.join('^')}`).pipe(
      map((result: ServiceResponse) => {
        return result.data;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deactivateTask(task_id: number): Observable<null> {
    return this.http.delete(`${this.linkedWOTaskURL}/${task_id}`).pipe(
      map(() => {
        return null;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
